import React, { useState } from 'react';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio, RadioGroup } from '@mui/material';

const CultureAttr = (props) => {
    const { cultures, culturesGoal, irrigations, plotInfo, setMessageBox } = props;
    const [open, setOpen] = useState(false);

    const [culture, setCulture] = useState("");
    const [goal, setGoal] = useState("");

    const [initDate, setPlantDate] = useState("");
    const [endDate, setHavestDate] = useState("");
    const [days, setDeltaDays] = useState(null);

    const [isSeed, setIsSeed] = useState(false);
    const [isIrrigated, setIsIrrigated] = useState(false);
    const [irrigationType, setIrrigationType] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        cleanSelect();
    };

    const changeCulture = (event) => {
        const value = event.target.value;

        if (value !== null && value.value !== "") {
            setCulture(value);

            const defaultGoal = culturesGoal.find(x => x.cultureId === value.id && x.default);
            if (defaultGoal != null)
                setGoal(defaultGoal.name);
        }
        else {
            cleanSelect();
        }
    }

    const changeIsIrrigated = (value) => {
        if (value !== null) {
            setIsIrrigated(value);

            if (!value) {
                setIrrigationType("");
            }
        }
    }

    const cleanSelect = () => {
        setHavestDate("");
        setPlantDate("");
        setCulture("");
        setGoal("");
        setIrrigationType("");
        setDeltaDays(null);
        setIsSeed(false);
        setIsIrrigated(false);
    };

    const changePlantDate = () => {
        if (!endDate || new Date(initDate) <= new Date(endDate)) {
            calculateDeltaDays(initDate, endDate);
        }
        else {
            setMessageBox("Atenção", "A data de plantio não pode ser superior a de colheita.", false);
            setPlantDate(endDate);
        }
    };

    const changeHavestDate = () => {
        if (!initDate || new Date(endDate) >= new Date(initDate)) {
            calculateDeltaDays(initDate, endDate);
        }
        else {
            setMessageBox("Atenção", "A data de colheita não pode ser inferior a de plantio.", false);
            setHavestDate(initDate);
        }
    };

    const handleConfirmation = (value) => {
        //Obrigátoriedade de data de plantio e de colheita
        //if (value.culture != null && (!value.plantDate || !value.harvestDate)) {
        //setMessageBox("Atenção", "A data do plantio e da colheita devem ser especificados.", false);
        //} else {}

        setMessageBox("Confirmação de atribuição",
            "Deseja mesmo atribuir esta cultura para os polígonos selecionados? Atenção o processo pode levar alguns segundos, durante este período não recarregue a página.",
            value,
            true);
        handleClose();

        //Limitação para data de plantio
        //const startFormatDate = new Date(value.plantDate);
        //const endFormatDate = new Date(value.harvestDate);
        //const twoYearsInMilliseconds = 2 * 365 * 24 * 60 * 60 * 1000;

        // if (endFormatDate - startFormatDate > twoYearsInMilliseconds) {
        //     setMessageBox("Atenção", "O período de plantio até colheita não pode ser superior a 2 anos.", false);
        // } else {
        //}
        //}
    };

    const calculateDeltaDays = (start, end) => {
        if (!start || !end) {
            return "";
        }

        const startDate = new Date(start);
        const endDate = new Date(end);
        const deltaMilliseconds = endDate - startDate;
        const deltaDays = Math.floor(deltaMilliseconds / (24 * 60 * 60 * 1000));

        setDeltaDays(deltaDays);
    };
    return (
        <div>
            <Button variant="contained" disabled={plotInfo.length <= 0} onClick={handleOpen}>
                Atribuir Cultura
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Informações</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ width: "400px" }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel>Cultura</FormLabel>
                                <Select
                                    value={culture}
                                    onChange={(event) => changeCulture(event)}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: '200px',
                                            },
                                        },
                                    }}
                                >
                                    {cultures && cultures
                                        .sort((a,b) => a.name.localeCompare(b.name))
                                        .map(x => (
                                            <MenuItem value={x} key={x.id}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                    <MenuItem value={null}>Limpar</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {culture && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>Finalidade</FormLabel>
                                        <Select
                                            value={goal}
                                            onChange={(event) => setGoal(event.target.value)}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: '200px',
                                                    },
                                                },
                                            }}
                                        >
                                            {culturesGoal
                                                .sort((a, b) => a.defaultProperty - b.defaultProperty)
                                                .map(x => (
                                                    culture.id === x.cultureId ? (
                                                        <MenuItem value={x.name} key={x.name}>
                                                            {x.name}
                                                        </MenuItem>
                                                    ) : null
                                                ))
                                            }
                                            <MenuItem value={""}>Nenhum</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Data Plantio</FormLabel>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={initDate}
                                            onChange={(event) => setPlantDate(event.target.value)}
                                            onBlur={changePlantDate}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Data Colheita</FormLabel>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={endDate}
                                            onChange={(event) => setHavestDate(event.target.value)}
                                            onBlur={changeHavestDate}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel>Campo de produção de Sementes?</FormLabel>
                                        <RadioGroup
                                            value={isSeed}
                                            onChange={() => setIsSeed(!isSeed)}
                                            name="isSeed"
                                            color="primary"
                                            row
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Sim" />
                                            <FormControlLabel value="false" control={<Radio />} label="Não" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel>É Irrigado?</FormLabel>
                                        <RadioGroup
                                            value={isIrrigated}
                                            onChange={() => changeIsIrrigated(!isIrrigated)}
                                            name="isIrrigated"
                                            color="primary"
                                            row
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Sim" />
                                            <FormControlLabel value="false" control={<Radio />} label="Não" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {isIrrigated && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel>Tipo de Irrigação</FormLabel>
                                            <Select
                                                value={irrigationType}
                                                onChange={(event) => setIrrigationType(event.target.value)}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '200px',
                                                        },
                                                    },
                                                }}
                                            >
                                                {irrigations && irrigations.map(x => (
                                                    <MenuItem value={x.name} key={x.id}>
                                                        {x.name}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value={""}>Nenhum</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {days != null && (
                                    <Grid item xs={12}>
                                        <p>Dias entre o plantio e a colheita: {days} dia{days !== 1 ? "s" : ""}</p>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={() =>
                            handleConfirmation({
                                culture: culture.value,
                                goal: goal,
                                plantDate: initDate,
                                harvestDate: endDate,
                                deltaDay: days,
                                irrigated: isIrrigated,
                                irrigationType: irrigationType,
                                seed: isSeed
                            })}
                        color="primary"
                        disabled={!plotInfo.length}
                        variant="contained"
                    >
                        Atribuir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CultureAttr;