const requestprodutoVeType = "REQUEST_PRODUTOVE";
const receiveprodutoVeType = "RECEIVE_PRODUTOVE";
const requestprodutoPorTerritorioType = "REQUEST_PRODUTOPORTERRITORIO";
const receiveprodutoPorTerritorioType = "RECEIVE_PRODUTOPORTERRITORIO";
const initialState = { produtos: [], isLoading: false, listProdutos: [] };

export const produtoveActions = {
  requestProdutoVe: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestprodutoVeType });

    const url = `/api/produtoVendaExterna`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const listProdutos = await response.json();
   // console.log(listProdutos);
    dispatch({ type: receiveprodutoVeType, listProdutos });
  },

  requestProdutoPorTerritorio: (territorio, moeda) => async (dispatch, getState) => {
    const { oidc } = getState();

    
    dispatch({ type: requestprodutoPorTerritorioType });

    const url = `/api/produtoVendaExterna/produtoPorTerritorio/${territorio}/${moeda}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const produtos = await response.json();
   // console.log("Produtos:",produtos);
    dispatch({ type: receiveprodutoPorTerritorioType, produtos });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestprodutoVeType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveprodutoVeType) {
    return {
      ...state,
      listProdutos: action.listProdutos,
      isLoading: false,
    };
  }
  if (action.type === requestprodutoPorTerritorioType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveprodutoPorTerritorioType) {
    return {
      ...state,
      produtos: action.produtos,
      isLoading: false,
    };
  }

  return state;
};
