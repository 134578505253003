const requestdateEncargosType = "REQUEST_DATEENCARGOS";
const receivedateEncargosType = "RECEIVE_DATEENCARGOS";
const initialState = { dateEncargos: [], isLoading: false };

export const dateEncargosActions = {
  requestdateEncargos: (date, term) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestdateEncargosType });

    const url = `/api/simuladorencargosdate/${encodeURIComponent(date)}/${encodeURIComponent(term)}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const dateEncargos = await response.json();
    dispatch({ type: receivedateEncargosType, dateEncargos });
  },
  requestdateEncargos2: (date, term) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestdateEncargosType });

    const url = `/api/simulador2/simuladorencargos/date?campanha=${date}&term=${term}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const dateEncargos = await response.json();
    dispatch({ type: receivedateEncargosType, dateEncargos });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestdateEncargosType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receivedateEncargosType) {
    return {
      ...state,
      dateEncargos: action.dateEncargos,
      isLoading: false,
    };
  }

  return state;
};
