// eslint-disable-next-line
const requestPrecosType = "REQUEST_PRECOSVE";
const receivePrecosType = "RECEIVE_PRECOSVE";
const requestPrecoProdutoType = "REQUEST_PRECOPRODUTO";
const receivePrecoProdutoType = "RECEIVE_PRECOSPRODUTO";
const initialState = { precos: "", isLoading: false };

export const precoVeActions = {
  // requestPrecosVe: () => async (dispatch, getState) => {
  //   const { oidc } = getState();

  //   dispatch({ type: requestPrecosType });

  //   const url = `/api/precoVendaExterna`;

  //   const options = {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${oidc.user.access_token}`,
  //     },
  //   };

  //   const response = await fetch(url, options);
  //   const precos = await response.json();
  //   console.log("Preços action:", precos);
  //   dispatch({ type: receivePrecosType, precos });
  // },
  requestPrecoProduto: (produto, territorio, moeda) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPrecoProdutoType });
    // console.log(produto);
    // var id = produto.id;
    //console.log(id);
    // console.log("moeda", moeda);

    const url = `/api/precoVendaExterna/${produto.id}/${produto.fornecedorId}/${produto.grupoMaterialId}/${territorio}/${moeda}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const precos = await response.json();
    // console.log("Preços:",precos);

    dispatch({ type: receivePrecoProdutoType, precos });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestPrecosType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receivePrecosType) {
    return {
      ...state,
      precos: action.precos,
      isLoading: false,
    };
  }
  if (action.type === requestPrecoProdutoType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receivePrecoProdutoType) {
    return {
      ...state,
      precos: action.precos,
      isLoading: false,
    };
  }

  return state;
};
