/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */

const addRow = "SIMULADOR_VE_ADD_ROW";
const updateAll = "SIMULADOR_VE_UPDATE_ALL";
const deleteRow = "SIMULADOR_VE_DELETE_ROW";
const requestSaveCenario = "REQUEST_SAVE_CENARIO";
const receiveSaveCenario = "RECEIVE_SAVE_CENARIO";
const removeAllRows = "REMOVE_ALL_ROW";
const requestSimulacaoCliente = "REQUEST_SIMULACAO_CLIENTE";
const receiveSimulacaoCliente = "RECEIVE_SIMULACAO_CLIENTE";
const requestDeleteCenario = "REQUEST_DELETE_CENARIO";
const receiveDeleteCenario = "RECEIVE_DELETE_CENARIO";
const requestLoadCenario = "REQUEST_LOAD_CENARIO";
const receiveLoadCenario = "RECEIVE_LOAD_CENARIO";
const requestUpdateCenario = "REQUEST_UPDATE_CENARIO";
const receiveUpdateCenario = "RECEIVE_UPDATE_CENARIO";
const updateCenarioFailure = "UPDATE_CENARIO_FAILURE";

const initialState = { itens: [], isLoading: false, rows: [] };

const CreateRow = (produto, preco, encargos, moeda, prazo, volume, precoNegociado, valorNegociado) => {
  // const priceEncargo = produto.price * (1 + (rate / 100));
  //const precoFinal = preco * (1 + encargo) - desconto;
  //console.log("moeda", moeda);
  // console.log("prazo", prazo);
  const encargosProduto = encargos.find((x) => moeda === x.moeda && prazo === x.condicaoComercial);
  //  console.log("prazo", encargosProduto);
  const encargoProduto = encargosProduto.encargo / 100;
  // console.log(produto, preco);
  //const preco = precos.find((x) => produto.id === x.produtoSimuladorVendaExternaId);
  //console.log(preco);
  const precoProduto = preco.preco ? preco.preco : 0;
  // console.log("precoProduto", precoProduto);
  const descontoProduto = preco.descontoMaximo ? preco.descontoMaximo : 0;
  const precoFinal = (precoProduto * (1 + encargoProduto)) - descontoProduto;
  if (volume === 0) {
    var valor = precoFinal * 1;
    valorNegociado = precoNegociado * 1;
  } else {
    valor = precoFinal * volume;
    valorNegociado = precoNegociado * volume;
  }
  const valorTotal = valor;

  return {
    produtoId: produto.id,
    produto: produto,
    preco: precoProduto,
    desconto: descontoProduto,
    precoFinal: precoFinal,
    encargo: encargoProduto,
    volume: volume ?? 0,
    valorTotal: valorTotal,
    moeda: moeda,
    prazoPagamento: prazo,
    precoNegociado: precoNegociado ?? 0,
    valorFinalNegociado: valorNegociado,

  }
};

export const simuladorVeActions = {

  addRow: (produto, precos, encargos, moeda, prazo) =>
    async (dispatch, getState) => {
      if (produto) {
        // const rate = encargosRate.rate ?? 0;
        const volume = 0;
        const precoNegociado = 0;
        const valorNegociado = 0;
        const row = CreateRow(produto, precos, encargos, moeda, prazo, volume, precoNegociado, valorNegociado);
        // console.log('encargos action simulador : ', encargos);

        dispatch({ type: addRow, row: row, });
      }
    },

  deleteRow: (index) => async (dispatch, getState) => {
    dispatch({
      type: deleteRow,
      index: index,
    });
  },

  deleteCenario: (cenarioId) => async (dispatch, getState) => {
    //console.log(cenarioId);
    const { oidc } = getState();
    dispatch({ type: requestDeleteCenario });

    const url = `api/simuladorVendaExterna/deleteCenario/${cenarioId}`;
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro desconhecido');
      }
      dispatch({ type: receiveDeleteCenario, cenarioId });
    } catch (error) {
      console.error("Failed to delete scenario", error);
    }
  },

  updateRows: (rows) => async (dispatch, getState) => {
    dispatch({ type: updateAll, rows });
  },
  removeAllRows: () => async (dispatch, getState) => {
    dispatch({ type: removeAllRows });
  },

  createSaveGenerateCenario: (cliente, status, rows, total, moeda, prazoPagamento) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rowss = rows.map((x, index) => { x.ordem = index; return x });
    //console.log("Rowss: ", rows);

    dispatch({ type: requestSaveCenario });

    const url = `api/simuladorVendaExterna/saveItens/${encodeURIComponent(cliente)}/${encodeURIComponent(status)}/${encodeURIComponent(total)}/${encodeURIComponent(moeda)}/${encodeURIComponent(prazoPagamento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rowss),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro desconhecido');
      }
      const SaveCenarioItems = await response.json();
      dispatch({ type: receiveSaveCenario, SaveCenarioItems });
    } catch (error) {
      console.error('Erro ao salvar cenário:', error);
    }

  },

  simulacaoCliente: (clienteId) => async (dispatch, getState) => {
    dispatch({ type: requestSimulacaoCliente });

    const { oidc } = getState();
    const url = `api/simuladorVendaExterna/simulacaoCliente/${clienteId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const simulacoes = await response.json();
      //console.log("Simulações: ", simulacoes);

      dispatch({ type: receiveSimulacaoCliente, simulacoes: simulacoes });
    } catch (error) {
      console.error("Failed to fetch simulation data", error);
      dispatch({ type: receiveSimulacaoCliente, simulacoes: [] });
    }
  },
  loadCenario: (cenarioId) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestLoadCenario });
    //console.log("cenarioId", cenarioId);

    const url = `api/simuladorVendaExterna/cenario/${cenarioId}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro desconhecido');
      }
      const cenario = await response.json();
      //  console.log(cenario);
      // const { produtos, precos, encargos, moeda, prazo } = cenario.simuladorVendaExternaItens;
      // const produtos = cenario.simuladorVendaExternaItens.produtoSimuladorVendaExterna;
      // console.log("produtos e preços: ", produtos, precos);

      const rows = cenario.simuladorVendaExternaItens.map(item => {
        const produto = item.produtoSimuladorVendaExterna;
        const precos = { produtoSimuladorVendaExternaId: item.produtoId, preco: item.preco, descontoMaximo: item.desconto };
        // console.log(precos);
        const encargos = [{ moeda: item.moeda, condicaoComercial: item.prazoPagamento, encargo: item.encargo * 100 }];
        const volume = item.volume;
        const precoNegociado = item.precoNegociado;
        const valorFinalNegociado = item.valorFinalNegociado;
        return CreateRow(produto, precos, encargos, item.moeda, item.prazoPagamento, volume, precoNegociado, valorFinalNegociado);
      });

      dispatch({ type: receiveLoadCenario, rows, cenario });
    } catch (error) {
      console.error('Erro ao carregar cenário:', error);
    }
  },
  updateCenario: (cenarioId, status, total, moeda, condiçaoPagamento, rows) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestUpdateCenario });
    //console.log(updatedCenario);

    const url = `api/simuladorVendaExterna/atualizarCenario/${cenarioId}/${status}/${total}/${moeda}/${condiçaoPagamento}`;
    const options = {
      method: "PUT",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro desconhecido');
      }
      const updatedData = await response.json();
      dispatch({ type: receiveUpdateCenario, updatedData });
    } catch (error) {
      console.error('Erro ao atualizar cenário:', error);
      dispatch({ type: updateCenarioFailure, error });
    }
  },

};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === addRow) {
    return {
      ...state,
      rows: [...state.rows, action.row],
      isLoading: false,
    };
  }

  if (action.type === deleteRow) {
    return {
      ...state,
      rows: state.rows.filter((item, index) => index !== action.index),
      isLoading: false,
    };
  }

  if (action.type === updateAll) {
    return {
      ...state,
      rows: action.rows,
      isLoading: false,
    };
  }

  if (action.type === receiveSaveCenario) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === removeAllRows) {
    return {
      ...state,
      rows: state.rows.splice(),
      isLoading: false,
    };
  }
  if (action.type === requestSimulacaoCliente) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveSimulacaoCliente) {
    return {
      ...state,
      itens: action.simulacoes,
      isLoading: false,
    };
  }
  if (action.type === requestDeleteCenario) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveDeleteCenario) {
    return {
      ...state,
      itens: state.itens.filter((item) => item.id !== action.cenarioId),
      isLoading: false,
    };
  }
  if (action.type === requestLoadCenario) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveLoadCenario) {
    return {
      ...state,
      rows: action.rows,
      cenario: action.cenario,
      isLoading: false,
    };
  }

  if (action.type === requestUpdateCenario) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveUpdateCenario) {
    return {
      ...state,
      itens: state.itens.map((item) =>
        item.id === action.updatedData.id ? action.updatedData : item
      ),
      isLoading: false,
    };
  }

  if (action.type === updateCenarioFailure) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }


  return state;
};