const requestClientsTerritoryType = "REQUEST_CLIENTSTERRITORY";
const receiveClientsTerritoryType = "RECEIVE_CLIENTSTERRITORY";
const requestClientsTerritoryAccountsType = "REQUEST_CLIENTSTERRITORY_ACCOUNTS";
const receiveClientsTerritoryAccountsType = "RECEIVE_CLIENTSTERRITORY_ACCOUNTS";
const requestClient = "REQUEST_CLIENT";
const receiveClient = "RECEIVE_CLIENT";
const initialState = { clients: [], isLoading: false, accounts: [], isLoadingAccount: false, client: [] };


export const clientTerritoryActions = {
  requestClientTerritories: () => async (dispatch, getState) => {
    const { oidc, clientes } = getState();

    if (clientes?.clients.length <= 0) {
      dispatch({ type: requestClientsTerritoryType });

      const url = `/api/clientterritory`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const clientList = await response.json();
      dispatch({ type: receiveClientsTerritoryType, clients: clientList });
    }
  },
  requestClientsAccounts: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestClientsTerritoryAccountsType });

    const url = `/api/clientterritory/account/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const accounts = await response.json();
    dispatch({ type: receiveClientsTerritoryAccountsType, accounts });
  },
  requestClient: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestClient });

    const url = `/api/clientterritory/client/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const client = await response.json();
    dispatch({ type: receiveClient, client });
  },

};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestClientsTerritoryType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveClientsTerritoryType) {
    return {
      ...state,
      clients: action.clients,
      isLoading: false,
    };
  }
  if (action.type === requestClientsTerritoryAccountsType) {
    return {
      ...state,
      isLoadingAccount: true,
    };
  }
  if (action.type === receiveClientsTerritoryAccountsType) {
    return {
      ...state,
      accounts: action.accounts,
      isLoadingAccount: false,
    };
  }
  if (action.type === requestClient) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveClient) {
    return {
      ...state,
      client: action.client,
      isLoading: false,
    };
  }

  return state;
};
