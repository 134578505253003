import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GetCenarioActions } from "../../../actions/getcenario.actions";
import { clientActions } from "../../../actions/client.actions";
import { productActions } from "../../../actions/products.actions";
import { AllCampaignsActions } from "../../../actions/allcampaign.actions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import CircleIcon from '@mui/icons-material/Circle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { nivelSemaforoActions } from "../../../actions/nivelSemaforo.actions";
import Stack from "@mui/material/Stack";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { ReactComponent as CrossSellSvg } from "../../../assets/svg/CrossSell.svg";
import { zeroPad } from "../../../helpers/utils";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "77px",
          padding: "6px 6px",
          "&:first-child": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const invertSignal = (value = 0) => {
  return value * -1;
}

const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

const isThisYear = (someDate) => {
  const today = new Date()
  return someDate.getFullYear() === today.getFullYear()
}

function RenderForecastsTable({ props, currency }) {
  const rows = props.getCenario.GetCenario.itensCenarios
  const [rowHeight, setRowHeight] = React.useState(0);

  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={theme}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{
              "th": {
                fontWeight: "bold"
              },
            }}>

              <TableCell>Produto</TableCell>
              <TableCell align="right">Preço</TableCell>
              <TableCell align="right">Encargos</TableCell>
              <TableCell align="right">Desconto Categoria</TableCell>
              <TableCell align="right">Hectares tratados</TableCell>
              <TableCell align="right">Combo Area</TableCell>
              <TableCell align="right">Desconto Packs</TableCell>
              <TableCell align="right">Valor Final Corteva</TableCell>
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Preço Negociado</TableCell>
              <TableCell align="right">Flexibilidade</TableCell>
              {/*<TableCell align="right">Flexibilidade Marketing</TableCell>*/}
              <TableCell align="right">Ajuste</TableCell>
              <TableCell align="right">Valor Final</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {!props.produtos.isLoading && rows.map((row, i) => {
              // var descontopack = (row.preco + (row.preco * row.encargos)) * (row.descontoPacks / 100)
              var imagem = props.produtos.products.find(x => x.productId === row.produtoId)
              return (

                <TableRow key={row.produto} ref={rowRef}>

                  <TableCell>
                    {imagem?.imagemProduto ? <img src={imagem.imagemProduto} height="50" style={{ maxWidth: "150px" }} alt={row.produto} /> : row.produto}
                  </TableCell>

                  <TableCell align="right">
                    {
                      row.preco.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {Number(row.encargos * 100).toLocaleString("pt-BR")}%
                  </TableCell>
                  <TableCell align="right">
                    {invertSignal(row.descontoCategoria * 100)}%
                  </TableCell>
                  <TableCell align="right">
                    {(row.hectaresTratados).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {(Number.isInteger(row.comboArea) ? row.comboArea : 0).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {/*
                      (descontopack).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })*/
                      `${invertSignal(row.descontoPacks)}%`
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.valorFinalCorteva.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                  <TableCell align="right">
                    {(row.volume).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.precoNegociado.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                  <TableCell align="right">
                    {invertSignal(row.flexibilidade).toFixed(3).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                    %
                  </TableCell>

                  {/*<TableCell align="right">
                    {invertSignal(row.flexibilidadeMkt).toFixed(2).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                    %
                  </TableCell>*/}

                  <TableCell align="right">
                    {
                      row.ajuste.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                  <TableCell align="right">
                    {
                      row.valorFinal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}


class ViewCenario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
    };
  }

  componentWillUnmount() {
    this.props.actions.setCenario([]);
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ clientId: params.GroupId })
    this.props.actions.GetCenario(params.CenarioId)
    this.props.actions.requestClients();
    this.props.actions.requestAllCampaigns();
    this.props.actions.requestAllNivelSemaforo();
    this.props.actions.requestProductsCenario(params.CenarioId)
  }

  render() {
    var clientes = ""
    var rowss = this.props.getCenario.GetCenario;
    var cenarioId = rowss.cenarioId
    var soma = 0
    var currency = ""
    var moeda = "USD"
    var csgLink = ""

    if (this.props.getCenario.isLoading === false && rowss.length !== 0) {

      var date = rowss.vencimento.split("T")[0]
      var [year, month, day] = date.split("-")
      var result = [day, month, year].join("/")
      // soma = this.props.getCenario.GetCenario.itensCenarios.reduce((sum, x) => { return sum + x.valorFinal }, 0)
      // eslint-disable-next-line eqeqeq
      clientes = this.props.clientes.clients.find((b) => b.id == this.state.clientId)

      if (clientes)
        csgLink = `https://app.powerbi.com.mcas.ms/groups/83b365d1-ccde-4480-a7d3-bda8b9ca0769/reports/ad772169-03f1-4ac4-888d-ab4c5e1c00d4/ReportSection821cb735e70de341d5b2?filter=Clientes/group_id eq '${clientes.clientId}' and Clientes/territorio eq '${clientes.territory}'`
    }
    if (this.props.allcampanhas.isLoading === false) {
      currency = this.props.allcampanhas.AllCampaigns.find(
        (b) => b.groupedCampaign === rowss?.campanha
      );
      moeda = rowss?.moeda == null || rowss?.moeda === undefined ? (currency ? "USD" : currency?.currency) : rowss?.moeda;
    }

    var nivel = undefined
    if (rowss != null && rowss !== undefined && rowss?.itensCenarios != null && rowss?.itensCenarios !== undefined) {
      soma = rowss.soma;
      nivel = rowss.nivelSemaforoAtt;
    }
    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.getCenario.isLoading || this.props.clientes.isLoading || this.props.allcampanhas.isLoading || this.props.produtos.isLoading || this.props.nivelSemaforo.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/MinhasSimulacoesCliente?GroupId=${this.state.clientId}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/Simulador"}
              >
                Simulador
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/MinhasSimulacoesCliente?GroupId=${this.state.clientId}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">Visualizar Simulação</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              {clientes !== undefined && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1 style={{ color: "var(--cort-blue)" }}>{clientes.clientName}</h1>
                  <Tooltip title="Cross-Sell & Growth">
                    <a className="ml-2" target="_blank" href={csgLink} rel="noreferrer">
                      <CrossSellSvg height={25} fill="#000" />
                    </a>
                  </Tooltip>
                </div>
              )}
              {cenarioId && <h5 style={{ fontWeight: "bold" }}>Cenário: #{zeroPad(cenarioId, 4)}</h5>}
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={8} md={8} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                  <h4>
                    <b>Valor total Simulação: </b>
                  </h4>
                </Grid>
                <Grid item xs={2} sm={8} md={4} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                  <h4>
                    {
                      soma.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: moeda === "BRL" ? "BRL" : "USD",
                      })
                    }
                  </h4>
                </Grid>

                <Grid item xs={2} sm={8} md={8} className="align-self-center" sx={{ paddingTop: "0.5rem !important" }}>
                  <h4>
                    <b>Sua simulação foi qualificada com o score:</b>
                  </h4>
                </Grid>
                <Grid item xs={2} sm={8} md={4} sx={{ paddingTop: "0.5rem !important" }}>
                  <Stack
                    direction="row"
                    spacing={0}
                    justifyContent="flex-start"
                    className="border border-2 d-table p-2 rounded"
                    sx={{
                      borderColor: nivel ? `#${nivel?.cor === 0 ? "C00000" : nivel?.cor === 1 ? "FFC000" : "00B050"} !important` : "var(--bs-border-color) !important"
                    }}>
                    <CircleIcon sx={{ color: `${nivel?.cor === 2 ? "#00B050" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
                    <CircleIcon sx={{ color: `${nivel?.cor === 1 ? "#FFC000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
                    <CircleIcon sx={{ color: `${nivel?.cor === 0 ? "#C00000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={8} md={3}>
              <label className="d-block">Campanha</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={rowss.campanha}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            <Grid item xs={2} sm={8} md={3}>
              <label>Condição</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={rowss.condicao}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            <Grid item xs={2} sm={8} md={3}>
              <label>Vencimento</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={result}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            {this.props.getCenario.GetCenario.length !== 0 &&
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                  <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                    <p >Cesta do Cliente</p>
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={2} sm={8} md={12} >
              {this.props.getCenario.GetCenario.length !== 0 &&
                <RenderForecastsTable props={this.props} currency={moeda} />
              }
            </Grid>
            {rowss?.cenarioWorkflow !== undefined && rowss?.cenarioWorkflow?.length > 0 &&
              <>
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                      <p>Histórico de Ações:</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={12} className="pt-0">
                  <Paper>
                    <Timeline
                      sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                          flex: 0.1,
                        },
                        paddingBottom: '12px'
                      }}
                    >
                      {rowss?.cenarioWorkflow?.map((x, index) => {
                        var date = new Date(x.dataEnvio);
                        var displayDate = isToday(date) ? date.toLocaleTimeString("pt-BR", { hour: '2-digit', minute: '2-digit' }) : isThisYear(date) ? date.toLocaleDateString("pt-BR", { day: "2-digit", month: "short" }) : date.toLocaleDateString("pt-BR", { dateStyle: "short" });
                        return (
                          <TimelineItem key={index}>
                            <TimelineOppositeContent color="textSecondary">
                              {displayDate}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color={x.worflowType === 0 ? undefined : x.worflowType === 1 ? "verde" : "vermelho"} />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ textAlign: "justify" }}><strong>{x.user.name} - {x.worflowType === 0 ? "Enviou o cenário para aprovação" : x.worflowType === 1 ? "Aprovou o cenário" : "Reprovou o cenário"}</strong>:<br /> {x.comentario}</TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </Paper>
                </Grid>
              </>
            }
          </Grid>
        </div>
      </div>
    );
  }
}


export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...productActions,
          ...GetCenarioActions,
          ...clientActions,
          ...AllCampaignsActions,
          ...nivelSemaforoActions,
        },
        dispatch,
      ),
    };
  }
)(ViewCenario);
