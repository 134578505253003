import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SuccessIcon = (props) => {
    return (
        <SvgIcon {...props} width="127" height="127" viewBox="0 0 127 127">
            <path fillRule="evenodd" clipRule="evenodd" d="M63.5 0C98.5701 0 127 28.4299 127 63.5C127 98.5701 98.5701 127 63.5 127C28.4299 127 0 98.5701 0 63.5C0 28.4299 28.4299 0 63.5 0ZM63.5 12.7C35.4439 12.7 12.7 35.4439 12.7 63.5C12.7 91.5561 35.4439 114.3 63.5 114.3C91.5561 114.3 114.3 91.5561 114.3 63.5C114.3 35.4439 91.5561 12.7 63.5 12.7ZM84.4099 39.9599L50.8 73.5697L42.5901 65.3599C40.1103 62.88 36.0897 62.88 33.6099 65.3599C31.13 67.8397 31.13 71.8603 33.6099 74.3401L46.3099 87.0401C48.7897 89.52 52.8103 89.52 55.2901 87.0401L93.3901 48.9401C95.87 46.4603 95.87 42.4397 93.3901 39.9599C90.9103 37.48 86.8897 37.48 84.4099 39.9599Z"/>
        </SvgIcon>
    );
};

export default SuccessIcon;
