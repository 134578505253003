import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { productActions } from "../../../actions/products.actions";
import { AllCampaignsActions } from "../../../actions/allcampaign.actions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import CircleIcon from '@mui/icons-material/Circle';
import { nivelSemaforoActions } from "../../../actions/nivelSemaforo.actions";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Fab } from "@mui/material";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as CrossSellSvg } from "../../../assets/svg/CrossSell.svg";
import { userActions } from "../../../actions/user.actions";
import SimulatorTableEditable from "./components/TableCropCenarioWorkflow";
import { simulator2Actions } from "../../../actions/simulator2.actions";
import { GetSafeNumber, isNullOrEmpty } from "../../../helpers";
import { workflowActions } from "../../../actions/workflow.actions";
import Semaforo from "./components/Semaforo";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SimuladorTableSeeds from "./seeds/TableSeedsWorkflow";
import ActionDialogButton from "../../ActionDialogButton";
import { metodoPagamentoActions } from "../../../actions/metodopagamento.actions";
import { cpaActions } from "../../../actions/cpa.actions";
import { isThisYear, isToday, zeroPad } from "../../../helpers/utils";
import ResumoResultadosCp from "./components/ResumoResultadosCp";

class ViewCenarioDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      params: {},
      campanhaCpaId: 0,
      role: null,
      isAdmin: false,
      hasSet: false,
    };
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.setState({ params });

    this.props.actions.GetCenario(params.CenarioId);
    this.props.actions.GetCpaCpCenario(params.CenarioId);
    this.props.actions.GetCpaSeedsCenario(params.CenarioId);
    this.props.actions.requestAllNivelSemaforo();
    this.props.actions.requestClient(params.ClienteId);
    this.props.actions.requestAllMetodos();
    this.props.actions.getCur();
    this.props.actions.requestAllCampaigns2();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.hasSet && this.props.user.role?.role && this.props.user?.roleLevels) {
      const isAdmin = this.props.user.role?.role?.accessLevel === this.props.user?.roleLevels?.viewAll
      this.setState({ role: this.props.user.role?.role });
      this.setState({ isAdmin: isAdmin });
      this.setState({ hasSet: true });
    }
  }


  render() {
    const cenario = this.props.workflow.cenario;
    const cliente = this.props.simulator2.clientes?.client;

    const canViewCp = cliente?.sapOrigem?.includes('CP');
    const canViewSeeds = cliente?.sapOrigem?.includes('Seeds');
    const rowss = cenario;

    const territorio = cenario?.territorio;
    const distrito = cenario?.distrito;
    const cenarioId = cenario?.id;

    const csgLink = ""
    const cur = this.props.simulator2?.cur.value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      maximumSignificantDigits: 10
    })

    const metodoPagamentoCpItem = this.props.metodoPagamento.allMetodos.
      find(metodo => metodo.metodoPagamentoId === cenario?.cenarioV2Cp?.metodoPagamento)

    const isLoading = this.props.workflow.isLoading || this.props.workflow.cenarioStatus === "loading" || this.props.simulator2.clientes.isLoading || this.props.metodoPagamento.isLoading

    return (
      <div className="card">
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/Workflow2`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/Workflow2`}
              >
                Workflow Aprovação 2.0
              </Link>
              <Typography color="text.primary">Visualizar Simulação</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        {/* {this.props.simulator2.cur.status !== "failure" && */}
        <Tooltip title={
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center" sx={{ fontWeight: "bold", padding: "4px" }}>Informações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: "none" }}>Cotação do Dólar</TableCell>
                <TableCell sx={{ borderBottom: "none" }}>{cur}</TableCell>
              </TableRow>
            </TableBody>
          </Table>}>

          <Fab disabled={this.props.simulator2.cur.status !== "idle"} sx={{ position: "fixed", bottom: "10px", right: "10px" }} size="medium" color="primary">
            {/* {this.props.simulator2.cur.status !== "idle" ? <CircularProgress size={30} /> : */}
            <QuestionMarkIcon />
          </Fab>
        </Tooltip>
        {/* } */}
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={3} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={12}>
              <Grid container spacing={3} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={8} md={5}>

                  <Stack
                    justifyContent="flex-start"
                    alignItems="start"
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    sx={{
                      [`& h5`]: {
                        fontWeight: "bold",
                        marginBottom: 0
                      },
                    }}
                  >
                    <h5>Simulação: {zeroPad(cenarioId, 4)}</h5>
                    <h5>Distrito: {distrito}</h5>
                    <h5>Território: {territorio}</h5>
                  </Stack>

                </Grid>
                <Grid item xs={2} sm={8} md={1.5}>
                </Grid>
                <Grid item xs={2} sm={8} md={2.5}>
                  <Grid container sx={{ marginTop: "0px !important" }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    {/* {(role?.workflowViewScoreData || role?.isAdmin) && ( */}
                    {(
                      <>
                        <Grid item xs={1} sm={4} md={6} className="align-self-center" sx={{ paddingTop: "0 !important", fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.1vw, 1.5rem)" }, fontWeight: "Bold" }}>
                          Score da Negociação:
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} sx={{ paddingTop: "0px !important" }}>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            className="border border-2 d-table p-2 rounded"
                            sx={{
                              borderColor: cenario?.corGm != null ? `${cenario?.corGm === 0 ? "#C00000" : cenario?.corGm === 1 ? "#FFC000" : "#00B050"} !important` : "var(--bs-border-color) !important"
                            }}>
                            <CircleIcon sx={{ color: `${cenario?.corGm === 2 ? "#00B050" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.5vw, 1.5rem)" } }} />
                            <CircleIcon sx={{ color: `${cenario?.corGm === 1 ? "#FFC000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.5vw, 1.5rem)" } }} />
                            <CircleIcon sx={{ color: `${cenario?.corGm === 0 ? "#C00000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.5vw, 1.5rem)" } }} />
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {(!isLoading && rowss?.situacaoCenario === 3 && cenario.isApprover) &&
                  <Grid item xs={2} sm={8} md={3}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                    >
                      <ActionDialogButton aria-label="Aprovar" variant="contained"
                        disabled={rowss?.situacaoCenario !== 3 || !cenario.isApprover}
                        color={"verde"}
                        title={"Deseja realmente aprovar o cenário?"}
                        message={"Insira uma justificativa para a aprovação"}
                        onClick={(e) => { this.props.actions.aprovarCenario(cenarioId, e) }}
                        size="large"
                      >
                        Aprovar
                      </ActionDialogButton>
                      <ActionDialogButton aria-label="Reprovar" variant="contained"
                        disabled={rowss?.situacaoCenario !== 3 || !cenario.isApprover}
                        color={"vermelho"}
                        title={"Deseja realmente reprovar o cenário?"}
                        message={"Insira uma justificativa para a reprovação"}
                        validInput={true}
                        onClick={(e) => { this.props.actions.reprovarCenario(cenarioId, e) }}
                        size="large"
                      >
                        Reprovar
                      </ActionDialogButton>
                    </Stack>
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12}>
              <Divider sx={{ borderColor: 'black', borderBottom: '2px dotted' }} />
            </Grid>

            <Grid item xs={2} sm={8} md={5.5}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={1} sm={3} md={4} className="align-self-center">
                  <h5>
                    <b>Cliente: </b>
                  </h5>
                </Grid>
                <Grid item xs={1} sm={4} md={8} className="align-self-center">
                  <div style={{ display: "flex" }}>
                    <h5>{cliente?.nomeVisaoUnica}</h5>
                    {/* <Tooltip title="Cross-Sell & Growth">
                      <a className="ml-2" target="_blank" href={csgLink} rel="noreferrer">
                        <CrossSellSvg height={25} fill="#000" />
                      </a>
                    </Tooltip> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={0} sm={0} md={1}></Grid>

            <Grid item xs={2} sm={8} md={5.5}>
              <Grid container spacing={2} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={8} md={12}>
                  <Grid container sx={{ padding: "0px !important" }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    <Grid item xs={1.5} sm={5} md={10}>
                      <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>

                        <Grid item xs={1} sm={4} md={6} className="align-self-center">
                          <h5>
                            <b>Valor Final Corteva: </b>
                          </h5>
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} className="align-self-left text-left">
                          <h5>
                            {
                              (cenario?.totalSimulacaoCorteva ?? 0).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (cenario?.cenarioV2Cp == null ? "BRL" : cenario?.cenarioV2Cp?.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }
                          </h5>
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} className="align-self-center">
                          <h5>
                            <b>Valor Final Negociado: </b>
                          </h5>
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} className="align-self-left text-left">
                          <h5>
                            {
                              (cenario?.totalSimulacao ?? 0).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (cenario?.cenarioV2Cp == null ? "BRL" : cenario?.cenarioV2Cp?.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }
                          </h5>
                        </Grid>
                        {(this.state.isAdmin || this.state.role?.accessLevel > this.props.user?.roleLevels?.viewTerritory) && (
                          <>
                            <Grid item xs={1} sm={4} md={6} className="align-self-center">
                              <h5>
                                <b>GM da Negociação</b>
                              </h5>
                            </Grid>
                            <Grid item xs={1} sm={4} md={6} className="align-self-left text-left">
                              <h5>
                                {(Number((cenario?.gmActual ?? 0).toFixed(2))).toLocaleString("pt-BR")}%
                              </h5>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* {this.props.getCenario.GetCenario.length !== 0 && */}
            {cenario?.isCpActive && canViewCp && cenario?.cenarioV2Cp && (
              <>
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                      <p>CROP PROTECTION</p>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2} sm={8} md={12}>
                  <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                    <div>
                      <label style={{ marginBottom: "14px" }}>Valor total</label>
                      <h4>
                        {
                          cenario?.totalCp.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (cenario?.cenarioV2Cp?.moeda === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </div>
                    {/* DSM visualiza o % total do distrito e o % territorio 
                    RSL visualiza o % total do RSL e o % distrito da simulação
                    Area Leader visualiza o %total e o %total do RSL  */}
                    <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                      <div style={{ textAlign: "center" }}>
                        <label style={{ marginBottom: "14px" }}>%CPA total</label>
                        <h4>
                          {Number((cenario?.cenarioV2Cp?.cpaActual ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
                        </h4>
                      </div>

                      {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewArea) && (
                        <div style={{ textAlign: "center" }}>
                          <label style={{ marginBottom: "14px" }}>%CPA total (Ýrea)</label>
                          <h4>
                            {Number((this.props?.cpa?.area?.aprovadoPerCpaGeralUsado ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
                          </h4>
                        </div>
                      )}

                      {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) && (
                        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginBottom: "14px" }}>%CPA total (Business Area)</label>
                            <h4>
                              {Number((this.props?.cpa?.area?.children[0]?.aprovadoPerCpaGeralUsado ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
                            </h4>
                          </div>
                        </div>
                      )}

                      {(this.state.isAdmin || this.state.role?.accessLevel <= this.props.user?.roleLevels?.viewBusinessArea) && (
                        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginBottom: "14px" }}>%CPA total (Distrito)</label>
                            <h4>
                              {Number((this.props?.cpa?.area?.children[0]?.children[0]?.aprovadoPerCpaGeralUsado ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
                            </h4>
                          </div>
                        </div>
                      )}

                      {(this.state.isAdmin || this.state.role?.accessLevel < this.props.user?.roleLevels?.viewBusinessArea) && (
                        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginBottom: "14px" }}>%CPA total (Território)</label>
                            <h4>
                              {Number((this.props?.cpa?.area?.children[0]?.children[0]?.children[0]?.aprovadoPerCpaGeralUsado ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
                            </h4>
                          </div>
                        </div>
                      )}
                    </div>


                    <div>
                      <label style={{ marginBottom: "0" }}>Score </label>
                      <Semaforo cor={cenario?.cenarioV2Cp?.corGm} />
                    </div>
                  </Stack>
                </Grid>

                <Grid item xs={2} sm={8} md={2.4}>
                  <label className="d-block">Campanha</label>
                  <h5>{cenario?.cenarioV2Cp?.campanha}</h5>
                </Grid>
                <Grid item xs={2} sm={8} md={2.4}>
                  <label className="d-block">Condição</label>
                  <h5>{cenario?.cenarioV2Cp?.condicao}</h5>
                </Grid>
                <Grid item xs={2} sm={8} md={2.4}>
                  <label className="d-block">Vencimento</label>
                  <h5>{cenario?.cenarioV2Cp?.vencimento ? new Date(cenario?.cenarioV2Cp?.vencimento).toLocaleDateString("pt-BR") : ""}</h5>
                </Grid>
                <Grid item xs={2} sm={8} md={2.4}>
                  <label className="d-block">Método de Pagamento</label>
                  {/* <h5>{this.props.metodoPagamento.metodo?.metodo}</h5> */}
                  <h5>{metodoPagamentoCpItem?.metodo}</h5>
                </Grid>
                <Grid item xs={2} sm={8} md={2.4}>
                  {metodoPagamentoCpItem?.isBarter &&
                    <>
                      <label className="d-block">Valorização Barter</label>
                      <h5>{isNullOrEmpty(cenario?.cenarioV2Cp?.valorizacaoBarter) ? '-' : `${Number(cenario?.cenarioV2Cp?.valorizacaoBarter?.toFixed(2)).toLocaleString("pt-BR")}%`}</h5>
                    </>
                  }
                </Grid>
                <ResumoResultadosCp
                  cliente={cliente}
                  sumFinalPrice={cenario?.cenarioV2Cp?.socCrop ?? 0}
                  encargos={cenario?.cenarioV2Cp?.encargos ?? 0}
                  qtdPremium={cenario?.cenarioV2Cp?.numProdutosPremium ?? 0}
                  decontoOfertaEstruturada={cenario?.cenarioV2Cp?.descontoOfertaEstruturada ?? 0}
                />
                <Grid item xs={2} sm={8} md={12}>
                  <SimulatorTableEditable actions={this.props} currency={cenario?.cenarioV2Cp?.moeda} rows={cenario?.cenarioV2Cp?.itensCenarios} />
                </Grid>
              </>
            )
            }
            {/* <Grid item xs={2} sm={8} md={12} className="pt-0">
              {this.props.getCenario.GetCenario.length !== 0 &&
                <RenderForecastsTable props={this.props} currency={moeda} role={role} />
              }
            </Grid> */}

            {cenario?.isSeedsActive && canViewSeeds &&
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                  <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                    <p>SEEDS</p>
                  </Grid>
                </Grid>

                <Grid item xs={2} sm={8} md={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
                  <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                    <div>
                      <label style={{ marginBottom: "14px" }}>Valor total</label>
                      <h4>
                        {
                          cenario?.totalSeeds?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (cenario?.cenarioV2Cp == null ? "BRL" : cenario?.cenarioV2Cp?.moeda === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </div>
                    {/* <div style={{ textAlign: "center" }}>
                      <label style={{ marginBottom: "14px" }}>CPA - Seeds</label>
                      <h4 >
                        {Number(cpaSeedsFinal.toFixed(2)).toLocaleString("pt-BR")}%
                      </h4>
                    </div>
                    <div>
                      <label style={{ marginBottom: "0" }}>Score </label>
                      <Semaforo cor={cenario?.cor} />
                    </div> */}
                  </Stack>
                </Grid>

                {cenario?.cenarioV2Seeds?.map((item) => {
                  if (item?.cultura === "Milho") {
                    const metodoPagamentoSeedsItem = this.props.metodoPagamento.allMetodos.find(metodo => metodo.metodoPagamentoId === item.condicao);

                    function somaValorTotalSeedsMilho() {
                      return item.rows.reduce((acc, row) => acc + (GetSafeNumber(row.precoNegociado) * row.volume), 0);
                    }

                    const valorTotalSeedsMilho = somaValorTotalSeedsMilho();

                    const areaSeeds = !this.props?.cpa?.areaSeedsCultura ? undefined : this.props?.cpa?.areaSeedsCultura["Milho"];

                    return (
                      <React.Fragment key={item.id}>

                        <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                          <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                            <p>Milho</p>
                          </Grid>
                        </Grid>

                        {/* de acordo com o  aprovado. Ex. :
                        DSM visualiza o % total do distrito e o % territorio 
                        RSL visualiza o % total do RSL e o % distrito da simulação
                        Area Leader visualiza o %total e o %total do RSL */}


                        <Grid item xs={2} sm={8} md={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
                          <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                            <div>
                              <label style={{ marginBottom: "14px" }}>Valor total</label>
                              <h4>
                                {
                                  valorTotalSeedsMilho.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: (item.moeda === "BRL" ? "BRL" : "USD"),
                                  })
                                }
                              </h4>
                            </div>

                            <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                              <div style={{ textAlign: "center" }}>
                                <label style={{ marginBottom: "14px" }}>CPA total</label>
                                <h4>
                                  {
                                    Number(item.cpaActual.toFixed(2)).toLocaleString("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    })
                                  }
                                </h4>
                              </div>

                              {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewArea) && (
                                <div style={{ textAlign: "center" }}>
                                  <label style={{ marginBottom: "14px" }}>CPA total (Ýrea)</label>
                                  <h4>
                                    {isNullOrEmpty(areaSeeds?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                      Number(areaSeeds?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    }
                                  </h4>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Business Area)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel <= this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Distrito)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel < this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Território)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div>
                              <label style={{ marginBottom: "0" }}>Score </label>
                              <Semaforo cor={item.scoreGM} />
                            </div>
                          </Stack>
                        </Grid>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px', marginBottom: '24px' }}>
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Campanha</label>
                            <h5>{item.campanhaGroup}</h5>
                          </Grid>
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Condição</label>
                            <h5>{metodoPagamentoSeedsItem?.metodo}</h5>
                          </Grid>
                          {metodoPagamentoSeedsItem?.isBarter && (
                            <Grid item xs={2} sm={8} md={2.5}>
                              <label className="d-block">Valorização Barter</label>
                              <h5>{item.valorizacaoBarter || ''}</h5>
                            </Grid>
                          )}
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Vencimento</label>
                            <h5>{item.vencimento}</h5>
                          </Grid>
                        </div>
                        <Grid item xs={2} sm={8} md={12}>
                          <SimuladorTableSeeds section={item} state={this.state} actions={this.props} handleChangeSelect={this.handleChangeSelect} />
                        </Grid>
                      </React.Fragment>
                    );
                  }
                  else if (item?.cultura === "Soja") {
                    const metodoPagamentoSeedsItem = this.props.metodoPagamento.allMetodos.find(metodo => metodo.metodoPagamentoId === item.condicao);

                    function somaValorTotalSeedsSoja() {
                      return item.rows.reduce((acc, row) => acc + (GetSafeNumber(row.precoNegociado) * row.volume), 0);
                    }

                    // const valorTotalSeedsSoja = somaValorTotalSeedsSoja() / curNumber;
                    const valorTotalSeedsSoja = somaValorTotalSeedsSoja();

                    const sumFinalPriceEncargoSeedsSoja = item.rows.reduce(
                      (sum, row) => sum + (GetSafeNumber(row.subTotalPrice) * row.volume), 0
                    );

                    // console.log("sumFinalPriceEncargoSeedsSoja: ", sumFinalPriceEncargoSeedsSoja)

                    const sumFinalPriceCpaSeedsSoja = item.rows.reduce(
                      (sum, row) => sum + (GetSafeNumber(row.brDscCpa) * row.volume), 0
                    );

                    // console.log("sumFinalPriceCpaSeedsSoja: ", sumFinalPriceCpaSeedsSoja)

                    const cpaActualSeedsSoja = GetSafeNumber(sumFinalPriceCpaSeedsSoja / sumFinalPriceEncargoSeedsSoja) * 100;

                    // console.log("cpaActualSeedsSoja: ", cpaActualSeedsSoja)
                    const areaSeeds = !this.props?.cpa?.areaSeedsCultura ? undefined : this.props?.cpa?.areaSeedsCultura["Soja"];

                    return (
                      <React.Fragment key={item.id}>
                        <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                          <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                            <p>Soja</p>
                          </Grid>
                        </Grid>

                        <Grid item xs={2} sm={8} md={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
                          <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                            <div>
                              <label style={{ marginBottom: "14px" }}>Valor total</label>
                              <h4>
                                {
                                  valorTotalSeedsSoja.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: (item.moeda === "BRL" ? "BRL" : "USD"),
                                  })
                                }
                              </h4>
                            </div>
                            <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                              <div style={{ textAlign: "center" }}>
                                <label style={{ marginBottom: "14px" }}>CPA total</label>
                                <h4>
                                  {
                                    Number(item.cpaActual.toFixed(2)).toLocaleString("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    })
                                  }
                                </h4>
                              </div>

                              {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewArea) && (
                                <div style={{ textAlign: "center" }}>
                                  <label style={{ marginBottom: "14px" }}>CPA total (Ýrea)</label>
                                  <h4>
                                    {isNullOrEmpty(areaSeeds?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                      Number(areaSeeds?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    }
                                  </h4>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Business Area)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel <= this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Distrito)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel < this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Território)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <label style={{ marginBottom: "0" }}>Score </label>
                              <Semaforo cor={item.scoreGM} />
                            </div>
                          </Stack>
                        </Grid>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px', marginBottom: '24px' }}>
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Campanha</label>
                            <h5>{item.campanhaGroup}</h5>
                          </Grid>
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Condição</label>
                            <h5>{metodoPagamentoSeedsItem?.metodo}</h5>
                          </Grid>
                          {metodoPagamentoSeedsItem?.isBarter && (
                            <Grid item xs={2} sm={8} md={2.5}>
                              <label className="d-block">Valorização Barter</label>
                              <h5>{item.valorizacaoBarter || ''}</h5>
                            </Grid>
                          )}
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Vencimento</label>
                            <h5>{item.vencimento}</h5>
                          </Grid>
                        </div>
                        <Grid item xs={2} sm={8} md={12}>
                          <SimuladorTableSeeds section={item} state={this.state} actions={this.props} handleChangeSelect={this.handleChangeSelect} />
                        </Grid>
                      </React.Fragment>
                    );
                  }
                  else if (item?.cultura === "Sorgo") {
                    const metodoPagamentoSeedsItem = this.props.metodoPagamento.allMetodos.find(metodo => metodo.metodoPagamentoId === item.condicao);

                    function somaValorTotalSeedsSorgo() {
                      return item.rows.reduce((acc, row) => acc + (GetSafeNumber(row.precoNegociado) * row.volume), 0);
                    }

                    const valorTotalSeedsSorgo = somaValorTotalSeedsSorgo();

                    const areaSeeds = !this.props?.cpa?.areaSeedsCultura ? undefined : this.props?.cpa?.areaSeedsCultura["Sorgo"];

                    return (
                      <React.Fragment key={item.id}>
                        <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                          <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                            <p>Sorgo</p>
                          </Grid>
                        </Grid>

                        <Grid item xs={2} sm={8} md={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
                          <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                            <div>
                              <label style={{ marginBottom: "14px" }}>Valor total</label>
                              <h4>
                                {
                                  valorTotalSeedsSorgo.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: (item.moeda === "BRL" ? "BRL" : "USD"),
                                  })
                                }
                              </h4>
                            </div>
                            <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                              <div style={{ textAlign: "center" }}>
                                <label style={{ marginBottom: "14px" }}>CPA total</label>
                                <h4>
                                  {
                                    Number(item.cpaActual.toFixed(2)).toLocaleString("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    })
                                  }
                                </h4>
                              </div>

                              {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewArea) && (
                                <div style={{ textAlign: "center" }}>
                                  <label style={{ marginBottom: "14px" }}>CPA total (Ýrea)</label>
                                  <h4>
                                    {isNullOrEmpty(areaSeeds?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                      Number(areaSeeds?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    }
                                  </h4>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Business Area)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel <= this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Distrito)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}

                              {(this.state.isAdmin || this.state.role?.accessLevel < this.props.user?.roleLevels?.viewBusinessArea) && (
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: "20px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <label style={{ marginBottom: "14px" }}>CPA total (Território)</label>
                                    <h4>
                                      {isNullOrEmpty(areaSeeds?.children[0]?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado) ? "-" :
                                        Number(areaSeeds?.children[0]?.children[0]?.children[0]?.aprovadoPerSacoCpaGeralUsado?.toFixed(2)).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      }
                                    </h4>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <label style={{ marginBottom: "0" }}>Score </label>
                              <Semaforo cor={item.scoreGM} />
                            </div>
                          </Stack>
                        </Grid>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px', marginBottom: '24px' }}>
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Campanha</label>
                            <h5>{item.campanhaGroup}</h5>
                          </Grid>
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Condição</label>
                            <h5>{metodoPagamentoSeedsItem?.metodo}</h5>
                          </Grid>
                          {metodoPagamentoSeedsItem?.isBarter && (
                            <Grid item xs={2} sm={8} md={2.5}>
                              <label className="d-block">Valorização Barter</label>
                              <h5>{item.valorizacaoBarter || ''}</h5>
                            </Grid>
                          )}
                          <Grid item xs={2} sm={8} md={2.5}>
                            <label className="d-block">Vencimento</label>
                            <h5>{item.vencimento}</h5>
                          </Grid>
                        </div>
                        <Grid item xs={2} sm={8} md={12}>
                          <SimuladorTableSeeds section={item} state={this.state} actions={this.props} handleChangeSelect={this.handleChangeSelect} />
                        </Grid>
                      </React.Fragment>
                    );
                  }
                })}
              </Grid>
            }

            {
              <>
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                      <p>Histórico de Ações:</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={12} className="pt-0">
                  <Paper>
                    <Timeline
                      sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                          flex: 0.1,
                        },
                        paddingBottom: '12px'
                      }}
                    >
                      {rowss?.cenarioWorkflow?.map((x, index) => {
                        var approvers = x?.workflowCondition?.approvers;
                        var approver = approvers?.find(y => y.order == (x.workflowOrder))?.role;
                        var autoApprove = approvers != null && approvers?.length == 1 && isNullOrEmpty(approvers[0]?.role);

                        var date = new Date(x.dataEnvio);
                        var displayDate = isToday(date) ? date.toLocaleTimeString("pt-BR", { hour: '2-digit', minute: '2-digit' }) : isThisYear(date) ? date.toLocaleDateString("pt-BR", { day: "2-digit", month: "short" }) : date.toLocaleDateString("pt-BR", { dateStyle: "short" });
                        return (
                          <TimelineItem key={index}>
                            <TimelineOppositeContent color="textSecondary">
                              {displayDate}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color={x.worflowType === 0 ? undefined : x.worflowType === 1 ? "verde" : "vermelho"} />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ textAlign: "justify" }}>
                              {x.worflowType === 0 ?
                                <>
                                  <strong>{x.user.name} - Enviou o cenário para aprovação</strong>:
                                  <br />
                                  {x.comentario}
                                  <br />
                                  {!autoApprove ?
                                    <>
                                      Nível de aprovação: {approvers?.length}
                                      <br />
                                      Aprovadores: {approvers?.map(x => x.role)?.join(",")}
                                    </> :
                                    <>
                                      Nível de aprovação: Automático
                                    </>
                                  }
                                </>
                                :
                                (autoApprove ?
                                  <>
                                    <strong>Aprovado Automáticamente</strong>
                                  </>
                                  :
                                  <>
                                    <strong>{x.user?.name} - {x.worflowType === 1 ? "Aprovou o cenário" : "Reprovou o cenário"}</strong>:
                                    <br />
                                    Avaliador: {approver}
                                    <br />
                                    {x.comentario}
                                  </>
                                )
                              }
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </Paper>
                </Grid>
              </>
            }
          </Grid>
        </div>
      </div >
    );
  }
}


export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...workflowActions,
          ...productActions,
          // ...GetCenarioActions,
          // ...clientActions,
          ...AllCampaignsActions,
          ...nivelSemaforoActions,
          // ...cenariosActions,
          ...userActions,
          ...simulator2Actions,
          ...metodoPagamentoActions,
          ...cpaActions
        },
        dispatch,
      ),
    };
  }
)(ViewCenarioDetails);
