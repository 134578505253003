import { history } from "../helpers";

const requestMunicipiosType = "REQUEST_MUNICIPIOS";
const receiveMunicipiosType = "RECEIVE_MUNICIPIOS";

const requestEstadoType = "REQUEST_ESTADO";
const receiveEstadoType = "RECEIVE_ESTADO";

const requestTerritoryType = "REQUEST_TERRITORY";
const receiveTerritoryType = "RECEIVE_TERRITORY";

const requestClientType = "REQUEST_CLIENT";
const receiveClientType = "RECEIVE_CLIENT";

const requestSafraType = "REQUEST_SAFRA";
const receiveSafraType = "RECEIVE_SAFRA";

const requestCampanhaType = "REQUEST_CAMPANHA";
const receiveCampanhaType = "RECEIVE_CAMPANHA";

const requestAccountsType = "REQUEST_ACCOUNT";
const receiveAccountsType = "RECEIVE_ACCOUNT";

const requestPlotType = "REQUEST_PLOT";
const receivePlotType = "RECEIVE_PLOT";

const requestSafrasType = "REQUEST_SAFRAS";
const receiveSafrasType = "RECEIVE_SAFRAS";

const requestTerritoriesType = "REQUEST_ALLTERRITORIES";
const receiveTerritoriesType = "RECEIVE_ALLTERRITORIES";

const initialState = {
  campanhas: [],
  clients: [],
  accounts: [],
  territory: [],
  allTerritories: [],
  whitespaces: [],
  plotData: [],
  municipios: [],
  estados: [],
  safras: [],
  safra: "",

  isLoadingConditions: false,
  isLoadingSafra: false,
  isLoadingClients: false,
  isLoadingAccounts: false,
  isLoadingTerritory: false,
  isLoadingTerritories: false,
  isLoadingWhitespaces: false,
  isLoadingPlots: false,
  isLoadingCampanhas: false,
  isLoadingEstados: false,
  isLoadingSafras: false
};

export const whitespaceActions = {
  requestSafras: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSafrasType });

    const url = `api/Safras`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const safras = await response.json();
    dispatch({ type: receiveSafrasType, safras });
  },
  requestCampaings: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCampanhaType });

    const url = `api/whitespaceView/campanhas`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const campanhas = await response.json();
    dispatch({ type: receiveCampanhaType, campanhas });
  },
  changeUserSafra: (safraId) => async (dispatch, getState) => {
    const { oidc } = getState();

    var url = `api/Safras/setUserSafra/${safraId}`; //Mais de uma &nomevarivel${props.nomevariavel}

    var options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  requestClientWithId: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestClientType });

    const url = `api/whitespace/${id}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const clients = await response.json();
    dispatch({ type: receiveClientType, clients });
  },
  requestAccountsWithId: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAccountsType });

    const url = `api/whitespace/accounts/${id}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const accounts = await response.json();
    dispatch({ type: receiveAccountsType, accounts });
  },
  requestAccountsWithIbge: (uf) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAccountsType });

    const url = `api/whitespaceView/accountsByState/${uf}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const accounts = await response.json();
    dispatch({ type: receiveAccountsType, accounts });
  },
  requestFormattedClients: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestClientType });

    const url = `api/whitespaceView/clients`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const clients = await response.json();
    dispatch({ type: receiveClientType, clients });
  },
  requestIBGEWithId: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestTerritoryType });

    const url = `api/whitespace/territory/${id}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const territory = await response.json();
    dispatch({ type: receiveTerritoryType, territory });
  },
  requestIBGEViewId: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestTerritoryType });

    const url = `api/whitespaceView/territory/${id}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const territory = await response.json();
    dispatch({ type: receiveTerritoryType, territory });
  },
  requestAllIBGEWithId: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestTerritoriesType });

    const url = `api/whitespace/allTerritory/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const territory = await response.json();
    dispatch({ type: receiveTerritoriesType, territory });
  },

  getSafra: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSafraType });

    const url = `api/Safras/UserSafra`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      timeout: 0,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const safra = await response.json();
    dispatch({ type: receiveSafraType, safra });
  },
  getPlotData: (groupId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPlotType });

    const url = `api/whitespace/plotWhitespace/${groupId}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      timeout: 0,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const whitespace = await response.json();
    dispatch({ type: receivePlotType, whitespace });
  },
  getMunicipiosWithState: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestMunicipiosType });

    const url = `api/whitespaceView/municipios/${id}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      timeout: 0,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const municipios = await response.json();
    dispatch({ type: receiveMunicipiosType, municipios });
  },

  createAccountWhitespace: (list) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/createAccountWhitespace`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(list),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  deleteAccountWhitespace: (list) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/deleteAccountWhitespace`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(list),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },

  createPolygon: (uf, ibge, source, data) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/createPolygon/${uf}/${ibge}/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  listCreatePolygon: (uf, ibge, data) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/listCreatePolygon/${uf}/${ibge}`;

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  createPlotPolygon: (uf, ibge, source, data) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/createPlotPolygon/${uf}/${ibge}/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  listCreatePlotPolygon: (uf, ibge, data) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/listCreatePlotPolygon/${uf}/${ibge}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  updatePolygon: (groupId, source, data) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/updatePolygon/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  updateListPolygon: (list) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/updateListPolygon`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(list),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  deletePolygon: (groupId, source) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/deletePolygon/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  disablePolygon: (groupId, source) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/disablePolygon/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  generateRelation: (relation) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/changePlotRelation`;

    const options = {
      method: "POST",
      body: JSON.stringify(relation),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  updatePlotRelation: (list) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/updatePlotRelation`;

    const options = {
      method: "POST",
      body: JSON.stringify(list),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  createAccountPlot: (list) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/createAccountPlot`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(list),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  deleteAccountPlot: (list) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/deleteAccountPlot`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(list),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  updatePlotPolygon: (groupId, source, data) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/updatePlotPolygon/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  updateListPlotPolygon: (list) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/updateListPlotPolygon`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      body: JSON.stringify(list),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  deletePlotPolygon: (groupId, source) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/deletePlotPolygon/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  disablePlotPolygon: (groupId, source) => async (dispatch, getState) => {
    const { oidc } = getState();
    const url = `api/whitespace/disablePlotPolygon/${source}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },

  redirect: (url) => async (dispatch, getState) => {
    history.push(url, { some: "state" });
    history.go(0);
  },

  getEstados: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEstadoType });

    const url = `api/whitespaceView/estados`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      timeout: 0,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const estados = await response.json();
    dispatch({ type: receiveEstadoType, estados });
  },
  getMunicipios: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestMunicipiosType });

    const url = `api/whitespace/municipios/${id}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      timeout: 0,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const municipios = await response.json();
    dispatch({ type: receiveMunicipiosType, municipios });
  },
  getAllMunicipios: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestMunicipiosType });

    const url = `api/whitespace/allMunicipios`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      timeout: 0,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const municipios = await response.json();
    dispatch({ type: receiveMunicipiosType, municipios });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestClientType:
      return {
        ...state,
        isLoadingClients: true,
      };
    case receiveClientType:
      return {
        ...state,
        clients: action.clients,
        isLoadingClients: false,
      };
    case requestAccountsType:
      return {
        ...state,
        isLoadingAccounts: true,
      };
    case receiveAccountsType:
      return {
        ...state,
        accounts: action.accounts,
        isLoadingAccounts: false,
      };
    case requestTerritoryType:
      return {
        ...state,
        isLoadingTerritory: true,
      };
    case receiveTerritoryType:
      return {
        ...state,
        territory: action.territory,
        isLoadingTerritory: false,
      };
    case requestTerritoriesType:
      return {
        ...state,
        isLoadingTerritories: true,
      };
    case receiveTerritoriesType:
      // try {
      //   var ptsWithin = [];
      //   var listCounty = [];

      //   action.territory.municipio.features.forEach(x => {
      //     var poly1 = TurfBuffer(TurfPolygon(x.geometry.coordinates), 5000, { units: 'meters' });

      //     action.territory.todosMunicipios.features.forEach(y => {
      //       var poly2 = TurfPolygon(y.geometry.coordinates);
      //       var i = TurfIntersect(poly1, poly2);

      //       if (i != null) {
      //         ptsWithin.push(y.properties.GEOCODIG_M);
      //       }
      //     });
      //   });

      //   ptsWithin = ptsWithin.filter(function (item, pos) {
      //     return ptsWithin.indexOf(item) === pos;
      //   });

      //   if (ptsWithin.length > 0) {
      //     action.territory.todosMunicipios.features.forEach((x, index) => {
      //       var internalSource = x.properties.GEOCODIG_M;
      //       ptsWithin.forEach((y) => {
      //         if (y === internalSource) {
      //           listCounty.push({ uf: x.properties.SIGLA, municipio: x.properties.Nome_Munic, codIbge: x.properties.GEOCODIG_M });
      //         }
      //       });
      //     });
      //   }
      // }
      // catch (e) {
      //   console.log("Erro ao descobrir múnicipios próximos.");
      // }

      return {
        ...state,
        allTerritories: action.territory,
        isLoadingTerritories: false,
      };
    case requestPlotType:
      return {
        ...state,
        isLoadingPlots: true,
      };
    case receivePlotType:
      return {
        ...state,
        plotData: action.plotData,
        isLoadingPlots: false,
      };
    case requestMunicipiosType:
      return {
        ...state,
      };
    case receiveMunicipiosType:
      return {
        ...state,
        municipios: action.municipios,
      };
    case requestCampanhaType:
      return {
        ...state,
        isLoadingCampanhas: true,
      };
    case receiveCampanhaType:
      return {
        ...state,
        campanhas: action.campanhas,
        isLoadingCampanhas: false,
      };
    case requestEstadoType:
      return {
        ...state,
        isLoadingEstados: true,
      };
    case receiveEstadoType:
      return {
        ...state,
        estados: action.estados,
        isLoadingEstados: false,
      };
    case requestSafraType:
      return {
        ...state,
        isLoadingSafra: true,
      };
    case receiveSafraType:
      return {
        ...state,
        safra: action.safra,
        isLoadingSafra: false,
      };
    case requestSafrasType:
      return {
        ...state,
        isLoadingSafras: true,
      };
    case receiveSafrasType:
      return {
        ...state,
        safras: action.safras,
        isLoadingSafras: false,
      };
    default:
      return state;
  }
};
