import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const InfoIcon = (props) => {
    return (
        <SvgIcon {...props} width="127" height="127" viewBox="0 0 127 127">
            <path d="M63.5 57.7273C66.6882 57.7273 69.2727 60.3118 69.2727 63.5V86.5909C69.2727 89.7791 66.6882 92.3636 63.5 92.3636C60.3118 92.3636 57.7273 89.7791 57.7273 86.5909V63.5C57.7273 60.3118 60.3118 57.7273 63.5 57.7273Z" />
            <path d="M63.5 46.1818C66.6882 46.1818 69.2727 43.5973 69.2727 40.4091C69.2727 37.2209 66.6882 34.6364 63.5 34.6364C60.3118 34.6364 57.7273 37.2209 57.7273 40.4091C57.7273 43.5973 60.3118 46.1818 63.5 46.1818Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 63.5C0 28.4299 28.4299 0 63.5 0C98.5701 0 127 28.4299 127 63.5C127 98.5701 98.5701 127 63.5 127C28.4299 127 0 98.5701 0 63.5ZM63.5 11.5455C34.8063 11.5455 11.5455 34.8063 11.5455 63.5C11.5455 92.1937 34.8063 115.455 63.5 115.455C92.1937 115.455 115.455 92.1937 115.455 63.5C115.455 34.8063 92.1937 11.5455 63.5 11.5455Z" />
        </SvgIcon>
    );
};

export default InfoIcon;
