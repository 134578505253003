import { history } from "../helpers";

const requestSaveCenarioType = "REQUEST_SaveCenario";
const receiveSaveCenarioType = "RECEIVE_SaveCenario";
const initialState = { SaveCenario: [], isLoading: false };

export const SaveCenarioActions = {
  createSaveCenario: (clienteId, campanha, condicao, vencimento) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })
    // console.log(rows, getState())

    dispatch({ type: requestSaveCenarioType });
    // console.log("CHEGOU NO CENARIO: " + clienteId)
    const url = `/api/cenario/${clienteId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const SaveCenario = await response.json();
    // console.log(SaveCenario)
    sessionStorage.setItem("cenarioId", SaveCenario)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    history.push(`/Simulador-Cliente-Edit?GroupId=${params.GroupId}&CenarioId=${SaveCenario}`);
    dispatch({ type: receiveSaveCenarioType, SaveCenario });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestSaveCenarioType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveSaveCenarioType) {
    return {
      ...state,
      SaveCenario: action.SaveCenario,
      isLoading: false,
    };
  }

  return state;
};
