import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userActions } from "../actions/user.actions";
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSendRequest: false,
    };
  }
  componentWillMount() {
    this.props.actions.requestRoutesPrivateRoute();
    this.setState({ hasSendRequest: true });
  }

  render() {
    const { oidc } = this.props;
    const userLoggedIn = oidc.user;

    if (!userLoggedIn) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }

    if (this.props.parentModulo === undefined || this.props.parentModulo == null)
      return this.props.children;
    else {
      const hasPermision = this.props.user.hasCompleteRequestModulo &&
        !this.props.user.isLoadingPrivateRoute &&
        (this.props.user.routesPrivateRoute == null ||
          this.props.user.routesPrivateRoute === undefined ||
          !this.props.user.routesPrivateRoute.find(x => x.toLowerCase().includes(this.props.parentModulo.toLowerCase())));

      return (
        <React.Fragment>
          <Backdrop
            sx={{ color: '#ffffff', position: "absolute", zIndex: (theme) => theme.zIndex.drawer + 2, backgroundColor: "#000000" }}
            open={this.props.user.isLoadingPrivateRoute}
          >
            <Stack spacing={2} alignItems="center" direction="column">
              <div style={{ color: "white", fontFamily: "Gilroy", fontWeight: "Bold", }}>Validando Permissões...</div>
              <CircularProgress color="inherit" />
            </Stack>
          </Backdrop>
          
          {hasPermision && <Navigate to="/" />}
          {this.props.outlet ? <Outlet /> : (!hasPermision && this.props.children)}

        </React.Fragment>
      )
    }
  }
};

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...userActions
      }, dispatch),
    };
  }
)(PrivateRoute);
