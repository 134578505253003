export function removeObjectDuplicates(values, property) {
    let uniqueProperty = [];
    let uniqueValues = [];

    values.forEach(x => {
        if (x[property] == null || x[property] === undefined)
            return;

        if (!uniqueProperty.includes(x[property])) {
            uniqueValues.push(x);
            uniqueProperty.push(x[property]);
        }
    });

    return uniqueValues;
}

export function toDateTimeFormat(str) {
    if (str == null || str === undefined)
        return "";

    const partes = str.split("/");
    const dia = partes[0];
    const mes = partes[1];
    const ano = partes[2];
    return new Date(mes + "/" + dia + "/" + ano);
}

export function toNegative(value) {
    if (value == null || value === undefined)
        return 0;

    return Math.abs(value) * -1;
}

export const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}

export const isThisYear = (someDate) => {
    const today = new Date()
    return someDate.getFullYear() === today.getFullYear()
}

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const isNullOrUndefined = (value) => {
    if (value == null || value == undefined || value.length <= 0) {
        return true;
    }
    return false;
}

export function isNumber(str) {
    let num = Number(str);
    return !isNaN(num) && isFinite(num);
}

export function spanToSeconds(span) {
    const parts = span.split(':');
    let seconds = 0;

    if (parts.length === 3) {
        // HH:MM:SS
        seconds += parseInt(parts[0], 10) * 3600; // Hours to seconds
        seconds += parseInt(parts[1], 10) * 60;   // Minutes to seconds
        seconds += parseInt(parts[2], 10);        // Seconds
    } else if (parts.length === 2) {
        // MM:SS
        seconds += parseInt(parts[0], 10) * 60;   // Minutes to seconds
        seconds += parseInt(parts[1], 10);        // Seconds
    } else if (parts.length === 1) {
        // SS
        seconds += parseInt(parts[0], 10);        // Seconds
    }

    return seconds;
}

export function secondsToTime(seconds) {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hrs > 0)
        return `Tempo Estimado ${hrs.toString().padStart(2, '0')}h ${mins.toString().padStart(2, '0')}m`;
    
    if (mins > 0)
        return `Tempo Estimado ${mins.toString().padStart(2, '0')}m ${secs.toString().padStart(2, '0')}s`;
    
    return `Tempo Estimado ${secs.toString().padStart(2, '0')}s`;
}