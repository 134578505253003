import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simualdorVe.actions';


const FormGrupoMaterial = ({ encargos, send }) => {
  const formik = useFormik({
    initialValues: {
      moeda: encargos?.moeda || '',
      condicaoComercial: encargos?.condicaoComercial || '',
      encargo: encargos?.encargo || '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.moeda) {
        errors.moeda = 'Moeda é obrigatório';
      }
      if (!values.condicaoComercial) {
        errors.condicaoComercial = 'Condicao de Pagamento é obrigatório';
      }
      if (!values.encargo) {
        errors.encargo = 'Encargo é obrigatório';
      }
      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },
  });

  useEffect(() => {
    if (encargos.moeda) {
      formik.setFieldValue('moeda', encargos.moeda);
    }
    if (encargos.condicaoComercial) {
      formik.setFieldValue('condicaoComercial', encargos.condicaoComercial);
    }
    if (encargos.encargo) {
      formik.setFieldValue('encargo', encargos.encargo);
    }
  }, [encargos]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Stack spacing={2}>
        <FormControl fullWidth error={formik.touched.moeda && Boolean(formik.errors.moeda)}>
        <InputLabel id="moeda-label">Moeda</InputLabel>
          <Select
            labelId="moeda-label"
            id="moeda"
            name='moeda'
            value={formik.values.moeda}
            onChange={formik.handleChange}
            label="Moeda"
            variant="standard"
          >
            <MenuItem value="BRL">BRL</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
          {formik.touched.moeda && <FormHelperText>{formik.errors.moeda}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth error={formik.touched.condicaoComercial && Boolean(formik.errors.condicaoComercial)}>
          <TextField
            id="condicaoComercial"
            label="Condicao de Pagamento"
            variant="standard"
            type="text"
            value={formik.values.condicaoComercial}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.condicaoComercial && <FormHelperText>{formik.errors.condicaoComercial}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth error={formik.touched.encargo && Boolean(formik.errors.encargo)}>
          <TextField
            id="encargo"
            label="Encargo"
            variant="standard"
            type="number"
            value={formik.values.encargo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.encargo && <FormHelperText>{formik.errors.encargo}</FormHelperText>}
        </FormControl>
        <Button variant="contained" color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </Stack>
    </form>
  );
};

class AdminSimuladorCreateFornecedorPage extends React.Component {
  constructor(props) {
    super(props);

    this.sendEncargo = this.sendEncargo.bind(this);
  }

  // componentWillUnmount() {
  //   this.props.actions.clearFornecedor();
  // }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.EncargoId) {
      this.props.actions.requestEncargo(params.EncargoId)
    }
  
  }

  sendEncargo(encargo) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.EncargoId)
      this.props.actions.updateEncargo(params.EncargoId, encargo, navigate);
    else
      this.props.actions.saveEncargo(encargo, navigate);
  }


  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var isLoading = this.props.adminSimuladorVe.isLoading;

    const encargo = this.props.adminSimuladorVe.encargos ? this.props.adminSimuladorVe.encargos : [];

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-Encargos"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Encargos"}>
                Encargos
              </Link>
              <Typography color="text.primary">Novo Encargo</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <FormGrupoMaterial encargos={encargo} send={this.sendEncargo} />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminSimuladorCreateFornecedorPage);
