const requestdescontopacksType = "REQUEST_DESCONTOPACKS";
const receivedescontopacksType = "RECEIVE_DESCONTOPACKS";
const initialState = { descontopacks: [], isLoading: false };

export const descontopackActions = {
  requestdescontopacks: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestdescontopacksType });

    const url = `/api/descontopacks`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const descontopacks = await response.json();
    dispatch({ type: receivedescontopacksType, descontopacks });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestdescontopacksType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receivedescontopacksType) {
    return {
      ...state,
      descontopacks: action.descontopacks,
      isLoading: false,
    };
  }

  return state;
};
