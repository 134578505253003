import { IconButton, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const FloatingDiv = ({ isOpen, title, children }) => {
    const [position, setPosition] = useState({ x: 0, y: '35%' });
    const [index, setIndex] = useState(9999);
    const [isDragging, setIsDragging] = useState(false);
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        const handleMouseMove = (e) => {
            e.preventDefault();

            if (isDragging) {
                const { clientX, clientY } = e;
                const newX = clientX - 35;
                const newY = clientY - 32;

                // Adjust the position to stay within the viewport boundaries
                const maxX = window.innerWidth - 180; // Assuming 20px padding
                const maxY = window.innerHeight - 80;

                setPosition({
                    x: Math.min(maxX, Math.max(0, newX)),
                    y: Math.min(maxY, Math.max(0, newY)),
                });
            }
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const handleMouseDown = (e) => {
        const rect = e.target.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;

        setIsDragging(true);
        setPosition({
            x: e.clientX - offsetX,
            y: e.clientY - offsetY,
        });
    };

    return (
        <div style={{
            position: 'fixed',
            top: position.y,
            left: position.x,
            transfrom: `translate: (${-position.x}, ${-position.y})`,
            padding: '20px',
            zIndex: index,
        }}>
            <div
                style={{
                    cursor: isDragging ? 'grabbing' : 'grab',
                    background: "#ffffff33",
                    backdropFilter: 'blur(5px)',
                    minWidth: "100px",
                    width: "100%",
                    borderRadius: "8px",
                    marginBottom: "8px",
                }}

            >
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Stack direction={"row"}
                        onMouseDown={handleMouseDown}
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}>
                        <IconButton sx={{
                            height: "32px",
                            width: "32px",
                            color: "white"
                        }}>
                            <DragHandleIcon />
                        </IconButton>

                        {!open && title}
                    </Stack>

                    <IconButton sx={{
                        height: "32px",
                        width: "32px",
                        color: "white"
                    }}
                        onClick={(e) => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Stack>
            </div>

            <div style={{ display: open ? 'block' : 'none' }}>
                {children}
            </div>
        </div>
    );
};

export default FloatingDiv;
