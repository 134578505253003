const requestFormularioType = "REQUEST_FORMULARIOS";
const receiveFomularioType = "RECEIVE_FORMULARIOS";
const downloadRequestCenarioslType = "REQUEST_DOWNLOAD_CENARIO";
const downloadCompleteCenarioslType = "COMPLETE_DOWNLOAD_CENARIO";
const requestSituacaoEspecialType = "REQUEST_CENARIOS";
const receiveSituacaoEspecialType = "RECEIVE_CENARIOS";
const initialState = { formulario: null, isLoading: false, file: null, isLoadingDownload: false, situacaoEspecial: [], isLoadingSituacaoEspecial: false };

export const formularioActions = {
  requestFormulario: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestFormularioType });

    const url = `/api/cenario/Formulario/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const formulario = await response.json();
    dispatch({ type: receiveFomularioType, formulario });
  },
  requestSituacaoEspecial: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSituacaoEspecialType });

    const url = `/api/cenario/Formulario/SituacaoEspecial`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const situacaoEspecial = await response.json();
    dispatch({ type: receiveSituacaoEspecialType, situacaoEspecial });
  },
  getFormularioCenario: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: downloadRequestCenarioslType });

    const url = `/api/cenario/Formulario/file/${id}`;

    const options = {
      method: "GEt",
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    var filename = "";
    var disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }
    var blob = await response.blob();

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var URL = window.URL || window.webkitURL;
      var downloadUrl = URL.createObjectURL(blob);

      if (filename) {
        // use HTML5 a[download] attribute to specify filename
        var a = document.createElement("a");
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.location.href = downloadUrl;
      }

      setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
    }

    dispatch({ type: downloadCompleteCenarioslType, file: blob });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestFormularioType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveFomularioType) {
    return {
      ...state,
      formulario: action.formulario,
      isLoading: false,
    };
  }
  if (action.type === downloadRequestCenarioslType) {
    return {
      ...state,
      isLoadingDownload: true,
    }
  }
  if (action.type === downloadCompleteCenarioslType) {
    return {
      ...state,
      isLoadingDownload: false,
      file: action.file
    }
  }
  if (action.type === requestSituacaoEspecialType) {
    return {
      ...state,
      isLoadingSituacaoEspecial: true,
    }
  }
  if (action.type === receiveSituacaoEspecialType) {
    return {
      ...state,
      isLoadingSituacaoEspecial: false,
      situacaoEspecial: action.situacaoEspecial
    }
  }

  return state;
};
