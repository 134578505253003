import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';

function ConfirmationDialogRaw({ onClose, value: valueProp, open, title, cancelText = "Cancelar", confirmText = "Adicionar", options = [{ label: '', value: '' }], ...other }) {
  const [value, setValue] = React.useState(valueProp);
  const [text, setText] = React.useState('');
  const radioGroupRef = React.useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
    setText('');
  };

  const handleOk = () => {
    onClose(value);
    setText('');
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSearchText = (event) => {
    setText(event.target.value);
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: "55vh" } }}
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      fullScreen={fullScreen}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers sx={{ height: 'fit-content', padding: '10px 12px', overflow: "visible" }}>
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            boxShadow: 'none',
            border: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: '1rem !important' }}
            onChange={handleSearchText}
            value={text}
          />
          <IconButton
            type="button"
            sx={{ p: "5px 10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </DialogContent>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.filter(x => { return x?.label?.toLowerCase()?.includes(text?.toLowerCase() ?? "") }).map((option, i) => (
            <FormControlLabel
              value={option.value}
              key={i}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button variant='contained' onClick={handleOk}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ConfirmationDialog({ title = "Produtos", cancelText = "Cancelar", confirmText = "Adicionar", options = [{ label: '', value: '' }], Node = Button, onChange = (value) => { }, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [value] = React.useState('');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <React.Fragment>
      <Node onClick={handleClickListItem} {...props}>
        {props.children}
      </Node>
      <ConfirmationDialogRaw
        keepMounted
        open={open}
        onClose={handleClose}
        value={value}
        options={options}
        title={title}
        cancelText={cancelText}
        confirmText={confirmText}
      />
    </React.Fragment>
  );
}