import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Divider, Typography, Stack } from '@mui/material';
import Success from '../assets/svg/SuccessIcon';
import Error from '../assets/svg/ErrorIcon';
import Info from '../assets/svg/InfoIcon';

export default function ErrorDialog({ open, onClose = () => { }, onCancel = () => { }, onConfirm = () => { }, canCancel = false, title = "", message = "", type = "" }) {
  var Icon = null;

  switch (type) {
    case 'success':
      Icon = Success;
      break;
    case 'error':
      Icon = Error;
      break;
    case 'info':
      Icon = Info;
      break;
    default:
      break;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {Icon != null &&
            <Icon
              sx={{
                height: "100px",
                width: "100px",
                color: type === 'success' ? '#0E6600' : type === 'error' ? '#910000' : 'var(--cort-blue)'
              }}
            />
          }
          <Stack direction={"column"} spacing={1}>
            <Typography sx={{ color: "#000", fontWeight: "bold", fontSize: "1.5rem" }}>{title}</Typography>
            <Typography sx={{ color: "#8d8d8d", fontSize: "0.9rem" }}>{message}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        {canCancel && <Button onClick={(e) => { onClose(e); onCancel(e); }} variant="text">
          Cancelar
        </Button>}
        <Button onClick={(e) => { onClose(e); onConfirm(e); }} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}