import React from "react";
import { useState } from "react";
import { ChromePicker } from "react-color";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { culturasWhitespaceActions } from "../../../../../actions/culturasWhitespace.actions.js";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Grid, Stack, TextField, Slider, FormControl, Paper } from "@mui/material";
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';

function FormEdit({
  culture = {},
  send = (culture) => { },
  isEdit,
  ...props }
) {
  const [cultureValue, setCulture] = React.useState(null);
  const [opacity, setOpacity] = React.useState(culture?.opacity ? culture.opacity : 1);

  const formik = useFormik({
    initialValues: culture,
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Insira um nome';
      }
      if (!values.value) {
        errors.value = 'Defina o valor da cultura';
      }
      if (!values.color) {
        errors.color = 'Insira uma cor';
      }
      if (!values.opacity) {
        errors.opacity = 'Defina a opacidade do polígono';
      }
      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },
  });

  // Handle opacity change
  const handleOpacityChange = (event, newValue) => {
    setOpacity(newValue);

    // Update Formik value
    formik.setFieldValue("opacity", newValue);
  };

  // Handle color change
  const handleCurrentColorChange = (color) => {
    formik.setFieldValue("color", color);
  };

  const ColorPickerButton = (props) => {
    const { color, setColor } = props;
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [currentColor, setCurrentColor] = useState(color ? color : "#000000");
    const [originalColor, setOriginalColor] = useState(color ? color : "#000000");

    const togglePicker = () => {
      setIsPickerOpen(!isPickerOpen);
      if (isPickerOpen) {
        setCurrentColor(originalColor);
      }
    };

    const handleColorChange = (color) => {
      setCurrentColor(color.hex);
    };

    const saveColorChange = () => {
      setOriginalColor(currentColor);
      setColor(currentColor);
      setIsPickerOpen(false);
    };

    const cancelColorChange = () => {
      setCurrentColor(originalColor);
      setIsPickerOpen(false);
    };

    return (
      <div>
        <Button fullWidth onClick={togglePicker} style={{ color: 'black' }} startIcon={<div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: currentColor }}></div>}>
          Cor
        </Button>
        {isPickerOpen && (
          <div style={{ position: 'absolute', zIndex: '999' }}>
            <Grid container spacing={2} sx={{ width: '242px' }}>
              <Grid item xs={12}>
                <ChromePicker
                  color={currentColor}
                  onChange={handleColorChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button fullWidth onClick={cancelColorChange} variant="contained" sx={{ backgroundColor: "#a60000" }}>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button fullWidth onClick={saveColorChange} variant="contained">
                      OK
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  };

  if (cultureValue !== culture) {
    setCulture(culture);
  }

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: "8px 0" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ width: "100% !important" }} direction="row" xl="auto" justifyContent="center" alignItems="flex-start" className="mt-4 mb-5">
        <Grid item xs={2} sm={8} md={4}>
          <TextField
            color={'azul'}
            fullWidth
            id={"name"}
            name={"name"}
            label={"Nome"}
            variant="standard"
            disabled={isEdit}
            value={formik.values["name"]}
            onChange={formik.handleChange}
            error={formik.touched["name"] && Boolean(formik.errors["name"])}
            helperText={formik.touched["name"] && formik.errors["name"]}
          />
        </Grid>
        <Grid item xs={2} sm={8} md={4}>
          <TextField
            color={'azul'}
            fullWidth
            disabled={isEdit}
            id={"value"}
            name={"value"}
            label={"Valor"}
            variant="standard"
            value={formik.values["value"]}
            onChange={formik.handleChange}
            error={formik.touched["value"] && Boolean(formik.errors["value"])}
            helperText={formik.touched["value"] && formik.errors["value"]}
          />
        </Grid>
        <Grid item xs={2} sm={8} md={2}>
          <ColorPickerButton color={formik.values["color"]} setColor={handleCurrentColorChange} />
        </Grid>
        <Grid item xs={2} sm={8} md={2}>
          <FormControl fullWidth alignItems={"center"}>
            <Typography id="opacity-slider" gutterBottom>
              Opacidade: {(opacity * 100).toFixed(0)}%
            </Typography>
            <Slider
              color={'azul'}
              value={opacity}
              min={0}
              max={1}
              step={0.01}
              onChange={handleOpacityChange}
              aria-labelledby="opacity-slider"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Button color="azul" variant="contained" fullWidth type="submit">
        Salvar
      </Button>
    </Box>
  );
}

class CultureEdit extends React.Component {
  constructor(props) {
    super(props);

    this.isEdit = false;
    this.state = {};
    this.sendCulture = this.sendCulture.bind(this);
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.CultureId !== undefined && params.CultureId !== '') {
      this.props.actions.getCulture(params.CultureId);
      this.setState({ isEdit: true });
    }
  }

  componentWillUnmount() {
    this.props.actions.cleanCulture();
  }

  sendCulture(culture) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.CultureId !== undefined && params.CultureId !== '') {
      this.props.actions.setCulture(params.CultureId, culture, navigate);
    }
    else {
      this.props.actions.saveCulture(culture, navigate);
    }
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var isLoading = this.props.culturasWhitespace.isLoading;
    var isDataLoad = this.props.culturasWhitespace;

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Whitespace"}>
                Whitespace
              </Link>
              <Link underline="hover" color="inherit" to={"/Admin-Culture"}>
                Culturas
              </Link>
              <Typography color="text.primary">{(params.CultureId !== undefined && params.CultureId !== '') ? "Editar" : "Novo"}</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {!isLoading && isDataLoad &&
            <FormEdit
              culture={this.props.culturasWhitespace.culture ?? {}}
              send={this.sendCulture}
              isEdit={this.state.isEdit}
            />
          }
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...culturasWhitespaceActions,
      }, dispatch),
    };
  }
)(CultureEdit);