const requestModulosType = "REQUEST_MODULOS";
const receiveModulosType = "RECEIVE_MODULOS";
const requestModuloType = "REQUEST_MODULO";
const receiveModuloType = "RECEIVE_MODULO";

const initialState = { modulos: [], modulo: null, isLoading: false, isLoadingModulo: false };

export const adminModuloActions = {
  clearModulo: () => async (dispatch, getState) => {
    dispatch({ type: receiveModuloType, modulo: {} });
  },
  requestModulos: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestModulosType });

    const url = `/api/admin/modulo`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const modulos = await response.json();
    dispatch({ type: receiveModulosType, modulos });
  },
  requestModulo: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestModuloType });

    const url = `/api/admin/modulo/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const modulo = await response.json();
    dispatch({ type: receiveModuloType, modulo });
  },
  updateModulo: (id, moduloEdit, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestModuloType });

    const url = `/api/admin/modulo/${id}`;

    const options = {
      method: "PUT",
      body: JSON.stringify(moduloEdit),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const modulo = await response.json();
      navigate('/Admin-Modulo')
      dispatch({ type: receiveModuloType, modulo: null });
      dispatch(adminModuloActions.requestModulos());
    }
  },
  saveModulo: (moduloEdit, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestModuloType });

    const url = `/api/admin/modulo`;

    const options = {
      method: "POST",
      body: JSON.stringify(moduloEdit),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const modulo = await response.json();
      navigate('/Admin-Modulo');
      dispatch({ type: receiveModuloType, modulo: null });
      dispatch(adminModuloActions.requestModulos());
    }
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestModulosType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveModulosType) {
    return {
      ...state,
      modulos: action.modulos,
      isLoading: false,
    };
  }
  if (action.type === requestModuloType) {
    return {
      ...state,
      isLoadingModulo: true,
    };
  }
  if (action.type === receiveModuloType) {
    return {
      ...state,
      modulo: action.modulo,
      isLoadingModulo: false,
    };
  }
  return state;
};
