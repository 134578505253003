import { alertConstants } from "../constants";

const callAlert = "CALL_ALERT";
const closeAlert = "CLOSE_ALERT";

const initialState = {
  alertOpen: false,
  alertType: "",
  alertTitle: "",
  alertMessage: ""
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

export const AlertActions = {
  success,
  error,
  clear,
  closeAlert: () => async (dispatch, getState) => {
    dispatch({ type: closeAlert });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case callAlert:
      return {
        ...state,
        alertOpen: true,
        alertType: action.responseType,
        alertTitle: action.title,
        alertMessage: action.message
      }
    case closeAlert:
      return {
        ...state,
        alertOpen: false,
        alertType: "",
        alertTitle: "",
        alertMessage: ""
      }
    default:
      return state;
  }
};
