// import { history } from "../helpers";

const requestGetCenarioType = "REQUEST_GetCenario";
const receiveGetCenarioType = "RECEIVE_GetCenario";


const initialState = { GetCenario: [], isLoading: false };

export const GetCenarioActions = {
  GetCenario: (cenarioId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetCenarioType });

    const url = `/api/getcenario/${cenarioId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const GetCenario = await response.json();
    dispatch({ type: receiveGetCenarioType, GetCenario });
  },
  CloneCenario: (cenarioId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetCenarioType });

    const url = `/api/cenario/clone/${cenarioId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const GetCenario = await response.json();

    dispatch({ type: receiveGetCenarioType, GetCenario });
  },
  setCenario: (GetCenario) => async (dispatch, getState) => {
    dispatch({ type: receiveGetCenarioType, GetCenario });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestGetCenarioType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveGetCenarioType) {
    return {
      ...state,
      GetCenario: action.GetCenario,
      isLoading: false,
    };
  }

  return state;
};
