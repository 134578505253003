import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AllowUseNavigate, { navigate } from '../../../Navigate.jsx';
import { adminStockActions } from "../../../../actions/admin/stock.actions.js";

import dayjs from 'dayjs';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, Stack, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

dayjs.locale('pt-br');

function FormEdit({
  period = {},
  send = (period) => { },
  ...props }
) {
  const [periodConst, setPeriod] = React.useState(null);

  const formik = useFormik({
    initialValues: period,
    validate: (values) => {
      const errors = {};
      if (!values.ano) {
        errors.ano = 'Insira um Ano';
      }
      if (!values.trimestre) {
        errors.trimestre = 'Insira um Mês';
      }
      if (!values.dataInicio) {
        errors.dataInicio = 'Insira uma data incial';
      }
      if (!values.dataFim) {
        errors.dataFim = 'Insira uma data final';
      }
      if (values.dataFim && values.segundaEtapa && values.dataFim <= values.segundaEtapa) {
        errors.dataFim = 'A data fim deve ser posterior à segunda etapa';
      }
      if (values.segundaEtapa && values.dataInicio && values.segundaEtapa <= values.dataInicio) {
        errors.segundaEtapa = 'A segunda etapa deve ser posterior à data de início';
      }
      if (values.dataFim && values.dataInicio && values.dataFim <= values.dataInicio) {
        errors.dataFim = 'A data fim deve ser posterior à data de início';
      }
      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },
  });

  useEffect(() => {
    if (periodConst !== period) {
      setPeriod(period);
    }
  }, [setPeriod, period, periodConst]);

  // const formatBoolean = (value) => {
  //   return value ? 'Sim' : 'Não';
  // };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: "8px 0" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        sx={{ width: "100% !important" }}
        direction="row-reverse"
        xl="auto"
        justifyContent="center"
        alignItems="flex-start"
        className="mt-4 mb-5">
        <Grid item xs={2} sm={8} md={12}>
          <TextField
            color={'azul'}
            fullWidth
            id={"ano"}
            name={"ano"}
            label={"Ano"}
            variant="standard"
            value={formik.values["ano"]}
            onChange={formik.handleChange}
            error={formik.touched["ano"] && Boolean(formik.errors["ano"])}
            helperText={formik.touched["ano"] && formik.errors["ano"]}
            disabled={true}
          />
        </Grid>
        <Grid item xs={2} sm={8} md={12}>
          <TextField
            color={'azul'}
            fullWidth
            id={"trimestre"}
            name={"trimestre"}
            label={"Mês"}
            variant="standard"
            value={formik.values["trimestre"]}
            onChange={formik.handleChange}
            error={formik.touched["trimestre"] && Boolean(formik.errors["trimestre"])}
            helperText={formik.touched["trimestre"] && formik.errors["trimestre"]}
          />
        </Grid>
        <Grid item xs={2} sm={8} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id="porRaiz"
                name="porRaiz"
                checked={formik.values.porRaiz}
                onChange={formik.handleChange}
              />
            }
            label="Por Raiz"
          />
          <FormHelperText error={formik.touched.porRaiz && Boolean(formik.errors.porRaiz)}>
            {formik.touched.porRaiz && formik.errors.porRaiz}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            fullWidth
            format="DD/MM/YYYY"
            openTo="year"
            color="azul"
            id={"dataInicio"}
            name={"dataInicio"}
            label={"Data Inicio | Preenchimento RC/RTV"}
            variant="standard"
            sx={{ width: "100%" }}
            value={formik.values["dataInicio"] ? dayjs(formik.values["dataInicio"]) : null}
            onChange={(newValue) =>
              formik.setFieldValue("dataInicio", newValue ? dayjs(newValue) : null)
            }
            error={formik.touched["dataInicio"] && Boolean(formik.errors["dataInicio"])}
            helperText={formik.touched["dataInicio"] && formik.errors["dataInicio"]}
            views={['year', 'month', 'day']}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            fullWidth
            format="DD/MM/YYYY"
            openTo="year"
            color="azul"
            id={"segundaEtapa"}
            name={"segundaEtapa"}
            label={"Data Inicio | Preenchimento DSM"}
            variant="standard"
            sx={{ width: "100%" }}
            value={formik.values["segundaEtapa"] ? dayjs(formik.values["segundaEtapa"]) : null}
            onChange={(newValue) =>
              formik.setFieldValue("segundaEtapa", newValue ? dayjs(newValue) : null)
            }
            error={formik.touched["segundaEtapa"] && Boolean(formik.errors["segundaEtapa"])}
            helperText={formik.touched["segundaEtapa"] && formik.errors["segundaEtapa"]}
            views={['year', 'month', 'day']}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            fullWidth
            format="DD/MM/YYYY"
            openTo="year"
            color="azul"
            id={"dataFim"}
            name={"dataFim"}
            label={"Data Fim"}
            variant="standard"
            sx={{ width: "100%" }}
            value={formik.values["dataFim"] ? dayjs(formik.values["dataFim"]) : null}
            onChange={(newValue) =>
              formik.setFieldValue("dataFim", newValue ? dayjs(newValue) : null)
            }
            error={formik.touched["dataFim"] && Boolean(formik.errors["dataFim"])}
            helperText={formik.touched["dataFim"] && formik.errors["dataFim"]}
            views={['year', 'month', 'day']}
          />
        </Grid>
      </Grid>
      <Button color="azul" variant="contained" fullWidth type="submit">
        Salvar
      </Button>
    </Box >
  );
}

class SafraEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0
    };

    this.sendPeriod = this.sendPeriod.bind(this);
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.PeriodId !== undefined && params.PeriodId !== '') {
      this.props.actions.requestPeriod(params.PeriodId);
      this.setState({ id: params.PeriodId });
    }
  }

  componentWillUnmount() {
    this.props.actions.clean();
  }

  sendPeriod(period) {
    if (this.state.id !== undefined && this.state.id !== '')
      this.props.actions.updatePeriod(this.state.id, period, navigate);
  }

  render() {
    const periodId = this.state.PeriodId;
    var isLoading = this.props.adminStocks.isLoading;
    var isDataLoad = this.props.adminStocks;

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Stock"}>
                Estoque
              </Link>
              <Typography color="text.primary">{(periodId !== undefined && periodId !== '') ? "Novo" : "Editar"} Período</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {!isLoading && isDataLoad &&
            <FormEdit
              period={this.props.adminStocks.period ?? {}}
              send={this.sendPeriod}
            />
          }
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminStockActions,
      }, dispatch),
    };
  }
)(SafraEdit);