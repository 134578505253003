import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AlertActions } from "../actions/alert.actions"

import NavBar from "./Navbar";
import configure from "../util/configureSignalR";
import SimpleBoxError from "./SimpleBoxError";
let connection = null

class Layout extends React.Component {
  componentWillMount() {
    const { oidc } = this.props;
    if (oidc && oidc?.user && oidc.user.access_token) {
      connection = configure(oidc);
    }
  }
  render() {
    const { oidc } = this.props;
    const userLoggedIn = oidc.user;
    return (
      <div>
        {userLoggedIn && (
          <div>
            <SimpleBoxError
              open={this.props.alert.alertOpen}
              onClose={this.props.actions.closeAlert}
              type={this.props.alert.alertType}
              title={this.props.alert.alertTitle}
              message={this.props.alert.alertMessage}
            />

            <NavBar oidc={oidc} connection={connection} />
            <div id="main-content" className="container-fluid">
              {this.props.children}
            </div>
          </div>
        )}
        {!userLoggedIn && this.props.children}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    oidc: state.oidc,
    alert: state.alert
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...AlertActions,
    },
    dispatch
  ),
});

const connectedDefaultPage = connect(mapStateToProps, mapDispatchToProps)(Layout);
export { connectedDefaultPage as Layout };
