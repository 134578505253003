import * as React from 'react';

import {
  Button, Drawer, Box,
  Divider, ListItem,
  ListItemIcon, ListItemText,
  Typography,
  Avatar,
  List
}
  from '@mui/material';

import MailIcon from "@mui/icons-material/Mail";
import { Stack } from 'react-bootstrap';

export default function InformationDrawer() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const list = () => (
    <Box
      sx={{ width: 550 }}
      onClick={() => handleClose()}
      onKeyDown={() => handleClose()}
    >
      <List>
        {[
          "Obrigado por dedicar seu tempo a essa grande prioridade da Corteva! Fizemos o máximo possível para que seja a experiência mais fácil e rápida.",
          "Algumas informações para ajudar:",
        ].map((text, index) => (
          <ListItem key={text}>
            <ListItemText
              primary={text}
              sx={{ textAlign: index === 0 ? "center" : "start" }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          "Os dados são sempre exibidos com base no Sell In, Sell Out e Estoques do ÚLTIMO MÊS.",
          "Quando só confirmado os dados de Estoque Físico/Estoque Disponível, o Estoque Integra será utilizado.",
          //"O Saldo Estoque é cálculado atráves da seguinte fórmula: Estoque Disponivel (Anterior) + Sell In Corteva - Sell Out.",
          //"É importante considerar o atraso temporal entre os números de Sell In, Sell Out & Estoques (Exemplo: Grande Sell In de Fungicidas em Out/Nov com picos de escoamento Nov/Dez, a depender do contexto da safra, timings de aplicações entre outros fatores).",
          //"A presença e condições dos dados Sell In Integra, Sell Out Integra e 
          "Estoques  Integra (Físico/Disponível)  é dependente da instalação, configuração e situação do extrator de dados do Integra.",
          "Ao preencher os dados se certifique de todo e qualquer preenchimento, correção e validação. A responsabilidade dos dados é compartilhada com todos os agentes envolvidos neste processo.",
          "Ao preencher os dados, procure estar sempre alicerçado em evidências, reports ou documentos obtidos diretamente com o parceiro comercial da Corteva.",
          "Os dados devem ser preenchidos pensando nos estoques a nível de Raiz CNPJ nos Estados.",
          //"Os dados de município e CNPJ são ilustrativos e fazem referência aos dados da matriz do canal. Os dados NÃO ESTÃO a nível de filial."
        ].map((text, index) => (
          <ListItem key={text.split("-")[0]}>
            <ListItemIcon>
              <Avatar
                sx={{ bgcolor: "var(--cort-blue)", width: 30, height: 30 }}
              >
                {index + 1}
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={text.split("–")[0]}
              secondary={
                text.split("–")[1] && (
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    ></Typography>
                    {text.split("–")[1]}
                  </React.Fragment>
                )
              }
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["Dúvidas ou Problemas: "].map((text, index) => (
          <ListItem key={text}>
            <ListItemText
              primary={text}
              sx={{ textAlign: "center" }}
              secondary={
                <React.Fragment>
                  <Stack direction='column'>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <MailIcon /> BA Sul | ba.sul@corteva.com
                    </Typography>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <MailIcon /> BA Norte | ba.norte@corteva.com
                    </Typography>
                  </Stack>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Button
        fullWidth
        variant='outlined'
        sx={{ fontWeight: "bold", outline: "2px solid", '&:hover': { outline: "2px solid" } }}
        onClick={() => handleClickOpen()}
      >
        Sobre o Módulo
      </Button>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={() => handleClose()}
      >
        {list()}
      </Drawer>
    </React.Fragment >
  );
}