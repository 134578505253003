import React from "react";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ConfirmationDialog from "./CustomSelect";
import { Stack, Tooltip } from "@mui/material";

export default function TerritorysPagination(props) {
  const { territory, addData,
    changeLoadState,
    removeData } = props;

  const onChange = (newValue = [], oldValue = []) => {
    var removeValues = oldValue.filter(x => !newValue.includes(x));
    var addValues = newValue.filter(x => !oldValue.includes(x));

    removeValues.forEach(value => {
      removeData(value);
    });

    if (addValues.length > 0) {
      changeLoadState(true);
      addData(addValues);
    }
  }

  return (
    <ConfirmationDialog
      variant="contained"
      options={territory}
      onChange={onChange}
      sx={{
        position: "absolute",
        top: "115px",
        left: "310px",
        textAlign: "center",
        padding: "8px",
        borderRadius: "8px",
        minWidth: "56px",
        width: "56px",
        height: "35px",
        color: "var(--cort-blue)",
        fontSize: "0.8rem",
        lineHeight: "1rem",
        fontWeight: "bold",
        zIndex: (theme) => theme.zIndex.drawer,
      }}
    >
      <Tooltip title={"Adicionar Municipio"}>
        <TravelExploreIcon sx={{
          borderRadius: "50%",
          width: "34px",
          height: "34px",
          padding: "4px",
          color: "white",
        }} />
      </Tooltip>
    </ConfirmationDialog>
  );
}