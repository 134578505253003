import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminWhitespaceActions } from "../../../../../actions/admin/whitespace.actions.js";

import dayjs from 'dayjs';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Grid, Stack, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function FormEdit({
  safra = {},
  send = (safra) => { },
  ...props }
) {
  const [safraConst, setSafra] = React.useState(null);

  const formik = useFormik({
    initialValues: safra,
    validate: (values) => {
      const errors = {};
      if (!values.nome) {
        errors.nome = 'Insira um nome';
      }
      if (!values.dataInicio) {
        errors.dataInicio = 'Insira uma data incial';
      }
      if (!values.dataFim) {
        errors.dataFim = 'Insira uma data final';
      }
      if (dayjs(values.dataFim).isBefore(values.dataInicio, 'day')) {
        errors.dataFim = 'A data final deve ser maior que a data inicial';
      }
      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },
  });

  if (safraConst !== safra) {
    setSafra(safra);
  }

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: "8px 0" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ width: "100% !important" }} direction="row-reverse" xl="auto" justifyContent="center" alignItems="flex-start" className="mt-4 mb-5">
        <Grid item xs={2} sm={8} md={12}>
          <TextField
            color={'azul'}
            fullWidth
            id={"nome"}
            name={"nome"}
            label={"Nome"}
            variant="standard"
            value={formik.values["nome"]}
            onChange={formik.handleChange}
            error={formik.touched["nome"] && Boolean(formik.errors["nome"])}
            helperText={formik.touched["nome"] && formik.errors["nome"]}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-BR">
            <DatePicker
              fullWidth
              format="DD/MM/YYYY"
              openTo="year"
              color="azul"
              id={"dataFim"}
              name={"dataFim"}
              label={"Data Fim"}
              variant="standard"
              sx={{ width: "100%" }}
              value={formik.values["dataFim"] ? dayjs(formik.values["dataFim"]) : null}
              onChange={(newValue) =>
                formik.setFieldValue("dataFim", newValue ? dayjs(newValue) : null)
              }
              error={formik.touched["dataFim"] && Boolean(formik.errors["dataFim"])}
              helperText={formik.touched["dataFim"] && formik.errors["dataFim"]}
              views={['year', 'month', 'day']}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} sm={4} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-BR">
            <DatePicker
              fullWidth
              format="DD/MM/YYYY"
              openTo="year"
              color="azul"
              id={"dataInicio"}
              name={"dataInicio"}
              label={"Data Início"}
              variant="standard"
              sx={{ width: "100%" }}
              value={formik.values["dataInicio"] ? dayjs(formik.values["dataInicio"]) : null}
              onChange={(newValue) =>
                formik.setFieldValue("dataInicio", newValue ? dayjs(newValue) : null)
              }
              error={formik.touched["dataInicio"] && Boolean(formik.errors["dataInicio"])}
              helperText={formik.touched["dataInicio"] && formik.errors["dataInicio"]}
              views={['year', 'month', 'day']}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} sm={4} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={formik.values["ativa"]}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                value={formik.values["ativa"]}
                onChange={(event) => formik.setFieldValue("ativa", event.target.checked)}
              />
            }
            id={"ativa"}
            name={"ativa"}
            label={"Ativa"}
            variant="standard"
            error={formik.touched["ativa"] && Boolean(formik.errors["ativa"])}
            helperText={formik.touched["ativa"] && formik.errors["ativa"]}
          />
        </Grid>
      </Grid>
      <Button color="azul" variant="contained" fullWidth type="submit">
        Salvar
      </Button>
    </Box>
  );
}

class SafraEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.sendSafra = this.sendSafra.bind(this);
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.SafraId !== undefined && params.SafraId !== '')
      this.props.actions.requestSafra(params.SafraId);
  }

  componentWillUnmount(){
    this.props.actions.cleanSafra();
  }

  sendSafra(safra) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.SafraId !== undefined && params.SafraId !== '')
      this.props.actions.updateSafra(params.SafraId, safra, navigate);
    else
      this.props.actions.saveSafra(safra, navigate);
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var isLoading = this.props.adminWhitespace.isLoading;
    var isDataLoad = this.props.adminWhitespace;

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Whitespace"}>
                Whitespace
              </Link>
              <Link underline="hover" color="inherit" to={"/Admin-Safra"}>
                Safras
              </Link>
              <Typography color="text.primary">{(params.SafraId !== undefined && params.SafraId !== '') ? "Editar" : "Novo"}</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {!isLoading && isDataLoad &&
            <FormEdit
              safra={this.props.adminWhitespace.safra ?? {}}
              send={this.sendSafra}
            />
          }
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminWhitespaceActions,
      }, dispatch),
    };
  }
)(SafraEdit);