import { cenariosActions } from "./cenario.actions";
import { simulatorActions } from "./simulator.actions";

const requestSaveGenerateCenarioType = "REQUEST_SaveGenerateCenario";
const receiveSaveGenerateCenarioType = "RECEIVE_SaveGenerateCenario";
const initialState = { SaveGenerateCenario: [], isLoading: false };

export const SaveGenerateCenarioActions = {
  createSaveGenerateCenario: (cenarioId, campanha, condicao, vencimento) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })

    // console.log(rows, getState())

    dispatch({ type: requestSaveGenerateCenarioType });
    // console.log("SAVE GENERATE CENARIO")
    // console.log(cenarioId)
    const url = `/api/cenariogeradoitems/${cenarioId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const SaveGenerateCenario = await response.json();
    dispatch({ type: receiveSaveGenerateCenarioType, SaveGenerateCenario });

  },

  createSaveGenerateCenarioAndClear: (cenarioId, campanha, condicao, vencimento, clienteId) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })

    // console.log(rows, getState())

    dispatch({ type: requestSaveGenerateCenarioType });
    // console.log("SAVE GENERATE CENARIO")
    // console.log(cenarioId)
    const url = `/api/cenariogeradoitems/${cenarioId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    try {
      const SaveGenerateCenario = await response.json();
      dispatch({ type: receiveSaveGenerateCenarioType, SaveGenerateCenario });
    } catch {

    }

    dispatch(simulatorActions.removeAllRows())
    dispatch(cenariosActions.requestCenarios(clienteId))
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestSaveGenerateCenarioType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveSaveGenerateCenarioType) {
    return {
      state: initialState,
      SaveGenerateCenario: action.SaveGenerateCenario,
      isLoading: false,
    };
  }

  return state;
};
