import React from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { ListItem, Paper, Tooltip } from "@mui/material";
import Grid from '@mui/material/Grid';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { isNullOrUndefined } from "../../../../helpers/utils";

const aggregateCultures = (cultureArray) =>
    cultureArray.reduce((agg, item) => {
        if (!agg[item.culture]) {
            agg[item.culture] = { area: 0, color: item.color || "" };
        }
        agg[item.culture].area += item.area;
        return agg;
    }, {});

function Row(props) {
    const { row, drawR, removeList, goToPolygon, showingStates, valueAreaPlot, cultures } = props;
    const [open, setOpen] = React.useState(false);

    let cultureA = [];
    let cultureP = [];

    let pointedCultureA = [];
    let pointedCultureP = [];

    let stateCultures = null;

    if (showingStates) {
        stateCultures = JSON.parse(row.properties.TalhoesCulturas);
        if (!isNullOrUndefined(stateCultures)) {
            let newCultureA = stateCultures.uniqueA.map(x => {
                let cultureName = "";
                if (x.Culture == "Empty") {
                    cultureName = "Vazio";
                }
                else {
                    cultureName = cultures.find(y => y.value == x.Culture).name;
                }

                return { culture: cultureName, area: x.TotalArea, color: '' }
            });

            let newCultureP = stateCultures.uniqueP.map(x => {
                let cultureName = "";
                if (x.Culture == "Empty") {
                    cultureName = "Vazio";
                }
                else {
                    cultureName = cultures.find(y => y.value == x.Culture).name;
                }

                return { culture: cultureName, area: x.TotalArea, color: '' }
            });

            cultureA.push(...newCultureA);
            cultureP.push(...newCultureP);
        }

        pointedCultureA = aggregateCultures(cultureA);
        pointedCultureP = aggregateCultures(cultureP);
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                <TableCell sx={{ padding: "0" }} align="center">
                    <IconButton sx={{ padding: "0" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ fontSize: "70%", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 6px" }}>
                    {!showingStates &&
                        row.properties.COD_TALHAO
                    }
                    {showingStates &&
                        row.properties.Nome_Munic
                    }
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "70%", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 16px" }}>
                    {Number(Math.round(row.properties.Area)).toLocaleString("pt-br") + " ha"}
                </TableCell>
                <TableCell align="center" >
                    <IconButton sx={{ padding: "0" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => removeList(row, false, drawR)}
                    >
                        <CloseIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" sx={{ 'td': { border: 'none' } }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            {!showingStates && (
                                                <>
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Cliente: " + (!isNullOrUndefined(row.properties.ACCOUNT) ? row.properties.ACCOUNT : "Sem Dono")} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem", fontWeight: "bold" } }} primary={"Culturas"}/>
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Classificada: " + (!isNullOrUndefined(row.properties.CULT_PREDIT) ? cultures.find(x => x.value == row.properties.CULT_PREDIT).name : "Nenhuma")} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Apontada: " + (!isNullOrUndefined(row.properties.CULT_APONT) ? cultures.find(x => x.value == row.properties.CULT_APONT).name : "Nenhuma")} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Data Plantio: " +
                                                        (!isNullOrUndefined(row.properties.CULT_PLANT_DATE) ?
                                                            new Date(row.properties.CULT_PLANT_DATE).toLocaleDateString('pt-BR', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                            }) : "Nenhuma")} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Data Colheita: " +
                                                        (!isNullOrUndefined(row.properties.CULT_HARVEST_DATE) ?
                                                            new Date(row.properties.CULT_HARVEST_DATE).toLocaleDateString('pt-BR', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                            }) : "Nenhuma")} />

                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"É Campo de produção de semente: " +
                                                        (!isNullOrUndefined(row.properties.SEMENTE) ? row.properties.SEMENTE : "Não definido")} />

                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"É Irrigado: " +
                                                        (!isNullOrUndefined(row.properties.IRRIGADO) ? row.properties.IRRIGADO : "Não definido")} />
                                                </>
                                            )}
                                            {showingStates && (
                                                <>
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Estado Sigla: " + (row.properties.SIGLA)} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Total de Talhões: " + Math.round(row.properties.ContagemTalhoes).toLocaleString("pt-br")} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Área Talhões: " + (Math.round(row.properties.AreaTotalTalhoes)).toLocaleString("pt-br") + " ha"} />

                                                    <ListItemText
                                                        sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }}
                                                        primary={
                                                            "Talhões Atribuídos: "
                                                            + (Math.round(row.properties.TalhoesConhecidas)).toLocaleString("pt-br")
                                                            + " ("
                                                            + (Number((row.properties.TalhoesConhecidas / row.properties.ContagemTalhoes) * 100)).toFixed(1).toLocaleString("pt-br")
                                                            + "%)"}
                                                    />
                                                    <ListItemText
                                                        sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }}
                                                        primary={
                                                            "Área Talhões Atribuídos: "
                                                            + (Math.round(row.properties.AreaTotalTalhoesConhecidas)).toLocaleString("pt-br")
                                                            + " ha ("
                                                            + (Number(row.properties.AreaTotalTalhoesConhecidas / row.properties.AreaTotalTalhoes) * 100).toFixed(1).toLocaleString("pt-br")
                                                            + "%)"}
                                                    />
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell >
                                            <Stack direction="row" spacing={2}>
                                                <ListItem>
                                                    <Tooltip title="Centralizar no Polígono">
                                                        <IconButton aria-label="Centralizar no Polígono"
                                                            onClick={() => goToPolygon(row)}
                                                        >
                                                            <GpsFixedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItem>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    {showingStates && (
                                        <TableRow sx={{ padding: '8px 0px' }}>
                                            <TableCell colSpan={4}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ 'td': { border: 'none' } }}>
                                                                <TableHead sx={{ backgroundColor: "var(--cort-blue)", 'th': { padding: '8px' } }}>
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white" }}>
                                                                            Cultura Apontada
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody sx={{ display: "flex !important", flexFlow: "column", overflowY: "auto", maxHeight: "80px" }}>
                                                                    {Object.keys(pointedCultureA).map((key, index) => {
                                                                        return (
                                                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                                                                <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                                                                    {key + ": " + Number(Math.round(pointedCultureA[key].area)).toLocaleString("Pt-Br") + " ha"}
                                                                                    {" (" + Number((pointedCultureA[key].area / valueAreaPlot) * 100).toFixed(1).toLocaleString("Pt-Br") + "%)"}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer >
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <TableContainer component={Paper}>
                                                            <Table aria-label="collapsible table">
                                                                <TableHead sx={{ backgroundColor: "var(--cort-blue)", 'th': { padding: '8px' } }}>
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white" }}>
                                                                            Cultura Classificada
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody sx={{ display: "flex !important", flexFlow: "column", overflowY: "auto", maxHeight: "80px" }}>
                                                                    {Object.keys(pointedCultureP).map((key, index) => {
                                                                        return (
                                                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                                                                <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                                                                    {key + ": " + Number(Math.round(pointedCultureP[key].area)).toLocaleString("Pt-Br") + " ha"}
                                                                                    {" (" + Number((pointedCultureP[key].area / valueAreaPlot) * 100).toFixed(1).toLocaleString("Pt-Br") + "%)"}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer >
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

export default function MapPanel(props) {
    const { data, drawR, inEdit, saveArea, setEdit, remove, removePolygon, cancel, cleanList, goToPolygon, copySourceCodes, valueAreaPlot, showingStates, cultures } = props;

    return (
        <React.Fragment>
            <TableContainer sx={{ borderRadius: "4px 4px 0px 0px" }}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                        <TableRow>
                            <TableCell align="center" sx={{ padding: "0px" }}>
                                <Tooltip title={"Copiar códigos dos polígonos"}>
                                    <IconButton sx={{ padding: "0", color: "white" }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => copySourceCodes(true)}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 6px" }}>
                                Nome
                            </TableCell>
                            <TableCell align="right" sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 16px" }}>
                                Área Total
                            </TableCell>
                            <TableCell align="center" sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 16px" }}>
                                <Tooltip title={"Remover Todas Seleções"}>
                                    <IconButton sx={{ padding: "0", color: "white" }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => cleanList("plot")}
                                    >
                                        <PlaylistRemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer sx={{ minHeight: "280px", maxHeight: "280px", overflowX: "hidden", borderRadius: "0px 0px 4px 4px" }}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {data && data.map((data, index) => (
                            <Row key={index}
                                row={data}
                                drawR={drawR}
                                inEdit={inEdit}
                                goToPolygon={goToPolygon}
                                removeList={remove}
                                cancel={cancel}
                                setEdit={setEdit}
                                saveArea={saveArea}
                                removePolygon={removePolygon}
                                showingStates={showingStates}
                                valueAreaPlot={valueAreaPlot}
                                cultures={cultures}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}