import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdvancedAnayticsImage from "../assets/images/advancedAnalytics.png";
import MenuIcon from '@mui/icons-material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { Badge, ClickAwayListener, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, Popper, Stack } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import configure from "../util/configureSignalR";
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import CloseIcon from '@mui/icons-material/Close';
import CortevaLogo from "../assets/svg/CortevaLogo/CortevaLogo";
import CortevaTextLogo from "../assets/svg/CortevaLogo/CortevaTextLogo";

const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

const isThisYear = (someDate) => {
  const today = new Date()
  return someDate.getFullYear() === today.getFullYear()
}

function NavBar({ oidc, connection = configure(), closeDrawer = undefined }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [messages, setMessage] = React.useState([]);
  React.useEffect(() => {
    connection?.on("refreshAll", message => {
      var obj = JSON.parse(message);
      setMessage(obj);
    });

    connection?.on("reciveNew", message => {
      var obj = JSON.parse(message);
      setMessage([...messages, obj]);
    });
  }, [connection, messages]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const removeNotificacao = (id, i) => {
    setMessage(messages.filter((x, index) => index !== i));
    connection?.invoke("removeMyNotifications", id)
  }

  const removerTudo = () => {
    setMessage([]);
    connection?.invoke("removeAllMyNotifications")
  }

  const countNotificacao = messages.reduce((total, x) => { if (!x.Visualizado) return total + 1; return total; }, 0);

  const userLoggedIn = oidc?.user?.profile?.roles?.find(x => x === "admin") !== undefined;

  return (
    <AppBar position="static" color='whiteB'>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Toolbar disableGutters sx={{ minHeight: "59px !important" }}>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              ml: "6px",
              display: { xs: 'none', sm: 'none', md: 'flex' },
              alignItems: 'center',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <CortevaLogo sx={{ fontSize: "2rem", color: "var(--cort-blue)" }} />
            <CortevaTextLogo sx={{ fontSize: "1.7rem", marginLeft: "0.5rem", width: "auto", color: "black" }} />
          </Typography>
          {closeDrawer &&
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={closeDrawer}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

            </Box>
          }
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              ml: "6px",
              display: { xs: 'flex', sm: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {/* <img src={CortevaImage} height="30" alt="Customer 360" /> */}
            <CortevaLogo sx={{ fontSize: "2rem", color: "var(--cort-blue)" }} />
            <CortevaTextLogo sx={{ fontSize: "1.7rem", marginLeft: "0.5rem", width: "auto", color: "black" }} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' } }}>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <PopupState variant="popper" popupId="demo-popup-popover">
              {(popupState) => {
                const { onClick, ...Toggle } = bindToggle(popupState)
                return (
                  <>
                    <IconButton {...Toggle}
                      onClick={(e) => { onClick(e); connection?.invoke("viewAllMyNotifications"); }}
                      size="large"
                      color="inherit"
                    >
                      <Badge badgeContent={countNotificacao} variant={(countNotificacao === 0 && messages.length > 0) ? "dot" : "standart"} showZero={(countNotificacao === 0 && messages.length > 0)} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>

                    <Popper
                      {...bindPopper(popupState)}
                      placement="bottom-end"
                      disablePortal={false}
                      sx={{ zIndex: 999 }}
                    >
                      <ClickAwayListener onClickAway={popupState.close}>
                        <Paper
                          sx={{
                            width: 'clamp(100px,100vw,360px)',
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5
                          }}
                        >
                          <ListItem secondaryAction={<IconButton onClick={popupState.close}><CloseIcon /></IconButton>}>
                            <ListItemText primaryTypographyProps={{ variant: "h6" }} primary={"Notificações"} />
                          </ListItem>

                          {messages.length > 0 && <Typography onClick={removerTudo} component={"a"} href="#" variant="caption" display={"block"} align="right" sx={{ mr: "8px", textDecoration: "underline !important" }} >
                            Remover tudo
                          </Typography>}
                          <Divider />
                          <List sx={{
                            maxHeight: "447px",
                            overflow: "auto",
                            paddingTop: 0,
                          }}>
                            {messages.map((x, i) => {
                              var date = new Date(x.DataDeEnvio);
                              var displayDate = isToday(date) ? date.toLocaleTimeString("pt-BR", { hour: '2-digit', minute: '2-digit' }) : isThisYear(date) ? date.toLocaleDateString("pt-BR", { day: "2-digit", month: "short" }) : date.toLocaleDateString("pt-BR", { dateStyle: "short" });

                              var redirect = x.RedirectUrl;
                              if (redirect === undefined || redirect == null || redirect === "")
                                redirect = window.location.href.replace(window.location.origin, '');

                              return (
                                <React.Fragment key={i}>
                                  <ListItem >
                                    <Stack
                                      direction="column"
                                      justifyContent="flex-start"
                                      alignItems="flex-end"
                                      spacing={0}
                                      sx={{ width: "100%" }}
                                    >
                                      <ListItem sx={{ p: "0px !important", width: "100%" }}>
                                        <ListItemIcon>
                                          <NotificationsIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={
                                            <Stack
                                              direction="row"
                                              justifyContent="space-between"
                                              alignItems="center"
                                              spacing={0}
                                              sx={{ width: "100%" }}
                                            >
                                              <Typography component={Link} to={redirect} sx={{ color: "#000", ":hover": { color: "inherit !important" } }}>
                                                {x.Titulo}
                                              </Typography>
                                              <IconButton onClick={(e) => removeNotificacao(x.Id, i)} >
                                                <CloseIcon />
                                              </IconButton>
                                            </Stack>
                                          }
                                          secondary={x.Mensagem}
                                          secondaryTypographyProps={{
                                            component: Link,
                                            to: redirect,
                                            sx: {
                                              fontWeight: "400",
                                              fontSize: "0.875rem",
                                              lineHeight: "1.43",
                                              letterSpacing: "0.01071em",
                                              color: "rgba(0, 0, 0, 0.6)",
                                              ":hover": { color: "rgba(0, 0, 0, 0.6) !important" }
                                            }
                                          }
                                          }
                                        />
                                      </ListItem>
                                      <Typography variant="caption" sx={{ mr: "8px" }} >
                                        {displayDate}
                                      </Typography>
                                    </Stack>
                                  </ListItem>
                                  <Divider />
                                </React.Fragment>
                              );
                            })
                            }
                            {messages.length === 0 && <Typography variant="caption" display={"block"} align="center" sx={{
                              fontWeight: "400",
                              fontSize: "0.875rem",
                              lineHeight: "1.43",
                              letterSpacing: "0.01071em",
                              color: "rgba(0, 0, 0, 0.6)",
                              mt: "8px"
                            }} >
                              Você não possuí novas notificações
                            </Typography>}
                          </List>
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </>
                )
              }}
            </PopupState>

            <Button variant="text" onClick={handleOpenUserMenu} sx={{ p: 0, ml: "12px" }}>
              <img src={AdvancedAnayticsImage} height="30" alt="Customer 360" />
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  ml: "-12px",
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: "53.5px",
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
            >
              {userLoggedIn &&
                <Link to="/Admin">
                  <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ color: '#000' }}>Admin</ListItemText>
                  </MenuItem>
                </Link>
              }
              <Link to="/logout">
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon >
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ color: '#000' }}>Sair</ListItemText>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;