import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminPrivateRoute = (props) => {
  const { oidc } = props;
  const userLoggedIn = oidc?.user?.profile?.roles?.find(x => x === "admin") !== undefined;
  if (!userLoggedIn) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return props.children;
};

function mapStateToProps(state) {
  return {
    oidc: state.oidc,
  };
}

export default connect(mapStateToProps)(AdminPrivateRoute);
