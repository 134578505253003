const requestAnalyticsProductsType = "REQUEST_AnalyticsProducts";
const receiveAnalyticsProductsType = "RECEIVE_AnalyticsProducts";

const initialState = { AnalyticsProductsSeeds: [], isLoading: false };

export const AnalyticsProductsActions = {
  requestAnalyticsProducts: (campanha, territorio, clienteId, distrito) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAnalyticsProductsType });
    const url = `/api/simuladorprodutosanalytics/${encodeURIComponent(campanha)}/${encodeURIComponent(territorio)}/${encodeURIComponent(clienteId)}/${encodeURIComponent(distrito)}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const AnalyticsProducts = await response.json();
    dispatch({ type: receiveAnalyticsProductsType, AnalyticsProducts });
  },
  requestAnalyticsProducts2: (campanha, clienteId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAnalyticsProductsType });
    const url = `/api/simulador2/simuladorprodutos/analytics?campanha=${campanha}&clienteId=${clienteId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const AnalyticsProducts = await response.json();
    dispatch({ type: receiveAnalyticsProductsType, AnalyticsProducts });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestAnalyticsProductsType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveAnalyticsProductsType) {
    return {
      ...state,
      AnalyticsProducts: action.AnalyticsProducts,
      isLoading: false,
    };
  }

  return state;
};
