import React from "react";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ConfirmationDialog from "./CustomSelect";

export default function CountyPagination(props) {
  const { counties, addData, changeLoadState, removeData } = props;

  const onChange = (newValue = [], oldValue = []) => {
    var removeValues = oldValue.filter(x => !newValue.includes(x));
    var addValues = newValue.filter(x => !oldValue.includes(x));

    if (removeValues.length > 0){
      changeLoadState(true);
      removeData(removeValues, "county");
    }

    if (addValues.length > 0) {
      changeLoadState(true);
      addData(addValues, "county");
    }
  }

  return (
    <ConfirmationDialog
      variant="contained"
      sx={{
        borderRadius: "50%",
        width: "36px",
        heigth: "36px",
        maxHeight: "36px",
        minWidth: "36px !important",
        background: "var(--cort-blue)",
        color: "white",
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
      }}
      options={counties}
      onChange={onChange}
      title="Municípios"
      groupBy={(item) => { return item?.uf }}
      sortBy="selected"
    >
      <TravelExploreIcon />
    </ConfirmationDialog>
  );
}