import React, { useEffect } from "react";
import { FixedSizeList } from 'react-window';
import {
    Button,
    Grid,
    TextField,
    ListItemButton,
    ListItemIcon,
    Checkbox,
    ListItemText,
    Tooltip
} from "@mui/material";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const TransferList = ({ products, right, setRight }) => {
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(products);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    return (
        <Grid container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ height: "220px" }}
        >
            <Grid item xs={5}>
                <CustomList items={left} checked={checked} handleToggle={handleToggle} setChecked={setChecked} />
            </Grid>
            <Grid item xs={2}>
                <Grid container direction="column" alignItems="center">
                    {/* <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="Adicionar Todos"
                    >
                        ≫
                    </Button> */}
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="Adicionar"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="Remover"
                    >
                        &lt;
                    </Button>
                    {/* <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="Remover Todos"
                    >
                        ≪
                    </Button> */}
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <CustomList items={right} checked={checked} handleToggle={handleToggle} setChecked={setChecked} />
            </Grid>
        </Grid>
    );
}

const CustomList = ({ items, checked, setChecked = () => { }, handleToggle = () => { } }) => {
    const [filter, setFilter] = React.useState("");
    const [filteredItems, setFilteredItems] = React.useState(items);

    useEffect(() => {
        setFilter("");
        setFilteredItems(items);
    }, [items]);

    const normalizeString = (str) => {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    };

    const handleChangeFilter = (event) => {
        const value = normalizeString(event.target.value);
        const filtered = items.filter((x) => normalizeString(x.nome).includes(value));

        setFilter(event.target.value);
        setFilteredItems(filtered);
    }

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    return (
        <React.Fragment>
            <Grid container sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.42)" }}>
                {/* <Grid item xs={2}>
                    <Tooltip title={"Selecionar todos"}>
                        <Checkbox
                            onClick={handleToggleAll(filteredItems)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={
                                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{
                                'aria-label': 'todos selecionados',
                            }}
                        />
                    </Tooltip>
                </Grid> */}
                <Grid item xs={12}>
                    <TextField fullWidth
                        value={filter}
                        placeholder="Buscar..."
                        variant="standard"
                        margin="dense"
                        onChange={handleChangeFilter}
                        sx={{
                            '& input': {
                                padding: "2px 2px 2px 16px!important",
                            },
                            '*::after': {
                                border: "none !important"
                            },
                            '*::before': {
                                border: "none !important"
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <FixedSizeList
                height={180}
                width={"100%"}
                itemSize={42}
                itemCount={filteredItems.length}
                overscanCount={5}>
                {({ index, style }) => {
                    const labelId = `transfer-list-item-${filteredItems[index]}-label`;

                    return (
                        <ListItemButton
                            key={filteredItems[index]}
                            role="listitem"
                            style={style}
                            onClick={handleToggle(filteredItems[index])}
                            sx={{ paddingLeft: "0px" }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(filteredItems[index]) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={filteredItems[index].nome} />
                        </ListItemButton>
                    );
                }
                }
            </FixedSizeList>
        </React.Fragment>
    );
}

export default TransferList;