import { HandleResponse, isNullOrEmpty } from "../helpers"

const requestGetAreaType = "CPA_ESTRUTURA_REQUEST_GET_AREA";
const receiveGetAreaType = "CPA_ESTRUTURA_RECEIVE_GET_AREA";
const failureGetAreaType = "CPA_ESTRUTURA_FAILURE_GET_AREA";

const requestGetBusinessType = "CPA_ESTRUTURA_REQUEST_GET_BUSINESS";
const receiveGetBusinessType = "CPA_ESTRUTURA_RECEIVE_GET_BUSINESS";
const failureGetBusinessType = "CPA_ESTRUTURA_FAILURE_GET_BUSINESS";

const requestGetDistritosType = "CPA_ESTRUTURA_REQUEST_GET_DISTRITOS";
const receiveGetDistritosType = "CPA_ESTRUTURA_RECEIVE_GET_DISTRITOS";
const failureGetDistritosType = "CPA_ESTRUTURA_FAILURE_GET_DISTRITOS";

const setFormDataType = "CPA_SET_ESTRUTURA_FORMDATA";
const handleChangeFormDataType = "CPA_ESTRUTURA_HANDLE_CHANGE_FORMDATA";
const clearFormDataType = "CPA_ESTRUTURA_CLEAR_FORMDATA";


const initialState = {
  formData: { distrito: null, businessArea: null, area: null, role: null, canView: false },
  distritos: [],
  businessAreas: [],
  areas: [],
  status: "idle"
};

export const cpaEstruturaActions = {
  initEstruturaForm: () => async (dispatch) => {
    try {
      const formDataJson = localStorage.getItem("cpa_formData");
      const formData = isNullOrEmpty(formDataJson) ? null : JSON.parse(formDataJson);

      dispatch({ type: setFormDataType, formData: (formData ?? { ...initialState.formData }) });

      if (!isNullOrEmpty(formData?.role)) {
        dispatch(cpaEstruturaActions.GetEstruturaAreas())
      }
    } catch (_) { }
  },
  handleChangeEstruturaForm: (propName = "", value = "") => async (dispatch) => {
    dispatch({ type: handleChangeFormDataType, propName, value });
  },
  clearFormEstruturaData: () => async (dispatch) => {
    dispatch({ type: clearFormDataType });
  },
  GetEstruturaAreas: () => async (dispatch, getState) => {
    const { oidc, cpaEstrutura } = getState();

    dispatch({ type: requestGetAreaType });

    const url = `/api/simulador2/EstruturaComercial/Areas`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      areas => {
        dispatch({ type: receiveGetAreaType, areas });
        if (!isNullOrEmpty(cpaEstrutura.formData.area)) {
          dispatch(cpaEstruturaActions.GetEstruturaBusinessAreas(cpaEstrutura.formData.area))
        }
      },
      error => {
        dispatch({ type: failureGetAreaType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados de área.", message: error, responseType: "error" });
      }
    );
  },
  GetEstruturaBusinessAreas: (area) => async (dispatch, getState) => {
    const { oidc, cpaEstrutura } = getState();

    dispatch({ type: requestGetBusinessType });

    const url = `/api/simulador2/EstruturaComercial/BusinessAreas?area=${area}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      businessareas => {
        dispatch({ type: receiveGetBusinessType, businessareas });
        if (!isNullOrEmpty(cpaEstrutura.formData.area) && !isNullOrEmpty(cpaEstrutura.formData.businessArea)) {
          dispatch(cpaEstruturaActions.GetEstruturaDistritos(cpaEstrutura.formData.area, cpaEstrutura.formData.businessArea))
        }
      },
      error => {
        dispatch({ type: failureGetBusinessType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados de Business Area.", message: error, responseType: "error" });
      }
    );
  },
  GetEstruturaDistritos: (area, businessArea) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetDistritosType });

    const url = `/api/simulador2/EstruturaComercial/Distritos?area=${area}&businessArea=${businessArea}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      distritos => {
        dispatch({ type: receiveGetDistritosType, distritos });
      },
      error => {
        dispatch({ type: failureGetDistritosType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados de Distritos.", message: error, responseType: "error" });
      }
    );
  },

};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case setFormDataType:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.formData,
        }
      };
    case handleChangeFormDataType:
      {
        const formData = {
          ...state.formData,
          [action.propName]: action.value,
        }

        localStorage.setItem("cpa_formData", JSON.stringify(formData));

        return {
          ...state,
          formData: {
            ...formData
          }
        };
      }
    case clearFormDataType:
      localStorage.removeItem("cpa_formData");
      return {
        ...state,
        formData: {
          ...initialState.formData,
        }
      };
    case requestGetAreaType:
      return {
        ...state,
        areas: [],
        status: "loading",
      };
    case receiveGetAreaType:
      return {
        ...state,
        status: "idle",
        areas: action.areas,
      };
    case failureGetAreaType:
      return {
        ...state,
        areas: [],
        status: "failure",
      };
    case requestGetBusinessType:
      return {
        ...state,
        businessareas: [],
        status: "loading",
      };
    case receiveGetBusinessType:
      return {
        ...state,
        status: "idle",
        businessAreas: action.businessareas,
      };
    case failureGetBusinessType:
      return {
        ...state,
        businessAreas: [],
        status: "failure",
      };
    case requestGetDistritosType:
      return {
        ...state,
        distritos: [],
        status: "loading",
      };
    case receiveGetDistritosType:
      return {
        ...state,
        status: "idle",
        distritos: action.distritos,
      };
    case failureGetDistritosType:
      return {
        ...state,
        distritos: [],
        status: "failure",
      };
    default:
      return state;
  }
};
