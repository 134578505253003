import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { valorAreaLiderActions } from "../../../../actions/valorAreaLider.actions";
import { cpaActions } from "../../../../actions/cpa.actions";
import { TableCpaCp } from "./components/TableCpaCp";
import { Backdrop, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import { isDefaultDate, isNullOrEmpty } from "../../../../helpers";
import CircularProgress from '@mui/material/CircularProgress';
import ViewSelect from "./components/ViewSelect";
import { userActions } from "../../../../actions/user.actions";
import CachedIcon from '@mui/icons-material/Cached';
import { TableCpaSeeds } from "./components/TableCpaSeeds";
import { CellInput } from "./components/CellInput";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "8px 16px"
        }
      }
    }
  }
});

class LeaderApproval extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      role: null,
      isAdmin: false,
      hasSet: false,
      valorMinInputs: {},
      selectedArea: ''
    };

    this.onChangePerCpaDisponivelCp = this.onChangePerCpaDisponivelCp.bind(this);
    this.onChangePerCpaDisponivelCpArea = this.onChangePerCpaDisponivelCpArea.bind(this);
    this.onChangePerCpaDisponivelSeeds = this.onChangePerCpaDisponivelSeeds.bind(this);
    this.onChangePerCpaDisponivelSeedsArea = this.onChangePerCpaDisponivelSeedsArea.bind(this);
  }

  componentDidMount() {
    this.props.actions.GetCampanhasCp();
    this.props.actions.GetCampanhasSeeds();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();
  }

  componentWillUnmount() {
    this.props.actions.clearFormData();
  }

  updateDataCp() {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      if (this.state.isAdmin) {
        this.props.actions.UpdateCpaCpArea(this.props.cpa.formData.campanhaCp, this.props.cpaEstrutura.formData.area);
      } else {
        this.props.actions.UpdateCpaCpArea(this.props.cpa.formData.campanhaCp);
      }
    }
  }

  updateDataSeeds() {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      if (this.state.isAdmin) {
        this.props.actions.UpdateCpaSeedsArea(this.props.cpa.formData.campanhaSeeds, this.props.cpaEstrutura.formData.area);
      } else {
        this.props.actions.UpdateCpaSeedsArea(this.props.cpa.formData.campanhaSeeds);
      }
    }
  }

  requestDataCpaCp(campanha) {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      if (this.state.isAdmin) {
        this.props.actions.GetCpaCpArea(campanha, this.props.cpaEstrutura.formData.area);
      } else {
        this.props.actions.GetCpaCpArea(campanha);
      }
    } else if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) {
      if (this.state.isAdmin) {
        this.props.actions.GetCpaCpBusiness(campanha, this.props.cpaEstrutura.formData.area, this.props.cpaEstrutura.formData.businessArea);
      } else {
        this.props.actions.GetCpaCpBusiness(campanha);
      }
    } else {
      if (this.state.isAdmin) {
        this.props.actions.GetCpaCpDistrito(campanha, this.props.cpaEstrutura.formData.distrito);
      } else {
        this.props.actions.GetCpaCpDistrito(campanha);
      }
    }
  }

  requestDataCpaSeeds(campanha) {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      if (this.state.isAdmin) {
        this.props.actions.GetCpaSeedsArea(campanha, this.props.cpaEstrutura.formData.area);
      } else {
        this.props.actions.GetCpaSeedsArea(campanha);
      }
    } else if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) {
      if (this.state.isAdmin) {
        this.props.actions.GetCpaSeedsBusiness(campanha, this.props.cpaEstrutura.formData.area, this.props.cpaEstrutura.formData.businessArea);
      } else {
        this.props.actions.GetCpaSeedsBusiness(campanha);
      }
    } else {
      if (this.state.isAdmin) {
        this.props.actions.GetCpaSeedsDistrito(campanha, this.props.cpaEstrutura.formData.distrito);
      } else {
        this.props.actions.GetCpaSeedsDistrito(campanha);
      }
    }
  }

  onChangePerCpaDisponivelCp(id, value) {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      this.props.actions.handleChangeCp(id, value, "BusinessArea");
    } else if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) {
      this.props.actions.handleChangeCp(id, value, "District");
    } else {
      this.props.actions.handleChangeCp(id, value, "Territory");
    }
  }

  onChangePerCpaDisponivelCpArea(id, value) {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      this.props.actions.handleChangeCpArea(id, value, "Area");
    }
  }

  onChangePerCpaDisponivelSeeds(id, value) {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      this.props.actions.handleChangeSeeds(id, value, "BusinessArea");
    } else if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea) {
      this.props.actions.handleChangeSeeds(id, value, "District");
    } else {
      this.props.actions.handleChangeSeeds(id, value, "Territory");
    }
  }

  onChangePerCpaDisponivelSeedsArea(id, value) {
    if (this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
      this.props.actions.handleChangeSeedsArea(id, value);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.hasSet && this.props.user.role?.role && this.props.user?.roleLevels) {
      const isAdmin = this.props.user.role?.role?.accessLevel === this.props.user?.roleLevels?.viewAll
      this.setState({ role: this.props.user.role?.role });
      this.setState({ isAdmin: isAdmin });
      this.setState({ hasSet: true });

      if (!isAdmin && this.props.user.role?.role?.accessLevel >= this.props.user?.roleLevels?.viewArea) {
        this.props.actions.getValorAreaLeader();
      }
    }

    if (
      (prevProps.cpaEstrutura.formData.canView && !this.props.cpaEstrutura.formData.canView)
      // eslint-disable-next-line eqeqeq
      || (prevProps.cpaEstrutura.formData.role != this.props.cpaEstrutura.formData.role)
      || (prevProps.cpaEstrutura.formData.area !== this.props.cpaEstrutura.formData.area)
      || (prevProps.cpaEstrutura.formData.businessArea !== this.props.cpaEstrutura.formData.businessArea)
      || (prevProps.cpaEstrutura.formData.distrito !== this.props.cpaEstrutura.formData.distrito)
    ) {
      this.props.actions.clearFormData();
    }

    // eslint-disable-next-line eqeqeq
    if (prevProps.cpaEstrutura.formData.role != this.props.cpaEstrutura.formData.role) {
      this.setState({ role: this.props.cpaEstrutura.formData.role });
    }

    if (
      this.props.cpaEstrutura.formData.canView
      // eslint-disable-next-line eqeqeq
      && ((prevProps.cpaEstrutura.formData.role != this.props.cpaEstrutura.formData.role)
        || (prevProps.cpaEstrutura.formData.area !== this.props.cpaEstrutura.formData.area))
    ) {
      if (this.props.cpaEstrutura.formData.role.accessLevel >= this.props.user?.roleLevels?.viewArea) {
        this.props.actions.getValorAreaLeader(this.props.cpaEstrutura.formData.area);
      }
    }
  }

  handleInputChange = (id, event) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      valorMinInputs: {
        ...prevState.valorMinInputs,
        [id]: value
      }
    }));
  }

  handleSave = () => {
    const { valorMinInputs } = this.state;
    Object.keys(valorMinInputs).forEach(id => {
      const updatedValor = valorMinInputs[id];
      this.props.actions.updateValorAreaLeader(id, { valorMinNegociacao: updatedValor });
    });
  }

  handleAreaChange = (event) => {
    const selectedArea = event.target.value;
    this.setState({ selectedArea }, () => {
      this.props.actions.GetCpaArea(selectedArea);
      this.props.actions.requestBusinessArea(selectedArea);
      this.props.actions.getOrCreateValorAreaLeader(selectedArea);
    });
  }

  render() {
    console.log("props: ", this.props);


    const valorAreaLider = this.props.valorAreaLider?.valorAreaLeaders

    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.user.isLoadingRoleLevels || this.props.user.isLoadingRole || this.props.cpa.areaStatus === "loading" || this.props.cpa.areaSeedsStatus === "loading"}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Workflow2"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link underline="hover" color="inherit" to={"/Workflow2"}>
                Workflow Aprovação 2.0
              </Link>
              <Typography color="text.primary">CPA</Typography>
            </Breadcrumbs>
          </Stack>
        </div>

        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ marginTop: "0 !important" }}>
            {this.state.isAdmin &&
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={2}>
                  <Grid item xs={12} className="rounded-3"
                    sx={{
                      marginLeft: "16px",
                      marginTop: "16px",
                      backgroundColor: "#e6e8ea",
                      fontWeight: "500",
                      padding: "8px 16px !important",
                    }}
                  >
                    <ViewSelect />
                  </Grid>
                </Grid>
              </Grid>
            }
            {!(this.state.isAdmin && !this.props.cpaEstrutura.formData.canView) &&
              <>
                {this.state.role?.accessLevel >= this.props.user?.roleLevels?.viewArea && valorAreaLider &&
                  <>
                    <Grid item xs={2} sm={8} md={12} display={"flex"} flexDirection={"column"} alignItems={"flex-end"} justifyContent={"space-evenly"} gap={2}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="body1" mr={1}>Ultima alteração:</Typography>
                        {this.props.valorAreaLider.changeStatus === "loading" || this.props.valorAreaLider.status === "loading" ?
                          <CircularProgress color="azul" size={25} /> :
                          <Typography variant="body2">
                            {!isNullOrEmpty(this.props.valorAreaLider.formData.updateDate) && !isDefaultDate(this.props.valorAreaLider.formData.updateDate) ?
                              (new Date(this.props.valorAreaLider.formData.updateDate)).toLocaleString("pt-BR") :
                              "Não alterado!"
                            }
                          </Typography>
                        }
                      </Stack>
                    </Grid>
                    <Grid item xs={2} sm={8} md={12}>
                      <Box>
                        <TableContainer component={Paper}>
                          <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
                              <TableHead sx={{
                                backgroundColor: "var(--cort-blue)",
                                "th": {
                                  color: "#FFF",
                                  fontWeight: "bold"
                                },
                              }}>
                                <TableRow>
                                  <TableCell size="medium" >Business Area</TableCell>
                                  <TableCell size="medium" >RSL</TableCell>
                                  <TableCell size="medium" align="center">Valor mínimo da negociação</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {valorAreaLider?.map((row) => (
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      {row.businessArea}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.responsavel && row.responsavel.name ? row.responsavel.name : '-'}
                                    </TableCell>
                                    <CellInput
                                      name="valorMinNegociacao"
                                      placeholder=""
                                      value={row.valorMinNegociacao}
                                      onChange={(value) => this.props.actions.updateValorAreaLeader(row.id, value)}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      customInput={TextField}
                                      size="small"
                                      prefix={"R$ "}
                                      sx={{ input: { textAlign: "right", fontSize: "0.875rem", p: "8.5px 8px" } }}
                                      allowNegative={false}
                                    />
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </ThemeProvider>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </>
                }

                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#0072ce", color: "#fff", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingBottom: "0.5rem !important", paddingTop: "0.5rem !important", paddingRight: "1rem !important", display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
                      <p style={{ marginBottom: "0" }}>CROP PROTECTION</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={4}>
                  <Stack direction={{ md: "column", sm: "column", xs: "column" }} gap={2} alignItems={{ md: "flex-start", sm: "flex-start", xs: "flex-start" }}>
                    <Typography variant="body1" fontWeight={"bold"}>Campanha:</Typography>
                    <Select
                      value={this.props.cpa.formData.campanhaCp}
                      placeholder="Campanha"
                      onChange={(e) => {
                        this.props.actions.handleChangeFormData("campanhaCp", e.target.value);
                        this.requestDataCpaCp(e.target.value);
                      }}
                      sx={{ minWidth: 130, width: "100%" }}
                      size="small"
                    >
                      {this.props.cpa.campanhaStatus !== "loading" &&
                        this.props.cpa.campanhas.map((campanha) => (
                          <MenuItem
                            key={campanha.id}
                            value={campanha.id}
                          >
                            {campanha.campanhaCpa}
                          </MenuItem>
                        ))}
                    </Select>
                  </Stack>
                </Grid>

                {!isNullOrEmpty(this.props.cpa.formData.campanhaCp) &&
                  <Grid item xs={2} sm={8} md={8} display={"flex"} flexDirection={"column"} alignItems={"flex-end"} justifyContent={"space-evenly"} gap={2}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography variant="body1" mr={1}>Dados atualizados em:</Typography>

                      {this.props.cpa.areaStatus === "loading" ?
                        <CircularProgress color="azul" size={25} /> :
                        <Typography variant="body2">
                          {!isNullOrEmpty(this.props.cpa.formData.updateDataDateCp) && !isDefaultDate(this.props.cpa.formData.updateDataDateCp) ?
                            (new Date(this.props.cpa.formData.updateDataDateCp)).toLocaleString("pt-BR") :
                            "Não atualizado!"
                          }
                        </Typography>
                      }
                      {this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea &&
                        <Tooltip title={"Atualizar dados"}>
                          <IconButton size="small" sx={{ ml: 1 }} onClick={() => this.updateDataCp()}>
                            <CachedIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Stack>

                    {this.props.cpa.area &&
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="body1" mr={1}>Ultima alteração:</Typography>
                        {this.props.cpa.changeStatus === "loading" || this.props.cpa.areaStatus === "loading" ?
                          <CircularProgress color="azul" size={25} /> :
                          <Typography variant="body2">
                            {!isNullOrEmpty(this.props.cpa.formData.updateDateCp) && !isDefaultDate(this.props.cpa.formData.updateDateCp) ?
                              (new Date(this.props.cpa.formData.updateDateCp)).toLocaleString("pt-BR") :
                              "Não alterado!"
                            }
                          </Typography>
                        }
                      </Stack>
                    }
                  </Grid>
                }
                {(!isNullOrEmpty(this.props.cpa.formData.campanhaCp) && this.props.cpa.area) &&

                  <Grid item xs={2} sm={8} md={12}>
                    <TableCpaCp
                      cpa={this.props.cpa.area}
                      onChange={this.onChangePerCpaDisponivelCp}
                      onChangeParent={this.onChangePerCpaDisponivelCpArea}
                      {...(
                        this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea ?
                          {
                            canEditParent: true,
                          } :
                          this.state.role.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea ?
                            {
                              actuationAreaLabel: "Business Area",
                              childrenActuationAreaLabel: "Distrito",
                              responsavelLabel: "DSM",
                            } :
                            {
                              actuationAreaLabel: "Distrito",
                              childrenActuationAreaLabel: "Território",
                              responsavelLabel: "RC",
                            }
                      )}
                    />
                  </Grid>
                }

                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#0072ce", color: "#fff", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingBottom: "0.5rem !important", paddingTop: "0.5rem !important", paddingRight: "1rem !important", display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
                      <p style={{ marginBottom: "0" }}>SEEDS</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={4}>
                  <Stack direction={{ md: "column", sm: "column", xs: "column" }} gap={2} alignItems={{ md: "flex-start", sm: "flex-start", xs: "flex-start" }}>
                    <Typography variant="body1" fontWeight={"bold"}>Campanha:</Typography>
                    <Select
                      value={this.props.cpa.formData.campanhaSeeds}
                      placeholder="Campanha"
                      onChange={(e) => {
                        this.props.actions.handleChangeFormData("campanhaSeeds", e.target.value);
                        this.requestDataCpaSeeds(e.target.value);
                      }}
                      sx={{ minWidth: 130, width: "100%" }}
                      size="small"
                    >
                      {this.props.cpa.campanhaSeedsStatus !== "loading" &&
                        this.props.cpa.campanhasSeeds.map((campanha) => (
                          <MenuItem
                            key={campanha.id}
                            value={campanha.id}
                          >
                            {campanha.campanhaCpa}
                          </MenuItem>
                        ))}
                    </Select>
                  </Stack>
                </Grid>

                {!isNullOrEmpty(this.props.cpa.formData.campanhaSeeds) &&
                  <Grid item xs={2} sm={8} md={8} display={"flex"} flexDirection={"column"} alignItems={"flex-end"} justifyContent={"space-evenly"} gap={2}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography variant="body1" mr={1}>Dados atualizados em:</Typography>

                      {this.props.cpa.areaSeedsStatus === "loading" ?
                        <CircularProgress color="azul" size={25} /> :
                        <Typography variant="body2">
                          {!isNullOrEmpty(this.props.cpa.formData.updateDataDateSeeds) && !isDefaultDate(this.props.cpa.formData.updateDataDateSeeds) ?
                            (new Date(this.props.cpa.formData.updateDataDateSeeds)).toLocaleString("pt-BR") :
                            "Não atualizado!"
                          }
                        </Typography>
                      }
                      {this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea &&
                        <Tooltip title={"Atualizar dados"}>
                          <IconButton size="small" sx={{ ml: 1 }} onClick={() => this.updateDataSeeds()}>
                            <CachedIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Stack>

                    {this.props.cpa.areaSeeds &&
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="body1" mr={1}>Ultima alteração:</Typography>
                        {this.props.cpa.changeSeedsStatus === "loading" || this.props.cpa.areaSeedsStatus === "loading" ?
                          <CircularProgress color="azul" size={25} /> :
                          <Typography variant="body2">
                            {!isNullOrEmpty(this.props.cpa.formData.updateDateSeeds) && !isDefaultDate(this.props.cpa.formData.updateDateSeeds) ?
                              (new Date(this.props.cpa.formData.updateDateSeeds)).toLocaleString("pt-BR") :
                              "Não alterado!"
                            }
                          </Typography>
                        }
                      </Stack>
                    }
                  </Grid>
                }
                {(!isNullOrEmpty(this.props.cpa.formData.campanhaSeeds) && this.props.cpa.areaSeeds) &&

                  <Grid item xs={2} sm={8} md={12}>
                    <TableCpaSeeds
                      cpa={this.props.cpa.areaSeeds}
                      onChange={this.onChangePerCpaDisponivelSeeds}
                      onChangeParent={this.onChangePerCpaDisponivelSeedsArea}
                      {...(
                        this.state.role.accessLevel >= this.props.user?.roleLevels?.viewArea ?
                          {
                            canEditParent: true,
                          } :
                          this.state.role.accessLevel >= this.props.user?.roleLevels?.viewBusinessArea ?
                            {
                              actuationAreaLabel: "Business Area",
                              childrenActuationAreaLabel: "Distrito",
                              responsavelLabel: "DSM",
                            } :
                            {
                              actuationAreaLabel: "Distrito",
                              childrenActuationAreaLabel: "Território",
                              responsavelLabel: "RC",
                            }
                      )}
                    />
                  </Grid>
                }
              </>
            }
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...valorAreaLiderActions,
        ...cpaActions,
        ...userActions,
      }, dispatch),
    };
  }
)(LeaderApproval);
