import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SoyBoxIcon(props) {
  return <SvgIcon viewBox="0 0 40 50" {...props} >
    <path d="M0.856567 1.1701H38.1168V49.1373H0.856567V1.1701Z" fill="#F6F3E5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.8938 21.5123C25.1805 21.5018 26.5041 20.6413 27.2794 19.631C27.9791 18.7191 28.2896 17.6083 28.3675 16.6839C25.8243 16.4945 24.4928 17.3414 23.7788 18.3354C23.1051 19.2733 22.8765 20.4845 22.8938 21.5123ZM21.2416 22.3933C21.0634 21.0008 21.2243 18.9553 22.3874 17.336C23.626 15.6116 25.8488 14.5738 29.3568 15.0692C29.7632 15.1266 30.0717 15.4641 30.0925 15.874C30.1585 17.1766 29.8435 19.1034 28.6385 20.6739C27.5125 22.1413 25.6835 23.2004 22.9439 23.2249L22.7642 36.904C22.758 37.3771 22.3695 37.7555 21.8964 37.7493C21.4234 37.7431 21.045 37.3546 21.0512 36.8815L21.2416 22.3933Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33.4057 7.59428H5.56761V42.2848H33.4057V7.59428ZM3.85449 5.88116V43.998H35.1188V5.88116H3.85449Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.741781 0.428279C1.15147 0.191747 1.67533 0.332115 1.91186 0.7418L5.36475 6.72238C5.60128 7.13207 5.46092 7.65593 5.05123 7.89246C4.64155 8.129 4.11768 7.98863 3.88115 7.57894L0.42826 1.59836C0.191728 1.18867 0.332096 0.66481 0.741781 0.428279Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.28074 41.9713C4.87106 41.7348 4.34719 41.8751 4.11066 42.2848L0.65777 48.2654C0.421238 48.6751 0.561606 49.199 0.971291 49.4355C1.38098 49.672 1.90484 49.5317 2.14137 49.122L5.59426 43.1414C5.83079 42.7317 5.69043 42.2078 5.28074 41.9713Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33.834 41.9713C34.2437 41.7348 34.7676 41.8751 35.0041 42.2848L38.457 48.2654C38.6935 48.6751 38.5531 49.199 38.1435 49.4355C37.7338 49.672 37.2099 49.5317 36.9734 49.122L33.5205 43.1414C33.284 42.7317 33.4243 42.2078 33.834 41.9713Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.856567 0.741816V2.02665H38.1168V0.741816C38.1168 0.505284 37.9251 0.313538 37.6885 0.313538H1.28485C1.04831 0.313538 0.856567 0.505285 0.856567 0.741816ZM38.1168 47.8525H0.856567V49.1373C0.856567 49.3738 1.04832 49.5656 1.28485 49.5656H37.6885C37.9251 49.5656 38.1168 49.3738 38.1168 49.1373V47.8525Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M38.2582 0.428279C37.8485 0.191747 37.3247 0.332115 37.0881 0.7418L33.6352 6.72238C33.3987 7.13207 33.5391 7.65593 33.9488 7.89246C34.3585 8.129 34.8823 7.98863 35.1188 7.57894L38.5717 1.59836C38.8083 1.18867 38.6679 0.66481 38.2582 0.428279Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.6243 27.4297C18.5698 26.0284 18.1223 24.1207 16.901 22.6224C15.5533 20.9692 13.3346 19.9039 9.96167 20.4509C9.61438 20.5072 9.377 20.8324 9.42919 21.1803C9.65423 22.6806 10.3393 24.8518 11.7045 26.4677C12.3936 27.2833 13.2701 27.9735 14.362 28.3461C15.3699 28.69 16.5223 28.7499 17.8193 28.4332L21.4154 31.6297C21.6805 31.8654 22.0866 31.8416 22.3223 31.5764C22.558 31.3112 22.5341 30.9051 22.269 30.6694L18.6243 27.4297ZM17.3261 27.2282C17.2382 26.0574 16.8359 24.5761 15.9051 23.4343C14.9537 22.2672 13.3927 21.3814 10.8199 21.6338C11.1016 22.9081 11.6985 24.4698 12.6859 25.6385C13.2602 26.3182 13.9522 26.8487 14.7769 27.1301C15.4854 27.3718 16.3259 27.4417 17.3261 27.2282Z" fill="black" />
  </SvgIcon>
}