import { simulatorActions } from "./simulator.actions";
import { simulator2Actions } from "./simulator2.actions";

const requestProductsType = "REQUEST_PRODUCTS";
const receiveProductsType = "RECEIVE_PRODUCTS";
const removeAllProductsType = "REMOVE_ALL_PRODUCTS";

const requestSeedsProducts = "REQUEST_SEEDS_PRODUCTS";
const receiveSeedsProducts = "RECEIVE_SEEDS_PRODUCTS";

const initialState = { seedsProducts: [], products: [], isLoading: false };

export const productActions = {
  requestProducts: (campanha, clienteProps, cliente) => async (dispatch, getState) => {
    const { oidc } = getState();

    var distrito = ""

    for (var j = 0; j < clienteProps.length; j++) {
      if (clienteProps[j].id === cliente) {

        distrito = clienteProps[j].district
      }
    }

    dispatch({ type: requestProductsType });

    const url = `/api/simuladorprodutos/${encodeURIComponent(campanha)}/${encodeURIComponent(distrito)}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const products = await response.json();
    dispatch({ type: receiveProductsType, products });
  },
  requestProducts2: (campanha, clienteId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestProductsType });

    const url = `/api/simulador2/simuladorprodutos?campanha=${campanha}&clienteId=${clienteId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const products = await response.json();
    dispatch({ type: receiveProductsType, products });
  },
  requestProductsAndUpdate: (campanha, clienteProps, cliente, currency, oldCurrency) => async (dispatch, getState) => {
    const { oidc } = getState();

    var distrito = ""

    for (var j = 0; j < clienteProps.length; j++) {
      if (clienteProps[j].id === cliente) {

        distrito = clienteProps[j].district
      }
    }

    dispatch({ type: requestProductsType });

    const url = `/api/simuladorprodutos/${encodeURIComponent(campanha)}/${encodeURIComponent(distrito)}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const products = await response.json();
    dispatch({ type: receiveProductsType, products });
    dispatch(simulatorActions.updateRows(products, currency, oldCurrency))

  },
  requestProductsAndUpdate2: (campanha, cliente, currency, oldCurrency) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestProductsType });

    const url = `/api/simulador2/simuladorprodutos?campanha=${campanha}&clienteId=${cliente.id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const products = await response.json();
    dispatch({ type: receiveProductsType, products });
    dispatch(simulator2Actions.updateRows(products, currency, oldCurrency))

  },
  requestProductsCenario: (cenario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestProductsType });

    const url = `/api/simuladorprodutoscenario/${cenario}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const products = await response.json();
    dispatch({ type: receiveProductsType, products });
  },
  requestProductsCenario2: (cenario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestProductsType });

    const url = `/api/simulador2/simuladorprodutos/cenario?id=${cenario}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const products = await response.json();
    dispatch({ type: receiveProductsType, products });
  },
  removeAllProducts: () => (dispatch, getState) => {
    dispatch({ type: removeAllProductsType });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestProductsType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveProductsType) {
    return {
      ...state,
      products: action.products,
      isLoading: false,
    };
  }
  if (action.type === removeAllProductsType) {
    return {
      ...state,
      products: [],
      isLoading: false,
    };
  }

  if (action.type === requestSeedsProducts) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveSeedsProducts) {
    return {
      ...state,
      seedsProducts: action.products,
      isLoading: false,
    };
  }

  return state;
};
