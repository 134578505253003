const requestCampaignsType = "REQUEST_Campaigns";
const receiveCampaignsType = "RECEIVE_Campaigns";

const requestCampaignsSeeds = "REQUEST_CAMPAIGNS_SEEDS";
const receiveCampaignsSeeds = "RECEIVE_CAMPAIGNS_SEEDS";

const initialState = { campaigns: [], seedsCampaigns: [], isLoading: false, isLoadingSeeds: false };

export const campaignActions = {
  requestCampaigns: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCampaignsType });

    const url = `/api/simuladorcampanha`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const campaigns = await response.json();
    dispatch({ type: receiveCampaignsType, campaigns });
  },
  requestCampaigns2: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCampaignsType });

    const url = `/api/Simulador2/SimuladorCampanha`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const campaigns = await response.json();
    dispatch({ type: receiveCampaignsType, campaigns });
  },

  requestCampaignsSeeds: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCampaignsSeeds });

    const url = `/api/SimuladorCampanhaSeeds`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const campaigns = await response.json();
    dispatch({ type: receiveCampaignsSeeds, campaigns });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCampaignsType:
      return {
        ...state,
        isLoading: true,
      };
    case receiveCampaignsType:
      return {
        ...state,
        campaigns: action.campaigns,
        isLoading: false,
      };
    case requestCampaignsSeeds:
      return {
        ...state,
        isLoadingSeeds: true,
      }
    case receiveCampaignsSeeds:
      return {
        ...state,
        isLoadingSeeds: false,
        seedsCampaigns: action.campaigns
      }
    default:
      return state
  }
};
