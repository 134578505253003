/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import { simulator2Constants } from "../constants/simulator2.constants";
import { GetSafeNumber } from "../helpers";

const constantsValues = [];
for (var i in simulator2Constants) {
  constantsValues.push(simulator2Constants[i])
}
const initialState = {
  clientes: { clients: [], isLoading: false, client: {}, isLoadingClient: false },
  cenarios: { listCenarios: [], isLoading: false, isLoadingEnvio: false, file: null, isLoadingDownload: true },
  simulators: [],
  simulatorsData: {
    id: undefined,
    hasData: false,
    isCpActive: true,
    isSeedsActive: true,
    initCp: false,
    moeda: undefined,
    cenarioV2Cp: {
      campanha: "",
      condicao: "",
      vencimento: "",
      metodoPagamento: "",
      valorizacaoBarter: 0,
    }
  },
  descontos: { soc: [], premium: [] },
  nivelSemaforo: { flexibilidade: [], gmTarget: [] },
  cur: { status: "idle", value: 0 }
};


const clientesReducer = (state, action) => {
  switch (action.type) {
    case simulator2Constants.requestClientsType: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case simulator2Constants.receiveClientsType: {
      return {
        ...state,
        clients: action.clients,
        isLoading: false,
      };
    }
    case simulator2Constants.requestClientType: {
      return {
        ...state,
        client: {},
        isLoadingClient: true,
      };
    }
    case simulator2Constants.receiveClientType: {
      return {
        ...state,
        client: action.client,
        isLoadingClient: false,
      };
    }
    default:
      return state;
  }
}

const cenariosReducer = (state, action) => {
  switch (action.type) {
    case simulator2Constants.requestCenariosType: {
      return {
        ...state,
        listCenarios: [],
        isLoading: true,
      };
    }
    case simulator2Constants.receiveCenarioslType: {
      return {
        ...state,
        listCenarios: action.listCenarios,
        isLoading: false,
      };
    }
    case simulator2Constants.deletarRequestCenarioslType: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case simulator2Constants.deletarCompleteCenarioslType: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case simulator2Constants.cancelarRequestCenarioslType: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case simulator2Constants.cancelarCompleteCenarioslType: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case simulator2Constants.enviarRequestCenarioslType: {
      return {
        ...state,
        isLoadingEnvio: true,
      }
    }
    case simulator2Constants.enviarCompleteCenarioslType: {
      return {
        ...state,
        isLoadingEnvio: false,
      }
    }
    case simulator2Constants.downloadRequestCenarioslType: {
      return {
        ...state,
        isLoadingDownload: true,
      }
    }
    case simulator2Constants.downloadCompleteCenarioslType: {
      return {
        ...state,
        isLoadingDownload: false,
        file: action.file
      }
    }
    default:
      return state;
  }
}

export const CalcRow2 = (row) => {
  row.valorFinal = row.precoNegociado * row.volume;

  row.hectaresTratados = GetSafeNumber(row.volume / row.dose, 0)

  row.msSimulacao = GetSafeNumber(row.hectaresTratados / row.areaSoja, 4);

  row.validPremium = row.premium && row.msSimulacao >= row.msMin;
  // #region Valores definidos para o calculo do valor final corteva
  row.priceEncargo = row.preco * ((row.encargos / 100));

  row.subTotal = row.preco + row.priceEncargo;

  row.descontoPrice = (row.subTotal * (row.descontoCategoria / 100)) * -1;

  row.basePrice = row.subTotal + row.descontoPrice;

  row.descontoIncentivo = row.basePrice * (row.decontoOfertaEstruturada / 100) * -1;

  row.precoCampanhaOE = row.preco + row.priceEncargo + row.descontoPrice + row.descontoIncentivo;

  if (row.tipoIncentivo == 0) {
    row.fatIncentivo = GetSafeNumber(((row.precoCampanhaOE * (1 + row.incentivo)) * row.descVolExaltUtrisha) + ((row.volume - row.descVolExaltUtrisha) * row.precoCampanhaOE));
  } else {
    const incentivo = row.moeda == "BRL" ? row.incentivo : (row.incentivo / row.cur);
    row.fatIncentivo = GetSafeNumber(((row.precoCampanhaOE - incentivo) * row.descVolExaltUtrisha) + ((row.volume - row.descVolExaltUtrisha) * row.precoCampanhaOE));
  }

  row.precoIncentivo = row.descVolExaltUtrisha > 0 ? GetSafeNumber(row.fatIncentivo / row.volume) : row.precoCampanhaOE;

  row.descExaltUtrisha = row.precoIncentivo - row.precoCampanhaOE;

  const finalPrice = row.preco + row.priceEncargo + row.descontoPrice + row.descontoIncentivo + row.descExaltUtrisha;
  // #endregion
  row.valorFinalCorteva = Number(finalPrice.toFixed(2));

  row.cpa = row.precoNegociado - row.valorFinalCorteva;

  row.salesDeduction = GetSafeNumber(row.salesDeductionPer / 100) * row.valorFinal;

  row.netSales = row.valorFinal - row.salesDeduction;

  row.cogsFinal = row.moeda == "USD" ? (row.cogs * row.volume) : (row.cogs * row.cur * row.volume);

  row.gm = row.netSales - row.cogsFinal;

  row.gmPer = GetSafeNumber(row.gm / row.netSales) * 100;

  row.cpaPer = GetSafeNumber(row.cpa / row.subTotal) * 100;

  row.perAtingidoGM = GetSafeNumber(row.gmPer / row.gmTarget) * 100;

  row.perAtingidoCpa = GetSafeNumber(row.cpaPer / (row.cpaMax / 100));

  return row;
}

const SimulatorReducer = (state, action) => {
  state = state || initialState.simulators;

  if (action.type === simulator2Constants.requestItensCenario) {
    return { isLoading: true };
  }
  if (action.type === simulator2Constants.receiveItensCenario) {
    return action.ItensCenario.map(x => { return CalcRow2(x) });
  }
  if (action.type === simulator2Constants.REMOVE_ALL_ROW) {
    return [];
  }
  if (action.type === simulator2Constants.attAllDiscount) {
    for (let i = 0; i < state.length; i++) {
      let row = state[i];
      if (!(row.excluido != undefined && row.excluido)) {
        const title = row.produto.toLowerCase().trim();

        row.decontoOfertaEstruturada = action.decontoOfertaEstruturada;
        if (title.includes("exalt")) {
          row.descVolExaltUtrisha = action.descExalt;
        }
        else if (title.includes("utrisha")) {
          row.descVolExaltUtrisha = action.descUtrisha;
        } else {
          row.descVolExaltUtrisha = 0;
        }

        row.moeda = action.currency;

        row = CalcRow2(row);
      }
    }
  }
  if (action.type === simulator2Constants.changeCondition) {
    for (let i = 0; i < state.length; i++) {
      let row = state[i];
      if (!(row.excluido != undefined && row.excluido)) {

        row.encargos = action.conditions;

        row = CalcRow2(row);
      }
    }
    return [...state];
  }
  if (action.type === simulator2Constants.REMOVE_ROW) {
    const row = state.find((b) => action.produto === b.produto && !(b.excluido != undefined && b.excluido));
    const index = state.findIndex((b) => action.produto === b.produto && !(b.excluido != undefined && b.excluido));
    if (row.analytics) {
      row.motivo = action.motivo;
      row.excluido = true;

      return [
        ...state.slice(0, index),

        {
          ...row,
        },
        ...state.slice(index + 1),
      ];
    } else {
      const rows = state.filter((b) => action.produto !== b.produto && !(b.excluido != undefined && b.excluido));
      //console.log(rows)
      return [...rows];
    }

  }
  if (action.type === simulator2Constants.ADD_ROW) {
    //console.log(state);
    const row = state.find((b) => action.row.produto === b.produto && !(b.excluido != undefined && b.excluido));
    if (row) {
      return state;
    }

    return [...state, action.row];
  }
  if (action.type === simulator2Constants.changeValue) {
    let row = state.find((obj) => action.produto === obj.produto && !(obj.excluido != undefined && obj.excluido));

    row[action.name] = action.value
    row = CalcRow2(row);

    return [...state];
  }
  if (action.type === simulator2Constants.UPDATE_ALL) {
    const rows = state;
    var produtos = action.produtos;
    var moeda = action.currency;
    var moedaA = action.oldCurrency;
    var isDiferent = moedaA !== moeda;
    if (produtos?.length > 0) {
      var updatedRows = rows.map((item) => {
        var product = produtos.find(x => item.produto === x.product);
        if (product !== undefined) {
          item.preco = product.price;
          item.dose = product.dose;
          item.cur = product.cur;
          item.cogs = product.cogs;
          item.gmTarget = product.gmTarget;
          item.cpaMax = product.cpaMax;
          item.moeda = moeda;

          if (isDiferent) {
            item.precoNegociado = 0;
          }
          item.salesDeductionPer = product.salesDeduction;
          item = CalcRow2(item);
          return item;
        }
        return undefined;
      }).filter(item => item !== undefined);

      return updatedRows;
    } else {
      return state;
    }
  }

  return state;
};


export const DescontosReducer = (state, action) => {
  state = state || initialState.descontos;

  if (action.type === simulator2Constants.requestDescontoSocCpType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === simulator2Constants.receiveDescontoSocCpType) {
    return {
      ...state,
      soc: action.desconto,
      isLoading: false,
    };
  }
  if (action.type === simulator2Constants.requestDescontoPremiumCpType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === simulator2Constants.receiveDescontoPremiumCpType) {
    return {
      ...state,
      premium: action.desconto,
      isLoading: false,
    };
  }

  return state;
};

export const NivelSemaforoReducer = (state, action) => {
  state = state || initialState.nivelSemaforo;

  if (action.type === simulator2Constants.requestNivelSemaforoFlexibilidadeType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === simulator2Constants.receiveNivelSemaforoFlexibilidadeType) {
    return {
      ...state,
      flexibilidade: action.nivel,
      isLoading: false,
    };
  }
  if (action.type === simulator2Constants.requestNivelSemaforoGmTargetType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === simulator2Constants.receiveNivelSemaforoGmTargetType) {
    return {
      ...state,
      gmTarget: action.nivel,
      isLoading: false,
    };
  }

  return state;
};

export const SimulatorDataReducer = (state, action) => {
  state = state || initialState.simulatorsData;
  switch (action.type) {
    case simulator2Constants.changeDataValue:
      return {
        ...state,
        [action.name]: action.value,
      };
    case simulator2Constants.changeDataCpValue:
      return {
        ...state,
        cenarioV2Cp: {
          ...state.cenarioV2Cp,
          [action.name]: action.value,
        }
      };
    case simulator2Constants.clearData:
      return {
        ...initialState.simulatorsData
      };
    default:
      return state;
  }
};

export const CurReducer = (state, action) => {
  state = state || initialState.cur;
  switch (action.type) {
    case simulator2Constants.requestCur:
      return {
        ...state,
        status: "loading",
        value: 0,
      };
    case simulator2Constants.receiveCur:
      return {
        ...state,
        status: "idle",
        value: action.value
      };
    case simulator2Constants.failureCur:
      return {
        ...state,
        status: "failure",
        value: 0,
      };
    default:
      return state;
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  if (constantsValues.includes(action.type))
    return {
      ...state,
      clientes: clientesReducer(state.clientes, action),
      cenarios: cenariosReducer(state.cenarios, action),
      simulators: SimulatorReducer(state.simulators, action),
      simulatorsData: SimulatorDataReducer(state.simulatorsData, action),
      descontos: DescontosReducer(state.descontos, action),
      nivelSemaforo: NivelSemaforoReducer(state.nivelSemaforo, action),
      cur: CurReducer(state.cur, action),
    };
  return state;
};
