export const simulator2Constants = {
  requestClientsType: "SIMULADOR_2_REQUEST_CLIENTS",
  receiveClientsType: "SIMULADOR_2_RECEIVE_CLIENTS",
  requestClientType: "SIMULADOR_2_REQUEST_CLIENT",
  receiveClientType: "SIMULADOR_2_RECEIVE_CLIENT",
  requestCenariosType: "REQUEST_CENARIOS",
  receiveCenarioslType: "SIMULADOR_2_RECEIVE_CENARIOS",
  deletarRequestCenarioslType: "SIMULADOR_2_REQUEST_DELETE_CENARIOS",
  deletarCompleteCenarioslType: "SIMULADOR_2_COMPLETE_DELETE_CENARIOS",
  cancelarRequestCenarioslType: "SIMULADOR_2_REQUEST_CANCEL_CENARIOS",
  cancelarCompleteCenarioslType: "SIMULADOR_2_COMPLETE_CANCEL_CENARIOS",
  enviarRequestCenarioslType: "SIMULADOR_2_REQUEST_ENVIAR_CENARIO",
  enviarCompleteCenarioslType: "SIMULADOR_2_COMPLETE_ENVIAR_CENARIO",
  downloadRequestCenarioslType: "SIMULADOR_2_REQUEST_DOWNLOAD_CENARIO",
  downloadCompleteCenarioslType: "SIMULADOR_2_COMPLETE_DOWNLOAD_CENARIO",
  changeValue: "SIMULADOR_2_CHANGE_VALUE",
  changeDataValue: "SIMULADOR_2_DATA_CHANGE_VALUE",
  changeDataCpValue: "SIMULADOR_2_DATA_CP_CHANGE_VALUE",
  clearData: "SIMULADOR_2_DATA_CLEAR",
  changeCondition: "SIMULADOR_2_CHANGE_CONDITION",
  ADD_ROW: "SIMULADOR_2_ADD_ROW",
  REMOVE_ROW: "SIMULADOR_2_REMOVE_ROW",
  attDiscount: "SIMULADOR_2_UPDATE_DISCOUNT",
  attAllDiscount: "SIMULADOR_2_UPDATE_ALL_DISCOUNT",
  UPDATE_ALL: "SIMULADOR_2_UPDATE_ALL",
  REMOVE_ALL_ROW: "SIMULADOR_2_REMOVE_ALL_ROW",
  requestItensCenario: "SIMULADOR_2_REQUEST_ITENS_CENARIO",
  receiveItensCenario: "SIMULADOR_2_RECEIVE_ITENS_CENARIO",
  requestDescontoSocCpType: "SIMULADOR_2_REQUEST_DESCONTO_SOCCP",
  receiveDescontoSocCpType: "SIMULADOR_2_RECEIVE_DESCONTO_SOCCP",
  requestDescontoPremiumCpType: "SIMULADOR_2_REQUEST_DESCONTO_PREMIUMCP",
  receiveDescontoPremiumCpType: "SIMULADOR_2_RECEIVE_DESCONTO_PREMIUMCP",
  requestNivelSemaforoFlexibilidadeType: "SIMULADOR_2_REQUEST_NIVELSEMAFORO_FLEXIBILIDADE",
  receiveNivelSemaforoFlexibilidadeType: "SIMULADOR_2_RECEIVE_NIVELSEMAFORO_FLEXIBILIDADE",
  requestNivelSemaforoGmTargetType: "SIMULADOR_2_REQUEST_NIVELSEMAFORO_GMTARGET",
  receiveNivelSemaforoGmTargetType: "SIMULADOR_2_RECEIVE_NIVELSEMAFORO_GMTARGET",
  requestCreateCenarioType: "SIMULADOR_2_REQUEST_CREATE_CENARIO",
  receiveCreateCenarioType: "SIMULADOR_2_RECEIVE_CREATE_CENARIO",
  requestSaveCenarioType: "SIMULADOR_2_REQUEST_SAVE_CENARIO",
  receiveSaveCenarioType: "SIMULADOR_2_RECEIVE_SAVE_CENARIO",
  requestCur: "SIMULADOR_2_REQUEST_CUR",
  receiveCur: "SIMULADOR_2_RECEIVE_CUR",
  failureCur: "SIMULADOR_2_FAILURE_CUR",
}

