import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Dialog, DialogActions, DialogTitle, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simualdorVe.actions';
import AddIcon from '@mui/icons-material/Add';
import { adminTerritoryActions } from '../../../../../actions/admin/territory.actions';
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        valueIsNumericString
      />
    );
  },
);

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
class AdminSimuladorCreateFornecedorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fornecedor: "",
      grupoMaterial: "",
      areaComercial: "",
      distrito: "",
      territorio: "",
      produto: "",
      preco: "",
      descontoMaximo: "",
      moeda: "",
      addedItems: [],
      produtos: [],
      open: false,
      openExist: false,
      currency: "",
      isLoading: false,
      page: 0,
      rowsPerPage: 10,
      mensagem: "",
      botao: "",
    };

    // this.sendFornecedor = this.sendFornecedor.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchProdutos = this.fetchProdutos.bind(this);
    this.SaveCenario = this.SaveCenario.bind(this);
    this.checkFornecedorGrupoMaterialExists = this.checkFornecedorGrupoMaterialExists.bind(this);
  }

  // componentWillUnmount() {
  //   this.props.actions.clearFornecedor();
  // }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // console.log(params.FornecedorId);
    // if (params.FornecedorId !== undefined && params.FornecedorId !== '') {
    //   this.props.actions.requestFornecedor(params.FornecedorId)
    // }
    this.props.actions.requestFornecedores();
    this.props.actions.requestGrupoMateriais();
    this.props.actions.requestTerritorios();
    this.props.actions.requestDisponilidades();


  }


  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      ...(name === 'areaComercial' ? { distrito: '', territorio: '' } : {}),
      ...(name === 'distrito' ? { territorio: '' } : {}),
    }, () => {
      if (name === 'fornecedor' || name === 'grupoMaterial') {
        this.fetchProdutos();
        this.checkFornecedorGrupoMaterialExists();
      }
    });
  }

  fetchProdutos() {
    const { fornecedor, grupoMaterial } = this.state;
    if (fornecedor && grupoMaterial) {
      this.props.actions.requestProdutosByFornecedorGrupo(fornecedor, grupoMaterial)
        .then((produtos) => {
          this.setState({ produtos });
        });
    } else {
      this.setState({ produtos: [] });
    }
  }

  checkFornecedorGrupoMaterialExists() {
    const { fornecedor, grupoMaterial } = this.state;
    if (fornecedor && grupoMaterial) {
      const disponibilidades = this.props.adminSimuladorVe.disponibilidades;
      const existe = disponibilidades.some(d =>
        d.fornecedorId === fornecedor && d.grupoMaterialId === grupoMaterial
      );

      if (existe) {
        this.setState({
          fornecedor: "",
          grupoMaterial: "",
          openExist: true,
        });
      }
    }
  }

  handleAddItem = () => {

    // var disponibilidades = this.props.adminSimuladorVe.disponibilidades;
    // console.log(disponibilidades);
    // console.log(this.state.fornecedor);
    // console.log(this.state.grupoMaterial);
    // // Verifica se a combinação de fornecedor e grupo material já existe
    // const existe = disponibilidades.some(d =>
    //   d.fornecedorId === this.state.fornecedor && d.grupoMaterialId === this.state.grupoMaterial
    // );

    // if (existe) {

    //   this.setState({ openExist: true });
    // } else {
      this.setState({ isLoading: true });
      const { areaComercial, distrito, territorio, produto, preco, descontoMaximo, moeda } = this.state;
      const produtos = this.props.adminSimuladorVe.produtos ? this.props.adminSimuladorVe.produtos : [];
      const rows = [...this.props.adminSimuladorVe.rows];

      if (!(rows.find((x) => this.state.produto.id === x.produto.id))) {
        this.props.actions.addRow(
          produto,
          produtos,
          areaComercial,
          distrito,
          territorio,
          preco,
          descontoMaximo,
          moeda
        );
      }
      this.setState({ isLoading: false });
   // }
  };


  handleClickOpen = () => {
    this.setState({ open: true });
  };


  handleClose = () => {
    this.setState({ open: false });
    this.setState({ openExist: false });
  };

  handleDeleteRow = (index) => {
    this.props.actions.deleteRow(index);
    this.handleClose();
  };

  SaveCenario = (event) => {
    event.preventDefault();
    const rows = [...this.props.adminSimuladorVe.rows];
    this.props.actions.createSaveDisponibilidade(
      this.state.fornecedor,
      this.state.grupoMaterial,
      rows,
      navigate
    ).then(() => {
      this.props.actions.removeAllRows();
    })
  };

  handleChangeFornecedorGrupoMaterial = (event, opcao) => {
    if (opcao === 1) {
      this.setState({ fornecedor: event });
    }
    if (opcao === 2) {
      this.setState({ grupoMaterial: event });
    }

    if (this.state.fornecedor && this.state.grupoMaterial) {
      var disponibilidades = this.props.adminSimuladorVe.disponibilidades;

      // Verifica se a combinação de fornecedor e grupo material já existe
      const existe = disponibilidades.some(d =>
        d.fornecedorId === this.state.fornecedor && d.grupoMaterialId === this.state.grupoMaterial
      );

      if (existe) {
        this.setState({ openExist: true });
      }
    }
  }


  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var rows = this.props.adminSimuladorVe.rows ? this.props.adminSimuladorVe.rows : [];

    var isLoading = this.props.adminSimuladorVe.isLoadingFornecedor;

    const fornecedor = this.props.adminSimuladorVe.fornecedores ? this.props.adminSimuladorVe.fornecedores : [];
    const grupoMaterial = this.props.adminSimuladorVe.grupoMaterial ? this.props.adminSimuladorVe.grupoMaterial : [];
    const territorios = this.props.adminTerritory.territorios ? this.props.adminTerritory.territorios : [];
    const produtos = this.props.adminSimuladorVe.produtos ? this.props.adminSimuladorVe.produtos : [];


    const areaComercialSet = new Set(territorios
      .filter(x => x.areaName !== "")
      .map(x => x.areaName)
    );
    const distritoSet = new Set(
      territorios
        .filter(x => x.areaName === this.state.areaComercial && x.districtName !== "")
        .map(x => x.districtName)
    );
    const territorioSet = new Set(
      territorios
        .filter(x => x.areaName === this.state.areaComercial && x.districtName === this.state.distrito && x.territoryName !== "")
        .map(x => x.territoryName)
    );
    // console.log(territorios);
    // console.log(areaComercialSet);
    // console.log(distritoSet);
    // console.log("Fornecedor 1: ", fornecedor);
    // console.log(this.state.fornecedor);

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || this.state.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-Precos"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Precos"}>
                Preços
              </Link>
              <Typography color="text.primary">Novo Preço</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <form
            onSubmit={this.SaveCenario}
            autoComplete="off"
          >
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth >
                <InputLabel id="role-label">Fornecedor</InputLabel>
                <Select
                  name="fornecedor"
                  label="Fornecedor"
                  variant="standard"
                  type="text"
                  value={this.state.fornecedor || ""}
                  onChange={this.handleChange}
                // onBlur={formik.handleBlur}
                >
                  {
                    fornecedor.map((x, index) => (
                      <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
                    ))
                  }
                </Select>

              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="role-label">Grupo Material</InputLabel>
                <Select
                  name="grupoMaterial"
                  label="Grupo Material"
                  variant="standard"
                  value={this.state.grupoMaterial || ""}
                  onChange={this.handleChange}
                // onBlur={formik.handleBlur}
                >  {
                    grupoMaterial.map((x, index) => (
                      <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Stack>

            <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem", width: "calc(100%) !important", backgroundColor: "primary", fontWeight: "bold" }}>
              Selecione os parâmetros abaixo:
            </div>

            <Grid direction="row" container spacing={2}>
              <Grid item xs={12} sm={6} md={1.5}>
                <label className="d-block">Área Comercial</label>
                <Select
                  name='areaComercial'
                  value={this.state.areaComercial || ""}
                  placeholder="Área Comercial"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {
                    Array.from(areaComercialSet).map((x) => (
                      <MenuItem value={x}>{x}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={1.5}>
                <label>Distrito</label>
                <Select
                  name='distrito'
                  value={this.state.distrito || ""}
                  placeholder="Distrito"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {
                    Array.from(distritoSet).map((x) => (
                      <MenuItem value={x}>{x}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={1.5}>
                <label>Território</label>
                <Select
                  name='territorio'
                  value={this.state.territorio || ""}
                  placeholder="Território"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {
                    Array.from(territorioSet).map((x) => (
                      <MenuItem key={x} value={x}>{x}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <label>Produto</label>
                <Select
                  name='produto'
                  value={this.state.produto || ""}
                  placeholder="Produto"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  {
                    produtos.map((x, index) => (
                      <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={1}>
                <label>Moeda</label>
                <Select
                  name='moeda'
                  value={this.state.moeda || ""}
                  placeholder="Moeda"
                  onChange={this.handleChange}
                  fullWidth
                  size="small"
                >
                  <MenuItem value="BRL">BRL</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <label>Preço</label>
                <TextField
                  name='preco'
                  // type="number"
                  value={this.state.preco || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={10} sm={10} md={2}>

                <label>Desconto Máximo</label>
                <TextField
                  name='descontoMaximo'
                  // type="number"
                  value={this.state.descontoMaximo || ""}
                  onChange={this.handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>

              <Grid item xs={2} sm={2} md={0.5} sx={{ alignContent: "end" }}>
                <IconButton
                  color="primary"
                  size="large"
                  aria-label="Adicionar"
                  fullWidth
                  onClick={this.handleAddItem}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            {rows.length > 0 && (
              <TableContainer component={Paper} sx={{ marginTop: 4 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Área Comercial</TableCell>
                      <TableCell>Distrito</TableCell>
                      <TableCell>Território</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell>Moeda</TableCell>
                      <TableCell>Preço</TableCell>
                      <TableCell>Desconto Máximo</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.areaComercial}</TableCell>
                        <TableCell>{row.distrito}</TableCell>
                        <TableCell>{row.territorio}</TableCell>
                        <TableCell>{row.produto.nome}</TableCell>
                        <TableCell>{row.moeda}</TableCell>
                        <TableCell>
                          {Number(row.preco).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (row.moeda === "BRL" ? "BRL" : "USD"),
                          })}
                        </TableCell>
                        <TableCell>
                          {Number(row.descontoMaximo).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (row.moeda === "BRL" ? "BRL" : "USD"),
                          })}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton aria-label="delete" size="small" onClick={this.handleClickOpen}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              Deseja realmente exluir este item?
                            </DialogTitle>
                            <DialogActions>
                              <Button onClick={this.handleClose}>Cancelar</Button>
                              <Button variant="contained" onClick={() => this.handleDeleteRow(index)} autoFocus>
                                Exluir
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage="Linhas por página:"
                  component="div"
                  count={rows.length}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={this.state.rowsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage} />
              </TableContainer>
            )}
            {this.state.openExist && (
              <Dialog
                open={this.state.openExist}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Fornecedor e Grupo Material já existem em Preços. Altere pelo menos um para adicionar itens.
                </DialogTitle>
                <DialogActions>
                  <Button onClick={this.handleClose}>Ok</Button>
                </DialogActions>
              </Dialog>
            )}
            {rows.length > 0 && (
              <div>
                <Button sx={{ marginTop: "10px" }} variant="contained" color="primary" fullWidth type="submit">
                  Salvar
                </Button>

              </div>
            )}

          </form>

        </div>
      </div>
    );
  }

}
export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminTerritoryActions,
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminSimuladorCreateFornecedorPage);
