import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'
// import TableFooter from '@mui/material/TableFooter';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { SaveCenarioActions } from "../../../actions/savecenario.actions";
import { clientActions } from "../../../actions/client.actions";
import { cenariosActions } from "../../../actions/cenario.actions";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as SimuladorDDSSvg } from "../../../assets/svg/SimuladorDDS-Cut.svg";
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as CrossSellSvg } from "../../../assets/svg/CrossSell.svg";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from "@mui/material/Grid";
import { GetCenarioActions } from "../../../actions/getcenario.actions";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from '@mui/icons-material/Circle';
import { FormControl, FormControlLabel, FormGroup, Popover, Stack, Switch } from "@mui/material";
import PublishIcon from '@mui/icons-material/Publish';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import FilterListIcon from '@mui/icons-material/FilterList';
import { userActions } from "../../../actions/user.actions";
// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import BlockIcon from '@mui/icons-material/Block';
import ActionDialogButton from "../../ActionDialogButton";
import { zeroPad } from "../../../helpers/utils";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />

      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />

      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`; }

function Row({ row = undefined, groupId = "", remove = () => { }, cancel = (cenarioId = 0, text = "") => { }, actions = undefined, role = undefined, ...props }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [motivo, setMotivo] = React.useState("");
  const handleChangeMotivo = (e) => {
    setMotivo(e.target.value);
  }

  const handleClickOpenDialog = (e) => {
    setMotivo("");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMotivo("");
  };

  const enviarAprovacaoCenario = () => {
    actions.enviarCenario(row.id, row.cenarioId, motivo);
    setOpenDialog(false);
  }

  // const getFormularioCenario = () => {
  //   actions.getFormularioCenario(row.cenarioId);
  // }

  return (
    <React.Fragment>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Deseja realmente enviar o cenário para aprovação?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Insira uma justificativa sobre o envio:
          </DialogContentText>
          <TextField
            autoFocus
            value={motivo}
            onChange={handleChangeMotivo}
            margin="dense"
            id="name"
            label="Justificativa*"
            fullWidth
            variant="standard"
            error={motivo.replaceAll(" ", "").length < 20}
            helperText={motivo.replaceAll(" ", "").length < 20 ? `Quantidade de Caracteres: ${motivo.replaceAll(" ", "").length} de 20` : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={enviarAprovacaoCenario}>Enviar</Button>
        </DialogActions>
      </Dialog>
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          'th': {
            maxWidth: '400px'
          }
        }}
      >
        <TableCell component="th" scope="row" align="center">
          {zeroPad(row.cenarioId, 4)}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.dataCriada}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.campanha}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.produtos}
        </TableCell>
        <TableCell align="right">
          {
            Number(parseFloat(row.valorFinal).toFixed(2)).toLocaleString("pt-br", {
              style: "currency",
              currency: row.moeda,
            })
          }
        </TableCell>
        <TableCell align="center">
          {row.situacaoCenario}
        </TableCell>
        <TableCell align="center">
          <CircleIcon sx={{ color: `${row.corAtingido === 0 ? "#C00000" : row.corAtingido === 1 ? "#FFC000" : row.corAtingido === 2 ? "#00B050" : "var(--bs-border-color)"}` }} />
        </TableCell>
        <TableCell align="center">
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 1.5, md: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Link
              to={`/Cenario?CenarioId=${row.cenarioId}&GroupId=${groupId}`}
            >
              <Tooltip title="Visualizar Cenário">
                <IconButton>
                  <SimuladorDDSSvg style={{ height: "24px", widht: "24px" }} />
                </IconButton>
              </Tooltip>
            </Link>

            {(role?.canEditCenario || role?.isAdmin) ?
              <Link
                to={`/Simulador-Cliente-Edit?GroupId=${groupId}&CloneId=${row.cenarioId}`}
              >
                <Tooltip title="Clonar Cenário">
                  <IconButton>
                    <ContentCopyIcon sx={{ color: "#000" }} />
                  </IconButton>
                </Tooltip>
              </Link>
              :
              <IconButton disabled>
                <ContentCopyIcon />
              </IconButton>
            }
            {((row.situacao === 0 || row.situacao === 1) && (role?.canEditCenario || role?.isAdmin)) ?
              <Link
                to={`/Simulador-Cliente-Edit?GroupId=${groupId}&CenarioId=${row.cenarioId}`}
              >
                <Tooltip title="Editar Cenário">
                  <IconButton>
                    <EditIcon sx={{ color: "#000" }} />
                  </IconButton>
                </Tooltip>
              </Link>
              :
              <IconButton disabled>
                <EditIcon />
              </IconButton>
            }
            {(row.situacao === 1 && (role?.canSendCenario || role?.isAdmin)) ?
              <Tooltip title="Enviar p/ aprovação">
                <IconButton onClick={handleClickOpenDialog}>
                  <PublishIcon sx={{ color: "#000" }} />
                </IconButton>
              </Tooltip>
              :
              // (row.situacao === 4 && (role?.canSendCenario || role?.isAdmin)) ?
              //   <Link
              //     to={`/Cenario-Formulario?CenarioId=${row.cenarioId}&GroupId=${groupId}`}
              //   >
              //     <Tooltip title="Enviar Planilha">
              //       <IconButton>
              //         <AttachEmailIcon sx={{ color: "#000" }} />
              //       </IconButton>
              //     </Tooltip>
              //   </Link>

              //   :
              <IconButton disabled>
                <PublishIcon />
              </IconButton>
            }


            {(row.situacao !== 4 && (role?.canDeleteCenario || role?.isAdmin)) ?
              <Tooltip title="Deletar Cenário">
                <IconButton onClick={(e) => { remove(row.cenarioId) }}>
                  <DeleteIcon sx={{ color: "#000" }} />
                </IconButton>
              </Tooltip>
              :
              <IconButton disabled>
                <DeleteIcon />
              </IconButton>
            }

            {(row.situacao === 4 || row.situacao === 3) ?
              <Tooltip title="Cancelar Cenário">
                <ActionDialogButton
                  ButtonNode={IconButton}
                  sendText="Confirmar"
                  title="Insira o Motivo do Cancelamento"
                  validInput={true}
                  onClick={(motivo) => { cancel(row.cenarioId, motivo) }}
                >
                  <BlockIcon sx={{ color: "#000" }} />
                </ActionDialogButton>
              </Tooltip>
              :
              <IconButton disabled>
                <BlockIcon />
              </IconButton>
            }
          </Stack>
        </TableCell>

      </TableRow>
    </React.Fragment>
  )
}


function CustomPaginationActionsTable({ rows = undefined, remove = () => { }, cancel = (cenarioId = 0, text = "") => { }, actions = undefined, status = [], statusSelected = [], handleChangeSelect = (event) => { }, role = undefined, ...props }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeOpt = (event) => {
    handleChangeSelect(event);
    setPage(0);
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
        <TableHead sx={{
          backgroundColor: "var(--cort-blue)",
          "th": {
            color: "#FFF",
            fontWeight: "bold"
          },
        }}>
          <TableRow>
            <TableCell size="medium" align="center">Simulação</TableCell>
            <TableCell size="medium">Data Simulação</TableCell>
            <TableCell size="medium">Campanha</TableCell>
            <TableCell size="medium">Produtos</TableCell>
            {/* <TableCell size="medium" align="right">Volume Total</TableCell> */}
            <TableCell size="medium" align="right">Valor Final</TableCell>
            <TableCell size="medium" align="center">
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 0, sm: 0.5, md: 1 }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div>Status</div>
                      <Tooltip title="Filtro" >
                        <IconButton {...bindTrigger(popupState)} color="fullWhite" size="small" >
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <FormControl component="fieldset" className="p-1">
                        <FormGroup aria-label="position">
                          {status.map((st) => (
                            <FormControlLabel
                              key={st.codigo}
                              value="top"
                              control={
                                <Switch
                                  checked={statusSelected.indexOf(st.texto) > -1}
                                  size="small"
                                  name={st.texto}
                                  onChange={handleChangeOpt}
                                />
                              }
                              sx={{ marginLeft: 0 }}
                              label={st.texto}
                            // labelPlacement="top"
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Popover>
                  </>
                )}
              </PopupState>
            </TableCell>
            <TableCell size="medium" align="center">Score</TableCell>
            <TableCell size="medium" align="center">
              Ações
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <Row key={index} row={row} groupId={params.GroupId} remove={remove} cancel={cancel} actions={actions} role={role} />
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={defaultLabelDisplayedRows}
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'Linhas por página',
          },
          native: false,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer >

  );
}

class SimulatorClientePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      statusSelected: ["Rascunho", "Salvo", "Aguardando Aprovação", "Aprovado", "Reprovado", "Cancelado"],
      situacao: [
        { texto: "Rascunho", codigo: 0 },
        { texto: "Salvo", codigo: 1 },
        // { texto: "Excluído", codigo: 2 },
        { texto: "Aguardando Aprovação", codigo: 3 },
        { texto: "Aprovado", codigo: 4 },
        { texto: "Reprovado", codigo: 5 },
        { texto: "Cancelado", codigo: 6 },
      ]
    };
    this.removeCenario = this.removeCenario.bind(this);
    this.cancelCenario = this.cancelCenario.bind(this);
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ clientId: params.GroupId });
    // console.log(params.GroupId)

    this.props.actions.requestClients();
    this.props.actions.requestCenarios(params.GroupId);
    this.props.actions.requestRole();
  }

  removeCenario(cenarioId) {
    this.props.actions.deleteCenario(cenarioId, this.props.actions.requestCenarios, this.state.clientId);
  }

  cancelCenario(cenarioId, motivo) {
    this.props.actions.cancelCenario(cenarioId, motivo, this.props.actions.requestCenarios, this.state.clientId);
  }

  handleChangeSelect = (event) => {
    const { name, checked } = event.target;
    let { statusSelected } = this.state;
    if (checked && statusSelected.indexOf(name) === -1) {
      statusSelected.push(name);
    }

    if (!checked && statusSelected.indexOf(name) > -1) {
      statusSelected = statusSelected.filter((b) => b !== name);
    }

    this.setState({
      statusSelected: statusSelected,
    });
  };

  render() {
    // const { oidc } = this.props;
    const { clientId } = this.state;

    var rows = [];
    var client = {};
    var cli = "";
    var territorio = "";
    var distrito = "";
    var role = this.props.user.role?.role;
    var csgLink = ""

    if (!this.props.listCenarios.isLoading && this.props.listCenarios) {
      const statusList = [];
      this.state.situacao.filter((s) => this.state.statusSelected.indexOf(s.texto) > -1).map((st) => statusList.push(st.codigo));

      rows = this.props.listCenarios.listCenarios;

      var rowsFilter = rows.filter((k) => statusList.indexOf(k.situacaoCenario) > -1);

      rows = rowsFilter.map(c => {
        var situacao = this.state.situacao.find(x => x.codigo === c.situacaoCenario)?.texto;
        return {
          "id": clientId,
          "cenarioId": c.cenarioId,
          "situacao": c.situacaoCenario,
          "situacaoCenario": situacao,
          "statusCenario": c.statusCenario,
          "campanha": c.campanha === null ? "" : c.campanha,
          "vencimento": c.vencimento,
          "condicao": c.condicao,
          "distrito": c.distrito,
          "clienteId": c.clienteId,
          "territorio": c.territorio,
          "dataCriada": (new Date(c.dataCriada)).toLocaleDateString(),
          "produtos": c.itensCenarios.filter(x => { return x.volume !== 0 }).map(x => { return x.produto }).join(", "),
          "volumeTotal": c.itensCenarios.reduce((sum, x) => { return sum + x.volume }, 0),
          "valorFinal": c.itensCenarios.reduce((sum, x) => { return sum + x.valorFinal }, 0),
          "moeda": c.moeda === null || c.moeda === undefined ? "USD" : c.moeda,
          "score": c.nivelSemaforoAtt,
          "corAtingido": c.corAtingido,
        }
      })
    }

    if (!this.props.clientes.isLoading && this.props.clientes) {
      // eslint-disable-next-line eqeqeq
      client = this.props.clientes.clients.find(x => x.id == clientId)
      // console.log(clientId, client)
      if (client) {
        cli = client.clientName
        territorio = client.territory;
        distrito = client.district;

        csgLink = `https://app.powerbi.com.mcas.ms/groups/83b365d1-ccde-4480-a7d3-bda8b9ca0769/reports/ad772169-03f1-4ac4-888d-ab4c5e1c00d4/ReportSection821cb735e70de341d5b2?filter=Clientes/group_id eq '${client.clientId}' and Clientes/territorio eq '${client.territory}'`
      }
    }

    // console.log("TERR: " + territorio)
    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.clientes.isLoading || this.props.listCenarios.isLoading || this.props.user.isLoadingRole}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Simulador"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/Simulador"}
              >
                Simulador
              </Link>
              <Typography color="text.primary">Minhas Simulações</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{cli}</h1>
                <Tooltip title="Cross-Sell & Growth">
                  <a className="ml-2" target="_blank" href={csgLink} rel="noreferrer">
                    <CrossSellSvg height={25} fill="#000" />
                  </a>
                </Tooltip>
              </div>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {distrito}</h6>
            </Grid>
            <Grid item xs={2} sm={8} md={6}>
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button component={Link} to={`/CompararCenarioCliente?GroupId=${clientId}`} variant="contained" size="large" className="py-3" color="azul" sx={{ fontSize: "14pt", width: { xs: "100%", sm: "100%", md: "fit-content" }, color: "#fff !important", textAlign: "center" }}>Comparar Cenários</Button>
                {(role?.canCreateCenario || role?.isAdmin) ?
                  <Button component={Link} to={`/Simulador-Cliente?GroupId=${clientId}`} variant="contained" size="large" className="py-3" color="azul" sx={{ fontSize: "14pt", width: { xs: "100%", sm: "100%", md: "fit-content" }, color: "#fff !important", textAlign: "center" }}>Novo Cenário</Button>
                  :
                  <Button disabled variant="contained" size="large" className="py-3" color="azul" sx={{ fontSize: "14pt", width: { xs: "100%", sm: "100%", md: "fit-content" }, textAlign: "center" }}>Novo Cenário</Button>
                }
              </Stack>
            </Grid>
            <Grid item xs={2} sm={8} md={12} >
              <CustomPaginationActionsTable
                rows={rows}
                remove={this.removeCenario}
                cancel={this.cancelCenario}
                actions={this.props.actions}
                statusSelected={this.state.statusSelected}
                status={this.state.situacao}
                handleChangeSelect={this.handleChangeSelect}
                role={role}
              />
            </Grid>
          </Grid>
        </div>
      </div >
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...clientActions,
        ...cenariosActions,
        ...SaveCenarioActions,
        ...GetCenarioActions,
        ...userActions,
      }, dispatch),
    };
  }
)(SimulatorClientePage);
