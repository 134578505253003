const requestNivelSemaforoType = "REQUEST_NIVEL";
const receiveNivelSemaforolType = "RECEIVE_NIVEL";
const initialState = { niveis: [], isLoading: false };

export const nivelSemaforoActions = {
  requestAllNivelSemaforo: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestNivelSemaforoType });

    const url = `/api/NivelSemaforo`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const niveis = await response.json();
    dispatch({ type: receiveNivelSemaforolType, niveis });
  },

  requestAllNivelSemaforoProduto: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestNivelSemaforoType });

    const url = `/api/NivelSemaforo/produto`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const niveis = await response.json();
    dispatch({ type: receiveNivelSemaforolType, niveis });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestNivelSemaforoType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveNivelSemaforolType) {
    return {
      ...state,
      niveis: action.niveis,
      isLoading: false,
    };
  }
  return state;
};
