/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { whitespaceActions } from "../../../actions/whitespace.actions";
import { clientActions } from "../../../actions/client.actions";
import { culturasWhitespaceActions } from "../../../actions/culturasWhitespace.actions";
import { accountWhitespaceActions } from "../../../actions/admin/accountWhitespace.actions";
import axios from "axios";
import {
  polygon as TurfPolygon,
  area as TurfArea,
  within as TurfWithin,
  center as TurfCenter,
  featureCollection,
  point as TurfPoint,
  distance as TurfDistance,
  bbox as TurfBbox,
  intersect as TurfIntersect
} from "@turf/turf";

import Map from "./Map/Map.jsx";
import TerritoryPagination from "./TerritorysPagination.jsx";
import ClientsPagination from "./ClientsPagination.jsx";

import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import "./style.css";
import "../../../index.css";
import { TextField } from "@mui/material";
import Virtualize from "../../AutocompleteVirtual";
import ButtonSelectTerrritory from "../../ButtonSelectTerrritory";
import ButtonSelectSafra from "../../ButtonSelectSafra";
import { userActions } from "../../../actions/user.actions";
import SimpleBoxError from "../../SimpleBoxError";
import FloatingDiv from "../../FloatingDiv.jsx"
import PersonIcon from '@mui/icons-material/Person';
import { isNullOrEmpty } from "../../../helpers/validation.js";
import { spanToSeconds } from "../../../helpers/utils.js";
import configure from "../../../util/configureSignalR.js";

let connection = null;
class WhiteSpacePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupId: "",
      client: null,
      territory: [],
      ibgeTerritory: [],
      municipios: [],
      user: null,

      loadingVu: false,

      isLoading: true,
      isLoadingPlots: true,
      hasPlots: false,

      progressFarm: 0,
      progressPlot: 0,

      markerOn: false,
      marker: null,

      codNameAllTerritory: [],
      codNameTerritoryLoaded: [],

      countFarm: 0,
      valueAreaFarm: 0,
      countAssignedFarm: 0,
      valueAreaAssignedFarm: 0,

      countPlot: 0,
      valueAreaPlot: 0,
      countAssignedPlot: 0,
      valueAreaAssignedPlot: 0,

      pointedCultureArea: 0,
      predictCultureArea: 0,

      showPlots: false,
      showAllData: true,

      isLoadingTerritory: false,
      isLoadingToButton: false,
      isLoadingTerritories: false,

      selectData: null,
      allCountyData: null,
      data: null,
      polygonRegion: null,
      accountData: null,
      assignedData: null,
      allAssignedData: null,
      newestData: null,

      allPlotData: null,
      plotData: null,

      coordinates: [-10.068823732529452, -51.63808832208099],

      accountId: "",
      accountName: "",
      accountUF: "",
      accountIBGE: "",
      accountTerritories: [],
      accountAssigned: false,

      accountsIds: [],
      accountPolygon: null,
      accountsPolygons: [],

      mapR: null,
      drawR: null,
      sourcePolygon: [],
      dataPolygon: [],

      sourcePlotPolygon: [],
      dataPlotPolygon: [],

      territorio: undefined,
      area: undefined,
      distrito: undefined,
      isSetStorage: false,

      error: false,
      errorTitle: "",
      errorMessage: "",
      safra: undefined,

      plotLayer: undefined,
      linePlotLayer: undefined
    };

    this.getUser = this.getUser.bind(this);
    this.copySourceCodes = this.copySourceCodes.bind(this);
    this.setMapbox = this.setMapbox.bind(this);
    this.setDraw = this.setDraw.bind(this);

    this.handleEnableMarker = this.handleEnableMarker.bind(this);
    this.markInMap = this.markInMap.bind(this);
    this.goTo = this.goTo.bind(this);
    this.goToPolygon = this.goToPolygon.bind(this);
    this.goToPolygonWithSource = this.goToPolygonWithSource.bind(this);

    this.findCoordinates = this.findCoordinates.bind(this);
    this.getAllCountyData = this.getAllCountyData.bind(this);

    this.addData = this.addData.bind(this);
    this.tryAddData = this.tryAddData.bind(this);
    this.tryRemoveData = this.tryRemoveData.bind(this);

    this.addDataOuside = this.addDataOuside.bind(this);
    this.tryAddDataOuside = this.tryAddDataOuside.bind(this);

    this.createPolygon = this.createPolygon.bind(this);
    this.createMultiplesPolygons = this.createMultiplesPolygons.bind(this);

    this.selectPolygon = this.selectPolygon.bind(this);
    this.updatePolygon = this.updatePolygon.bind(this);
    this.deletePolygon = this.deletePolygon.bind(this);

    this.selectPlotPolygon = this.selectPlotPolygon.bind(this);
    this.updatePlotPolygon = this.updatePlotPolygon.bind(this);
    this.deletePlotPolygon = this.deletePlotPolygon.bind(this);

    this.fazerAtribuicao = this.fazerAtribuicao.bind(this);
    this.atribuir = this.atribuir.bind(this);
    this.selectAccount = this.selectAccount.bind(this);

    this.desatribuir = this.desatribuir.bind(this);
    this.desatribuirVarios = this.desatribuirVarios.bind(this);

    this.changeMapLoaded = this.changeMapLoaded.bind(this);
    this.changeLoadState = this.changeLoadState.bind(this);
    this.updateMapWithClient = this.updateMapWithClient.bind(this);

    this.buttonAccountPlots = this.buttonAccountPlots.bind(this);
    this.setShowPlots = this.setShowPlots.bind(this);
    this.getAllAssignedPlotVision = this.getAllAssignedPlotVision.bind(this);
    this.getAllAssignedPlotNVision = this.getAllAssignedPlotNVision.bind(this);
    this.getAllAssignedPlots = this.getAllAssignedPlots.bind(this);

    this.getAccounData = this.getAccounData.bind(this);
    this.getPointCultureArea = this.getPointCultureArea.bind(this);
    this.getPredictCultureArea = this.getPredictCultureArea.bind(this);
    this.getAccountsPolygon = this.getAccountsPolygon.bind(this);
    this.getAssignedFarms = this.getAssignedFarms.bind(this);
    this.getAllAssignedFarms = this.getAllAssignedFarms.bind(this);
    this.cleanList = this.cleanList.bind(this);
    this.setSelectData = this.setSelectData.bind(this);

    this.setByStorage = this.setByStorage.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.onChangeSafra = this.onChangeSafra.bind(this);

    this.onChangeDistrito = this.onChangeDistrito.bind(this);
    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);

    this.updateListPlotPolygon = this.updateListPlotPolygon.bind(this);
    this.updateRelationPlotWithFarm = this.updateRelationPlotWithFarm.bind(this);
    this.updateRelationFarmWithPlot = this.updateRelationFarmWithPlot.bind(this);

    this.disablePolygon = this.disablePolygon.bind(this);
    this.disableListPolygon = this.disableListPolygon.bind(this);

    this.disablePlotPolygon = this.disablePlotPolygon.bind(this);
    this.disableListPlotPolygon = this.disableListPlotPolygon.bind(this);

    this.assignInternalPlotData = this.assignInternalPlotData.bind(this);
    this.removerSobreposicoes = this.removerSobreposicoes.bind(this);
  }

  handleOpenError(title, message) {
    this.setState({
      errorTitle: title,
      errorMessage: message,
      error: true
    });
  }
  handleEnableMarker() {
    this.setState({ markerOn: !this.state.markerOn });
  }

  getUser() {
    axios
      .get(`api/whitespace/getUser`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ user: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  copySourceCodes(inPlot) {
    var sources = [];
    if (inPlot) {
      var dataSource = this.state.sourcePlotPolygon;
      dataSource.forEach((x) => {
        sources.push(x);
      });
    } else {
      var dataSource = this.state.sourcePolygon;
      dataSource.forEach((x) => {
        var text = x + ".json";
        sources.push(text);
      });
    }

    navigator.clipboard.writeText(sources);
  }
  setMapbox(value) {
    if (this.state.mapR == null) {
      this.setState({ mapR: value });
    }
  }
  setDraw(value) {
    if (this.state.drawR == null) {
      this.setState({ drawR: value });
    }
  }
  setShowPlots(value, whitespace) {
    this.setState({
      showPlots: value,
      accountData: featureCollection([]),
    });

    var selectData;
    if (value) {
      selectData = featureCollection(this.state.dataPlotPolygon);
      if (whitespace?.length > 0) {
        this.setState({
          polygonRegion: featureCollection(whitespace),
        });
        this.getPlotInWhitespace(whitespace);
        this.getAllAssignedPlotVision(whitespace);
        this.getAllAssignedPlotNVision(whitespace);
      }
    } else {
      selectData = featureCollection(this.state.dataPolygon);
      this.setState({
        polygonRegion: featureCollection([]),
      });
      this.getAssignedFarms(this.state.groupId);

      if (this.state.showAllData) this.getAllAssignedFarms();
    }

    this.setSelectData(selectData);
  }
  markInMap(coord) {
    this.setState({ marker: { latitude: coord[1], longitude: coord[0] } });

    this.setState({ markerOn: true });
    this.setState({ isLoading: false, progressFarm: 0 });
  }
  goTo(layer) {
    var map = this.state.mapR;
    var f;

    if (map == null) return;
    switch (layer) {
      case "data":
        f = this.state.data.features;
        break;
      case "plot":
        f = this.state.plotData.features;
        break;
      case "select":
        f = this.state.selectData.features;
        break;
      case "account":
        f = this.state.accountData.features;
        break;
      case "assigned":
        f = this.state.assignedData.features;
        break;
      default:
        return;
    }
    if (f.length === 0) return;
    var bbox = TurfBbox({
      type: "FeatureCollection",
      features: f,
    });
    map?.fitBounds(bbox, { padding: 20 });
  }
  goToCoord(coord, data) {
    this.setState({ marker: { latitude: coord[1], longitude: coord[0] } });
    this.setState({ markerOn: true });

    const map = this.state.mapR;
    if (map == null) return;

    var point = TurfPoint(coord);
    var bbox = TurfBbox(featureCollection(data.features));

    map.fitBounds(bbox, { padding: 20 });
    this.setState({ isLoading: false, progressFarm: 0 });
  }
  goToPolygon(feature) {
    var map = this.state.mapR;
    if (map == null) return;
    var bbox = TurfBbox({
      type: "FeatureCollection",
      features: [feature],
    });
    map?.fitBounds(bbox, { padding: 20 });
  }
  goToPolygonWithSource(source) {
    var data = this.state.data;
    var feature;
    data.features.forEach((x) => {
      if (x.properties.COD_IMOVEL == source) {
        feature = x;
        return;
      }
    });

    if (feature == null) {
      this.handleOpenError("Aviso - Polígono não carregado", "O Polígono desejado não está visível no momento. Por favor, verifique se o município do polígono desejado está carregado antes de prosseguir.");
    }

    this.goToPolygon(feature);
  }

  setAccountsIds(value) {
    var ids = [];
    for (var i = 0; i < value.length; i++) {
      ids.push(value[i].accountId);
    }

    this.setState({ accountsIds: ids });
  }
  setSelectData(value) {
    this.setState({ selectData: value });
  }
  cleanList(value) {
    switch (value) {
      case "plot":
        this.setState({ sourcePlotPolygon: [] });
        this.setState({ dataPlotPolygon: [] });
        if (this.state.showPlots) {
          this.setState({ selectData: featureCollection([]) });
        }
        break;
      case "data":
        this.setState({ sourcePolygon: [] });
        this.setState({ dataPolygon: [] });
        if (!this.state.showPlots) {
          this.setState({ selectData: featureCollection([]) });
        }
        break;
      default:
        this.setState({ sourcePolygon: [] });
        this.setState({ dataPolygon: [] });
        this.setState({ sourcePlotPolygon: [] });
        this.setState({ dataPlotPolygon: [] });
        this.setState({ selectData: featureCollection([]) });
        break;
    }
  }

  changeMapLoaded(value) {
    this.setState({
      isLoading: value
    });
  }

  changeLoadState(value) {
    this.setState({
      isLoading: value, progressFarm: 0,
      hasPlots: false, isLoadingPlots: value, progressPlot: 0
    });
  }

  changeClient(event) {
    this.setState({
      isLoading: true,
      isLoadingPlots: true,
      error: false
    });

    if (this.state.territory.length <= 0) {
      this.setState({ territory: this.props.whitespace.territory });
    }

    if (event.target.value === this.state.groupId) return;

    this.setState({
      groupId: event.target.value,
      client: this.props.clientes.clients.find(
        (x) => x.clientId === event.target.value
      ),
    });
    this.props.actions.requestAccountsWithId(event.target.value).catch(() => {
      this.handleOpenError("Conta não encontrada", "A visão escolhida não possui contas vinculadas no sistema. Recomendamos entrar em contato com o suporte ou um superior para resolver este problema.");
      this.setState({ isLoading: false, isLoadingPlots: false });
    });
    this.props.actions.getMunicipios().then(() => {
      this.setState({ municipios: this.props.whitespace.municipios });
    });

    this.cleanList();

    this.setState({
      accountId: "",
      accountPolygon: featureCollection([])
    });

    this.setState({ showPlots: false });

    if (this.state.drawR !== null) {
      this.state.drawR.deleteAll();
    }
  }

  updateMapWithClient = async (event) => {
    this.setState({ loadingVu: true });
    this.setShowPlots(false, null);

    await Promise.all([
      this.props.actions.requestIBGEWithId(event.clientId),
      this.props.actions.getMunicipios(event.clientId)
    ]).then(() => {
      this.setState({ municipios: this.props.whitespace.municipios });
    });

    this.setState({ newestData: featureCollection([]) });

    let territory = this.state.territory;
    let newTerritory = this.props.whitespace.territory;
    let loadedTerritories = this.state.codNameTerritoryLoaded;
    let newsTerritories = [];

    let oldValues = [];
    let loadedValue = [];
    let totalValues = [];
    let territories = [];
    let uniqueObject = [];

    loadedTerritories = loadedTerritories.map(x => {
      loadedValue.push(x.codIbge);
      oldValues.push(x.codIbge);
      totalValues.push(x.codIbge);
      return x;
    });

    territory.forEach(x => {
      oldValues.push(x.codIbge);
      totalValues.push(x.codIbge);
      territories.push(x);
    });

    newTerritory.forEach(x => {
      newsTerritories.push(x.codIbge);
      totalValues.push(x.codIbge);
      territories.push(x);
    });

    const uniqueNews = [...new Set(newsTerritories)];
    const uniqueArray = [...new Set(totalValues)];
    let cods = [];

    var difference = uniqueArray.filter((x) => {
      return !oldValues.includes(x);
    });
    var differenceLoad = uniqueNews.filter((x) => {
      return !loadedValue.includes(x);
    });

    if (difference.length > 0) {
      difference.forEach((x) => {
        if (x !== null && x !== "") {
          this.setState({ isLoading: true });

          cods.push(x);
        }
      });

      if (cods.length > 0) {
        this.addData(cods);
      }

      for (let i = 0; i < territories.length; i++) {
        uniqueObject[territories[i].codIbge] = territories[i];
      }

      territory = territory.concat(newTerritory);
      this.setState({ territory: territory });
    } else if (differenceLoad > 0) {
      const ibgeTerritories = [...this.state.ibgeTerritory];
      let newData = this.state.data ? this.state.data.features : [];

      differenceLoad.forEach((x) => {
        ibgeTerritories.forEach((y) => {
          if (x === y[0].codIbge) {
            y[1].features.forEach((z) => {
              newData.push(z);
            });
          }
        });
      });

      this.setState({ data: featureCollection(newData), isLoading: true });

      if (newData.length > 0) {
        const centerCoords = TurfCenter(featureCollection(newData)).geometry.coordinates;
        this.setState({ coordinates: centerCoords });
      }
    } else {
      this.setState({ isLoading: false, progressFarm: 0 });
      uniqueObject = loadedTerritories;
    }

    this.setState({ codNameTerritoryLoaded: uniqueObject });

    this.getAssignedFarms(event.clientId);
    this.addAssignedData(event.clientId, uniqueArray);
    this.addPlotData(uniqueArray);
    this.getAllCountyData(uniqueArray);

    this.setState({ loadingVu: false });
  }

  selectAccount(event, account) {
    if (event.target.checked) {
      this.setState({
        accountId: account.Cod,
        accountName: account.Nome,
        accountUF: account.Uf,
        accountIBGE: account.CodIBGE,
        accountTerritories: account.MunicipiosProximos
      });

      this.getIfAccountAssigned(account.Cod);
      this.getAccountPolygon(account.Cod);
      this.getAccountsPolygon(this.state.groupId);

      if (this.state.showPlots) {
        this.getAccountPlotAssigned(account.Cod);
      } else {
        this.getAccounData(account.Cod);
      }
    } else {
      this.setState({
        accountId: "",
        accountName: "",
        accountIBGE: "",
        accountTerritories: [],
        accountAssigned: false,
        accountData: featureCollection([])
      });
    }
  }
  getIfAccountAssigned(cod) {
    axios
      .get(`/api/whitespace/account/${cod}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ accountAssigned: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  createPolygon(data) {
    if (this.state.showPlots) {
      var farms = this.state.data;
      var oldData = this.state.plotData;
      var oldAllData = this.state.allPlotData;

      const newData = featureCollection([...oldData.features, ...data]);
      const newAllData = featureCollection([...oldAllData.features, ...data]);

      this.setState({
        plotData: newData,
        allPlotData: newAllData
      });

      const centerPoint = TurfCenter(TurfPolygon(data.geometry.coordinates));
      const polyPoint = TurfPoint([centerPoint.geometry.coordinates[0], centerPoint.geometry.coordinates[1]], {
        idAccount: data.properties.ACCOUNT_ID,
        source: data.properties.COD_TALHAO
      });

      var listRelation = [];

      //Encontrar polígono de fazenda
      farms.features.forEach((x) => {
        //Transforma a feature em um polygono
        var poly = TurfPolygon(x.geometry.coordinates);

        //Verifica se é a fazenda do polígono alterado
        if (TurfWithin(polyPoint, poly).features.length > 0) {
          listRelation.push({
            source: x.properties.COD_IMOVEL,
            within: [polyPoint.properties.source],
            status: true
          });
          return;
        }
      });

      this.props.actions.generateRelation(listRelation);

      if (this.state.mapR != null) {
        var layer = this.state.mapR.getSource("plot");
        if (layer != null) {
          layer.setData(newData);
        }
      }

      if (this.state.accountId != "") this.atribuir(data);

      const uf = data.properties.Sigla;
      const Ibge = data.properties.GEOCODIG_M;


      this.props.actions.createPlotPolygon(uf, Ibge, [data]);
    } else {
      var oldData = this.state.data;

      const newData = featureCollection([...oldData.features, ...data]);
      this.updateRelationPlotWithFarm(featureCollection([data]), true);
      this.setState({ data: newData });

      if (this.state.mapR != null) {
        var layer = this.state.mapR.getSource("data");
        if (layer != null) {
          layer.setData(newData);
        }
      }

      if (this.state.accountId != "") this.atribuir(data);

      const uf = data.properties.COD_ESTADO;
      const Ibge = data.properties.COD_IMOVEL.split("-")[1];
      this.props.actions.createPolygon(uf, Ibge, [data]);
    }
  }
  createMultiplesPolygons(data) {
    var newAllPlotData = this.state.allPlotData;
    if (newAllPlotData != null) newAllPlotData = newAllPlotData.features;

    var newPlotData = this.state.plotData;
    if (newPlotData != null) newPlotData = newPlotData.features;

    var newData = this.state.data;
    if (newData != null) newData = newData.features;

    data.forEach((item) => {
      if (this.state.showPlots) {
        newAllPlotData.push(item);
        newPlotData.push(item);
        this.updateRelationFarmWithPlot(item, true);
      } else {
        newData.push(item);
        this.updateRelationPlotWithFarm(featureCollection([item]), true);
      }
    });

    if (this.state.showPlots) {
      newPlotData = featureCollection(newPlotData);
      newAllPlotData = featureCollection(newAllPlotData);

      this.setState({
        plotData: newPlotData,
        allPlotData: newAllPlotData
      });

      if (this.state.mapR != null) {
        var layer = this.state.mapR.getSource("plot");
        if (layer != null) {
          layer.setData(newPlotData);
        }
      }

      const uf = data[0].properties.Sigla;
      const Ibge = data[0].properties.GEOCODIG_M;

      const polygons = data.map(polygon => ({
        Source: polygon.properties.COD_TALHAO,
        Body: JSON.stringify(featureCollection([polygon])),
      }));
      this.props.actions.listCreatePlotPolygon(uf, Ibge, polygons);
    } else {
      newData = featureCollection(newData);

      this.setState({ data: newData });

      if (this.state.mapR != null) {
        var layer = this.state.mapR.getSource("data");
        if (layer != null) {
          layer.setData(newData);
        }
      }

      if (this.state.accountId != "") {
        data.forEach((x) => {
          this.atribuir(x);
        });
      }

      const uf = data[0].properties.COD_ESTADO;
      const Ibge = data[0].properties.COD_IMOVEL.split("-")[1];

      const polygons = data.map(polygon => ({
        Source: polygon.properties.COD_IMOVEL,
        Body: JSON.stringify(featureCollection([polygon])),
      }));
      this.props.actions.listCreatePolygon(uf, Ibge, polygons);
    }
  }
  selectPolygon(id, data, ctrl, shift) {
    var sources = this.state.sourcePolygon;
    var datas = this.state.dataPolygon;

    if ((sources.includes(id) && shift == false) || ctrl == true) {
      var index = sources.indexOf(id);
      if (index > -1) {
        sources.splice(index, 1);
        datas.splice(index, 1);
      }
    } else if (!sources.includes(id)) {
      sources.push(id);
      datas.push(data);
    }

    this.setState({ sourcePolygon: sources });
    this.setState({ dataPolygon: datas });

    if (this.state.mapR != null) {
      var layer = this.state.mapR.getSource("select");
      if (layer != null) {
        layer.setData(featureCollection(datas));
      }
    }
  }
  updatePolygon = async (source, data) => {
    var newData = this.state.data;
    var newAccountData = this.state.accountData;
    var newAssignedData = this.state.assignedData;
    var position;

    newData.features.forEach((y) => {
      if (y.properties.COD_IMOVEL === source) {
        position = newData.features.indexOf(y);
        newData.features[position] = data;

        this.updateRelationPlotWithFarm(featureCollection([y]), true);
        return;
      }
    });

    this.setState({ data: newData });

    newAccountData.features.forEach((y) => {
      var internalSource = y.properties.COD_IMOVEL;
      if (internalSource === source) {
        position = newAccountData.features.indexOf(y);
        newAccountData.features[position] = data;
        return;
      }
    });

    this.setState({ accountData: newAccountData });

    if (this.state.accountId != "") {
      newAssignedData.features.forEach((y) => {
        var internalSource = y.properties.COD_IMOVEL;
        if (internalSource === source) {
          position = newAssignedData.features.indexOf(y);
          newAssignedData.features[position] = data;
          return;
        }
      });

      this.setState({ assignedData: newAssignedData });
    }

    try {
      this.state.mapR.getSource("data").setData(newData);
      this.state.mapR.getSource("assigned").setData(newAssignedData);
      this.state.mapR.getSource("account").setData(newAccountData);
    } catch (e) { }

    this.props.actions
      .updatePolygon(
        this.state.groupId,
        source,
        JSON.stringify(featureCollection([data]))
      )
      .then(() => {
        this.getAccountPolygon(this.state.accountId);
        this.getAccountsPolygon(this.state.groupId);
        this.props.actions.requestAccountsWithId(this.state.groupId);
      });
  }
  updateListPolygon = async (list) => {
    var newData = this.state.data;
    var newAssignedData = this.state.assignedData;
    var newAccountData = this.state.accountData;
    var position;

    var data = list.map((x) => {
      newData.features.forEach((y) => {
        var internalSource = y.properties.COD_IMOVEL;
        if (internalSource === x.Source) {
          position = newData.features.indexOf(y);
          newData.features[position] = x.Body.features[0];

          this.updateRelationPlotWithFarm(y, true);
          return;
        }
      });

      newAssignedData.features.forEach((y) => {
        var internalSource = y.properties.COD_IMOVEL;
        if (internalSource == x.Source) {
          position = newAssignedData.features.indexOf(y);
          newAssignedData.features[position] = x.Body.features[0];
          return;
        }
      });

      if (this.state.accountId != "") {
        newAccountData.features.forEach((y) => {
          var internalSource = y.properties.COD_IMOVEL;
          if (internalSource == x.Source) {
            position = newAccountData.features.indexOf(y);
            newAccountData.features[position] = x.Body.features[0];
            return;
          }
        });
      }

      return { Id: 0, Source: x.Source, Body: JSON.stringify(x.Body) };
    });

    this.setState({ data: newData });
    this.setState({ assignedData: newAssignedData });
    this.setState({ accountData: newAccountData });

    try {
      this.state.mapR.getSource("data").setData(newData);
      this.state.mapR.getSource("assigned").setData(newAssignedData);
      this.state.mapR.getSource("account").setData(newAccountData);
    } catch (e) { }

    await this.props.actions.updateListPolygon(data)
      .then(() => {
        this.getAccountPolygon(this.state.accountId);
        this.getAccountsPolygon(this.state.groupId);

        this.props.actions.requestAccountsWithId(this.state.groupId);
      });
  }

  deletePolygon = async (source) => {
    var newData = this.state.data;
    var update = false;

    newData.features = newData.features.filter((y) => {
      if (y.properties.COD_IMOVEL === source) {
        if (y.properties.ACCOUNT_ID !== null && y.properties.ACCOUNT_ID !== "") {
          update = true;
        }

        return true;
      }

      return false;
    });

    this.setState({ data: newData });
    if (this.state.mapR != null) {
      var layer = this.state.mapR.getSource("data");
      if (layer != null) {
        layer.setData(newData);
      }
    }

    this.props.actions.deletePolygon(this.state.groupId, source).then(() => {
      if (update) {
        this.getAccountPolygon(this.state.accountId);
        this.getAccountsPolygon(this.state.groupId);
        this.props.actions.requestAccountsWithId(this.state.groupId);
      }
    });
  }
  disablePolygon = async (source) => {
    var newData = this.state.data;
    var polygons = [];

    newData.features = newData.features.filter((y) => {
      if (y.properties.COD_IMOVEL === source) {
        polygons.push(y);
        return false;
      }
      return true;
    });

    this.assignInternalPlotData(featureCollection(polygons), false);

    this.setState({ data: newData });

    newData.features.forEach(feature => {
      if (feature.properties.COD_IMOVEL === source) {
        this.updateRelationPlotWithFarm(feature, false);
      }
    });

    if (this.state.mapR != null) {
      var layer = this.state.mapR.getSource("data");
      if (layer != null) {
        layer.setData(newData);
      }
    }

    this.props.actions.disablePolygon(this.state.groupId, source).then(() => {
      this.getAccountPolygon(this.state.accountId);
      this.getAssignedFarms(this.state.groupId);
      this.getAccounData(this.state.accountId);
      this.props.actions.requestAccountsWithId(this.state.groupId);
    });
  }
  disableListPolygon = async (cod) => {
    var polygons = [];

    await axios
      .post(`/api/whitespace/disableListPolygon`, cod, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then(() => {
        var newData = this.state.data;

        newData.features = newData.features.filter((feature) => {
          if (cod.includes(feature.properties.COD_IMOVEL)) {
            polygons.push(feature);
            return false;
          }

          return true;
        });

        if (polygons.length > 0) {
          this.assignInternalPlotData(featureCollection(polygons), false);
        }

        this.setState({ data: newData });

        newData.features.forEach(feature => {
          if (cod.includes(feature.properties.COD_IMOVEL)) {
            this.updateRelationPlotWithFarm(feature, false);
          }
        });
        if (this.state.mapR != null) {
          var layer = this.state.mapR.getSource("data");
          if (layer != null) {
            layer.setData(newData);
          }
        }

        this.getAccountPolygon(this.state.accountId);
        this.getAssignedFarms(this.state.groupId);
        this.getAccounData(this.state.accountId);
        this.props.actions.requestAccountsWithId(this.state.groupId);
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  selectPlotPolygon(id, data, ctrl, shift) {
    var sources = this.state.sourcePlotPolygon;
    var datas = this.state.dataPlotPolygon;

    if ((sources.includes(id) && shift == false) || ctrl == true) {
      var index = sources.indexOf(id);
      if (index > -1) {
        sources.splice(index, 1);
        datas.splice(index, 1);
      }
    } else if (!sources.includes(id)) {
      sources.push(id);
      datas.push(data);
    }

    this.setState({ sourcePlotPolygon: sources });
    this.setState({ dataPlotPolygon: datas });

    if (this.state.mapR != null) {
      var layer = this.state.mapR.getSource("select");
      if (layer != null) {
        layer.setData(featureCollection(datas));
      }
    }
  }
  updatePlotPolygon = async (source, data) => {
    var newData = this.state.plotData;
    var newAllData = this.state.allPlotData;
    var newAssignedData = this.state.assignedData;
    var newAccountData = this.state.accountData;
    let indexToUpdate = 0;

    let matchingFeature = newAllData.features.find(
      (feature) => feature.properties?.COD_TALHAO === source
    );

    if (matchingFeature && data !== undefined) {
      indexToUpdate = newAllData.features.indexOf(matchingFeature);
      newAllData.features[indexToUpdate] = data;

      this.setState({ allPlotData: newAllData });
    }

    matchingFeature = newData.features.find(
      (feature) => feature.properties?.COD_TALHAO === source
    );

    if (matchingFeature && data !== undefined) {
      indexToUpdate = newData.features.indexOf(matchingFeature);
      newData.features[indexToUpdate] = data;
      this.updateRelationFarmWithPlot(newData.features[indexToUpdate], true);

      this.setState({ plotData: newData });
    }

    matchingFeature = newAccountData.features.find(
      (feature) => feature.properties?.COD_TALHAO === source
    );

    if (matchingFeature && data !== undefined) {
      indexToUpdate = newAccountData.features.indexOf(matchingFeature);
      newAccountData.features[indexToUpdate] = data;

      this.setState({ accountData: newAccountData });
    }

    if (this.state.accountId != "") {
      matchingFeature = newAssignedData.features.find(
        (feature) => feature.properties?.COD_TALHAO === source
      );

      if (matchingFeature && data !== undefined) {
        indexToUpdate = newAssignedData.features.indexOf(matchingFeature);
        newAssignedData.features[indexToUpdate] = data;

        this.setState({ assignedData: newAssignedData });
      }
    }

    try {
      this.state.mapR.getSource("plot").setData(newData);
      this.state.mapR.getSource("assigned").setData(newAssignedData);
      this.state.mapR.getSource("account").setData(newAccountData);
    } catch (e) { }

    this.props.actions.updatePlotPolygon(
      this.state.groupId,
      source,
      JSON.stringify(featureCollection([data]))
    );
  }
  updateListPlotPolygon = async (list) => {
    this.setState({ isLoadingButtons: true });

    var newData = this.state.plotData;
    var newAllData = this.state.allPlotData;
    var newAssignedData = this.state.assignedData;
    var newAccountData = this.state.accountData;
    var position;

    var data = list.map((x) => {
      return { Id: 0, Source: x.Source, Body: JSON.stringify(x.Body) };
    });

    this.props.actions.requestAccountsWithId(this.state.groupId);

    newAllData.features.forEach((newFeature) => {
      const currentFeatureCode = newFeature.properties?.COD_TALHAO;
      const matchingListItem = list.find((listItem) => listItem.Source === currentFeatureCode);

      if (matchingListItem) {
        const updatedFeature = matchingListItem.Body.features?.[0];

        if (updatedFeature !== undefined) {
          position = newAllData.features.indexOf(newFeature);
          newAllData.features[position] = updatedFeature;
        }
      }
    });

    this.setState({ allPlotData: newData });

    newData.features.forEach((newFeature) => {
      const currentFeatureCode = newFeature.properties?.COD_TALHAO;
      const matchingListItem = list.find((listItem) => listItem.Source === currentFeatureCode);

      if (matchingListItem) {
        const updatedFeature = matchingListItem.Body.features?.[0];

        if (updatedFeature !== undefined) {
          position = newData.features.indexOf(newFeature);
          newData.features[position] = updatedFeature;

          this.updateRelationFarmWithPlot(newFeature, true);
        }
      }
    });

    this.setState({ plotData: newData });

    newAssignedData.features.forEach((y) => {
      list.forEach((x) => {
        var internalSource = y.properties.COD_TALHAO;
        if (internalSource == x.Source) {
          position = newAssignedData.features.indexOf(y);
          newAssignedData.features[position] = x.Body.features[0];
          return;
        }
      });
    });

    this.setState({ assignedData: newAssignedData });

    if (this.state.accountId != "") {
      newAccountData.features.forEach((y) => {
        list.forEach((x) => {
          var internalSource = y.properties.COD_TALHAO;
          if (internalSource == x.Source) {
            position = newAccountData.features.indexOf(y);
            newAccountData.features[position] = x.Body.features[0];
            return;
          }
        });
      });

      this.setState({ accountData: newAccountData });
    }

    try {
      this.state.mapR.getSource("plot").setData(newData);
      this.state.mapR.getSource("assigned").setData(newAssignedData);
      this.state.mapR.getSource("account").setData(newAccountData);
    } catch (e) { }

    this.props.actions
      .updateListPlotPolygon(data)
      .then(() => {
        this.setState({ isLoadingButtons: false });
      })
      .catch(() => {
        this.setState({ isLoadingButtons: false });
      });
  }
  deletePlotPolygon = async (source) => {
    var newData = this.state.plotData;
    var position;

    newData.features.forEach((y) => {
      var internalSource = y.properties.COD_TALHAO;
      if (internalSource === source) {
        position = newData.features.indexOf(y);
        newData.features.splice(position, 1);
      }
    });

    this.setState({ plotData: newData });
    if (this.state.mapR != null) {
      var layer = this.state.mapR.getSource("plot");
      if (layer != null) {
        layer.setData(newData);
      }
    }
    this.props.actions.deletePlotPolygon(this.state.groupId, source);
  }
  disablePlotPolygon = async (source) => {
    var newData = this.state.plotData;
    var newAllData = this.state.allPlotData;

    newData.features = newData.features.filter((feature) => {
      if (source !== feature.properties.COD_TALHAO) {
        return true;
      }

      return false;
    });

    newAllData.features = newAllData.features.filter((feature) => {
      if (source !== feature.properties.COD_TALHAO) {
        return true;
      }

      return false;
    });

    this.setState({
      plotData: newData,
      allPlotData: newAllData
    });

    if (this.state.mapR != null) {
      var layer = this.state.mapR.getSource("plot");
      if (layer != null) {
        layer.setData(newData);
      }
    }

    this.props.actions.disablePlotPolygon(this.state.groupId, source);
  }
  disableListPlotPolygon = async (cod) => {
    axios
      .post(`/api/whitespace/disableListPlotPolygon`, cod, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then(() => {
        var newData = this.state.plotData;
        var newAllData = this.state.allPlotData;

        newData.features = newData.features.filter((feature) => {
          if (!cod.includes(feature.properties.COD_TALHAO)) {
            return true;
          }

          return false;
        });

        newAllData.features = newAllData.features.filter((feature) => {
          if (!cod.includes(feature.properties.COD_TALHAO)) {
            return true;
          }

          return false;
        });

        this.setState({
          plotData: newData,
          allPlotData: newAllData
        });

        if (this.state.mapR != null) {
          var layer = this.state.mapR.getSource("plot");
          if (layer != null) {
            layer.setData(newData);
          }
        }
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  fazerAtribuicao = async () => {
    if (this.state.accountId !== "") {
      var newAssignedData = this.state.assignedData.features;
      var newAccountData = this.state.accountData.features;
      var position;

      var accountId = this.state.accountId;
      var accountName = this.state.accountName;
      var accountProximidades = this.state.accountTerritories;

      var accountUF = this.state.accountUF;
      var accountIBGE = this.state.accountIBGE;

      var groupId = this.state.groupId;
      var clientName = this.state.client.clientName;
      var userName = this.state.user.name;
      var territoryId = this.state.territorio?.territoryId;

      try {
        if (this.state.showPlots) {
          this.setState({ isLoadingToButton: true });

          var accountData = this.state.accountPolygon;
          var newData = this.state.plotData;
          var dataPolygon = this.state.dataPlotPolygon;
          var sourcePolygon = this.state.sourcePlotPolygon;
          var listPolygons = [];
          var assignList = [];

          sourcePolygon.forEach((internalSource, index) => {

            if (isNullOrEmpty(dataPolygon[index].properties["ACCOUNT_ID"]) ||
              dataPolygon[index].properties["ACCOUNT_ID"] === accountId) {
              dataPolygon[index].properties["ACCOUNT"] = accountName;
              dataPolygon[index].properties["ACCOUNT_ID"] = accountId;
              dataPolygon[index].properties["CLIENT_ID"] = groupId;
              dataPolygon[index].properties["CLIENT_NAME"] = clientName;
              dataPolygon[index].properties["USER_NAME"] = userName;
              dataPolygon[index].properties["TERRITORY_ID"] = territoryId;

              let indexToUpdate = newData.features.findIndex(y => y.properties.COD_TALHAO === internalSource);

              if (indexToUpdate !== -1) {
                newData.features[indexToUpdate] = dataPolygon[index];
              }

              if (newAssignedData != null) {
                newAssignedData = newAssignedData.concat(dataPolygon[index]);
              }

              if (newAccountData != null) {
                newAccountData = newAccountData.concat(dataPolygon[index]);
              }

              listPolygons.push({
                Source: internalSource,
                Id: this.state.accountId,
                Body: JSON.stringify(featureCollection([dataPolygon[index]])),
              });

              var nearPolygon = null;
              var minDistance = Infinity;

              accountData.features.forEach(accountFeature => {
                var point1 = TurfCenter(accountFeature);
                point1 = [point1.geometry.coordinates[0], point1.geometry.coordinates[1]];

                var point2 = TurfCenter(dataPolygon[index]);
                point2 = [point2.geometry.coordinates[0], point2.geometry.coordinates[1]];

                // Assuming accountFeature.geometry is the geometry of the current feature
                const currentDistance = TurfDistance(point1, point2);
                if (currentDistance < minDistance) {
                  minDistance = currentDistance;
                  nearPolygon = accountFeature;
                }
              });

              assignList.push({
                source: nearPolygon.properties.COD_IMOVEL,//Source Fazenda
                within: [internalSource],
                status: true
              });
            } else {
              this.handleOpenError("Aviso - Esse polígono já possui um account atribuido", "O Polígono selecionado já possui um account atribuido, logo, não é possível definir uma atribuição.");
            }
          });

          this.setState({
            plotData: newData,
            assignedData: featureCollection(newAssignedData),
            accountData: featureCollection(newAccountData)
          });

          this.props.actions.generateRelation(assignList);

          this.props.actions.createAccountPlot(listPolygons).then(() => {
            this.props.actions.requestAccountsWithId(this.state.groupId)
              .then(() => {
                this.setState({ isLoadingToButton: false });
              });
          });
        } else {
          this.setState({ isLoadingToButton: true });

          const conditions = this.props.accountWhitespace.conditions;

          const oneToOne = conditions.oneToOneLock;
          const countyLock = conditions.countyAsideLock;
          const stateLock = conditions.stateLock;

          var lockedByState = 0;
          var lockedByCounty = 0;

          var newData = this.state.data;
          var dataPolygon = this.state.dataPolygon;
          var sourcePolygons = this.state.sourcePolygon;
          var assignedAlready = this.state.accountAssigned ? this.state.accountAssigned : false;

          var listPolygon = [];
          var polygons = [];

          if (oneToOne && sourcePolygons.length > 1) {
            this.handleOpenError("Apenas relação 1:1", "É permitido que seja aplicada a associação de contas para somente 1 polígono de fazenda.");
            return;
          }

          sourcePolygons.forEach((internalSource, index) => {

            if (isNullOrEmpty(dataPolygon[index].properties["ACCOUNT_ID"]) ||
              dataPolygon[index].properties["ACCOUNT_ID"] === accountId) {

              if (assignedAlready == false || oneToOne == false) {
                dataPolygon[index].properties["ACCOUNT"] = accountName;
                dataPolygon[index].properties["ACCOUNT_ID"] = accountId;
                dataPolygon[index].properties["CLIENT_ID"] = groupId;
                dataPolygon[index].properties["CLIENT_NAME"] = clientName;
                dataPolygon[index].properties["USER_NAME"] = userName;
                dataPolygon[index].properties["TERRITORY_ID"] = territoryId;

                var polyUf = dataPolygon[index].properties["COD_ESTADO"];
                if (!stateLock && polyUf != accountUF) {
                  lockedByState += 1;
                  return;
                }

                var polyIBGE = dataPolygon[index].properties["COD_IMOVEL"].split("-")[1];
                if (!countyLock && !accountProximidades.find(x => x == polyIBGE) && polyIBGE != accountIBGE) {
                  lockedByCounty += 1;
                  return;
                }

                let indexToUpdate = newData.features.findIndex(y => y.properties.COD_IMOVEL === internalSource);

                if (indexToUpdate !== -1) {
                  newData.features[indexToUpdate] = dataPolygon[index];
                }

                if (newAssignedData != null) {
                  newAssignedData = newAssignedData.concat(dataPolygon[index]);
                }

                if (newAccountData != null) {
                  newAccountData = newAccountData.concat(dataPolygon[index]);
                }

                polygons.push(dataPolygon[index]);
                listPolygon.push({
                  Source: internalSource,
                  Id: this.state.accountId,
                  Body: JSON.stringify(featureCollection([dataPolygon[index]])),
                });

                assignedAlready = true;
              }
              else {
                this.handleOpenError("Fazenda príncipal já definida", "A conta já possui um polígono de fazenda atribuído.");
                return;
              }
            }
            else {
              this.handleOpenError("Polígono já atribuído", "O Polígono selecionado já possui uma conta atribuída, portanto, não é possível definir uma nova atribuição.");
              return;
            }
          });

          if (lockedByCounty > 0) {
            this.handleOpenError("Tentativa de atribuição não permitida", "Os polígonos selecionados para atribuição se encontram fora dos municípios que fazem fronteira com o município denominado da Conta do Salesforce.");
          }

          if (lockedByState > 0) {
            this.handleOpenError("Tentativa de atribuição não permitida", "Os polígonos selecionados para atribuição se encontram fora do estado denominado da Conta do Salesforce.");
          }

          this.setState({
            data: newData,
            assignedData: featureCollection(newAssignedData),
            accountData: featureCollection(newAccountData)
          });

          if (polygons.length > 0) {
            this.assignInternalPlotData(
              featureCollection(polygons),
              true
            );
          }

          this.props.actions.createAccountWhitespace(listPolygon)
            .then(() => {
              this.getAccountPolygon(this.state.accountId);
              this.getAssignedFarms(this.state.groupId);
              this.getAccounData(this.state.accountId);
            })
            .then(() => {
              this.setState({ isLoadingToButton: false });

              this.getIfAccountAssigned(this.state.accountId);
              return this.props.actions.requestAccountsWithId(this.state.groupId);
            })
            .catch(error => {
              this.handleOpenError("Não foi possível atualizar o mapa", "Contate o administrador, erro: " + error);
            });
        }
      } catch (e) {
        this.handleOpenError("Não foi possível realizar a atribuição", "Contate o administrador, erro: " + e);
        this.setState({ isLoadingToButton: false });
      }

      this.cleanList();
    }
  }
  desatribuirVarios = async () => {
    if (this.state.accountId !== "") {
      this.setState({ isLoadingToButton: true });

      try {
        if (this.state.showPlots) {
          var newData = this.state.plotData;
          var newAssignedData = this.state.assignedData;
          var newAccountData = this.state.accountData;
          var position;

          const sourcePlotPolygon = this.state.sourcePlotPolygon;
          var dataPolygon = this.state.dataPlotPolygon;
          var listPolygon = [];

          sourcePlotPolygon.map((internalSource, index) => {
            if (
              isNullOrEmpty(dataPolygon[index].properties["ACCOUNT_ID"]) ||
              dataPolygon[index].properties["ACCOUNT_ID"] ===
              this.state.accountId
            ) {
              dataPolygon[index].properties["ACCOUNT"] = null;
              dataPolygon[index].properties["ACCOUNT_ID"] = null;
              dataPolygon[index].properties["CLIENT_ID"] = null;
              dataPolygon[index].properties["CLIENT_NAME"] = null;
              dataPolygon[index].properties["USER_NAME"] = null;
              dataPolygon[index].properties["TERRITORY_ID"] = null;

              newData.features.map((y) => {
                if (y.properties.COD_TALHAO === internalSource) {
                  position = newData.features.indexOf(y);
                  newData.features[position] = dataPolygon[index];
                  return;
                }
              });
              newAssignedData.features.map((y) => {
                if (y.properties.COD_TALHAO === internalSource) {
                  position = newAssignedData.features.indexOf(y);
                  newAssignedData.features.splice(position, 1);
                  return;
                }
              });
              newAccountData.features.map((y) => {
                if (y.properties.COD_TALHAO === internalSource) {
                  position = newAccountData.features.indexOf(y);
                  newAccountData.features.splice(position, 1);
                  return;
                }
              });

              listPolygon.push({
                Source: internalSource,
                Id: this.state.accountId,
                Body: JSON.stringify(featureCollection([dataPolygon[index]])),
              });
            }
          });

          this.setState({ plotData: newData });
          this.setState({ assignedData: newAssignedData });
          this.setState({ accountData: newAccountData });

          try {
            this.state.mapR.getSource("plot").setData(newData);
            this.state.mapR.getSource("assigned").setData(newAssignedData);
            this.state.mapR.getSource("account").setData(newAccountData);
          } catch (e) { }

          this.props.actions.deleteAccountPlot(listPolygon).then(() => {
            this.props.actions
              .requestAccountsWithId(this.state.groupId)
              .then(() => {
                this.setState({ isLoadingToButton: false });
              });
          });
        } else {
          var newData = this.state.data;
          var newAssignedData = this.state.assignedData;
          var newAccountData = this.state.accountData;

          var position;

          var dataPolygon = this.state.dataPolygon;
          var listPolygon = [];
          var polygons = [];
          this.state.sourcePolygon.map((x, index) => {
            if (
              isNullOrEmpty(dataPolygon[index].properties["ACCOUNT_ID"]) ||
              dataPolygon[index].properties["ACCOUNT_ID"] ===
              this.state.accountId
            ) {
              dataPolygon[index].properties["ACCOUNT"] = null;
              dataPolygon[index].properties["ACCOUNT_ID"] = null;
              dataPolygon[index].properties["CLIENT_ID"] = null;
              dataPolygon[index].properties["CLIENT_NAME"] = null;
              dataPolygon[index].properties["USER_NAME"] = null;
              dataPolygon[index].properties["TERRITORY_ID"] = null;

              newData.features.map((y) => {
                if (
                  y.properties.COD_IMOVEL ===
                  dataPolygon[index].properties.COD_IMOVEL
                ) {
                  position = newData.features.indexOf(y);
                  newData.features[position] = dataPolygon[index];
                  return;
                }
              });
              newAssignedData.features.map((y) => {
                if (
                  y.properties.COD_IMOVEL ===
                  dataPolygon[index].properties.COD_IMOVEL
                ) {
                  position = newAssignedData.features.indexOf(y);
                  newAssignedData.features.splice(position, 1);
                  return;
                }
              });
              newAccountData.features.map((y) => {
                if (
                  y.properties.COD_IMOVEL ===
                  dataPolygon[index].properties.COD_IMOVEL
                ) {
                  position = newAccountData.features.indexOf(y);
                  newAccountData.features.splice(position, 1);
                  return;
                }
              });

              polygons.push(dataPolygon[index]);
              listPolygon.push({
                Source: x,
                Id: this.state.accountId,
                Body: JSON.stringify(featureCollection([dataPolygon[index]])),
              });
            }
          });

          this.setState({ data: newData });
          this.setState({ assignedData: newAccountData });
          this.setState({ accountData: newAccountData });

          if (polygons.length > 0) {
            this.assignInternalPlotData(
              featureCollection(polygons),
              false
            );
          }

          try {
            this.state.mapR.getSource("data").setData(newData);
            this.state.mapR.getSource("assigned").setData(newAssignedData);
            this.state.mapR.getSource("account").setData(newAccountData);
          } catch (e) { }

          this.props.actions.deleteAccountWhitespace(listPolygon)
            .then(() => {
              this.getAccountPolygon(this.state.accountId);
              this.getAssignedFarms(this.state.groupId);
              this.getAccounData(this.state.accountId);
            })
            .then(() => {
              this.setState({ isLoadingToButton: false });

              this.getIfAccountAssigned(this.state.accountId);
              return this.props.actions.requestAccountsWithId(this.state.groupId);
            })
            .catch(error => {
              console.error('Um erro ocorreu:', error);
            });
        }
      } catch (e) {
        console.log("Não foi possivel desatribuir" + e);
        this.setState({ isLoadingToButton: false });
      }

      this.cleanList();
    }
  }

  atribuir = async (polygon) => {
    const id = this.state.accountId;
    const name = this.state.accountName;
    const accountProximidades = this.state.accountTerritories;
    const accountUF = this.state.accountUF;
    const accountIBGE = this.state.accountIBGE;

    const group = this.state.groupId;
    const clientName = this.state.client.clientName;
    const assignedData = this.state.assignedData;
    const userName = this.state.user.name;
    const territoryId = this.state.territorio?.territoryId;

    if (id !== "") {
      if (this.state.showPlots) {
        this.setState({ isLoadingToButton: true });

        var listPolygon = [];

        if (
          isNullOrEmpty(polygon.properties["ACCOUNT_ID"]) ||
          polygon.properties["ACCOUNT_ID"] === id
        ) {
          polygon.properties["ACCOUNT"] = name;
          polygon.properties["ACCOUNT_ID"] = id;
          polygon.properties["CLIENT_ID"] = group;
          polygon.properties["CLIENT_NAME"] = clientName;
          polygon.properties["USER_NAME"] = userName;
          polygon.properties["TERRITORY_ID"] = territoryId;

          listPolygon.push({
            Source: polygon.properties.COD_TALHAO,
            Id: id,
            Body: JSON.stringify(featureCollection([polygon])),
          });

          assignedData.features.push(polygon);
        }

        this.setState({ assignedData: assignedData });

        if (this.state.accountId != null) {
          this.setState({ accountData: assignedData });
        }

        this.props.actions.createAccountPlot(listPolygon).then(() => {
          this.props.actions.requestAccountsWithId(group);
          this.setState({ isLoadingToButton: false });
        });
      } else if (this.state.accountAssigned == false) {
        this.setState({ isLoadingToButton: true });

        var conditions = this.props.accountWhitespace.conditions;
        var oneToOne = conditions.oneToOneLock;
        var countyLock = conditions.countyLock;
        var stateLock = conditions.stateLock;

        var lockedByState = 0;
        var lockedByCounty = 0;

        var assignedAlready = this.state.accountAssigned ? this.state.accountAssigned : false;
        var listPolygon = [];

        if (isNullOrEmpty(polygon.properties["ACCOUNT_ID"]) ||
          polygon.properties["ACCOUNT_ID"] === id) {

          var polyUf = polygon.properties["COD_ESTADO"];
          if (!stateLock && polyUf != accountUF) {
            lockedByState += 1;
            return;
          }

          var polyIBGE = polygon.properties["COD_IMOVEL"].split("-")[1];
          if (!countyLock && !accountProximidades.find(x => x == polyIBGE) && polyIBGE != accountIBGE) {
            lockedByCounty += 1;
            return;
          }

          if (assignedAlready == false || oneToOne == false) {
            polygon.properties["ACCOUNT"] = name;
            polygon.properties["ACCOUNT_ID"] = id;
            polygon.properties["CLIENT_ID"] = group;
            polygon.properties["CLIENT_NAME"] = clientName;
            polygon.properties["USER_NAME"] = userName;
            polygon.properties["TERRITORY_ID"] = territoryId;

            listPolygon.push({
              Source: polygon.properties.COD_IMOVEL,
              Id: id,
              Body: JSON.stringify(featureCollection([polygon])),
            });

            assignedData.features.concat(polygon);

            this.assignInternalPlotData(
              featureCollection([polygon]),
              true
            );

            assignedAlready = true;
          }
          else {
            this.handleOpenError("Fazenda príncipal já definida", "A conta já possui um polígono de fazenda atribuído.");
            return;
          }
        }
        else {
          this.handleOpenError("Polígono já atribuído", "O Polígono selecionado já possui uma conta atribuída, portanto, não é possível definir uma nova atribuição.");
        }

        if (lockedByCounty > 0)
          this.handleOpenError("Tentativa de atribuição não permitida", "Os polígonos selecionados para atribuição se encontram fora dos municipios próximos denominado da Conta do Salesforce.");

        if (lockedByState > 0)
          this.handleOpenError("Tentativa de atribuição não permitida", "Os polígonos selecionados para atribuição se encontram fora do estado denominado da Conta do Salesforce.");


        assignedData = featureCollection(assignedData);
        this.setState({ assignedData: assignedData });

        if (this.state.accountId != null) {
          this.setState({ accountData: assignedData });
        }

        this.props.actions.createAccountWhitespace(listPolygon)
          .then(() => {
            this.getAccountPolygon(this.state.accountId);
            this.getAssignedFarms(this.state.groupId);
            this.getAccounData(this.state.accountId);
          })
          .then(() => {
            this.setState({ isLoadingToButton: false });

            this.getIfAccountAssigned(this.state.accountId);
            return this.props.actions.requestAccountsWithId(this.state.groupId);
          })
          .catch(error => {
            console.error('Um erro ocorreu:', error);
          });
      }
    }
  }
  desatribuir = async (id, source) => {
    if (id.length > 0 && source.length > 0) {
      this.setState({ isLoadingToButton: true });
      if (this.state.showPlots) {
        var listPolygon = [];
        var newData = this.state.plotData;
        var feature;

        newData.features.map((y, index) => {
          if (y.properties.COD_TALHAO === source) {
            feature = newData.features[index];

            if (
              isNullOrEmpty(feature.properties["ACCOUNT_ID"]) ||
              feature.properties["ACCOUNT_ID"] === id
            ) {
              feature.properties["ACCOUNT"] = null;
              feature.properties["ACCOUNT_ID"] = null;
              feature.properties["CLIENT_ID"] = null;
              feature.properties["CLIENT_NAME"] = null;
              feature.properties["USER_NAME"] = null;
              feature.properties["TERRITORY_ID"] = null;

              newData.features[position] = feature;
              listPolygon.push({
                Source: source,
                Id: id,
                Body: JSON.stringify(featureCollection([feature])),
              });
            }
            return;
          }
        });

        this.setState({ plotData: newData });

        this.props.actions.deleteAccountPlot(listPolygon).then(() => {
          this.setState({ isLoadingToButton: false });
          this.props.actions.requestAccountsWithId(this.state.groupId);
        });
      } else {
        var listPolygon = [];
        var newData = this.state.data;
        var feature;
        var position;

        newData.features.map((y) => {
          if (y.properties.COD_IMOVEL === source) {
            position = newData.features.indexOf(y);
            feature = newData.features[position];

            if (
              isNullOrEmpty(feature.properties["ACCOUNT_ID"]) ||
              feature.properties["ACCOUNT_ID"] === id
            ) {
              feature.properties["ACCOUNT"] = null;
              feature.properties["ACCOUNT_ID"] = null;
              feature.properties["CLIENT_ID"] = null;
              feature.properties["CLIENT_NAME"] = null;
              feature.properties["USER_NAME"] = null;
              feature.properties["TERRITORY_ID"] = null;

              newData.features[position] = feature;

              listPolygon.push({
                Source: source,
                Id: id,
                Body: JSON.stringify(featureCollection([feature])),
              });

              this.assignInternalPlotData(featureCollection([feature]), false);
            }
            return;
          }
        });

        this.setState({ data: newData });

        this.props.actions.deleteAccountWhitespace(listPolygon)
          .then(() => {
            this.getAccountPolygon(this.state.accountId);
            this.getAssignedFarms(this.state.groupId);
            this.getAccounData(this.state.accountId);
          })
          .then(() => {
            this.setState({ isLoadingToButton: false });

            this.getIfAccountAssigned(this.state.accountId);
            return this.props.actions.requestAccountsWithId(this.state.groupId);
          })
          .catch(error => {
            console.error('Um erro ocorreu:', error);
          });
      }
    }
  }

  removerSobreposicoes() {
    var newData = this.state.data;
    var finalData = [];
    var list = [];
    var ptsWithin = [];

    newData.features.map((x) => {
      var area = TurfArea(TurfPolygon(x.geometry.coordinates));
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [point.geometry.coordinates[0], point.geometry.coordinates[1]];
      point = TurfPoint(point, { source: x.properties.COD_IMOVEL, area: area });
      list.push(point);
    });
    list = featureCollection(list);

    newData.features.map((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var area = TurfArea(polygon);
      var within = TurfWithin(list, polygon);

      //Verifica se há pontos dentro do poligno
      within.features.map((y) => {
        if (y.properties.source !== x.properties.COD_IMOVEL) {
          if (area > y.properties.area) ptsWithin.push(y.properties.source);
          else ptsWithin.push(x.properties.source);
        }
      });
    });
  }
  setData(data) {
    this.setState({ data: data });

    if (data.features.length > 0) {
      var coord = featureCollection(TurfCenter(data)).features.geometry
        .coordinates;
      this.setState({ coordinates: coord });
    }
  }

  findCoordinates = async (cod, type) => {
    await axios
      .get(`/api/whitespace/territoryByCoord/${cod[0]}/${cod[1]}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (!isNullOrEmpty(json.data)) {
          var latLng = [parseFloat(cod[1]), parseFloat(cod[0])];
          this.tryAddDataOuside(json.data, latLng);
        }
        else {
          var latLng = [parseFloat(cod[1]), parseFloat(cod[0])];
          this.markInMap(latLng);

          this.handleOpenError("Coordenada não encontrada.", "A Coordenada apontada não foi localizada, ou o Whitespace não possui registros disponíveis para a mesma.");
          this.setState({ isLoading: false, progressFarm: 0 });
        }
      })
      .catch((err) => {
        console.error("Could not load data", err);
        var latLng = [parseFloat(cod[1]), parseFloat(cod[0])];
        this.markInMap(latLng);
        this.setState({ isLoading: false, progressFarm: 0 });
      });
  }
  tryAddDataOuside = async (territory, latLng) => {
    var territories = this.props.whitespace.allTerritories;
    var unique = this.state.codNameTerritoryLoaded;
    let exist = false;
    let cods = [];

    if (isNullOrEmpty(territory))
      return;

    territories.push(territory);

    exist = unique.some((x) => territory.codIbge == x.codIbge);

    if (!exist) {
      unique.map((x) => {
        cods.push(x.codIbge);
      });

      territories.map((x) => {
        if (x.codIbge === territory.codIbge) {
          unique[territory.codIbge] = x;
          cods.push(x.codIbge);
        }
      });

      this.setState({ codNameTerritoryLoaded: unique });
      this.addDataOuside(territory.codIbge, latLng);
      this.addAssignedData(this.state.groupId, cods);
      this.addPlotData(cods);
      this.getAllCountyData(cods);
    }
    if (exist || cods.length <= 0) {
      this.changeLoadState(false);
    }
  }
  addDataOuside = async (cod, latLng) => {
    await axios
      .post(`/api/whitespace/whitespaceByIbge/`, [cod], {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
          "Content-Type": "application/json",
        },
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
          const progress = Math.round((progressEvent.loaded * 100) / total);
          this.setState({ progressFarm: `Baixando... ${progress}%` });
        },
      })
      .then((json) => {
        var data = this.state.data;
        var newestData = this.state.newestData;
        var refData = this.state.ibgeTerritory;

        var newRef = [];
        refData.map((y) => {
          newRef.push(y);
        });

        if (data === null || data.length == 0 || data === undefined)
          data = featureCollection([]);

        if (newestData === null || newestData.length == 0 || newestData === undefined)
          newestData = featureCollection([]);

        var newData = data.features.concat(json.data.features);
        var oldData = newestData.features.concat(json.data.features);

        newData = featureCollection(newData);
        oldData = featureCollection(oldData);
        newRef.push({ codIbge: cod, collection: featureCollection(json.data.features) });

        this.setState({ data: newData });
        this.setState({ newestData: oldData });
        this.setState({ ibgeTerritory: newRef });

        this.goToCoord(latLng, json.data);
      })
      .catch((err) => {
        this.handleOpenError("Município indisponível.", "O Whitespace não possui registro deste município no momento.");
        this.setState({ isLoading: false, progressFarm: 0 });
      });
  }

  tryAddData = async (cod) => {
    var territories = this.props.whitespace.allTerritories;
    var unique = this.state.codNameTerritoryLoaded;

    let exist = false;
    let cods = [];

    exist = unique.some((x) => cod.includes(x.codIbge));

    if (!exist) {
      unique.map((x) => {
        cods.push(x.codIbge);
      });

      territories.map((x) => {
        cod.map(y => {
          if (x.codIbge === y) {
            unique[y] = x;
            cods.push(y);
          }
        })
      });

      this.setState({ codNameTerritoryLoaded: unique });

      this.addData(cod);
      this.addAssignedData(this.state.groupId, cods);
      this.addPlotData(cods);
      this.getAllCountyData(cods);
    }
    if (exist || cods.length <= 0) {
      this.changeLoadState(false);
    }
  }
  addData = async (cods) => {
    connection?.on("whitespaceLayer1", message => {
      const timespan = spanToSeconds(message);
      this.setState({ progressFarm: timespan });
    });

    await axios
      .post(`/api/whitespace/whitespaceByIbge`, JSON.stringify(cods), {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
          "Content-Type": "application/json",
        },
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
          const progress = Math.round((progressEvent.loaded * 100) / total);
          this.setState({ progressFarm: `Baixando... ${progress}%` });
        },
      })
      .then((json) => {
        var data = this.state.data;
        var newestData = this.state.newestData;
        var refData = this.state.ibgeTerritory;

        var newRef = [];
        refData.map((y) => {
          newRef.push(y);
        });

        if (data === null || data.length <= 0 || data === undefined)
          data = featureCollection([]);

        if (
          newestData === null ||
          newestData.length <= 0 ||
          newestData === undefined
        )
          newestData = featureCollection([]);

        const newData = featureCollection(data.features.concat(json.data.features));
        const oldData = featureCollection(newestData.features.concat(json.data.features));

        json.data.features.forEach(function (feature) {
          const codIbge = feature.properties.COD_IMOVEL.split("-")[1];

          const existingEntry = newRef.find(function (entry) {
            return entry.codIbge === codIbge;
          });

          if (!existingEntry) {
            newRef.push({ codIbge: codIbge, collection: featureCollection([feature]) });
          }
        });

        this.setState({
          data: newData,
          newestData: oldData,
          ibgeTerritory: newRef
        });

        if (oldData.features.length > 0) {
          const coord = featureCollection(TurfCenter(oldData)).features.geometry.coordinates;
          this.setState({ coordinates: coord });
        }

        connection?.off("whitespaceLayer1");
      })
      .catch((err) => {
        console.error("Não foi possivel carregar os dados, erro: ", err);
        this.handleOpenError(
          "Aviso: Não foi possível carregar a camada de fazendas.",
          "O tempo limite pode ter sido atingido ou um erro inesperado pode ter ocorrido. Tente novamente. Se não é a primeira vez vendo este erro, entre em contato com seu superior ou suporte."
        );
      });
  }
  addAssignedData(id, cod) {
    axios
      .post(`/api/whitespace/AllAssignedWhitespacesByIbge/${id}`, cod, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        var newData = [];

        if (json.data == null) {
          newData = featureCollection([]);
        }
        else {
          newData = json.data;
        }

        this.setState({ allAssignedData: newData });
      })
      .catch((err) => {
        console.error("Não foi possivel carregar os dados de atribuição, erro: ", err);
        this.handleOpenError(
          "Aviso: Não foi possível carregar a camada de atribuições de fazendas.",
          "O tempo limite pode ter sido atingido ou um erro inesperado pode ter ocorrido. Tente novamente. Se não é a primeira vez vendo este erro, entre em contato com seu superior ou suporte."
        );
      });
  }
  addPlotData = async (cod) => {
    connection?.on("whitespaceLayer2", message => {
      const timespan = spanToSeconds(message);
      this.setState({ progressPlot: timespan });
    });

    await axios
      .post(`/api/whitespace/PlotByIbge`, cod,
        {
          headers: {
            Accept: "application/json",
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.oidc.user.access_token}`,
          },
          onDownloadProgress: (progressEvent) => {
            const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
            const progress = Math.round((progressEvent.loaded * 100) / total);
            this.setState({ progressPlot: `Baixando... ${progress}%` });
          },
        })
      .then((json) => {
        const { features: existingFeatures } = featureCollection([]);
        const { features: newFeatures } = json.data;

        const allFeatures = existingFeatures.concat(newFeatures);
        const updatedData = featureCollection(allFeatures);

        this.setState({
          allPlotData: updatedData,
          isLoadingPlots: false,
          progressPlot: 0,
          hasPlots: true
        });
      })
      .catch((err) => {
        console.error("Não foi possivel carregar a camada de talhões, erro: ", err);
        this.handleOpenError(
          "Aviso: Não foi possível carregar a camada de talhões.",
          "O tempo limite pode ter sido atingido ou um erro inesperado pode ter ocorrido. Tente novamente. Se não é a primeira vez vendo este erro, entre em contato com seu superior ou suporte."
        );
        this.setState({
          isLoadingPlots: false,
          progressPlot: 0,
          hasPlots: false
        });
      });

    connection?.off("whitespaceLayer2");
  }
  addAssignedPlotData = async (id, cod) => {
    await axios
      .post(`/api/whitespace/AllAssignedPlotsByIbge/${id}`, cod,
        {
          headers: {
            Accept: "application/json",
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.oidc.user.access_token}`,
          }
        })
      .then((json) => {
        var newData = [];

        if (json.data == null) {
          newData = featureCollection([]);
        }
        else {
          newData = featureCollection(json.data.features);
        }

        this.setState({ allAssignedData: newData });
      })
      .catch((err) => {
        console.error("Não foi possivel carregar atribuições de talhões, erro: ", err);
        this.handleOpenError(
          "Aviso: Não foi possível carregar a camada de atribuições de talhões.",
          "O tempo limite pode ter sido atingido ou um erro inesperado pode ter ocorrido. Tente novamente. Se não é a primeira vez vendo este erro, entre em contato com seu superior ou suporte."
        );
      });
  }

  tryRemoveData(cod) {
    this.removeData(cod);
    this.removeAssignedData(cod);
  }
  removeData(cod) {
    const { data, allCountyData, codNameTerritoryLoaded, mapR } = this.state;

    const newData = featureCollection(data?.features?.filter(x => {
      const source = parseInt(x.properties.COD_IMOVEL.split("-")[1]);
      return source !== cod;
    }));

    const newCountyData = featureCollection(allCountyData?.features?.filter(x => {
      const source = parseInt(x.properties.GEOCODIG_M);
      return source !== cod;
    }));

    const updatedCodName = codNameTerritoryLoaded.filter(x => x.codIbge !== cod);

    this.setState({
      data: newData,
      allCountyData: newCountyData,
      codNameTerritoryLoaded: updatedCodName
    });

    mapR.getSource("data").setData(newData);
  }
  removeAssignedData(cod) {
    const { allAssignedData, codNameTerritoryLoaded } = this.state;

    const newData = featureCollection(allAssignedData?.features?.filter(x => {
      const source = parseInt(x.properties.COD_IMOVEL.split("-")[1]);
      return source !== cod;
    }));

    const updatedCodName = codNameTerritoryLoaded.filter(x => x.codIbge !== cod);
    this.setState({
      allAssignedData: newData,
      codNameTerritoryLoaded: updatedCodName
    });
  }
  getAllCountyData(cods) {
    axios
      .post(`api/whitespace/whitespaceMunicipios`, cods, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ allCountyData: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  getAccountsPolygon(groupId) {
    axios
      .get(`api/whitespace/assignedWhitespaces/${groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (!this.state.showPlots)
          this.setState({ accountsPolygons: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAccountPolygon(accountId) {
    axios
      .get(`api/whitespace/accountWhitespace/${accountId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ accountPolygon: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAccounData(accountId) {
    axios
      .get(`api/whitespace/accountWhitespace/${accountId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ accountData: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  //Pegar os talhões de uma Account
  getAccountPlot() {
    //Pega todos os talhões proximos e internos aos poligonos da Account
    const account = this.state.accountId;
    const plotData = this.state.allPlotData;
    const accountPolygon = this.state.accountPolygon;

    var ptsWithin = [];
    var result = [];

    try {
      plotData.features.map((x) => {
        accountPolygon.features.map((y) => {
          const accountPolygon = TurfPolygon(y.geometry.coordinates);
          const polygon = TurfPolygon(x.geometry.coordinates);

          const intersection = TurfIntersect(accountPolygon, polygon);
          if (intersection != null) {
            const sharedArea = TurfArea(intersection);
            const originalArea = TurfArea(polygon);
            const threshold = 0.5; // 50%

            if (sharedArea / originalArea >= threshold) {
              ptsWithin.push(x.properties.COD_TALHAO);
            }
          }
        });
      });

      plotData.features.forEach((x) => {
        var source = x.properties.COD_TALHAO;
        if (ptsWithin.includes(source) || x.properties.ACCOUNT_ID == account)
          result.push(x);
      });

      result = featureCollection(result);
      this.setState({ plotData: result });
    } catch (e) {
      console.log(e);
    }

    this.setState({ isLoadingToButton: false });
  }
  getAccountPlotAssigned = async (accountId) => {
    //Pega todos os talhões proximos e internos aos poligonos da Account
    if (!this.state.showPlots)
      return;

    const accountPolygon = this.state.accountPolygon;
    const plotData = this.state.allPlotData;

    var ptsWithin = [];
    var result = [];

    plotData.features.map((x) => {
      accountPolygon.features.map((y) => {
        const accountPolygon = TurfPolygon(y.geometry.coordinates);
        const polygon = TurfPolygon(x.geometry.coordinates);

        const intersection = TurfIntersect(accountPolygon, polygon);
        if (intersection != null) {
          const sharedArea = TurfArea(intersection);
          const originalArea = TurfArea(polygon);
          const threshold = 0.5; // 50%

          if (sharedArea / originalArea >= threshold) {
            ptsWithin.push(x.properties.COD_TALHAO);
          }
        }
      });
    });

    plotData.features.forEach((x) => {
      var source = x.properties.COD_TALHAO;
      if (ptsWithin.includes(source) || x.properties.ACCOUNT_ID == accountId)
        result.push(x);
    });

    result = featureCollection(result);
    this.setState({ accountData: result });
  }
  getAssignedVisionPlotsInternal = async () => {
    await axios
      .get(`api/whitespace/plotAllClientsAccounts/${this.state.groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (!this.state.showPlots)
          return;

        var list = [];
        var ptsWithin = [];
        var result = [];

        json.data.features.forEach((x) => {
          var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
          point = [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ];
          point = TurfPoint(point, {
            source: x.properties.COD_TALHAO,
          });
          list.push(point);
        });
        list = featureCollection(list);

        this.state.accountPolygon.features.forEach((x) => {
          var polygon = TurfPolygon(x.geometry.coordinates);
          var within = TurfWithin(list, polygon);

          //Verifica se há pontos em uma X distancia
          const distance = 4;
          for (var i = 0; i < list.features.length; i++) {
            var byDistance = TurfDistance(
              TurfPoint(list.features[i].geometry.coordinates),
              TurfCenter(polygon)
            );
            if (byDistance <= distance) {
              ptsWithin.push(list.features[i].properties.source);
            }
          }

          //Verifica se há pontos dentro do poligno
          within.features.forEach((x) => {
            ptsWithin.push(x.properties.source);
          });
        });

        json.data.features.forEach((x) => {
          var source = x.properties.COD_TALHAO;
          if (ptsWithin.includes(source)) result.push(x);
        });

        result = featureCollection(result);
        this.setState({ assignedData: result });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAssignedNVisionPlotsInternal = async () => {
    await axios
      .get(`api/whitespace/plotAllAccounts/${this.state.groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (!this.state.showPlots)
          return;

        var list = [];
        var ptsWithin = [];
        var result = [];

        json.data.features.forEach((x) => {
          var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
          point = [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ];
          point = TurfPoint(point, {
            source: x.properties.COD_TALHAO,
          });
          list.push(point);
        });
        list = featureCollection(list);

        this.state.accountPolygon.features.forEach((x) => {
          var polygon = TurfPolygon(x.geometry.coordinates);
          var within = TurfWithin(list, polygon);

          //Verifica se há pontos em uma X distancia
          const distance = 4;
          for (var i = 0; i < list.features.length; i++) {
            var byDistance = TurfDistance(
              TurfPoint(list.features[i].geometry.coordinates),
              TurfCenter(polygon)
            );
            if (byDistance <= distance) {
              ptsWithin.push(list.features[i].properties.source);
            }
          }

          //Verifica se há pontos dentro do poligno
          within.features.forEach((x) => {
            ptsWithin.push(x.properties.source);
          });
        });

        json.data.features.forEach((x) => {
          var source = x.properties.COD_TALHAO;
          if (ptsWithin.includes(source)) result.push(x);
        });

        result = featureCollection(result);
        this.setState({ allAssignedData: result });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  //Assinalação via >50% área
  assignInternalPlotData = async (accountPolygon, assign) => {
    //Pega todos os dados estáticos necessários
    const nameAccount = this.state.accountName;
    const groupId = this.state.groupId;
    const clientName = this.state.client.clientName;
    const userName = this.state.user.name;
    const json = this.state.allPlotData;

    var idAccount = this.state.accountId;
    var newData = json;

    if (json == null) return;

    var ptsWithin = [];

    json.features.map((x) => {
      accountPolygon.features.map((y) => {
        //Caso não haja account selecionada pega o valor do polígono
        if (idAccount == "" || idAccount == null) {
          idAccount = y.properties.ACCOUNT_ID;
        }

        if (!isNullOrEmpty(x.properties.ACCOUNT_ID) && x.properties.ACCOUNT_ID != idAccount) {
          return;
        }

        const accountPolygon = TurfPolygon(y.geometry.coordinates);
        const polygon = TurfPolygon(x.geometry.coordinates);

        const intersection = TurfIntersect(accountPolygon, polygon);
        if (intersection != null) {
          const sharedArea = TurfArea(intersection);
          const originalArea = TurfArea(polygon);
          const threshold = 0.5; // 50%

          if (sharedArea / originalArea >= threshold) {
            ptsWithin.push(x.properties.COD_TALHAO);
          }
        }
      });
    });

    // Remove duplicidade
    ptsWithin = [...new Set(ptsWithin)];

    if (ptsWithin.length > 0) {
      var listPlot = [];

      //Atualiza os dados dos talhões em relação de atribuir ou desatribuir
      json.features.forEach((x, index) => {
        var internalSource = x.properties.COD_TALHAO;
        ptsWithin.forEach((y) => {
          if (y === internalSource) {
            var promisedData = newData.features[index];

            //Caso a operação seja de atribuir inseri os dados ao talhão, caso contrário limpa os mesmos.
            if (assign) {
              promisedData.properties["ACCOUNT"] = nameAccount;
              promisedData.properties["ACCOUNT_ID"] = idAccount;
              promisedData.properties["CLIENT_ID"] = groupId;
              promisedData.properties["CLIENT_NAME"] = clientName;
              promisedData.properties["USER_NAME"] = userName;
              promisedData.properties["TERRITORY_ID"] = null;
            } else {
              promisedData.properties["ACCOUNT"] = null;
              promisedData.properties["ACCOUNT_ID"] = null;
              promisedData.properties["CLIENT_ID"] = null;
              promisedData.properties["CLIENT_NAME"] = null;
              promisedData.properties["USER_NAME"] = null;
              promisedData.properties["TERRITORY_ID"] = null;
            }

            listPlot.push({
              Source: y,
              Id: idAccount,
              Body: JSON.stringify(featureCollection([promisedData])),
            });
          }
        });
      });

      //Se a operação for de atribuição envia um comando para criar as contas.
      if (assign) {
        await this.props.actions.createAccountPlot(listPlot);
      } else {
        await this.props.actions.deleteAccountPlot(listPlot);
      }
    }
  }

  //Assinalação via centroide
  /*assignInternalPlotData = async (accountPolygon, assign) => {
    //Pega todos os dados estáticos necessários
    const nameAccount = this.state.accountName;
    const groupId = this.state.groupId;
    const clientName = this.state.client.clientName;
    const userName = this.state.user.name;
    const json = this.state.allPlotData;

    var idAccount = this.state.accountId;
    var newData = json;

    if (json == null) return;

    var list = [];
    var ptsWithin = [];

    json.features.map((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [point.geometry.coordinates[0], point.geometry.coordinates[1]];
      point = TurfPoint(point, {
        idAccount: x.properties.ACCOUNT_ID,
        source: x.properties.COD_TALHAO,
      });
      list.push(point);
    });
    list = featureCollection(list);

    accountPolygon.features.map((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var within = TurfWithin(list, polygon);

      //Caso não haja account selecionada pega o valor do polígono
      if (idAccount == "" || idAccount == null) {
        idAccount = x.properties.ACCOUNT_ID;
      }

      //Verifica se o talhão utiliza da mesma account
      list.features.map((y) => {
        if (
          y.properties.idAccount != null &&
          y.properties.idAccount == idAccount
        ) {
          ptsWithin.push(y.properties.source);
        }
      });

      //Verifica se há pontos dentro do poligno
      ptsWithin = within.features.map((y) => y.properties.source);
      ptsWithin = [...new Set(ptsWithin)]; // Remove duplicata

      // x.properties.PLOT_COUNT = within.features.length;
      // listPolygons.push({
      //   Source: x.properties.COD_IMOVEL,
      //   Body: featureCollection([x]),
      // });
    });

    if (ptsWithin.length > 0) {
      var listPlot = [];

      //Atualiza os dados dos talhões em relação de atribuir ou desatribuir
      json.features.forEach((x, index) => {
        var internalSource = x.properties.COD_TALHAO;
        ptsWithin.forEach((y) => {
          if (y === internalSource) {
            var promisedData = newData.features[index];

            //Caso a operação seja de atribuir inseri os dados ao talhão, caso contrário limpa os mesmos.
            if (assign) {
              promisedData.properties["ACCOUNT"] = nameAccount;
              promisedData.properties["ACCOUNT_ID"] = idAccount;
              promisedData.properties["CLIENT_ID"] = groupId;
              promisedData.properties["CLIENT_NAME"] = clientName;
              promisedData.properties["USER_NAME"] = userName;
              promisedData.properties["TERRITORY_ID"] = null;
            } else {
              promisedData.properties["ACCOUNT"] = null;
              promisedData.properties["ACCOUNT_ID"] = null;
              promisedData.properties["CLIENT_ID"] = null;
              promisedData.properties["CLIENT_NAME"] = null;
              promisedData.properties["USER_NAME"] = null;
              promisedData.properties["TERRITORY_ID"] = null;
            }

            listPlot.push({
              Source: y,
              Id: idAccount,
              Body: JSON.stringify(featureCollection([promisedData])),
            });
          }
        });
      });

      //Se a operação for de atribuição envia um comando para criar as contas.
      if (assign) {
        await this.props.actions.createAccountPlot(listPlot);
      } else {
        await this.props.actions.deleteAccountPlot(listPlot);
      }
    }
  }*/

  //Atualiza o relacionamento dos talhões através do polígono de fazenda
  updateRelationPlotWithFarm = async (polygon, status) => {
    //Pega a layer completa de talhões
    const plots = this.state.allPlotData;

    if (plots == null) return;

    var listRelation = [];
    var listPolygon = [];

    polygon.features.map((y) => {
      var ptsWithin = [];

      plots.features.map((x) => {
        //Caso não haja account selecionada pega o valor do polígono
        if (!isNullOrEmpty(x.properties.ACCOUNT_ID)) {
          if (!isNullOrEmpty(y.properties.ACCOUNT_ID) && x.properties.ACCOUNT_ID != y.properties.ACCOUNT_ID) {
            return;
          }
        }

        const accountPolygon = TurfPolygon(y.geometry.coordinates);
        const polygon = TurfPolygon(x.geometry.coordinates);

        const intersection = TurfIntersect(accountPolygon, polygon);
        if (intersection != null) {
          const sharedArea = TurfArea(intersection);
          const originalArea = TurfArea(polygon);
          const threshold = 0.5; // 50%

          if (sharedArea / originalArea >= threshold) {
            if (isNullOrEmpty(x.properties.ACCOUNT_ID)) {
              const accountId = y.properties.ACCOUNT_ID;

              x.properties["ACCOUNT"] = y.properties.ACCOUNT;
              x.properties["ACCOUNT_ID"] = accountId;
              x.properties["CLIENT_ID"] = y.properties.CLIENT_ID;
              x.properties["CLIENT_NAME"] = y.properties.CLIENT_NAME;
              x.properties["USER_NAME"] = y.properties.USER_NAME;
              x.properties["TERRITORY_ID"] = y.properties.TERRITORY_ID;

              //Adiciona em uma lista de relações de account
              listPolygon.push({
                Source: x.properties.COD_TALHAO,
                Id: accountId,
                Body: JSON.stringify(featureCollection([x])),
              });
            }

            ptsWithin.push(x.properties.COD_TALHAO);
          }
        }
      });

      ptsWithin = [...new Set(ptsWithin)];
      if (ptsWithin.length > 0) {
        //Adiciona em uma lista de relações
        listRelation.push({
          source: y.properties.COD_IMOVEL,
          within: ptsWithin,
          status
        });
      }
    });

    if (listRelation.length > 0) {
      //Envia a lista de relações para atualizar a tabela de relações
      this.props.actions.updatePlotRelation(listRelation);
    }

    if (listPolygon.length > 0) {
      //Envia a lista de atribuições para atualizar a tabela de whitespace accounts
      this.props.actions.createAccountPlot(listPolygon);
    }
  }

  //Atualiza o relacionamento das fazendas através do polígono de talhão
  updateRelationFarmWithPlot = async (polygon, status) => {
    //Pega a layer completa de talhões
    const farms = this.state.data;

    if (farms == null) return;

    var listRelation = [];
    var listPolygon = [];

    farms.features.map((y) => {
      //Caso não haja account selecionada pega o valor do polígono
      if (!isNullOrEmpty(y.properties.ACCOUNT_ID)) {
        if (!isNullOrEmpty(polygon.properties.ACCOUNT_ID) && polygon.properties.ACCOUNT_ID != y.properties.ACCOUNT_ID) {
          return;
        }
      }

      const accountPolygon = TurfPolygon(y.geometry.coordinates);
      const poly = TurfPolygon(polygon.geometry.coordinates);

      const intersection = TurfIntersect(accountPolygon, poly);
      if (intersection != null) {
        const sharedArea = TurfArea(intersection);
        const originalArea = TurfArea(poly);
        const threshold = 0.5; // 50%

        if (sharedArea / originalArea >= threshold) {
          if (isNullOrEmpty(polygon.properties.ACCOUNT_ID)) {
            const accountId = y.properties.ACCOUNT_ID;

            polygon.properties["ACCOUNT"] = y.properties.ACCOUNT;
            polygon.properties["ACCOUNT_ID"] = accountId;
            polygon.properties["CLIENT_ID"] = y.properties.CLIENT_ID;
            polygon.properties["CLIENT_NAME"] = y.properties.CLIENT_NAME;
            polygon.properties["USER_NAME"] = y.properties.USER_NAME;
            polygon.properties["TERRITORY_ID"] = y.properties.TERRITORY_ID;

            //Adiciona em uma lista de relações de account
            listPolygon.push({
              Source: polygon.properties.COD_TALHAO,
              Id: accountId,
              Body: JSON.stringify(featureCollection([polygon])),
            });
          }

          //Adiciona em uma lista de relações
          listRelation.push({
            source: y.properties.COD_IMOVEL,
            within: [polygon.properties.COD_TALHAO],
            status
          });
          return;
        }
      }
    });

    if (listRelation.length > 0) {
      //Envia a lista de relações para atualizar a tabela de relações
      this.props.actions.updatePlotRelation(listRelation);
    }

    if (listPolygon.length > 0) {
      //Envia a lista de atribuições para atualizar a tabela de whitespace accounts
      this.props.actions.createAccountPlot(listPolygon);
    }
  }

  //Pegar em um talhão especifico
  getPlotInWhitespace(whitespace) {
    const plotData = this.state.allPlotData;

    if (plotData == null) {
      this.setState({ isLoadingToButton: false });
      return;
    }

    this.setState({ polygonRegion: featureCollection(whitespace) });
    if (whitespace == null) {
      this.setState({ plotData: featureCollection([]) });
      return;
    }

    var pointsList = [];

    var ptsWithin = [];
    var result = [];

    plotData.features.map((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [point.geometry.coordinates[0], point.geometry.coordinates[1]];
      point = TurfPoint(point, {
        idAccount: x.properties.ACCOUNT_ID,
        source: x.properties.COD_TALHAO,
      });
      pointsList.push(point);
    });
    pointsList = featureCollection(pointsList);

    whitespace.map((y) => {
      var polygon = TurfPolygon(y.geometry.coordinates);
      var within = TurfWithin(pointsList, polygon);

      //Verifica se há pontos em uma X distancia
      const distance = 4;
      for (var i = 0; i < pointsList.features.length; i++) {
        var byDistance = TurfDistance(
          TurfPoint(pointsList.features[i].geometry.coordinates),
          TurfCenter(polygon)
        );

        if (byDistance <= distance) {
          ptsWithin.push(pointsList.features[i].properties.source);
        }
      }

      //Verifica se há pontos dentro do poligno
      within.features.forEach((x) => {
        ptsWithin.push(x.properties.source);
      });
    });

    plotData.features.map((x) => {
      whitespace.map((y) => {
        const accountPolygon = TurfPolygon(y.geometry.coordinates);
        const polygon = TurfPolygon(x.geometry.coordinates);

        const intersection = TurfIntersect(accountPolygon, polygon);
        if (intersection != null) {
          const sharedArea = TurfArea(intersection);
          const originalArea = TurfArea(polygon);
          const threshold = 0.5; // 50%

          if (sharedArea / originalArea >= threshold) {
            ptsWithin.push(x.properties.COD_TALHAO);
          }
        }
      });
    });

    result = plotData.features.filter(feature => {
      const source = feature.properties.COD_TALHAO;
      return ptsWithin.includes(source);
    });

    result = featureCollection(result);
    this.setState({ plotData: result });

    this.setState({ isLoadingToButton: false });
  }

  //Pegar todos os talhões assinalados
  getAllAssignedPlotVision(whitespace) {
    axios
      .get(`api/whitespace/plotAllWithAccount/${this.state.groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (!this.state.showPlots)
          return;

        if (whitespace == null) {
          this.setState({ assignedData: featureCollection([]) });
          return;
        }

        var list = [];
        var ptsWithin = [];
        var result = [];

        json.data.features.forEach((x) => {
          var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
          point = [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ];
          point = TurfPoint(point, {
            source: x.properties.COD_TALHAO,
          });
          list.push(point);
        });
        list = featureCollection(list);

        whitespace.forEach((x) => {
          var polygon = TurfPolygon(x.geometry.coordinates);
          var within = TurfWithin(list, polygon);

          //Verifica se há pontos em uma X distancia
          const distance = 4;
          for (var i = 0; i < list.features.length; i++) {
            var byDistance = TurfDistance(
              TurfPoint(list.features[i].geometry.coordinates),
              TurfCenter(polygon)
            );
            if (byDistance <= distance) {
              ptsWithin.push(list.features[i].properties.source);
            }
          }

          //Verifica se há pontos dentro do poligno
          within.features.forEach((x) => {
            ptsWithin.push(x.properties.source);
          });
        });

        json.data.features.forEach((x) => {
          var source = x.properties.COD_TALHAO;
          if (ptsWithin.includes(source)) result.push(x);
        });

        result = featureCollection(result);
        this.setState({ assignedData: result });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  //Pegar todos os talhões assinalados excluindo a visão única atual
  getAllAssignedPlotNVision(whitespace) {
    axios
      .get(`api/whitespace/plotAllAccounts/${this.state.groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (this.state.showPlots)
          return;

        if (whitespace == null) {
          this.setState({ allAssignedData: featureCollection([]) });
          return;
        }

        var list = [];
        var ptsWithin = [];
        var result = [];

        json.data.features.forEach((x) => {
          var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
          point = [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ];
          point = TurfPoint(point, {
            source: x.properties.COD_TALHAO,
          });
          list.push(point);
        });
        list = featureCollection(list);

        whitespace.forEach((x) => {
          var polygon = TurfPolygon(x.geometry.coordinates);
          var within = TurfWithin(list, polygon);

          //Verifica se há pontos em uma X distancia
          const distance = 4;
          for (var i = 0; i < list.features.length; i++) {
            var byDistance = TurfDistance(
              TurfPoint(list.features[i].geometry.coordinates),
              TurfCenter(polygon)
            );
            if (byDistance <= distance) {
              ptsWithin.push(list.features[i].properties.source);
            }
          }

          //Verifica se há pontos dentro do poligno
          within.features.forEach((x) => {
            ptsWithin.push(x.properties.source);
          });
        });

        json.data.features.forEach((x) => {
          var source = x.properties.COD_TALHAO;
          if (ptsWithin.includes(source)) result.push(x);
        });

        result = featureCollection(result);
        this.setState({ allAssignedData: result });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  //Pegar dados talhões
  getPlotsData(groupId) {
    axios
      .get(`api/whitespace/plotWhitespace/${groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        var countArea = 0;

        try {
          this.setState({ allPlotData: json.data });

          json.data.features.forEach((x) => {
            countArea += x.properties.Area;
          });

          countArea = parseFloat(countArea).toFixed(1);
          this.setState({ valueAreaPlot: countArea });
          this.setState({ countPlot: json.data.features.length });
        } catch (e) {
          console.log(e);
        }

        this.setState({
          isLoadingButtons: false
        });
      })
      .catch((err) => {
        console.error("Could not load data", err);
        this.setState({
          isLoadingButtons: false
        });
      });
  }
  getPointCultureArea(event) {
    var data = this.state.allPlotData;
    var area = 0;
    var count = 0;
    data.features.forEach((x) => {
      if (x.properties.CULT_APONT === event.target.value) {
        count += 1;
        area += x.properties.Area;
      }
    });

    this.setState({ pointedCultureArea: area });
  }
  getPredictCultureArea(event) {
    var data = this.state.allPlotData;
    var area = 0;
    var count = 0;
    data.features.forEach((x) => {
      if (x.properties.CULT_PREDIT === event.target.value) {
        count += 1;
        area += x.properties.Area;
      }
    });

    this.setState({ predictCultureArea: area });
  }

  //Pegar todos os talhões da Visão Única
  getVisionPlots() {
    const plotData = this.state.allPlotData;
    const accountPolygons = this.state.accountsPolygons;

    var ptsWithin = [];
    var result = [];

    try {
      plotData.features.map((x) => {
        accountPolygons.features.map((y) => {
          const accountPolygon = TurfPolygon(y.geometry.coordinates);
          const polygon = TurfPolygon(x.geometry.coordinates);

          const intersection = TurfIntersect(accountPolygon, polygon);
          if (intersection != null) {
            const sharedArea = TurfArea(intersection);
            const originalArea = TurfArea(polygon);
            const threshold = 0.5; // 50%

            if (sharedArea / originalArea >= threshold) {
              ptsWithin.push(x.properties.COD_TALHAO);
            }
          }
        });
      });

      const accounts = this.state.accountsIds;
      plotData.features.forEach((x) => {
        var source = x.properties.COD_TALHAO;
        if (ptsWithin.includes(source) || accounts.includes(x.properties.ACCOUNT_ID))
          result.push(x);
      });

      result = featureCollection(result);
      this.setState({ plotData: result });
    } catch (e) {
      console.log(e);
    }

    this.setState({ isLoadingToButton: false });
  }
  //Pegar todos os talhões assinalados na visão única
  getAssignedVisionPlots() {
    axios
      .get(`api/whitespace/plotAllClientsAccounts/${this.state.groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (this.state.showPlots) {
          var data = json.data;
          this.setState({ assignedData: data });
        }
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  //Pegar talhões de fora da Visão Única
  getAllAssignedPlots() {
    axios
      .get(`api/whitespace/plotAllAccounts/${this.state.groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (!this.state.showPlots)
          return;

        var list = [];
        var ptsWithin = [];
        var result = [];

        json.data.features.forEach((x) => {
          var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
          point = [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ];
          point = TurfPoint(point, {
            source: x.properties.COD_TALHAO,
          });
          list.push(point);
        });
        list = featureCollection(list);

        this.state.accountsPolygons.features.forEach((x) => {
          var polygon = TurfPolygon(x.geometry.coordinates);
          var within = TurfWithin(list, polygon);

          //Verifica se há pontos em uma X distancia
          const distance = 4;
          for (var i = 0; i < list.features.length; i++) {
            var byDistance = TurfDistance(
              TurfPoint(list.features[i].geometry.coordinates),
              TurfCenter(polygon)
            );
            if (byDistance <= distance) {
              ptsWithin.push(list.features[i].properties.source);
            }
          }

          //Verifica se há pontos dentro do poligno
          within.features.forEach((x) => {
            ptsWithin.push(x.properties.source);
          });
        });

        json.data.features.forEach((x) => {
          var source = x.properties.COD_TALHAO;
          if (ptsWithin.includes(source)) result.push(x);
        });

        result = featureCollection(result);
        this.setState({ allAssignedData: result });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAllSpecificPlotsAccount() {
    axios
      .get(`api/whitespace/plotAllWithAccount/${this.state.groupId}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        if (!this.state.showPlots)
          return;

        var list = [];
        var ptsWithin = [];
        var result = [];

        json.data.features.forEach((x) => {
          var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
          point = [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ];
          point = TurfPoint(point, {
            source: x.properties.COD_TALHAO,
          });
          list.push(point);
        });
        list = featureCollection(list);

        this.state.accountPolygon.features.forEach((x) => {
          var polygon = TurfPolygon(x.geometry.coordinates);
          var within = TurfWithin(list, polygon);

          //Verifica se há pontos em uma X distancia
          const distance = 4;
          for (var i = 0; i < list.features.length; i++) {
            var byDistance = TurfDistance(
              TurfPoint(list.features[i].geometry.coordinates),
              TurfCenter(polygon)
            );
            if (byDistance <= distance) {
              ptsWithin.push(list.features[i].properties.source);
            }
          }

          //Verifica se há pontos dentro do poligno
          within.features.forEach((x) => {
            ptsWithin.push(x.properties.source);
          });
        });

        json.data.features.forEach((x) => {
          var source = x.properties.COD_TALHAO;
          if (ptsWithin.includes(source)) result.push(x);
        });

        result = featureCollection(result);
        this.setState({ allAssignedData: result });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  //Pegar fazendas
  getAssignedFarms(id) {
    axios
      .get(`api/whitespace/assignedWhitespaces/${id}`, {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ accountsPolygons: json.data });
        this.setState({ assignedData: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAllAssignedFarms() {
    var territory;
    var tArray = [];
    if (this.state.codNameTerritoryLoaded.length > 0) {
      territory = this.state.codNameTerritoryLoaded;
    } else {
      territory = this.props.whitespace.territory;
    }

    territory.forEach((x) => {
      tArray.push(x.codIbge);
    });
    this.addAssignedData(this.state.groupId, tArray);
  }

  buttonAccountPlots() {
    const { showPlots, accountId, showAllData, groupId } = this.state;

    this.setShowPlots(!showPlots, []);

    if (!showPlots) {
      this.setState({ polygonRegion: accountId !== "" ? this.state.accountPolygon : this.state.accountsPolygons });

      if (accountId !== "") {
        this.getAccountPlot();
        this.getAccountPlotAssigned(accountId);
        this.getAssignedVisionPlotsInternal();

        if (showAllData) this.getAssignedNVisionPlotsInternal();
      } else {
        this.getVisionPlots();
        this.getAssignedVisionPlots();

        if (showAllData) this.getAllAssignedPlots();
      }
    } else {
      if (accountId !== "") {
        this.getAccounData(accountId);
      }

      this.getAssignedFarms(groupId);

      if (showAllData) this.getAllAssignedFarms();
    }
  }

  buttonShowAllData() {
    if (!this.state.showAllData) {
      if (this.state.showPlots) {
        if (this.state.accountId !== "") {
          this.getAssignedVisionPlotsInternal();
          this.getAssignedNVisionPlotsInternal();
        } else {
          this.getAllAssignedPlots();
        }
      } else {
        this.getAssignedFarms(this.state.groupId);
        this.getAllAssignedFarms();
      }
    }

    this.setState({ showAllData: !this.state.showAllData });
  }

  onChangeArea(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ area: newInputValue });
      this.setState({ distrito: undefined });
      this.setState({ territorio: undefined });
      localStorage.setItem("Area", JSON.stringify(newInputValue));
      localStorage.removeItem("Distrito");
      localStorage.removeItem("Territorio");
    } else {
      this.setState({ area: options[0] });
      localStorage.setItem("Area", JSON.stringify(options[0]));
    }
  }

  onChangeSafra(safra) {
    this.setState({ isLoadingData: true });
    this.setState({ isLoadingPlots: true });

    if (safra != null) {
      this.props.actions.changeUserSafra(safra.id).then(() => {
        window.location.reload();
      });
    } else {
      this.props.actions.changeUserSafra(0).then(() => {
        window.location.reload();
      });
    }
  }

  onChangeDistrito(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ distrito: newInputValue });
      this.setState({ territorio: undefined });
      localStorage.setItem("Distrito", JSON.stringify(newInputValue));
      localStorage.removeItem("Territorio");
    } else {
      this.setState({ distrito: options[0] });
      localStorage.setItem("Distrito", JSON.stringify(options[0]));
    }
  }

  onChangeTerritorio(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ territorio: newInputValue });
      localStorage.setItem("Territorio", JSON.stringify(newInputValue));

      this.props.actions.changeTerritorio(newInputValue.territoryId);
      this.props.actions.getMunicipios(this.state.groupId).then(() => {
        this.setState({ municipios: this.props.whitespace.municipios });
      });
    } else {
      this.setState({ territorio: options[0] });
      localStorage.setItem("Territorio", JSON.stringify(options[0]));
      this.props.actions.changeTerritorio(options[0].territoryId);
    }
  }

  setByStorage(areas = [], distritos = [], territorios = [], role, roleLevels) {
    var area = JSON.parse(localStorage.getItem("Area"));
    var distrito = JSON.parse(localStorage.getItem("Distrito"));
    var territorio = JSON.parse(localStorage.getItem("Territorio"));
    if (area && areas.find((x) => x === area) !== undefined) {
      this.setState({ area: area });
    } else {
      localStorage.removeItem("Area");
    }
    if (
      distrito &&
      distritos.find((x) => x.districtName === distrito.districtName) !==
      undefined
    ) {
      this.setState({ distrito: distrito });
    } else {
      localStorage.removeItem("Distrito");
    }

    if (
      territorio &&
      territorios.find((x) => x.territoryName === territorio.territoryName) !==
      undefined
    ) {
      this.setState({ territorio: territorio });
      this.props.actions.changeTerritorio(territorio.territoryId);
    } else if (
      territorios.length == 1 &&
      role?.accessLevel <= roleLevels?.viewTerritory
    ) {
      this.setState({ territorio: territorios[0] });
      this.props.actions.changeTerritorio(territorios[0].territoryId);
    } else {
      localStorage.removeItem("Territorio");
    }

    this.setState({ isSetStorage: true });
  }

  componentWillMount() {
    this.props.actions.getSafra();
    this.props.actions.requestSafras();
    this.props.actions.requestCultures();
    this.props.actions.requestCulturesGoal();
    this.props.actions.requestTypesIrrigation();

    this.props.actions.requestClients();
    this.props.actions.requestTerritorios();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();

    this.getUser();

    const { oidc } = this.props;
    if (oidc && oidc?.user && oidc.user.access_token) {
      connection = configure(oidc);
    }
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (!isNullOrEmpty(params.GroupId)) {
      this.setState({ groupId: params.GroupId });

      this.props.actions.requestAccountsWithId(params.GroupId)
        .then(() => {
          if (!this.state.loadingVu) {
            this.props.actions.getClientConditions(params.GroupId);
            this.setAccountsIds(this.props.whitespace.accounts);
            this.updateMapWithClient({ clientId: params.GroupId });
            this.props.actions.requestAllIBGEWithId(params.GroupId);
          }
        }).catch((e) => {
          this.handleOpenError("Clientes não encontrados", "Os clientes da Visão Única escolhida não estão registrados ou possuem algum vínculo incorreto. Contate seu superior.");
        });
    }

    if (this.state.groupId === null) this.setState({ isLoading: false, progressFarm: 0 });
    else this.setState({ isLoading: true });

    var a = window.document.getElementById("Map");
    a.scrollIntoView();
  }
  componentWillUnmount() {
    this.setState({ oldData: [] });
  }

  render() {
    const { oidc } = this.props;
    var isLoading = (this.props.clientes.isLoading ||
      this.props.whitespace.isLoadingAccounts ||
      this.props.user.isLoadingTerritorios ||
      this.props.user.isLoadingRole ||
      this.props.user.isLoadingRoleLevels) &&
      !this.state.error;

    var isLoadingMap = (this.state.isLoadingData ||
      this.props.whitespace.isLoadingWhitespaces ||
      this.props.culturasWhitespace.isLoading ||
      this.props.whitespace.isLoadingTerritories ||
      this.state.isLoading ||
      this.state.isLoadingToButton) &&
      !this.state.error;

    var clients = [];
    var accounts = [];
    var accIds = [];
    var accData = [];
    var safra = "";

    var role = "";
    var roleLevels = null;
    var territorios = [{ territoryName: "TODOS" }];
    var areas = ["TODOS"];
    var distritos = [{ districtName: "TODOS" }];

    if (this.props.whitespace) {
      if (!this.props.whitespace.isLoadingAccounts) {
        accounts = this.props.whitespace.accounts.sort((a, b) =>
          a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0
        );

        var whiteArea = 0;
        for (var i = 0; i < accounts.length; i++) {
          var salesforceLink = oidc.user?.profile?.upn.toLowerCase().includes("@repcorteva") ? `https://corteva.force.com/Partner/s/Account/${accounts[i].accountId}` : `https://corteva.lightning.force.com/${accounts[i].accountId}`
          accIds.push(accounts[i].AccountId);

          accounts[i].whitespaces &&
            accounts[i].whitespaces.map((x) => {
              whiteArea += x.area;
            });

          accData.push({
            Nome: accounts[i].nome,
            Cod: accounts[i].accountId,
            Link: salesforceLink,
            Ie: accounts[i].ie,
            Uf: accounts[i].uf,
            CodIBGE: accounts[i].codMunicipioIBGE,
            Municipio: accounts[i].municipio,
            Fazendas: accounts[i].whitespaces,
            Area: parseFloat(whiteArea).toFixed(2),
            MunicipiosProximos: accounts[i].municipiosProximos
          });

          whiteArea = 0;
        }
      }

      if (!this.props.whitespace.isLoadingSafra) {
        safra = this.props.whitespace.safra;
      }
    }

    if (
      !this.props.user.isLoadingRole && this.props.user.role != null &&
      !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null
    ) {
      roleLevels = this.props.user.roleLevels;
      role = this.props.user.role.role;
    }

    if (
      !this.props.clientes.isLoading && this.props.clientes &&
      !this.props.user.isLoadingTerritorios && this.props.user.territorios.length > 0 &&
      !this.props.user.isLoadingRole && this.props.user.role != null &&
      !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null
    ) {
      var territoriosToFilter = this.props.user.territorios;
      areas = areas.concat(this.props.user.role.areas);
      areas = areas.filter((x) => x !== "");
      var distritosToFilter = this.props.user.role.distritos;

      if (!this.state.isSetStorage) {
        this.setByStorage(
          this.props.user.role.areas,
          this.props.user.role.distritos,
          this.props.user.territorios,
          role,
          roleLevels
        );
      }

      clients = this.props.clientes.clients.sort((a, b) =>
        a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
      );
      if (isNullOrEmpty(this.state.client)) {
        this.setState({
          client: clients.find((x) => x.clientId === this.state.groupId),
        });
      }

      if (
        this.state.area !== "" &&
        this.state.area != null &&
        this.state.area !== undefined &&
        role.accessLevel === roleLevels.viewAll
      ) {
        if (this.state.area !== "TODOS") {
          distritosToFilter = distritosToFilter.filter(
            (x) => x.areaName === this.state.area
          );
          territoriosToFilter = territoriosToFilter.filter(
            (x) => x.areaName === this.state.area
          );
          clients = clients.filter((x) =>
            territoriosToFilter.find((t) => x.territory == t.territoryName)
          );
        }
      }

      distritos = distritos.concat(distritosToFilter);
      distritos = distritos.filter((x) => x.districtName !== "");
      if (
        this.state.distrito !== "" &&
        this.state.distrito != null &&
        this.state.distrito !== undefined &&
        (role?.accessLevel >= roleLevels?.viewArea ||
          (role?.accessLevel >= roleLevels?.viewManyDistrict &&
            distritos.length > 2))
      ) {
        if (this.state.distrito.districtName !== "TODOS") {
          territoriosToFilter = territoriosToFilter.filter(
            (x) => x.districtName === this.state.distrito.districtName
          );
          clients = clients.filter((x) =>
            territoriosToFilter.find((t) => x.territory == t.territoryName)
          );
        }
      }

      if (
        this.state.territorio !== "" &&
        this.state.territorio != null &&
        this.state.territorio !== undefined &&
        role?.accessLevel >= roleLevels?.viewDistrict
      ) {
        if (this.state.territorio.territoryName !== "TODOS")
          clients = clients.filter(
            (x) => x.territory === this.state.territorio.territoryName
          );
      }
      territorios = territorios.concat(territoriosToFilter);
    }

    return (
      <div className="card">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 2,
            backgroundColor: '#000000',
          }}
          open={isLoading}
        >
          <Stack spacing={2} alignItems="center" direction="column">
            <div
              style={{
                color: "white",
                fontFamily: "Gilroy",
                fontWeight: "Bold",
              }}
            >
              {"Baixando dados do servidor..."}
            </div>
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>

        <SimpleBoxError
          open={this.state.error}
          onClose={() => this.setState({ error: false })}
          title={this.state.errorTitle}
          message={this.state.errorMessage}
        />

        <div className="card-header">
          <Grid
            container
            spacing={2}
            sx={{
              "* a": {
                color: "var(--cort-blue) !important",
              },
            }}
          >
            <Grid item className="align-self-end">
              <Link to={"/"}>
                <ArrowBackIcon />
              </Link>
            </Grid>
            <Grid item xs={11} className="align-self-end">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to={"/"}>
                  Home
                </Link>
                <Typography color="text.primary">Whitespace Visão Única</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
        <div id="Map" style={{ borderRadius: "8px" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50px",
              left: "10px",
              textAlign: "center",
              padding: "8px",
              borderRadius: "8px",
              width: "290px",
              backgroundColor: "white",
              boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
              zIndex: (theme) => theme.zIndex.drawer,
            }}>
            <Stack direction="row"
              spacing={2}
              sx={{
                color: "var(--cort-blue)",
                width: "290px",
                height: "42px",
                alignItems: "center"
              }}>
              <PersonIcon style={{
                height: "32px",
                width: "32px",
                marginLeft: "8px"
              }} />
              <Virtualize
                sx={{
                  color: "black",
                  width: "225px",
                  height: "100%",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  'input': {
                    fontSize: "clamp(0.5rem, 2rem)",
                    fontFamily: "Gilroy",
                    fontWeight: "bold",
                    maxWidth: "80%"
                  },
                  '*, ::after, ::before': {
                    border: "none !important",
                    padding: "0px !important"
                  },
                }}
                value={
                  this.state.client == null
                    ? clients.find(
                      (x) => x.clientId === this.state.groupId
                    ) ?? null
                    : this.state.client
                }
                options={clients}
                noOptionsText="Visão Única não encontrada"
                getOptionLabel={(option) => option.clientName}
                onChange={(event, newInputValue) => {
                  var e = { target: { value: newInputValue.clientId } };
                  this.changeClient(e);
                  this.updateMapWithClient(newInputValue);
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Visão Única" />
                )}
              ></Virtualize>
            </Stack>
          </Box>

          {!isLoading && (
            <FloatingDiv isOpen={true}
              title={<div style={{
                fontFamily: "Gilroy",
                fontWeight: "bold",
                color: "white"
              }}>Fazendas</div>}>
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                direction={"column"}
                spacing={2}
                sx={{
                  textAlign: "center",
                  width: "290px",
                  padding: "8px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <ClientsPagination
                    data={accData}
                    selectAccount={this.selectAccount}
                    desatribuir={this.desatribuir}
                    indexSelect={this.state.accountId}
                    goTo={this.goTo}
                    goToPolygon={this.goToPolygonWithSource}
                  />

                  <Grid container spacing={1} className="mt-2" id="canvas">
                    {!this.state.showPlots && (
                      <>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            sx={{
                              fontFamily: "Gilroy",
                              fontWeight: "Bold",
                              fontSize: "clamp(15px, 15px, 100px)",
                              width: "100%",
                            }}
                            disabled={
                              this.state.accountId === "" ||
                              (this.state.sourcePolygon.length <= 0 &&
                                this.state.sourcePlotPolygon.length <= 0) ||
                              ((this.state.allPlotData === null ||
                                this.state.allPlotData.features.length <= 0) &&
                                this.state.isLoadingPlots) ||
                              this.state.isLoadingToButton
                            }
                            onClick={this.fazerAtribuicao}
                          >
                            Atribuir
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            sx={{
                              fontFamily: "Gilroy",
                              fontWeight: "Bold",
                              backgroundColor: "#ff0000",
                              fontSize: "clamp(15px, 15px, 100px)",
                              width: "100%",
                            }}
                            disabled={
                              this.state.accountId === "" ||
                              (this.state.sourcePolygon.length <= 0 &&
                                this.state.sourcePlotPolygon.length <= 0) ||
                              ((this.state.allPlotData === null ||
                                this.state.allPlotData.features.length <= 0) &&
                                this.state.isLoadingPlots) ||
                              this.state.isLoadingToButton
                            }
                            onClick={this.desatribuirVarios}
                          >
                            Desatribuir
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Stack>

              {!(
                this.props.user.isLoadingTerritorios ||
                this.props.user.isLoadingRole ||
                this.props.user.isLoadingRoleLevels
              ) &&
                role.accessLevel > roleLevels?.viewTerritory && (
                  <>
                    <ButtonSelectTerrritory
                      variant="outlined"
                      sx={{
                        marginTop: "8px",
                        textAlign: "center",
                        width: "290px",
                        padding: "8px",
                        borderRadius: "8px",
                        backgroundColor: "white !important",
                        border: "none",
                        fontWeight: "bold",
                        boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
                        zIndex: (theme) => theme.zIndex.drawer,
                      }}
                      selectedArea={
                        this.state.area ? this.state.area : areas[0]
                      }
                      areas={areas}
                      onChangeArea={this.onChangeArea}
                      selectedDistrito={
                        this.state.distrito
                          ? this.state.distrito
                          : distritos[0]
                      }
                      distritos={distritos}
                      onChangeDistrito={this.onChangeDistrito}
                      selectedTerritorio={
                        this.state.territorio
                          ? this.state.territorio
                          : territorios[0]
                      }
                      territorios={territorios}
                      onChangeTerritorio={this.onChangeTerritorio}
                      role={role}
                      roleLevels={roleLevels}
                      fullWidth
                    >
                      Mudar Território
                    </ButtonSelectTerrritory>
                  </>
                )}
            </FloatingDiv>
          )}


          <ButtonSelectSafra
            safras={this.props.whitespace.safras}
            changeSafra={this.onChangeSafra}
            selectedSafra={safra}
          >
          </ButtonSelectSafra>

          <TerritoryPagination
            territory={this.props.whitespace.allTerritories}
            selectedTerritory={this.state.codNameTerritoryLoaded}
            addData={this.tryAddData}
            getAllAssignedFarms={this.getAllAssignedFarms}
            changeLoadState={this.changeLoadState}
            isLoading={this.state.isLoading}
            removeData={this.tryRemoveData}
          />

          <Stack
            justifyContent="flex-start"
            alignItems="center"
            direction={"row"}
            spacing={2}
            sx={{ height: "calc(100vh - 159px)", borderRadius: "0 0 8px 8px" }}
          >
            <Map
              copySourceCodes={this.copySourceCodes}
              goTo={this.goTo}
              goToPolygon={this.goToPolygon}
              setShowPlots={this.setShowPlots}
              buttonAccountPlots={this.buttonAccountPlots}
              setMapbox={this.setMapbox}
              setDraw={this.setDraw}
              changeLoadState={this.changeMapLoaded}
              createPolygon={this.createPolygon}
              createMultiplesPolygons={this.createMultiplesPolygons}
              selectPolygon={this.selectPolygon}
              updatePolygon={this.updatePolygon}
              deletePolygon={this.deletePolygon}
              disablePolygon={this.disablePolygon}
              disableListPolygon={this.disableListPolygon}
              disableListPlotPolygon={this.disableListPlotPolygon}
              selectPlotPolygon={this.selectPlotPolygon}
              updatePlotPolygon={this.updatePlotPolygon}
              deletePlotPolygon={this.deletePlotPolygon}
              disablePlotPolygon={this.disablePlotPolygon}
              cleanList={this.cleanList}
              setSelectData={this.setSelectData}
              updateListPlotPolygon={this.updateListPlotPolygon}
              onClickMarker={this.handleEnableMarker}
              addData={this.findCoordinates}

              accountId={this.state.accountId}
              data={this.state.data}
              counties={this.state.allCountyData}
              polygonRegion={this.state.polygonRegion}
              allPlotData={this.state.allPlotData}
              plotData={this.state.plotData}
              plotInfo={this.state.dataPlotPolygon}
              farmInfo={this.state.dataPolygon}
              assignedData={this.state.assignedData}
              allAssignedData={this.state.allAssignedData}
              accountData={this.state.accountData}
              farmSelected={this.state.sourcePolygon}
              plotSelected={this.state.sourcePlotPolygon}
              showPlots={this.state.showPlots}
              showAllData={this.state.showAllData}
              isLoading={isLoadingMap}
              progressFarm={this.state.progressFarm}
              coordinates={this.state.coordinates}
              municipios={this.state.municipios}
              countFarm={this.state.countFarm}
              valueAreaFarm={this.state.valueAreaFarm}
              countAssignedFarm={this.state.countAssignedFarm}
              valueAreaAssignedFarm={this.state.valueAreaAssignedFarm}
              countPlot={this.state.countPlot}
              valueAreaPlot={this.state.valueAreaPlot}
              countAssignedPlot={this.state.countAssignedPlot}
              valueAreaAssignedPlot={this.state.valueAreaAssignedPlot}
              pointedCultureArea={this.state.pointedCultureArea}
              predictCultureArea={this.state.predictCultureArea}
              codNameAll={this.props.whitespace.allTerritories}
              selectData={this.state.selectData}
              groupId={this.state.groupId}
              territoryId={this.state.territorio?.territoryId}
              accountsList={accIds}
              marker={this.state.marker}
              markerOn={this.state.markerOn}
              cultures={this.props.culturasWhitespace.cultures}
              culturesGoal={this.props.culturasWhitespace.culturesGoal}
              irrigations={this.props.culturasWhitespace.irrigations}
              plotLayer={this.props.culturasWhitespace.plotLayer}
              plotLineLayer={this.props.culturasWhitespace.plotLineLayer}
              isLoadingPlots={this.state.isLoadingPlots}
              hasPlots={this.state.hasPlots}
              progressPlot={this.state.progressPlot}
              selectedPolygon={this.state.dataPolygon}
            />
          </Stack>
        </div >
      </div >
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...accountWhitespaceActions,
          ...whitespaceActions,
          ...culturasWhitespaceActions,
          ...clientActions,
          ...userActions,
        },
        dispatch
      ),
    };
  }
)(WhiteSpacePage);
