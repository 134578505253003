/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { productActions } from "../../../actions/products.actions";
import { productPriceActions } from "../../../actions/productprice.actions";
import { campaignActions } from "../../../actions/campaign.actions";
import { payConditionsActions } from "../../../actions/paycondition.action";
import { packActions } from "../../../actions/pack.action";
import { descontopackActions } from "../../../actions/packdiscount.action";
import { produtopackActions } from "../../../actions/produtopack.action";
import { dateEncargosActions } from "../../../actions/dateencargos.action";
import { categoryDiscountActions } from "../../../actions/categorydiscount.action";
import { EncargosRatingsActions } from "../../../actions/encargosrate.actions";
import { GetAllDiscountCategoryActions } from "../../../actions/getalldiscountcategory.action";
import { encargosActions } from "../../../actions/encargos.action";
import { SaveCenarioActions } from "../../../actions/savecenario.actions";
import { SaveCenarioItemsActions } from "../../../actions/savecenarioitems.actions";
import { AllCampaignsActions } from "../../../actions/allcampaign.actions";
import { DeleteCenarioActions } from "../../../actions/deletecenario.action";
import { AnalyticsProductsActions } from "../../../actions/analyticsproducts.actions";
import { SaveGenerateCenarioActions } from "../../../actions/savegeneratecenario.acitons";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SimulatorTableEditable from "./components/TableSimulator";
import { GetCenarioActions } from "../../../actions/getcenario.actions";
import Tooltip from '@mui/material/Tooltip';
import AllowUseNavigate, { navigate } from "../../Navigate";
import ConfirmAlert, { confirm } from "../../ConfirmAlert";
import { nivelSemaforoActions } from "../../../actions/nivelSemaforo.actions";
import { userActions } from "../../../actions/user.actions";
import ConhecendoCliente from "./components/ConhecendoCliente";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { simulator2Actions } from "../../../actions/simulator2.actions";
import SimulatorFormSeeds from "./seeds/SimulatorFormSeeds";
import CrossSelling from "./components/CrossSelling";
import ResumoResultadosCp from "./components/ResumoResultadosCp";
import { GetSafeNumber, isNullOrEmpty } from "../../../helpers";
import Semaforo from "./components/Semaforo";
import { metodoPagamentoActions } from "../../../actions/metodopagamento.actions";
import { TableCell, Fab, Table, TableHead, TableBody, TableRow } from '@mui/material';
import { ErrorTooltip } from "./components/ErrorTooltip";
import ConfirmationDialog from "./components/SelectNewProduto";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { seedsEmbalagensActions } from "../../../actions/simuladorSeedsEmbalagens.action";
import { removeObjectDuplicates } from "../../../helpers/utils";

const zeroPad = (num, places) => String(num).padStart(places, "0");

function validateSeeds(rowsSeed) {
  return rowsSeed.some(x => GetSafeNumber(x.precoNegociado) > GetSafeNumber(x.precoNegociadoMax)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscCtgCli) > GetSafeNumber(x.brDscCtgCliMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscCtgCliTr) > GetSafeNumber(x.brDscCtgCliTrMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscPreCamp) > GetSafeNumber(x.brDscPreCampMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscPreCvTr) > GetSafeNumber(x.brDscPreCvTrMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscPen) > GetSafeNumber(x.brDscPenMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscTr) > GetSafeNumber(x.brDscTrMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscResCp) > GetSafeNumber(x.brDscResCpMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscDir) > GetSafeNumber(x.brDscDirMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.dscSobDsmp) > GetSafeNumber(x.dscSobDsmpMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.dscGestInv) > GetSafeNumber(x.dscGestInvMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscTg) > GetSafeNumber(x.brDscTgMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscVex) > GetSafeNumber(x.brDscVexMax * -1)) ||
    rowsSeed.some(x => GetSafeNumber(x.brDscVol) > GetSafeNumber(x.brDscVolMax * -1));
}

// eslint-disable-next-line no-unused-vars
function sumProducts(intervalo) {
  if (intervalo.length === 0) {
    return 0;
  }

  const numArrays = intervalo.length;
  const arrayLength = intervalo[0].length;

  let sumProduct = 0;

  for (let i = 0; i < arrayLength; i++) {
    let product = 1;
    for (let j = 0; j < numArrays; j++) {
      product *= intervalo[j][i];
    }
    sumProduct += product;
  }

  return sumProduct;
}

// eslint-disable-next-line no-unused-vars
function calculateFinalPer(array1, array2, array3, divisor) {
  const result = array1.reduce((acc, _, index) => {
    const product = array1[index] * array2[index] * array3[index];
    return acc + product;
  }, 0);

  return result / divisor;
}

class SimulatorEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clienteId: 0,
      distrito: "",
      territorio: "",
      csgLink: "",
      expandCrop: true,
      expandSeed: true,
      socCrop: 0,
      premiumProducts: 0,
      socCropPerc: 0,
      premiumProductsPerc: 0,
      decontoOfertaEstruturada: 0,
      totalCornVolume: 0,
      descExalt: 0,
      descUtrisha: 0,
      scoreNegociacaoCpa: 0,
      nivelFlexibilidade: undefined,
      nivelGm: undefined,
      cpaActual: 0,
      cpaTarget: 0,
      cpaActualSeeds: 0,
      isEdit: false,
      hasError: false,
      canSave: false,
      role: "",
      roleLevels: null,
      canViewCp: true,
      canViewSeeds: true,
    };

    this.toggleExpandCrop = this.toggleExpandCrop.bind(this);
    this.toggleExpandSeed = this.toggleExpandSeed.bind(this);
    this.toggleActiveCrop = this.toggleActiveCrop.bind(this);
    this.toggleActiveSeeds = this.toggleActiveSeeds.bind(this);
  }

  componentWillUnmount() {
    const finalizado = sessionStorage.getItem("isFinalizado");
    const excluido = sessionStorage.getItem("isExcluido");

    if (!this.props?.oidc?.user?.expired && finalizado !== "true" && excluido !== "true" && this.state.canSave) {
      confirm({
        title: "Deseja salvar o cenário?",
        description: "O cenário será salvo como Rascunho e ficará disponivel para futuras edições.",
        confirmationText: "Sim, salvar",
        cancellationText: "Não, descartar alterações",
      })
        .then(() => {
          this.props.actions.SaveCenarioAndClear();
          sessionStorage.removeItem("cenarioId");
        })
        .catch(() => {
          this.props.actions.removeAllRows();
          sessionStorage.removeItem("cenarioId");
        });
    }
    else {
      this.props.actions.removeAllRows();
      sessionStorage.removeItem("cenarioId");
    }
    this.props.actions.removeAllProducts();
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.props.getCenario.GetCenario = []
    this.props.actions.requestClient(params.GroupId);
    this.props.actions.requestCampaigns2();
    this.props.actions.requestCampaignsSeeds();
    this.props.actions.requestGetAllDiscountCategory2();
    this.props.actions.requestAllCampaigns2();
    this.props.actions.requestAllCampaignsSeeds();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();
    this.props.actions.requestDescontoPremium();
    this.props.actions.requestDescontoSoc();
    this.props.actions.requestNivelSemaforoFlexibilidade();
    this.props.actions.requestNivelSemaforoGmTarget();

    //Seeds
    this.props.actions.requestAllMetodos();
    this.props.actions.requestAllEncargos();
    this.props.actions.requestAllEmbalagens();

    this.props.actions.getCur();

    sessionStorage.setItem("isFinalizado", "false");
    sessionStorage.setItem("isExcluido", "false");
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.props.actions.handleChangeSimulatorData("isCpActive", false);
    this.props.actions.handleChangeSimulatorData("isSeedsActive", false);

    if (params.CenarioId !== undefined || params.CenarioId != null) {
      this.props.actions.requestItensById2(params.GroupId, params.CenarioId, false)
      this.setState({ isEdit: true })
    }

    if (params.CloneId !== undefined || params.CloneId != null) {
      this.props.actions.requestItensById2(params.GroupId, params.CloneId, true)
      this.setState({ isEdit: true })
    }
  }

  handleChangeCampaign = (event) => {
    if (event.target.value) {
      this.props.actions.handleChangeSimulatorData("initCp", true);
      this.props.actions.handleChangeSimulatorDataCp("campanha", event.target.value);
      this.props.actions.handleChangeSimulatorDataCp("condicao", "");
      this.props.actions.handleChangeSimulatorDataCp("vencimento", "");

      this.props.actions.requestencargos2(event.target.value);
      this.props.actions.requestMetodoPagamento(event.target.value);
      this.props.actions.requestPayConditions2(event.target.value);

      this.props.actions.handleChangeCondition(0);
      this.props.actions.clearEncargosRatings();

      const campaign = this.props.allcampanhas.AllCampaigns.find((b) => b.groupedCampaign === event.target.value);

      var oldCurrency = this.props.simulator2.simulatorsData.moeda;
      if (oldCurrency === "" || oldCurrency === undefined) {
        oldCurrency = campaign.currency
      }

      this.props.actions.handleChangeSimulatorData("moeda", campaign.currency);

      this.props.actions.requestProductsAndUpdate2(
        event.target.value,
        this.props.simulator2.clientes.client,
        campaign.currency,
        oldCurrency
      );

      if (!this.props.simulator2.simulatorsData.hasData) {
        this.props.actions.requestAnalyticsProducts2(
          event.target.value,
          this.props.simulator2.clientes.client.id
        );
      }
    }
  };

  handleChangePayCondition = (event) => {
    if (event.target.value) {
      this.props.actions.handleChangeSimulatorDataCp("condicao", event.target.value);
      this.props.actions.handleChangeSimulatorDataCp("vencimento", "");
      this.props.actions.clearEncargosRatings();

      this.props.actions.requestdateEncargos2(this.props.simulator2.simulatorsData.cenarioV2Cp.campanha, event.target.value);

      this.props.actions.handleChangeCondition(0);
    }
  };

  handleChangeDate = (event) => {
    if (event.target.value) {
      this.props.actions.handleChangeSimulatorDataCp("vencimento", event.target.value);

      const Date = this.props.dateencargos.dateEncargos.find((b) => b.fixedValueDate === event.target.value);

      if (!this.props.simulator2.simulatorsData.hasData) {
        this.props.actions.addRowAnalyticsProducts(
          this.props.analyticsProdutos.AnalyticsProducts,
          Date.rate,
          this.props.simulator2.clientes.client,
          this.props.simulator2.simulatorsData.moeda
        );
      }

      if (this.props.simulator2.simulatorsData.cenarioV2Cp.condicao) {
        this.props.actions.requestEncargosRatings2(
          this.props.simulator2.simulatorsData.cenarioV2Cp.condicao,
          event.target.value,
          this.props.simulator2.simulatorsData.cenarioV2Cp.campanha
        );

        this.props.actions.handleChangeCondition(Date.rate);
      }

      const cenarioId = this.props.simulator2.simulatorsData.id;

      if (cenarioId === undefined) {
        this.props.actions.createCenario();
      }
    }
  };

  handleChangePaymentMethod = (event, metodoSelecionado) => {
    this.props.actions.handleChangeSimulatorDataCp("metodoPagamento", event.target.value);
    this.props.actions.handleChangeSimulatorDataCp("valorizacaoBarter", 0);
    const maxBarter = metodoSelecionado ? metodoSelecionado.maxBarter : 0;
    this.props.actions.setMaxBarter(maxBarter);
  }

  handleChangeBarter = (values) => {
    const { value } = values;
    this.props.actions.handleChangeSimulatorDataCp("valorizacaoBarter", value);
  }

  addProduct = (id) => {
    this.props.actions.addRow(
      this.props.produtos.products.find((b) => id === b.productId),
      this.props.simulator2.clientes.client,
      this.props.encargosRate.EncargosRatings,
      this.props.simulator2.simulatorsData.moeda
    );
  };

  finalizarCenario = () => {
    sessionStorage.setItem("isFinalizado", "true");

    this.props.actions.SaveCenarioAndClear(true);

    this.props.actions.removeAllRows();
  };

  SaveCenario = () => {
    sessionStorage.setItem("isFinalizado", "true");

    this.props.actions.SaveCenarioAndClear();

    this.props.actions.removeAllRows();
  };

  deleteCenario = () => {
    sessionStorage.setItem("isExcluido", "true");

    const cenarioId = this.props.simulator2.simulatorsData.id;

    if (!this.state.isEdit && cenarioId && cenarioId !== "") {
      this.props.actions.deleteCenario(
        cenarioId,
        this.props.actions.requestCenarios,
        this.state.clienteId
      )
    }

    navigate(`/MinhasSimulacoesCliente?GroupId=${this.state.clienteId}`);
    this.props.actions.removeAllRows();
  };

  toggleExpandCrop() {
    this.setState({ expandCrop: !this.state.expandCrop })
  }

  toggleActiveCrop() {
    this.props.actions.handleChangeSimulatorData("isCpActive", !this.props.simulator2.simulatorsData.isCpActive)
  }

  toggleExpandSeed() {
    this.setState({ expandSeed: !this.state.expandSeed })
  }

  toggleActiveSeeds() {
    this.props.actions.handleChangeSimulatorData("isSeedsActive", !this.props.simulator2.simulatorsData.isSeedsActive)
  }

  calcResumo(rows) {
    const { client: cliente } = this.props.simulator2.clientes;
    const { soc: descontosSoc, premium: descontosPremium } = this.props.simulator2.descontos;

    if (!cliente || !descontosSoc || !descontosPremium) {
      return;
    }

    const sumFinalPrice = rows.reduce((sum, row) => { return sum + (row.moeda === "BRL" ? ((row.basePrice * row.volume) / row.cur) : (row.basePrice * row.volume)); }, 0);

    const socCrop = GetSafeNumber(sumFinalPrice / cliente?.sojaPotencialCpUsd) * 100;

    if (this.state.socCrop !== socCrop) {
      this.setState({ socCrop: socCrop })
    }

    const premiumProducts = rows.reduce((sum, row) => sum + (row.validPremium ? 1 : 0), 0)

    if (this.state.premiumProducts !== premiumProducts) {
      this.setState({ premiumProducts: premiumProducts })
    }

    const socCropPerc = descontosSoc.find(x => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || socCrop >= ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || socCrop < ssMax;

      return vSsMin && vSsMax;
    })?.perc ?? 0;

    if (this.state.socCropPerc !== socCropPerc) {
      this.setState({ socCropPerc: socCropPerc })
    }

    const premiumProductsPerc = descontosPremium.find(x => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || premiumProducts >= ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || premiumProducts < ssMax;

      return vSsMin && vSsMax;
    })?.perc ?? 0;

    if (this.state.premiumProductsPerc !== premiumProductsPerc) {
      this.setState({ premiumProductsPerc: premiumProductsPerc })
    }
    const decontoOfertaEstruturada = socCropPerc + premiumProductsPerc;

    if (this.state.decontoOfertaEstruturada !== decontoOfertaEstruturada) {
      this.setState({ decontoOfertaEstruturada: decontoOfertaEstruturada })
    }
  }

  calcScore(rows) {
    const { flexibilidade: nivelSemaforoFlexibilidade, gmTarget: nivelSemaforoGmTarget } = this.props.simulator2.nivelSemaforo;

    const sumFinalPriceEncargo = rows.reduce((sum, row) => {
      return sum + ((row.subTotal) * (row.volume));
    }, 0);

    const sumFinalPriceCpaMax = rows.reduce((sum, row) => {
      return sum + ((row.subTotal) * (row.volume) * (row.cpaMax / 100));
    }, 0);

    const cpaTarget = GetSafeNumber(sumFinalPriceCpaMax / sumFinalPriceEncargo) * 100;

    if (this.state.cpaTarget !== cpaTarget) {
      this.setState({ cpaTarget: cpaTarget })
    }

    const sumFinalPriceCpa = rows.reduce((sum, row) => sum + ((row.volume) * row.cpa), 0);
    const cpaActual = GetSafeNumber(sumFinalPriceCpa / sumFinalPriceEncargo) * 100;

    if (this.state.cpaActual !== cpaActual) {
      this.setState({ cpaActual: cpaActual })
    }

    const score = cpaActual < cpaTarget ? (cpaActual / cpaTarget) : (Math.round(cpaActual) / Math.round(cpaTarget));
    const scoreNegociacaoCpa = GetSafeNumber(score * 100, 2);

    if (this.state.scoreNegociacaoCpa !== scoreNegociacaoCpa) {
      this.setState({ scoreNegociacaoCpa: scoreNegociacaoCpa })
    }

    const nivelFlexibilidade = nivelSemaforoFlexibilidade.find(x => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || scoreNegociacaoCpa > ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || scoreNegociacaoCpa <= ssMax;

      return vSsMin && vSsMax;
    });

    if (this.state.nivelFlexibilidade !== nivelFlexibilidade) {
      this.setState({ nivelFlexibilidade: nivelFlexibilidade })
    }

    const gmTargetCrop = GetSafeNumber(rows.reduce((acc, x) => acc + x.valorFinal * (x.gmTarget / 100), 0));
    const gmActualCrop = GetSafeNumber(rows.reduce((acc, x) => acc + x.gm, 0));

    const scoreGm = GetSafeNumber(GetSafeNumber(gmActualCrop / gmTargetCrop) * 100, 2);

    const nivelGm = nivelSemaforoGmTarget.find(x => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || scoreGm >= ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || scoreGm < ssMax;

      return vSsMin && vSsMax;
    });

    if (this.state.nivelGm !== nivelGm) {
      this.setState({ nivelGm: nivelGm })
    }
  }

  calcFinalRows(cpRows, seedsRows, currency) {
    const { gmTarget: niveisSemaforos } = this.props.simulator2.nivelSemaforo;
    const isBRL = currency != null && currency === "BRL";

    // let cpaCp = 0;
    // let cpaSeeds = 0;

    // let precoEncargoCP = 0;
    // let precoEncargoSeed = 0;

    // let sumFinalPriceCpaMaxCP = 0;
    // let sumFinalPriceCpaMaxSeeds = 0;

    let cur = 0;
    let total = 0;

    let gmTargetSeeds = 0;
    let gmActualSeeds = 0;

    let gmTargetCrop = 0;
    let gmActualCrop = 0;

    if (seedsRows !== undefined && seedsRows.length > 0) {
      // cpaSeeds = seedsRows.reduce(
      //   (acc, x) => acc +
      //     (
      //       (brlCurrency ?
      //         (x.moeda === "BRL" ? x.brDscCpa : (x.brDscCpa * x.cur)) :
      //         (x.moeda === "BRL" ? (x.brDscCpa / x.cur) : x.brDscCpa)
      //       ) * x.volume
      //     )
      //   , 0);

      // precoEncargoSeed = seedsRows.reduce((acc, x) => {
      //   let price = x.section === "Milho" ? x.priceRate : x.priceStRate;

      //   price = brlCurrency ?
      //     (x.moeda === "BRL" ? price : (price * x.cur)) :
      //     (x.moeda === "BRL" ? (price / x.cur) : price);

      //   return acc + (price * x.volume)
      // }, 0);

      // sumFinalPriceCpaMaxSeeds = seedsRows.reduce(
      //   (acc, x) => acc +
      //     (
      //       (brlCurrency ?
      //         (x.moeda === "BRL" ? x.maxDscCpa : (x.maxDscCpa * x.cur)) :
      //         (x.moeda === "BRL" ? (x.maxDscCpa / x.cur) : x.maxDscCpa)
      //       ) * x.volume
      //     )
      //   , 0);

      //console.log(seedsRows);
      const valorFinalNegociadoSeeds = GetSafeNumber(seedsRows.reduce((acc, x) => acc + x.valorFinalNegociado, 0));
      gmTargetSeeds = GetSafeNumber(seedsRows.reduce((acc, x) => acc + x.valorFinalNegociado * (x.gmTarget / 100), 0) / valorFinalNegociadoSeeds);
      gmActualSeeds = GetSafeNumber(seedsRows.reduce((acc, x) => acc + x.gmStd, 0) / valorFinalNegociadoSeeds);

      cur = seedsRows.find(x => !isNullOrEmpty(x.cur)).cur;
      total += seedsRows.reduce(
        (acc, x) => acc +
          (isBRL ?
            (x.moeda === "BRL" ? x.valorFinalNegociado : (x.valorFinalNegociado * x.cur)) :
            (x.moeda === "BRL" ? (x.valorFinalNegociado / x.cur) : x.valorFinalNegociado)
          )
        , 0);
      total = GetSafeNumber(total);
    }

    if (cpRows !== undefined && cpRows.length > 0) {
      // cpaCp = cpRows.reduce((acc, x) => acc + (x.cpa * x.volume), 0);
      // precoEncargoCP = cpRows.reduce((sum, row) => sum + ((row.subTotal) * (row.volume)), 0);
      // sumFinalPriceCpaMaxCP = cpRows.reduce((sum, row) => sum + ((row.subTotal) * (row.volume) * (row.cpaMax / 100)), 0);

      //console.log(cpRows);
      gmTargetCrop = GetSafeNumber(cpRows.reduce((acc, x) => acc + x.valorFinal * (x.gmTarget / 100), 0));
      gmActualCrop = GetSafeNumber(cpRows.reduce((acc, x) => acc + x.gm, 0));

      total += cpRows.reduce((sum, x) => sum + x.valorFinal, 0);
      total = GetSafeNumber(total);
    }

    // console.log("Currency: " + cur);
    // console.log("GmTargetCrop: " + gmTargetCrop);
    // console.log("GmTargetSeeds: " + gmTargetSeeds);

    // console.log("GmActualCrop: " + gmActualCrop);
    // console.log("GmActualSeeds: " + gmActualSeeds);
    // console.log("Total: " + total);
    const gmTargetSum = gmTargetCrop + (isBRL ? gmTargetSeeds : GetSafeNumber(gmTargetSeeds / cur));
    const gmActualSum = gmActualCrop + (isBRL ? gmActualSeeds : GetSafeNumber(gmActualSeeds / cur));

    const gmTargetTotal = GetSafeNumber(gmTargetSum / total);
    const gmActualTotal = GetSafeNumber(gmActualSum / total);
    const score = GetSafeNumber(GetSafeNumber(gmActualTotal / gmTargetTotal) * 100, 2);

    // console.log("GmTargetTotal: " + gmTargetTotal);
    // console.log("GmActualTotal: " + gmActualTotal);
    // console.log("Score: " + score);

    //const scoreSum = (GetSafeNumber(cpaCp) + GetSafeNumber(cpaSeeds)) / (GetSafeNumber(precoEncargoCP) + GetSafeNumber(precoEncargoSeed));
    //const cpaTarget = (GetSafeNumber(sumFinalPriceCpaMaxCP) + GetSafeNumber(sumFinalPriceCpaMaxSeeds)) / (GetSafeNumber(precoEncargoCP) + GetSafeNumber(precoEncargoSeed));
    //const score = (scoreSum / cpaTarget);

    //const scoreNegociacaoCpa = GetSafeNumber(score * 100, 2);
    const nivelFlexibilidade = niveisSemaforos.find(x => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || score >= ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || score < ssMax;

      return vSsMin && vSsMax;
    });
    return { totalSimulacao: total, totalCor: nivelFlexibilidade };
  }

  calcDescontoExaltUtrisha() {
    // #sc Milho
    const totalCornVolume = this.props.simuladorSeeds.sections
      ?.filter(section => section.cultura === "Milho")
      .reduce((acc, section) => {
        const sectionVolume = section.rows?.reduce((rowAcc, row) => rowAcc + (GetSafeNumber(row.salesCount !== null ? row.salesCount * row.volume : row.volume) || 0), 0) || 0;
        return acc + sectionVolume;
      }, 0);

    if (this.state.totalCornVolume !== totalCornVolume) {
      this.setState({ totalCornVolume: totalCornVolume });
      if (totalCornVolume === 0) {
        this.setState({ descExalt: 0 })
        this.setState({ descUtrisha: 0 });
        return;
      }
    }

    const cropRows = !this.props.simulator2.simulators?.isLoading ? this.props.simulator2.simulators : [];
    let exaltVol = 0;
    let utrishaVol = 0;

    cropRows.forEach(row => {
      if (row.produto && typeof row.produto === 'string') {
        const title = row.produto.toLowerCase().trim();
        if (title.includes("exalt")) {
          exaltVol = row.volume;
        }
        if (title.includes("utrisha")) {
          utrishaVol = row.volume;
        }
      }
    });

    // Exalt (5sc : 1L) 
    const crossExalt = totalCornVolume / 5;
    const descExalt = exaltVol < crossExalt ? 0 : crossExalt;

    if (this.state.descExalt !== descExalt) {
      this.setState({ descExalt: descExalt })
    }

    // UtrishaN
    const crossUtrisha = totalCornVolume / 3;
    const descUtrisha = utrishaVol < crossUtrisha ? 0 : crossUtrisha;

    if (this.state.descUtrisha !== descUtrisha) {
      this.setState({ descUtrisha: descUtrisha });
    }
  }

  setCanSave() {
    const cenarioId = this.props.simulator2.simulatorsData.id;

    var rowsCp = !this.props.simulator2.simulators?.isLoading ? this.props.simulator2.simulators : [];
    if (this.props.simulator2.simulatorsData.isCpActive) {
      rowsCp = rowsCp.filter((row) => !(row.excluido !== undefined && row.excluido));
    } else {
      rowsCp = []
    }

    var rowsSeed = this.props.simuladorSeeds?.sections?.flatMap(x => x.rows !== undefined ? x.rows : null)?.filter(row => row !== null);
    if (this.props.simulator2.simulatorsData.isSeedsActive) {
      rowsSeed = rowsSeed?.filter((row) => !(row?.excluido !== undefined && row?.excluido));
    } else {
      rowsSeed = []
    }

    const metodo = this.props.simulator2.simulatorsData?.cenarioV2Cp?.metodoPagamento;
    const cpHasError = this.props.simulator2?.simulatorsData?.isCpActive && this.state.canViewCp &&
      rowsCp.length > 0 &&
      (rowsCp.some(x => x.precoNegociado > x.valorFinalCorteva) ||
        isNullOrEmpty(metodo))

    const seedHasError = this.props.simulator2?.simulatorsData?.isSeedsActive && this.state.canViewSeeds &&
      rowsSeed.length > 0 && validateSeeds(rowsSeed);

    const hasError = cpHasError || seedHasError;
    if (this.state.hasError !== hasError) {
      this.setState({ hasError: hasError })
    }

    const canSave = cenarioId !== undefined &&
      (rowsCp.length + rowsSeed.length) > 0 && !hasError;

    if (this.state.canSave !== canSave) {
      this.setState({ canSave: canSave })
    }
  }

  componentDidUpdate(prevPros, prevState) {
    this.setCanSave();
    // eslint-disable-next-line eqeqeq
    if (prevPros.simulator2.simulators != this.props.simulator2.simulators || prevPros.simuladorSeeds?.sections != this.props.simuladorSeeds?.sections) {
      this.calcDescontoExaltUtrisha()
    }

    if (!this.props.simulator2.simulators?.isLoading) {
      let rows = !this.props.simulator2.simulators?.isLoading ? this.props.simulator2.simulators : [];
      rows = rows.filter((row) => !(row?.excluido !== undefined && row?.excluido));
      this.calcScore(rows);
    }

    // eslint-disable-next-line eqeqeq
    if (prevPros.simulator2.simulators != this.props.simulator2.simulators) {
      let rows = !this.props.simulator2.simulators?.isLoading ? this.props.simulator2.simulators : [];
      rows = rows.filter((row) => !(row?.excluido !== undefined && row?.excluido));

      this.calcResumo(rows);
    }

    if (prevPros.user.isLoadingRole && !this.props.user.isLoadingRole && this.props.user.role != null) {
      const role = this.props.user.role.role;
      this.setState({ role: role });

    }
    if (prevPros.user.isLoadingRoleLevels && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {
      const roleLevels = this.props.user.roleLevels;
      this.setState({ roleLevels: roleLevels });
    }

    // eslint-disable-next-line eqeqeq
    if (prevPros.simulator2?.clientes?.client != this.props.simulator2?.clientes?.client || (this.props.simulator2?.clientes?.client && this.state.clienteId === 0)) {
      const { client: cliente } = this.props.simulator2.clientes;
      if (cliente) {
        this.setState({ clienteId: cliente.id })
        this.setState({ distrito: cliente.distrito })
        this.setState({ territorio: cliente.territorio })
        this.setState({
          csgLink: `https://app.powerbi.com.mcas.ms/groups/83b365d1-ccde-4480-a7d3-bda8b9ca0769/reports/ad772169-03f1-4ac4-888d-ab4c5e1c00d4/ReportSection821cb735e70de341d5b2?filter=Clientes/group_id eq '${cliente.grupoId}' and Clientes/territorio eq '${cliente.territorio}'`
        });
        this.setState({ canViewCp: cliente.sapOrigem?.includes('CP') })
        this.setState({ canViewSeeds: cliente.sapOrigem?.includes('Seeds') })
      }
    }
  }

  render() {
    const { client: cliente } = this.props.simulator2.clientes;
    const { role, roleLevels } = this.state;

    const cenarioId = this.props.simulator2.simulatorsData.id;

    var canShow = this.props.campanhas.isLoading ||
      this.props.simuladorSeeds.isLoading ||
      this.props.simulator2.clientes.isLoadingClient ||
      this.props.produtos.isLoading ||
      this.props.condicaoPagamentos.isLoading ||
      this.props.dateencargos.isLoading ||
      (this.props.simulator2.simulators?.isLoading === undefined ? false : this.props.simulator2.simulators?.isLoading) ||
      this.props.nivelSemaforo.isLoading;

    var rowsCp = !this.props.simulator2.simulators?.isLoading ? this.props.simulator2.simulators : [];
    if (this.props.simulator2.simulatorsData.isCpActive && this.state.canViewCp) {
      rowsCp = rowsCp.filter((row) => !(row.excluido !== undefined && row.excluido));
    } else {
      rowsCp = []
    }

    var rowsSeed = this.props.simuladorSeeds?.sections?.flatMap(x => x.rows !== undefined ? x.rows : null)?.filter(row => row !== null);
    rowsSeed ??= []
    if (this.props.simulator2.simulatorsData.isSeedsActive && this.state.canViewSeeds) {
      rowsSeed = rowsSeed?.filter((row) => !(row?.excluido !== undefined && row?.excluido));
    } else {
      rowsSeed = []
    }

    const total = rowsCp.reduce((sum, x) => sum + x.valorFinal, 0);
    const rascunhoCp = !(rowsCp.length > 0 && !rowsCp.some(x => (isNullOrEmpty(x.volume) || x.volume === 0 || isNullOrEmpty(x.precoNegociado) || x.precoNegociado === 0)));
    const rascunhoSeeds = !(rowsSeed.length > 0 && !rowsSeed.some(x => isNullOrEmpty(x.volume) || x.volume === 0 || isNullOrEmpty(x.precoNegociado) || x.precoNegociado === 0));
    const rascunho = (this.props.simulator2.simulatorsData.isCpActive && this.state.canViewCp && rascunhoCp) ||
      (this.props.simulator2.simulatorsData.isSeedsActive && this.state.canViewSeeds && rascunhoSeeds);

    const { totalSimulacao, totalCor } = this.calcFinalRows(rowsCp, rowsSeed, this.props.simulator2.simulatorsData.moeda);
    const metodosPagamento = removeObjectDuplicates(this.props.metodoPagamento?.metodos?.filter(x => this.props.simulator2.simulatorsData.cenarioV2Cp.condicao == x.condicao) ?? [], "metodo");

    return (
      <div className="card">
        <AllowUseNavigate />
        <ConfirmAlert />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={canShow || this.props.analyticsProdutos.isLoading ||
            this.props.productPrice.isLoading ||
            this.props.descontoCategoriaSimulador.isLoading ||
            this.props.encargosRate.isLoading ||
            this.props.produtoPacks.isLoading ||
            this.props.packdiscounts.isLoading ||
            this.props.packs.isLoading ||
            this.props.simuladorSeeds.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {this.props.simulator2.cur.status !== "failure" && <Tooltip title={
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center" sx={{ fontWeight: "bold", padding: "4px" }}>Informações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: "none" }}>Cotação do Dólar</TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  {this.props.simulator2.cur.value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                    maximumSignificantDigits: 10
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>}>
          <Fab disabled={this.props.simulator2.cur.status !== "idle"} sx={{ position: "fixed", bottom: "10px", right: "10px" }} size="medium" color="primary">
            {this.props.simulator2.cur.status !== "idle" ? <CircularProgress size={30} /> : <QuestionMarkIcon />}
          </Fab>
        </Tooltip>
        }

        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clienteId}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/Simulador2"}
              >
                Simulador 2.0
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clienteId}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">{this.state.isEdit ? "Editar Simulação" : "Nova Simulação"}</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{cliente?.nomeVisaoUnica ?? ""}</h1>
                {/* <Tooltip title="Cross-Sell & Growth">
                  <a className="ml-2" target="_blank" href={this.state.csgLink} rel="noreferrer">
                    <CrossSellSvg height={25} fill="#000" />
                  </a>
                </Tooltip> */}
              </div>
              {(!(this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels) && role.accessLevel > roleLevels?.viewTerritory) &&
                <>
                  <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {this.state.territorio}</h6>
                  <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {this.state.distrito}</h6>
                </>
              }
              {cenarioId && (
                <h5 style={{ fontWeight: "bold" }}>
                  Cenário: #{zeroPad(cenarioId, 4)}
                </h5>
              )}
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                {totalSimulacao > 0 &&
                  <>
                    <Grid item xs={2} sm={8} md={8} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        <b>Valor total Simulação: </b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={4} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        {
                          totalSimulacao.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (this.props.simulator2.simulatorsData.moeda === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </Grid>
                  </>
                }
                {totalSimulacao > 0 &&
                  <>
                    <Grid item xs={2} sm={8} md={8} className="align-self-center" sx={{ paddingTop: "0.5rem !important" }}>
                      <h4>
                        <b>Sua simulação foi qualificada com o score:</b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={4} sx={{ paddingTop: "0.5rem !important" }}>
                      <Semaforo cor={totalCor?.cor} />
                    </Grid>
                  </>

                }
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12} sx={{ alignSelf: "flex-end" }}>
              <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2} justifyContent={"center"}>
                {this.state.canSave ?
                  <Link to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clienteId}`}>
                    <Button
                      onClick={() => this.SaveCenario()}
                      variant="contained"
                      color="laranja"
                      sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                    >
                      Salvar Rascunho
                    </Button>
                  </Link> :
                  <Tooltip title={this.state.hasError ? "Verifique os erros da simulação" : "Adicione ao menos um produto para salvar o cenário"}>
                    <span>
                      <Button
                        disabled={true}
                        variant="contained"
                        color="laranja"
                        sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                      >
                        Salvar Rascunho
                      </Button>
                    </span>
                  </Tooltip>
                }
                {!rascunho && this.state.canSave ?
                  <Link to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clienteId}`}>
                    <Button
                      onClick={() => this.finalizarCenario()}
                      variant="contained"
                      color="verde"
                      sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                    >
                      Finalizar Cenário
                    </Button>
                  </Link> :
                  <Tooltip title="Preencha todos os campos para finalizar o cenário">
                    <span>
                      <Button
                        disabled={true}
                        variant="contained"
                        color="verde"
                        sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                      >
                        Finalizar Cenário
                      </Button>
                    </span>
                  </Tooltip>
                }
                <Link
                  to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clienteId}`}
                >
                  <Button
                    variant="contained"
                    color="vermelho"
                    onClick={() => this.deleteCenario()}
                    sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                  >
                    Sair sem Salvar
                  </Button>
                </Link>
              </Stack>
            </Grid>
            <ConhecendoCliente client={cliente} />

            {/* CROSS-SELLING SECTION */}

            {this.props.simulator2.simulatorsData.isCpActive && this.props.simulator2.simulatorsData.isSeedsActive &&
              <CrossSelling client={cliente} totalCornVolume={this.state.totalCornVolume} exalt={this.state.descExalt} utrisha={this.state.descUtrisha} />
            }

            {/* CROP PROTECTION SIMULATOR SECTION */}
            {this.state.canViewCp &&
              <>
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: this.props.simulator2.simulatorsData.isCpActive ? "#0072ce" : "#e6e8ea", color: this.props.simulator2.simulatorsData.isCpActive ? "#fff" : "#000", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingBottom: "0.5rem !important", paddingTop: "0.5rem !important", paddingRight: "1rem !important", display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
                      <Stack alignItems={"center"} gap={1} direction={"row"} >
                        <p style={{ marginBottom: "0" }}>CROP PROTECTION</p>
                        <IconButton onClick={this.toggleExpandCrop} disabled={!this.props.simulator2.simulatorsData.isCpActive} sx={{ color: "#FFF" }} size="small">
                          {this.props.simulator2.simulatorsData.isCpActive ? (this.state.expandCrop ? <ExpandLessIcon /> : <ExpandMoreIcon />) : <ExpandMoreIcon />}
                        </IconButton>
                      </Stack>
                      <Button color={this.props.simulator2.simulatorsData.isCpActive ? "whiteB" : "primary"} variant="outlined" onClick={this.toggleActiveCrop} sx={{ justifySelf: "flex-end" }}>
                        {this.props.simulator2.simulatorsData.isCpActive ? "Desativar" : "Simular"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {(this.props.simulator2.simulatorsData.isCpActive && this.state.expandCrop) &&
                  <>
                    <Grid item xs={2} sm={8} md={12} style={{ marginTop: '4px', marginBottom: '4px' }}>
                      {total > 0 &&
                        <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                          <div>
                            <label style={{ marginBottom: "14px" }}>Valor total</label>
                            <h4>
                              {
                                total.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: (this.props.simulator2.simulatorsData.moeda === "BRL" ? "BRL" : "USD"),
                                })
                              }
                            </h4>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginBottom: "14px" }}>CPA - CP</label>
                            <h4 >
                              {Number(this.state.cpaActual.toFixed(2)).toLocaleString("pt-BR")}%
                            </h4>
                          </div>
                          <div>
                            <label style={{ marginBottom: "0" }}>Score </label>
                            <Semaforo cor={this.state.nivelGm?.cor} />
                          </div>
                        </Stack>
                      }
                    </Grid>

                    <Grid item xs={2} sm={8} md={12} >
                      <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                        <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                          <p>Selecione os parâmetros da Simulação:</p>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={2} sm={8} md={12} >
                      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={8} md={2}>
                          <label className="d-block">Campanha</label>
                          <Select
                            value={this.props.simulator2.simulatorsData.cenarioV2Cp.campanha}
                            placeholder="Campanha"
                            onChange={this.handleChangeCampaign}
                            sx={{ minWidth: 130, width: "100%" }}
                            size="small"
                          // disabled={rowss.length > 0}
                          >
                            {!this.props.campanhas.isLoading &&
                              this.props.campanhas.campaigns.map((campanha) => (
                                <MenuItem
                                  key={`${campanha.campaignId}_${campanha.salesOrg}`}
                                  value={campanha.groupedCampaign}
                                >
                                  {campanha.groupedCampaign}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={2} sm={8} md={2}>
                          <label>Condição</label>
                          <Select
                            value={this.props.simulator2.simulatorsData.cenarioV2Cp.condicao}
                            placeholder="Condição"
                            onChange={this.handleChangePayCondition}
                            sx={{ width: "100%" }}
                            size="small"
                          >
                            {this.props.condicaoPagamentos.payConditions.map((condicao) => (
                              <MenuItem
                                key={condicao.paymentTermsId}
                                value={condicao.paymentTerms}
                              >
                                {condicao.paymentTerms}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={2} sm={8} md={1.5}>
                          <label>Vencimento</label>
                          <Select
                            value={this.props.simulator2.simulatorsData.cenarioV2Cp.vencimento}
                            placeholder="Vencimento"
                            onChange={this.handleChangeDate}
                            sx={{ width: "100%" }}
                            size="small"
                          >
                            {this.props.dateencargos.dateEncargos.map(
                              (dateencargos) => (
                                <MenuItem key={dateencargos.paymentTermsId} value={dateencargos.fixedValueDate}>
                                  {dateencargos.formatDate}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </Grid>
                        <Grid item xs={2} sm={8} md={2}>
                          <label>Método de Pagamento</label>
                          <Select
                            value={this.props.simulator2.simulatorsData.cenarioV2Cp.metodoPagamento}
                            placeholder="Metodo de Pagamento"
                            onChange={(event) => this.handleChangePaymentMethod(event, this.props.metodoPagamento.metodos.find(metodo => metodo.metodoPagamentoId === event.target.value))}
                            sx={{ width: "100%" }}
                            size="small"
                          >
                            {metodosPagamento
                              .map((metodoPagamento) => (
                                <MenuItem key={metodoPagamento.metodoPagamentoId} value={metodoPagamento.metodoPagamentoId}>
                                  {metodoPagamento.metodo}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        {this.props.metodoPagamento.metodos.find(metodo => metodo.metodoPagamentoId === this.props.simulator2.simulatorsData.cenarioV2Cp.metodoPagamento && metodo.isBarter) && (
                          <Grid item xs={2} sm={8} md={2}>
                            <label>Valorização Barter</label>
                            <ErrorTooltip
                              name="Valorização Barter"
                              id="outlined-size-small"
                              value={Number(this.props.simulator2.simulatorsData.cenarioV2Cp.valorizacaoBarter)}
                              onValueChange={this.handleChangeBarter}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              customInput={TextField}
                              size="small"
                              suffix={"%"}
                              sx={{ input: { textAlign: "right" }, width: "100%" }}
                              maxValue={this.props.metodoPagamento?.maxBarter ?? 0}
                              messageError={`O valor precisa ser igual ou inferior a ${this.props.metodoPagamento?.maxBarter ?? 0}%`}
                              allowNegative={false}
                            />
                          </Grid>
                        )}
                        <Grid item xs={2} sm={8} md={2.5}>
                          <label style={{ opacity: 0 }}>SPACING</label>
                          <ConfirmationDialog variant="outlined" color={"azul"} endIcon={<AddShoppingCartIcon />} fullWidth sx={{ minHeight: "40px" }}
                            options={
                              !this.props.produtos.isLoading ?
                                this.props.produtos.products.filter(x => !rowsCp.some(r => r.produto === x.product))
                                  .map((produto) => { return { value: produto.productId, label: produto.product } }) :
                                []
                            }
                            onChange={this.addProduct}
                          >
                            Adicionar Produto
                          </ConfirmationDialog>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={2} sm={8} md={3}>
                  {total > 0 &&
                    <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                      <div>
                        <label style={{ marginBottom: "14px" }}>Valor total</label>
                        <h4>
                          {
                            total.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: (this.props.simulator2.simulatorsData.moeda === "BRL" ? "BRL" : "USD"),
                            })
                          }
                        </h4>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <label style={{ marginBottom: "14px" }}>CPA - CP</label>
                        <h4 >
                          {Number(this.state.cpaActual.toFixed(2)).toLocaleString("pt-BR")}%
                        </h4>
                      </div>
                      <div>
                        <label style={{ marginBottom: "0" }}>Score </label>
                        <Semaforo cor={this.state.nivelFlexibilidade?.cor} />
                      </div>
                    </Stack>
                  }
                </Grid> */}
                    {rowsCp > 0 && (
                      <Grid item xs={2} sm={8} md={12} >
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "0 !important", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                          <Grid item xs={2} sm={1.5} md={2.5} sx={{ textAlign: { xs: "center", sm: "left" }, paddingTop: "1rem !important" }}>
                            <p>Cesta do Cliente</p>
                          </Grid>
                          <Grid item xs={2} sm={5.5} md={8} sx={{ textAlign: "center", paddingTop: "1rem !important" }}>
                            <p>(Informe os dados de Volume e Preço Negociado)</p>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {rowsCp.length > 0 &&
                      <ResumoResultadosCp
                        cliente={cliente}
                        sumFinalPrice={this.state.socCrop}
                        encargos={this.props?.encargosRate?.EncargosRatings?.rate ?? 0}
                        qtdPremium={this.state.premiumProducts}
                        decontoOfertaEstruturada={this.state.decontoOfertaEstruturada * -1}
                      />}

                    <Grid item xs={2} sm={8} md={12}>
                      {rowsCp.length > 0 &&
                        <SimulatorTableEditable actions={this.props} state={this.state} currency={this.props.simulator2.simulatorsData.moeda} rows={this.props.simulator2.simulators} />}
                      {rowsCp.length === 0 && (
                        <p>Use a caixa de seleção acima para iniciar uma simulação</p>
                      )}
                    </Grid>
                  </>
                }
              </>
            }
            {this.state.canViewSeeds &&
              <>
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: this.props.simulator2.simulatorsData.isSeedsActive ? "#0072ce" : "#e6e8ea", color: this.props.simulator2.simulatorsData.isSeedsActive ? "#fff" : "#000", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingBottom: "0.5rem !important", paddingTop: "0.5rem !important", paddingRight: "1rem !important", display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
                      <Stack alignItems={"center"} gap={1} direction={"row"} >
                        <p style={{ marginBottom: "0" }}>SEEDS</p>
                        <IconButton onClick={this.toggleExpandSeed} disabled={!this.props.simulator2.simulatorsData.isSeedsActive} sx={{ color: "#FFF" }} size="small">
                          {this.props.simulator2.simulatorsData.isSeedsActive ? (this.state.expandSeed ? <ExpandLessIcon /> : <ExpandMoreIcon />) : <ExpandMoreIcon />}
                        </IconButton>
                      </Stack>
                      <Button color={this.props.simulator2.simulatorsData.isSeedsActive ? "whiteB" : "primary"} variant="outlined" onClick={this.toggleActiveSeeds} sx={{ justifySelf: "flex-end" }}>
                        {this.props.simulator2.simulatorsData.isSeedsActive ? "Desativar" : "Simular"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {(this.props.simulator2.simulatorsData.isSeedsActive && this.state.expandSeed) &&
                  <Grid item xs={2} sm={8} md={12}>
                    <SimulatorFormSeeds />
                  </Grid>
                }
              </>
            }

          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...simulator2Actions,
          ...productActions,
          ...campaignActions,
          ...payConditionsActions,
          ...packActions,
          ...descontopackActions,
          ...produtopackActions,
          ...dateEncargosActions,
          ...categoryDiscountActions,
          ...productPriceActions,
          ...EncargosRatingsActions,
          ...GetAllDiscountCategoryActions,
          ...encargosActions,
          ...GetCenarioActions,
          ...SaveCenarioActions,
          ...SaveCenarioItemsActions,
          ...AllCampaignsActions,
          ...DeleteCenarioActions,
          ...AnalyticsProductsActions,
          ...SaveGenerateCenarioActions,
          ...nivelSemaforoActions,
          ...userActions,
          ...metodoPagamentoActions,
          ...seedsEmbalagensActions
        },
        dispatch
      ),
    };
  }
)(SimulatorEditPage);
