import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, FormHelperText, Stack, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simualdorVe.actions';


// Função de validação
// const validate = (values) => {
//   const errors = {};
//   if (!values.nome) {
//     errors.nome = 'Nome é obrigatório';
//   }
//   if (!values.cnpj) {
//     errors.cnpj = 'CNPJ é obrigatório';
//   } else if (!/^(\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2})$/.test(values.cnpj)) {
//     errors.cnpj = 'CNPJ inválido';
//   }
//   return errors;
// };

const FormFornecedor = ({ fornecedor, send }) => {
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');

  const formik = useFormik({
    initialValues: {
      nome: fornecedor.nome ?? '',
      cnpj: fornecedor.cnpj ?? '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.nome) {
        errors.nome = 'Nome é obrigatório';
      }
      if (!values.cnpj) {
        errors.cnpj = 'CNPJ é obrigatório';
      }
      // else if (!/^(\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2})$/.test(values.cnpj)) {
      //   errors.cnpj = 'CNPJ inválido';
      // }
      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },

  });
  useEffect(() => {
    setNome(fornecedor?.nome || '');
    setCnpj(fornecedor?.cnpj || '');
    if (fornecedor.cnpj && fornecedor.nome) {
      formik.setFieldValue('nome', fornecedor.nome);
      formik.setFieldValue('cnpj', fornecedor.cnpj);
    }
  }, [fornecedor]);

  const handleNomeChange = (event) => {
    const { value } = event.target;
    setNome(value);
    formik.setFieldValue('nome', value);

  };

  // const handleCnpjChange = (event) => {
  //   const { value } = event.target;
  //   let formattedValue = value.replace(/\D/g, ''); // Remove tudo exceto dígitos

  //   if (formattedValue.length > 14) {
  //     formattedValue = formattedValue.slice(0, 14);
  //   }

  //   formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
  //   formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  //   formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
  //   formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');

  //   setCnpj(formattedValue);
  //   formik.setFieldValue('cnpj', formattedValue);
  // };
  const handleCnpjChange = (event) => {
    const { value } = event.target;
    setCnpj(value);
    formik.setFieldValue('cnpj', value);
    // Remove tudo exceto os números
    // const formattedValue = value.replace(/[^\d]/g, '');

    // // Aplica a máscara de CNPJ
    // let maskedValue = '';
    // if (formattedValue.length <= 14) {
    //   maskedValue = formattedValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    // } else {
    //   maskedValue = formattedValue.slice(0, 14).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    // }

    // setCnpj(maskedValue);
    // formik.setFieldValue('cnpj', formattedValue); // Define o valor sem máscara no formik

    // // Validar o CNPJ formatado
    // if (formattedValue.length === 14 && !/^(\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2})$/.test(maskedValue)) {
    //   formik.setFieldError('cnpj', 'CNPJ inválido');
    // } else {
    //   formik.setFieldError('cnpj', '');
    // }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        <FormControl fullWidth error={formik.touched.nome && Boolean(formik.errors.nome)}>
          <TextField
            id="nome"
            label="Nome"
            variant="standard"
            type="text"
            value={nome}
            onChange={handleNomeChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.nome && <FormHelperText>{formik.errors.nome}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}>
          <TextField
            id="cnpj"
            type="text"
            label="CNPJ"
            variant="standard"
            value={cnpj}
            onChange={handleCnpjChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.cnpj && <FormHelperText>{formik.errors.cnpj}</FormHelperText>}
        </FormControl>

        <Button variant="contained" color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </Stack>
    </form>
  );
};

class AdminSimuladorCreateFornecedorPage extends React.Component {
  constructor(props) {
    super(props);

    this.sendFornecedor = this.sendFornecedor.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.clearFornecedor();
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.FornecedorId !== undefined && params.FornecedorId !== '') {
      this.props.actions.requestFornecedor(params.FornecedorId)
    }

  }

  sendFornecedor(fornecedor) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.FornecedorId !== undefined && params.FornecedorId !== '')
      this.props.actions.updateFornecedor(params.FornecedorId, fornecedor, navigate);
    else
      this.props.actions.saveFornecedor(fornecedor, navigate);
  }


  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    //var isLoading = this.props.adminSimuladorVe.isLoadingFornecedor;

    const fornecedor = this.props.adminSimuladorVe.fornecedor ? this.props.adminSimuladorVe.fornecedor : [];


    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.adminSimuladorVe.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-Fornecedores"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Fornecedores"}>
                Fornecedores
              </Link>
              <Typography color="text.primary">Novo Fornecedor</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <FormFornecedor fornecedor={fornecedor} send={this.sendFornecedor} />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminSimuladorCreateFornecedorPage);
