/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useCallback } from 'react';
import { featureCollection } from '@turf/turf';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import Map, { Source, Layer, Marker, NavigationControl } from 'react-map-gl';
import DrawControl, { drawRef, mapRef } from './Control-Panel';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import MapIcon from '@mui/icons-material/Map';
import Slider from '@mui/material/Slider';
import PanToolIcon from '@mui/icons-material/PanTool';
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import LayersIcon from '@mui/icons-material/Layers';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import PublicIcon from '@mui/icons-material/Public';
import { ReactComponent as ChangeLayer } from "../../../../assets/svg/ChangeLayer.svg";

import {
  dataLayer, selectedLayer,
  assignedLayer, accountLayer, allAssignedLayer,
  regionLayer, lineLayer, accountLineLayer, allAccountLineLayer,
  assignedLineLayer, municiLayer, stateFarmLayer, statePlotLayer
} from './Map-Style';
import './Control-Panel.css';
import '../mapbox-gl.css';
import FarmPanel from './FarmPanel.jsx';
import PlotPanel from './PlotPanel.jsx';
import ResumeFarm from './ResumeFarmPanel.jsx';
import ResumePlot from './ResumePlotPanel.jsx';
import CoordinatesPagination from "../CoordinatesPagination.jsx";
import MapInformations from "../MapInformations.jsx";
import CountdownTime from './CountdownTime.jsx';
import { isNumber } from '../../../../helpers/utils.js';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ2FicmllbGRhc3NpZSIsImEiOiJjaW93dnp4N2IwMW1pdWNtOHZsOTdrZGtwIn0.q9DFHVBMw2qOZy7Cv4nayA'; // Set your mapbox token here

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}
function isNullOrEmpty(str) {
  return str === null || str === undefined || str.length <= 0;
}

export default function App(props) {
  const { actions, data, plotData, assignedData, accountData,
    farmInfo, plotInfo, farmSelected, polygonRegion,
    selectPolygon, progressFarm,
    isLoading, cleanList, cultures,
    changeLoadState, coordinates, setMapbox, setDraw,
    plotSelected, selectPlotPolygon,
    showPlots, hasPlots, isLoadingPlots, progressPlot, setShowPlots,
    allAssignedData, showAllData,
    countFarm, valueAreaFarm,
    countAssignedFarm, valueAreaAssignedFarm,
    countPlot, valueAreaPlot,
    countAssignedPlot, valueAreaAssignedPlot,
    pointedCultureArea, predictCultureArea, selectData,
    setSelectData, goTo, goToPolygon,
    sumSelectFarmArea, sumSelectPlotArea, copySourceCodes, counties,
    marker, markerOn, onClickMarker,
    addData, showingStates, plotLayer,
    plotApontLayer, plotPreditLayer
  } = props;

  const drawR = drawRef;
  const mapR = mapRef;

  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");

  const [hoverInfo, setHoverInfo] = useState(null);
  const [cutPolygon, setCutPolygon] = useState(null);
  const [culture, setCulture] = React.useState("");
  const [polyHover, setPolyHover] = useState(null);

  const [munici, setMunici] = useState(null);
  const [zoomMap, setZoomMap] = useState(3);
  const [sizeSelection, setSizeSelection] = useState(1);
  const [apparence, setApparence] = useState("mapbox://styles/mapbox/satellite-streets-v12");

  const [updatingMap, setUpdatingMap] = useState(false);
  const [drawTools, setDrawTools] = useState(true);
  const [selectMode, setSelectMode] = useState(false);
  const [edit, setEdit] = useState(false);
  const [shift, setShift] = useState(false);
  const [combine, setCombine] = useState(false);
  const [uncombine, setUncombine] = useState(false);
  const [rope, setRope] = useState(false);
  const [oldCoord, setOldCoord] = useState([0, 0]);

  const [styled, setStyled] = useState(false);
  const [farmOpen, setFarmOpen] = useState(false);
  const [plotOpen, setPlotOpen] = useState(false);
  const [openPlotOptions, setPlotOptions] = useState(false);

  const [cultureSwitch, setCultureSwitch] = useState(false);
  const [resumeFarmOpen, setResumeFarmOpen] = useState(false);
  const [resumePlotOpen, setResumePlotOpen] = useState(false);
  const [plotPredictOpen, setPlotPredictOpen] = useState(false);
  const [plotPointedOpen, setPlotPointedOpen] = useState(false);
  const [transition, setTransition] = useState(Slide);
  const [errorEdit, setErrorEdit] = useState(false);

  //Buttons

  const handleFarmClick = (Transition) => () => {
    setTransition(() => Transition);

    setPlotOpen(false);
    setResumeFarmOpen(false);
    setResumePlotOpen(false);
    setPlotPredictOpen(false);
    setPlotPointedOpen(false);

    setFarmOpen(!farmOpen);
  };
  const handlePlotClick = (Transition) => () => {
    setTransition(() => Transition);

    setFarmOpen(false);
    setResumeFarmOpen(false);
    setResumePlotOpen(false);
    setPlotPredictOpen(false);
    setPlotPointedOpen(false);

    setPlotOpen(!plotOpen);
  };
  function handleResumeFarmClick(Transition) {
    setTransition(() => Transition);

    setPlotOpen(false);
    setFarmOpen(false);
    setResumePlotOpen(false);
    setPlotPredictOpen(false);
    setPlotPointedOpen(false);

    setResumeFarmOpen(!resumeFarmOpen);
  };
  function handleResumePlotClick(Transition) {
    setTransition(() => Transition);

    setPlotOpen(false);
    setFarmOpen(false);
    setResumeFarmOpen(false);
    setPlotPredictOpen(false);
    setPlotPointedOpen(false);

    setResumePlotOpen(!resumePlotOpen);
  };
  function handleClose(Transition) {
    setTransition(() => Transition);

    setPlotOpen(false);
    setFarmOpen(false);
    setResumeFarmOpen(false);
    setResumePlotOpen(false);
    setPlotPredictOpen(false);
    setPlotPointedOpen(false);
  };
  function handlePlotPredictClick(Transition) {
    setTransition(() => Transition);

    setPlotOpen(false);
    setFarmOpen(false);
    setResumeFarmOpen(false);
    setResumePlotOpen(false);
    setPlotPointedOpen(false);
    setPlotPredictOpen(!plotPredictOpen);
  };
  function handlePlotPointedClick(Transition) {
    setTransition(() => Transition);

    setPlotOpen(false);
    setFarmOpen(false);
    setResumeFarmOpen(false);
    setResumePlotOpen(false);
    setPlotPredictOpen(false);
    setPlotPointedOpen(!plotPointedOpen);
  };
  const handleResumeFarmClose = () => {
    setResumeFarmOpen(false);
    setResumePlotOpen(false);

    setPlotOpen(false);
    setFarmOpen(true);
  };
  const handleResumePlotClose = () => {
    setResumeFarmOpen(false);
    setResumePlotOpen(false);

    setFarmOpen(false);
    setPlotOpen(true);
  };
  const handleExtraPlotClose = () => {
    setPlotPointedOpen(false);
    setPlotPredictOpen(false);
    setFarmOpen(false);
    setPlotOpen(false);
    setResumeFarmOpen(false);

    setResumePlotOpen(true);
  };

  const refreshPlots = () => {
    setUpdatingMap(true);
    setShowPlots(false);

    setTimeout(() => {
      setShowPlots(true);
      setUpdatingMap(false);
    }, 1000);
  }
  const changeMapStyle = (value) => {
    setStyled(false);

    setApparence('mapbox://styles/mapbox/' + value);
    mapR.setStyle(apparence);
  }
  const changeSizeSelection = (event) => {
    setSizeSelection(event.target.value);
  }

  function selectPolygonMode(selectMode, draw) {
    setEdit(false);
    if (draw?.getMode() == "simple_select") {
      setSelectMode(!selectMode);
    }
    else {
      try {
        draw?.changeMode("simple_select");
      } catch (err) {
        console.error(err);
      }
    }
  }

  const onHover = useCallback((e) => {
    const {
      features,
      point: { x, y }
    } = e;

    if (!isNullOrEmpty(features)) {
      const hoveredFeature = features && features[0];
      setPolyHover(hoveredFeature && { feature: hoveredFeature, x, y });
    }
    else {
      setPolyHover(null);
    }
  }, []);

  const onClick = useCallback((event, data, plotData, farmSelected, plotSelected, selectData, edit, map, size, selectMode, showPlots, showingStates) => {
    if (!edit && selectMode) {
      const bbox = [
        [event.point.x - size, event.point.y - size],
        [event.point.x + size, event.point.y + size]
      ];
      // Find features intersecting the bounding box.
      const selectedFeatures = map.queryRenderedFeatures(bbox, {
        layers: ['data', 'plot']
      });

      if (selectedFeatures?.length > 0) {
        var modifiedData = [];
        var obj;
        if (selectData != null) {
          for (var i = 0; i < selectData.features.length; i++) {
            obj = selectData.features[i];
            if (obj != null) {
              modifiedData.push(obj);
            }
          }
        }

        selectedFeatures.forEach(x => {
          const hoveredFeature = x;

          if (hoveredFeature != null) {
            if (showingStates) {
              for (var s = 0; s < data.features.length; s++) {
                obj = data.features[s];

                if (obj.properties.Nome_Munic === hoveredFeature.properties.Nome_Munic) {
                  if ((farmSelected.includes(obj.properties.Nome_Munic) || event.originalEvent.ctrlKey == true) && event.originalEvent.shiftKey == false) {
                    var index = farmSelected.indexOf(obj.properties.Nome_Munic);
                    if (index > -1) {
                      modifiedData.splice(index, 1);
                    }
                  }
                  else if (!farmSelected.includes(obj.properties.Nome_Munic)) {
                    if (farmSelected.length >= 100) {
                      setMessageType("Aviso - Muitos Polígonos selecionados");
                      setMessage("O sistema pode apresentar travamentos ou mal funcionamento ao manipular tantos polígonos");
                      setErrorEdit(true);
                      return;
                    }

                    modifiedData.push(obj);
                  }

                  selectPolygon(hoveredFeature.properties.Nome_Munic, obj, event.originalEvent.ctrlKey, event.originalEvent.shiftKey);
                  break;
                }
              }
            }
            else {
              if (!showPlots) {
                for (var j = 0; j < data.features.length; j++) {
                  obj = data.features[j];

                  if (obj.properties.COD_IMOVEL === hoveredFeature.properties.COD_IMOVEL) {
                    if ((farmSelected.includes(obj.properties.COD_IMOVEL) || event.originalEvent.ctrlKey == true) && event.originalEvent.shiftKey == false) {
                      var index = farmSelected.indexOf(obj.properties.COD_IMOVEL);
                      if (index > -1) {
                        modifiedData.splice(index, 1);
                      }
                    }
                    else if (!farmSelected.includes(obj.properties.COD_IMOVEL)) {
                      if (farmSelected.length >= 100) {
                        setMessageType("Aviso - Muitos Polígonos selecionados");
                        setMessage("O sistema pode apresentar travamentos ou mal funcionamento ao manipular tantos polígonos");
                        setErrorEdit(true);
                        return;
                      }

                      modifiedData.push(obj);
                    }

                    selectPolygon(hoveredFeature.properties.COD_IMOVEL, obj, event.originalEvent.ctrlKey, event.originalEvent.shiftKey);
                    break;
                  }
                }
              }
              else {
                for (var f = 0; f < plotData.features.length; f++) {
                  obj = plotData.features[f];

                  var source = obj.properties.COD_TALHAO;
                  var hoveredSource = hoveredFeature.properties.COD_TALHAO;

                  if (source === hoveredSource) {
                    if ((plotSelected.includes(source) || event.originalEvent.ctrlKey == true) && event.originalEvent.shiftKey == false) {
                      var index = plotSelected.indexOf(source);
                      if (index > -1) {
                        modifiedData.splice(index, 1);
                      }
                    }
                    else if (!plotSelected.includes(source)) {
                      if (plotSelected.length >= 100) {
                        setMessageType("Aviso - Muitos Polígonos selecionados");
                        setMessage("O sistema pode apresentar travamentos ou mal funcionamento ao manipular tantos polígonos");
                        setErrorEdit(true);
                        return;
                      }

                      modifiedData.push(obj);
                    }

                    selectPlotPolygon(hoveredSource, obj, event.originalEvent.ctrlKey, event.originalEvent.shiftKey);
                    break;
                  }
                }
              }
            }
          };
        })

        modifiedData = featureCollection(modifiedData);
        setSelectData(modifiedData);
      }
    }
  }, []);

  const onDataLoad = (coordinates, oldCoord, zoomMap, changeLoadState, goTo) => {
    if (oldCoord === coordinates)
      return;

    setOldCoord(coordinates);

    setMunici(null);
    setHoverInfo(null);
    setEdit(false);
    changeLoadState(false);

    goTo('data');
  }

  const onMapLoad = (map, draw) => {
    if (map != null) {
      setMapbox(map);
    }

    if (draw != null) {
      setDraw(draw);
    }
  }

  const clearList = (value) => {
    cleanList(value);

    drawR?.deleteAll();
    setEdit(false);
    setCombine(false);
    setUncombine(false);
    setSelectData(featureCollection([]));
  }
  const removeFromList = (feature, farm, draw) => {
    var modifiedData = [];
    if (feature != null) {
      if (selectData != null) {
        for (var i = 0; i < selectData.features.length; i++) {
          var oldObj = selectData.features[i];
          modifiedData.push(oldObj);
        }
      }

      var obj;
      if (showingStates) {
        for (var k = 0; k < data.features.length; k++) {
          obj = data.features[k];

          if (obj.properties.Nome_Munic === feature.properties.Nome_Munic) {
            if (farmSelected.length <= 0)
              modifiedData = [];

            if (farmSelected.includes(obj.properties.Nome_Munic)) {
              var index = farmSelected.indexOf(obj.properties.Nome_Munic);
              if (index > -1) {
                modifiedData.splice(index, 1);
              }
            }

            selectPolygon(feature.properties.Nome_Munic, obj, false, false);
            break;
          }
        }
      }
      else {
        if (farm) {
          for (var j = 0; j < data.features.length; j++) {
            obj = data.features[j];

            if (obj.properties.COD_IMOVEL === feature.properties.COD_IMOVEL) {
              if (farmSelected.length <= 0)
                modifiedData = [];

              if (farmSelected.includes(obj.properties.COD_IMOVEL)) {
                var index = farmSelected.indexOf(obj.properties.COD_IMOVEL);
                if (index > -1) {
                  modifiedData.splice(index, 1);
                }
              }

              selectPolygon(feature.properties.COD_IMOVEL, obj, false, false);
              break;
            }
          }
        }
        else {
          for (var f = 0; f < plotData.features.length; f++) {
            obj = plotData.features[f];
            var source = obj.properties.COD_TALHAO;
            var hoveredSource = feature.properties.COD_TALHAO;

            if (source === hoveredSource) {
              if (plotSelected.length <= 0)
                modifiedData = [];

              if (plotSelected.includes(source)) {
                var index = plotSelected.indexOf(source);
                if (index > -1) {
                  modifiedData.splice(index, 1);
                }
              }

              selectPlotPolygon(hoveredSource, obj, false, false);
              break;
            }
          }
        }
      }
    };

    modifiedData = featureCollection(modifiedData);
    setSelectData(modifiedData);

    draw?.deleteAll();
    setEdit(false);
  }

  const polyHoverProperties = polyHover ? polyHover.feature.properties : null;

  return (
    <>
      <Grid container spacing={2} sx={{ width: "100%", height: "100%" }}>
        <Grid item xs={12} sx={{ zIndex: 0, position: "relative", paddingLeft: "0px !important", paddingTop: "0 !important" }}>
          <Backdrop
            sx={{ color: '#ffffff', position: "absolute", zIndex: (theme) => theme.zIndex.drawer + 2, backgroundColor: "#000000" }}
            open={isLoading || updatingMap}
          >
            <Stack spacing={2} alignItems="center" direction="column">
              <div
                style={{
                  color: "white",
                  fontFamily: "Gilroy",
                  fontWeight: "Bold"
                }}>
                {isNullOrEmpty(progressFarm) ?
                  "Carregando..." :
                  isNumber(progressFarm) ? <CountdownTime initialValue={progressFarm} /> : progressFarm
                }
              </div>
              <CircularProgress
                color="inherit"
              />
            </Stack>
          </Backdrop>

          <Map
            key={"MapGL"}
            style={{ position: "relative !important", borderRadius: "0 0 8px 8px" }}
            initialViewState={{
              latitude: coordinates[0],
              longitude: coordinates[1],
              zoom: zoomMap
            }}
            mapStyle={apparence}
            mapboxAccessToken={MAPBOX_TOKEN}
            doubleClickZoom={false}
            touchZoomRotate={false}
            touchPitch={false}
            interactiveLayerIds={['data', 'plot']}
            onLoad={onMapLoad(mapR, drawR)}
            onRender={onDataLoad(coordinates, oldCoord, zoomMap, changeLoadState, goTo)}
            onMouseMove={(event) =>
              onHover(event, mapR)}
            onMouseDown={(event) =>
              onClick(event, data, plotData, farmSelected, plotSelected,
                selectData, edit, mapR,
                sizeSelection, selectMode, showPlots, showingStates)}
          >

            <DrawControl
              position="top-right"
              displayControlsDefault={false}
            />

            <NavigationControl
              position="top-right"
              style={{
                backgroundColor: "var(--cort-blue)",
                color: "white",
              }} />

            {markerOn && (
              <Marker
                longitude={marker.longitude}
                latitude={marker.latitude}
                anchor="bottom"
                onClick={onClickMarker}
              >
                <svg height={20} viewBox="0 0 24 24" style={{ fill: '#d00', stroke: 'none' }}>
                  <path d={`M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`} />
                </svg>
              </Marker>
            )}

            {!showPlots && (
              <>
                <Source type="geojson" data={counties}>
                  <Layer {...municiLayer} />
                </Source>
                <Source id='data' type="geojson" data={data}>
                  <Layer {...dataLayer} />
                  <Layer {...lineLayer} />
                </Source>
                <Source id='plot' type="geojson" data={featureCollection([])}>
                  <Layer {...plotLayer} />
                </Source>
                {showingStates && (
                  <Source id='state' type="geojson" data={data}>
                    <Layer {...stateFarmLayer} />
                  </Source>
                )}
                {!edit && (
                  <>
                    <Source id='assigned' type="geojson" data={assignedData}>
                      <Layer {...assignedLayer} />
                    </Source>
                    <Source type="geojson" data={accountData}>
                      <Layer {...accountLayer} />
                    </Source>

                    {showAllData && (
                      <Source type="geojson" data={allAssignedData}>
                        <Layer {...allAssignedLayer} />
                      </Source>
                    )}

                    <Source id='select' type="geojson" data={selectData}>
                      <Layer {...selectedLayer} />
                    </Source>
                  </>
                )}
              </>
            )}
            {showPlots && (
              <>
                <Source id='data' type="geojson" data={featureCollection([])}>
                  <Layer {...dataLayer} />
                </Source>

                <Source type="geojson" data={polygonRegion}>
                  <Layer {...regionLayer} />
                </Source>

                {showingStates && (
                  <Source id='plot' type="geojson" data={data}>
                    <Layer {...plotLayer} />
                    <Layer {...statePlotLayer} />
                  </Source>
                )}

                {!showingStates && (
                  <Source id='plot' type="geojson" data={plotData}>
                    {!cultureSwitch && (
                      <Layer {...plotApontLayer} />
                    )}
                    {cultureSwitch && (
                      <Layer {...plotPreditLayer} />
                    )}
                  </Source>
                )}

                {!edit && (
                  <>
                    <Source id='assigned' type="geojson" data={assignedData}>
                      <Layer {...assignedLineLayer} />
                    </Source>
                    <Source type="geojson" data={accountData}>
                      <Layer {...accountLineLayer} />
                    </Source>

                    {showAllData && (
                      <Source type="geojson" data={allAssignedData}>
                        <Layer {...allAccountLineLayer} />
                      </Source>
                    )}

                    <Source id='select' type="geojson" data={selectData}>
                      <Layer {...selectedLayer} />
                    </Source>
                  </>
                )}
              </>
            )}

            {polyHover && (
              <Box component={Paper} sx={{ position: "absolute", left: polyHover.x + 15, top: polyHover.y, borderRadius: "8px" }}>
                <TableContainer sx={{
                  maxHeight: "40px !important",
                  overflowX: "unset",
                  'table': {
                    border: 'none',
                    overflowWrap: "anywhere",
                    padding: "6px ",
                    borderRadius: "8px 8px 0px 0px",
                  },
                  'td': {
                    border: 'none',
                    overflowWrap: "anywhere",
                    padding: "6px "
                  }
                }}>
                  <Table aria-label="collapsible table" sx={{ overflowX: "unset" }}>
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                      {showingStates && (
                        <TableRow>
                          <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "8px !important" }}>
                            {polyHoverProperties.SIGLA} - {polyHoverProperties.Nome_Munic}
                          </TableCell>
                        </TableRow>
                      )}
                      {!showingStates && (
                        <TableRow>
                          <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "8px !important" }}>
                            {!isNullOrEmpty(polyHoverProperties.ACCOUNT) ? polyHoverProperties.ACCOUNT : "Não atribuido"}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>
                  </Table>
                </TableContainer>
                <TableContainer sx={{
                  borderRadius: "0px 0px 8px 8px",
                  'td': {
                    border: 'none',
                    overflowWrap: "anywhere",
                    padding: "6px ",
                  }
                }}>
                  <Table aria-label="collapsible table">
                    <TableBody>
                      {!isNullOrEmpty(polyHoverProperties.CLIENT_NAME) && (
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                          <TableCell>
                            VU: {polyHoverProperties.CLIENT_NAME !== undefined ? polyHoverProperties.CLIENT_NAME : ""}
                          </TableCell>
                        </TableRow>
                      )}

                      {/* Visualização de estados */}
                      {showingStates && !showPlots && (
                        <>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ fontWeight: 'bold' }}>
                              Fazendas
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Quantidade: {Number(polyHoverProperties.ContagemFazendas).toLocaleString("pt-br")}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Área Total: {Number(Math.round(polyHoverProperties.AreaTotalFazendas)).toLocaleString("pt-br")} ha
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", fontWeight: 'bold' }}>
                              Atribuições
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Quantidade: {Number(polyHoverProperties.FazendasConhecidas).toLocaleString("pt-br")}
                              {" (" + Number((polyHoverProperties.FazendasConhecidas / polyHoverProperties.ContagemFazendas) * 100).toFixed(1).toLocaleString("pt-br") + "%)"}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Área Total: {Number(Math.round(polyHoverProperties.AreaTotalFazendasConhecidas)).toLocaleString("pt-br")} ha
                              {" (" + Number((polyHoverProperties.AreaTotalFazendasConhecidas / polyHoverProperties.AreaTotalFazendas) * 100).toFixed(1).toLocaleString("pt-br") + "%)"}
                            </TableCell>
                          </TableRow>
                        </>
                      )}

                      {showingStates && showPlots && (
                        <>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ fontWeight: 'bold' }}>
                              Talhões
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Quantidade: {Number(polyHoverProperties.ContagemTalhoes).toLocaleString("pt-br")}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Área Total: {Number(Math.round(polyHoverProperties.AreaTotalTalhoes)).toLocaleString("pt-br")} ha
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ fontWeight: 'bold' }}>
                              Atribuições
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Quantidade: {Number(polyHoverProperties.TalhoesConhecidas).toLocaleString("pt-br")}
                              {" (" + Number((polyHoverProperties.TalhoesConhecidas / polyHoverProperties.ContagemTalhoes) * 100).toFixed(1).toLocaleString("pt-br") + "%)"}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell >
                              Área Total: {Number(Math.round(polyHoverProperties.AreaTotalTalhoesConhecidas)).toLocaleString("pt-br")} ha
                              {" (" + Number((polyHoverProperties.AreaTotalTalhoesConhecidas / polyHoverProperties.AreaTotalTalhoes) * 100).toFixed(1).toLocaleString("pt-br") + "%)"}
                            </TableCell>
                          </TableRow>
                        </>
                      )}

                      {/* Visualização de outros modos */}
                      {!showingStates && (
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                          <TableCell >
                            Área: {Number(Math.round(polyHoverProperties.Area !== undefined ? polyHoverProperties.Area : polyHoverProperties.NUM_AREA)).toLocaleString("Pt-Br")} ha
                          </TableCell>
                        </TableRow>
                      )}
                      {showPlots && (
                        <>
                          {!isNullOrEmpty(polyHoverProperties.CULT_APONT) && (
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                Cultura Apontada: {cultures.find(x => x.value == polyHoverProperties.CULT_APONT).name}
                              </TableCell>
                            </TableRow>
                          )}
                          {!isNullOrEmpty(polyHoverProperties.CULT_PLANT_DATE) && (
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                Data Plantio: {new Date(polyHoverProperties.CULT_PLANT_DATE).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })}
                              </TableCell>
                            </TableRow>
                          )}
                          {!isNullOrEmpty(polyHoverProperties.CULT_HARVEST_DATE) && (
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                Data Colheita: {new Date(polyHoverProperties.CULT_HARVEST_DATE).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      {!isNullOrEmpty(polyHoverProperties.USER_NAME) && (
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                          <TableCell >
                            Atribuído Por: {polyHoverProperties.USER_NAME !== undefined ? polyHoverProperties.USER_NAME : ""}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Map>

          <MapInformations
            cultures={cultures}
            actions={actions}
            assignedData={assignedData}
            allAssignedData={allAssignedData}
            pointedCulture={pointedCultureArea}
            predictCulture={predictCultureArea}
            showPlots={showPlots}
            refresh={refreshPlots}
            showingStates={showingStates}
            cultureSwitch={cultureSwitch}
          />

          <BottomNavigation
            showLabels
            sx={{
              backgroundColor: "var(--cort-blue)",
              borderRadius: "5px",
              position: 'absolute',
              right: "122px",
              top: "10px",
            }}
          >
            <BottomNavigationAction sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: "Bold",
              whiteSpace: "nowrap",
              padding: "0px 26px",
              justifyContent: "center",
              '& span': {
                width: "100px",
                textAlign: "center",
                textWrap: "pretty"
              }
            }}
              label={
                <>
                  {isLoadingPlots ? isNullOrEmpty(progressPlot) ?
                    "Carregando..." :
                    isNumber(progressPlot) ? <CountdownTime initialValue={progressPlot} /> : progressPlot :
                    !showPlots ? "Mostrar Talhões" : "Mostrar Fazendas"}
                </>
              }
              onClick={() => !showPlots ? hasPlots ? showingStates ? setShowPlots(true) : setPlotOptions(!openPlotOptions) : {} : setShowPlots(false)}
              icon={isLoadingPlots ? null : <ChangeLayer style={{ width: "18px", height: "24px" }} />}
            />
            {openPlotOptions && (
              <Box sx={{
                position: "absolute",
                top: "56px",
                left: "5px",
                width: "110px",
                background: "#ffffff",
                borderRadius: "0px 0px 8px 8px",
                padding: "8px",
                fontFamily: 'Gilroy',
                fontWeight: 'bold',
                fontSize: "0.8rem"
              }}>
                <Stack direction={"column"} spacing={2}>
                  <div style={{
                    textAlign: 'center',
                    background: "var(--cort-blue)",
                    color: "white",
                    margin: "-8px"
                  }}>
                    Cultura
                  </div>
                  <div
                    onClick={() => { setPlotOptions(false); setCultureSwitch(false); setShowPlots(!showPlots, farmInfo); }}
                    style={{
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}>
                    Apontada
                  </div>
                  <div
                    onClick={() => { setPlotOptions(false); setCultureSwitch(true); setShowPlots(!showPlots, farmInfo) }}
                    style={{
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}>
                    Classificada
                  </div>
                </Stack>
              </Box>
            )}
            <BottomNavigationAction sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: "Bold",
            }}
              label="Fazendas"
              icon={<MapIcon />}
              onClick={() => handleResumeFarmClick()}
            />
            <BottomNavigationAction sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: "Bold"
            }}
              label="Talhões"
              icon={<LayersIcon />}
              onClick={() => handleResumePlotClick()}
            />
          </BottomNavigation>

          <BottomNavigation
            showLabels
            sx={{
              backgroundColor: "var(--cort-blue)",
              borderRadius: "5px",
              position: 'absolute',
              right: "10px",
              top: "10px",
            }}
          >
            <CoordinatesPagination
              addData={addData}
              changeLoadState={changeLoadState}
            />
          </BottomNavigation>

          {/* Ferramentas */}
          <Box sx={{ position: "absolute", top: "115px", left: "10px" }}>
            <Stack direction={"row"} spacing={2}>
              <Tooltip title="Limpar Seleção">
                <IconButton
                  sx={{
                    color: "white", backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "3px",
                  }}
                  onClick={() => clearList("all")}>
                  <PlaylistRemoveIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Alterna entre o modo de seleção/arrasto">
                <IconButton
                  sx={{
                    color: "white",
                    backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                  onClick={() => selectPolygonMode(selectMode, drawR)}>
                  {selectMode && (
                    <PanToolIcon sx={{ width: "15px", height: "15px" }} />
                  )}
                  {!selectMode && (
                    <SwipeDownIcon sx={{ width: "20px", height: "20px" }} />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Aumenta o raio em km que será selecionado os polígonos">
                <Slider
                  label="Distancia da seleção"
                  orientation="horizontal"
                  valueLabelDisplay="auto"
                  min={1}
                  value={sizeSelection}
                  onChange={changeSizeSelection}
                  sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: "8px", width: "200px" }}
                />
              </Tooltip>
            </Stack>

            <Dialog
              open={errorEdit}
              onClose={() => setErrorEdit(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {messageType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  setErrorEdit(false)
                }}
                  autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          {/* Utilitarios */}
          <Box sx={{ position: "absolute", top: "185px", right: "10px" }}>
            <Stack spacing={2}>

              <Tooltip title="Mudar estilo">
                <IconButton
                  sx={{
                    color: "white", backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "3px",
                  }}
                  onClick={() => setStyled(!styled)}
                >
                  <VisibilityIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              </Tooltip>

              {styled && (
                <Paper
                  sx={{
                    position: "absolute",
                    top: "-15px",
                    right: "40px",
                    borderRadius: '8px',
                    padding: '8px',
                    color: 'black',
                    backgroundColor: 'white',
                    maxHeight: '320px',
                    overflowY: 'auto',
                    zIndex: (theme) => theme.zIndex.drawer,
                  }}
                >
                  <div
                    onClick={() => changeMapStyle("streets-v12")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Básico">
                      <PublicIcon />
                    </Tooltip>
                  </div>
                  <div
                    onClick={() => changeMapStyle("satellite-streets-v12")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Satelite">
                      <SatelliteAltIcon />
                    </Tooltip>
                  </div>
                  <div
                    onClick={() => changeMapStyle("dark-v11")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Modo Escuro">
                      <DarkModeIcon />
                    </Tooltip>
                  </div>
                  <div
                    onClick={() => changeMapStyle("light-v11")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Modo Claro">
                      <WbSunnyIcon />
                    </Tooltip>
                  </div>
                </Paper>
              )}

              <Tooltip title="Foca nos polígonos carregados no mapa">
                <IconButton
                  sx={{
                    color: "white", backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "3px",
                  }}
                  onClick={() => goTo('data')}>
                  <ZoomInMapIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              </Tooltip>

            </Stack>
          </Box>

          <Snackbar
            open={farmOpen && !isLoading && !isLoading}

            key={'farm'}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <Box component={Paper}>
              <Grid container spacing={2} sx={{ maxWidth: "350px", maxHeight: "540px", marginTop: "0 !important", marginLeft: "0 !important" }}>
                <Grid item xs={12} sx={{ padding: "0 !important" }}>
                  <FarmPanel
                    data={farmInfo}
                    showingStates={showingStates}
                    copySourceCodes={copySourceCodes}
                    drawR={drawR}
                    inEdit={edit}
                    goToPolygon={goToPolygon}
                    remove={removeFromList}
                    cleanList={clearList}
                  />
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: "16px", textAlign: "center", paddingRight: "8px", paddingLeft: "8px" }}>
                  <Grid item xs={6}>
                    <Typography sx={{
                      fontFamily: "Gilroy",
                      fontWeight: "Bold",
                      fontSize: "clamp(13px, 13px, 100px)",
                    }}>
                      Polígonos Selecionados:
                    </Typography>
                    <Typography sx={{
                      fontFamily: "Gilroy",
                      fontWeight: "Bold",
                      fontSize: "clamp(15px, 15px, 100px)",
                    }}>
                      {Number(farmInfo?.length).toLocaleString("pt-br")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{
                      fontFamily: "Gilroy",
                      fontWeight: "Bold",
                      fontSize: "clamp(13px, 13px, 100px)",
                    }}>
                      Área Total Selecionada:
                    </Typography>
                    <Typography sx={{
                      fontFamily: "Gilroy",
                      fontWeight: "Bold",
                      fontSize: "clamp(15px, 15px, 100px)",
                    }}>
                      {(Math.round(sumSelectFarmArea)).toLocaleString("pt-br")} ha
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      fullWidth
                      sx={{
                        fontFamily: "Gilroy",
                        fontWeight: "Bold",
                        fontSize: "clamp(10px, 10px, 100px)",
                        width: "100%",
                      }}
                      onClick={() => handleResumeFarmClick(TransitionLeft)}
                      variant="contained"
                    >
                      <ListAltIcon />
                      Resumo Geral Fazendas
                    </Button>
                  </Grid>
                  <Grid className='align-self-center' item xs={3}>
                    <Tooltip title="Centralizar mapa nos polígonos selecionados">
                      <Button
                        fullWidth
                        disabled={farmInfo?.length <= 0 || showPlots}
                        sx={{ fontWeight: "Bold", fontSize: "clamp(1px, 1px, 100px)", height: "36px" }}
                        onClick={() => goTo('select')}
                        variant="contained"
                      >
                        <ZoomInMapIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Snackbar>
          <Snackbar
            open={plotOpen && !isLoading && !isLoading}
            key={'plot'}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Grid container sx={{ maxWidth: "350px", maxHeight: "540px", backgroundColor: "white", borderRadius: "8px" }}>
              <Grid item xs={12}>
                <PlotPanel
                  data={showingStates ? farmInfo : plotInfo}
                  copySourceCodes={copySourceCodes}
                  drawR={drawR}
                  inEdit={edit}
                  goToPolygon={goToPolygon}
                  remove={removeFromList}
                  cleanList={clearList}
                  cultures={cultures}
                  valueAreaPlot={valueAreaPlot}
                  showingStates={showingStates}
                />
              </Grid>
              <Grid container spacing={2} className='align-self-center' sx={{ padding: "16px", textAlign: "center" }}>
                <Grid item xs={6}>
                  <Typography sx={{
                    fontFamily: "Gilroy",
                    fontWeight: "Bold",
                    fontSize: "clamp(13px, 13px, 100px)",
                  }}>
                    Polígonos Selecionados:
                  </Typography>
                  <Typography sx={{
                    fontFamily: "Gilroy",
                    fontWeight: "Bold",
                    fontSize: "clamp(15px, 15px, 100px)",
                  }}>
                    {!showingStates ? Number(plotInfo?.length).toLocaleString("pt-br") : Number(farmInfo?.length).toLocaleString("pt-br")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{
                    fontFamily: "Gilroy",
                    fontWeight: "Bold",
                    fontSize: "clamp(13px, 13px, 100px)",
                  }}>
                    Área Total Selecionada:
                  </Typography>
                  <Typography sx={{
                    fontFamily: "Gilroy",
                    fontWeight: "Bold",
                    fontSize: "clamp(15px, 15px, 100px)",
                  }}>
                    {!showingStates ? (Math.round(sumSelectPlotArea)).toLocaleString("pt-br") : (Math.round(sumSelectFarmArea)).toLocaleString("pt-br")} ha
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Button
                    fullWidth
                    sx={{
                      fontFamily: "Gilroy",
                      fontWeight: "Bold",
                      fontSize: "clamp(10px, 10px, 100px)",
                      width: "100%",
                    }}
                    onClick={() => handleResumePlotClick(TransitionLeft)}
                    variant="contained"
                  >
                    <ListAltIcon />
                    Resumo Geral Talhões
                  </Button>
                </Grid>
                <Grid className='align-self-center' item xs={3}>
                  <Tooltip title="Centralizar mapa nos polígonos selecionados">
                    <Button
                      fullWidth
                      disabled={plotInfo?.length <= 0 || !showPlots}
                      sx={{ fontWeight: "Bold", fontSize: "clamp(1px, 1px, 100px)", height: "36px" }}
                      onClick={() => goTo('select')}
                      variant="contained"
                    >
                      <ZoomInMapIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12}>
              </Grid>
            </Grid>
          </Snackbar>

          <Snackbar
            open={resumeFarmOpen && !isLoading && !isLoading}
            key={'farmResume'}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Grid container sx={{ borderRadius: "8px", background: "white", width: "415px" }}>
              <Grid item xs={12}>
                <ResumeFarm
                  handleResumeClose={() => handleClose()}
                  countFarm={countFarm}
                  valueAreaFarm={valueAreaFarm}
                  countAssignedFarm={countAssignedFarm}
                  valueAreaAssignedFarm={valueAreaAssignedFarm}
                />
              </Grid>
              <Grid item xs={12} sx={{ padding: '16px' }}>
                <Button
                  fullWidth
                  sx={{
                    fontFamily: "Gilroy",
                    fontWeight: "Bold",
                    fontSize: "clamp(10px, 10px, 100px)",
                    width: "100%"
                  }}
                  onClick={() => handleResumeFarmClose()}
                  variant="contained"
                >
                  <ListAltIcon />
                  Selecionar Polígono
                </Button>
              </Grid>
            </Grid>
          </Snackbar>
          <Snackbar
            open={resumePlotOpen && !isLoading && !isLoading}
            key={'plotResume'}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Grid container sx={{ background: "white", borderRadius: "8px", width: "415px" }}>
              <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                <ResumePlot
                  handleResumeClose={() => handleClose()}
                  countAssignedPlot={countAssignedPlot}
                  countPlot={countPlot}
                  valueAreaAssignedPlot={valueAreaAssignedPlot}
                  valueAreaPlot={valueAreaPlot}
                />
              </Grid>
              <Grid item xs={6} sx={{ padding: "0px 8px" }}>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                      <TableRow>
                        <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", textAlign: "center !important", padding: "4px !important" }}>
                          Cultura Apontada
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ display: "flex !important", flexFlow: "column", overflowY: "auto", maxHeight: "80px" }}>
                      {Object.keys(pointedCultureArea).map((key, index) => {
                        return (
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                              {key + ": " + Number(Math.round(pointedCultureArea[key].area)).toLocaleString("Pt-Br") + " ha"}
                              {" (" + Number((pointedCultureArea[key].area / valueAreaPlot) * 100).toFixed(1).toLocaleString("Pt-Br") + "%)"}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer >
              </Grid>
              <Grid item xs={6} sx={{ padding: "0px 8px" }}>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                      <TableRow>
                        <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", textAlign: "center !important", padding: "4px !important" }}>
                          Cultura Classificada
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ display: "flex !important", flexFlow: "column", overflowY: "auto", maxHeight: "80px" }}>
                      {Object.keys(predictCultureArea).map((key, index) => {
                        return (
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                              {key + ": " + Number(Math.round(predictCultureArea[key].area)).toLocaleString("Pt-Br") + " ha"}
                              {" (" + Number((predictCultureArea[key].area / valueAreaPlot) * 100).toFixed(1).toLocaleString("Pt-Br") + "%)"}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer >
              </Grid>
              <Grid item xs={12} sx={{ padding: "16px 8px" }}>
                <Button
                  fullWidth
                  sx={{
                    fontFamily: "Gilroy",
                    fontWeight: "Bold",
                    fontSize: "clamp(10px, 10px, 100px)",
                    width: "100%",
                  }}
                  onClick={() => handleResumePlotClose()}
                  variant="contained"
                >
                  <ListAltIcon />
                  Selecionar Polígono
                </Button>
              </Grid>
            </Grid>
          </Snackbar>
        </Grid>
      </Grid>
    </>
  );
}