import { cenariosActions } from "./cenario.actions";
import { simulatorActions } from "./simulator.actions";

const requestSaveCenarioItemsType = "REQUEST_SaveCenarioItems";
const receiveSaveCenarioItemsType = "RECEIVE_SaveCenarioItems";
const initialState = { SaveCenarioItems: [], isLoading: false };

export const SaveCenarioItemsActions = {
  createSaveCenarioItems: (cenarioId, campanha, condicao, vencimento) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })
    // console.log(rows, getState())

    dispatch({ type: requestSaveCenarioItemsType });
    // console.log("SAVE CENARIO ITENS")
    const url = `/api/cenarioitems/${cenarioId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const SaveCenarioItems = await response.json();
    dispatch({ type: receiveSaveCenarioItemsType, SaveCenarioItems });

  },
  createSaveCenarioItemsAndClear: (cenarioId, campanha, condicao, vencimento, clienteId) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })

    dispatch({ type: requestSaveCenarioItemsType });
    // console.log("SAVE CENARIO ITENS")
    const url = `/api/cenarioitems/${cenarioId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    try {
      const SaveCenarioItems = await response.json();
      dispatch({ type: receiveSaveCenarioItemsType, SaveCenarioItems });
    }
    catch {

    }
    dispatch(simulatorActions.removeAllRows())
    dispatch(cenariosActions.requestCenarios(clienteId))
  },
  createSaveCenarioItemsAndSend: (cenarioId, campanha, condicao, vencimento, clienteId, comentario) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })

    dispatch({ type: requestSaveCenarioItemsType });

    const url = `/api/cenarioitems/${cenarioId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    try {
      const SaveCenarioItems = await response.json();
      dispatch({ type: receiveSaveCenarioItemsType, SaveCenarioItems });
    }
    catch {

    }
    dispatch(simulatorActions.removeAllRows())
    dispatch(cenariosActions.enviarCenario(clienteId, cenarioId, comentario));
    dispatch(cenariosActions.requestCenarios(clienteId))
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestSaveCenarioItemsType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveSaveCenarioItemsType) {
    return {
      state: initialState,
      SaveCenarioItems: action.SaveCenarioItems,
      isLoading: false,
    };
  }

  return state;
};
