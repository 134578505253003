import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Checkbox, FormHelperText, Input, InputAdornment, MenuItem, Select, Stack, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { adminUserActions } from '../../../../../actions/admin/user.actions';
import { StocksActions as stockActions } from '../../../../../actions/stock.actions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useFormik } from 'formik';
import { adminModuloActions } from '../../../../../actions/admin/modulo.actions';
import { adminRoleActions } from '../../../../../actions/admin/role.actions';
import TransferList from '../../../../EnchantedTransferList';
import { adminTerritoryActions } from '../../../../../actions/admin/territory.actions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AllowUseNavigate, { navigate } from '../../../../Navigate';
import Virtualize from '../../../../AutocompleteVirtual';
import { isNullOrEmpty } from '../../../../../helpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simualdorVe.actions';

const camposTexto = [
  { texto: "Nome", prop: "nome" },
];

const FormEdit = ({
  produto = {},
  fornecedores = [],
  grupoMateriais = [],
  send = (produto) => { },
  ...props
}) => {
  const formik = useFormik({
    initialValues: {
      nome: produto.nome || '',
      fornecedor: produto.fornecedor?.id || '',
      grupoMaterial: produto.grupoMaterial?.id || ''
    },
    validate: (values) => {
      const errors = {};
      if (!values.nome) {
        errors.nome = 'Nome é obrigatório';
      }
      if (!values.fornecedor) {
        errors.fornecedor = 'Fornecedor é obrigatório';
      }
      if (!values.grupoMaterial) {
        errors.grupoMaterial = 'Grupo Material é obrigatório';
      }
      return errors;
    },
    onSubmit: (values) => {
      send(values);
    },
  });

  useEffect(() => {
      formik.setValues({
        nome: produto.nome || '',
        fornecedor: produto.fornecedor?.id || '',
        grupoMaterial: produto.grupoMaterial?.id || ''
      });

  }, [produto]);

  const handleFornecedorChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue('fornecedor', value);
  };

  const handleGrupoMaterialChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue('grupoMaterial', value);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        <FormControl fullWidth error={formik.touched[camposTexto.prop] && Boolean(formik.errors[camposTexto.prop])}>
          <TextField
            id="nome"
            label="Nome"
            variant="standard"
            type="text"
            value={formik.values.nome}
            onChange={formik.handleChange}
          />
          {formik.touched.nome && <FormHelperText>{formik.errors.nome}</FormHelperText>}
        </FormControl>

        <FormControl error={formik.touched.fornecedor && Boolean(formik.errors.fornecedor)} variant="standard" fullWidth>
          <InputLabel id="fornecedor-label">Fornecedor</InputLabel>
          <Select
            labelId="fornecedor-label"
            id="fornecedor"
            aria-describedby="fornecedor-text"
            value={formik.values.fornecedor}
            label="Fornecedor"
            required
            onChange={handleFornecedorChange}
          >
            {
              fornecedores.map((x, index) => (
                <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
              ))
            }
          </Select>
          <FormHelperText id="fornecedor-text">
            {formik.touched.fornecedor && formik.errors.fornecedor}
          </FormHelperText>
        </FormControl>

        <FormControl error={formik.touched.grupoMaterial && Boolean(formik.errors.grupoMaterial)} variant="standard" fullWidth>
          <InputLabel id="grupo-material-label">Grupo Material</InputLabel>
          <Select
            labelId="grupo-material-label"
            id="grupoMaterial"
            aria-describedby="grupoMaterial-text"
            label="Grupo Material"
            required
            value={formik.values.grupoMaterial}
            onChange={handleGrupoMaterialChange}
          >
            {
              grupoMateriais.map((x, index) => (
                <MenuItem key={index} value={x.id}>{x.nome}</MenuItem>
              ))
            }
          </Select>
          <FormHelperText id="grupoMaterial-text">
            {formik.touched.grupoMaterial && formik.errors.grupoMaterial}
          </FormHelperText>
        </FormControl>

        <Button variant="contained" color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </Stack>
    </form>
  );
};

class AdminUsuarioEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.sendProduto = this.sendProduto.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.clearProduto();
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.ProductId !== undefined && params.ProductId !== '')
      this.props.actions.requestProduct(params.ProductId)

    this.props.actions.requestFornecedores();
    this.props.actions.requestGrupoMateriais();
  }

  sendProduto(produto) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.ProductId !== undefined && params.ProductId !== '')
      this.props.actions.updateProduto(params.ProductId, produto, navigate);
    else
      this.props.actions.saveProduto(produto, navigate);
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    //const produto = this.props.adminSimuladorVe.produto ? this.props.adminSimuladorVe.produto : [];

    // var isLoading = this.props.adminUser.isLoadingUser ||
    //   this.props.adminUser.isLoadingRoleLevels ||
    //   this.props.adminModulo.isLoading ||
    //   this.props.adminRole.isLoading;
    // this.props.adminTerritory.isLoading ||
    // this.props.adminTerritory.isLoadingAreas ||
    // this.props.adminTerritory.isLoadingDistritos;

    // var isDataLoad = this.props.adminUser;

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.adminSimuladorVe.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-SimuladorVendaExterna"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Produtos"}>
                Produtos
              </Link>
              <Typography color="text.primary">{(params.UserId !== undefined && params.UserId !== '') ? "Editar" : "Novo"}</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {/* {!isLoading && isDataLoad && */}
          <FormEdit
            produto={this.props.adminSimuladorVe.produto ?? {}}
            fornecedores={this.props.adminSimuladorVe.fornecedores}
            grupoMateriais={this.props.adminSimuladorVe.grupoMaterial}
            send={this.sendProduto}
          />
          {/* } */}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        //...adminUserActions,
        // ...adminModuloActions,
        // ...adminRoleActions,
        // ...adminTerritoryActions,
        // ...stockActions,
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminUsuarioEditPage);
