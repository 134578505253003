import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GetCenarioActions } from "../../../actions/getcenario.actions";
import { clientActions } from "../../../actions/client.actions";
import { productActions } from "../../../actions/products.actions";
import { AllCampaignsActions } from "../../../actions/allcampaign.actions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import TextField from "@mui/material/TextField";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import CircleIcon from '@mui/icons-material/Circle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { nivelSemaforoActions } from "../../../actions/nivelSemaforo.actions";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Button } from "@mui/material";
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as CrossSellSvg } from "../../../assets/svg/CrossSell.svg";
import { cenariosActions } from "../../../actions/cenario.actions";
import { userActions } from "../../../actions/user.actions";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
          },
          minWidth: "77px"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  },
});

const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

const isThisYear = (someDate) => {
  const today = new Date()
  return someDate.getFullYear() === today.getFullYear()
}

const invertSignal = (value = 0) => {
  return value * -1;
}

const zeroPad = (num, places) => String(num).padStart(places, '0')

function RenderForecastsTable({ props, currency, role }) {
  const rows = props.getCenario.GetCenario.itensCenarios;
  const [rowHeight, setRowHeight] = React.useState(0);

  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{
              "th": {
                fontWeight: "bold"
              },
            }}>

              <TableCell>Produto</TableCell>
              <TableCell align="right">Preço</TableCell>
              {role?.workflowViewInternalData && (
                <TableCell align="right">Encargos</TableCell>
              )}
              <TableCell align="right">Desconto Categoria</TableCell>
              <TableCell align="right">Hectares tratados</TableCell>
              <TableCell align="right">Combo Area</TableCell>
              {role?.workflowViewInternalData && (
                <TableCell align="right">Desconto Packs</TableCell>
              )}
              <TableCell align="right">Preço Final Corteva</TableCell>
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Preço Negociado</TableCell>
              {role?.workflowViewInternalData && (
                <TableCell align="right">Flexibilidade</TableCell>
              )}
              <TableCell align="right">Ajuste</TableCell>
              <TableCell align="right">Valor Final</TableCell>

              {(role?.workflowViewEconomyData || role?.workflowViewEconomyData || role?.workflowViewSensitiveData || role?.isAdmin) &&
                <TableCell sx={{ backgroundColor: "var(--cort-blue)", borderColor: "var(--cort-blue)", minWidth: 0 }}></TableCell>
              }
              {/*<TableCell align="right">Cogs</TableCell>
                  <TableCell align="right">Sales Deduction</TableCell>
                   <TableCell align="right">NUP (A  vista)</TableCell> 
                  <TableCell align="right">NUP Preço Negociado</TableCell>
                  <TableCell align="right">Saúde Negociação</TableCell>
                  <TableCell align="right">% Saúde Negociação</TableCell>
                  <TableCell align="center">Score</TableCell>*/}
              {(role?.workflowViewEconomyData || role?.isAdmin) && (
                <>
                  <TableCell align="center">GM Target</TableCell>
                  <TableCell align="center">Flexibilidade Aprovada</TableCell>
                </>
              )}
              {(role?.workflowViewEconomyData || role?.isAdmin) && (
                <>
                  <TableCell align="center">Sales Deduction</TableCell>
                  <TableCell align="center">COGS</TableCell>
                  <TableCell align="center">GM</TableCell>
                  <TableCell align="center">GM %</TableCell>
                  {/*<TableCell align="center">Flexibilidade Marketing</TableCell>*/}
                  <TableCell align="center">%Atingido GM</TableCell>
                  <TableCell align="center">%Atingido Flex</TableCell>
                </>
              )}
              {(role?.workflowViewSensitiveData || role?.isAdmin) &&
                <>
                  <TableCell align="center">Score</TableCell>
                </>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.produtos.isLoading && rows.map((row, i) => {
              var corAtingida = `${row?.corAtingido === 0 ? "vermelho" : row?.corAtingido === 1 ? "amarelo" : "verde"}`;
              var imagem = props.produtos.products.find(x => x.productId === row.produtoId)
              return (

                <TableRow key={row.produto} ref={rowRef}>
                  <TableCell >
                    {imagem?.imagemProduto ? <img src={imagem.imagemProduto} height="50" style={{ maxWidth: "150px" }} alt={row.produto} /> : row.produto}
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.preco.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  {role?.workflowViewInternalData && (
                    <TableCell align="right">
                      {Number(row.encargos * 100).toLocaleString("pt-BR")}%
                    </TableCell>)}
                  <TableCell align="right">
                    {invertSignal(row.descontoCategoria * 100)}%
                  </TableCell>
                  <TableCell align="right">
                    {(row.hectaresTratados).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {(Number.isInteger(row.comboArea) ? row.comboArea : 0).toLocaleString("pt-BR")}
                  </TableCell>
                  {role?.workflowViewInternalData && (
                    <TableCell align="right">
                      {/*
                      (descontopack).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })*/
                        `${invertSignal(row.descontoPacks)}%`
                      }
                    </TableCell>)}
                  <TableCell align="right">
                    {
                      row.valorFinalCorteva.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {(row.volume).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.precoNegociado.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  {role?.workflowViewInternalData && (
                    <>
                      {/*<TableCell align="right">
                        {invertSignal(row.flexibilidade).toFixed(3).toLocaleString("pt-BR", {
                          style: "currency",
                        })}
                        %
                      </TableCell>*/}
                      <TableCell align="right">
                        {invertSignal(row.flexibilidade).toFixed(3).toLocaleString("pt-BR", {
                          style: "currency",
                        })}%
                      </TableCell>
                    </>
                  )}
                  <TableCell align="right">
                    {
                      row.ajuste.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      Number(parseFloat(row.valorFinal).toFixed(2)).toLocaleString("pt-br", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                  {(role?.workflowViewEconomyData || role?.workflowViewEconomyData || role?.workflowViewSensitiveData || role?.isAdmin) &&
                    <TableCell sx={{ backgroundColor: "var(--cort-blue)", borderColor: "var(--cort-blue)", minWidth: 0 }}></TableCell>
                  }
                  {(role?.workflowViewEconomyData || role?.isAdmin) && (
                    <>
                      <TableCell align="center">
                        {
                          `${Number(parseFloat(row.gmTarget).toFixed(2)).toLocaleString("pt-br")}%`
                        }
                      </TableCell>
                      <TableCell align="center">
                        {invertSignal(row.flexibilidadeTargetMkt).toFixed(2).toLocaleString("pt-BR", {
                          style: "currency",
                        })}%
                      </TableCell>
                    </>)}
                  {(role?.workflowViewEconomyData || role?.isAdmin) && (
                    <>
                      <TableCell align="center">
                        {
                          Number(parseFloat(row.valorFinal * (row.salesDeduction / 100)).toFixed(2)).toLocaleString("pt-br", {
                            style: "currency",
                            currency: (currency === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </TableCell>

                      <TableCell align="center">
                        {
                          Number((currency === "BRL" ? row.volume * row.cogs * row.cur : row.volume * row.cogs).toFixed(2)).toLocaleString("pt-br", {
                            style: "currency",
                            currency: (currency === "BRL" ? "BRL" : "USD"),
                          })
                        }

                      </TableCell>

                      <TableCell align="center">
                        {
                          Number(parseFloat(row.gm).toFixed(2)).toLocaleString("pt-br")
                        }
                      </TableCell>

                      <TableCell align="center">
                        {
                          Number(parseFloat(row.gmPer).toFixed(2)).toLocaleString("pt-br")
                        }%
                      </TableCell>
                      {/*<TableCell align="center">
                        {
                          Number(parseFloat(row.flexibilidadeMkt).toFixed(2)).toLocaleString("pt-br")
                        }%
                      </TableCell>*/}
                      <TableCell align="center">
                        {
                          Number(parseFloat(row.finalGmPer).toFixed(2)).toLocaleString("pt-br")
                        }%
                      </TableCell>
                      <TableCell align="center">
                        {
                          Number(parseFloat(row.finalFlexibilidadeMkt).toFixed(2)).toLocaleString("pt-br")
                        }%
                      </TableCell>
                    </>
                  )}
                  {(role?.workflowViewSensitiveData || role?.isAdmin) &&
                    <TableCell align="center">
                      <CircleIcon color={corAtingida} />
                    </TableCell>
                  }
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer >
  );
}

function ActionDialogButton({ message = "", title = "", label = "Justificativa*", validInput = false, validFunction = (motivo = "") => { return motivo.replaceAll(" ", "").length < 20 }, helperText = (motivo = "") => { return `Quantidade de Caracteres: ${motivo.replaceAll(" ", "").length} de 20`; }, onClick = (motivo = "") => { }, children, ...props }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [motivo, setMotivo] = React.useState("");

  const handleChangeMotivo = (e) => {
    setMotivo(e.target.value);
  }

  const handleClickOpenDialog = (e) => {
    setMotivo("");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMotivo("");
  };

  const enviarAprovacaoCenario = () => {
    if (!validInput) {
      onClick(motivo);
      setOpenDialog(false);
    } else {
      if (!validFunction(motivo)) {
        onClick(motivo);
        setOpenDialog(false);
      }
    }
  }

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
          {validInput ?
            <TextField
              autoFocus
              value={motivo}
              onChange={handleChangeMotivo}
              margin="dense"
              id="name"
              label={label}
              fullWidth
              variant="standard"
              error={validFunction(motivo)}
              helperText={validFunction(motivo) ? helperText(motivo) : ""}
            />
            : <TextField
              autoFocus
              value={motivo}
              onChange={handleChangeMotivo}
              margin="dense"
              id="name"
              label={label}
              fullWidth
              variant="standard"
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={enviarAprovacaoCenario}>Enviar</Button>
        </DialogActions>
      </Dialog>
      <Button {...props} onClick={handleClickOpenDialog}>
        {children}
      </Button>
    </>
  );
}

class ViewCenarioDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
    };
  }

  componentWillUnmount() {
    this.props.actions.setCenario([]);
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ clientId: params.GroupId })
    this.props.actions.GetCenario(params.CenarioId)
    this.props.actions.requestClients();
    this.props.actions.requestAllCampaigns();
    this.props.actions.requestAllNivelSemaforo();
    this.props.actions.requestRoleLevels();
    this.props.actions.requestRole();
    this.props.actions.requestProductsCenario(params.CenarioId)
  }

  render() {
    var clientes = ""
    var rowss = this.props.getCenario.GetCenario;
    var territorio = "";
    var distrito = "";
    var cenarioId = rowss?.cenarioId
    var soma = 0
    var currency = ""
    var role = ""
    var roleLevels = undefined
    var moeda = "USD"
    var csgLink = ""

    if (this.props.getCenario.isLoading === false && rowss?.length !== 0) {
      var date = new Date(rowss?.vencimento)//.split("T")[0]
      //var [year, month, day] = date.split("-")
      var result = date.toLocaleDateString("pt-BR")//[day, month, year].join("/")
      // soma = this.props.getCenario.GetCenario.itensCenarios.reduce((sum, x) => { return sum + x.valorFinal }, 0)
      clientes = this.props.clientes.clients.find((b) => b.clientId === this.state.clientId)
      if (clientes)
        csgLink = `https://app.powerbi.com.mcas.ms/groups/83b365d1-ccde-4480-a7d3-bda8b9ca0769/reports/ad772169-03f1-4ac4-888d-ab4c5e1c00d4/ReportSection821cb735e70de341d5b2?filter=Clientes/group_id eq '${clientes.clientId}' and Clientes/territorio eq '${clientes.territory}'`

      territorio = rowss?.territorio;
      distrito = rowss?.distrito;
    }

    if (!this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {
      roleLevels = this.props.user.roleLevels
      role = this.props.user.role.role
    }

    if (this.props.allcampanhas.isLoading === false) {
      currency = this.props.allcampanhas.AllCampaigns.find(
        (b) => b.groupedCampaign === rowss?.campanha
      );
      moeda = rowss?.moeda == null || rowss?.moeda === undefined ? (currency ? "USD" : currency?.currency) : rowss?.moeda;
    }

    var saudeSimulacao = 0;
    var gmTarget = 0;
    var gmActual = 0;
    var finalGm = 0;

    var flexTarget = 0;
    var flexActual = 0;
    var finalFlex = 0;

    var somaCorteva = 0;
    var desconto = 0;
    var nivel = undefined;
    var isApprover = undefined;
    if (rowss != null && rowss !== undefined && rowss?.itensCenarios != null && rowss?.itensCenarios !== undefined) {
      soma = rowss?.soma;
      somaCorteva = rowss?.somaCorteva;

      gmTarget = rowss?.gmTarget;
      gmActual = rowss?.gmActual;
      finalGm = rowss?.finalGm;

      flexTarget = rowss?.flexibilidadeTargetMkt;
      flexActual = rowss?.flexibilidadeActualMkt;
      finalFlex = rowss?.finalFlexibilidadeMkt;
      //Validação manual das cores
      var corFlex = `${finalFlex > 100 ? "vermelho" : finalFlex == 100 ? "amarelo" : "verde"}`;
      var corGm = `${finalGm >= 97 ? "verde" : finalGm >= 92 ? "amarelo" : "vermelho"}`;

      desconto = rowss?.desconto;
      isApprover = rowss?.isApprover;
    }

    var isLoading = this.props.getCenario.isLoading || this.props.clientes.isLoading || this.props.allcampanhas.isLoading || this.props.produtos.isLoading || this.props.nivelSemaforo.isLoading || this.props.user.isLoadingRoleLevels;
    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/Workflow`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/Workflow`}
              >
                Workflow Aprovação
              </Link>
              <Typography color="text.primary">Visualizar Simulação</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={3} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={12}>
              <Grid container spacing={3} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={8} md={5}>
                  {cenarioId &&
                    <Stack
                      justifyContent="flex-start"
                      alignItems="start"
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      sx={{
                        [`& h5`]: {
                          fontWeight: "bold",
                          marginBottom: 0
                        },
                      }}
                    >
                      <h5>Simulação #{zeroPad(cenarioId, 4)}</h5>
                      <h5>Distrito: {distrito}</h5>
                      <h5>Território: {territorio}</h5>
                    </Stack>
                  }
                </Grid>
                <Grid item xs={2} sm={8} md={1.5}>
                </Grid>
                <Grid item xs={2} sm={8} md={2.5}>
                  <Grid container sx={{ marginTop: "0px !important" }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    {(role?.workflowViewScoreData || role?.isAdmin) && (
                      <>
                        <Grid item xs={1} sm={4} md={6} className="align-self-center" sx={{ paddingTop: "0 !important", fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.1vw, 1.5rem)" }, fontWeight: "Bold" }}>
                          Score da Negociação:
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} sx={{ paddingTop: "0px !important" }}>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            className="border border-2 d-table p-2 rounded"
                            sx={{
                              borderColor: rowss?.corAtingido != null ? `${rowss?.corAtingido === 0 ? "#C00000" : rowss?.corAtingido === 1 ? "#FFC000" : "#00B050"} !important` : "var(--bs-border-color) !important"
                            }}>
                            <CircleIcon sx={{ color: `${rowss?.corAtingido === 2 ? "#00B050" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.5vw, 1.5rem)" } }} />
                            <CircleIcon sx={{ color: `${rowss?.corAtingido === 1 ? "#FFC000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.5vw, 1.5rem)" } }} />
                            <CircleIcon sx={{ color: `${rowss?.corAtingido === 0 ? "#C00000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 1.5rem)", sm: "1.5rem", md: "clamp(0.75rem, 1.5vw, 1.5rem)" } }} />
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {(!isLoading && rowss?.situacaoCenario === 3 && isApprover) &&
                  <Grid item xs={2} sm={8} md={3}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                    >
                      <ActionDialogButton aria-label="Aprovar" variant="contained"
                        disabled={rowss?.situacaoCenario !== 3 || !isApprover}
                        color={"verde"}
                        title={"Deseja realmente aprovar o cenário?"}
                        message={"Insira uma justificativa para a aprovação"}
                        onClick={(e) => { this.props.actions.aprovarCenario(cenarioId, e) }}
                        size="large"
                      >
                        Aprovar
                      </ActionDialogButton>
                      <ActionDialogButton aria-label="Reprovar" variant="contained"
                        disabled={rowss?.situacaoCenario !== 3 || !isApprover}
                        color={"vermelho"}
                        title={"Deseja realmente reprovar o cenário?"}
                        message={"Insira uma justificativa para a reprovação"}
                        validInput={true}
                        onClick={(e) => { this.props.actions.reprovarCenario(cenarioId, e) }}
                        size="large"
                      >
                        Reprovar
                      </ActionDialogButton>
                    </Stack>
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12}>
              <Divider sx={{ borderColor: 'black', borderBottom: '2px dotted' }} />
            </Grid>

            <Grid item xs={2} sm={8} md={5.5}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={1} sm={3} md={4} className="align-self-center">
                  <h5>
                    <b>Cliente: </b>
                  </h5>
                </Grid>
                <Grid item xs={1} sm={4} md={8} className="align-self-center">
                  <div style={{ display: "flex" }}>
                    <h5>{clientes?.clientName}</h5>
                    <Tooltip title="Cross-Sell & Growth">
                      <a className="ml-2" target="_blank" href={csgLink} rel="noreferrer">
                        <CrossSellSvg height={25} fill="#000" />
                      </a>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item xs={1} sm={3} md={4} className="align-self-center">
                  <h5>
                    <b>Campanha: </b>
                  </h5>
                </Grid>
                <Grid item xs={1} sm={4} md={8} className="align-self-center">
                  <h5>
                    {rowss?.campanha}
                  </h5>
                </Grid>
                <Grid item xs={1} sm={3} md={4} className="align-self-center">
                  <h5>
                    <b>Condição: </b>
                  </h5>
                </Grid>
                <Grid item xs={1} sm={4} md={8} className="align-self-center">
                  <h5>
                    {rowss?.condicao}
                  </h5>
                </Grid>
                <Grid item xs={1} sm={3} md={4} className="align-self-center">
                  <h5>
                    <b>Vencimento: </b>
                  </h5>
                </Grid>
                <Grid item xs={1} sm={4} md={8} className="align-self-center">
                  <h5>
                    {result}
                  </h5>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={0} sm={0} md={1}></Grid>

            <Grid item xs={2} sm={8} md={5.5}>
              <Grid container spacing={2} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={8} md={12}>
                  <Grid container sx={{ padding: "0px !important" }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    <Grid item xs={1.5} sm={5} md={10}>
                      <Grid container columns={{ xs: 2, sm: 8, md: 12 }}>
                        <Grid item xs={1} sm={4} md={6} className="align-self-center">
                          <h5>
                            <b>Valor Final Corteva: </b>
                          </h5>
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} className="align-self-center text-center">
                          <h5>
                            {
                              somaCorteva.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: moeda === "BRL" ? "BRL" : "USD",
                              })
                            }
                          </h5>
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} className="align-self-center">
                          <h5>
                            <b>Valor Final Negociado: </b>
                          </h5>
                        </Grid>
                        <Grid item xs={1} sm={4} md={6} className="align-self-center text-center">
                          <h5>
                            {
                              soma.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: moeda === "BRL" ? "BRL" : "USD",
                              })
                            }
                          </h5>
                        </Grid>
                      </Grid>
                    </Grid>
                    {(role?.workflowViewInternalData || role?.isAdmin) &&
                      <>
                        <Grid item xs={0.5} sm={3} md={2} className="align-self-center">
                          <h5>
                            <ChangeHistoryIcon sx={{ verticalAlign: "top" }} />

                            {(desconto).toFixed(2).toLocaleString("pt-BR", {
                              style: "currency",
                            })}%
                          </h5>
                        </Grid>
                      </>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    {(role?.workflowViewEconomyData || role?.isAdmin) &&
                      <>
                        <Grid item xs={1} sm={3} md={3} lg={3.5} className="align-self-center">
                          <h5>
                            <b>GM Target:</b>
                          </h5>
                        </Grid>
                        <Grid item xs={0.75} sm={3} md={2} lg={2} className="align-self-left">
                          <h5>
                            {`${(Number(gmTarget)).toFixed(2).toLocaleString("pt-BR", {
                              style: "currency",
                            })}%`
                            }
                          </h5>
                        </Grid>
                        <Grid item xs={0.25} sm={2} md={1} lg={0.5} className="align-self-left">
                        </Grid>
                        <Grid item xs={1} sm={3} md={3} lg={3.5} className="align-self-center">
                          <h5>
                            <b>GM Cenário:</b>
                          </h5>
                        </Grid>
                        <Grid item xs={0.75} sm={3} md={2} lg={2} className="align-self-left">
                          <h5>
                            {
                              `${(Number(gmActual)).toFixed(2).toLocaleString("pt-BR", {
                                style: "currency",
                              })}%`
                            }
                          </h5>
                        </Grid>
                        <Grid item xs={0.25} sm={2} md={1} lg={0.5} className="align-self-left">
                        </Grid>
                        <Grid item xs={1} sm={3} md={3} lg={3.5} className="align-self-center">
                          <h5>
                            <b>Flex Target:</b>
                          </h5>
                        </Grid>
                        <Grid item xs={0.75} sm={3} md={2} lg={2} className="align-self-left">
                          <h5>
                            {invertSignal(flexTarget).toFixed(2).toLocaleString("pt-BR", {
                              style: "currency",
                            })}%
                          </h5>
                        </Grid>
                        <Grid item xs={0.25} sm={2} md={1} lg={0.5} className="align-self-left">
                        </Grid>
                        <Grid item xs={1} sm={3} md={3} lg={3.5} className="align-self-center">
                          <h5>
                            <b>Flex Cenário:</b>
                          </h5>
                        </Grid>
                        <Grid item xs={0.75} sm={3} md={2} lg={2} className="align-self-left">
                          <h5>
                            {invertSignal(flexActual).toFixed(2).toLocaleString("pt-BR", {
                              style: "currency",
                            })}%
                          </h5>
                        </Grid>
                        <Grid item xs={0.25} sm={2} md={1} lg={0.5} className="align-self-left">
                        </Grid>
                      </>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {this.props.getCenario.GetCenario.length !== 0 &&
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                  <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                    <p>Dados da Simulação</p>
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={2} sm={8} md={12} className="pt-0">
              {this.props.getCenario.GetCenario.length !== 0 &&
                <RenderForecastsTable props={this.props} currency={moeda} role={role} />
              }
            </Grid>
            {rowss?.cenarioWorkflow !== undefined && rowss?.cenarioWorkflow?.length > 0 &&
              <>
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                      <p>Histórico de Ações:</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={12} className="pt-0">
                  <Paper>
                    <Timeline
                      sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                          flex: 0.1,
                        },
                        paddingBottom: '12px'
                      }}
                    >
                      {rowss?.cenarioWorkflow?.map((x, index) => {
                        var date = new Date(x.dataEnvio);
                        var displayDate = isToday(date) ? date.toLocaleTimeString("pt-BR", { hour: '2-digit', minute: '2-digit' }) : isThisYear(date) ? date.toLocaleDateString("pt-BR", { day: "2-digit", month: "short" }) : date.toLocaleDateString("pt-BR", { dateStyle: "short" });
                        return (
                          <TimelineItem key={index}>
                            <TimelineOppositeContent color="textSecondary">
                              {displayDate}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color={x.worflowType === 0 ? undefined : x.worflowType === 1 ? "verde" : "vermelho"} />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ textAlign: "justify" }}><strong>{x.user.name} - {x.worflowType === 0 ? "Enviou o cenário para aprovação" : x.worflowType === 1 ? "Aprovou o cenário" : "Reprovou o cenário"}</strong>:<br /> {x.comentario}</TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </Paper>
                </Grid>
              </>
            }
          </Grid>
        </div>
      </div >
    );
  }
}


export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...productActions,
          ...GetCenarioActions,
          ...clientActions,
          ...AllCampaignsActions,
          ...nivelSemaforoActions,
          ...cenariosActions,
          ...userActions
        },
        dispatch,
      ),
    };
  }
)(ViewCenarioDetails);
