import React, { useState } from "react";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from "@mui/material/Tooltip";
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';

function Row(props) {
    const { row, selectAccount, indexSelect, desatribuir, goToPolygon } = props;
    const [open, setOpen] = React.useState(false);
    const [exclude, setExclude] = React.useState(false);

    const handleClickOpen = () => {
        setExclude(true);
    };

    const handleClose = () => {
        setExclude(false);
    };

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                <TableCell sx={{ padding: "0" }} align="center">
                    <IconButton sx={{ padding: "0" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ fontSize: "clamp(10px, 5px, 100px)", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 6px" }}>
                    {row.Nome + " - " + row.Ie}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "clamp(10px, 5px, 100px)", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 6px" }}>
                    {Number(Math.round(row.Area)).toLocaleString("pt-br") + "Ha"}
                </TableCell>
                <TableCell align="center" sx={{ padding: "0", paddingRight: "0" }}>
                    <Checkbox sx={{ padding: "9px 4px " }}
                        checked={row.Cod === indexSelect}
                        disabled={row.Cod !== indexSelect && indexSelect !== ''}
                        onChange={(event) => selectAccount(event, row)}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0.5 }} key={"moreinfo-" + row.id}>
                            {/*
                                <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Código: " + historyRow.codigo} />
                                */}
                            <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={
                                <a target="_blank" href={row.Link} rel="noreferrer">
                                    Salesforce Account
                                </a>
                            }
                            />
                            <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"IE: " + row.Ie} />
                            <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"UF: " + row.Uf} />
                            <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Município: " + row.Municipio} />
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                                        <TableRow>
                                            <TableCell
                                                align="left"
                                                sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", fontSize: "0.7rem", paddingRight: "8px" }}>
                                                Fazenda
                                            </TableCell>
                                            <TableCell align="center"
                                                sx={{ padding: 0, fontFamily: "Gilroy", fontWeight: "Bold", color: "white", fontSize: "0.7rem" }}>
                                                Área
                                            </TableCell>
                                            <TableCell
                                                sx={{ padding: 0, fontFamily: "Gilroy", fontWeight: "Bold", color: "white", fontSize: "0.7rem" }}>
                                            </TableCell>
                                            <TableCell
                                                sx={{ padding: 0, fontFamily: "Gilroy", fontWeight: "Bold", color: "white", fontSize: "0.7rem" }}>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.Fazendas.map((x, index) => {
                                            return (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, overflowWrap: "anywhere" }}
                                                >
                                                    <TableCell sx={{ fontSize: "clamp(10px, 10px, 50px)", maxWidth: "50px", padding: "6px 6px" }}>
                                                        {x.source}
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ fontSize: "clamp(10px, 10px, 50px)", padding: "6px 6px" }}>
                                                        {Number(Math.round(x.area)).toLocaleString("pt-br") + "Ha"}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: "0 !important" }}>
                                                        <Tooltip title="Centralizar no Polígono">
                                                            <IconButton
                                                                sx={{
                                                                    color: "gray",
                                                                }}
                                                                onClick={() => goToPolygon(x.source)}>
                                                                <ZoomInMapIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ padding: "0 6px" }}>
                                                        <Tooltip title="Desatribui o polígono.">
                                                            <IconButton sx={{ padding: "0" }}
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={handleClickOpen}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Dialog
                                                            open={exclude}
                                                            onClose={handleClose}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                Desatribuir propriedade
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">
                                                                    Deseja desatribuir a propriedade {x.source}?
                                                                    O processo pode levar alguns segundos...
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleClose}>Não</Button>
                                                                <Button onClick={() => desatribuir(row.Cod, x.source)} autoFocus>
                                                                    Sim
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

export default function CustomPaginationActionsTable(props) {
    const { data, selectAccount, indexSelect, desatribuir, goTo, goToPolygon } = props;

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{ borderRadius: "4px 4px 0px 0px" }}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                        <TableRow>
                            <TableCell align="center" sx={{ padding: "5px 16px" }}>
                            </TableCell>
                            <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "5px 6px" }}>
                                Nome
                            </TableCell>
                            <TableCell align="right" sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "5px 6px" }}>
                                Área Total
                            </TableCell>
                            <TableCell align="center" sx={{ padding: "5px 6px" }}>
                                <Tooltip title="Centralizar em fazendas da account selecionada.">
                                    <IconButton
                                        sx={{
                                            color: "white",
                                        }}
                                        onClick={() => indexSelect != "" ? goTo('account') : goTo('assigned')}>
                                        <ZoomInMapIcon sx={{ width: "15px", height: "15px" }} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer component={Paper} sx={{ minHeight: "200px", maxHeight: "200px", borderRadius: "0px 0px 4px 4px" }}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {data.length <= 0 && (
                            <TableCell>
                                Não há contas nesta visão ou as contas disponiveis estão com os dados incompletos.
                            </TableCell>
                        )}
                        {data.map((data, index) => (
                            <Row key={index}
                                row={data}
                                goToPolygon={goToPolygon}
                                selectAccount={selectAccount}
                                indexSelect={indexSelect}
                                desatribuir={desatribuir}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment >
    )
}
