const requestPeriodsType = "REQUEST_PERIODS";
const receivePeriodsType = "RECEIVE_PERIODS";

const requestPeriodType = "REQUEST_PERIOD";
const receivePeriodType = "RECEIVE_PERIOD";

const requestUpdatePeriodType = "REQUEST_UPDATE_PERIOD";
const receiveUpdatePeriodType = "RECEIVE_UPDATE_PERIOD";

const errorType = "STOCK_ERROR";
const closeErrorType = "CLOSE_STOCK_ERROR";

const cleanPeriodType = "CLEAN_PERIOD_DATA";

const initialState = { periods: [], period: null, isLoading: false, stockError: false, stockMessage: "" };

export const adminStockActions = {
  requestPeriods: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPeriodsType });

    const url = `api/estoque/periods`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const periods = await response.json();

    dispatch({ type: receivePeriodsType, periods });
  },
  requestPeriod: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPeriodType });

    const url = `api/estoque/period/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const period = await response.json();

    dispatch({ type: receivePeriodType, period });
  },
  updatePeriod: (id, form, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestUpdatePeriodType });

    const url = `api/estoque/updatePeriod/${id}`;

    const options = {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-Stock');
      dispatch({ type: receiveUpdatePeriodType, period: null });
      dispatch(adminStockActions.requestPeriods());
    }
  },
  activatePeriod: (id, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPeriodType });

    const url = `api/estoque/activatePeriod/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-Stock');
      dispatch({ type: receiveUpdatePeriodType, period: null });
      dispatch(adminStockActions.requestPeriods());
    }
    else {
      const errorMessage = await response.json();
      dispatch({ type: errorType, message: errorMessage });
      dispatch(adminStockActions.requestPeriods());
    }
  },
  initializePeriod: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPeriodType });

    const url = `api/estoque/initEstoque/${id}`;

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receivePeriodType });
      dispatch(adminStockActions.requestPeriods());
    }
    else {
      const errorMessage = await response.json();
      dispatch({ type: errorType, message: errorMessage });
      dispatch(adminStockActions.requestPeriods());
    }
  },
  pausePeriod: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestPeriodType });

    const url = `api/estoque/pauseEstoque/${id}`;

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receivePeriodType });
      dispatch(adminStockActions.requestPeriods());
    }
    else {
      dispatch({ type: errorType, errorType: response.statusText });
    }
  },
  clean: () => async (dispatch, getState) => {
    dispatch({ type: cleanPeriodType });
  },
  closeError: () => async (dispatch, getState) => {
    dispatch({ type: closeErrorType });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestPeriodsType:
      return {
        ...state,
        isLoading: true,
      };
    case receivePeriodsType:
      return {
        ...state,
        isLoading: false,
        periods: action.periods
      };
    case requestPeriodType:
      return {
        ...state,
        isLoading: true,
      };
    case receivePeriodType:
      return {
        ...state,
        isLoading: false,
        period: action.period
      }
    case cleanPeriodType:
      return {
        ...state,
        periods: []
      };
    case errorType:
      return {
        ...state,
        isLoading: false,
        stockError: true,
        stockMessage: action.message
      };
    case closeErrorType:
      return {
        ...state,
        stockError: false,
        stockMessage: ""
      };
    default:
      return state;
  }
};
