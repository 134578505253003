import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CortevaTextLogo = (props) => {
    return (
        <SvgIcon {...props} width="3326" height="740" viewBox="0 0 3326 740">
            <path d="M131.88 404.737C159.139 415.101 188.894 420.282 221.144 420.282C241.492 420.282 261.648 417.979 281.613 413.373C301.577 408.384 319.814 401.667 336.323 393.223C352.832 384.779 366.077 375.183 376.059 364.436L316.742 271.168C310.599 278.077 303.305 284.41 294.858 290.167C286.412 295.541 277.005 299.955 266.639 303.409C256.273 306.864 245.331 308.591 233.813 308.591C217.688 308.591 203.099 306.288 190.045 301.682C176.992 297.076 165.858 290.551 156.643 282.107C147.429 273.663 140.326 263.492 135.335 251.594C130.344 239.695 127.849 226.453 127.849 211.868C127.849 195.748 130.344 181.547 135.335 169.264C140.326 156.982 147.045 146.619 155.492 138.175C164.322 129.731 174.496 123.398 186.014 119.176C197.916 114.57 210.394 112.267 223.447 112.267C236.117 112.267 247.251 113.994 256.849 117.449C266.831 120.519 275.47 124.741 282.764 130.115C290.443 135.488 296.97 141.437 302.345 147.962L376.635 61.0273C363.198 47.2098 348.224 35.8871 331.716 27.0593C315.59 17.8476 298.314 11.1308 279.885 6.90876C261.84 2.30292 243.412 0 224.599 0C191.581 0 161.251 5.18156 133.608 15.5447C106.349 25.9078 82.7369 40.4929 62.7725 59.3001C42.8082 77.7234 27.259 99.793 16.1251 125.509C5.37502 151.225 0 179.436 0 210.141C0 240.847 5.37502 269.057 16.1251 294.773C26.8751 320.489 42.0403 342.751 61.6208 361.558C81.5851 379.981 105.005 394.374 131.88 404.737Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M657.101 418.556C618.708 418.556 583.386 409.536 551.136 391.496C519.27 373.457 493.93 348.509 475.118 316.652C456.305 284.795 446.899 248.907 446.899 208.99C446.899 169.073 456.305 133.186 475.118 101.329C493.93 69.472 519.27 44.7156 551.136 27.0599C583.386 9.02042 618.708 0.000665205 657.101 0.000665205C695.494 0.000665205 730.623 9.02042 762.489 27.0599C794.356 44.7156 819.503 69.472 837.932 101.329C856.744 133.186 866.15 169.073 866.15 208.99C866.15 248.907 856.744 284.795 837.932 316.652C819.503 348.509 794.164 373.457 761.913 391.496C730.047 409.536 695.11 418.556 657.101 418.556ZM657.101 299.38C682.44 299.38 702.02 291.32 715.842 275.199C729.663 258.695 736.574 236.625 736.574 208.99C736.574 180.971 729.663 158.902 715.842 142.781C702.02 126.277 682.44 118.025 657.101 118.025C631.377 118.025 611.605 126.277 597.783 142.781C583.962 158.902 577.051 180.971 577.051 208.99C577.051 236.625 583.962 258.695 597.783 275.199C611.605 291.32 631.377 299.38 657.101 299.38Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1180.64 414.526L1104.04 268.29H1103.47V414.526H975.62V6.90943H1165.67C1198.68 6.90943 1226.9 12.8586 1250.32 24.757C1273.74 36.2716 1291.21 52.2001 1302.73 72.5425C1314.63 92.5011 1320.58 115.146 1320.58 140.479C1320.58 167.73 1312.9 191.91 1297.55 213.02C1282.57 234.13 1260.88 249.291 1232.47 258.503L1321.16 414.526H1180.64ZM1103.47 183.082H1154.15C1166.43 183.082 1175.65 180.396 1181.79 175.022C1187.93 169.265 1191 160.437 1191 148.539C1191 137.792 1187.74 129.348 1181.21 123.207C1175.07 117.066 1166.05 113.995 1154.15 113.995H1103.47V183.082Z" />
            <path d="M1744.97 6.90943V108.238H1636.7V414.526H1508.85V108.238H1401.74V6.90943H1744.97Z" />
            <path d="M1970.84 108.813V158.902H2097.54V255.049H1970.84V312.621H2114.82V414.526H1842.99V6.90943H2114.82V108.813H1970.84Z" />
            <path d="M2636.96 6.90943L2499.9 414.526H2334.61L2197.55 6.90943H2333.46L2417.54 287.289L2501.05 6.90943H2636.96Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3010.54 414.526L2990.38 352.923H2854.47L2834.32 414.526H2700.13L2849.29 6.90943H2996.72L3145.3 414.526H3010.54ZM2959.29 256.776L2922.43 143.357L2885.57 256.776H2959.29Z" />
            <path d="M3172.52 88.5737V31.4437H3148.59V11.0717H3221.02V31.4437H3196.44V88.5737H3172.52Z" />
            <path d="M3245.37 88.5737V11.0717H3265.97L3285.88 43.4554L3306.07 11.0717H3326V88.5737H3302.96V70.0839C3302.96 63.4409 3303.11 57.4622 3303.41 52.1478C3303.46 51.3952 3303.52 50.6435 3303.58 49.8924L3289.12 72.2983H3281.81L3267.77 50.1399C3267.8 50.6234 3267.83 51.1082 3267.86 51.5942C3268.23 56.761 3268.41 62.9242 3268.41 70.0839V88.5737H3245.37Z" />
            <path d="M526.761 485.627C530.349 489.215 534.735 491.008 539.918 491.008C544.901 491.008 549.088 489.215 552.477 485.627C556.065 482.04 557.859 477.656 557.859 472.474C557.859 467.293 556.065 462.908 552.477 459.321C549.088 455.734 544.901 453.94 539.918 453.94C534.735 453.94 530.349 455.734 526.761 459.321C523.172 462.908 521.378 467.293 521.378 472.474C521.378 477.656 523.172 482.04 526.761 485.627Z" />
            <path d="M553.075 681.431V517.614H525.864V681.431H553.075Z" />
            <path d="M441.525 544.219C446.31 534.852 453.087 527.578 461.859 522.397C470.829 517.215 481.694 514.624 494.452 514.624V542.724H487.276C456.775 542.724 441.525 559.265 441.525 592.348V681.431H414.314V517.614H441.525V544.219Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1668 554.981C3.38892 567.536 0 582.184 0 598.924C0 615.465 3.38892 630.213 10.1668 643.167C16.9446 656.121 26.2143 666.185 37.9758 673.359C49.7374 680.534 62.8944 684.121 77.4468 684.121C91.6005 684.121 103.96 681.032 114.526 674.854C125.29 668.477 133.364 660.505 138.746 650.939V681.431H166.256V517.614H138.746V547.507C133.563 538.141 125.689 530.368 115.124 524.19C104.558 518.012 92.0989 514.923 77.7458 514.923C63.1934 514.923 49.9367 518.411 37.9758 525.386C26.2143 532.361 16.9446 542.226 10.1668 554.981ZM131.271 566.938C136.255 576.105 138.746 586.867 138.746 599.223C138.746 611.579 136.255 622.44 131.271 631.807C126.287 640.975 119.509 648.049 110.937 653.032C102.565 657.815 93.295 660.206 83.1282 660.206C72.9615 660.206 63.6918 657.815 55.3191 653.032C46.9465 648.049 40.2683 640.975 35.2846 631.807C30.3009 622.44 27.8091 611.479 27.8091 598.924C27.8091 586.568 30.3009 575.806 35.2846 566.639C40.2683 557.472 46.9465 550.497 55.3191 545.714C63.6918 540.931 72.9615 538.539 83.1282 538.539C93.295 538.539 102.565 541.03 110.937 546.013C119.509 550.795 126.287 557.771 131.271 566.938Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M316.898 524.19C306.333 518.012 293.973 514.923 279.82 514.923C265.267 514.923 252.01 518.411 240.05 525.386C228.288 532.361 219.018 542.226 212.24 554.981C205.463 567.536 202.074 582.184 202.074 598.924C202.074 615.465 205.463 630.213 212.24 643.167C219.018 656.121 228.288 666.185 240.05 673.359C252.01 680.534 265.267 684.121 279.82 684.121C293.973 684.121 306.333 680.932 316.898 674.555C327.464 668.178 335.438 660.206 340.82 650.64V662.874C340.82 679.814 336.036 692.967 326.467 702.334C317.098 711.701 304.937 716.384 289.986 716.384C276.829 716.384 265.566 713.295 256.197 707.117C246.827 701.138 240.648 693.167 237.657 683.202H210.745C213.337 700.74 221.809 714.59 236.162 724.754C250.515 734.918 268.457 740 289.986 740C305.735 740 319.49 736.712 331.251 730.135C343.212 723.558 352.382 714.391 358.761 702.633C365.141 691.074 368.33 677.821 368.33 662.874V517.614H340.82V547.507C335.637 538.141 327.663 530.368 316.898 524.19ZM333.345 566.938C338.328 576.105 340.82 586.867 340.82 599.223C340.82 611.579 338.328 622.44 333.345 631.807C328.361 640.975 321.583 648.049 313.011 653.032C304.638 657.815 295.369 660.206 285.202 660.206C275.035 660.206 265.766 657.815 257.393 653.032C249.02 648.049 242.342 640.975 237.358 631.807C232.375 622.44 229.883 611.479 229.883 598.924C229.883 586.568 232.375 575.806 237.358 566.639C242.342 557.472 249.02 550.497 257.393 545.714C265.766 540.931 275.035 538.539 285.202 538.539C295.369 538.539 304.638 541.03 313.011 546.013C321.583 550.795 328.361 557.771 333.345 566.938Z" />
            <path d="M657.461 684.121C644.903 684.121 633.639 682.028 623.672 677.843C613.704 673.459 605.83 667.48 600.049 659.907C594.268 652.135 591.078 643.266 590.48 633.302H618.589C619.386 641.473 623.174 648.149 629.951 653.331C636.929 658.512 645.999 661.103 657.162 661.103C667.529 661.103 675.702 658.811 681.682 654.227C687.663 649.644 690.653 643.864 690.653 636.889C690.653 629.715 687.463 624.433 681.084 621.045C674.705 617.458 664.837 613.971 651.481 610.583C639.321 607.394 629.353 604.205 621.579 601.017C614.003 597.629 607.425 592.746 601.843 586.369C596.461 579.792 593.77 571.223 593.77 560.66C593.77 552.29 596.262 544.617 601.245 537.642C606.229 530.667 613.306 525.187 622.476 521.201C631.646 517.016 642.112 514.923 653.873 514.923C672.014 514.923 686.666 519.507 697.829 528.674C708.993 537.842 714.973 550.397 715.771 566.34H688.56C687.962 557.771 684.473 550.895 678.094 545.714C671.914 540.532 663.542 537.941 652.976 537.941C643.208 537.941 635.433 540.034 629.652 544.219C623.871 548.404 620.981 553.884 620.981 560.66C620.981 566.041 622.675 570.525 626.064 574.112C629.652 577.5 634.038 580.291 639.221 582.483C644.603 584.476 651.979 586.767 661.349 589.358C673.11 592.547 682.679 595.736 690.055 598.924C697.431 601.914 703.71 606.497 708.893 612.675C714.276 618.853 717.067 626.925 717.266 636.889C717.266 645.857 714.774 653.928 709.79 661.103C704.807 668.277 697.73 673.957 688.56 678.142C679.589 682.128 669.223 684.121 657.461 684.121Z" />
            <path d="M755.679 554.981C748.901 567.536 745.512 582.283 745.512 599.223C745.512 616.362 748.901 631.309 755.679 644.064C762.456 656.818 771.826 666.683 783.787 673.658C795.947 680.633 809.802 684.121 825.351 684.121C845.086 684.121 861.532 679.139 874.69 669.174C887.846 659.01 896.618 645.558 901.003 628.818H871.699C868.709 639.181 863.227 647.153 855.253 652.733C847.279 658.313 837.312 661.103 825.351 661.103C809.802 661.103 797.243 655.722 787.674 644.96C778.105 634.199 773.321 618.953 773.321 599.223C773.321 579.693 778.105 564.646 787.674 554.084C797.243 543.322 809.802 537.941 825.351 537.941C837.312 537.941 847.279 540.831 855.253 546.61C863.426 552.39 868.908 560.262 871.699 570.226H901.003C896.817 552.888 888.146 539.336 874.989 529.571C862.031 519.806 845.485 514.923 825.351 514.923C809.802 514.923 795.947 518.411 783.787 525.386C771.826 532.361 762.456 542.226 755.679 554.981Z" />
            <path d="M951.365 491.008C946.182 491.008 941.797 489.215 938.208 485.627C934.62 482.04 932.826 477.656 932.826 472.474C932.826 467.293 934.62 462.908 938.208 459.321C941.797 455.734 946.182 453.94 951.365 453.94C956.349 453.94 960.535 455.734 963.924 459.321C967.513 462.908 969.307 467.293 969.307 472.474C969.307 477.656 967.513 482.04 963.924 485.627C960.535 489.215 956.349 491.008 951.365 491.008Z" />
            <path d="M964.522 517.614V681.431H937.311V517.614H964.522Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1159.51 609.686C1160.11 603.906 1160.41 598.426 1160.41 593.244C1160.41 578.298 1157.12 564.945 1150.54 553.187C1144.16 541.229 1134.99 531.863 1123.03 525.087C1111.07 518.311 1097.32 514.923 1081.77 514.923C1065.82 514.923 1051.67 518.411 1039.31 525.386C1027.15 532.162 1017.68 541.927 1010.9 554.682C1004.12 567.436 1000.73 582.283 1000.73 599.223C1000.73 616.163 1004.22 631.11 1011.2 644.064C1018.18 656.818 1027.74 666.683 1039.9 673.658C1052.26 680.633 1066.22 684.121 1081.77 684.121C1101.3 684.121 1117.65 679.238 1130.81 669.473C1143.96 659.509 1152.74 646.654 1157.12 630.91H1127.82C1124.63 640.078 1119.05 647.451 1111.07 653.032C1103.3 658.413 1093.53 661.103 1081.77 661.103C1067.41 661.103 1055.15 656.619 1044.99 647.651C1035.02 638.483 1029.54 625.828 1028.54 609.686H1159.51ZM1125.42 560.959C1130.01 568.333 1132.3 577.201 1132.3 587.565H1028.84C1030.24 572.02 1035.72 559.863 1045.29 551.094C1055.05 542.326 1066.82 537.941 1080.57 537.941C1090.14 537.941 1098.81 539.934 1106.59 543.92C1114.56 547.706 1120.84 553.386 1125.42 560.959Z" />
            <path d="M1276.17 514.624C1296.1 514.624 1312.25 520.703 1324.61 532.859C1336.97 544.817 1343.15 562.155 1343.15 584.874V681.431H1316.24V588.76C1316.24 572.419 1312.15 559.963 1303.98 551.393C1295.8 542.625 1284.64 538.24 1270.49 538.24C1256.13 538.24 1244.67 542.724 1236.1 551.692C1227.73 560.66 1223.54 573.714 1223.54 590.853V681.431H1196.33V517.614H1223.54V540.931C1228.92 532.56 1236.2 526.083 1245.37 521.5C1254.74 516.916 1265 514.624 1276.17 514.624Z" />
            <path d="M1387.6 554.981C1380.82 567.536 1377.43 582.283 1377.43 599.223C1377.43 616.362 1380.82 631.309 1387.6 644.064C1394.37 656.818 1403.74 666.683 1415.71 673.658C1427.87 680.633 1441.72 684.121 1457.27 684.121C1477 684.121 1493.45 679.139 1506.61 669.174C1519.76 659.01 1528.54 645.558 1532.92 628.818H1503.62C1500.63 639.181 1495.15 647.153 1487.17 652.733C1479.2 658.313 1469.23 661.103 1457.27 661.103C1441.72 661.103 1429.16 655.722 1419.59 644.96C1410.02 634.199 1405.24 618.953 1405.24 599.223C1405.24 579.693 1410.02 564.646 1419.59 554.084C1429.16 543.322 1441.72 537.941 1457.27 537.941C1469.23 537.941 1479.2 540.831 1487.17 546.61C1495.34 552.39 1500.83 560.262 1503.62 570.226H1532.92C1528.74 552.888 1520.06 539.336 1506.91 529.571C1493.95 519.806 1477.4 514.923 1457.27 514.923C1441.72 514.923 1427.87 518.411 1415.71 525.386C1403.74 532.361 1394.37 542.226 1387.6 554.981Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1718.74 593.244C1718.74 598.426 1718.44 603.906 1717.84 609.686H1586.87C1587.87 625.828 1593.35 638.483 1603.32 647.651C1613.48 656.619 1625.74 661.103 1640.1 661.103C1651.86 661.103 1661.63 658.413 1669.4 653.032C1677.38 647.451 1682.96 640.078 1686.15 630.91H1715.45C1711.07 646.654 1702.29 659.509 1689.14 669.473C1675.98 679.238 1659.63 684.121 1640.1 684.121C1624.55 684.121 1610.59 680.633 1598.23 673.658C1586.07 666.683 1576.51 656.818 1569.53 644.064C1562.55 631.11 1559.06 616.163 1559.06 599.223C1559.06 582.283 1562.45 567.436 1569.23 554.682C1576.01 541.927 1585.48 532.162 1597.64 525.386C1610 518.411 1624.15 514.923 1640.1 514.923C1655.65 514.923 1669.4 518.311 1681.36 525.087C1693.32 531.863 1702.49 541.229 1708.87 553.187C1715.45 564.945 1718.74 578.298 1718.74 593.244ZM1690.63 587.565C1690.63 577.201 1688.34 568.333 1683.76 560.959C1679.17 553.386 1672.89 547.706 1664.92 543.92C1657.14 539.934 1648.47 537.941 1638.9 537.941C1625.15 537.941 1613.39 542.326 1603.62 551.094C1594.05 559.863 1588.57 572.02 1587.17 587.565H1690.63Z" />
        </SvgIcon>
    );
};

export default CortevaTextLogo;
