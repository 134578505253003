const requestEncargosRatingsType = "REQUEST_EncargosRatingS";
const receiveEncargosRatingsType = "RECEIVE_EncargosRatingS";
const clearEncargosRatingsType = "CLEAR_EncargosRatingS";
const initialState = { EncargosRatings: [], isLoading: false };

export const EncargosRatingsActions = {
  requestEncargosRatings: (paymentTerms, vencimento, campanha) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargosRatingsType });

    const url = `/api/simuladorencargos/${encodeURIComponent(paymentTerms.paymentTerms)}/${encodeURIComponent(vencimento)}/${encodeURIComponent(campanha)}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const EncargosRatings = await response.json();
    dispatch({ type: receiveEncargosRatingsType, EncargosRatings });
  },
  requestReloadEncargosRatings: (paymentTerms, vencimento, campanha) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargosRatingsType });
    const url = `/api/simuladorencargos/${encodeURIComponent(paymentTerms)}/${encodeURIComponent(vencimento)}/${encodeURIComponent(campanha)}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const EncargosRatings = await response.json();
    dispatch({ type: receiveEncargosRatingsType, EncargosRatings });
  },
  requestEncargosRatings2: (paymentTerms, vencimento, campanha) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargosRatingsType });

    const url = `/api/simulador2/simuladorencargos/rate?paymentTerms=${paymentTerms}&vencimento=${vencimento}&campanha=${campanha}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.status === 200) {
      const EncargosRatings = await response.json();
      dispatch({ type: receiveEncargosRatingsType, EncargosRatings });
    } else {
      dispatch({ type: receiveEncargosRatingsType, EncargosRatings: [] });
    }

  },
  clearEncargosRatings: () => async (dispatch,) => {
    dispatch({ type: clearEncargosRatingsType });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestEncargosRatingsType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveEncargosRatingsType) {
    return {
      ...state,
      EncargosRatings: action.EncargosRatings,
      isLoading: false,
    };
  }
  if (action.type === clearEncargosRatingsType) {
    return {
      ...state,
      EncargosRatings: [],
      isLoading: false,
    };
  }

  return state;
};
