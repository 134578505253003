const requestTerritoriosType = "REQUEST_TERRITORIOS";
const receiveTerritoriosType = "RECEIVE_TERRITORIOS";
const requestAreasType = "REQUEST_AREAS";
const receiveAreasType = "RECEIVE_AREAS";
const requestDistritosType = "REQUEST_DISTRITOS";
const receiveDistritosType = "RECEIVE_DISTRITOS";
const requestBusinessAreaType = "REQUEST_BUSINESSAREA";
const receiveBusinessAreaType = "RECEIVE_BUSINESSAREA";

const initialState = { territorios: [], isLoading: false, areas: [], isLoadingAreas: false, distritos: [], isLoadingDistritos: false, businessAreas: [], isLoadingbusinessArea: false };

export const adminTerritoryActions = {
  requestTerritorios: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestTerritoriosType });

    const url = `/api/admin/territory/territorios`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const territorios = await response.json();
    dispatch({ type: receiveTerritoriosType, territorios });
  },
  requestAreas: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAreasType });

    const url = `/api/admin/territory/areas`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const areas = await response.json();
    dispatch({ type: receiveAreasType, areas });
  },
  requestDistritos: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestDistritosType });

    const url = `/api/admin/territory/distritos`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const distritos = await response.json();
    dispatch({ type: receiveDistritosType, distritos });
  },
  requestBusinessAreas: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestBusinessAreaType });

    const url = `/api/admin/territory/businessAreas`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const businessAreas = await response.json();
    dispatch({ type: receiveBusinessAreaType, businessAreas });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestTerritoriosType:
      return {
        ...state,
        isLoading: true,
      };
    case receiveTerritoriosType:
      return {
        ...state,
        territorios: action.territorios,
        isLoading: false,
      };
    case requestAreasType:
      return {
        ...state,
        isLoadingAreas: true,
      };
    case receiveAreasType:
      return {
        ...state,
        areas: action.areas,
        isLoadingAreas: false,
      };
    case requestDistritosType:
      return {
        ...state,
        isLoadingDistritos: true,
      };
    case receiveDistritosType:
      return {
        ...state,
        distritos: action.distritos,
        isLoadingDistritos: false,
      };
    case requestBusinessAreaType:
      return {
        ...state,
        isLoadingbusinessArea: true,
      };
    case receiveBusinessAreaType:
      return {
        ...state,
        businessAreas: action.businessAreas,
        isLoadingbusinessArea: false,
      };
    default:
      return state;
  }
};