import React, { Component } from "react";
import { connect } from "react-redux";

import userManager from "../../../util/userManager";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CortevaLogo from "../../../assets/svg/CortevaLogo/CortevaLogo";
import CortevaTextLogo from "../../../assets/svg/CortevaLogo/CortevaTextLogo";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://1500fh.com/">
        1500fh
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class LoginPage extends Component {
  onLoginButtonClick(event) {
    event.preventDefault();
    userManager.signinRedirect();
  }

  render() {
    const theme = createTheme();
    return (
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://assets.corteva.com/is/image/Corteva/IMG-climate_hero_2732x1820-Global:Wide)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ m: 15 }}>
                {/* <img src={CortevaImage} height="30" alt="Customer 360" /> */}
                <CortevaLogo sx={{ fontSize: "2.5rem", color: "var(--cort-blue)" }} />
                <CortevaTextLogo sx={{ fontSize: "2rem", marginLeft: "0.5rem", width: "auto", color: "black" }} />
              </Box>

              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "var(--cort-blue)", fontWeight: "bold" }}
              >
                Customer 360
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={this.onLoginButtonClick}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Entrar
                </Button>
                <Grid container>
                  <Grid item sx={{ maxWidth: "250px" }}>
                    {/* <Alert severity="info" size="small" >{"Manutenção Programada! No dia 09/03/23 entre 16h até as 19h a plataforma receberá uma manutenção programada e poderá ocorrer indisponibilidades durante o período informado."}</Alert> */}
                  </Grid>
                  {/* <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid> */}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(LoginPage);
