import { Box, Card, Grid, IconButton, Rating, Stack, Typography } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import useFitText from "use-fit-text";
import SoyBoxIcon from "./Icons/SoyBoxIcon";
import LandscapeIcon from "./Icons/LandscapeIcon";
import SoyGroupIcon from "./Icons/SoyGroupIcon";
import CornIcon from "./Icons/CornIcon";
import CornGroupIcon from "./Icons/CornGroupIcon";
import CottonIcon from "./Icons/CottonIcon";

function AjustFontSize(props) {
  const { fontSize, ref } = useFitText();
  return <Box sx={{ ...props.sx, ".ajust-text": { fontSize: fontSize } }} ref={ref}>{props.children}
  </Box>
}

export default function ConhecendoCliente(props) {
  const { client: propsClient } = props;
  const client = propsClient ?? {};
  const [expand, setExpand] = useState(true);
  return <>
    <Grid item xs={2} sm={8} md={12} >
      <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#44546a", color: "#fff", fontWeight: "bold" }}>
        <Grid item xs={12} sx={{ paddingBottom: "0.5rem !important", paddingTop: "0.5rem !important", display: "flex", alignItems: "center", gap: "8px" }}>
          <p style={{ marginBottom: "0" }}>Conhecendo o Cliente</p>
          <IconButton onClick={() => setExpand(!expand)} sx={{ color: "#FFF" }} size="small">
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
    {expand &&
      <Grid item xs={2} sm={8} md={12}>
        <Card>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} bgcolor={"#d9d9d9"} sx={{ display: "flex" }} >
              <Grid container spacing={1} padding={"8px"}>
                <Grid item xs={12} sm={8} md={8} sx={{ display: "flex" }}>
                  <div style={{ padding: "8px", backgroundColor: "#a6a6a6", flex: 1, display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: "14px" }}>Área Plantada</Typography>
                    <Stack direction={"row"} justifyContent={"space-around"} sx={{ "*": { textAlign: "center" } }}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                        <SoyGroupIcon style={{ height: "50px", width: "auto" }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {Number(client?.areaPlantadaSoja ?? 0).toLocaleString("pt-BR")} Ha
                        </Typography>
                        <Typography variant="subtitle2">Soja</Typography>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <CornIcon style={{ height: "50px", width: "auto" }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {Number(client?.areaPlantadaMilhoSafrinha ?? 0).toLocaleString("pt-BR")} Ha
                        </Typography>
                        <Typography variant="subtitle2">Milho Safrinha</Typography>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <CornGroupIcon style={{ height: "50px", width: "auto" }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {Number(client?.areaPlantadaMilhoVerao ?? 0).toLocaleString("pt-BR")} Ha
                        </Typography>
                        <Typography variant="subtitle2">Milho Verão</Typography>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <CottonIcon style={{ height: "50px", width: "auto" }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {Number(client?.areaPlantadaAlgodao ?? 0).toLocaleString("pt-BR")} Ha
                        </Typography>
                        <Typography variant="subtitle2">Algodão</Typography>
                      </div>
                    </Stack>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ display: "flex" }} >
                  <div style={{ padding: "8px", backgroundColor: "#bfbfbf", display: "flex", flex: 1, justifyContent: "space-between" }}>
                    <Grid container>
                      <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Typography variant="subtitle1" sx={{ alignSelf: "flex-start", fontWeight: "bold" }}>Potencial</Typography>
                        <AjustFontSize sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography className="ajust-text" variant="subtitle1" sx={{ textAlign: "center", fontWeight: "bold" }}>
                            {
                              Number(parseFloat(client?.potencialCpUsd ?? 0).toFixed(2)).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "USD",
                              })
                            }
                          </Typography>
                          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>Potencial CP</Typography>
                        </AjustFontSize>
                      </Grid>
                      <Grid item xs={6} sx={{ display: "flex", alignItems: "stretch", justifyContent: "center" }}>
                        <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"} sx={{ "*": { textAlign: "center" } }}>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                              {Number(client?.potencialMilho ?? 0).toLocaleString("pt-BR")}
                            </Typography>
                            <Typography variant="subtitle2">Milho (sacos)</Typography>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                              {Number(client?.potencialSoja ?? 0).toLocaleString("pt-BR")}
                            </Typography>
                            <Typography variant="subtitle2">Soja (sacos)</Typography>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex" }}>
                  <div style={{ padding: "8px", backgroundColor: "#9bb1db", flex: 1, display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: "14px" }}>Vendas por Safra</Typography>
                    <Grid container columns={{ xs: 2, sm: 4, md: 7 }} spacing={1}>
                      <Grid item xs={1} sm={1} md={0.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <LandscapeIcon sx={{ height: "auto", width: "min(100%, 39px)" }} />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <AjustFontSize sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography className="ajust-text" variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            {
                              Number(parseFloat(client?.vendasCpDolSafraAntAnt ?? 0).toFixed(2)).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "USD",
                              })
                            }
                          </Typography>
                          <Typography variant="subtitle2">Safra</Typography>
                          <Typography variant="subtitle2">21/22</Typography>
                        </AjustFontSize>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <AjustFontSize sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography className="ajust-text" variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            {
                              Number(parseFloat(client?.vendasCpDolSafraAnt ?? 0).toFixed(2)).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "USD",
                              })
                            }
                          </Typography>
                          <Typography variant="subtitle2">Safra</Typography>
                          <Typography variant="subtitle2">22/23</Typography>
                        </AjustFontSize>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <AjustFontSize sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography className="ajust-text" variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            {
                              Number(parseFloat(client?.vendasCpDolSafraAtual ?? 0).toFixed(2)).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "USD",
                              })
                            }
                          </Typography>
                          <Typography variant="subtitle2">Safra</Typography>
                          <Typography variant="subtitle2">23/24</Typography>
                        </AjustFontSize>
                      </Grid>
                      <Grid item xs={1} sm={1} md={0.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <SoyBoxIcon sx={{ height: "auto", width: "min(100%, 40px)" }} />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <AjustFontSize sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography className="ajust-text" variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            {Number(client?.vendasSeedsSacasSafraAntAnt ?? 0).toLocaleString("pt-BR")}
                          </Typography>
                          <Typography variant="subtitle2">Safra</Typography>
                          <Typography variant="subtitle2">21/22</Typography>
                        </AjustFontSize>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <AjustFontSize sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography className="ajust-text" variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            {Number(client?.vendasSeedsSacasSafraAnt ?? 0).toLocaleString("pt-BR")}
                          </Typography>
                          <Typography variant="subtitle2">Safra</Typography>
                          <Typography variant="subtitle2">22/23</Typography>
                        </AjustFontSize>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        <AjustFontSize sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography className="ajust-text" variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            {Number(client?.vendasSeedsSacasSafraAtual ?? 0).toLocaleString("pt-BR")}
                          </Typography>
                          <Typography variant="subtitle2">Safra</Typography>
                          <Typography variant="subtitle2">23/24</Typography>
                        </AjustFontSize>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} bgcolor={"#d9d9d9"} sx={{ display: "flex" }} >
              <Grid container spacing={1} padding={"8px"} sx={{ paddingLeft: { md: "0" }, paddingTop: { xs: "0", sm: "0 ", md: "8px" } }}>
                <Grid item xs={12} sm={10} md={9} sx={{ display: "flex" }}>
                  <Stack direction={"row"} justifyContent={"space-around"} sx={{ padding: "8px", backgroundColor: "#6e8dca", flex: 1, "*": { textAlign: "center" } }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {Number(parseFloat(client?.socCpSafraAtual ?? 0).toFixed(2)).toLocaleString("pt-br")}%
                      </Typography>
                      <Typography variant="subtitle2">SOC CP</Typography>
                      <Typography variant="subtitle2">23/24</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {Number(parseFloat(client?.socSeedsSafraAtual ?? 0).toFixed(2)).toLocaleString("pt-br")}%
                      </Typography>
                      <Typography variant="subtitle2">SOC Seeds</Typography>
                      <Typography variant="subtitle2">23/24</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{Number(client?.anosCliente ?? 0).toFixed(2)}</Typography>
                      <Typography variant="subtitle2">Anos Cliente Corteva</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{client?.reclamacoes ?? 0}</Typography>
                      <Typography variant="subtitle2">Reclamações</Typography>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={2} md={3} sx={{ display: "flex" }} >
                  <div style={{ padding: "8px", backgroundColor: "#fdd3bf", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", flex: 1 }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {
                          Number(parseFloat(client?.limiteAprovado ?? 0).toFixed(2)).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "USD",
                          })
                        }
                      </Typography>
                      <Typography variant="subtitle2">Total Limite Aprovado</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {
                          Number(parseFloat(client?.saldoDispCredito ?? 0).toFixed(2)).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "USD",
                          })
                        }
                      </Typography>
                      <Typography variant="subtitle2">Saldo Disponível</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Typography variant="subtitle2"
                        sx={{ textDecoration: "underline !important", mt: 1 }}
                        component={"a"}
                        target="_blank"
                        href="https://app.powerbi.com.mcas.ms/groups/997fdd88-ba95-4f3a-9864-ed1c332d6c14/reports/449cdb1b-3910-43ee-aece-3886b1dfa4f5/ReportSectione3a6a0e80ce722eab140?experience=power-bi">
                        Mais detalhes
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ display: "flex" }}>
                  <div style={{ padding: "8px", backgroundColor: "#a6a6a6", flex: 1, display: "flex", flexDirection: 'column', justifyContent: "center" }}>
                    <Stack flexDirection={"row"} gap={2} justifyContent={"space-around"}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="subtitle2" sx={{ textAlign: "center", fontWeight: "bold" }}>{client?.segmentoAtual}</Typography>
                        <Typography variant="subtitle2" sx={{ textAlign: "center", fontWeight: "bold" }}>Segmento Atual</Typography>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", gap: "8px", justifyContent: "left", alignItems: "left" }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Subir Classificação?</Typography>
                        <Typography variant="subtitle2">SOC Seeds Milho:
                          <Typography component={"span"} variant="subtitle1" sx={{ fontWeight: "bold", marginLeft: "8px" }}>
                            {Number(parseFloat(client?.socSeedsSegmento ?? 0).toFixed(2)).toLocaleString("pt-br")}%
                          </Typography>
                        </Typography>
                        <Typography variant="subtitle2">SOC CP:
                          <Typography component={"span"} variant="subtitle1" sx={{ fontWeight: "bold", marginLeft: "8px" }}>
                            {Number(parseFloat(client?.socCpSegmento ?? 0).toFixed(2)).toLocaleString("pt-br")}%
                          </Typography>
                        </Typography>
                      </div>
                    </Stack>
                    <Stack flexDirection={"row"} gap={2} justifyContent={"space-around"}>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", }}>Classificação cliente:
                        <Typography component={"span"} variant="subtitle2" sx={{ marginLeft: "8px" }}>
                          {client?.segmentoCliente}
                        </Typography>
                      </Typography>
                    </Stack>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ display: "flex" }} >
                  <div style={{ padding: "8px", backgroundColor: "#7f7f7f", display: "flex", flex: 1, justifyContent: "space-between" }}>
                    <Grid container>
                      <Grid item xs={6} sx={{ display: "flex" }}>
                        <Stack direction={"column"} alignItems={"center"} justifyContent={"space-around"} sx={{ "*": { textAlign: "center" }, flex: 1 }}>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>Tendência Vendas CP</Typography>
                            <Typography variant="subtitle1" sx={{ color: "#FFF", fontWeight: "bold" }}>{client?.retentionTendenciaVendasCp}</Typography>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Orientação</Typography>
                            <Typography variant="subtitle2" textAlign={"left"} sx={{ whiteSpace: "pre-line" }}>{client?.retentionOrientacoes}</Typography>
                          </div>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} sx={{ display: "flex" }}>
                        <Stack direction={"column"} alignItems={"center"} justifyContent={"space-around"} sx={{ "*": { textAlign: "center" }, flex: 1 }}>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>Tendência Vendas Seeds</Typography>
                            <Typography variant="subtitle1" sx={{ color: "#FFF", fontWeight: "bold" }}>{client?.retentionTendenciaVendasSeeds}</Typography>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                              {Number(parseFloat(client?.retentionProbCompra ?? 0).toFixed(2)).toLocaleString("pt-br")}%
                            </Typography>
                            <Typography variant="subtitle2">Índice Retenção</Typography>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    }
  </>
}