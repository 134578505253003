import { useEffect, useState } from "react";
import { secondsToTime } from "../../../../helpers/utils";

export default function CountdownTime({ initialValue }) {
  const [remaining, setRemaining] = useState(initialValue);

  useEffect(() => {
    if (remaining > 0) {
      const timer = setTimeout(() => setRemaining(remaining - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [remaining]);

  return secondsToTime(remaining);
}