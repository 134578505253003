import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Dialog, DialogActions, DialogTitle, InputBase, Stack/*, Toolbar*/ } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import EditIcon from '@mui/icons-material/Edit';
import { adminSimuladorVeActions } from '../../../../../actions/admin/simualdorVe.actions';
import DeleteIcon from "@mui/icons-material/Delete";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy } =
    props;


  return (
    <TableHead
      sx={{
        backgroundColor: "#0072CE",
        "th": {
          color: "#FFF",
          fontWeight: "bold"
        },
      }}>
      <TableRow>
        <TableCell
          key={'fornecedor'}
          align={'left'}
          sortDirection={orderBy === 'fornecedor' ? order : false}
          size="medium"
          sx={{
            '*': {
              color: '#fff !important',
              '&:hover': {
                color: '#FFF !important',
              },
            },
          }}
        >
          Fornecedores
        </TableCell>
        <TableCell>
          Grupo Material
        </TableCell>
        {/* <TableCell>
          Área Comercial
        </TableCell>
        <TableCell>
          Distrito
        </TableCell>
        <TableCell >
          Território
        </TableCell> */}
        <TableCell align="center">
          Ações
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />

      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />

      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`; }

function EnhancedTableToolbar({ handleSearchText = (event) => { } }) {
  return (
    <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }}
      spacing={{ xs: 1, sm: 1.5, md: 2 }}
      justifyContent={{ xs: "center", sm: "center", md: "space-around" }}
      alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
      sx={{ mb: "16px" }}
    >
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquise por Fornecedor"
          onChange={handleSearchText}
          inputProps={{ "aria-label": "Pesquise por Fornecedor" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <Box sx={{ minWidth: { xs: "100%", sm: "100%", md: 'fit-content' } }}>
        <Link to={`/Admin-Precos-Create`} style={{ width: "100%" }}>
          <Button variant="contained" color='azul' size="large" sx={{ width: "100%" }}>Adicionar Preços</Button>
        </Link>
      </Box>
    </Stack>
  );
}

function CustomPaginationActionsTable({ rows, oidc, handleSearchText, handleDeleteDisponibilidade, ...props }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('probBuy2MoreProducts');
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClickOpen = (fornecedorId) => {
    setOpen(true);
    setDeleteId(fornecedorId);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleDeleteRow = () => {
    handleDeleteDisponibilidade(deleteId);
    handleClose();
  };



  return (
    <>
      <EnhancedTableToolbar handleSearchText={handleSearchText} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : stableSort(rows, getComparator(order, orderBy))
            ).map((row) => {
              return (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                    {row.fornecedor.nome}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                    {row.grupoMaterial.nome}
                  </TableCell>
                  {/* <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                    {row.precosSimuladorVendaExterna.map((item) => item.areaComercial || "").join("  ")}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                    {row.precosSimuladorVendaExterna.map((item) => item.distrito || "").join(" ")}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                    {row.precosSimuladorVendaExterna.map((item) => item.territorio || "").join(" ")}
                  </TableCell> */}
                  <TableCell align="right">
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 1.5, md: 2 }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Tooltip title="Editar Precos">
                        <Link to={`/Admin-Precos-Edit?DisponibilidadeId=${row.id}`}>
                          <IconButton>
                            <EditIcon sx={{ color: "#000" }} />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Excluir">
                        <IconButton onClick={() => handleClickOpen(row.id)}>
                          <DeleteIcon sx={{ color: "#000" }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage="Linhas por página:"
          labelDisplayedRows={defaultLabelDisplayedRows}
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'Linhas por página',
            },
            native: false,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Tem certeza que deseja excluir este Fornecedor?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleDeleteRow} autoFocus>Excluir</Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </>
  );
}

class AdminUsuarioHomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      statusSelected: [],
    };
  }

  componentWillMount() {
    this.props.actions.removeAllRows();
    this.props.actions.requestDisponilidades();
  }


  handleChangeSelect = (event) => {
    const { name, checked } = event.target;
    let { statusSelected } = this.state;
    if (checked && statusSelected.indexOf(name) === -1) {
      statusSelected.push(name);
    }

    if (!checked && statusSelected.indexOf(name) > -1) {
      statusSelected = statusSelected.filter((b) => b !== name);
    }

    this.setState({
      statusSelected: statusSelected,
    });
  };

  handleSearchText = (event) => {
    const { value } = event.target;

    this.setState({
      searchText: value,
    });
  };

  handleDeleteDisponibilidade = (id) => {
    this.props.actions.deleteDisponibilidade(id);
  };


  render() {
    const { oidc } = this.props;

    var rows = [];

    if (!this.props.adminSimuladorVe.isLoading && this.props.adminSimuladorVe) {

      rows = this.props.adminSimuladorVe.disponibilidades;
    }

    if (this.state.searchText !== "") {
      rows = rows.filter((k) =>
        k?.fornecedor.nome?.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    }

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.adminSimuladorVe.isLoadingFornecedor}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Preços</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <CustomPaginationActionsTable rows={rows} oidc={oidc} handleSearchText={this.handleSearchText} handleDeleteDisponibilidade={this.handleDeleteDisponibilidade} />
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminSimuladorVeActions,
      }, dispatch),
    };
  }
)(AdminUsuarioHomePage);
