import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { PatternFormat } from 'react-number-format';
import { Box } from '@mui/material';

const filter = createFilterOptions();

export function ConInfSelect({ onChange, value: deafaultValue, handleCreate, ...props }) {
  const [value, setValue] = React.useState(deafaultValue ? deafaultValue : null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      consultoria: '',
      cnpj: '',
      nome: '',
      email: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    consultoria: '',
    cnpj: '',
    nome: '',
    email: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.checkValidity()) {
      handleCreate(dialogValue)
      setValue({
        id: '',
        label: dialogValue.consultoria + " - " + dialogValue.nome,
      });
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (newValue && newValue.newValue) {
            toggleOpen(true);
            setValue(null);
          } else {
            setValue(newValue);
            onChange(event, newValue.id);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              id: params.inputValue,
              label: `Adicionar "${params.inputValue}"`,
              newValue: true
            });
          }

          return filtered;
        }}
        // eslint-disable-next-line eqeqeq
        isOptionEqualToValue={(option, value) => option.id == value.id || option.label == value.label}
        getOptionLabel={(option) => { return option.label }}
        {...props}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Adicionar novo Consultor Influenciador</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Insira todas as informações abaixo
            </DialogContentText>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                autoFocus
                margin="dense"
                id="consultoria"
                value={dialogValue.consultoria}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    consultoria: event.target.value,
                  })
                }
                label="Consultoria"
                type="text"
                variant="standard"
                required
              />
              <PatternFormat
                autoFocus
                margin="dense"
                id="cnpj"
                customInput={TextField}
                value={dialogValue.cnpj}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    cnpj: event.target.value,
                  })
                }
                label="CNPJ"
                format="##.###.###/####-##"
                variant="standard"
                required
              />
              <TextField
                autoFocus
                margin="dense"
                id="nome"
                value={dialogValue.nome}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    nome: event.target.value,
                  })
                }
                label="Nome completo do consultor"
                type="text"
                variant="standard"
                required
              />
              <TextField
                margin="dense"
                id="email"
                value={dialogValue.email}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    email: event.target.value,
                  })
                }
                label="Email do consultor"
                type="email"
                variant="standard"
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit">Adicionar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}