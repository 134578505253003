import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Switch, FormControlLabel, Typography } from '@mui/material';

const getObjValues = (values = []) => {
  var obj = {}
  values?.forEach(x => {
    obj[x.id] = false;
    obj[`${x.id}Max`] = false;
  });
  return obj;
}

export default function DiscountDialog({ open, close, onActiveDiscountChange, sessionDiscounts, activeDiscounts }) {
  const [discounts, setDiscounts] = useState(getObjValues(sessionDiscounts));

  React.useEffect(() => {
    setDiscounts(getObjValues(sessionDiscounts))
  }, [sessionDiscounts]);

  const handleChange = (e) => {
    const { id, checked } = e.target;
    setDiscounts({ ...discounts, [id]: checked, [`${id}Max`]: checked });
  };

  const handleClear = () => {
    const resetState = getObjValues(sessionDiscounts);
    setDiscounts(resetState);
  };

  const handleCancel = () => {
    close();
    setDiscounts(activeDiscounts);
  };

  const handleApply = () => {
    onActiveDiscountChange(discounts);
    close();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="discount-dialog-title"
        maxWidth={false}
        fullWidth={true}
        style={{ maxWidth: '500px', margin: 'auto', }}
      >
        <DialogTitle id="discount-dialog-title">
          Descontos
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {sessionDiscounts?.map((discount) => (
              <React.Fragment key={discount.id}>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">{discount.label}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        id={discount.id}
                        checked={discounts[discount.id]}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label={discounts[discount.id] ? 'Ocultar' : 'Exibir'}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClear} color='vermelho'>
            Limpar
          </Button>
          <Button autoFocus onClick={handleCancel}>
            Cancelar
          </Button>
          <Button onClick={handleApply} variant='contained' color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
