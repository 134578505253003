import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function MapPanel(props) {
    const { handleResumeClose, countFarm, valueAreaFarm,
        countAssignedFarm, valueAreaAssignedFarm } = props;

    return (
        <React.Fragment>
            <TableContainer sx={{ borderRadius: "4px 4px 0px 0px" }}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                        <TableRow>
                            <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", fontSize: "1.25rem", padding: "8px !important" }}>
                                Resumo Fazendas
                            </TableCell>
                            <TableCell sx={{ textAlign: "right !important" }}>
                                <IconButton sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white" }}
                                    onClick={handleResumeClose}>
                                    <CloseIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer sx={{ borderRadius: "0px 0px 4px 4px", height: "200px" }}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                {"Número Total: " + Number(countFarm).toLocaleString("pt-br")}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                {"Conhecidas: " + Number(countAssignedFarm).toLocaleString("pt-br")}
                                {countAssignedFarm !== 0 ? " (" + Number((countAssignedFarm / countFarm) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                {"Desconhecidas: " + Number((countFarm - countAssignedFarm)).toLocaleString("pt-br")}
                                {countAssignedFarm !== 0 ? " (" + Number(((countFarm - countAssignedFarm) / countFarm) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                {"Área Total: " + Number(Math.round(valueAreaFarm)).toLocaleString("pt-br") + " ha"}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                {"Área Conhecida: " + Number(Math.round(valueAreaAssignedFarm)).toLocaleString("pt-br") + " ha"}
                                {valueAreaFarm !== 0 ? " (" + Number((valueAreaAssignedFarm / valueAreaFarm) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                            <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                                {"Área Desconhecida: " + Number(Math.round(valueAreaFarm - valueAreaAssignedFarm)).toLocaleString("pt-br") + " ha"}
                                {valueAreaFarm !== 0 ? " (" + Number(((valueAreaFarm - valueAreaAssignedFarm) / valueAreaFarm) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}