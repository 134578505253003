import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { toNegative } from "../../../../helpers/utils";
import { GetSafeNumber } from "../../../../helpers";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});


const invertSignal = (value = 0) => {
  return value * -1;
}

export default function SimulatorTableEditable(props) {
  const { currency, actions, rows } = props;
  const role = props.actions.user?.role?.role

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow
              sx={{
                th: {
                  fontWeight: "bold",
                },
              }}
            >
              <TableCell align="left">Produto</TableCell>
              <TableCell align="left">Última Negociação</TableCell>
              <TableCell align="left">Recomendações</TableCell>
              <TableCell align="left">Sales Input</TableCell>
              <TableCell align="left">Cota</TableCell>
              <TableCell align="left">Preço</TableCell>
              {/* <TableCell align="center">Encargos</TableCell> */}
              <TableCell align="left">Desconto Categoria Max</TableCell>
              <TableCell align="left">Desconto Categoria</TableCell>
              <TableCell align="left">Valor Final Corteva</TableCell>
              <TableCell align="left">Preço proposto pelo Cliente</TableCell>
              <TableCell align="left">Volume</TableCell>
              <TableCell align="left">Preço Negociado</TableCell>
              <TableCell align="left">CPA</TableCell>
              {/*<TableCell align="center">Flexibilidade Marketing</TableCell>*/}
              {/* <TableCell align="center">Ajuste</TableCell> */}
              <TableCell align="left">Valor Final</TableCell>
              {/* <TableCell align="center">Score</TableCell> */}
              {role?.workflowViewSensitiveData && (
                <>
                  {/* <TableCell align="left">FRB</TableCell> */}
                  <TableCell align="left">COGS</TableCell>
                  <TableCell align="left">Sales Dedution</TableCell>
                  <TableCell align="left">GM Target</TableCell>
                  {/* <TableCell align="left">CPA Máx Aprovado</TableCell> */}
                  <TableCell align="left">Sales Dedution</TableCell>
                  <TableCell align="left">Net Sales</TableCell>
                  <TableCell align="left">COGS</TableCell>
                  <TableCell align="left">GM</TableCell>
                  <TableCell align="left">%GM</TableCell>
                  {/* <TableCell align="left">CPA%</TableCell> */}
                  <TableCell align="left">% Atingido GM</TableCell>
                  {/* <TableCell align="left">% Atingido CPA</TableCell> */}
                </>
              )}
              <TableCell align="left">Score</TableCell>
              {/* <TableCell align="left">Score Produto</TableCell> */}

            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.filter((row) => !(row.excluido !== undefined && row.excluido)).map((row, i) => {

              var imagem = actions.produtos.products.find(x => x.productId === row.produtoId);
              const cogs = currency === "BRL" ? GetSafeNumber(row.cogs * row.cur, 2) : GetSafeNumber(row.cogs, 2);

              const recomendacoes = [];
              if (row.historico) {
                recomendacoes.push("Histórico");
              }
              if (row.recomendacaoMarketing) {
                recomendacoes.push("Marketing");
              }
              if (row.guiaPosicionamento) {
                recomendacoes.push("Guia de Posicionamento");
              }
              if (row.geracaoDemanda) {
                recomendacoes.push("Geração Demanda");
              }
              if (row.novoProduto) {
                recomendacoes.push("Novo Produto");
              }

              return (
                <TableRow key={row.produto}>
                  <TableCell>
                    {imagem?.imagemProduto ? <img alt={row.produto} src={imagem.imagemProduto} height="50" style={{ maxWidth: "150px" }} /> : row.produto}
                  </TableCell>
                  <TableCell align="left">
                    {currency === "BRL" ?
                      (row.ultimaNegociacaoBrl ?? 0).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }) :
                      (row.ultimaNegociacaoUsd ?? 0).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "USD",
                      })
                    }
                  </TableCell>
                  <TableCell align="center">
                    {recomendacoes.join(", ")}
                  </TableCell>
                  <TableCell align="left">
                    {(Number((row.salesInput ?? 0).toFixed(2))).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="left">
                    {(Number((row.cota ?? 0).toFixed(2))).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="left">
                    {
                      row.preco.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  {/* <TableCell align="left">
                    {Number(row.embargoes === undefined ? 0 : row.embargoes).toLocaleString("pt-BR")}%
                  </TableCell> */}
                  <TableCell align="left">
                    {Number(invertSignal(row.descontoCategoriaMax * 100).toFixed(2)).toLocaleString("pt-BR")}%
                  </TableCell>
                  <TableCell align="left">
                    {row.descontoCategoria}%
                  </TableCell>
                  <TableCell align="left">
                    {
                      row.valorFinalCorteva.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="left">
                    {GetSafeNumber(row.precoProposto).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: (currency === "BRL" ? "BRL" : "USD"),
                    })}
                  </TableCell>
                  <TableCell align="left">
                    {row.volume}
                  </TableCell>
                  <TableCell align="left">
                    {GetSafeNumber(row.precoNegociado).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: (currency === "BRL" ? "BRL" : "USD"),
                    })}
                  </TableCell>
                  <TableCell align="left">
                    {
                      row.cpa.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                  <TableCell align="left">
                    {
                      row.valorFinal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  {role?.workflowViewSensitiveData && (
                    <>
                      {/* FRB */}
                      {/* <TableCell align="left">
                        {row.frb}
                      </TableCell> */}
                      <TableCell align="left">
                        {/* COGS (usd)	 */}
                        {(toNegative(cogs)).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: (currency === "BRL" ? "BRL" : "USD"),
                        })
                        }
                      </TableCell>
                      <TableCell align="left">
                        {/* Sales Dedution (%) */}
                        {(toNegative(row.salesDeductionPer)).toFixed(2).toLocaleString("pt-br")}%
                      </TableCell>
                      <TableCell align="left">
                        {/* GM Target */}
                        {(row.gmTarget).toFixed(2).toLocaleString("pt-br")}%
                      </TableCell>
                      {/* CPA Máx Aprovado (%) */}
                      {/* <TableCell align="left">
                        {Number(parseFloat(row.cpaMax).toFixed(2).toLocaleString("pt-br"))}%
                      </TableCell> */}
                      <TableCell align="left">
                        {/* Sales Dedution */}
                        {(toNegative(row.salesDeduction)).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: (currency === "BRL" ? "BRL" : "USD"),
                        })
                        }
                      </TableCell>
                      <TableCell align="left">
                        {/* Net Sales (novo) */}
                        {(row.netSales).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: (currency === "BRL" ? "BRL" : "USD"),
                        })
                        }
                      </TableCell>
                      <TableCell align="left">
                        {/* COGS  */}
                        {Number(toNegative(parseFloat(row.cogsFinal))
                          .toFixed(2))
                          .toLocaleString("pt-br")}
                      </TableCell>
                      <TableCell align="left">
                        {/* GM */}
                        {(row.gm
                        ).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: (currency === "BRL" ? "BRL" : "USD"),
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                        }
                      </TableCell>
                      <TableCell align="left">
                        {/* %GM */}
                        {Number(parseFloat(row.gmPer
                        ).toFixed(2)).toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                      </TableCell>
                      {/* CPA% */}
                      {/* <TableCell align="left">
                        {Number(parseFloat(row.cpaPer
                        ).toFixed(2)).toLocaleString("pt-br")}%
                      </TableCell> */}
                      <TableCell align="left">
                        {Number(parseFloat(row.perAtingidoGM).toFixed(2)).toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                      </TableCell>
                      {/* <TableCell align="left">
                        {Number(parseFloat(row.perAtingidoCpa
                        ).toFixed(2)).toLocaleString("pt-br")}%
                      </TableCell> */}
                    </>
                  )}
                  <TableCell align="center">
                    <CircleIcon sx={{ color: `${row.corGm === 0 ? "#C00000" : row.corGm === 1 ? "#FFC000" : row.corGm === 2 ? "#00B050" : "var(--bs-border-color)"}` }} />
                  </TableCell>
                  {/* <TableCell align="center">
                    <CircleIcon sx={{ color: `${row.corProduto === 0 ? "#C00000" : row.corProduto === 1 ? "#FFC000" : row.corProduto === 2 ? "#00B050" : "var(--bs-border-color)"}` }} />
                  </TableCell> */}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}