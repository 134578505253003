import { HandleResponse } from "../helpers";
import { metodoPagamentoActions } from "./metodopagamento.actions";
import { simulator2Actions } from "./simulator2.actions";

const requestCenariosType = "WORKFLOW_REQUEST_CENARIOS";
const receiveCenarioslType = "WORKFLOW_RECEIVE_CENARIOS";

const requestGetCenarioType = "WORKFLOW_REQUEST_GET_CENARIO";
const receiveGetCenarioType = "WORKFLOW_RECEIVE_GET_CENARIO";
const failureGetCenarioType = "WORKFLOW_FAILURE_GET_CENARIO";

const enviarRequestCenarioslType = "WORKFLOW_REQUEST_ENVIAR_CENARIO";
const enviarCompleteCenarioslType = "WORKFLOW_COMPLETE_ENVIAR_CENARIO";
const enviarFailureCenarioslType = "WORKFLOW_FAILURE_ENVIAR_CENARIO";

const initialState = {
  status: "idle",
  listCenarios: [],
  cenario: {},
  isLoading: false,
  cenarioStatus: "idle",
  isLoadingEnvio: false,
  file: null,
  isLoadingDownload: true
};

export const workflowActions = {
  requestAllCenarios: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCenariosType });

    const url = `/api/simulador2/workflow`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const listCenarios = await response.json();
    dispatch({ type: receiveCenarioslType, listCenarios });
  },
  GetCenario: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetCenarioType });

    const url = `/api/simulador2/workflow/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      cenario => {
        dispatch({ type: receiveGetCenarioType, cenario });
        if (cenario.isCpActive ?? true) {
          if (cenario.cenarioV2Cp?.metodoPagamento !== "" && cenario.cenarioV2Cp?.metodoPagamento !== undefined && cenario.cenarioV2Cp?.metodoPagamento != null) {
            dispatch(metodoPagamentoActions.GetMetodoPagamento(cenario.cenarioV2Cp.metodoPagamento));
          }
        }
      },
      error => {
        dispatch({ type: failureGetCenarioType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível encontrar o cenário.", message: error, responseType: "error" });
      }
    );
  },
  enviarCenario: (clientId, id, comentario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enviarRequestCenarioslType });

    const url = `/api/simulador2/workflow/envio/${id}`;

    const options = {
      method: "PATCH",
      body: JSON.stringify(comentario),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
        "Content-Type": "application/json"
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      _ => {
        dispatch({ type: enviarCompleteCenarioslType });
        dispatch(simulator2Actions.requestCenarios(clientId));
      },
      error => {
        dispatch({ type: enviarFailureCenarioslType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível enviar o cenário.", message: error, responseType: "error" });
      }
    );
  },
  aprovarCenario: (id, comentario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enviarRequestCenarioslType });

    const url = `/api/simulador2/workflow/aprovar/${id}`;

    const options = {
      method: "PATCH",
      body: JSON.stringify(comentario ?? ""),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
        "Content-Type": "application/json"
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      _ => {
        dispatch({ type: enviarCompleteCenarioslType });
        dispatch(workflowActions.GetCenario(id));
      },
      error => {
        dispatch({ type: enviarFailureCenarioslType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível aprovar o cenário.", message: error, responseType: "error" });
      }
    );
  },
  reprovarCenario: (id, comentario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enviarRequestCenarioslType });

    const url = `/api/simulador2/workflow/reprovar/${id}`;

    const options = {
      method: "PATCH",
      body: JSON.stringify(comentario ?? ""),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
        "Content-Type": "application/json"
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      _ => {
        dispatch({ type: enviarCompleteCenarioslType });
        dispatch(workflowActions.GetCenario(id));
      },
      error => {
        dispatch({ type: enviarFailureCenarioslType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível reprovar o cenário.", message: error, responseType: "error" });
      }
    );
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCenariosType:
      return {
        ...state,
        listCenarios: [],
        isLoading: true,
      };
    case receiveCenarioslType:
      return {
        ...state,
        listCenarios: action.listCenarios,
        isLoading: false,
      };
    case enviarRequestCenarioslType:
      return {
        ...state,
        status: "loading",
      };
    case enviarCompleteCenarioslType:
      return {
        ...state,
        status: "idle",
      };
    case enviarFailureCenarioslType:
      return {
        ...state,
        status: "failure",
      };
    case requestGetCenarioType:
      return {
        ...state,
        cenarioStatus: "loading",
      };
    case receiveGetCenarioType:
      return {
        ...state,
        cenarioStatus: "idle",
        cenario: action.cenario,
      };
    case failureGetCenarioType:
      return {
        ...state,
        cenarioStatus: "failure",
      };
    default:
      return state;
  }
};
