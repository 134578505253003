import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CrossSelling({ totalCornVolume, exalt, utrisha }) {
  const [expandCrossSelling, setExpandCrossSelling] = useState(true);

  function toggleExpandCrossSelling() {
    setExpandCrossSelling(!expandCrossSelling);
  }

  return (
    <>
      <Grid item xs={2} sm={8} md={12} >
        <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#70AD47", color: "#fff", fontWeight: "bold" }}>
          <Grid item xs={12} sx={{ paddingBottom: "0.5rem !important", paddingTop: "0.5rem !important", paddingRight: "1rem !important", display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
            <Stack alignItems={"center"} gap={1} direction={"row"} >
              <p style={{ marginBottom: "0" }}>CROSS-SELLING</p>
              <IconButton onClick={toggleExpandCrossSelling} sx={{ color: "#FFF" }} size="small">
                {expandCrossSelling ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {expandCrossSelling &&
        <>
          <Grid item xs={2} sm={8} md={12}>
            <Grid container spacing={2} className="rounded-3" sx={{ display: "inline-block", marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
              <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                <p>Venda integrada:</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="rounded-3" sx={{ display: "flex", flexDirection: "row", marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "12px", marginBotton: "12px" }}>
              <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <label className="d-block">#sc Milho</label>
                <div style={{ fontWeight: "bold" }} > {Number(totalCornVolume).toLocaleString("pt-BR")} </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <label className="d-block" >Exalt (5sc : 1L)</label>
                <div style={{ fontWeight: "bold" }} >
                  {totalCornVolume === 0 ?
                    "Inserir área de milho" :
                    (exalt === 0 ? "Aumentar volume de Exalt" : `Ativada ${Number(exalt?.toFixed(2)).toLocaleString("pt-BR")}L`)
                  }
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <label className="d-block">Utrisha N (3sc :1Kg)</label>
                <div style={{ fontWeight: "bold" }} >
                  {totalCornVolume === 0 ?
                    "Inserir área de milho" :
                    (utrisha === 0 ? "Aumentar volume de Utrisha N" : `Ativada ${Number(utrisha?.toFixed(2)).toLocaleString("pt-BR")}Kg`)
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </>
  )
}
