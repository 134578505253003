import { HandleResponse, isNullOrEmpty } from "../helpers"

const requestGetAreaType = "CPA_REQUEST_GET_AREA";
const receiveGetAreaType = "CPA_RECEIVE_GET_AREA";
const failureGetAreaType = "CPA_FAILURE_GET_AREA";

const requestGetAreaSeedsType = "CPA_REQUEST_GET_AREA_SEEDS";
const receiveGetAreaSeedsType = "CPA_RECEIVE_GET_AREA_SEEDS";
const failureGetAreaSeedsType = "CPA_FAILURE_GET_AREA_SEEDS";

const requestChangeCpType = "CPA_REQUEST_CHANGE_CP";
const receiveChangeCpType = "CPA_RECEIVE_CHANGE_CP";
const failureChangeCpType = "CPA_FAILURE_CHANGE_CP";

const requestChangeCpAreaType = "CPA_REQUEST_CHANGE_CP_AREA";
const receiveChangeCpAreaType = "CPA_RECEIVE_CHANGE_CP_AREA";
const failureChangeCpAreaType = "CPA_FAILURE_CHANGE_CP_AREA";

const requestChangeSeedsType = "CPA_REQUEST_CHANGE_SEEDS";
const receiveChangeSeedsType = "CPA_RECEIVE_CHANGE_SEEDS";
const failureChangeSeedsType = "CPA_FAILURE_CHANGE_SEEDS";

const requestChangeSeedsAreaType = "CPA_REQUEST_CHANGE_SEEDS_AREA";
const receiveChangeSeedsAreaType = "CPA_RECEIVE_CHANGE_SEEDS_AREA";
const failureChangeSeedsAreaType = "CPA_FAILURE_CHANGE_SEEDS_AREA";

const requestChangeSeedsAreaCulturaType = "CPA_REQUEST_CHANGE_SEEDS_AREA_CULTURA";
const receiveChangeSeedsAreaCulturaType = "CPA_RECEIVE_CHANGE_SEEDS_AREA_CULTURA";
const failureChangeSeedsAreaCulturaType = "CPA_FAILURE_CHANGE_SEEDS_AREA_CULTURA";

const handleChangeFormDataType = "CPA_HANDLE_CHANGE_FORMDATA";
const clearFormDataType = "CPA_CLEAR_FORMDATA";

const requestGetCampanhaType = "CPA_REQUEST_GET_CAMPANHAS";
const receiveGetCampanhaType = "CPA_RECEIVE_GET_CAMPANHAS";
const failureGetCampanhaType = "CPA_FAILURE_GET_CAMPANHAS";

const requestGetCampanhaSeedsType = "CPA_REQUEST_GET_CAMPANHAS_SEEDS";
const receiveGetCampanhaSeedsType = "CPA_RECEIVE_GET_CAMPANHAS_SEEDS";
const failureGetCampanhaSeedsType = "CPA_FAILURE_GET_CAMPANHAS_SEEDS";

const initialState = {
  formData: { campanhaCp: '', campanhaSeeds: '', updateDateCp: "", updateDataDateCp: "", updateDateSeeds: "", updateDataDateSeeds: "" },
  area: undefined,
  areaSeeds: undefined,
  areaSeedsCultura: undefined,
  areaSeedsStatus: "idle",
  campanhas: [],
  campanhasSeeds: [],
  campanhaStatus: "idle",
  campanhaSeedsStatus: "idle",
  changeStatus: "idle",
  changeSeedsStatus: "idle",
};

export const cpaActions = {
  handleChangeFormData: (propName = "", value = "") => async (dispatch) => {
    dispatch({ type: handleChangeFormDataType, propName, value });
  },
  clearFormData: () => async (dispatch) => {
    dispatch({ type: clearFormDataType });
  },
  GetCampanhasCp: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetCampanhaType });

    const url = `/api/simulador2/cpa/Cp/Campanhas`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      campanha => {
        dispatch({ type: receiveGetCampanhaType, campanha });
      },
      error => {
        dispatch({ type: failureGetCampanhaType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados de campanha.", message: error, responseType: "error" });
      }
    );
  },
  GetCampanhasSeeds: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetCampanhaSeedsType });

    const url = `/api/simulador2/cpa/Seeds/Campanhas`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      campanha => {
        dispatch({ type: receiveGetCampanhaSeedsType, campanha });
      },
      error => {
        dispatch({ type: failureGetCampanhaSeedsType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados de campanha.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaCpCenario: (cenarioId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaType });

    const url = `/api/simulador2/cpa/Cp/Cenario/${cenarioId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);

    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaType, area });
      },
      error => {
        dispatch({ type: failureGetAreaType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados de CPA.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaCpArea: (campanhaId = 0, area = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaType });

    const url = `/api/simulador2/cpa/Cp/Area?campanhaId=${campanhaId}${isNullOrEmpty(area) ? "" : `&area=${area}`}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateCp", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateCp", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados da área.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaCpBusiness: (campanhaId = 0, area = "", businessArea = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaType });

    const url = `/api/simulador2/cpa/Cp/BusinessArea?campanhaId=${campanhaId}${isNullOrEmpty(area) ? "" : `&area=${area}`}${isNullOrEmpty(area) ? "" : `&businessArea=${businessArea}`}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateCp", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateCp", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados da business Area.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaCpDistrito: (campanhaId = 0, distrito = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaType });

    const url = `/api/simulador2/cpa/Cp/District?campanhaId=${campanhaId}${isNullOrEmpty(distrito) ? "" : `&district=${distrito}`}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateCp", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateCp", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados do Distrito.", message: error, responseType: "error" });
      }
    );
  },
  handleChangeCp: (id, value, route = "BusinessArea") => async (dispatch, getState) => {
    const { oidc, cpa } = getState();

    const child = cpa.area.children.find(x => x.id === id);

    if (child && child.perCpaDisponivel !== value) {
      const originalValue = child.perCpaDisponivel;

      dispatch({ type: requestChangeCpType, id, value });

      const url = `/api/simulador2/cpa/Cp/${route}/${id}`;

      const options = {
        method: "PATCH",
        body: JSON.stringify(value),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      HandleResponse(response).then(
        updateDateCp => {
          dispatch({ type: receiveChangeCpType, updateDateCp });
        },
        error => {
          dispatch({ type: failureChangeCpType, id, value: originalValue });
          dispatch({ type: "CALL_ALERT", title: "Não foi possível realizar a alteração.", message: error, responseType: "error" });
        }
      );
    }
  },
  handleChangeCpArea: (id, value) => async (dispatch, getState) => {
    const { oidc, cpa } = getState();

    const child = cpa.area;

    if (child && child.perCpaDisponivel !== value) {
      const originalValue = child.perCpaDisponivel;

      dispatch({ type: requestChangeCpAreaType, id, value });

      const url = `/api/simulador2/cpa/Cp/Area/${id}`;

      const options = {
        method: "PATCH",
        body: JSON.stringify(value),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      HandleResponse(response).then(
        updateDateCp => {
          dispatch({ type: receiveChangeCpAreaType, updateDateCp });
        },
        error => {
          dispatch({ type: failureChangeCpAreaType, id, value: originalValue });
          dispatch({ type: "CALL_ALERT", title: "Não foi possível realizar a alteração.", message: error, responseType: "error" });
        }
      );
    }
  },
  UpdateCpaCpArea: (campanhaId = 0, area = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaType });

    const url = `/api/simulador2/cpa/Cp/Area?campanhaId=${campanhaId}${isNullOrEmpty(area) ? "" : `&area=${area}`}`;

    const options = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateCp", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateCp", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaType });
        dispatch({ type: clearFormDataType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível atualizar os dados da área.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaSeedsCenario: (cenarioId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestChangeSeedsAreaCulturaType });

    const url = `/api/simulador2/cpa/Seeds/Cenario/${cenarioId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);

    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveChangeSeedsAreaCulturaType, area });
      },
      error => {
        dispatch({ type: failureChangeSeedsAreaCulturaType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados de CPA.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaSeedsArea: (campanhaId = 0, area = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaSeedsType });

    const url = `/api/simulador2/cpa/Seeds/Area?campanhaId=${campanhaId}${isNullOrEmpty(area) ? "" : `&area=${area}`}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaSeedsType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateSeeds", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateSeeds", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaSeedsType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados da área.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaSeedsBusiness: (campanhaId = 0, area = "", businessArea = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaSeedsType });

    const url = `/api/simulador2/cpa/Seeds/BusinessArea?campanhaId=${campanhaId}${isNullOrEmpty(area) ? "" : `&area=${area}`}${isNullOrEmpty(area) ? "" : `&businessArea=${businessArea}`}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaSeedsType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateSeeds", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateSeeds", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaSeedsType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados da business Area.", message: error, responseType: "error" });
      }
    );
  },
  GetCpaSeedsDistrito: (campanhaId = 0, distrito = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaSeedsType });

    const url = `/api/simulador2/cpa/Seeds/District?campanhaId=${campanhaId}${isNullOrEmpty(distrito) ? "" : `&district=${distrito}`}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaSeedsType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateSeeds", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateSeeds", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaSeedsType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados do Distrito.", message: error, responseType: "error" });
      }
    );
  },
  handleChangeSeedsArea: (id, value) => async (dispatch, getState) => {
    const { oidc, cpa } = getState();

    const child = cpa.areaSeeds;

    if (child && child.cpaDisponivel !== value) {
      const originalValue = child.cpaDisponivel;

      dispatch({ type: requestChangeSeedsAreaType, id, value });

      const url = `/api/simulador2/cpa/Seeds/Area/${id}`;

      const options = {
        method: "PATCH",
        body: JSON.stringify(value),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      HandleResponse(response).then(
        updateDate => {
          dispatch({ type: receiveChangeSeedsAreaType, updateDate });
        },
        error => {
          dispatch({ type: failureChangeSeedsAreaType, id, value: originalValue });
          dispatch({ type: "CALL_ALERT", title: "Não foi possível realizar a alteração.", message: error, responseType: "error" });
        }
      );
    }
  },
  handleChangeSeeds: (id, value, route = "BusinessArea") => async (dispatch, getState) => {
    const { oidc, cpa } = getState();

    const child = cpa.areaSeeds.children.find(x => x.id === id);

    if (child && child.cpaDisponivel !== value) {
      const originalValue = child.cpaDisponivel;

      dispatch({ type: requestChangeSeedsType, id, value });

      const url = `/api/simulador2/cpa/Seeds/${route}/${id}`;

      const options = {
        method: "PATCH",
        body: JSON.stringify(value),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      HandleResponse(response).then(
        updateDate => {
          dispatch({ type: receiveChangeSeedsType, updateDate });
        },
        error => {
          dispatch({ type: failureChangeSeedsType, id, value: originalValue });
          dispatch({ type: "CALL_ALERT", title: "Não foi possível realizar a alteração.", message: error, responseType: "error" });
        }
      );
    }
  },
  UpdateCpaSeedsArea: (campanhaId = 0, area = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAreaSeedsType });

    const url = `/api/simulador2/cpa/Seeds/Area?campanhaId=${campanhaId}${isNullOrEmpty(area) ? "" : `&area=${area}`}`;

    const options = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      area => {
        dispatch({ type: receiveGetAreaSeedsType, area });
        dispatch({ type: handleChangeFormDataType, propName: "updateDateSeeds", value: area.lastGeralManualUpdate });
        dispatch({ type: handleChangeFormDataType, propName: "updateDataDateSeeds", value: area.lastGeralAutomaticUpdate });
      },
      error => {
        dispatch({ type: failureGetAreaSeedsType });
        dispatch({ type: clearFormDataType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível atualizar os dados da área.", message: error, responseType: "error" });
      }
    );
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestGetAreaType:
      return {
        ...state,
        area: undefined,
        areaStatus: "loading",
      };
    case receiveGetAreaType:
      return {
        ...state,
        areaStatus: "idle",
        area: action.area,
      };
    case failureGetAreaType:
      return {
        ...state,
        area: undefined,
        areaStatus: "failure",
      };
    case requestGetAreaSeedsType:
      return {
        ...state,
        areaSeeds: undefined,
        areaSeedsStatus: "loading",
      };
    case receiveGetAreaSeedsType:
      return {
        ...state,
        areaSeedsStatus: "idle",
        areaSeeds: action.area,
      };
    case failureGetAreaSeedsType:
      return {
        ...state,
        areaSeeds: undefined,
        areaSeedsStatus: "failure",
      };
    case requestChangeSeedsAreaCulturaType:
      return {
        ...state,
        areaSeedsCultura: undefined,
        areaSeedsStatus: "loading",
      };
    case receiveChangeSeedsAreaCulturaType:
      return {
        ...state,
        areaSeedsCultura: action.area,
        areaSeedsStatus: "idle",
      };
    case failureChangeSeedsAreaCulturaType:
      return {
        ...state,
        areaSeedsCultura: undefined,
        areaSeedsStatus: "failure",
      };
    case requestGetCampanhaType:
      return {
        ...state,
        campanhas: [],
        campanhaStatus: "loading",
      };
    case receiveGetCampanhaType:
      return {
        ...state,
        campanhaStatus: "idle",
        campanhas: action.campanha,
      };
    case failureGetCampanhaType:
      return {
        ...state,
        campanhas: [],
        campanhaStatus: "failure",
      };
    case requestGetCampanhaSeedsType:
      return {
        ...state,
        campanhasSeeds: [],
        campanhaSeedsStatus: "loading",
      };
    case receiveGetCampanhaSeedsType:
      return {
        ...state,
        campanhaSeedsStatus: "idle",
        campanhasSeeds: action.campanha,
      };
    case failureGetCampanhaSeedsType:
      return {
        ...state,
        campanhasSeeds: [],
        campanhaSeedsStatus: "failure",
      };
    case handleChangeFormDataType:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.propName]: action.value,
        }
      };
    case clearFormDataType:
      return {
        ...state,
        formData: {
          ...initialState.formData,
        }
      };
    case requestChangeCpType:
      {
        const child = state.area.children.find(x => x.id === action.id);
        child.perCpaDisponivel = action.value;

        return {
          ...state,
          changeStatus: "loading",
        };
      }
    case receiveChangeCpType:
      return {
        ...state,
        formData: {
          ...state.formData,
          updateDateCp: action.updateDateCp,
        },
        changeStatus: "idle",
      };
    case failureChangeCpType:
      {
        const child = state.area.children.find(x => x.id === action.id);
        child.perCpaDisponivel = action.value;

        return {
          ...state,
          changeStatus: "idle",
        };
      }
    case requestChangeCpAreaType:
      {
        const child = state.area;
        child.perCpaDisponivel = action.value;

        return {
          ...state,
          changeStatus: "loading",
        };
      }
    case receiveChangeCpAreaType:
      return {
        ...state,
        formData: {
          ...state.formData,
          updateDateCp: action.updateDateCp,
        },
        changeStatus: "idle",
      };
    case failureChangeCpAreaType:
      {
        const child = state.area;
        child.perCpaDisponivel = action.value;

        return {
          ...state,
          changeStatus: "idle",
        };
      }
    case requestChangeSeedsType:
      {
        const child = state.areaSeeds.children.find(x => x.id === action.id);
        child.cpaDisponivel = action.value;

        return {
          ...state,
          changSeedsStatus: "loading",
        };
      }
    case receiveChangeSeedsType:
      return {
        ...state,
        formData: {
          ...state.formData,
          updateDateSeeds: action.updateDate,
        },
        changeSeedsStatus: "idle",
      };
    case failureChangeSeedsType:
      {
        const child = state.areaSeeds.children.find(x => x.id === action.id);
        child.cpaDisponivel = action.value;

        return {
          ...state,
          changeSeedsStatus: "idle",
        };
      }
    case requestChangeSeedsAreaType:
      {
        const child = state.areaSeeds;
        child.cpaDisponivel = action.value;

        return {
          ...state,
          changSeedsStatus: "loading",
        };
      }
    case receiveChangeSeedsAreaType:
      return {
        ...state,
        formData: {
          ...state.formData,
          updateDateSeeds: action.updateDate,
        },
        changeSeedsStatus: "idle",
      };
    case failureChangeSeedsAreaType:
      {
        const child = state.areaSeeds;
        child.cpaDisponivel = action.value;

        return {
          ...state,
          changeSeedsStatus: "idle",
        };
      }
    default:
      return state;
  }
};
