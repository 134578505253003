import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { userActions } from '../../../../../actions/user.actions';
import { adminUserActions } from "../../../../../actions/admin/user.actions";
import { adminWhitespaceActions } from "../../../../../actions/admin/whitespace.actions";
import { culturasWhitespaceActions } from "../../../../../actions/culturasWhitespace.actions";
import { visuallyHidden } from '@mui/utils';

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import CreateIcon from '@mui/icons-material/Create';
import Grid from "@mui/material/Grid";
import TableSortLabel from '@mui/material/TableSortLabel';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const columns = [
  { id: 'name', label: 'Nome', minWidth: 100, align: 'left' },
  { id: 'value', label: 'Valor', minWidth: 100, align: 'left' },
  { id: 'color', label: 'Cor', minWidth: 100, align: 'left' },
  { id: 'opacity', label: 'Opacidade', minWidth: 100, align: 'center' },
];

const headCells = [
  {
    id: 'name',
    align: 'left',
    label: 'Nome',
    sortable: true
  },
  {
    id: 'value',
    align: 'left',
    label: 'Valor',
    sortable: true
  },
  {
    id: 'color',
    align: 'left',
    label: 'Cor',
    sortable: true
  },
  {
    id: 'opacity',
    align: 'center',
    label: 'Opacidade',
    sortable: true
  },
  {
    id: 'status',
    align: 'center',
    label: 'Ativo',
    sortable: true
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const formatBoolean = (value) => {
  return value ? 'Sim' : 'Não';
};

const formatDate = (date) => {
  if (typeof date === 'string') {
    const parsedDate = new Date(date);
    if (parsedDate instanceof Date && !isNaN(parsedDate)) {
      const day = parsedDate.getDate().toString().padStart(2, '0');
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }

  return date;
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "th": {
          color: "#FFF",
          fontWeight: "bold",
          backgroundColor: "var(--cort-blue) !important"
        },
      }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            size="medium"
            sx={{
              '*': {
                color: '#fff !important',
                '&:hover': {
                  color: '#FFF !important',
                },
              },
            }}
          >
            {headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}

              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              : headCell.label
            }

          </TableCell>
        ))}
        <TableCell
          align={'center'}
          size="medium"
          sx={{
            '*': {
              color: '#fff !important',
              '&:hover': {
                color: '#FFF !important',
              },
            },
          }}
        >
          Opções
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ handleSearchText = (event) => { } }) {
  return (
    <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }}
      spacing={{ xs: 1, sm: 1.5, md: 2 }}
      justifyContent={{ xs: "center", sm: "center", md: "space-around" }}
      alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
      sx={{ mb: "16px" }}
    >
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquise por Nome"
          onChange={handleSearchText}
          inputProps={{ "aria-label": "Pesquise por Nome" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <Box sx={{ minWidth: { xs: "100%", sm: "100%", md: 'fit-content' } }}>
        <Link to={`/Admin-Culture-Edit`} style={{ width: "100%" }}>
          <Button variant="contained" color='azul' size="large" sx={{ width: "100%" }}>Adicionar Cultura</Button>
        </Link>
      </Box>
    </Stack>
  );
}

function CultureTable(props) {
  const { rows, actions, handleSearchText } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('nome');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <EnhancedTableToolbar handleSearchText={handleSearchText} />
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rows != null && rowsPerPage > 0
              ? stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : stableSort(rows, getComparator(order, orderBy))
            ).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    const isColorValue = typeof value === 'string' && value.startsWith('#'); // Use startsWith for better accuracy
                    const isNumber = !isNaN(parseFloat(value));

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {isColorValue ? (
                          <div
                            style={{
                              height: '20px',
                              width: '20px',
                              background: value,
                              borderRadius: '50%', // Use 50% for a perfect circle
                            }}
                          ></div>
                        ) : isNumber ? (
                          (value * 100).toFixed(0) + '%' // Round the number to 2 decimal places
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">
                    {row.status ? "Sim" : "Não"}
                  </TableCell>
                  <TableCell align="center">
                    <Grid container spacing={2} >
                      <Grid item xs={6}>
                        <Link to={`/Admin-Culture-Edit?CultureId=${row["id"]}`} style={{ width: "100%" }}>
                          <Button variant="text" startIcon={<CreateIcon />}>Editar</Button>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="text" startIcon={row["status"] ? <EventAvailableIcon /> : <EventBusyIcon />} onClick={() => actions.toggleCulture(row["id"])}>{row["status"] ? 'Desativar' : 'Ativar'}</Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage="Linhas por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : to}`}
          backIconButtonText="Página anterior"
          nextIconButtonText="Próxima página"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

class AdminCulturePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: ''
    };
  }

  componentWillMount() {
    this.props.actions.requestListCultures();
  }

  handleSearchText = (event) => {
    const { value } = event.target;

    this.setState({
      searchText: value,
    });
  };

  render() {
    const { oidc } = this.props;
    var rows = [];

    if (!this.props.culturasWhitespace.isLoading && this.props.culturasWhitespace) {
      rows = this.props.culturasWhitespace.cultures;
    }

    if (this.state.searchText !== "") {
      rows = rows.filter((k) =>
        k?.name?.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
        k?.value?.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    }

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.culturasWhitespace.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Whitespace"}>
                Whitespace
              </Link>
              <Typography color="text.primary">Culturas</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <CultureTable rows={rows} actions={this.props.actions} handleSearchText={this.handleSearchText} />
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...culturasWhitespaceActions,
        ...adminUserActions,
        ...adminWhitespaceActions,
        ...userActions,
      }, dispatch),
    };
  }
)(AdminCulturePage);