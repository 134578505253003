/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { userActions } from "../../../actions/user.actions";
import { clientTerritoryActions } from "../../../actions/clientTerritory.actions";
import { precoVeActions } from "../../../actions/precove.actions";
import { simuladorVeEncargosActions } from "../../../actions/simuladorVeEncargos.action";
import { produtoveActions } from "../../../actions/produtove.action";
import { Alert, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, createTheme, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { NumericFormat } from "react-number-format";
import { simuladorVeActions } from "../../../actions/simuladorVendaExterna.actions";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

class SimulatorEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cliente: 25,
      cli: 0,
      clientId: "",
      moeda: "",
      prazoPagamento: "",
      territorio: "",
      distrito: "",
    };
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.setState({ cli: params.GroupId });

    this.props.actions.requestClientTerritories();
    // this.props.actions.requestProdutoVe();
    // this.props.actions.requestPrecosVe();
    this.props.actions.requestEncargos();
    this.props.actions.simulacaoCliente(params.GroupId);
    this.props.actions.loadCenario(params.CenarioId).then(() => {
      const cenario = this.props.simuladorVendaExterna.cenario;
      if (cenario) {
        this.setState({ prazoPagamento: cenario.condicaoComercial || "" });
        this.setState({ moeda: cenario.moeda || "" });
      }
    });
    this.props.actions.requestProdutoPorTerritorio(params.GroupId, this.state.prazoPagamento);
    this.props.actions.requestCondicaoComercial(this.state.moeda);

    this.props.actions.requestPrecoProduto(this.state.produto, this.state.territorio, this.state.prazoPagamento);

  }


  render() {
    // const currency = 'BRL';
    var rows = this.props.simuladorVendaExterna.rows ? this.props.simuladorVendaExterna.rows : [];
    var territorio = "";
    var distrito = "";
    var cli = "";

    for (var j = 0; j < this.props.clientesVendaExterna.clients.length; j++) {
      // eslint-disable-next-line eqeqeq
      if (this.props.clientesVendaExterna.clients[j].id == this.state.cli) {
        this.state.cliente = this.props.clientesVendaExterna.clients[j];
        cli = this.props.clientesVendaExterna.clients[j].account.nome;
        this.state.territorio = this.props.clientesVendaExterna.clients[j].territory.territoryName;
        distrito = this.props.clientesVendaExterna.clients[j].territory.districtName;
        var endereco = this.state.cliente.account.endereco;
        var municipio = this.state.cliente.account.municipio;
        var uf = this.state.cliente.account.uf;
        var ie = this.state.cliente.account.ie;
        var cpfCnpj = this.state.cliente.account.cpfCnpj;
      }
    }
    var total = 0;
    for (var i = 0; i < rows.length; i++) {
      total = total + rows[i].valorFinalNegociado;
    }

    return (
      <div className="card">
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/simuladorVe"}
              >
                Simulador Venda Externa
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/simuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">Nova Simulação</Typography>
            </Breadcrumbs>
          </Stack>
        </div>

        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{cli}</h1>
              </div>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {distrito}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Endereço:</span> {endereco}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Cidade:</span> {municipio} - {uf}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Inscrição Estadual:</span> {ie}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>CPF/CNPJ:</span> {cpfCnpj}</h6>
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                {total > 0 && (
                  <>
                    <Grid item xs={2} sm={8} md={7} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        <b>Valor total Simulação: </b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={5} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        {
                          total.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={2}>
              <label className="d-block">Moeda</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={this.state.moeda}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            <Grid item xs={2} sm={8} md={2.25}>
              <label className="d-block">Prazo de Pagamento</label>
              <TextField
                disabled
                id="outlined-disabled"
                label={this.state.prazoPagamento}
                sx={{ width: "100%" }} size="small"
              />
            </Grid>

            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "0 !important", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                <Grid item xs={2} sm={1.5} md={2.5} sx={{ textAlign: { xs: "center", sm: "left" }, paddingTop: "1rem !important" }}>
                  <p>Cesta do Cliente</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12}>
              {this.props.simuladorVendaExterna.rows.length > 0 &&
                <TableContainer component={Paper}>
                  <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="custom pagination table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            th: {
                              fontWeight: "bold",
                            },
                          }}
                        >
                          <TableCell align="center">Produto</TableCell>
                          <TableCell align="center">Preço à Vista</TableCell>
                          <TableCell align="center">Encargo</TableCell>
                          <TableCell align="center">Desconto</TableCell>
                          <TableCell align="center">Preço Final</TableCell>
                          <TableCell align="center">Volume</TableCell>
                          <TableCell align="center">Preço Negociado</TableCell>
                          {/* <TableCell align="center">Valor Final Corteva</TableCell> */}
                          <TableCell align="center">Valor Final Negociado</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" align="center">
                              {row.produto.nome}
                            </TableCell>
                            <TableCell align="center">
                              {
                                row.preco.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                                })
                              }
                            </TableCell>
                            <TableCell align="center">
                              {Number((row.encargo) * 100).toFixed(2).toLocaleString("pt-BR")}%
                            </TableCell>
                            <TableCell align="center">  {
                              row.desconto.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell>
                            <TableCell align="center">  {
                              row.precoFinal.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell>
                            <TableCell align="center">
                              {row.volume}
                            </TableCell>
                            <TableCell align="center">{
                              row.precoNegociado.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell>
                            {/* <TableCell align="center">{
                              row.valorTotal.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell> */}
                            <TableCell align="center">{
                              row.valorFinalNegociado.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ThemeProvider>
                </TableContainer >
              }
            </Grid>
          </Grid>


        </div>
      </div>
    );
  }

}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {

          ...clientTerritoryActions,
          ...precoVeActions,
          ...produtoveActions,
          ...simuladorVeEncargosActions,
          ...simuladorVeActions,
          ...userActions
        },
        dispatch
      ),
    };
  }
)(SimulatorEditPage);
