import React, { Component } from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../../util/userManager";
import PropTypes from "prop-types";
// import { push } from "react-router-redux";
// import HomePage from "../home/HomePage";
import AllowUseNavigate, { navigate } from "../../Navigate";

class CallbackPage extends Component {
  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          navigate("/");
        }}
        errorCallback={(error) => {
          // navigate("/");
          console.error(error);
        }}
      >
        <>
          <AllowUseNavigate />
          {/* <HomePage /> */}
        </>
      </CallbackComponent>
    );
  }
}

CallbackPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(CallbackPage);
