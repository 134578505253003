const signalR = require("@microsoft/signalr");

export default function configure(oidc = null) {
  if (oidc == null) {
    return null
  }
  var connection = new signalR.HubConnectionBuilder()
    .withUrl(window.location.origin + "/hub", { accessTokenFactory: () => oidc.user.access_token })
    .withAutomaticReconnect([0, 2000, 5000, 10000, ...Array(1000).fill(60000)])
    .configureLogging(signalR.LogLevel.Error)
    .build();

  connection.start().then(function () {
    // console.log('Connected!');
    connection.invoke("addToGroup")
    connection.invoke("getMyNotifications")
  }).catch(function (err) {
    return console.error(err.toString());
  });
  return connection;
}