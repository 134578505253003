import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack"; // Corrigido
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

export default function ResumoResultadosCp(props) {
  const { cliente, sumFinalPrice, encargos, qtdPremium, decontoOfertaEstruturada } = props;

  return (
    <>
      <Grid item xs={12} sm={4} md={2} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
        <Typography variant='body2' gutterBottom>Área Soja</Typography>
        <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>
          {Number(cliente?.areaPlantadaSoja).toLocaleString("pt-BR")} Ha
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
        <Typography variant='body2' gutterBottom>Potencial</Typography>
        <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>
          {(Number(cliente.sojaPotencialCpUsd ?? 0)).toLocaleString("pt-BR", {
            style: "currency",
            currency: "USD",
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
        <Typography variant='body2' gutterBottom>SoC Crop:</Typography>
        <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>{(sumFinalPrice).toFixed(2)} %</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
        <Typography variant='body2' gutterBottom>#Produtos Premium:</Typography>
        <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>{qtdPremium}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
        <Typography variant='body2' gutterBottom>Desconto Oferta Estruturada %</Typography>
        <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>{(decontoOfertaEstruturada).toFixed(1)}%</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
        <Typography variant='body2' gutterBottom>Encargos % (+)</Typography>
        <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}> {encargos}% </Typography>
      </Grid>
    </>
  )
}
