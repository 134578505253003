import { useEffect, useState } from "react";
import { CellInputAlert } from "../../components/CellInputAlert";

export function CellInput({
  value,
  onChange = (value = 0) => { },
  ...props
}) {
  const [valueState, setValue] = useState(value);

  useEffect(() => {
    setValue(value)
  }, [value]);

  return <CellInputAlert
    value={valueState}
    onValueChange={(values) => setValue(Number(values.value))}
    onBlur={() => onChange(valueState)}
    {...props}
  />
}