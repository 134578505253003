import { FillLayer } from 'react-map-gl';

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': '#ffffff',
    'fill-opacity': 0.2
  },
};

export const municiLayer = {
  id: 'munici',
  type: 'line',
  paint: {
    'line-color': '#0072ce',
    'line-opacity': 1,
    'line-width': 2,
  },
};

export const assignedLineLayer = {
  id: 'assignedLine',
  type: 'line',
  paint: {
    'line-color': '#0072ce',
    'line-opacity': 1,
    'line-width': 2,
  },
};

export const accountLineLayer = {
  id: 'accountLine',
  type: 'line',
  paint: {
    'line-color': '#116e01',
    'line-opacity': 1,
    'line-width': 2,
  },
};

export const allAccountLineLayer = {
  id: 'allAccountLine',
  type: 'line',
  paint: {
    'line-color': '#ff0000',
    'line-opacity': 1,
    'line-width': 2,
  },
};

export const lineLayer = {
  id: 'line',
  type: 'line',
  paint: {
    'line-color': '#000',
    'line-opacity': 1,
    'line-width': 0.6,
  },
};

export const regionLayer = {
  id: 'region',
  type: 'line',
  paint: {
    'line-color': '#ffffff',
    'line-opacity': 0.5,
    'line-width': 2,
  },
};

export const selectedLayer = {
  id: 'selected',
  type: 'fill',
  paint: {
    'fill-outline-color': '#000000',
    'fill-color': '#fffb00',
    'fill-opacity': 1
  },
};
export const assignedLayer = {
  id: 'assigned',
  type: 'fill',
  paint: {
    'fill-outline-color': '#000000',
    'fill-color': '#002c69',
    'fill-opacity': 0.5
  },
};
export const allAssignedLayer = {
  id: 'allAssigned',
  type: 'fill',
  paint: {
    'fill-outline-color': '#660000',
    'fill-color': '#ff0000',
    'fill-opacity': 0.5
  },
};

export const accountLayer = {
  id: 'account',
  type: 'fill',
  paint: {
    'fill-outline-color': '#26ff00',
    'fill-color': '#116e01',
    'fill-opacity': 0.5
  },
};