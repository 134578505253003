import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import { Autocomplete, Backdrop, CircularProgress, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { cpaActions } from '../../../../../actions/cpa.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { userActions } from '../../../../../actions/user.actions';
import { cpaEstruturaActions } from '../../../../../actions/cpaEstruturaComercial.actions';
import { isNullOrEmpty } from '../../../../../helpers';
import { valorAreaLiderActions } from "../../../../../actions/valorAreaLider.actions";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

class ViewSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
    }
  }

  componentDidMount() {
    this.props.actions.initEstruturaForm();
    this.props.actions.requestRolesCpa();
    this.props.actions.requestRoleLevels();
  }

  setDrawerOpen = (open) => () => {
    this.setState({ drawerOpen: open });
  }

  render() {
    const role = this.props.cpaEstrutura.formData.role;
    const roleLevels = this.props.user?.roleLevels;

    return (
      <React.Fragment >
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <h6 className="mb-0">Selecione um modo de visualização para ver a página:</h6>
          <Button
            variant="outlined"
            color={"azul"}
            onClick={this.setDrawerOpen(true)}
            startIcon={<EditIcon />}
          >
            Editar Filtros
          </Button>
          {this.props.cpaEstrutura.formData.canView &&
            <>
              <Typography variant='body2' fontWeight={"bold"}>
                {role?.name}
                <Typography variant='body1' component={"span"}>
                  {
                    role.accessLevel >= roleLevels?.viewArea ?
                      ` | Area Comercial: ${this.props.cpaEstrutura.formData.area}` :
                      role.accessLevel >= roleLevels?.viewBusinessArea ?
                        ` | Business Area: ${this.props.cpaEstrutura.formData.businessArea}` :
                        ` | Distrito: ${this.props.cpaEstrutura.formData.distrito}`
                  }
                </Typography>
              </Typography>
            </>
          }
        </Stack>
        <Drawer
          anchor={'right'}
          open={this.state.drawerOpen}
          onClose={this.setDrawerOpen(false)}
        >
          <Backdrop
            sx={{ color: '#fff', position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(0,0,0,0.75)" }}
            open={this.props.user.isLoadingRoleLevels || this.props.user.isLoadingRolesCpa || this.props.cpaEstrutura.status === "loading"}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Box
            sx={{ width: "clamp(100px,100vw,350px)" }}
            role="presentation"
          >

            <DrawerHeader style={{ backgroundColor: "#e6e8ea" }}>
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                direction={'row'}
                spacing={2}
              >
                <IconButton sx={{ border: "1px solid" }} onClick={this.setDrawerOpen(false)}>
                  <ChevronLeftIcon />
                </IconButton>
                <h5>Selecione um filtro</h5>
              </Stack>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Nível de Acesso:"
                  secondary={
                    <React.Fragment>
                      <Autocomplete
                        disablePortal
                        size='small'
                        // defaultValue={areas[0]}
                        value={role}
                        options={this.props.user.rolesCpa.filter(role => role.accessLevel !== this.props.user?.roleLevels?.viewAll)}
                        noOptionsText="Nível não encontrado"
                        disableClearable={true}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        onChange={(event, newInputValue) => {
                          this.props.actions.clearFormEstruturaData();
                          this.props.actions.handleChangeEstruturaForm("role", newInputValue);
                          this.props.actions.GetEstruturaAreas();
                        }}
                        sx={{ maxWidth: 300 }}
                        renderInput={(params) => <TextField {...params} placeholder="Nível de Acesso" />}
                      />
                    </React.Fragment>
                  }
                  secondaryTypographyProps={{ component: "div", sx: { mt: 1 } }}
                />
              </ListItem>
            </List>
            <Divider />
            {role &&
              <>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Área Comercial:"
                      secondary={
                        <React.Fragment>
                          <Autocomplete
                            disablePortal
                            size='small'
                            value={this.props.cpaEstrutura.formData.area}
                            options={this.props.cpaEstrutura.areas.filter(x => !isNullOrEmpty(x))}
                            noOptionsText="Área não encontrada"
                            disableClearable={true}
                            onChange={(event, newInputValue) => {
                              this.props.actions.handleChangeEstruturaForm("canView", false);
                              this.props.actions.handleChangeEstruturaForm("area", newInputValue);
                              this.props.actions.handleChangeEstruturaForm("businessArea", null);
                              this.props.actions.handleChangeEstruturaForm("distrito", null);
                              if (role?.accessLevel >= roleLevels?.viewArea) {
                                this.props.actions.handleChangeEstruturaForm("canView", true);
                                this.props.actions.getOrCreateValorAreaLeader(newInputValue);
                              } else {
                                this.props.actions.GetEstruturaBusinessAreas(newInputValue);
                              }
                            }}
                            sx={{ maxWidth: 300 }}
                            renderInput={(params) => <TextField {...params} placeholder="Áreas" />}
                          />
                        </React.Fragment>
                      }
                      secondaryTypographyProps={{ component: "div" }}
                    />
                  </ListItem>
                </List>
                <Divider />
              </>
            }
            {role && !isNullOrEmpty(this.props.cpaEstrutura.formData.area) && role?.accessLevel <= roleLevels?.viewBusinessArea &&
              <>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Business Area:"
                      secondary={
                        <React.Fragment>
                          <Autocomplete
                            disablePortal
                            size='small'
                            value={this.props.cpaEstrutura.formData.businessArea}
                            options={this.props.cpaEstrutura.businessAreas.filter(x => !isNullOrEmpty(x))}
                            noOptionsText="Business Area não encontrada"
                            disableClearable={true}
                            onChange={(event, newInputValue) => {
                              this.props.actions.handleChangeEstruturaForm("canView", false);
                              this.props.actions.handleChangeEstruturaForm("businessArea", newInputValue);
                              this.props.actions.handleChangeEstruturaForm("distrito", null);
                              if (role?.accessLevel >= roleLevels?.viewBusinessArea) {
                                this.props.actions.handleChangeEstruturaForm("canView", true);
                              } else {
                                this.props.actions.GetEstruturaDistritos(this.props.cpaEstrutura.formData.area, newInputValue);
                              }
                            }}
                            sx={{ maxWidth: 300 }}
                            renderInput={(params) => <TextField {...params} placeholder="Business Area" />}
                          />
                        </React.Fragment>
                      }
                      secondaryTypographyProps={{ component: "div" }}
                    />
                  </ListItem>
                </List>
                <Divider />
              </>
            }
            {role && !isNullOrEmpty(this.props.cpaEstrutura.formData.businessArea) && role?.accessLevel < roleLevels?.viewBusinessArea &&
              <>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Distrito:"
                      secondary={
                        <React.Fragment>
                          <Autocomplete
                            disablePortal
                            size='small'
                            value={this.props.cpaEstrutura.formData.distrito}
                            options={this.props.cpaEstrutura.distritos.filter(x => !isNullOrEmpty(x))}
                            noOptionsText="Distrito não encontrado"
                            disableClearable={true}
                            onChange={(event, newInputValue) => {
                              this.props.actions.handleChangeEstruturaForm("distrito", newInputValue);
                              this.props.actions.handleChangeEstruturaForm("canView", true);
                            }}
                            sx={{ maxWidth: 300 }}
                            renderInput={(params) => <TextField {...params} placeholder="Distrito" />}
                          />
                        </React.Fragment>
                      }
                      secondaryTypographyProps={{ component: "div" }}
                    />
                  </ListItem>
                </List>
                <Divider />
              </>
            }
          </Box>
        </Drawer>
      </React.Fragment>
    );
  }
}
export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...cpaActions,
        ...cpaEstruturaActions,
        ...userActions,
        ...valorAreaLiderActions,
      }, dispatch),
    };
  }
)(ViewSelect);