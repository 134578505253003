const requestRolesType = "REQUEST_ROLE";
const receiveRolesType = "RECEIVE_ROLE";

const initialState = { roles: [], isLoading: false };

export const adminRoleActions = {
  requestRoles: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestRolesType });

    const url = `/api/admin/role`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const roles = await response.json();
    dispatch({ type: receiveRolesType, roles });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestRolesType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveRolesType) {
    return {
      ...state,
      roles: action.roles,
      isLoading: false,
    };
  }

  return state;
};
