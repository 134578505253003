import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function MapPanel(props) {
  const { handleResumeClose, countAssignedPlot,
    countPlot, valueAreaAssignedPlot, valueAreaPlot } = props;

  return (
    <React.Fragment>
      <TableContainer sx={{ borderRadius: "4px 4px 0px 0px" }}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
            <TableRow>
              <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", fontSize: "1.25rem", padding: "8px !important" }}>
                Resumo Talhões
              </TableCell>
              <TableCell sx={{ textAlign: "right !important" }}>
                <IconButton sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white" }}
                  onClick={handleResumeClose}>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer sx={{ height: "200px", borderRadius: "0px 0px 4px 4px" }}>
        <Table aria-label="collapsible table">
          <TableBody>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                {"Número Total: " + Number(countPlot).toLocaleString("pt-br")}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                {"Conhecidos: " + Number(countAssignedPlot).toLocaleString("pt-br")}
                {countAssignedPlot !== 0 ? " (" + Number((countAssignedPlot / countPlot) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                {"Desconhecidos: " + Number((countPlot - countAssignedPlot)).toLocaleString("pt-br")}
                {countAssignedPlot !== 0 ? " (" + Number(((countPlot - countAssignedPlot) / countPlot) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                {"Área Total: " + Number(Math.round(valueAreaPlot)).toLocaleString("pt-br") + " ha"}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                {"Área Conhecida: " + Number(Math.round(valueAreaAssignedPlot)).toLocaleString("pt-br") + " ha"}
                {valueAreaAssignedPlot !== 0 ? " (" + Number((valueAreaAssignedPlot / valueAreaPlot) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px" }}>
                {"Área Desconhecida: " + Number(Math.round(valueAreaPlot - valueAreaAssignedPlot)).toLocaleString("pt-br") + " ha"}
                {valueAreaAssignedPlot !== 0 ? " (" + Number(((valueAreaPlot - valueAreaAssignedPlot) / valueAreaPlot) * 100).toFixed(1).toLocaleString("pt-br") + "%)" : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}