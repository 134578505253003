const requestConditionsType = "REQUEST_CLIENT_CONDITIONS";
const receiveConditionsType = "RECEIVE_CLIENT_CONDITIONS";

const requestClientsList = "REQUEST_CLIENTS_LIST";
const receiveClientsList = "RECEIVE_CLIENTS_LIST";

const initialState = {
  clients: [],
  conditions: {},

  isLoadingClients: false,
  isLoadingClient: false,
  isLoadingConditions: false,
};

export const accountWhitespaceActions = {
  getClientConditions: (groupId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestConditionsType });

    const url = `/api/whitespaceOptions/clientConditions/${groupId}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const conditions = await response.json();
    dispatch({ type: receiveConditionsType, conditions: conditions });
  },
  setClientConditions: (groupId, data) => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/whitespaceOptions/setClientConditions/${groupId}`;

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch(accountWhitespaceActions.getClientsList());
    }
  },
  getClientsList: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestClientsList });

    const url = `/api/whitespaceOptions/clientsList`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const clients = await response.json();
    dispatch({ type: receiveClientsList, clients });
  },
  setGlobalConditions: (data) => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/whitespaceOptions/setGlobalConditions`;

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch(accountWhitespaceActions.getClientsList());
    }
  },
  setAllClientsConditions: (data) => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/whitespaceOptions/setAllClientConditions`;

    dispatch({ type: requestClientsList });

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch(accountWhitespaceActions.getClientsList());
    }
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestConditionsType:
      return {
        ...state,
        isLoadingConditions: true
      };
    case receiveConditionsType:
      return {
        ...state,
        conditions: action.conditions,
        isLoadingConditions: true
      };
    case requestClientsList:
      return {
        ...state,
        isLoadingClients: true
      };
    case receiveClientsList:
      return {
        ...state,
        clients: action.clients,
        isLoadingClients: false
      };
    default:
      return state;
  }
};
