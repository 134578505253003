const requestAllCampaignsType = "REQUEST_AllCampaigns";
const receiveAllCampaignsType = "RECEIVE_AllCampaigns";

const requestAllSeedsCampaign = "REQUEST_ALL_SEEDS_CAMPAIGNS";
const receiveAllSeedsCampaign = "RECEIVE_ALL_SEEDS_CAMPAIGNS";
const initialState = { AllCampaigns: [], AllSeedsCampaigns: [], isLoading: false };

export const AllCampaignsActions = {
  requestAllCampaigns: (paymentTerms, vencimento, campanha) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAllCampaignsType });
    const url = `/api/simuladorcampanhatodas`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const AllCampaigns = await response.json();
    dispatch({ type: receiveAllCampaignsType, AllCampaigns });
  },
  requestAllCampaigns2: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAllCampaignsType });
    const url = `/api/Simulador2/SimuladorCampanha/all`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const AllCampaigns = await response.json();
    dispatch({ type: receiveAllCampaignsType, AllCampaigns });
  },
  requestAllCampaignsSeeds: (a) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAllSeedsCampaign });
    const url = `/api/SimuladorCampanhaSeeds/all`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const AllCampaigns = await response.json();
    dispatch({ type: receiveAllSeedsCampaign, AllCampaigns });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestAllCampaignsType) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveAllCampaignsType) {
    return {
      ...state,
      AllCampaigns: action.AllCampaigns,
      isLoading: false,
    };
  }

  if (action.type === requestAllSeedsCampaign) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveAllSeedsCampaign) {
    return {
      ...state,
      AllSeedsCampaigns: action.AllCampaigns,
      isLoading: false,
    };
  }

  return state;
};
