import React from "react";
import {
  Table, TableBody, TableCell,
  TableHead, TableRow,
  Alert as MuiAlert, TextField,
  TableContainer, Paper,

}
  from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Virtualize from "../../../AutocompleteVirtual";
import { NumericFormat } from "react-number-format";
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import { toNegative } from "../../../../helpers/utils";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: "30px",
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
  },
}));

const CellInputAlert = ({ messageError, maxValue, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const isAllowed = (value, max) => {
    var number = Number(value.value)

    if (max === undefined)
      return true;

    if (number > max) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return number <= max;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (<HtmlTooltip placement="right" leaveDelay={600} open={open} onClose={handleClose}
    title={
      <React.Fragment>
        <Alert severity="error">
          {messageError}
        </Alert>
      </React.Fragment>
    }
  >
    <TableCell align="center">
      <NumericFormat
        {...props}
        isAllowed={(values, sourceInfo) => isAllowed(values, maxValue)}
      />
    </TableCell>
  </HtmlTooltip>
  );
}


const tableCells = [
  {
    id: 'produto',
    label: 'Produto',
    align: 'left',
    headStyle: { position: "sticky", left: "0px", zIndex: 20, backgroundColor: "#fff", borderRight: "1px solid #e0e0e0" },
    rowStyle: { position: "sticky", left: "0px", zIndex: 10, backgroundColor: "#fff", borderRight: "1px solid #e0e0e0" },
  },
  { id: 'ultimaNegociacao', label: 'Última Negociação', type: 'monetary', align: 'right' },
  { id: 'recomendacoes', label: 'Recomendações' },
  { id: 'salesInput', label: 'Sales Input (vol)', type: 'numeric' },
  { id: 'cota', label: 'Cota (vol)', type: 'numeric' },
  {
    id: 'sufixo',
    label: 'Sufixo',
    type: 'text',
  },
  {
    id: 'salesUnit',
    label: 'Sales Unit',
    align: 'left'
  },
  { id: 'precoSemente', label: 'Preço Semente', type: 'monetary', align: 'right' },
  { id: 'precoSt', label: 'Preço ST', type: 'monetary' },
  {
    id: 'encargo',
    label: 'Encargo',
    type: 'percent',
  },
  // {
  //   id: 'brDscCtgCliMax',
  //   label: 'BR Desc.Categ.Cli Máximo',
  //   type: 'percent',
  // },
  {
    id: 'brDscCtgCli',
    label: 'BR Desc.Categ.Cli',
    type: 'percent',
  },
  // {
  //   id: 'brDscCtgCliTrMax',
  //   label: 'BR Desc.Categ.Cli.Tr Máximo',
  //   type: 'percent',
  // },
  {
    id: 'brDscCtgCliTr',
    label: 'BR Desc.Categ.Cli.Tr',
    type: 'percent',
  },
  // {
  //   id: 'brDscPreCampMax',
  //   label: 'BR Desc. Pré-Camp Max',
  //   type: 'percent',
  // },
  {
    id: 'brDscPreCamp',
    label: 'BR Desc. Pré-Camp',
    type: 'percent',
  },
  // {
  //   id: 'brDscPreCvTrMax',
  //   label: 'BR Desc. Pré-CV Tratamento Max',
  //   type: 'percent',
  // },
  {
    id: 'brDscPreCvTr',
    label: 'BR Desc. Pré-CV Tratamento',
    type: 'percent',
  },
  // {
  //   id: 'brDscPenMax',
  //   label: 'BR Desc. Peneira Max',
  //   type: 'percent',
  // },
  {
    id: 'brDscPen',
    label: 'BR Desc. Peneira',
    type: 'percent',
  },
  // {
  //   id: 'brDscTrMax',
  //   label: 'BR Desc. Tratamento Max',
  //   type: 'percent',
  // },
  {
    id: 'brDscTr',
    label: 'BR Desc. Tratamento',
    type: 'percent',
  },
  // {
  //   id: 'brDscVolMax',
  //   label: 'BR Desc. sobre Vol Max',
  //   type: 'percent',
  // },
  {
    id: 'brDscVol',
    label: 'BR Desc. sobre Vol',
    type: 'percent',
  },
  // {
  //   id: 'brDscResCpMax',
  //   label: 'BR Desc. RES/Cooperante Max',
  //   type: 'percent',
  // },
  {
    id: 'brDscResCp',
    label: 'BR Desc. RES/Cooperante',
    type: 'percent',

  },
  // {
  //   id: 'brDscDirMax',
  //   label: 'BR Desc. Diretoria Max',
  //   type: 'percent',
  // },
  { id: 'brDscDirTr', label: 'BR Desc. Diretoria', type: 'percent' },
  // { id: 'brDscDirTrMax', label: 'BR Desc. Diretoria Tr Max', type: 'percent' },
  { id: 'brDscDir', label: 'BR Desc. Diretoria Tr', type: 'percent' },
  // { id: 'dscSobDsmpMax', label: 'Sob Desempenho Max', type: 'percent' },
  { id: 'dscSobDsmp', label: 'Sob Desempenho', type: 'percent' },
  // { id: 'dscGestInvMax', label: 'Gestão de Inventário Max', type: 'percent' },
  { id: 'dscGestInv', label: 'Gestão de Inventário', type: 'percent' },
  // { id: 'brDscVexMax', label: 'BR Desc. Verba Exc Max', type: 'percent' },
  { id: 'brDscVex', label: 'BR Desc. Verba Exc', type: 'percent' },
  // { id: 'brDscTgMax', label: 'BR Desc. Troca Grãos Max', type: 'percent' },
  { id: 'brDscTg', label: 'BR Desc. Troca Grãos', type: 'percent' },
  { id: 'precoCorteva', label: 'Preço Corteva', type: 'monetary' },
  { id: 'precoCliente', label: 'Preço p.p Cliente', type: 'monetary' },
  { id: 'volume', label: 'Volume', type: 'numeric' },
  { id: 'precoNegociado', label: 'Preço Negociado', type: 'monetary' },
  { id: 'brDscCpa', label: 'BR Desc. CPA', type: 'monetary' },
  { id: 'valorFinalCorteva', label: 'Valor Final Corteva', type: 'monetary' },
  { id: 'valorFinalNegociado', label: 'Valor Final', type: 'monetary' },
  // { id: 'frb', label: 'FRB', type: 'numeric' },
  { id: 'cur', label: 'CUR', type: 'numeric', isSensitive: true },
  { id: 'salesDeductionPer', label: 'Sales Deduction', type: 'percent', isNegative: true, isSensitive: true },
  { id: 'cogsSeeds', label: 'COGS SEMENTE (BRL)', type: 'monetary', isNegative: true, isSensitive: true },
  { id: 'cogsStPer', label: 'COGS ST (BRL)', type: 'monetary', isNegative: true, isSensitive: true },
  { id: 'gmTarget', label: 'GM Target', type: 'percent', isSensitive: true },
  // { id: 'maxDscCpa', label: 'CPA Máx Aprovado ($)', type: 'monetary' },
  { id: 'salesDeduction', label: 'Sales Deduction', type: 'monetary', isNegative: true, isSensitive: true },
  { id: 'netSales', label: 'Net Sales', type: 'monetary', isSensitive: true },
  { id: 'cogs', label: 'COGS', type: 'monetary', isNegative: true, isSensitive: true },
  { id: 'cogsSt', label: 'COGS ST (BRL)', type: 'monetary', isNegative: true, isSensitive: true },
  { id: 'gmStd', label: 'GM (STD)', type: 'monetary', isSensitive: true },
  { id: 'gmPer', label: '%GM', type: 'percent', isSensitive: true },
  { id: 'perAtingidoGm', label: '% Atingido GM', type: 'percent', isSensitive: true },
  // { id: 'perAtingidoCpa', label: '% Atingido CPA', type: 'percent' },
  { id: 'scoreGM', label: 'Score', type: 'score', isSensitive: true },
  { id: 'scoreProduto', label: 'Score do Produto', type: 'score', hidden: true, isSensitive: true },
];

export default function SimuladorTableSeeds(props) {
  const { section, actions, handleChangeSelect } = props

  const role = props.actions.user?.role?.role
  const viewSensitiveInformation = role?.workflowViewEconomyData

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: "1rem" }}>
        <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
          <Table stickyHeader sx={{ minWidth: 650, '* th': { textWrap: 'nowrap', fontWeight: "bold" } }} size="small">
            <TableHead sx={{ fontWeight: "bold" }}>
              <TableRow>
                {tableCells
                  .filter((head) => {
                    // Filtro para o TableHead
                    return (!head.isSensitive || viewSensitiveInformation) && !head.hidden;
                  })
                  .map((head, index) => (
                    <TableCell
                      key={head.id + head.label + "head" + index}
                      name={head.id + head.label + "head" + index}
                      align={head.align ?? 'center'}
                      sx={{ ...head.headStyle }}
                    >
                      {head.label}
                    </TableCell>
                  ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ maxHeight: 400 }}>
              {section.rows && section.rows
                .filter((row) => {
                  // Filtro para o TableBody
                  return !row.excluido;
                })
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {tableCells
                      .filter((head) => {
                        return (!head.isSensitive || viewSensitiveInformation) && !head.hidden;
                      })
                      .map((head, cellIndex) => {
                        let cellValue = row[head.id] ?? "";

                        if (!head.isInput) {
                          if (head.isNegative) {
                            cellValue = toNegative(cellValue);
                          }

                          switch (head.type) {
                            case 'numeric':
                              cellValue = cellValue ? cellValue : "0";
                              cellValue = cellValue.toLocaleString("pt-BR");
                              break;
                            case 'percent':
                              cellValue = cellValue ? cellValue : "0";
                              cellValue = cellValue.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%";
                              break;
                            case 'monetary':
                              cellValue = cellValue ? cellValue : "0";
                              cellValue = cellValue.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: section.moeda,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
                              break;
                            case 'score':
                              break;
                            default:
                              cellValue = cellValue ? cellValue : "--";
                              break;
                          }
                        } else {
                          cellValue = cellValue ? cellValue : 0;
                        }

                        return (
                          <TableCell
                            key={`${head.id}-${rowIndex}-${cellIndex}`}
                            align={head.align ?? 'center'}
                            sx={{ ...head.rowStyle, ...(head.type == 'numeric' || head.type == 'monetary' ? { textWrap: "nowrap" } : {}) }}
                          >
                            {head.isInput ?
                              head.type === 'select' ?
                                (
                                  <Virtualize
                                    sx={{
                                      color: "black",
                                      width: "120px",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      'input': {
                                        fontSize: "clamp(0.5rem, 2rem)",
                                        fontFamily: "Gilroy",
                                        fontWeight: "bold",
                                        maxWidth: "80%"
                                      },
                                      '*, ::after, ::before': {
                                        border: "none !important",
                                        padding: "0px !important"
                                      },
                                    }}
                                    options={row[head.optionData]}
                                    noOptionsText="Nenhum resultado encontrado..."
                                    getOptionLabel={(option) => option[head.optionLabel]}
                                    getValue={(option) => option[head.optionLabel]}
                                    onChange={(_, value) => handleChangeSelect(section.id, row.id, head.id, value)}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder={head.label} />
                                    )}
                                  />
                                ) :
                                (
                                  <CellInputAlert
                                    key={`${head.id}-${rowIndex}-${cellIndex}`}
                                    value={cellValue}
                                    onValueChange={(e, _) => actions.handleChangeValue(section.id, row.id, head.id, Number(e.value), props.state.groupId, section.condicao, props.state.distrito, props.state.classificacaoSeeds)}
                                    customInput={TextField}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    size="small"
                                    suffix={head.type === 'percent' ? "%" : ""}
                                    sx={{ ...head.rowStyle, input: { textAlign: "right", minWidth: "120px" } }}
                                    maxValue={row[head.id + "Max"] !== undefined ? Math.abs(row[head.id + "Max"]) : undefined}
                                    messageError={head.errorMessage}
                                    allowNegative={false}
                                    InputProps={{
                                      startAdornment: <> {row[head.id] > Math.abs(row[head.id + "Max"]) && <Tooltip title={head.errorMessage}><ErrorIcon color="laranja" /></Tooltip>}</>
                                    }}
                                  />
                                )
                              :
                              head.type === 'score' ?
                                <CircleIcon sx={{ color: `${cellValue === 0 ? "#C00000" : cellValue === 1 ? "#FFC000" : cellValue === 2 ? "#00B050" : "var(--bs-border-color)"}` }} />
                                :
                                cellValue
                            }
                          </TableCell>
                        );
                      })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
    </>
  );

}