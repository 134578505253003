const requestGetAllDiscountCategoryType = "REQUEST_GetAllDiscountCategory";
const receiveGetAllDiscountCategoryType = "RECEIVE_GetAllDiscountCategory";

const initialState = { GetAllDiscountCategory: [], isLoading: false };

export const GetAllDiscountCategoryActions = {
  requestGetAllDiscountCategory: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAllDiscountCategoryType });

    const url = `/api/simuladorcategorydiscount`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const GetAllDiscountCategory = await response.json();
    dispatch({ type: receiveGetAllDiscountCategoryType, GetAllDiscountCategory });
  },
  requestGetAllDiscountCategory2: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetAllDiscountCategoryType });

    const url = `/api/Simulador2/SimuladorDescontoCategoria`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const GetAllDiscountCategory = await response.json();
    dispatch({ type: receiveGetAllDiscountCategoryType, GetAllDiscountCategory });
  },
};

export const reducer = (state, action) => {

  state = state || initialState;

  if (action.type === requestGetAllDiscountCategoryType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveGetAllDiscountCategoryType) {
    return {
      ...state,
      GetAllDiscountCategory: action.GetAllDiscountCategory,
      isLoading: false,
    };
  }

  return state;
};
