import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CornGroupIcon(props) {
  return <SvgIcon viewBox="0 0 37 50" {...props} >
    <path d="M14.2531 40.1883C13.4625 28.593 9.31186 23.4981 7.33539 22.4001C7.44519 15.153 8.91657 0.658813 13.9236 0.658813C18.9307 0.658813 20.8413 14.9334 21.1707 22.0706C18.0084 22.0706 15.2413 34.1491 14.2531 40.1883Z" fill="#EFE8BA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2258 2.70914C11.1059 1.17372 12.3087 0 13.9237 0C15.527 0 16.7578 1.14526 17.6838 2.65652C18.6227 4.18872 19.3609 6.26981 19.9395 8.5245C21.0993 13.0442 21.663 18.4457 21.8289 22.0403L21.8607 22.7295H21.1708C20.996 22.7295 20.7573 22.8119 20.4467 23.0912C20.1365 23.3704 19.8051 23.8024 19.463 24.3856C18.7799 25.5502 18.1241 27.195 17.524 29.066C16.3268 32.7989 15.3943 37.2939 14.9033 40.2947L13.5958 40.2332C13.2053 34.5062 11.9873 30.418 10.6459 27.6446C9.29458 24.8508 7.84063 23.4344 7.01549 22.976L6.67072 22.7844L6.67669 22.3901C6.73198 18.7411 7.12936 13.2574 8.14998 8.66875C8.65904 6.38005 9.3332 4.26635 10.2258 2.70914ZM8.0009 22.0386C9.11556 22.7996 10.5529 24.4263 11.8321 27.0709C12.8764 29.23 13.828 32.092 14.4195 35.7754C14.9142 33.4584 15.5426 30.9292 16.2693 28.6636C16.8798 26.7601 17.5705 25.0078 18.3265 23.7189C18.7039 23.0755 19.1153 22.5167 19.5655 22.1117C19.8395 21.8652 20.1467 21.6594 20.484 21.5361C20.2899 18.0107 19.7342 13.0257 18.6632 8.85202C18.0971 6.64592 17.3982 4.71228 16.5603 3.34497C15.7096 1.95671 14.8239 1.31765 13.9237 1.31765C13.0351 1.31765 12.1764 1.9557 11.3689 3.3644C10.574 4.75133 9.93459 6.7141 9.4362 8.95483C8.47558 13.2737 8.07602 18.4483 8.0009 22.0386Z" fill="#020202" />
    <path d="M19.6504 23.4256C21.5424 21.2832 25.8444 21.0824 27.759 21.0824C26.7454 29.1161 26.7454 28.1119 26.0697 31.4593C25.537 34.0983 24.1354 39.5295 22.0154 41.8362C20.1825 43.8119 14.5825 45.987 14.5825 43.8447C14.5825 41.1668 14.5825 38.4888 14.9203 35.1414C15.2582 31.7941 17.2853 26.1035 19.6504 23.4256Z" fill="#1D9641" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.5062 20.4236H27.759C26.784 20.4236 25.1789 20.4742 23.5639 20.8052C21.9715 21.1316 20.2473 21.7544 19.1566 22.9895C17.8993 24.4131 16.7674 26.588 15.911 28.794C15.0522 31.0063 14.4415 33.3254 14.2648 35.0753C13.9237 38.4557 13.9237 41.1612 13.9237 43.8368V43.8447C13.9237 44.2697 14.0698 44.6584 14.3848 44.9432C14.6795 45.2097 15.0551 45.3243 15.4076 45.3666C16.1034 45.4502 16.9697 45.2827 17.813 45.012C19.5059 44.4686 21.4788 43.3834 22.4984 42.2843L22.5005 42.2821C23.6586 41.022 24.5744 38.9754 25.2606 36.9873C25.9548 34.9758 26.4453 32.9284 26.7155 31.5897C26.8673 30.8375 26.9853 30.3041 27.0884 29.8374C27.4443 28.228 27.6242 27.4139 28.4126 21.1649L28.5062 20.4236ZM27.0101 21.7522C26.3086 27.2642 26.1348 28.0498 25.7975 29.574C25.6947 30.0387 25.5767 30.5721 25.4239 31.329C25.1614 32.6292 24.6848 34.6169 24.015 36.5575C23.3374 38.5207 22.4928 40.3425 21.5313 41.3893C20.7176 42.2657 18.9749 43.2552 17.4102 43.7574C16.6244 44.0097 15.9761 44.1078 15.5647 44.0584C15.3635 44.0342 15.2873 43.9827 15.2686 43.9658C15.262 43.9599 15.259 43.9557 15.2555 43.9472C15.2513 43.9369 15.2413 43.9066 15.2413 43.8447C15.2413 41.167 15.2416 38.5187 15.5758 35.2076C15.7371 33.6102 16.3088 31.4103 17.1393 29.2709C17.9723 27.1253 19.0364 25.116 20.1442 23.8617C20.9455 22.9544 22.3183 22.4056 23.8285 22.096C24.9664 21.8628 26.1132 21.7788 27.0101 21.7522Z" fill="#020202" />
    <path d="M8.85575 23.4256C6.96374 21.2832 2.66167 21.0824 0.747131 21.0824C1.76071 29.1161 1.76071 28.1119 2.43643 31.4593C2.96915 34.0983 4.37067 39.5295 6.49074 41.8362C8.32362 43.8119 13.9236 45.987 13.9236 43.8447C13.9236 41.1668 13.9236 38.4888 13.5858 35.1414C13.2479 31.7941 11.2208 26.1035 8.85575 23.4256Z" fill="#1D9641" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 20.4236H0.747169C1.72216 20.4236 3.32728 20.4742 4.94222 20.8052C6.53468 21.1316 8.25888 21.7544 9.34961 22.9895C10.6069 24.4131 11.7388 26.588 12.5952 28.794C13.4539 31.0063 14.0647 33.3254 14.2413 35.0753C14.5825 38.4557 14.5825 41.1612 14.5825 43.8368V43.8447C14.5825 44.2697 14.4363 44.6584 14.1213 44.9432C13.8266 45.2097 13.4511 45.3243 13.0986 45.3666C12.4028 45.4502 11.5365 45.2827 10.6932 45.012C9.00029 44.4686 7.0274 43.3834 6.00779 42.2843L6.0057 42.2821C4.84758 41.022 3.93173 38.9754 3.24559 36.9873C2.55137 34.9758 2.06091 32.9284 1.79067 31.5897C1.63883 30.8375 1.52089 30.3041 1.41773 29.8374C1.06191 28.228 0.881932 27.4139 0.0935252 21.1649L0 20.4236ZM1.49611 21.7522C2.19755 27.2642 2.37138 28.0498 2.70864 29.574C2.81146 30.0387 2.92947 30.5721 3.08226 31.329C3.34474 32.6292 3.82141 34.6169 4.49114 36.5575C5.16872 38.5207 6.01332 40.3425 6.97484 41.3893C7.78858 42.2657 9.5313 43.2552 11.0959 43.7574C11.8817 44.0097 12.53 44.1078 12.9415 44.0584C13.1427 44.0342 13.2189 43.9827 13.2376 43.9658C13.2442 43.9599 13.2472 43.9557 13.2506 43.9472C13.2549 43.9369 13.2649 43.9066 13.2649 43.8447C13.2649 41.167 13.2645 38.5187 12.9303 35.2076C12.7691 33.6102 12.1973 31.4103 11.3668 29.2709C10.5339 27.1253 9.46973 25.116 8.36197 23.8617C7.56069 22.9544 6.18784 22.4056 4.67763 22.096C3.53981 21.8628 2.39295 21.7788 1.49611 21.7522Z" fill="#020202" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9022 4.94121L11.9472 0.980774L13.2648 0.995746L13.2199 4.94121H15.2413V1.31767H16.5589V4.94121H18.206V6.25886H16.5589V9.22358H19.5237V10.5412H16.5589V12.5177H20.1825V13.8354H16.5589V16.8001H20.8413V18.1177H16.5589V20.4236H21.1707V21.7413H16.5589V24.0472H18.5354V25.3648H16.5589V28.0001H16.8884V29.3178H16.5589V30.6354H15.2413V29.3178H12.9429L12.9354 29.9841L11.6178 29.9691L11.6252 29.3178H11.6178V28.0001H11.6402L11.6701 25.3648H9.64128V24.0472H11.6851L11.7113 21.7413H7.33539V20.4236H11.7263L11.7525 18.1177H7.6648V16.8001H11.7674L11.8011 13.8354H7.99421V12.5177H11.8161L11.8386 10.5412H8.65304V9.22358H11.8535L11.8872 6.25886H9.64128V4.94121H11.9022ZM13.205 6.25886L13.1713 9.22358H15.2413V6.25886H13.205ZM15.2413 10.5412H13.1563L13.1338 12.5177H15.2413V10.5412ZM15.2413 13.8354H13.1189L13.0852 16.8001H15.2413V13.8354ZM15.2413 18.1177H13.0702L13.044 20.4236H15.2413V18.1177ZM15.2413 21.7413H13.029L13.0028 24.0472H15.2413V21.7413ZM15.2413 25.3648H12.9879L12.9579 28.0001H15.2413V25.3648ZM12.9354 31.6237H15.5707V32.9413H12.9354V31.6237Z" fill="black" />
    <path d="M22.1589 44.8001C21.3683 33.2048 17.2177 28.1099 15.2413 27.0118C15.3511 19.7647 16.8225 5.27057 21.8295 5.27057C26.8366 5.27057 28.7472 19.5451 29.0766 26.6824C25.9142 26.6824 23.1472 38.7609 22.1589 44.8001Z" fill="#EFE8BA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.1317 7.3209C19.0118 5.78548 20.2146 4.61176 21.8296 4.61176C23.4329 4.61176 24.6636 5.75701 25.5897 7.26827C26.5286 8.80048 27.2668 10.8816 27.8454 13.1363C29.0052 17.656 29.5689 23.0574 29.7348 26.652L29.7666 27.3412H29.0767C28.9019 27.3412 28.6631 27.4237 28.3526 27.703C28.0424 27.9821 27.711 28.4141 27.3689 28.9974C26.6858 30.162 26.03 31.8067 25.4299 33.6778C24.2326 37.4106 23.3002 41.9057 22.8092 44.9065L21.5017 44.8449C21.1112 39.118 19.8932 35.0298 18.5518 32.2564C17.2005 29.4626 15.7465 28.0462 14.9214 27.5877L14.5766 27.3962L14.5826 27.0018C14.6379 23.3528 15.0352 17.8691 16.0559 13.2805C16.5649 10.9918 17.2391 8.8781 18.1317 7.3209ZM15.9068 26.6503C17.0214 27.4114 18.4588 29.038 19.738 31.6826C20.7823 33.8418 21.7338 36.7037 22.3254 40.3872C22.8201 38.0702 23.4485 35.541 24.1752 33.2753C24.7857 31.3718 25.4764 29.6195 26.2323 28.3307C26.6098 27.6872 27.0212 27.1284 27.4714 26.7234C27.7454 26.4769 28.0525 26.2712 28.3899 26.1479C28.1958 22.6225 27.6401 17.6374 26.5691 13.4638C26.003 11.2577 25.3041 9.32404 24.4662 7.95673C23.6155 6.56847 22.7298 5.92941 21.8296 5.92941C20.941 5.92941 20.0823 6.56745 19.2748 7.97616C18.4799 9.36308 17.8405 11.3259 17.3421 13.5666C16.3815 17.8855 15.9819 23.06 15.9068 26.6503Z" fill="#020202" />
    <path d="M27.5562 28.0373C29.4482 25.895 33.7503 25.6942 35.6649 25.6942C34.6513 33.7279 34.6513 32.7237 33.9756 36.0711C33.4428 38.7101 32.0413 44.1413 29.9212 46.448C28.0884 48.4236 22.4883 50.5988 22.4883 48.4564C22.4883 45.7785 22.4883 43.1006 22.8262 39.7532C23.1641 36.4058 25.1912 30.7152 27.5562 28.0373Z" fill="#1D9641" />
    <path fillRule="evenodd" clipRule="evenodd" d="M36.412 25.0353H35.6649C34.6899 25.0353 33.0848 25.0859 31.4698 25.417C29.8774 25.7434 28.1532 26.3662 27.0624 27.6012C25.8052 29.0248 24.6733 31.1998 23.8169 33.4058C22.9581 35.618 22.3474 37.9371 22.1707 39.6871C21.8295 43.0675 21.8295 45.773 21.8295 48.4485V48.4564C21.8295 48.8815 21.9757 49.2701 22.2907 49.5549C22.5854 49.8214 22.961 49.9361 23.3135 49.9784C24.0093 50.0619 24.8755 49.8945 25.7189 49.6238C27.4118 49.0803 29.3846 47.9951 30.4043 46.8961L30.4063 46.8938C31.5645 45.6337 32.4803 43.5872 33.1665 41.5991C33.8607 39.5876 34.3511 37.5402 34.6214 36.2014C34.7732 35.4493 34.8912 34.9158 34.9943 34.4492C35.3501 32.8397 35.5301 32.0256 36.3185 25.7766L36.412 25.0353ZM34.9159 26.3639C34.2145 31.8759 34.0407 32.6615 33.7034 34.1857C33.6006 34.6505 33.4826 35.1838 33.3298 35.9407C33.0673 37.241 32.5906 39.2287 31.9209 41.1692C31.2433 43.1325 30.3987 44.9543 29.4372 46.0011C28.6235 46.8774 26.8808 47.8669 25.3161 48.3692C24.5303 48.6214 23.882 48.7195 23.4706 48.6701C23.2694 48.646 23.1931 48.5945 23.1744 48.5776C23.1679 48.5717 23.1648 48.5674 23.1614 48.559C23.1572 48.5487 23.1472 48.5183 23.1472 48.4564C23.1472 45.7788 23.1475 43.1304 23.4817 39.8194C23.643 38.2219 24.2147 36.022 25.0452 33.8826C25.8782 31.737 26.9423 29.7278 28.0501 28.4735C28.8514 27.5662 30.2242 27.0174 31.7344 26.7078C32.8722 26.4745 34.0191 26.3905 34.9159 26.3639Z" fill="#020202" />
    <path d="M16.7616 28.0373C14.8696 25.895 10.5676 25.6942 8.65302 25.6942C9.66659 33.7279 9.66659 32.7237 10.3423 36.0711C10.875 38.7101 12.2766 44.1413 14.3966 46.448C16.2295 48.4236 21.8295 50.5988 21.8295 48.4564C21.8295 45.7785 21.8295 43.1006 21.4917 39.7532C21.1538 36.4058 19.1267 30.7152 16.7616 28.0373Z" fill="#1D9641" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.90588 25.0353H8.65305C9.62805 25.0353 11.2332 25.0859 12.8481 25.417C14.4406 25.7434 16.1648 26.3662 17.2555 27.6012C18.5127 29.0248 19.6447 31.1998 20.501 33.4058C21.3598 35.618 21.9706 37.9371 22.1472 39.6871C22.4884 43.0675 22.4884 45.773 22.4884 48.4485V48.4564C22.4884 48.8815 22.3422 49.2701 22.0272 49.5549C21.7325 49.8214 21.3569 49.9361 21.0045 49.9784C20.3087 50.0619 19.4424 49.8945 18.5991 49.6238C16.9062 49.0803 14.9333 47.9951 13.9137 46.8961L13.9116 46.8938C12.7535 45.6337 11.8376 43.5872 11.1515 41.5991C10.4573 39.5876 9.96679 37.5402 9.69655 36.2014C9.54472 35.4493 9.42678 34.9158 9.32362 34.4492C8.96779 32.8397 8.78782 32.0256 7.99941 25.7766L7.90588 25.0353ZM9.402 26.3639C10.1034 31.8759 10.2773 32.6615 10.6145 34.1857C10.7173 34.6505 10.8354 35.1838 10.9881 35.9407C11.2506 37.241 11.7273 39.2287 12.397 41.1692C13.0746 43.1325 13.9192 44.9543 14.8807 46.0011C15.6945 46.8774 17.4372 47.8669 19.0018 48.3692C19.7876 48.6214 20.4359 48.7195 20.8474 48.6701C21.0485 48.646 21.1248 48.5945 21.1435 48.5776C21.15 48.5717 21.1531 48.5674 21.1565 48.559C21.1608 48.5487 21.1707 48.5183 21.1707 48.4564C21.1707 45.7788 21.1704 43.1304 20.8362 39.8194C20.675 38.2219 20.1032 36.022 19.2727 33.8826C18.4398 31.737 17.3756 29.7278 16.2679 28.4735C15.4666 27.5662 14.0937 27.0174 12.5835 26.7078C11.4457 26.4745 10.2988 26.3905 9.402 26.3639Z" fill="#020202" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.8081 9.55297L19.8531 5.59253L21.1707 5.6075L21.1258 9.55297H23.1472V5.92943H24.4648V9.55297H26.1119V10.8706H24.4648V13.8353H27.4295V15.153H24.4648V17.1295H28.0884V18.4471H24.4648V21.4118H28.7472V22.7295H24.4648V25.0354H29.0766V26.353H24.4648V28.6589H26.4413V29.9766H24.4648V32.6119H24.7942V33.9295H24.4648V35.2472H23.1472V33.9295H20.8488L20.8412 34.5958L19.5237 34.5809L19.5311 33.9295H19.5236V32.6119H19.5461L19.576 29.9766H17.5472V28.6589H19.591L19.6172 26.353H15.2413V25.0354H19.6322L19.6584 22.7295H15.5707V21.4118H19.6733L19.707 18.4471H15.9001V17.1295H19.722L19.7445 15.153H16.5589V13.8353H19.7594L19.7931 10.8706H17.5472V9.55297H19.8081ZM21.1109 10.8706L21.0772 13.8353H23.1472V10.8706H21.1109ZM23.1472 15.153H21.0622L21.0397 17.1295H23.1472V15.153ZM23.1472 18.4471H21.0248L20.9911 21.4118H23.1472V18.4471ZM23.1472 22.7295H20.9761L20.9499 25.0354H23.1472V22.7295ZM23.1472 26.353H20.9349L20.9087 28.6589H23.1472V26.353ZM23.1472 29.9766H20.8937L20.8638 32.6119H23.1472V29.9766ZM20.8413 36.2354H23.4766V37.5531H20.8413V36.2354Z" fill="black" />
  </SvgIcon>
}