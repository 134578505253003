
import React from "react";
import { IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import AllInboxIcon from '@mui/icons-material/AllInbox';

const ChangeStockMenu = (props) => {
  const { actions, role, rowsIds, sort } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={"Acionar todos estoques"}>
        <IconButton
          aria-label={"Acionar todos estoques"}
          color={"white"}
          onClick={handleClick}
        >
          <AllInboxIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="change-all-stock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'change-all-stock-btn',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={sort}>
          Ordenar
        </MenuItem>
        {role && role.includes("Sales Representative") && (
          [
            <MenuItem onClick={() => { handleClose(); actions.handleConfirmAllStock(0, rowsIds) }}>
              Apontar Todos Novamente
            </MenuItem>,
            <MenuItem onClick={() => { handleClose(); actions.handleConfirmAllStock(1, rowsIds) }}>
              Validar Todos
            </MenuItem>
          ]
        )}
        {role && role.includes("DSM") && (
          [
            <MenuItem onClick={() => { handleClose(); actions.handleConfirmAllStock(1, rowsIds) }}>
              Apontar Todos Novamente
            </MenuItem>,
            <MenuItem onClick={() => { handleClose(); actions.handleConfirmAllStock(2, rowsIds) }}>
              Aprovar Todos
            </MenuItem>
          ]
        )}
        {role && (role.includes("Admin") || role.includes("KAM")) && (
          [
            <MenuItem onClick={() => { handleClose(); actions.handleConfirmAllStock(0, rowsIds) }}>
              Alterar Todos
            </MenuItem>,
            <MenuItem onClick={() => { handleClose(); actions.handleConfirmAllStock(2, rowsIds) }}>
              Aprovar Todos
            </MenuItem>
          ]
        )}
      </Menu>
    </div>
  )
}

export default ChangeStockMenu;