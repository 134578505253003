import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Card, CardContent, CardMedia, Grid, Stack, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { adminUserActions } from '../../../../actions/admin/user.actions';
import { useFormik } from 'formik';
import { adminModuloActions } from '../../../../actions/admin/modulo.actions';
import { adminRoleActions } from '../../../../actions/admin/role.actions';
import TransferList from '../../../EnchantedTransferList';
import { adminTerritoryActions } from '../../../../actions/admin/territory.actions';
import AllowUseNavigate, { navigate } from '../../../Navigate';

const camposTexto = [
  { texto: "Descrição", prop: "descricao" },
  { texto: "Url", prop: "redirectUrl" },
  { texto: "Url da Imagem", prop: "imageUrl" },
];


function FormEdit({
  modulo = {},
  users = [],
  send = (modulo) => { },
  ...props }
) {
  const [moduloConst, setModulo] = React.useState(null);
  const [usersConst, setUsers] = React.useState(null);
  const [modulosUser, setModulosUser] = React.useState([]);
  const [modulosNotUser, setModulosNotUser] = React.useState([]);

  const formik = useFormik({
    initialValues: modulo,
    validate: (values) => {
      const errors = {};
      if (!values.descricao) {
        errors.descricao = 'Insira uma descrição';
      }
      if (!values.redirectUrl) {
        errors.redirectUrl = 'Insira uma url para o usuário ser redirecionado';
      }
      if (!values.imageUrl) {
        errors.imageUrl = 'Insira uma imagem para o módulo';
      }
      return errors;
    },
    onSubmit: (values) => {
      values.usuarios = modulosUser.map(x => { return x.userId })
      send(values);
    },
  });

  if (moduloConst !== modulo || usersConst !== users) {
    setModulo(modulo);
    setUsers(users);
    setModulosUser(users.filter(x => formik.values?.userModulos?.find(y => y.userId === x.userId) !== undefined));
    setModulosNotUser(users.filter(x => formik.values?.userModulos?.find(y => y.userId === x.userId) === undefined));
  }

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: "8px 0" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ width: "100% !important" }} direction="row-reverse" xl="auto" justifyContent="center" alignItems="flex-start" className="mt-4 mb-5">
        <Grid item xs={2} sm={8} md={6} justifyContent="center" alignItems="center" sx={{ paddingLeft: { xs: "0 !important", sm: "0 !important", md: "16px" } }}>
          <Card sx={{ maxWidth: "90%", margin: "0 auto", display: "flex", flexDirection: "column" }} >
            <CardMedia
              component="img"
              image={`${formik.values.imageUrl}`}
              alt={`${formik.values.descricao}`}
              sx={{ height: { xs: "calc((100vw * 0.9) * 0.381944)", sm: "calc((100vw * 0.9) * 0.381944)", md: "calc((((100vw - 200px) / 2) * 0.9) * 0.381944)" }, width: "auto", minWidth: "100%" }}
            />
            <CardContent sx={{ backgroundColor: "#61616166", width: "100%" }}>
              <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: "center", color: "#626262", fontWeight: "bold", textDecoration: "none" }}>
                {formik.values.descricao}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2} sm={8} md={6} justifyContent="center" alignItems="center" sx={{ paddingLeft: { xs: "0 !important", sm: "0 !important", md: "16px" } }}>
          {camposTexto.map((x, index) => (
            <TextField
              key={index}
              color={'azul'}
              fullWidth
              id={x.prop}
              name={x.prop}
              label={x.texto}
              variant="standard"
              value={formik.values[x.prop]}
              onChange={formik.handleChange}
              error={formik.touched[x.prop] && Boolean(formik.errors[x.prop])}
              helperText={formik.touched[x.prop] && formik.errors[x.prop]}
            />
          ))
          }
        </Grid>
      </Grid>

      <TransferList left={modulosNotUser}
        setLeft={setModulosNotUser}
        leftTitle={"Usuários Disponíveis"}
        property={"email"}
        getLabel={(value) => { return value.email }}
        right={modulosUser}
        setRight={setModulosUser}
        rightTitle={"Usuários Alocados"}
        buttonVariant={'contained'}
      />
      <Button color="azul" variant="contained" fullWidth type="submit">
        Salvar
      </Button>
    </Box>
  );
}

class AdminModuloEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.sendUser = this.sendUser.bind(this);
  }
  componentWillUnmount() {
    this.props.actions.clearModulo();
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.MolduloId !== undefined && params.MolduloId !== '')
      this.props.actions.requestModulo(params.MolduloId)
    this.props.actions.requestUsers();
  }

  sendUser(modulo) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.MolduloId !== undefined && params.MolduloId !== '')
      this.props.actions.updateModulo(params.MolduloId, modulo, navigate);
    else
      this.props.actions.saveModulo(modulo, navigate);
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var isLoading = this.props.adminUser.isLoading ||
      this.props.adminModulo.isLoadingModulo
      ;

    var isDataLoad = this.props.adminModulo /*&& this.props.adminUser.user != null*/;

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin-Modulo"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Modulo"}>
                Módulos
              </Link>
              <Typography color="text.primary">{(params.MolduloId !== undefined && params.MolduloId !== '') ? "Editar" : "Novo"}</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {!isLoading && isDataLoad &&
            <FormEdit
              modulo={this.props.adminModulo.modulo ?? {}}
              users={this.props.adminUser.users}
              send={this.sendUser}
            />
          }
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminUserActions,
        ...adminModuloActions,
        ...adminRoleActions,
        ...adminTerritoryActions,
      }, dispatch),
    };
  }
)(AdminModuloEditPage);
