import { WebStorageStateStore } from "oidc-client";
import { createUserManager } from "redux-oidc";

const clientId = process.env.REACT_APP_CLIENTID; //GUID Client Application ID
const tenantId = process.env.REACT_APP_TENANTID; //GUID Azure Tenant ID
const resourceId = process.env.REACT_APP_RESOURCEID; //GUID API Application ID

const userManagerConfig = {
  authority: `https://login.microsoftonline.com/${tenantId}/.well-known/openid-configuration`,
  client_id: clientId,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }/callback`,
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}`,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: false,
  scope: "openid profile",
  response_type: "token id_token",
  extraQueryParams: {
    resource: resourceId,
  },
  signingKeys: [],
  userStore: new WebStorageStateStore({ store: localStorage }),
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
