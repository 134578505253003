import { GetCenarioActions } from "./getcenario.actions";

const requestCenariosType = "REQUEST_CENARIOS";
const receiveCenarioslType = "RECEIVE_CENARIOS";
const deletarRequestCenarioslType = "REQUEST_DELETE_CENARIOS";
const deletarCompleteCenarioslType = "COMPLETE_DELETE_CENARIOS";
const cancelarRequestCenarioslType = "REQUEST_CANCEL_CENARIOS";
const cancelarCompleteCenarioslType = "COMPLETE_CANCEL_CENARIOS";
const enviarRequestCenarioslType = "REQUEST_ENVIAR_CENARIO";
const enviarCompleteCenarioslType = "COMPLETE_ENVIAR_CENARIO";
const downloadRequestCenarioslType = "REQUEST_DOWNLOAD_CENARIO";
const downloadCompleteCenarioslType = "COMPLETE_DOWNLOAD_CENARIO";
const initialState = { listCenarios: [], isLoading: false, isLoadingEnvio: false, file: null, isLoadingDownload: true };

export const cenariosActions = {
  requestCenarios: (clientId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCenariosType });

    const url = `/api/cenario/${clientId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const listCenarios = await response.json();
    dispatch({ type: receiveCenarioslType, listCenarios });
  },
  requestAllCenarios: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCenariosType });

    const url = `/api/cenario`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const listCenarios = await response.json();
    dispatch({ type: receiveCenarioslType, listCenarios });
  },
  deleteCenario: (id, func, arg1 = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: deletarRequestCenarioslType });

    const url = `/api/cenario/desativar/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();
    dispatch(func(arg1))
    dispatch({ type: deletarCompleteCenarioslType });
  },
  cancelCenario: (id, motivo, func, arg1 = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: cancelarRequestCenarioslType });

    const url = `/api/cenario/cancelar/${id}`;

    const options = {
      method: "PUT",
      body: motivo,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();
    dispatch(func(arg1))
    dispatch({ type: cancelarCompleteCenarioslType });
  },
  enviarCenario: (clientId, id, comentario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enviarRequestCenarioslType });

    const url = `/api/cenario/envioAprovacao/${id}`;

    const options = {
      method: "POST",
      body: comentario,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();
    dispatch({ type: enviarCompleteCenarioslType });
    dispatch(cenariosActions.requestCenarios(clientId));
  },
  aprovarCenario: (id, comentario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enviarRequestCenarioslType });

    const url = `/api/cenario/aprovar/${id}`;

    const options = {
      method: "POST",
      body: comentario,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();
    dispatch({ type: enviarCompleteCenarioslType });
    dispatch(GetCenarioActions.GetCenario(id));
  },
  reprovarCenario: (id, comentario) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enviarRequestCenarioslType });

    const url = `/api/cenario/reprovar/${id}`;

    const options = {
      method: "POST",
      body: comentario,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();
    dispatch({ type: enviarCompleteCenarioslType });
    dispatch(GetCenarioActions.GetCenario(id));
  }

};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestCenariosType) {
    return {
      ...state,
      listCenarios: [],
      isLoading: true,
    };
  }
  if (action.type === receiveCenarioslType) {
    return {
      ...state,
      listCenarios: action.listCenarios,
      isLoading: false,
    };
  }
  if (action.type === deletarRequestCenarioslType) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === deletarCompleteCenarioslType) {
    return {
      ...state,
      isLoading: false,
    }
  }
  if (action.type === cancelarRequestCenarioslType) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === cancelarCompleteCenarioslType) {
    return {
      ...state,
      isLoading: false,
    }
  }
  if (action.type === enviarRequestCenarioslType) {
    return {
      ...state,
      isLoadingEnvio: true,
    }
  }
  if (action.type === enviarCompleteCenarioslType) {
    return {
      ...state,
      isLoadingEnvio: false,
    }
  }
  if (action.type === downloadRequestCenarioslType) {
    return {
      ...state,
      isLoadingDownload: true,
    }
  }
  if (action.type === downloadCompleteCenarioslType) {
    return {
      ...state,
      isLoadingDownload: false,
      file: action.file
    }
  }

  return state;
};
