const requestpacksType = "REQUEST_PACKS";
const receivepacksType = "RECEIVE_PACKS";
const initialState = { packs: [], isLoading: false };

export const packActions = {
  requestPacks: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestpacksType });

    const url = `/api/packs`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const packs = await response.json();
    dispatch({ type: receivepacksType, packs });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestpacksType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receivepacksType) {
    return {
      ...state,
      packs: action.packs,
      isLoading: false,
    };
  }

  return state;
};
