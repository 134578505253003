const requestCultures = "REQUEST_CULTURES";
const receiveCultures = "RECEIVE_CULTURES";
const receiveListCultures = "RECEIVE_LIST_CULTURES";

const requestCulture = "REQUEST_CULTURE";
const receiveCulture = "RECEIVE_CULTURE";

const requestSaveCulture = "REQUEST_SAVE_CULTURE";
const receiveSaveCulture = "RECEIVE_SAVE_CULTURE";

const cleanCulture = "CLEAN_DATA";

const requestIrrigations = "REQUEST_IRRIGATIONS";
const receiveIrrigations = "RECEIVE_IRRIGATIONS";

const requestCulturesGoal = "REQUEST_CULTURES_GOAL";
const receiveCulturesGoal = "RECEIVE_CULTURES_GOAL";

const changeCultureColor = "CHANGE_CULTURE_COLOR";
const changeFillVariable = "CHANGE_FILL_VARIABLE";

const initialState =
{
  culture: undefined,
  cultures: [],
  culturesGoal: [],

  plotLineLayer: undefined,
  plotLayer: undefined,

  plotApontLayer: undefined,
  plotPreditLayer: undefined,
  stateLayer: undefined,

  irrigations: undefined,

  isLoading: false,
};

export const culturasWhitespaceActions = {
  //PAINEL DE CONTROLE DE CULTURAS
  requestCultures: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCultures });

    const url = `/api/CulturasWhitespace/GetCultures`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const cultures = await response.json();
    dispatch({ type: receiveCultures, cultures });
  },
  requestListCultures: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCultures });

    const url = `/api/CulturasWhitespace/GetListCultures`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const cultures = await response.json();
    dispatch({ type: receiveListCultures, cultures });
  },
  getCulture: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCulture });

    const url = `/api/CulturasWhitespace/GetCulture/${id}`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const culture = await response.json();
    dispatch({ type: receiveCulture, culture });
  },
  setCulture: (id, culture, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSaveCulture });

    const url = `/api/CulturasWhitespace/SaveCulture/${id}`;

    const options = {
      method: "POST",
      body: JSON.stringify(culture),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-Culture');
      dispatch({ type: receiveSaveCulture });
      dispatch(culturasWhitespaceActions.requestListCultures());
    }
  },
  saveCulture: (culture, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSaveCulture });

    const url = `/api/CulturasWhitespace/Create`;

    const options = {
      method: "POST",
      body: JSON.stringify(culture),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-Culture');
      dispatch({ type: receiveSaveCulture });
      dispatch(culturasWhitespaceActions.requestListCultures());
    }
  },
  toggleCulture: (id, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/CulturasWhitespace/ToggleCulture/${id}`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch(culturasWhitespaceActions.requestListCultures());
    }
  },
  cleanCulture: () => async (dispatch, getState) => {
    dispatch({ type: cleanCulture });
  },

  //FINALIDADES DE CULTURAS
  requestCulturesGoal: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCulturesGoal });

    const url = `/api/CulturasWhitespace/GetGoals`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const culturesGoal = await response.json();
    dispatch({ type: receiveCulturesGoal, culturesGoal });
  },

  //TIPOS DE IRRIGAÇÃO
  requestTypesIrrigation: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestIrrigations });

    const url = `/api/CulturasWhitespace/GetIrrigations`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const irrigations = await response.json();
    dispatch({ type: receiveIrrigations, irrigations });
  },

  //ITERAÇÕES VIA MAPA
  changeColorCulture: (culture) => async (dispatch, getState) => {
    dispatch({ type: changeCultureColor, culture });
  },

  changeFillVariable: (culture) => async (dispatch, getState) => {
    dispatch({ type: changeCultureColor, culture });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCultures:
      return {
        ...state,
        isLoading: true
      };
    case receiveCultures:
      var stops = action.cultures.map(item => [item.value, item.color]);
      var stopsOpacity = action.cultures.map(item => [item.value, Number(item.opacity)]);

      const plotLineLayer = {
        id: 'plotLine',
        type: 'line',
        paint: {
          'line-color': {
            property: "CULT_PREDIT",
            type: 'categorical',
            default: '#000000',
            stops: stops,
          },
          'line-opacity': {
            property: "CULT_PREDIT",
            type: 'categorical',
            default: 1,
            stops: [
              ["", 0]
            ]
          },
          'line-width': {
            property: "CULT_PREDIT",
            type: 'categorical',
            default: 1,
            stops: [
              ["", 0.3]
            ]
          },
        },
      };

      const plotLayer = {
        id: 'plot',
        type: 'fill',
        paint: {
          'fill-color': {
            property: "CULT_APONT",
            type: 'categorical',
            default: '#ffffff',
            stops: stops,
          },
          'fill-opacity': {
            property: "CULT_APONT",
            type: 'categorical',
            default: 0.2,
            stops: stopsOpacity,
          },
        },
      };

      const plotPreditLayer = {
        id: 'plot',
        type: 'fill',
        paint: {
          'fill-color': {
            property: "CULT_PREDIT",
            type: 'categorical',
            default: '#ffffff',
            stops: stops,
          },
          'fill-opacity': {
            property: "CULT_PREDIT",
            type: 'categorical',
            default: 0.2,
            stops: stopsOpacity,
          }
        },
      };

      const plotApontLayer = {
        id: 'plot',
        type: 'fill',
        paint: {
          'fill-color': {
            property: "CULT_APONT",
            type: 'categorical',
            default: '#ffffff',
            stops: stops,
          },
          'fill-opacity': {
            property: "CULT_APONT",
            type: 'categorical',
            default: 0.2,
            stops: stopsOpacity,
          },
        },
      };

      return {
        ...state,
        cultures: action.cultures,
        plotLayer: plotLayer,
        plotLineLayer: plotLineLayer,
        plotPreditLayer: plotPreditLayer,
        plotApontLayer: plotApontLayer,
        isLoading: false
      };
    case receiveListCultures:
      return {
        ...state,
        cultures: action.cultures,
        isLoading: false
      };
    case requestCulture:
      return {
        ...state,
        isLoading: true
      };
    case receiveCulture:
      return {
        ...state,
        culture: action.culture,
        isLoading: false
      };
    case requestSaveCulture:
      return {
        ...state,
        isLoading: true
      };
    case receiveSaveCulture:
      return {
        ...state,
        isLoading: false
      };
    case cleanCulture:
      return {
        ...state,
        culture: undefined,
        cultures: [],
        plotLayer: undefined,
        plotLineLayer: undefined,
        isLoading: false
      };
    case requestIrrigations:
      return {
        ...state
      };
    case receiveIrrigations:
      return {
        ...state,
        irrigations: action.irrigations
      };
    case requestCulturesGoal:
      return {
        ...state
      };
    case receiveCulturesGoal:
      return {
        ...state,
        culturesGoal: action.culturesGoal
      };
    case changeCultureColor:
      let culture = action.culture;

      let newCultureApot = state.plotApontLayer;
      let newCulturePredit = state.plotPreditLayer;
      let newCultures = state.cultures;

      let preditStops = newCulturePredit.paint['fill-color'].stops || [];
      let stopPreditIndex = preditStops.findIndex(stop => stop[0] === culture.name);

      let apontStops = newCultureApot.paint['fill-color'].stops || [];
      let stopApontIndex = apontStops.findIndex(stop => stop[0] === culture.name);

      let cultureIndex = newCultures.findIndex(x => x.value === culture.name);

      if (cultureIndex !== -1) {
        newCultures[cultureIndex].color = culture.color;
      }

      if (stopPreditIndex !== -1) {
        preditStops[stopPreditIndex][1] = culture.color;
        newCulturePredit.paint['fill-color'].stops = preditStops;
      }

      if (stopApontIndex !== -1) {
        apontStops[stopApontIndex][1] = culture.color;
        newCultureApot.paint['fill-color'].stops = apontStops;
      }

      return {
        ...state,
        cultures: newCultures,
        plotPreditLayer: newCulturePredit,
        plotApontLayer: newCultureApot
      };
    case changeFillVariable:
      let type = action.type;

      const stateLayer = {
        id: 'state-fill',
        type: 'fill',
        paint: {
          'fill-outline-color': '#000000',
          'fill-color': type.color,
          'fill-opacity': [
            'interpolate',
            ['linear'],
            ['get', type.name],
            0, 0,
            100, 1
          ]
        },
      };

      return {
        ...state,
        stateLayer: stateLayer
      };
    default:
      return state;
  }
};
