import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import configure from "../../../../util/configureSignalR";
import { userActions } from '../../../../actions/user.actions';
import { adminUserActions } from "../../../../actions/admin/user.actions";
import { adminJobsActions } from "../../../../actions/admin/jobs.actions";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BuildIcon from '@mui/icons-material/Build';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Grid, Tooltip } from "@mui/material";
let connection = null

class AdminJobsPage extends React.Component {
  componentWillMount() {
    const { oidc } = this.props;
    if (oidc && oidc?.user && oidc.user.access_token) {
      connection = configure(oidc);
    }
  }

  render() {
    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Whitespace</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Stack direction="column" spacing={2} style={{ textAlign: 'center' }}>
            <h1 style={{ fontSize: '2rem', fontWeight: 'bold', color: 'var(--cort-blue)' }}>Subprocessos Whitespace</h1>
            <FarmPlotRelationProgress actions={this.props.actions} connection={connection} />
            <ResumeWhitespaceProgress actions={this.props.actions} connection={connection} />
          </Stack>
        </div>
      </div>
    );
  }
}

function FarmPlotRelationProgress(props) {
  const [isRunning, setIsRunning] = React.useState(props.isRunning);
  const [stopped, setStopped] = React.useState(false);
  const [messages, setMessage] = React.useState("");

  const startJob = () => {
    props.actions.runFarmPlotJob();
    setIsRunning(true);
    setStopped(false);
  };

  const stopJob = () => {
    props.actions.stopFarmPlotJob();
    setIsRunning(false);
    setStopped(true);
    setMessage("");
  };

  props.connection?.on("whitespaceReport", message => {
    setMessage(message);
  });

  return (
    <Stack direction="column" spacing={2} style={{ textAlign: 'center' }}>
      {((!isRunning && messages.length <= 0) || stopped) && (
        <Button
          variant="contained"
          startIcon={<BuildIcon style={{ fontSize: '1.5rem' }} />}
          style={{ width: '100%', fontWeight: 'bold', fontSize: '1.1rem' }}
          onClick={startJob}
        >
          Processar relações Fazenda x Talhão
        </Button>
      )}
      {(messages.length > 0 && !stopped) && (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={11}>
            <Tooltip title="Processamento de relação fazenda x talhão">
              <div key={messages}
                style=
                {{
                  fontWeight: 'bold',
                  border: '2px solid var(--cort-blue)',
                  borderRadius: '16px',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {messages}
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Parar Processamento">
              <Button
                variant="contained"
                style={{
                  width: 'min-content',
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '999px'
                }}
                onClick={stopJob}
              >
                <StopCircleIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )
      }
    </Stack >
  )
}

function ResumeWhitespaceProgress(props) {
  const [isRunning, setIsRunning] = React.useState(props.isRunning);
  const [stopped, setStopped] = React.useState(false);
  const [messages, setMessage] = React.useState("");

  const startJob = () => {
    props.actions.runResumeJob();
    setIsRunning(true);
    setStopped(false);
  };

  const stopJob = () => {
    props.actions.stopResumeJob();
    setIsRunning(false);
    setStopped(true);
    setMessage("");
  };

  props.connection?.on("whitespaceResumeReport", message => {
    setMessage(message);
  });

  return (
    <Stack direction="column" spacing={2} style={{ textAlign: 'center' }}>
      {((!isRunning && messages.length <= 0) || stopped) && (
        <Button
          variant="contained"
          startIcon={<BuildIcon style={{ fontSize: '1.5rem' }} />}
          style={{ width: '100%', fontWeight: 'bold', fontSize: '1.1rem' }}
          onClick={startJob}
        >
          Gerar resumo de dados do whitespace
        </Button>
      )}
      {((messages.length > 0 || isRunning) && !stopped) && (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={11}>
            <Tooltip title="Processamento de resumo de dados do whitespace">
              <div
                style=
                {{
                  fontWeight: 'bold',
                  border: '2px solid var(--cort-blue)',
                  borderRadius: '16px',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {messages.length > 0 ? messages : "Processo está sendo iniciado..."}
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Parar Processamento">
              <Button
                variant="contained"
                style={{
                  width: 'min-content',
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '999px'
                }}
                onClick={stopJob}
              >
                <StopCircleIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )
      }
    </Stack >
  )
}


export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminUserActions,
        ...adminJobsActions,
        ...userActions,
      }, dispatch),
    };
  }
)(AdminJobsPage);