const requestProdutoresType = "REQUEST_PRODUTOR";
const receiveProdutoresType = "RECEIVE_PRODUTOR";
const initialState = { produtores: [], isLoading: false };

export const produtorActions = {
  requestProdutor: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestProdutoresType });

    const url = `/api/produtores`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const produtores = await response.json();
    dispatch({ type: receiveProdutoresType, produtores });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestProdutoresType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveProdutoresType) {
    return {
      ...state,
      produtores: action.produtores,
      isLoading: false,
    };
  }

  return state;
};
