import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function soyGroupIcon(props) {
  return <SvgIcon viewBox="0 0 37 52" {...props} >
    <path d="M17.4076 18.1673C15.5485 10.5529 24.3864 6.00953 29.0378 4.68964C32.3639 16.4111 27.3152 20.7587 24.3751 21.4674C26.6755 27.4488 21.669 30.528 18.8782 31.3199C19.538 41.084 11.4343 43.6097 7.29991 43.6521C2.97436 33.722 8.40487 29.3917 11.6608 28.4677C8.44394 22.4448 14.2349 18.9419 17.4076 18.1673Z" fill="#EFE4B0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.7577 3.70288C29.3026 3.54824 29.8697 3.86465 30.0244 4.40959C31.7319 10.427 31.3336 14.7096 29.9881 17.6366C28.8985 20.0068 27.2128 21.4259 25.6652 22.1064C26.4562 24.9786 25.7116 27.2875 24.3319 28.9863C23.0865 30.5196 21.3652 31.5125 19.9375 32.0489C20.0439 36.8248 18.1016 39.9898 15.5047 41.9502C12.8264 43.972 9.53629 44.6548 7.31028 44.6776C6.8984 44.6818 6.52395 44.4392 6.35945 44.0616C4.10936 38.8961 4.32577 35.0022 5.72043 32.2226C6.83243 30.0063 8.63489 28.6185 10.2625 27.8904C9.7612 26.6243 9.60472 25.4251 9.73675 24.3061C9.90461 22.8834 10.5286 21.6755 11.3511 20.6916C12.6949 19.0838 14.6167 18.0114 16.2244 17.4509C15.6669 13.5906 17.5855 10.515 20.1034 8.29817C22.8093 5.91574 26.3334 4.39079 28.7577 3.70288ZM28.3235 5.98891C26.2003 6.7183 23.5307 8.01364 21.4589 9.83778C19.065 11.9455 17.5977 14.6223 18.4039 17.924C18.4684 18.1882 18.4253 18.4673 18.2841 18.6998C18.1428 18.9323 17.915 19.0991 17.6508 19.1636C16.2148 19.5142 14.1905 20.4931 12.925 22.0071C12.3052 22.7486 11.8863 23.5941 11.7739 24.5464C11.6621 25.4942 11.8437 26.6333 12.5654 27.9845C12.7116 28.2581 12.726 28.5833 12.6046 28.8688C12.4833 29.1544 12.2392 29.3697 11.9407 29.4544C10.5356 29.8531 8.62572 31.0063 7.55391 33.1425C6.549 35.1453 6.19696 38.1835 7.96969 42.5967C9.8298 42.457 12.28 41.8143 14.2688 40.313C16.4503 38.6662 18.1615 35.9288 17.8547 31.389C17.8221 30.9057 18.132 30.4654 18.5981 30.3332C19.8554 29.9764 21.5966 29.1002 22.7396 27.693C23.8279 26.3531 24.4322 24.4734 23.4177 21.8355C23.3117 21.5598 23.3296 21.2519 23.4669 20.9904C23.6042 20.729 23.8476 20.5394 24.1347 20.4702C25.2951 20.1905 27.0398 19.1389 28.1243 16.7798C29.1407 14.5688 29.611 11.1249 28.3235 5.98891Z" fill="black" />
    <path d="M17.4076 18.1673C15.5485 10.5529 24.3864 6.00953 29.0378 4.68964C32.3639 16.4111 27.3152 20.7587 24.3751 21.4674C26.6755 27.4488 21.669 30.528 18.8782 31.3199C19.538 41.084 11.4343 43.6097 7.29991 43.6521C2.97436 33.722 8.40487 29.3917 11.6608 28.4677C8.44394 22.4448 14.2349 18.9419 17.4076 18.1673Z" fill="#EFE4B0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.7577 3.70288C29.3026 3.54824 29.8697 3.86465 30.0244 4.40959C31.7319 10.427 31.3336 14.7096 29.9881 17.6366C28.8985 20.0068 27.2128 21.4259 25.6652 22.1064C26.4562 24.9786 25.7116 27.2875 24.3319 28.9863C23.0865 30.5196 21.3652 31.5125 19.9375 32.0489C20.0439 36.8248 18.1016 39.9898 15.5047 41.9502C12.8264 43.972 9.53629 44.6548 7.31028 44.6776C6.8984 44.6818 6.52395 44.4392 6.35945 44.0616C4.10936 38.8961 4.32577 35.0022 5.72043 32.2226C6.83243 30.0063 8.63489 28.6185 10.2625 27.8904C9.7612 26.6243 9.60472 25.4251 9.73675 24.3061C9.90461 22.8834 10.5286 21.6755 11.3511 20.6916C12.6949 19.0838 14.6167 18.0114 16.2244 17.4509C15.6669 13.5906 17.5855 10.515 20.1034 8.29817C22.8093 5.91574 26.3334 4.39079 28.7577 3.70288ZM28.3235 5.98891C26.2003 6.7183 23.5307 8.01364 21.4589 9.83778C19.065 11.9455 17.5977 14.6223 18.4039 17.924C18.4684 18.1882 18.4253 18.4673 18.2841 18.6998C18.1428 18.9323 17.915 19.0991 17.6508 19.1636C16.2148 19.5142 14.1905 20.4931 12.925 22.0071C12.3052 22.7486 11.8863 23.5941 11.7739 24.5464C11.6621 25.4942 11.8437 26.6333 12.5654 27.9845C12.7116 28.2581 12.726 28.5833 12.6046 28.8688C12.4833 29.1544 12.2392 29.3697 11.9407 29.4544C10.5356 29.8531 8.62572 31.0063 7.55391 33.1425C6.549 35.1453 6.19696 38.1835 7.96969 42.5967C9.8298 42.457 12.28 41.8143 14.2688 40.313C16.4503 38.6662 18.1615 35.9288 17.8547 31.389C17.8221 30.9057 18.132 30.4654 18.5981 30.3332C19.8554 29.9764 21.5966 29.1002 22.7396 27.693C23.8279 26.3531 24.4322 24.4734 23.4177 21.8355C23.3117 21.5598 23.3296 21.2519 23.4669 20.9904C23.6042 20.729 23.8476 20.5394 24.1347 20.4702C25.2951 20.1905 27.0398 19.1389 28.1243 16.7798C29.1407 14.5688 29.611 11.1249 28.3235 5.98891Z" fill="black" />
    <path d="M24.1849 21.8948C18.4923 15.7642 24.3674 6.93336 28.0165 3.28424C37.2123 12.48 34.7674 19.1579 32.3954 21.3474C37.6501 25.7263 34.5849 31.2 32.3954 33.3895C38.088 42.1474 31.8481 48.7158 28.0165 50.9053C18.8207 43.8989 21.6305 37.0386 24.1849 34.4842C18.0544 30.5432 21.6305 24.2667 24.1849 21.8948Z" fill="#EFE4B0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.2424 2.51015C27.6699 2.08263 28.363 2.08263 28.7905 2.51015C33.5113 7.23094 35.3673 11.4299 35.6352 14.8579C35.8522 17.6336 35.0189 19.833 33.9303 21.2721C36.1613 23.538 36.668 26.0773 36.2653 28.3782C35.9018 30.4551 34.8138 32.2756 33.762 33.5181C36.3448 37.9143 36.1803 41.8744 34.7792 45.0522C33.3342 48.3294 30.6226 50.6769 28.5596 51.8558C28.1779 52.0739 27.7027 52.0425 27.353 51.7761C22.5695 48.1315 20.7462 44.3894 20.6007 41.0733C20.4846 38.4292 21.443 36.1984 22.5814 34.6732C21.4558 33.7538 20.6863 32.7175 20.2274 31.6057C19.644 30.1924 19.5975 28.7421 19.8524 27.3972C20.269 25.1998 21.5026 23.2008 22.7096 21.8423C20.1825 18.5341 20.3714 14.6695 21.5654 11.2939C22.8487 7.6661 25.3405 4.41205 27.2424 2.51015ZM28.0265 4.86671C26.4267 6.65075 24.6121 9.24636 23.6296 12.024C22.4943 15.2336 22.5187 18.4916 24.9871 21.1498C25.1847 21.3626 25.2896 21.6451 25.2789 21.9353C25.2681 22.2254 25.1426 22.4994 24.9298 22.697C23.7737 23.7705 22.3959 25.7356 22.0036 27.805C21.8115 28.8183 21.8608 29.8243 22.2513 30.7704C22.6399 31.712 23.4016 32.6792 24.7769 33.5634C25.0554 33.7424 25.238 34.038 25.2733 34.3673C25.3087 34.6966 25.1931 35.0241 24.959 35.2583C23.8567 36.3606 22.6762 38.4288 22.788 40.9773C22.8929 43.3667 24.1447 46.3816 28.0921 49.5733C29.7532 48.4757 31.7028 46.6024 32.7758 44.1689C33.9528 41.4994 34.1243 38.058 31.4775 33.9861C31.1957 33.5526 31.2557 32.981 31.6213 32.6154C32.6077 31.629 33.775 29.9068 34.1086 28.0008C34.4262 26.1859 34.0119 24.1196 31.6945 22.1884C31.4524 21.9866 31.309 21.6902 31.301 21.3751C31.293 21.06 31.4212 20.7568 31.6528 20.543C32.5891 19.6788 33.6684 17.7913 33.4524 15.0285C33.25 12.439 31.8974 8.98445 28.0265 4.86671Z" fill="black" />
    <path d="M30.6526 14.779C30.6526 16.8951 29.4272 18.6105 27.9157 18.6105C26.4042 18.6105 25.1789 16.8951 25.1789 14.779C25.1789 12.6628 26.4042 10.9474 27.9157 10.9474C29.4272 10.9474 30.6526 12.6628 30.6526 14.779Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.7418 18.1246C30.1009 19.0219 29.1168 19.7053 27.9157 19.7053C26.7147 19.7053 25.7306 19.0219 25.0897 18.1246C24.4473 17.2252 24.0842 16.0389 24.0842 14.779C24.0842 13.519 24.4473 12.3327 25.0897 11.4333C25.7306 10.5361 26.7147 9.85266 27.9157 9.85266C29.1168 9.85266 30.1009 10.5361 30.7418 11.4333C31.3842 12.3327 31.7473 13.519 31.7473 14.779C31.7473 16.0389 31.3842 17.2252 30.7418 18.1246ZM27.9157 18.6105C29.4273 18.6105 30.6526 16.8951 30.6526 14.779C30.6526 12.6629 29.4273 10.9474 27.9157 10.9474C26.4042 10.9474 25.1789 12.6629 25.1789 14.779C25.1789 16.8951 26.4042 18.6105 27.9157 18.6105Z" fill="black" />
    <path d="M30.6526 27.9157C30.6526 30.0319 29.4272 31.7473 27.9157 31.7473C26.4042 31.7473 25.1789 30.0319 25.1789 27.9157C25.1789 25.7996 26.4042 24.0842 27.9157 24.0842C29.4272 24.0842 30.6526 25.7996 30.6526 27.9157Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.7418 31.2614C30.1009 32.1587 29.1168 32.8421 27.9157 32.8421C26.7147 32.8421 25.7306 32.1587 25.0897 31.2614C24.4473 30.3621 24.0842 29.1757 24.0842 27.9158C24.0842 26.6559 24.4473 25.4695 25.0897 24.5702C25.7306 23.6729 26.7147 22.9895 27.9157 22.9895C29.1168 22.9895 30.1009 23.6729 30.7418 24.5702C31.3842 25.4695 31.7473 26.6559 31.7473 27.9158C31.7473 29.1757 31.3842 30.3621 30.7418 31.2614ZM27.9157 31.7474C29.4273 31.7474 30.6526 30.0319 30.6526 27.9158C30.6526 25.7997 29.4273 24.0842 27.9157 24.0842C26.4042 24.0842 25.1789 25.7997 25.1789 27.9158C25.1789 30.0319 26.4042 31.7474 27.9157 31.7474Z" fill="black" />
    <path d="M30.6526 41.0526C30.6526 43.1688 29.4272 44.8842 27.9157 44.8842C26.4042 44.8842 25.1789 43.1688 25.1789 41.0526C25.1789 38.9365 26.4042 37.2211 27.9157 37.2211C29.4272 37.2211 30.6526 38.9365 30.6526 41.0526Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.7418 44.3982C30.1009 45.2955 29.1168 45.9789 27.9157 45.9789C26.7147 45.9789 25.7306 45.2955 25.0897 44.3982C24.4473 43.4989 24.0842 42.3125 24.0842 41.0526C24.0842 39.7927 24.4473 38.6063 25.0897 37.707C25.7306 36.8097 26.7147 36.1263 27.9157 36.1263C29.1168 36.1263 30.1009 36.8097 30.7418 37.707C31.3842 38.6063 31.7473 39.7927 31.7473 41.0526C31.7473 42.3125 31.3842 43.4989 30.7418 44.3982ZM27.9157 44.8842C29.4273 44.8842 30.6526 43.1687 30.6526 41.0526C30.6526 38.9365 29.4273 37.221 27.9157 37.221C26.4042 37.221 25.1789 38.9365 25.1789 41.0526C25.1789 43.1687 26.4042 44.8842 27.9157 44.8842Z" fill="black" />
    <path d="M24.1849 21.8948C18.4923 15.7642 24.3674 6.93336 28.0165 3.28424C37.2123 12.48 34.7674 19.1579 32.3954 21.3474C37.6501 25.7263 34.5849 31.2 32.3954 33.3895C38.088 42.1474 31.8481 48.7158 28.0165 50.9053C18.8207 43.8989 21.6305 37.0386 24.1849 34.4842C18.0544 30.5432 21.6305 24.2667 24.1849 21.8948Z" fill="#EFE4B0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.2424 2.51015C27.6699 2.08263 28.363 2.08263 28.7905 2.51015C33.5113 7.23094 35.3673 11.4299 35.6352 14.8579C35.8522 17.6336 35.0189 19.833 33.9303 21.2721C36.1613 23.538 36.668 26.0773 36.2653 28.3782C35.9018 30.4551 34.8138 32.2756 33.762 33.5181C36.3448 37.9143 36.1803 41.8744 34.7792 45.0522C33.3342 48.3294 30.6226 50.6769 28.5596 51.8558C28.1779 52.0739 27.7027 52.0425 27.353 51.7761C22.5695 48.1315 20.7462 44.3894 20.6007 41.0733C20.4846 38.4292 21.443 36.1984 22.5814 34.6732C21.4558 33.7538 20.6863 32.7175 20.2274 31.6057C19.644 30.1924 19.5975 28.7421 19.8524 27.3972C20.269 25.1998 21.5026 23.2008 22.7096 21.8423C20.1825 18.5341 20.3714 14.6695 21.5654 11.2939C22.8487 7.6661 25.3405 4.41205 27.2424 2.51015ZM28.0265 4.86671C26.4267 6.65075 24.6121 9.24636 23.6296 12.024C22.4943 15.2336 22.5187 18.4916 24.9871 21.1498C25.1847 21.3626 25.2896 21.6451 25.2789 21.9353C25.2681 22.2254 25.1426 22.4994 24.9298 22.697C23.7737 23.7705 22.3959 25.7356 22.0036 27.805C21.8115 28.8183 21.8608 29.8243 22.2513 30.7704C22.6399 31.712 23.4016 32.6792 24.7769 33.5634C25.0554 33.7424 25.238 34.038 25.2733 34.3673C25.3087 34.6966 25.1931 35.0241 24.959 35.2583C23.8567 36.3606 22.6762 38.4288 22.788 40.9773C22.8929 43.3667 24.1447 46.3816 28.0921 49.5733C29.7532 48.4757 31.7028 46.6024 32.7758 44.1689C33.9528 41.4994 34.1243 38.058 31.4775 33.9861C31.1957 33.5526 31.2557 32.981 31.6213 32.6154C32.6077 31.629 33.775 29.9068 34.1086 28.0008C34.4262 26.1859 34.0119 24.1196 31.6945 22.1884C31.4524 21.9866 31.309 21.6902 31.301 21.3751C31.293 21.06 31.4212 20.7568 31.6528 20.543C32.5891 19.6788 33.6684 17.7913 33.4524 15.0285C33.25 12.439 31.8974 8.98445 28.0265 4.86671Z" fill="black" />
    <path d="M30.6526 14.779C30.6526 16.8951 29.4272 18.6105 27.9157 18.6105C26.4042 18.6105 25.1789 16.8951 25.1789 14.779C25.1789 12.6628 26.4042 10.9474 27.9157 10.9474C29.4272 10.9474 30.6526 12.6628 30.6526 14.779Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.7418 18.1246C30.1009 19.0219 29.1168 19.7053 27.9157 19.7053C26.7147 19.7053 25.7306 19.0219 25.0897 18.1246C24.4473 17.2252 24.0842 16.0389 24.0842 14.779C24.0842 13.519 24.4473 12.3327 25.0897 11.4333C25.7306 10.5361 26.7147 9.85266 27.9157 9.85266C29.1168 9.85266 30.1009 10.5361 30.7418 11.4333C31.3842 12.3327 31.7473 13.519 31.7473 14.779C31.7473 16.0389 31.3842 17.2252 30.7418 18.1246ZM27.9157 18.6105C29.4273 18.6105 30.6526 16.8951 30.6526 14.779C30.6526 12.6629 29.4273 10.9474 27.9157 10.9474C26.4042 10.9474 25.1789 12.6629 25.1789 14.779C25.1789 16.8951 26.4042 18.6105 27.9157 18.6105Z" fill="black" />
    <path d="M30.6526 27.9157C30.6526 30.0319 29.4272 31.7473 27.9157 31.7473C26.4042 31.7473 25.1789 30.0319 25.1789 27.9157C25.1789 25.7996 26.4042 24.0842 27.9157 24.0842C29.4272 24.0842 30.6526 25.7996 30.6526 27.9157Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.7418 31.2614C30.1009 32.1587 29.1168 32.8421 27.9157 32.8421C26.7147 32.8421 25.7306 32.1587 25.0897 31.2614C24.4473 30.3621 24.0842 29.1757 24.0842 27.9158C24.0842 26.6559 24.4473 25.4695 25.0897 24.5702C25.7306 23.6729 26.7147 22.9895 27.9157 22.9895C29.1168 22.9895 30.1009 23.6729 30.7418 24.5702C31.3842 25.4695 31.7473 26.6559 31.7473 27.9158C31.7473 29.1757 31.3842 30.3621 30.7418 31.2614ZM27.9157 31.7474C29.4273 31.7474 30.6526 30.0319 30.6526 27.9158C30.6526 25.7997 29.4273 24.0842 27.9157 24.0842C26.4042 24.0842 25.1789 25.7997 25.1789 27.9158C25.1789 30.0319 26.4042 31.7474 27.9157 31.7474Z" fill="black" />
    <path d="M30.6526 41.0526C30.6526 43.1688 29.4272 44.8842 27.9157 44.8842C26.4042 44.8842 25.1789 43.1688 25.1789 41.0526C25.1789 38.9365 26.4042 37.2211 27.9157 37.2211C29.4272 37.2211 30.6526 38.9365 30.6526 41.0526Z" fill="#EDEDED" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.7418 44.3982C30.1009 45.2955 29.1168 45.9789 27.9157 45.9789C26.7147 45.9789 25.7306 45.2955 25.0897 44.3982C24.4473 43.4989 24.0842 42.3125 24.0842 41.0526C24.0842 39.7927 24.4473 38.6063 25.0897 37.707C25.7306 36.8097 26.7147 36.1263 27.9157 36.1263C29.1168 36.1263 30.1009 36.8097 30.7418 37.707C31.3842 38.6063 31.7473 39.7927 31.7473 41.0526C31.7473 42.3125 31.3842 43.4989 30.7418 44.3982ZM27.9157 44.8842C29.4273 44.8842 30.6526 43.1687 30.6526 41.0526C30.6526 38.9365 29.4273 37.221 27.9157 37.221C26.4042 37.221 25.1789 38.9365 25.1789 41.0526C25.1789 43.1687 26.4042 44.8842 27.9157 44.8842Z" fill="black" />
  </SvgIcon>
}