import { cenariosActions } from "./cenario.actions";
import { simulatorActions } from "./simulator.actions";

const requestDeleteCenarioType = "REQUEST_DeleteCenario";
const receiveDeleteCenarioType = "RECEIVE_DeleteCenario";
const initialState = { DeleteCenario: [], isLoading: false };

export const DeleteCenarioActions = {
  createDeleteCenario: (cenarioId, campanha, condicao, vencimento) => async (dispatch, getState) => {
    const { oidc } = getState();

    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })

    dispatch({ type: requestDeleteCenarioType });

    const url = `/api/cenariodelete/${cenarioId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const DeleteCenario = await response.json();
    dispatch({ type: receiveDeleteCenarioType, DeleteCenario });

  },
  createDeleteCenarioAndClear: (cenarioId, campanha, condicao, vencimento, clienteId) => async (dispatch, getState) => {
    const { oidc } = getState();

    var rows = getState().simulators
    rows = rows.map((x, index) => { x.ordem = index; return x })

    dispatch({ type: requestDeleteCenarioType });
    
    const url = `/api/cenariodelete/${cenarioId}/${encodeURIComponent(campanha)}/${encodeURIComponent(condicao)}/${encodeURIComponent(vencimento)}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    dispatch({ type: receiveDeleteCenarioType });
    dispatch(simulatorActions.removeAllRows());
    dispatch(cenariosActions.requestCenarios(clienteId))
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestDeleteCenarioType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveDeleteCenarioType) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
};
