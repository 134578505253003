/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { whitespaceActions } from "../../../actions/whitespace.actions";
import { clientActions } from "../../../actions/client.actions";
import { culturasWhitespaceActions } from "../../../actions/culturasWhitespace.actions";
import axios from "axios";
import {
  polygon as TurfPolygon,
  within as TurfWithin,
  center as TurfCenter,
  featureCollection,
  point as TurfPoint,
  distance as TurfDistance,
  bbox as TurfBbox,
} from "@turf/turf";

import Map from "./MapView/Map.jsx";
import CountyPagination from "./CountyPagination.jsx";
import StatePagination from "./StatesPagination.jsx";
import UniqueVisionPagination from "./UniqueVisionPagination.jsx";
import BoxError from "../../SimpleBoxError.jsx";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import "./style.css";
import "../../../index.css";
import { userActions } from "../../../actions/user.actions";
import { FormControl, IconButton, Paper } from "@mui/material";
import configure from "../../../util/configureSignalR.js";
import { isNullOrUndefined, spanToSeconds } from "../../../helpers/utils.js";

let connection = null
class WhiteSpaceView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupId: "",
      campanha: null,
      client: null,
      territory: [],
      municipios: [],
      marker: null,
      markerOn: false,

      selectedKey: 1,
      countFarm: 0,
      valueAreaFarm: 0,
      countAssignedFarm: 0,
      valueAreaAssignedFarm: 0,
      sumSelectFarmArea: 0,
      sumSelectPlotArea: 0,

      countPlot: 0,
      valueAreaPlot: 0,
      countAssignedPlot: 0,
      valueAreaAssignedPlot: 0,

      pointedCultureArea: [],
      predictCultureArea: [],

      showingStates: false,

      hasPlots: false,
      showPlots: false,
      showAllData: true,

      isLoading: true,
      progressFarm: "",

      isLoadingPlots: false,
      progressPlot: "",

      isLoadingTerritory: false,
      isLoadingToButton: false,

      selectData: null,
      data: null,
      polygonRegion: null,
      accountData: null,
      assignedData: null,
      allAssignedData: null,
      newestData: null,

      plotData: null,

      allPlotData: null,
      allPlotAssignedData: null,
      allPlotAllAssignedData: null,

      countyData: null,

      coordinates: [-10.068823732529452, -51.63808832208099],

      accountId: "",
      accountName: "",
      accountTerritories: [],

      accountsIds: [],
      accountPolygon: null,
      accountsPolygons: [],

      mapR: null,
      drawR: null,
      sourcePolygon: [],
      dataPolygon: [],

      sourcePlotPolygon: [],
      dataPlotPolygon: [],

      loadedCods: [],
      territorio: undefined,
      area: undefined,
      distrito: undefined,
      isSetStorage: false,

      error: false,
      errorTitle: "",
      errorMessage: "",

      menuOpen: false,

      loadingMessage: ""
    };

    this.handleOpenError = this.handleOpenError.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
    this.handleEnableMarker = this.handleEnableMarker.bind(this);

    this.copySourceCodes = this.copySourceCodes.bind(this);
    this.setMapbox = this.setMapbox.bind(this);
    this.setDraw = this.setDraw.bind(this);
    this.changeSafra = this.changeSafra.bind(this);

    this.goTo = this.goTo.bind(this);
    this.goToData = this.goToData.bind(this);
    this.goToCoord = this.goToCoord.bind(this);
    this.goToPolygon = this.goToPolygon.bind(this);
    this.goToPolygonWithSource = this.goToPolygonWithSource.bind(this);

    this.onChangeKey = this.onChangeKey.bind(this);

    this.addData = this.addData.bind(this);
    this.getLayer1Data = this.getLayer1Data.bind(this);
    this.getLayer2Data = this.getLayer2Data.bind(this);

    this.removeData = this.removeData.bind(this);
    this.removeLayer1Data = this.removeLayer1Data.bind(this);
    this.removeLayer2Data = this.removeLayer2Data.bind(this);

    this.selectPolygon = this.selectPolygon.bind(this);
    this.selectPlotPolygon = this.selectPlotPolygon.bind(this);

    this.selectAccount = this.selectAccount.bind(this);
    this.changeLoadState = this.changeLoadState.bind(this);
    this.changeFarmLoad = this.changeFarmLoad.bind(this);
    this.changePlotLoad = this.changePlotLoad.bind(this);

    this.setShowPlots = this.setShowPlots.bind(this);
    this.getAllAssignedPlotVision = this.getAllAssignedPlotVision.bind(this);
    this.getAllAssignedPlotNVision = this.getAllAssignedPlotNVision.bind(this);

    this.getAccountsPolygon = this.getAccountsPolygon.bind(this);
    this.cleanList = this.cleanList.bind(this);
    this.setSelectData = this.setSelectData.bind(this);

    this.setByStorage = this.setByStorage.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.onChangeDistrito = this.onChangeDistrito.bind(this);
    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);

    this.cleanAllData = this.cleanAllData.bind(this);
  }

  handleOpenError(title, message) {
    this.setState({
      errorTitle: title,
      errorMessage: message,
      error: true
    });
  }
  handleCloseError() {
    this.setState({ error: false });
  }
  handleEnableMarker() {
    this.setState({ markerOn: !this.state.markerOn });
  }

  copySourceCodes(inPlot) {
    var sources = [];
    if (inPlot) {
      var dataSource = this.state.sourcePlotPolygon;
      dataSource.forEach(x => {
        sources.push(x);
      });
    }
    else {
      var dataSource = this.state.sourcePolygon;
      dataSource.forEach(x => {
        var text = x + ".json";
        sources.push(text);
      });
    }

    navigator.clipboard.writeText(sources);
  }
  setMapbox(value) {
    if (this.state.mapR == null) {
      this.setState({ mapR: value });
    }
  }
  setDraw(value) {
    if (this.state.drawR == null) {
      this.setState({ drawR: value });
    }
  }
  setShowPlots(value, whitespace) {
    this.setState({ showPlots: value });
    this.setState({ accountData: featureCollection([]) });

    if (value) {
      this.setState({
        isLoadingPlots: true,
        isLoadingToButton: true
      });

      this.setSelectData(featureCollection(this.state.showingStates ? this.state.dataPolygon : this.state.dataPlotPolygon));

      if (whitespace?.length > 0) {
        this.setState({ polygonRegion: featureCollection(whitespace) });
        this.getPlotInWhitespace(featureCollection(whitespace), true);
        //this.getAllAssignedPlotsIn(featureCollection(whitespace));
      }
      else {
        this.setState({ polygonRegion: this.state.data });
        this.getPlotInWhitespace(this.state.data, false);
        //this.getAllAssignedPlotsIn(this.state.data);
      }
    } else {
      this.setSelectData(featureCollection(this.state.dataPolygon));
      this.setState({ polygonRegion: featureCollection([]) });

      if (this.state.accountId != "") {
        this.addAssignedAccountData(this.state.loadedCods, this.state.accountId);
      }
    }
  }
  markInMap(coord) {
    this.setState({ marker: { latitude: coord[1], longitude: coord[0] } });
    this.setState({ markerOn: true });

    this.setState({ isLoading: false, progressFarm: "" });
  }
  goToCoord(coord, data) {
    this.setState({ marker: { latitude: coord[1], longitude: coord[0] } });
    this.setState({ markerOn: true });

    const map = this.state.mapR;
    if (map == null)
      return;

    if (!isNullOrUndefined(data.features)) {
      var bbox = TurfBbox(featureCollection(data.features));
      map.fitBounds(bbox, { padding: 20 });
    }

    this.setState({ isLoading: false, progressFarm: "" });
  }
  goTo(layer) {
    var map = this.state.mapR;
    var f;

    if (map == null) return;
    switch (layer) {
      case 'data':
        f = this.state.data.features;
        break;
      case 'plot':
        f = this.state.plotData.features;
        break;
      case 'select':
        f = this.state.selectData.features;
        break;
      case 'account':
        f = this.state.accountData.features;
        break;
      case 'assigned':
        f = this.state.assignedData.features;
        break;
      default:
        return;
    }
    if (f.length === 0) return;
    var bbox = TurfBbox({
      type: 'FeatureCollection',
      features: f
    });
    map?.fitBounds(bbox, { padding: 20 });
  }
  goToData(data) {
    var coord = featureCollection(TurfCenter(data)).features.geometry.coordinates;
    this.setState({ coordinates: coord });

    var map = this.state.mapR;
    if (map == null) return;

    map.flyTo({ center: coord });
  }
  goToPolygon(feature) {
    var map = this.state.mapR;
    if (map == null) return;
    var bbox = TurfBbox({
      type: 'FeatureCollection',
      features: [feature]
    });
    map?.fitBounds(bbox, { padding: 20 });
  }
  goToPolygonWithSource(source) {
    var data = this.state.data;
    var feature;
    data.features.forEach((x) => {
      if (x.properties.COD_IMOVEL == source) {
        feature = x;
        return;
      }
    })

    this.goToPolygon(feature);
  }

  setAccountsIds(value) {
    var ids = [];
    for (var i = 0; i < value.length; i++) {
      ids.push(value[i].accountId);
    }

    this.setState({ accountsIds: ids });
  }
  setSelectData(value) {
    this.setState({ selectData: value });
  }
  cleanList(value) {
    switch (value) {
      case "plot":
        this.setState({ sourcePlotPolygon: [] });
        this.setState({ dataPlotPolygon: [] });
        this.setState({ sumSelectPlotArea: 0 });
        if (this.state.showPlots) {
          this.setState({ selectData: featureCollection([]) });
        }
        break;
      case "data":
        this.setState({ sourcePolygon: [] });
        this.setState({ dataPolygon: [] });
        this.setState({ sumSelectFarmArea: 0 });
        if (!this.state.showPlots) {
          this.setState({ selectData: featureCollection([]) });
        }
        break;
      default:
        this.setState({ sourcePolygon: [] });
        this.setState({ dataPolygon: [] });
        this.setState({ sourcePlotPolygon: [] });
        this.setState({ dataPlotPolygon: [] });
        this.setState({ selectData: featureCollection([]) });
        break;
    }
  }
  cleanAllData() {
    const map = this.state.mapR;

    if (!isNullOrUndefined(map)) {
      let farmSource = map.getSource("data");
      let plotSource = map.getSource("plot");

      if (!isNullOrUndefined(farmSource))
        farmSource.setData(featureCollection([]));

      if (!isNullOrUndefined(plotSource))
        plotSource.setData(featureCollection([]));
    }

    this.setState({
      data: featureCollection([]),
      allPlotData: featureCollection([]),
      allPlotAssignedData: featureCollection([]),
      allPlotAllAssignedData: featureCollection([]),

      selectData: featureCollection([]),
      assignedData: featureCollection([]),
      allAssignedData: featureCollection([]),

      countyData: featureCollection([]),
      polygonRegion: featureCollection([]),

      dataPolygon: [],
      dataPlotPolygon: [],

      countFarm: 0,
      countAssignedFarm: 0,
      valueAreaFarm: 0,
      valueAreaAssignedFarm: 0,

      countPlot: 0,
      valueAreaPlot: 0,
      countAssignedPlot: 0,
      valueAreaAssignedPlot: 0,

      pointedCultureArea: [],
      predictCultureArea: [],

      sourcePolygon: [],
      sumSelectFarmArea: 0,

      sourcePlotPolygon: [],
      sumSelectPlotArea: 0,

      loadedCods: [],
    });
  }

  changeLoadState(value) {
    this.setState({
      isLoading: value, progressFarm: "",
      isLoadingPlots: value, progressPlot: "",
      hasPlots: !value
    });
  }

  changeFarmLoad(value) {
    this.setState({ isLoading: value });
  }

  changePlotLoad(value) {
    this.setState({ isLoadingPlots: value });
  }

  changeClient(event) {
    this.setState({ groupId: event.target.value });
    this.setState({ client: this.props.clientes.clients.find(x => x.clientId === event.target.value) });
    this.props.actions.requestAccountsWithId(event.target.value).catch(() => {
      this.handleOpenError("Erro ao baixar contas.", "Não foi possível encontrar as contas relacionadas a esta VU.");
    });;

    this.cleanList();

    this.setState({ accountId: "" });
    this.setState({ accountPolygon: featureCollection([]) });

    this.setState({ showPlots: false });

    if (this.state.drawR !== null) {
      this.state.drawR.deleteAll();
    }
  }
  changeSafra(campanha) {
    if (campanha.id === this.state.campanha.id) return;

    var codsToLoad = this.state.loadedCods;

    this.cleanAllData();
    this.setState({ campanha: campanha });

    if (!isNullOrUndefined(codsToLoad)) {
      var type = "";
      switch (this.state.selectedKey) {
        case 0:
          type = "state";
          break;
        case 1:
          type = "county";
          break;
        case 2:
          type = "vu";
          break;
        default:
          break;
      }

      this.changeLoadState(true);
      setTimeout(() => {
        this.addData(codsToLoad, type);
      }, 2000);
    }
  }
  onChangeKey(event) {
    this.cleanAllData();

    let value = event.target.value;

    this.setState({ selectedKey: value });

    if (value == 0)
      this.setState({ showingStates: true });
    else
      this.setState({ showingStates: false });
  }

  selectAccount(event, account) {
    if (event.target.checked) {
      this.setState({ accountId: account.Cod });
      this.setState({ accountName: account.Nome });

      this.getAccountPolygon(account.Cod);
      this.getAccountsPolygon(this.state.groupId);

      if (this.state.showPlots) {
        this.getAccountPlotAssigned(account.Cod);
      } else {
        this.getAccounData(account.Cod);
      }
    } else {
      this.setState({ accountId: "" });
      this.setState({ accountName: "" });
      this.setState({ accountTerritories: [] });

      this.setState({ accountData: featureCollection([]) });
    }
  }
  selectPolygon(id, data, ctrl, shift) {
    var sources = this.state.sourcePolygon;
    var datas = this.state.dataPolygon;

    if ((sources.includes(id) && shift == false) || ctrl == true) {
      var index = sources.indexOf(id);
      if (index > -1) {
        sources.splice(index, 1);
        datas.splice(index, 1);
      }
    } else if (!sources.includes(id)) {
      sources.push(id);
      datas.push(data);
    }

    this.setState({ sourcePolygon: sources });
    this.setState({ dataPolygon: datas });

    this.state.mapR.getSource("select").setData(featureCollection(datas));

    var area = 0;
    datas.forEach(x => {
      if (this.state.showingStates) {
        area = area + x.properties.Area;
      }
      else {
        area = area + x.properties.NUM_AREA;
      }
    })

    this.setState({ sumSelectFarmArea: area });
  }
  selectPlotPolygon(id, data, ctrl, shift) {
    var sources = this.state.sourcePlotPolygon;
    var datas = this.state.dataPlotPolygon;

    if ((sources.includes(id) && shift == false) || ctrl == true) {
      var index = sources.indexOf(id);
      if (index > -1) {
        sources.splice(index, 1);
        datas.splice(index, 1);
      }
    } else if (!sources.includes(id)) {
      sources.push(id);
      datas.push(data);
    }

    this.setState({ sourcePlotPolygon: sources });
    this.setState({ dataPlotPolygon: datas });

    this.state.mapR.getSource("select").setData(featureCollection(datas));

    var area = 0;
    datas.forEach(x => {
      area = area + x.properties.Area;
    })

    this.setState({ sumSelectPlotArea: area });
  }

  addData = async (cods, type) => {
    const allCods = new Set([...this.state.loadedCods, ...cods]);
    const loadedCods = [...allCods];

    this.setState({ loadedCods: loadedCods });

    connection?.on("whitespaceLayer1", message => {
      const timespan = spanToSeconds(message);
      this.setState({ progressFarm: timespan });
    });

    if (type == "state") {
      var l;
      var request;
      var data = this.state.data;

      request = cods.map(async x => {
        l = axios
          .get(`api/whitespaceView/whitespaceByState/${x}/${this.state.campanha.id}`, {
            method: "GET",
            timeout: 0,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.props.oidc.user.access_token}`,
            },
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
              const progress = Math.round((progressEvent.loaded * 100) / total);
              this.setState({ progressFarm: `Baixando... ${progress}%` });
            },
          })
          .then((json) => {
            if (isNullOrUndefined(json.data.features))
              return;

            if (!isNullOrUndefined(data)) {
              data = featureCollection(data.features.concat(json.data.features));
            }
            else {
              data = json.data;
            }

            this.setState({
              data: data
            });
          })
          .catch((err) => {
            console.error("Could not load data", err);
          });

        await Promise.all([l]);
      });

      await Promise.all(request);

      this.calculateCountyResume(data);

      this.goToData(data);

      this.setState({ isLoading: false, isLoadingPlots: false, hasPlots: true });

      connection.off("whitespaceLayer1");
    }
    else {
      this.getLayer1Data(cods, this.state.campanha.id, type);
      this.getLayer2Data(cods, this.state.campanha.id, type);
    }
  }
  getLayer1Data = async (cod, campaign, type) => {
    var data = this.state.data;
    var assignedData = this.state.assignedData;
    var allAssignedData = this.state.allAssignedData;
    var countyData = this.state.countyData;

    var l, la, loa, lb;
    var request;

    switch (type) {
      case 'county':
        request = cod.map(async x => {
          //GET LAYER 1
          l = axios
            .get(`api/whitespaceView/whitespaceByCounty/${x}/${campaign}`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
              onDownloadProgress: (progressEvent) => {
                const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
                const progress = Math.round((progressEvent.loaded * 100) / total);
                this.setState({ progressFarm: `Baixando... ${progress}%` });
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(data)) {
                data = featureCollection(data.features.concat(json.data.features));
              }
              else {
                data = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 1 ASSIGNED
          la = axios
            .get(`api/whitespaceView/assignedWhitespacesByCounty/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(assignedData)) {
                assignedData = featureCollection(assignedData.features.concat(json.data.features));
              }
              else {
                assignedData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 1 BORDER
          lb = axios
            .get(`api/whitespaceView/whitespaceBorderByCounty/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(countyData)) {
                countyData = featureCollection(countyData.features.concat(json.data.features));
              }
              else {
                countyData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          await Promise.all([l, la, lb]);
        });

        await Promise.all(request);
        break;
      case 'vu':
        request = cod.map(async x => {
          //GET LAYER 1
          l = axios
            .get(`api/whitespaceView/whitespaceByClient/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
              onDownloadProgress: (progressEvent) => {
                const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
                const progress = Math.round((progressEvent.loaded * 100) / total);
                this.setState({ progressFarm: `Baixando... ${progress}%` });
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(data)) {
                data = featureCollection(data.features.concat(json.data.features));
              }
              else {
                data = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 1 ASSIGNED
          la = axios
            .get(`api/whitespaceView/assignedWhitespacesByClient/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(assignedData)) {
                assignedData = featureCollection(assignedData.features.concat(json.data.features));
              }
              else {
                assignedData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 1 OTHER'S ASSIGNED
          loa = axios
            .get(`api/whitespaceView/allAssignedWhitespacesByClient/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(allAssignedData)) {
                allAssignedData = featureCollection(allAssignedData.features.concat(json.data.features));
              }
              else {
                allAssignedData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 1 BORDER
          lb = axios
            .get(`api/whitespaceView/whitespaceBorderByClient/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(countyData)) {
                countyData = featureCollection(countyData.features.concat(json.data.features));
              }
              else {
                countyData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          await Promise.all([l, la, loa, lb]);
        });

        await Promise.all(request);
        break;
      case 'coord':
        //GET LAYER 1
        l = axios
          .get(`api/whitespaceView/whitespaceByCoord/${campaign}/${cod[0]}/${cod[1]}`, {
            method: "GET",
            timeout: 0,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.props.oidc.user.access_token}`,
            },
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
              const progress = Math.round((progressEvent.loaded * 100) / total);
              this.setState({ progressFarm: `Baixando... ${progress}%` });
            },
          })
          .then((json) => {
            if (isNullOrUndefined(json.data.features))
              return;

            if (!isNullOrUndefined(data)) {
              data = featureCollection(data.features.concat(json.data.features));
            }
            else {
              data = json.data;
            }
          })
          .catch((err) => {
            console.error("Could not load data", err);
          });

        //GET LAYER 1 ASSIGNED
        la = axios
          .get(`api/whitespaceView/assignedWhitespacesByCoord/${campaign}/${cod[0]}/${cod[1]}`, {
            method: "GET",
            timeout: 0,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.props.oidc.user.access_token}`,
            },
          })
          .then((json) => {
            if (isNullOrUndefined(json.data.features))
              return;

            if (!isNullOrUndefined(assignedData)) {
              assignedData = featureCollection(assignedData.features.concat(json.data.features));
            }
            else {
              assignedData = json.data;
            }
          })
          .catch((err) => {
            console.error("Could not load data", err);
          });

        //GET LAYER 1 BORDER
        lb = axios
          .get(`api/whitespaceView/whitespaceBorderByCoord/${campaign}/${cod[0]}/${cod[1]}`, {
            method: "GET",
            timeout: 0,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.props.oidc.user.access_token}`,
            },
          })
          .then((json) => {
            if (isNullOrUndefined(json.data.features))
              return;

            if (!isNullOrUndefined(countyData)) {
              countyData = featureCollection(countyData.features.concat(json.data.features));
            }
            else {
              countyData = json.data;
            }
          })
          .catch((err) => {
            console.error("Could not load data", err);
          });

        await Promise.all([l, la, lb]);
        break;
      default:
        break;
    }

    connection.off("whitespaceLayer1");

    if (isNullOrUndefined(data) || isNullOrUndefined(data.features)) {
      if (type == 'coord') {
        this.setState({
          marker: { latitude: cod[1], longitude: cod[0] },
          markerOn: true
        });

        const map = this.state.mapR;
        if (map == null)
          return;

        var bbox = TurfBbox(TurfPoint([cod[1], cod[0]]));
        map.fitBounds(bbox, { padding: 20, maxZoom: 15 });
      }

      this.handleOpenError("Dados indisponiveis", "A requisição expirou ou não encontrou dados disponiveis de fazendas para o local requisitado.");
      this.setState({ isLoading: false, progressFarm: "" });
      return;
    }
    else {
      this.setState({
        data: data
      });
    }

    if (!isNullOrUndefined(countyData) && !isNullOrUndefined(countyData.features)) {
      this.setState({
        countyData: countyData
      });
    }

    this.calculateFarmResume(data);
    this.calculateFarmAssignedResume(assignedData, allAssignedData);

    if (this.state.showPlots) {
      this.setState({ polygonRegion: data });
    }
    else {
      this.setState({
        assignedData: assignedData,
        allAssignedData: allAssignedData
      });

      if (type == 'coord') {
        var coord = [parseFloat(cod[1]), parseFloat(cod[0])];
        this.goToCoord(coord, countyData);
      }
      else {
        this.goToData(data);
      }
    }

    this.setState({ isLoading: false, progressFarm: "" });
  }
  getLayer2Data = async (cod, campaign, type) => {
    var data = this.state.allPlotData;
    var assignedData = this.state.allPlotAssignedData;
    var allAssignedData = this.state.allPlotAllAssignedData;

    var l, la, loa, lb;
    var request;

    connection?.on("whitespaceLayer2", message => {
      const timespan = spanToSeconds(message);
      this.setState({ progressPlot: timespan });
    });

    switch (type) {
      case 'county':
        request = cod.map(async x => {
          //GET LAYER 2
          l = axios
            .get(`api/whitespaceView/whitespacePlotByCounty/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
              onDownloadProgress: (progressEvent) => {
                const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
                const progress = Math.round((progressEvent.loaded * 100) / total);
                this.setState({ progressPlot: `Baixando... ${progress}%` });
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(data)) {
                data = featureCollection(data.features.concat(json.data.features));
              }
              else {
                data = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 2 ASSIGNED
          la = axios
            .get(`api/whitespaceView/assignedWhitespacesPlotByCounty/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(assignedData)) {
                assignedData = featureCollection(assignedData.features.concat(json.data.features));
              }
              else {
                assignedData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          await Promise.all([l, la]);
        });
        break;
      case 'vu':
        request = cod.map(async x => {
          //GET LAYER 2
          l = axios
            .get(`api/whitespaceView/whitespacePlotByClient/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
              onDownloadProgress: (progressEvent) => {
                const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
                const progress = Math.round((progressEvent.loaded * 100) / total);
                this.setState({ progressPlot: `Baixando... ${progress}%` });
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(data)) {
                data = featureCollection(data.features.concat(json.data.features));
              }
              else {
                data = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 2 ASSIGNED
          la = axios
            .get(`api/whitespaceView/assignedWhitespacesPlotByClient/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(assignedData)) {
                assignedData = featureCollection(assignedData.features.concat(json.data.features));
              }
              else {
                assignedData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          //GET LAYER 2 OTHER'S ASSIGNED
          loa = axios
            .get(`api/whitespaceView/allAssignedWhitespacesPlotByClient/${x}/${campaign}`, {
              method: "GET",

              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
              },
            })
            .then((json) => {
              if (isNullOrUndefined(json.data.features))
                return;

              if (!isNullOrUndefined(allAssignedData)) {
                allAssignedData = featureCollection(allAssignedData.features.concat(json.data.features));
              }
              else {
                allAssignedData = json.data;
              }
            })
            .catch((err) => {
              console.error("Could not load data", err);
            });

          await Promise.all([l, la, loa]);
        });
        break;
      case 'coord':
        //GET LAYER 2
        l = axios
          .get(`api/whitespaceView/whitespacePlotByCoord/${campaign}/${cod[0]}/${cod[1]}`, {
            method: "GET",
            timeout: 0,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.props.oidc.user.access_token}`,
            },
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.event.currentTarget.getResponseHeader('X-Original-Size');
              const progress = Math.round((progressEvent.loaded * 100) / total);
              this.setState({ progressPlot: `Baixando... ${progress}%` });
            },
          })
          .then((json) => {
            if (isNullOrUndefined(json.data.features))
              return;

            if (!isNullOrUndefined(data)) {
              data = featureCollection(data.features.concat(json.data.features));
            }
            else {
              data = json.data;
            }
          })
          .catch((err) => {
            console.error("Could not load data", err);
          });

        //GET LAYER 2 ASSIGNED
        la = axios
          .get(`api/whitespaceView/assignedWhitespacesPlotByCoord/${campaign}/${cod[0]}/${cod[1]}`, {
            method: "GET",
            timeout: 0,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.props.oidc.user.access_token}`,
            },
          })
          .then((json) => {
            if (isNullOrUndefined(json.data.features))
              return;

            if (!isNullOrUndefined(assignedData)) {
              assignedData = featureCollection(assignedData.features.concat(json.data.features));
            }
            else {
              assignedData = json.data;
            }
          })
          .catch((err) => {
            console.error("Could not load data", err);
          });

        await Promise.all([l, la]);
        break;
      default:
        break;
    }

    if (request != null)
      await Promise.all(request);

    connection.off("whitespaceLayer2");

    if (isNullOrUndefined(data) || isNullOrUndefined(data.features)) {
      this.handleOpenError("Dados indisponiveis", "A requisição expirou ou não encontrou dados disponiveis de talhões para o local requisitado.");
      this.setState({ isLoadingPlots: false, progressPlot: "" });
      return;
    }

    this.calculatePlotResume(data);
    this.calculatePlotAssignedResume(assignedData, allAssignedData);

    this.setState({
      allPlotData: data,
      allPlotAssignedData: assignedData,
      allPlotAllAssignedData: allAssignedData,
      hasPlots: true
    });

    if (this.state.showPlots) {
      this.setState({
        plotData: data,
        assignedData: assignedData,
        allAssignedData: allAssignedData
      });

      if (type == 'coord') {
        var coord = [parseFloat(cod[1]), parseFloat(cod[0])];
        this.goToCoord(coord, data);
      }
      else {
        this.goToData(data);
      }
    }

    this.setState({ isLoadingPlots: false, progressPlot: "" });
  }

  removeData = async (cod, type) => {
    this.removeLayer1Data(cod, this.state.campanha.id, type);
    this.removeLayer2Data(cod, this.state.campanha.id, type);
  }
  removeLayer1Data = async (cod, campaign, type) => {
    const map = this.state.mapR;

    var data = this.state.data;
    var assignedData = this.state.assignedData;
    var allAssignedData = this.state.allAssignedData;
    var countyData = this.state.countyData;

    var newLayerData = [];
    var newLayerAssignedData = [];
    var newLayerAllAssignedData = [];
    var newLayerCountyData = [];

    switch (type) {
      case 'state':
        //REMOVE LAYER 1
        if (!isNullOrUndefined(data)) {
          if (!isNullOrUndefined(data.features)) {
            newLayerData = featureCollection(data?.features?.filter(x => {
              const source = parseInt(x.properties.COD_IMOVEL.split("-")[1]);
              return !cod.includes(source);
            }));
          }
        }

        //REMOVE LAYER 1 ASSIGNED
        if (!isNullOrUndefined(assignedData)) {
          if (!isNullOrUndefined(assignedData.features)) {
            newLayerAssignedData = featureCollection(assignedData?.features?.filter(x => {
              const source = parseInt(x.properties.COD_IMOVEL.split("-")[1]);
              return !cod.includes(source);
            }));
          }
        }
        break;
      case 'county':
        try {
          //REMOVE LAYER 1
          if (!isNullOrUndefined(data)) {
            if (!isNullOrUndefined(data.features)) {
              newLayerData = data.features.filter(x => {
                var codIbge = x.properties.COD_IMOVEL.split("-")[1];
                return !cod.includes(codIbge);
              });

              newLayerData = featureCollection(data?.features?.filter(x => {
                const source = parseInt(x.properties.COD_IMOVEL.split("-")[1]);
                return !cod.includes(source);
              }));
            }
          }

          //REMOVE LAYER 1 ASSIGNED
          if (!isNullOrUndefined(assignedData)) {
            if (!isNullOrUndefined(assignedData.features)) {
              newLayerAssignedData = featureCollection(assignedData?.features?.filter(x => {
                const source = parseInt(x.properties.COD_IMOVEL.split("-")[1]);
                return !cod.includes(source);
              }));
            }
          }

          //REMOVE LAYER 1 COUNTY BORDER
          if (!isNullOrUndefined(countyData)) {
            if (!isNullOrUndefined(countyData.features)) {
              newLayerCountyData = featureCollection(countyData?.features?.filter(x => {
                return !cod.includes(x.properties.GEOCODIG_M);
              }));
            }
          }
        }
        catch (e) {
          console.log("Erro ao remover: " + e);
        }
        break;
      case 'vu':
        var listCod = [];

        //REMOVE LAYER 1
        if (!isNullOrUndefined(data)) {
          if (!isNullOrUndefined(data.features)) {
            newLayerData = data.features.filter(x => {
              var codIbge = x.properties.COD_IMOVEL.split("-")[1];
              listCod.push(codIbge);

              return !x.properties.CLIENT_ID.includes(cod);
            });

            newLayerData = featureCollection(newLayerData);
          }
        }

        //REMOVE LAYER 1 ASSIGNED
        if (!isNullOrUndefined(assignedData)) {
          if (!isNullOrUndefined(assignedData.features)) {
            newLayerAssignedData = assignedData.features.filter(x => {
              return !x.properties.CLIENT_ID.includes(cod);
            });

            newLayerAssignedData = featureCollection(newLayerAssignedData);
          }
        }

        //REMOVE LAYER 1 ALL ASSIGNED
        if (!isNullOrUndefined(assignedData)) {
          if (!isNullOrUndefined(allAssignedData.features)) {
            newLayerAllAssignedData = allAssignedData.features.filter(x => {
              var codIbge = x.properties.COD_IMOVEL.split("-")[1];
              return !listCod.includes(codIbge);
            });

            newLayerAllAssignedData = featureCollection(newLayerAllAssignedData);
          }
        }

        //REMOVE LAYER 1 COUNTY BORDER
        if (!isNullOrUndefined(countyData)) {
          if (!isNullOrUndefined(countyData.features)) {
            newLayerCountyData = countyData.features.filter(x => {
              return !listCod.includes(x.properties.GEOCODIG_M);
            });

            newLayerCountyData = featureCollection(newLayerCountyData);
          }
        }
        break;
      default:
        break;
    }

    this.calculateFarmResume(newLayerData);
    this.calculateFarmAssignedResume(newLayerAssignedData, newLayerAllAssignedData);

    map.getSource("data").setData(newLayerData);
    this.setState({
      data: newLayerData,
      countyData: newLayerCountyData,
      isLoading: false,
      progressFarm: ""
    });

    if (this.state.showPlots) {
      this.setState({ polygonRegion: newLayerData });
    }
    else {
      this.setState({
        assignedData: newLayerAssignedData,
        allAssignedData: newLayerAllAssignedData,
      });
    }
  }
  removeLayer2Data = async (cod, campaign, type) => {
    const map = this.state.mapR;

    var data = this.state.allPlotData;
    var assignedData = this.state.allPlotAssignedData;
    var allAssignedData = this.state.allPlotAllAssignedData;

    var newLayerData = [];
    var newLayerAssignedData = [];

    switch (type) {
      case 'state':
        cod.forEach(ibge => {
          //REMOVE LAYER 1
          if (!isNullOrUndefined(data)) {
            if (!isNullOrUndefined(data.features)) {
              newLayerData = data.features.filter(x => {
                return !x.properties.Sigla.includes(ibge);
              });

              newLayerData = featureCollection(newLayerData);
            }
          }

          //REMOVE LAYER 1 ASSIGNED
          if (!isNullOrUndefined(assignedData)) {
            if (!isNullOrUndefined(assignedData.features)) {
              newLayerAssignedData = assignedData.features.filter(x => {
                return !x.properties.Sigla.includes(ibge);
              });

              newLayerAssignedData = featureCollection(newLayerAssignedData);
            }
          }
        });
        break;
      case 'county':
        //REMOVE LAYER 2
        if (!isNullOrUndefined(data)) {
          if (!isNullOrUndefined(data.features)) {
            newLayerData = data.features.filter(x => {
              return !cod.includes(x.properties.GEOCODIG_M);
            });

            newLayerData = featureCollection(newLayerData);
          }
        }

        //REMOVE LAYER 2 ASSIGNED
        if (!isNullOrUndefined(assignedData)) {
          if (!isNullOrUndefined(assignedData.features)) {
            newLayerAssignedData = assignedData.features.filter(x => {
              return !cod.includes(x.properties.GEOCODIG_M);
            });

            newLayerAssignedData = featureCollection(newLayerAssignedData);
          }
        }
        break;
      case 'vu':
        //REMOVE LAYER 2
        if (!isNullOrUndefined(data)) {
          if (!isNullOrUndefined(data.features)) {
            newLayerData = data.features.filter(x => {
              return !x.properties.CLIENT_ID.includes(cod);
            });

            newLayerData = featureCollection(newLayerData);
          }
        }

        //REMOVE LAYER 2 ASSIGNED
        if (!isNullOrUndefined(assignedData)) {
          if (!isNullOrUndefined(assignedData.features)) {
            newLayerAssignedData = assignedData.features.filter(x => {
              return !x.properties.CLIENT_ID.includes(cod);
            });

            newLayerAssignedData = featureCollection(newLayerAssignedData);
          }
        }

        break;
      default:
        break;
    }

    this.calculatePlotResume(newLayerData);
    this.calculatePlotAssignedResume(newLayerAssignedData, allAssignedData);

    this.setState({
      allPlotData: newLayerData,
      allPlotAssignedData: newLayerAssignedData,
      allPlotAllAssignedData: allAssignedData,
      isLoadingPlots: false,
      progressPlot: ""
    });

    if (this.state.showPlots) {
      map.getSource("plot").setData(newLayerData);

      this.setState({
        plotData: newLayerData,
        assignedData: newLayerAssignedData,
        allAssignedData: allAssignedData
      });
    }
  }

  getDataCenter(data) {
    this.setState({ data: data });

    if (data.features.length > 0) {
      var coord = featureCollection(TurfCenter(data)).features.geometry
        .coordinates;
      this.setState({ coordinates: coord });
    }
  }
  getAllTerritories() {
    axios
      .get(`/api/whitespaceView/allTerritory`, {
        method: "GET",
        timeout: 0,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ codNameAllTerritory: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAccountsPolygon(groupId) {
    axios
      .get(`api/whitespaceView/assignedWhitespaces/${groupId}/${this.state.campanha.id}`, {
        method: "GET",
        timeout: 0,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ accountsPolygons: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAccountPolygon(accountId) {
    axios
      .get(`api/whitespaceView/accountWhitespace/${accountId}/${this.state.campanha.id}`, {
        method: "GET",
        timeout: 0,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ accountPolygon: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }
  getAccounData(accountId) {
    axios
      .get(`api/whitespaceView/accountWhitespace/${accountId}/${this.state.campanha.id}`, {
        method: "GET",
        timeout: 0,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.oidc.user.access_token}`,
        },
      })
      .then((json) => {
        this.setState({ accountData: json.data });
      })
      .catch((err) => {
        console.error("Could not load data", err);
      });
  }

  //Pegar os talhões de uma Account
  getAccountPlot() {
    //Pega todos os talhões proximos e internos aos poligonos da Account
    var json = this.state.allPlotData;
    var list = [];
    var ptsWithin = [];
    var result = [];

    try {
      json.data.features.forEach((x) => {
        var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
        point = [
          point.geometry.coordinates[0],
          point.geometry.coordinates[1],
        ];
        point = TurfPoint(point, {
          source: x.properties.COD_TALHAO,
        });
        list.push(point);
      });
      list = featureCollection(list);

      this.state.accountPolygon.features.forEach((x) => {
        var polygon = TurfPolygon(x.geometry.coordinates);
        var within = TurfWithin(list, polygon);

        //Verifica se há pontos em uma X distancia
        const distance = 4;
        for (var i = 0; i < list.features.length; i++) {
          var byDistance = TurfDistance(
            TurfPoint(list.features[i].geometry.coordinates),
            TurfCenter(polygon)
          );
          if (byDistance <= distance) {
            ptsWithin.push(list.features[i].properties.source);
          }
        }

        //Verifica se há pontos dentro do poligno
        within.features.forEach((x) => {
          ptsWithin.push(x.properties.source);
        });
      });

      var account = this.state.accountId;
      json.data.features.forEach((x) => {
        var source = x.properties.COD_TALHAO;
        if (ptsWithin.includes(source) || x.properties.ACCOUNT_ID == account) result.push(x);
      });

      result = featureCollection(result);
      this.setState({ plotData: result });
    }
    catch (e) {
      console.log(e);
    }

    this.setState({ isLoadingToButton: false });
  }
  getAccountPlotAssigned(accountId) {
    //Pega todos os talhões proximos e internos aos poligonos da Account
    var json = this.state.allAssignedData;
    var list = [];
    var ptsWithin = [];
    var result = [];

    json.data.features.forEach((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
      ];
      point = TurfPoint(point, {
        source: x.properties.COD_TALHAO,
      });
      list.push(point);
    });
    list = featureCollection(list);

    this.state.accountPolygon.features.forEach((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var within = TurfWithin(list, polygon);

      //Verifica se há pontos em uma X distancia
      const distance = 4;
      for (var i = 0; i < list.features.length; i++) {
        var byDistance = TurfDistance(
          TurfPoint(list.features[i].geometry.coordinates),
          TurfCenter(polygon)
        );
        if (byDistance <= distance) {
          ptsWithin.push(list.features[i].properties.source);
        }
      }

      //Verifica se há pontos dentro do poligno
      within.features.forEach((x) => {
        ptsWithin.push(x.properties.source);
      });
    });

    var account = this.state.accountId;
    json.data.features.forEach((x) => {
      var source = x.properties.COD_TALHAO;
      if (ptsWithin.includes(source) || x.properties.ACCOUNT_ID == account) result.push(x);
    });

    result = featureCollection(result);
    this.setState({ accountData: result });
  }
  getAssignedVisionPlotsInternal() {
    var json = this.state.allAssignedData;
    var list = [];
    var ptsWithin = [];
    var result = [];

    json.data.features.forEach((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
      ];
      point = TurfPoint(point, {
        source: x.properties.COD_TALHAO,
      });
      list.push(point);
    });
    list = featureCollection(list);

    this.state.accountPolygon.features.forEach((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var within = TurfWithin(list, polygon);

      //Verifica se há pontos em uma X distancia
      const distance = 4;
      for (var i = 0; i < list.features.length; i++) {
        var byDistance = TurfDistance(
          TurfPoint(list.features[i].geometry.coordinates),
          TurfCenter(polygon)
        );
        if (byDistance <= distance) {
          ptsWithin.push(list.features[i].properties.source);
        }
      }

      //Verifica se há pontos dentro do poligno
      within.features.forEach((x) => {
        ptsWithin.push(x.properties.source);
      });
    });

    json.data.features.forEach((x) => {
      var source = x.properties.COD_TALHAO;
      if (ptsWithin.includes(source)) result.push(x);
    });

    result = featureCollection(result);
    this.setState({ assignedData: result });
  }
  getAssignedNVisionPlotsInternal() {
    var json = this.state.allPlotAllAssignedData;
    var list = [];
    var ptsWithin = [];
    var result = [];

    json.data.features.forEach((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
      ];
      point = TurfPoint(point, {
        source: x.properties.COD_TALHAO,
      });
      list.push(point);
    });
    list = featureCollection(list);

    this.state.accountPolygon.features.forEach((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var within = TurfWithin(list, polygon);

      //Verifica se há pontos em uma X distancia
      const distance = 4;
      for (var i = 0; i < list.features.length; i++) {
        var byDistance = TurfDistance(
          TurfPoint(list.features[i].geometry.coordinates),
          TurfCenter(polygon)
        );
        if (byDistance <= distance) {
          ptsWithin.push(list.features[i].properties.source);
        }
      }

      //Verifica se há pontos dentro do poligno
      within.features.forEach((x) => {
        ptsWithin.push(x.properties.source);
      });
    });

    json.data.features.forEach((x) => {
      var source = x.properties.COD_TALHAO;
      if (ptsWithin.includes(source)) result.push(x);
    });

    result = featureCollection(result);
    this.setState({ allAssignedData: result });
  }
  async getInternalPlotData(groupId, accountPolygon, assign) {
    //Pega os talhões dentro dos poligonos das accounts
    var json = this.state.allPlotData;
    var list = [];
    var ptsWithin = [];

    var idAccount = this.state.accountId;
    var nameAccount = this.state.accountName;

    var listPolygons = [];
    var listAPIPolygons = [];

    json.data.features.forEach((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
      ];
      point = TurfPoint(point, {
        source: x.properties.COD_TALHAO,
      });
      list.push(point);
    });
    list = featureCollection(list);

    accountPolygon.features.forEach((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var within = TurfWithin(list, polygon);

      //Verifica se há pontos dentro do poligno
      within.features.forEach((x) => {
        ptsWithin.push(x.properties.source);
      });

      x.properties.PLOT_COUNT = within.features.length;
      listAPIPolygons.push({
        Source: x.properties.COD_IMOVEL,
        Body: JSON.stringify(featureCollection([x])),
      });
      listPolygons.push({ Source: x.properties.COD_IMOVEL, Data: x });
    });

    this.props.actions.updateListPolygon(listAPIPolygons);
    this.updateInListPolygon(listPolygons);

    if (ptsWithin.length > 0) {
      var newData = json.data;
      var listPlot = [];

      json.data.features.forEach((x, index) => {
        var internalSource =
          x.properties.COD_TALHAO;
        ptsWithin.forEach((y) => {
          if (y === internalSource) {
            var promisedData = newData.features[index];
            if (assign) {
              promisedData.properties["ACCOUNT"] = nameAccount;
              promisedData.properties["ACCOUNT_ID"] = idAccount;
            } else {
              promisedData.properties.ACCOUNT = null;
              promisedData.properties.ACCOUNT_ID = null;
            }

            listPlot.push({
              Source: y,
              Id: idAccount,
              Body: JSON.stringify(featureCollection([promisedData])),
            });
          }
        });
      });

      if (assign) {
        this.props.actions.createAccountPlot(listPlot);
      } else {
        this.props.actions.deleteAccountPlot(listPlot);
      }
    }
  }

  //Pegar em um talhão especifico
  getPlotInWhitespace(whitespace, isSelected) {
    var json = this.state.allPlotData;

    try {
      this.setState({ polygonRegion: whitespace });
      if (whitespace == null) {
        this.setState({ plotData: featureCollection([]) });
        return;
      }

      var list = [];
      var ptsWithin = [];
      var result = [];

      if (isSelected) {
        json.features.forEach((x) => {
          var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
          point = [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ];
          point = TurfPoint(point, {
            source: x.properties.COD_TALHAO,
          });
          list.push(point);
        });
        list = featureCollection(list);

        whitespace.features.forEach((x) => {
          var polygon = TurfPolygon(x.geometry.coordinates);
          var within = TurfWithin(list, polygon);

          if (this.state.accountId != "") {
            //Verifica se há pontos em uma X distancia
            const distance = 4;
            for (var i = 0; i < list.features.length; i++) {
              var byDistance = TurfDistance(
                TurfPoint(list.features[i].geometry.coordinates),
                TurfCenter(polygon)
              );
              if (byDistance <= distance) {
                ptsWithin.push(list.features[i].properties.source);
              }
            }
          }

          //Verifica se há pontos dentro do poligno
          within.features.forEach((x) => {
            ptsWithin.push(x.properties.source);
          });
        });

        json.features.forEach((x) => {
          var source = x.properties.COD_TALHAO;
          if (ptsWithin.includes(source)) result.push(x);
        });

        result = featureCollection(result);
      }
      else {
        result = json;
      }

      this.setState({ plotData: result });
    }
    catch (e) {
      console.log(e);
    }

    this.setState({
      isLoadingPlots: false,
      progressPlot: "",
      isLoadingToButton: false
    });
  }
  getAllAssignedPlotVision(whitespace) {
    if (whitespace == null) {
      this.setState({ assignedData: featureCollection([]) });
      return;
    }

    var json = this.state.allAssignedData;
    var list = [];
    var ptsWithin = [];
    var result = [];

    json.data.features.forEach((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
      ];
      point = TurfPoint(point, {
        source: x.properties.COD_TALHAO,
      });
      list.push(point);
    });
    list = featureCollection(list);

    whitespace.forEach((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var within = TurfWithin(list, polygon);

      //Verifica se há pontos em uma X distancia
      const distance = 4;
      for (var i = 0; i < list.features.length; i++) {
        var byDistance = TurfDistance(
          TurfPoint(list.features[i].geometry.coordinates),
          TurfCenter(polygon)
        );
        if (byDistance <= distance) {
          ptsWithin.push(list.features[i].properties.source);
        }
      }

      //Verifica se há pontos dentro do poligno
      within.features.forEach((x) => {
        ptsWithin.push(x.properties.source);
      });
    });

    json.data.features.forEach((x) => {
      var source = x.properties.COD_TALHAO;
      if (ptsWithin.includes(source)) result.push(x);
    });

    result = featureCollection(result);
    this.setState({ assignedData: result });
  }
  getAllAssignedPlotNVision(whitespace) {
    var json = this.state.allAssignedData;
    if (whitespace == null) {
      this.setState({ allAssignedData: featureCollection([]) });
      return;
    }

    var list = [];
    var ptsWithin = [];
    var result = [];

    json.data.features.forEach((x) => {
      var point = TurfCenter(TurfPolygon(x.geometry.coordinates));
      point = [
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
      ];
      point = TurfPoint(point, {
        source: x.properties.COD_TALHAO,
      });
      list.push(point);
    });
    list = featureCollection(list);

    whitespace.forEach((x) => {
      var polygon = TurfPolygon(x.geometry.coordinates);
      var within = TurfWithin(list, polygon);

      //Verifica se há pontos em uma X distancia
      const distance = 4;
      for (var i = 0; i < list.features.length; i++) {
        var byDistance = TurfDistance(
          TurfPoint(list.features[i].geometry.coordinates),
          TurfCenter(polygon)
        );
        if (byDistance <= distance) {
          ptsWithin.push(list.features[i].properties.source);
        }
      }

      //Verifica se há pontos dentro do poligno
      within.features.forEach((x) => {
        ptsWithin.push(x.properties.source);
      });
    });

    json.data.features.forEach((x) => {
      var source = x.properties.COD_TALHAO;
      if (ptsWithin.includes(source)) result.push(x);
    });

    result = featureCollection(result);
    this.setState({ allAssignedData: result });
  }

  calculateCountyResume(data) {
    if (isNullOrUndefined(data.features))
      return;

    const dataCultures = this.props.culturasWhitespace.cultures;

    let cultureA = [];
    let cultureP = [];

    const CountFarm = data.features.reduce((sum, x) => sum + Number(x.properties.ContagemFazendas), 0).toFixed(1);
    const CountArea = data.features.reduce((sum, x) => {
      const areaValue = Number(x.properties.AreaTotalFazendas);

      if (!isNaN(areaValue) && areaValue !== null) {
        return sum + areaValue;
      }

      return sum;
    }, 0).toFixed(1);

    const CountFarmAssigned = data.features.reduce((sum, x) => sum + Number(x.properties.FazendasConhecidas), 0).toFixed(1);
    const CountFarmAreaAssigned = data.features.reduce((sum, x) => {
      const areaValue = Number(x.properties.AreaTotalFazendasConhecidas);

      if (!isNaN(areaValue) && areaValue !== null) {
        return sum + areaValue;
      }

      return sum;
    }, 0).toFixed(1);

    const CountPlot = data.features.reduce((sum, x) => sum + Number(x.properties.ContagemTalhoes), 0).toFixed(1);
    const CountPlotArea = data.features.reduce((sum, x) => {
      const areaValue = Number(x.properties.AreaTotalTalhoes);

      if (!isNaN(areaValue) && areaValue !== null) {
        return sum + areaValue;
      }

      return sum;
    }, 0).toFixed(1);

    const CountPlotAssigned = data.features.reduce((sum, x) => sum + Number(x.properties.TalhoesConhecidas), 0).toFixed(1);
    const CountPlotAreaAssigned = data.features.reduce((sum, x) => {
      const areaValue = Number(x.properties.AreaTotalTalhoesConhecidas);

      if (!isNaN(areaValue) && areaValue !== null) {
        return sum + areaValue;
      }

      return sum;
    }, 0).toFixed(1);

    data.features.forEach(x => {
      if (!isNullOrUndefined(x.properties.TalhoesCulturas)) {
        let cultures = JSON.parse(x.properties.TalhoesCulturas);
        if (!isNullOrUndefined(cultures)) {
          let newCultureA = cultures.uniqueA.map(x => {
            let cultureName = "";
            if (x.Culture == "Empty") {
              cultureName = "Vazio";
            }
            else {
              cultureName = dataCultures.find(y => y.value == x.Culture).name;
            }

            return { culture: cultureName, area: x.TotalArea, color: '' }
          });

          let newCultureP = cultures.uniqueP.map(x => {
            let cultureName = "";
            if (x.Culture == "Empty") {
              cultureName = "Vazio";
            }
            else {
              cultureName = dataCultures.find(y => y.value == x.Culture).name;
            }

            return { culture: cultureName, area: x.TotalArea, color: '' }
          });

          cultureA.push(...newCultureA);
          cultureP.push(...newCultureP);
        }
      }
    });

    const aggregateCultures = (cultureArray) =>
      cultureArray.reduce((agg, item) => {
        if (!agg[item.culture]) {
          agg[item.culture] = { area: 0, color: item.color || "" };
        }
        agg[item.culture].area += item.area;
        return agg;
      }, {});

    this.setState({
      countFarm: CountFarm,
      countAssignedFarm: CountFarmAssigned,
      valueAreaFarm: CountArea,
      valueAreaAssignedFarm: CountFarmAreaAssigned,

      countPlot: CountPlot,
      valueAreaPlot: CountPlotArea,
      countAssignedPlot: CountPlotAssigned,
      valueAreaAssignedPlot: CountPlotAreaAssigned,

      pointedCultureArea: aggregateCultures(cultureA),
      predictCultureArea: aggregateCultures(cultureP),
    });
  }
  calculatePlotResume(data) {
    const cultures = this.props.culturasWhitespace.cultures;

    let totalArea = 0;
    let cultureA = [];
    let cultureP = [];

    if (!isNullOrUndefined(data) && !isNullOrUndefined(data.features)) {
      data.features.forEach((feature) => {
        try {
          const area = Number(feature.properties.Area);
          totalArea += area;

          const cultureApont = cultures.find(x => x.value === feature.properties.CULT_APONT);
          const culturePredit = cultures.find(x => x.value === feature.properties.CULT_PREDIT);

          if (!isNullOrUndefined(cultureApont)) {
            cultureA.push({ culture: cultureApont.name, area });
          }
          else {
            cultureA.push({ culture: "Vazio", area });
          }

          if (!isNullOrUndefined(culturePredit)) {
            cultureP.push({ culture: culturePredit.name, area });
          }
          else {
            cultureP.push({ culture: "Vazio", area });
          }
        } catch (e) {
          console.error("An error occurred while processing a feature:", e);
          console.log("Problematic feature:", feature);
        }
      });

      // Aggregate areas based on unique cultures for both CULT_APONT and CULT_PREDIT
      const aggregateCultures = (cultureArray) =>
        cultureArray.reduce((agg, item) => {
          if (!agg[item.culture]) {
            agg[item.culture] = { area: 0, color: item.color || "" };
          }
          agg[item.culture].area += item.area;
          return agg;
        }, {});

      const uniqueA = aggregateCultures(cultureA);
      const uniqueP = aggregateCultures(cultureP);

      // Ensure 'totalArea' is a float with one decimal point
      totalArea = parseFloat(totalArea.toFixed(1));

      this.setState({
        valueAreaPlot: totalArea,
        countPlot: data.features.length,
        pointedCultureArea: uniqueA,
        predictCultureArea: uniqueP,
      });
    } else {
      // Set state to default values if 'data.features' is null or undefined
      this.setState({
        valueAreaPlot: 0,
        countPlot: 0,
        pointedCultureArea: [{ culture: "", area: 0 }],
        predictCultureArea: [{ culture: "", area: 0 }],
      });
    }
  }
  calculatePlotAssignedResume(assigned, allAssigned) {
    // Verifica se 'assigned' não é nulo ou indefinido e se possui 'features'
    if (!isNullOrUndefined(assigned) && !isNullOrUndefined(assigned.features)) {
      // Combina as features se 'allAssigned' estiver presente, senão utiliza 'assigned.features'
      const features = allAssigned ? assigned.features.concat(allAssigned.features) : assigned.features;

      // Calcula a soma da área das features em 'features'
      const countArea = features.reduce((sum, x) => sum + Number(x.properties.Area), 0).toFixed(2);

      // Atualiza o estado do componente com os valores calculados
      this.setState({
        valueAreaAssignedPlot: countArea,
        countAssignedPlot: features.length,
      });
    } else {
      // Define o estado para os valores padrão se 'assigned' ou 'assigned.features' for nulo ou indefinido
      this.setState({
        valueAreaAssignedPlot: 0,
        countAssignedPlot: 0,
      });
    }
  }
  calculateFarmResume(data) {
    // Verifica se 'data.features' existe
    if (data.features) {
      try {
        // Calcula a soma das áreas das features em 'data'
        const countArea = data.features.reduce((sum, x) => sum + Number(x.properties.NUM_AREA), 0).toFixed(1);

        // Atualiza o estado do componente com os valores calculados
        this.setState({
          valueAreaFarm: countArea,
          countFarm: data.features.length,
        });
      } catch (e) {
        // Trata quaisquer erros que possam ocorrer durante o cálculo
        console.error("Ocorreu um erro:", e);

        // Define o estado para os valores padrão em caso de erro
        this.setState({
          valueAreaFarm: 0,
          countFarm: 0,
        });
      }
    } else {
      // Define o estado para os valores padrão se 'data.features' não existir
      this.setState({
        valueAreaFarm: 0,
        countFarm: 0,
      });
    }
  }
  calculateFarmAssignedResume(assigned, allAssigned) {
    // Verifica se 'assigned' não é nulo ou indefinido e se possui 'features'
    if (!isNullOrUndefined(assigned) && !isNullOrUndefined(assigned.features)) {
      var features = assigned.features;

      if (!isNullOrUndefined(allAssigned) && !isNullOrUndefined(allAssigned.features)) {
        features = assigned.features.concat(allAssigned.features);
      }

      // Converte as features em uma coleção de features usando a função 'featureCollection'
      const data = featureCollection(features);

      try {
        // Calcula as coordenadas do centro da coleção de features usando o Turf
        const coord = TurfCenter(data).geometry.coordinates;

        // Atualiza o estado do componente com os valores calculados
        this.setState({
          coordinates: coord,
          valueAreaAssignedFarm: data.features.reduce((sum, x) => sum + Number(x.properties.NUM_AREA), 0).toFixed(1),
          countAssignedFarm: data.features.length,
        });
      } catch (e) {
        // Trata quaisquer erros que possam ocorrer durante o cálculo do TurfCenter
        console.error("Ocorreu um erro:", e);

        // Define o estado para os valores padrão em caso de erro
        this.setState({
          valueAreaAssignedFarm: 0,
          countAssignedFarm: 0,
        });
      }
    } else {
      // Define o estado para os valores padrão se 'assigned' ou 'assigned.features' for nulo ou indefinido
      this.setState({
        valueAreaAssignedFarm: 0,
        countAssignedFarm: 0,
      });
    }
  }

  ativarSelect = () => {
    this.setState((prevState) => ({ menuOpen: !prevState.menuOpen }));
  };

  onChangeArea(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ area: newInputValue });
      this.setState({ distrito: undefined });
      this.setState({ territorio: undefined });
      localStorage.setItem("Area", JSON.stringify(newInputValue));
      localStorage.removeItem("Distrito");
      localStorage.removeItem("Territorio");
    }
    else {
      this.setState({ area: options[0] });
      localStorage.setItem("Area", JSON.stringify(options[0]));
    }
  }

  onChangeDistrito(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ distrito: newInputValue });
      this.setState({ territorio: undefined });
      localStorage.setItem("Distrito", JSON.stringify(newInputValue));
      localStorage.removeItem("Territorio");

    }
    else {
      this.setState({ distrito: options[0] });
      localStorage.setItem("Distrito", JSON.stringify(options[0]));
    }

  }

  onChangeTerritorio(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ territorio: newInputValue });
      localStorage.setItem("Territorio", JSON.stringify(newInputValue));

      this.props.actions.changeTerritorio(newInputValue.territoryId);
      this.props.actions.getMunicipios(this.state.groupId).then(() => {
        this.setState({ municipios: this.props.whitespace.municipios });
      });
    }
    else {
      this.setState({ territorio: options[0] });
      localStorage.setItem("Territorio", JSON.stringify(options[0]));
    }
  }

  setByStorage(areas = [], distritos = [], territorios = []) {
    var area = JSON.parse(localStorage.getItem("Area"))
    var distrito = JSON.parse(localStorage.getItem("Distrito"))
    var territorio = JSON.parse(localStorage.getItem("Territorio"))
    if (area && areas.find(x => x === area) !== undefined) {
      this.setState({ area: area });
    } else {
      localStorage.removeItem("Area")
    }
    if (distrito && distritos.find(x => x.districtName === distrito.districtName) !== undefined) {
      this.setState({ distrito: distrito });
    } else {
      localStorage.removeItem("Distrito")
    }
    if (territorio && territorios.find(x => x.territoryName === territorio.territoryName) !== undefined) {
      this.setState({ territorio: territorio });
      this.props.actions.changeTerritorio(territorio.territoryId);
    } else {
      localStorage.removeItem("Territorio")
    }

    this.setState({ isSetStorage: true });
  }

  componentWillMount() {
    this.props.actions.requestFormattedClients();
    this.props.actions.requestCampaings();
    this.props.actions.getEstados();
    this.props.actions.getSafra();
    this.props.actions.requestSafras();
    this.props.actions.requestCultures();

    const { oidc } = this.props;
    if (oidc && oidc?.user && oidc.user.access_token) {
      connection = configure(oidc);
    }
  }
  componentDidMount() {
    this.getAllTerritories();

    var a = window.document.getElementById("Map");
    a.scrollIntoView();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.whitespace.campanhas !== this.props.whitespace.campanhas) {
      const activeCampaing = this.props.whitespace.campanhas.find(c => c.default);
      if (activeCampaing) {
        this.setState({ campanha: activeCampaing });
      }
    }
  }
  componentWillUnmount() {
    this.setState({ oldData: [] });
  }

  render() {
    const { oidc } = this.props;
    var clients = [];
    var campaings = [];
    var states = [];

    var isLoading = (this.props.clientes.isLoading ||
      this.props.whitespace.isLoadingAccounts ||
      this.props.user.isLoadingTerritorios ||
      this.props.user.isLoadingRole ||
      this.props.user.isLoadingRoleLevels) &&
      !this.state.error;

    var isLoadingMap = (this.state.isLoadingData ||
      this.props.whitespace.isLoadingWhitespaces ||
      this.props.culturasWhitespace.isLoading ||
      this.state.isLoading ||
      this.state.isLoadingToButton) &&
      !this.state.error

    if (this.props.whitespace) {
      if (!this.props.whitespace.isLoadingCampanhas) {
        campaings = this.props.whitespace.campanhas;
      }

      if (!this.props.whitespace.isLoadingEstados) {
        states = this.props.whitespace.estados;
      }

      if (!this.props.whitespace.isLoadingClients) {
        clients = this.props.whitespace.clients.sort((a, b) => a.uf > b.uf ? 1 : b.uf > a.uf ? -1 : 0);
      }
    }

    return (
      <div className="card">
        <BoxError
          open={this.state.error}
          onClose={this.handleCloseError}
          title={this.state.errorTitle}
          message={this.state.errorMessage}
        />

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#141414",
          }}
          open={isLoading}
        >
          <Stack spacing={2} alignItems="center" direction="column">
            <div
              style={{
                color: "white",
                fontFamily: "Gilroy",
                fontWeight: "Bold",
              }}
            >
              {"Baixando dados do servidor..."}
            </div>
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>

        <div className="card-header">
          <Grid
            container
            spacing={2}
            sx={{
              "* a": {
                color: "var(--cort-blue) !important",
              },
            }}
          >
            <Grid item className="align-self-end">
              <Link to={"/"}>
                <ArrowBackIcon />
              </Link>
            </Grid>
            <Grid item xs={11} className="align-self-end">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to={"/"}>
                  Home
                </Link>
                <Typography color="text.primary">Whitespace Analytics</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
        <div id="Map" style={{ borderRadius: "8px" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50px",
              left: "10px",
              textAlign: "center",
              padding: "8px",
              borderRadius: "8px",
              width: "290px",
              backgroundColor: "white",
              zIndex: (theme) => theme.zIndex.drawer,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} sx={{ alignItems: "center", maxWidth: "230px" }}>
                  {this.state.selectedKey === 0 && (
                    <StatePagination
                      state={states}
                      addData={this.addData}
                      changeLoadState={this.changeLoadState}
                      isLoading={this.state.isLoading}
                    />
                  )}
                  {this.state.selectedKey === 1 && (
                    <CountyPagination
                      counties={this.state.codNameAllTerritory}
                      addData={this.addData}
                      changeLoadState={this.changeLoadState}
                      isLoading={this.state.isLoading}
                      removeData={this.removeData}
                    />
                  )}
                  {this.state.selectedKey === 2 && (
                    <UniqueVisionPagination
                      clients={clients}
                      addData={this.addData}
                      changeLoadState={this.changeLoadState}
                      isLoading={this.state.isLoading}
                      removeData={this.removeData}
                    />
                  )}
                  <FormControl variant="standard">
                    <Select
                      sx={{
                        height: "42px",
                        color: "black",
                        width: "200px",
                        textAlign: "left",
                        'div': {
                          padding: "0px 0px 0px 8px !important",
                        },
                        'span': {
                          fontFamily: "Gilroy !important",
                          fontSize: "1.25rem !important",
                          fontWeight: "bold !important",
                        },
                        '::before': {
                          border: "none !important",
                        },
                        '::after': {
                          border: "none !important",
                        }
                      }}
                      onChange={this.onChangeKey}
                      value={this.state.selectedKey}
                    >
                      <MenuItem key={0} value={0}>
                        <ListItemText primary={"Estados"} />
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        <ListItemText primary={"Municípios"} />
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        <ListItemText primary={"Visões Únicas"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <FormControl variant="standard"
            sx={{
              position: "absolute",
              top: "115px",
              left: "10px",
              textAlign: "center",
              borderRadius: "8px",
              padding: "8px",
              width: "290px",
              height: "35px",
              justifyContent: 'center',
              color: "white",
              backgroundColor: "var(--cort-blue)",
              zIndex: (theme) => theme.zIndex.drawer,
              ':hover': {
                cursor: "pointer"
              }
            }}
            onClick={this.ativarSelect}
          >
            <Typography
              style={{
                fontFamily: "Gilroy",
                fontWeight: "Bold",
                textAlign: "center",
                fontSize: "1.25rem",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div>
                {this.state.campanha && this.state.campanha.nome}
                <SwapHorizIcon
                  sx={{
                    color: 'white',
                    textAlign: 'center'
                  }}
                />
              </div>
            </Typography>

            {this.state.menuOpen && (
              <Paper
                sx={{
                  position: 'absolute',
                  top: 'calc(100% + 8px)',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  borderRadius: '8px',
                  padding: '8px',
                  width: '290px',
                  color: 'black',
                  backgroundColor: 'white',
                  maxHeight: '100px',
                  overflowY: 'auto',
                  zIndex: (theme) => theme.zIndex.drawer,
                }}
              >
                {campaings && campaings?.sort((a, b) => new Date(b.dataFim) - new Date(a.dataFim))
                  .map((x, index) => (
                    <div
                      key={index}
                      onClick={() => this.changeSafra(x)}
                      style={{
                        fontFamily: 'Gilroy',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        padding: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      {x.nome}
                    </div>
                  ))}
              </Paper>
            )}
          </FormControl>

          <Stack
            justifyContent="flex-start"
            alignItems="center"
            direction={"row"}
            spacing={2}
            sx={{ height: "calc(100vh - 159px)", borderRadius: "0 0 8px 8px" }}
          >
            <Map
              actions={this.props.actions}
              showingStates={this.state.showingStates}
              progressFarm={this.state.progressFarm}
              copySourceCodes={this.copySourceCodes}
              goTo={this.goTo}
              goToPolygon={this.goToPolygon}
              data={this.state.data}
              counties={this.state.countyData}
              polygonRegion={this.state.polygonRegion}
              plotData={this.state.plotData}
              plotInfo={this.state.dataPlotPolygon}
              farmInfo={this.state.dataPolygon}
              assignedData={this.state.assignedData}
              allAssignedData={this.state.allAssignedData}
              accountData={this.state.accountData}
              farmSelected={this.state.sourcePolygon}
              plotSelected={this.state.sourcePlotPolygon}
              showAllData={this.state.showAllData}

              showPlots={this.state.showPlots}
              hasPlots={this.state.hasPlots}
              isLoadingPlots={this.state.isLoadingPlots}
              progressPlot={this.state.progressPlot}
              setShowPlots={this.setShowPlots}

              setMapbox={this.setMapbox}
              setDraw={this.setDraw}
              isLoading={isLoadingMap}
              changeLoadState={this.changeFarmLoad}
              coordinates={this.state.coordinates}
              municipios={this.state.municipios}
              createPolygon={this.createPolygon}
              createMultiplesPolygons={this.createMultiplesPolygons}
              selectPolygon={this.selectPolygon}
              updatePolygon={this.updatePolygon}
              deletePolygon={this.deletePolygon}
              disablePolygon={this.disablePolygon}
              selectPlotPolygon={this.selectPlotPolygon}
              updatePlotPolygon={this.updatePlotPolygon}
              deletePlotPolygon={this.deletePlotPolygon}
              disablePlotPolygon={this.disablePlotPolygon}
              cleanList={this.cleanList}
              countFarm={this.state.countFarm}
              valueAreaFarm={this.state.valueAreaFarm}
              countAssignedFarm={this.state.countAssignedFarm}
              valueAreaAssignedFarm={this.state.valueAreaAssignedFarm}
              countPlot={this.state.countPlot}
              valueAreaPlot={this.state.valueAreaPlot}
              countAssignedPlot={this.state.countAssignedPlot}
              valueAreaAssignedPlot={this.state.valueAreaAssignedPlot}
              pointedCultureArea={this.state.pointedCultureArea}
              predictCultureArea={this.state.predictCultureArea}
              codNameAll={this.state.codNameAllTerritory}
              selectData={this.state.selectData}
              setSelectData={this.setSelectData}
              allPlotData={this.state.allPlotData}
              sumSelectFarmArea={this.state.sumSelectFarmArea}
              sumSelectPlotArea={this.state.sumSelectPlotArea}
              marker={this.state.marker}
              markerOn={this.state.markerOn}
              onClickMarker={this.handleEnableMarker}
              addData={this.addData}
              removeData={this.removeData}
              cultures={this.props.culturasWhitespace.cultures}
              plotLayer={this.props.culturasWhitespace.plotLayer}
              plotApontLayer={this.props.culturasWhitespace.plotApontLayer}
              plotPreditLayer={this.props.culturasWhitespace.plotPreditLayer}
            />
          </Stack>
        </div>
      </div >
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...whitespaceActions,
          ...culturasWhitespaceActions,
          ...clientActions,
          ...userActions
        },
        dispatch
      ),
    };
  }
)(WhiteSpaceView);