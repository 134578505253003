import * as React from 'react';

import { Stack, Tooltip, Button, Dialog, DialogActions, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Box, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArticleIcon from '@mui/icons-material/Article';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

function formatNumber(num) {
  return num?.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) ?? "";
}

function GroupAndSumRows(data) {
  const groupedData = {};

  if (data != null && data.length > 0) {
    data.forEach(item => {
      const canal = item.canal + item.raizCNPJ;
      if (!groupedData[canal]) {
        groupedData[canal] = {
          canal: "",
          cnpj: "",
          estoqueDisponivelAnterior: 0,
          estoqueFisicoAnterior: 0,
          estoqueDisponivel: 0,
          estoqueFisico: 0,
          estoqueFisicoIntegra: 0,
          estoqueDisponivelIntegra: 0,
          saldoEstoque: 0,
          sellInCorteva: 0,
          sellInIntegra: 0,
          sellOutIntegra: 0,
          countRows: 0,
          validRows: 0,
          products: []
        };
      }

      groupedData[canal].canal = item.canal;
      groupedData[canal].cnpj = item.raizCNPJ;

      groupedData[canal].estoqueFisicoAnterior += item.estoqueFisicoAnterior;
      groupedData[canal].estoqueDisponivelAnterior += item.estoqueDisponivelAnterior;
      groupedData[canal].estoqueDisponivel += item.estoqueDisponivel;
      groupedData[canal].estoqueFisico += item.estoqueFisico;
      groupedData[canal].estoqueFisicoIntegra += item.estoqueFisicoIntegra;
      groupedData[canal].estoqueDisponivelIntegra += item.estoqueDisponivelIntegra;
      groupedData[canal].saldoEstoque += item.saldoEstoque;
      groupedData[canal].sellInCorteva += item.sellInCorteva;
      groupedData[canal].sellInIntegra += item.sellInIntegra;
      groupedData[canal].sellOutIntegra += item.sellOutIntegra;
      groupedData[canal].countRows += 1;
      groupedData[canal].validRows += item.estoqueValido ? 1 : 0;

      let existingProduct = groupedData[canal].products.find(x => x.produto === item.produto);
      if (existingProduct) {
        existingProduct.estoqueDisponivelAnterior += item.estoqueDisponivelAnterior;
        existingProduct.estoqueFisicoAnterior += item.estoqueFisicoAnterior;
        existingProduct.estoqueDisponivel += item.estoqueDisponivel;
        existingProduct.estoqueFisico += item.estoqueFisico;
        existingProduct.estoqueFisicoIntegra += item.estoqueFisicoIntegra;
        existingProduct.saldoEstoque += item.saldoEstoque;
        existingProduct.sellInCorteva += item.sellInCorteva;
        existingProduct.sellInIntegra += item.sellInIntegra;
        existingProduct.sellOutIntegra += item.sellOutIntegra;
      } else {
        groupedData[canal].products.push({ ...item });
      }

      groupedData[canal].totalValidated = formatNumber(groupedData[canal].validRows) / formatNumber(groupedData[canal].countRows);
    });
  }

  return groupedData;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = Object.entries(array).map(([_, el], index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: '',
    label: '',
  },
  {
    id: 'canal',
    label: 'Canal',
    style: {
      minWidth: "130px !important",
      maxWidth: "130px !important"
    }
  },
  {
    id: 'cnpj',
    label: 'Raiz CNPJ',
    subLabel: 'Produto',
    icon: <SubdirectoryArrowRightIcon sx={{ position: "relative", top: "-4px", left: "0px", fontSize: "1rem" }} />,
    style: {
      minWidth: "90px !important",
      maxWidth: "90px !important"
    }
  },
  {
    id: 'estoqueFisicoAnterior',
    numeric: true,
    label: 'Estoque Físico Anterior',
  },
  {
    id: 'estoqueDisponivelAnterior',
    numeric: true,
    label: 'Estoque Disponível Anterior',
  },
  // {
  //   id: 'sellInCorteva',
  //   numeric: true,
  //   label: 'Sell In Corteva',
  // },
  // {
  //   id: 'sellInIntegra',
  //   numeric: true,
  //   label: 'Sell In Integra',
  // },
  // {
  //   id: 'sellOutIntegra',
  //   numeric: true,
  //   label: 'Sell Out Integra',
  // },
  // {
  //   id: 'saldoEstoque',
  //   numeric: true,
  //   label: 'Saldo Estoque',
  // },
  {
    id: 'estoqueFisicoIntegra',
    numeric: true,
    label: 'Estoque Físico Integra',
  },
  {
    id: 'estoqueDisponivelIntegra',
    numeric: true,
    label: 'Estoque Disponível Integra',
  },
  {
    id: 'estoqueFisico',
    numeric: true,
    label: 'Estoque Físico',
  },
  {
    id: 'estoqueDisponivel',
    numeric: true,
    label: 'Estoque Disponível',
  },
  {
    id: 'totalValidated',
    label: 'Preenchido',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              sx={{ color: "#fff !important", '*': { color: "#fff !important" }, justifyContent: "center", ...headCell.style }}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.subLabel ?
                <Stack direction='column'>
                  <span>{headCell.label}</span>
                  <span>
                    {headCell.icon}
                    {headCell.subLabel}
                  </span>
                </Stack>
                :
                headCell.label
              }
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTable({ rows }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('canal');
  const [open, setOpen] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleToggle = (index) => {
    setOpen(prevOpen => {
      const newOpen = [...prevOpen];
      newOpen[index] = prevOpen[index] != null ? !prevOpen[index] : true;
      return newOpen;
    });
  };

  const orderRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)), [rows, order, orderBy]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer
          sx={{
            maxHeight: 440,
            'th': {
              backgroundColor: 'var(--cort-blue) !important',
              color: '#FFF !important',
              fontWeight: 'bold',
              fontSize: "0.7rem",
              padding: "4px 8px",
              textAlign: "center",
              '& > *': {
                textAlign: "center",
                fontWeight: 'bold',
                fontSize: "0.7rem",
              }
            },
            'td': {
              backgroundColor: '#fff !important',
              textAlign: "center",
              fontSize: "0.7rem",
              padding: "4px"
            }
          }}>
          <Table
            sx={{ minWidth: 750 }}
            stickyHeader
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {orderRows.map((row, index) => {
                return (
                  <>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleToggle(index)}
                        >
                          {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        {row.canal}
                      </TableCell>
                      <TableCell align="center">
                        {row.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5")}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.estoqueFisicoAnterior)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.estoqueDisponivelAnterior)}
                      </TableCell>
                      {/* <TableCell align="center">
                        {formatNumber(row.sellInCorteva)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.sellInIntegra)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.sellOutIntegra)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.saldoEstoque)}
                      </TableCell> */}
                      <TableCell align="center">
                        {formatNumber(row.estoqueFisicoIntegra)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.estoqueDisponivelIntegra)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.estoqueFisico)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.estoqueDisponivel)}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumber(row.validRows)} / {formatNumber(row.countRows)}
                      </TableCell>
                    </TableRow >
                    {
                      open[index] && row.products.map((productRow) => (
                        <TableRow key={productRow.date}>
                          <TableCell
                            sx={{
                              position: "sticky",
                              left: "0px"
                            }}
                            colSpan={2}
                          >
                          </TableCell>
                          <TableCell sx={{ position: "sticky", left: "100px", backgroundColor: "#fff" }}>
                            {productRow.produto}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.estoqueFisicoAnterior)}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.estoqueDisponivelAnterior)}
                          </TableCell>
                          {/* <TableCell align="center">
                            {formatNumber(productRow.sellInCorteva)}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.sellInIntegra)}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.sellOutIntegra)}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.saldoEstoque)}
                          </TableCell> */}
                          <TableCell align="center">
                            {formatNumber(productRow.estoqueFisicoIntegra)}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.estoqueDisponivelIntegra)}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.estoqueFisico)}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumber(productRow.estoqueDisponivel)}
                          </TableCell>
                          <TableCell align="center">
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box >
  );
}

export default function FormDialog({ rows }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const grouppedRows = GroupAndSumRows(rows);

  return (
    <React.Fragment>
      <Tooltip title="Resumo">
        <IconButton
          sx={{
            color: "white",
            backgroundColor: "var(--cort-blue)",
            borderRadius: "8px",
            '&:hover': {
              color: "var(--cort-blue)",
              backgroundColor: "white"
            }
          }}
          onClick={handleClickOpen}
        >
          <ArticleIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} sx={{ '.MuiDialog-paper': { maxWidth: "90vw" } }}>
        <DialogContent sx={{ padding: "0px" }}>
          <EnhancedTable rows={grouppedRows} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
}