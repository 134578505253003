const requestCategoryDiscountType = "REQUEST_CATEGORYDISCOUNT";
const receiveCategoryDiscountType = "RECEIVE_CATEGORYDISCOUNT";

const initialState = { CategoryDiscount: [], isLoading: false };

export const categoryDiscountActions = {
  requestCategoryDiscount: (campanha, produto, clienteProps, cliente) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCategoryDiscountType });
    var distrito = ""

    for (var j = 0; j < clienteProps.length; j++) {
      // eslint-disable-next-line eqeqeq
      if (clienteProps[j].id == cliente) {

        distrito = clienteProps[j].district
      }
    }

    const url = `/api/simuladorcategorydiscount`;

    const options = {
      method: "POST",
      body: JSON.stringify({ campanhaId: produto.campaign.groupedCampaign, tipoCliente: "F", produto: produto.product, distrito: distrito }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const CategoryDiscount = await response.json();
    dispatch({ type: receiveCategoryDiscountType, CategoryDiscount });
  },
  requestCategoryDiscount2: (campanha, produto, cliente) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCategoryDiscountType });
    var distrito = ""

    if (cliente) {
      distrito = cliente.distrito
    }

    const url = `/api/Simulador2/SimuladorDescontoCategoria`;

    const options = {
      method: "POST",
      body: JSON.stringify({ campanhaId: produto.campaign.groupedCampaign, tipoCliente: "F", produto: produto.product, distrito: distrito }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.status === 200) {
      const CategoryDiscount = await response.json();
      dispatch({ type: receiveCategoryDiscountType, CategoryDiscount });
    } else {
      dispatch({ type: receiveCategoryDiscountType, CategoryDiscount: { categoryDiscount: 0 } });

    }
  },
};

export const reducer = (state, action) => {

  state = state || initialState;

  if (action.type === requestCategoryDiscountType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveCategoryDiscountType) {
    return {
      ...state,
      CategoryDiscount: action.CategoryDiscount,
      isLoading: false,
    };
  }

  return state;
};
