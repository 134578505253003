import { HandleResponse, isNullOrEmpty } from "../helpers";

const requestGetType = "VALOR_AREA_LEADER_REQUEST_GET";
const receiveGetType = "VALOR_AREA_LEADER_RECEIVE_GET";
const failureGetType = "VALOR_AREA_LEADER_FAILURE_GET";

const requestUpdateType = "VALOR_AREA_LEADER_REQUEST_UPDATE";
const receiveUpdateType = "VALOR_AREA_LEADER_RECEIVE_UPDATE";
const failureUpdateType = "VALOR_AREA_LEADER_FAILURE_UPDATE";

const handleChangeFormDataType = "VALOR_AREA_LEADER_HANDLE_CHANGE_FORMDATA";
const clearFormDataType = "VALOR_AREA_LEADER_CLEAR_FORMDATA";

// Initial State
const initialState = {
  formData: { updateDate: "" },
  valorAreaLeaders: [],
  changeStatus: "idle",
  status: "idle",
};

// Actions
export const valorAreaLiderActions = {
  handleChangeValueForm: (propName = "", value = "") => async (dispatch) => {
    dispatch({ type: handleChangeFormDataType, propName, value });
  },
  clearValueForm: () => async (dispatch) => {
    dispatch({ type: clearFormDataType });
  },
  getValorAreaLeader: (area = "") => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetType });

    const url = `/api/Simulador2/ValorAreaLeader${isNullOrEmpty(area) ? "" : `?area=${area}`}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      valorAreaLeaders => {
        dispatch({ type: receiveGetType, valorAreaLeaders });
        if (valorAreaLeaders && valorAreaLeaders.length > 0) {
          const date = valorAreaLeaders.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate))
          dispatch({ type: handleChangeFormDataType, propName: "updateDate", value: date[0].lastUpdate });
        }
      },
      error => {
        dispatch({ type: failureGetType });
        dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os dados da área.", message: error, responseType: "error" });
      }
    );
  },
  updateValorAreaLeader: (id, value) => async (dispatch, getState) => {
    const { oidc, valorAreaLider } = getState();

    const child = valorAreaLider.valorAreaLeaders.find(x => x.id === id);

    if (child && child.valorMinNegociacao !== value) {
      const originalValue = child.valorMinNegociacao;

      dispatch({ type: requestUpdateType, id, value });

      const url = `/api/Simulador2/ValorAreaLeader/${id}`;

      const options = {
        method: "PATCH",
        body: JSON.stringify(value),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      HandleResponse(response).then(
        updateDate => {
          dispatch({ type: receiveUpdateType, updateDate });
        },
        error => {
          dispatch({ type: failureUpdateType, id, value: originalValue });
          dispatch({ type: "CALL_ALERT", title: "Não foi possível realizar a alteração.", message: error, responseType: "error" });
        }
      );
    }
  },

};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case requestGetType:
      return {
        ...state,
        valorAreaLeaders: [],
        status: "loading",
      };
    case receiveGetType:
      return {
        ...state,
        valorAreaLeaders: action.valorAreaLeaders,
        status: "idle",
      };
    case failureGetType:
      return {
        ...state,
        valorAreaLeaders: [],
        status: "idle",
      };
    case requestUpdateType:
      {
        const child = state.valorAreaLeaders.find(x => x.id === action.id);
        child.valorMinNegociacao = action.value;

        return {
          ...state,
          changeStatus: "loading",
        };
      }
    case receiveUpdateType:
      return {
        ...state,
        formData: {
          ...state.formData,
          updateDate: action.updateDate,
        },
        changeStatus: "idle",
      };
    case failureUpdateType:
      {
        const child = state.valorAreaLeaders.find(x => x.id === action.id);
        child.valorMinNegociacao = action.value;

        return {
          ...state,
          changeStatus: "idle",
        };
      }
    case clearFormDataType:
      return {
        ...state,
        formData: {
          ...initialState.formData,
        }
      };
    case handleChangeFormDataType:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.propName]: action.value,
        }
      };
    default:
      return state;
  }
};
