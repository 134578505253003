import { TableCell } from "@mui/material";

export function ResumoColsCp({ cpa }) {
  return <>
    {/* APROVADOS */}
    <TableCell align="right">
      {
        (cpa?.aprovadoTotalGeralPrecoEncargo ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovadoTotalGeralCorteva ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovadoTotalGeralNegociado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovadoCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
      {Number((cpa?.aprovadoPerCpaGeralUsado ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
    </TableCell>
    {/* AGUARDANDO APROVAÇÃO */}
    <TableCell align="right">
      {
        (cpa?.aprovacaoTotalGeralPrecoEncargo ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovacaoTotalGeralCorteva ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovacaoTotalGeralNegociado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovacaoCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
      {Number((cpa?.aprovacaoPerCpaGeralUsado ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
    </TableCell>
    {/* APROVADOS + AGUARDANDO APROVAÇÃO*/}
    <TableCell align="right">
      {
        (cpa?.workflowTotalPrecoEncargo ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.workflowTotalGeralCorteva ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.workflowTotalGeralNegociado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.workflowCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
      {Number((cpa?.workflowPerCpaGeralUsado ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
    </TableCell>
  </>
}