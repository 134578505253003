/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
// import uuid from "react-uuid";
// import uuid from "react-uuid";
import { history } from "../helpers";
import { dateEncargosActions } from "./dateencargos.action";
import { encargosActions } from "./encargos.action";
import { EncargosRatingsActions } from "./encargosrate.actions";
import { GetCenarioActions } from "./getcenario.actions";
import { payConditionsActions } from "./paycondition.action";

const changeValue = "CHANGE_VALUE";
const changeCondition = "CHANGE_CONDITION";
const ADD_ROW = "ADD_ROW";
const REMOVE_ROW = "REMOVE_ROW";
// const GET_DISCOUNT_CATEGORY = "GET_DISCOUNT_CATEGORY";
const attDiscount = "UPDATE_DISCOUNT";
const UPDATE_ALL = "UPDATE_ALL";
const REMOVE_ALL_ROW = "REMOVE_ALL_ROW";
const requestItensCenario = "REQUEST_ITENS_CENARIO";
const receiveItensCenario = "RECEIVE_ITENS_CENARIO";
const initialState = [];

export const simulatorActions = {

  requestItensById: (groupId, cenarioId, clone) => async (dispatch, getState) => {
    var state = getState();
    const { oidc/*, getCenario*/ } = getState();

    dispatch({ type: requestItensCenario });
    dispatch({ type: "REQUEST_GetCenario" })

    const url = `/api/getcenarioItensModel/${cenarioId}/${clone ? 1 : 0}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ItensCenario = await response.json();

    history.push(`/Simulador-Cliente-Edit?GroupId=${groupId}&CenarioId=${ItensCenario.cenarioId}`);
    sessionStorage.setItem("cenarioId", ItensCenario.cenarioId);
    dispatch(encargosActions.requestencargos(ItensCenario.campanha))
    dispatch(payConditionsActions.requestPayConditions(ItensCenario.campanha))
    dispatch(dateEncargosActions.requestdateEncargos(ItensCenario.campanha, ItensCenario.condicao))
    dispatch(EncargosRatingsActions.requestReloadEncargosRatings(ItensCenario.condicao, ItensCenario.vencimento, ItensCenario.campanha))

    dispatch(GetCenarioActions.setCenario(ItensCenario))
    dispatch({ type: receiveItensCenario, ItensCenario: ItensCenario.itens.map(x => { return CalcRow(x, ItensCenario.moeda) }) });
  },
  requestItensById2: (groupId, cenarioId, clone) => async (dispatch, getState) => {
    var state = getState();
    const { oidc/*, getCenario*/ } = getState();

    dispatch({ type: requestItensCenario });
    dispatch({ type: "REQUEST_GetCenario" })

    const url = `/api/getcenarioItensModel/${cenarioId}/${clone ? 1 : 0}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ItensCenario = await response.json();

    history.push(`/Simulador2/Simulador-Cliente-Edit?GroupId=${groupId}&CenarioId=${ItensCenario.cenarioId}`);
    sessionStorage.setItem("cenarioId", ItensCenario.cenarioId);
    dispatch(encargosActions.requestencargos(ItensCenario.campanha))
    dispatch(payConditionsActions.requestPayConditions(ItensCenario.campanha))
    dispatch(dateEncargosActions.requestdateEncargos(ItensCenario.campanha, ItensCenario.condicao))
    dispatch(EncargosRatingsActions.requestReloadEncargosRatings(ItensCenario.condicao, ItensCenario.vencimento, ItensCenario.campanha))

    dispatch(GetCenarioActions.setCenario(ItensCenario))
    dispatch({ type: receiveItensCenario, ItensCenario: ItensCenario.itens.map(x => { return CalcRow(x, ItensCenario.moeda) }) });
  },
  handleChange: (e, rowId, title, currency) => async (dispatch, getState) => {
    const { name, value } = e.target;
    dispatch({ type: changeValue, name, value, rowId, title, currency });
  },

  buscaCombo: (rows, descontoPacks, produtos, packs, currency) => async (dispatch, getState) => {
    // var cont = 0;
    var desconto = 0;
    // var contreqiuired = 0;
    var min = 0;
    var contNonrequired = 0;
    var tamanho = 0;
    var contreqiuiredGroup = 0;
    var list = [];
    var NotInList = [];
    var listRequired = [];
    var listRequiredGroup = [];
    var listId = [];
    var listHectare = [];
    var listHectareRequired = [];
    var listHectareRequiredGroup = [];
    var listNonRequired = [];
    var listAmbigousRequired = [];
    for (var i = 0; i < packs.length; i++) {
      //compara a lista de produtos do desconto com a lista de produtos que foram selecionados
      for (var j = 0; j < produtos.length; j++) {
        if (
          produtos[j].required && produtos[j].requiredGroup == 1 &&
          produtos[j].packId == packs[i].id
        ) {
          listRequired.push(produtos[j].nome);
        }
        if (
          produtos[j].required && produtos[j].requiredGroup == 2 &&
          produtos[j].packId == packs[i].id
        ) {
          listRequiredGroup.push(produtos[j].nome);
        }
        if (
          !produtos[j].required && produtos[j].requiredGroup == 0 &&
          produtos[j].packId == packs[i].id
        ) {
          listNonRequired.push(produtos[j].nome);
        }
        if (produtos[j].requiredGroup == 3 && produtos[j].packId == packs[i].id) {
          listAmbigousRequired.push(produtos[j].nome);
        }
      }

      if (listRequiredGroup.length > 0) contreqiuiredGroup = 1;

      //ve se as linhas da tabela correspondem a algum produto do pack
      for (var l = 0; l < rows.length; l++) {
        for (var j = 0; j < produtos.length; j++) {
          if (rows[l].title === produtos[j].nome && produtos[j].packId == packs[i].id && Number(rows[l].volume) !== 0) {
            list.push(rows[l]);
            listId.push(rows[l]);
            if ((listRequired.indexOf(produtos[j].nome) != -1 || listRequiredGroup.indexOf(produtos[j].nome) != -1))
              listHectare.push(Number(rows[l].hectaresTratados));
            if (listRequired.indexOf(produtos[j].nome) != -1)
              listHectareRequired.push(Number(rows[l].hectaresTratados));
            if (listRequiredGroup.indexOf(produtos[j].nome) != -1)
              listHectareRequiredGroup.push(Number(rows[l].hectaresTratados));
          }
          else {
            NotInList.push(rows[l]);
          }
        }
      }
      var flag = 0;
      var tamanhoGroup = 0;
      //ve se possui algum produto required
      for (var m = 0; m < list.length; m++) {
        for (var j = 0; j < listRequired.length; j++) {
          if (list[m].title === listRequired[j]) {
            tamanho++;
          }
        }
        for (var j = 0; j < listRequiredGroup.length; j++) {
          if (list[m].title === listRequiredGroup[j]) {
            flag = 1;
            tamanhoGroup++;
          }
        }
      }
      /*
        Se um produto do grupo 3 for adicionado ele pode pertencer os tanto ao listRequiredGroup como listNonRequired,
        mas não nos dois ao mesmo tempo.
        Ele deve entrar no grupo listRequiredGroup caso não haja nenhum produto nesta lista.
        Ele deve entrar no grupo listNonRequired caso já haja um produto no listRequiredGroup.
      */
      for (var n = 0; n < list.length; n++) {
        for (var j = 0; j < listNonRequired.length; j++) {
          if (list[n].title === listNonRequired[j]) {
            contNonrequired++;
          }
        }
        if (listAmbigousRequired.indexOf(list[n].title) != -1) {
          if (tamanhoGroup === 0) {
            flag = 1;
            tamanhoGroup++;
            listHectare.push(Number(list[n].hectaresTratados));
            listHectareRequiredGroup.push(Number(list[n].hectaresTratados));
          } else {
            contNonrequired++;
          }
        }
      }

      tamanho = tamanho + flag;
      var contaRequireds = listRequired.length + contreqiuiredGroup;

      if (tamanho === contaRequireds) {

        var total = tamanho + contNonrequired;
        // var total = 10
        for (var o = 0; o < descontoPacks.length; o++) {
          if (total === descontoPacks[o].qtdProdutosMin && descontoPacks[o].packId == packs[i].id)
            desconto = descontoPacks[o].desconto;
          else if (total > descontoPacks[o].qtdProdutosMin && descontoPacks[o].packId == packs[i].id)
            desconto = descontoPacks[descontoPacks.length - 1].desconto;
        }
        min = Math.min(...[...listHectareRequired, listHectareRequiredGroup.reduce((x, y) => { return x + (isNaN(Number(y)) ? 0 : Number(y)) }, 0)]);
      }

      for (var p = 0; p < list.length; p++) {
        dispatch({
          type: attDiscount,
          row: {
            id: list[p].id,
            title: list[p].title,
            price: list[p].price,
            embargoes: list[p].embargoes === undefined ? 0 : list[p].embargoes,
            category: list[p].category,
            categoryDiscount: list[p].categoryDiscount,
            packsDiscount: desconto * 100,
            cortevaFinalPrice: list[p].cortevaFinalPrice,
            hectaresTratados: list[p].hectaresTratados,
            comboArea: 0,
            programPacksDiscount: desconto * 100,
            dose: list[p].dose,
            volume: list[p].volume,
            negotiatedPrice: list[p].negotiatedPrice,
            flexibilidade: list[p].flexibilidade,
            flexibilidadeMkt: list[p].flexibilidadeMkt,
            ajuste: list[p].ajuste,
            valorTotal: list[p].valorTotal,
            cenarioId: list[p].cenarioId,
          },
          discount: desconto,
          min: min,
          currency: currency
        });
      }
    }
    //Sempre zerar os valores antes, para manter todos os valores atualizados
    for (var p = 0; p < rows.length; p++) {
      if (list.indexOf(rows[p]) === -1) {
        dispatch({
          type: attDiscount,
          row: {
            id: rows[p].id,
            title: rows[p].title,
            price: rows[p].price,
            embargoes: rows[p].embargoes === undefined ? 0 : rows[p].embargoes,
            category: rows[p].category,
            categoryDiscount: rows[p].categoryDiscount,
            packsDiscount: 0,
            cortevaFinalPrice: rows[p].cortevaFinalPrice,
            hectaresTratados: rows[p].hectaresTratados,
            comboArea: 0,
            programPacksDiscount: 0,
            dose: rows[p].dose,
            volume: rows[p].volume,
            negotiatedPrice: rows[p].negotiatedPrice,
            flexibilidade: rows[p].flexibilidade,
            ajuste: rows[p].ajuste,
            valorTotal: rows[p].valorTotal,
            cenarioId: rows[p].cenarioId,
          },
          discount: 0,
          min: 0,
          currency: currency
        });
      }
    }
  },

  saveCenarioWithItems: (rows) => async (dispatch, getState) => {
    //console.log(rows);
  },

  handleChangeCondition: (e, rows, conditions) => async (dispatch, getState) => {
    for (var i = 0; i < rows.length; i++) {
      dispatch({
        type: changeCondition,
        row: { ...rows[i], embargoes: rows[i].embargoes ?? 0 },
        rows: rows,
        conditions: conditions,
        value: e.target.value,
      });
    }
  },

  removeRow: (e, rowId, title, motivo = "") => async (dispatch, getState) => {
    //console.log(title)
    dispatch({ type: REMOVE_ROW, rowId, title, motivo });
  },
  removeAllRows: () => async (dispatch, getState) => {
    dispatch({ type: REMOVE_ALL_ROW });
  },
  addRowAnalyticsProducts: (produtos, rate, cliente, clientes, cenarioId, moeda) => async (dispatch, getState) => {
    //console.log(produtos);
    if (produtos.length > 0) {
      const rowClient = clientes.find((c) => cliente === c.id);
      for (var i = 0; i < produtos.length; i++) {
        dispatch({
          type: ADD_ROW,
          row: {
            // id: 0,
            title: produtos[i].product,
            price: produtos[i].price,
            embargoes: rate,
            category: produtos[i].productCategory,
            categoryDiscount: produtos[i].descontoCategoria / 100,
            packsDiscount: 0,
            cortevaFinalPrice:
              produtos[i].price +
              produtos[i].price * (rate / 100) -
              (produtos[i].price + produtos[i].price * (rate / 100)) *
              (produtos[i].descontoCategoria / 100) -
              (produtos[i].price + produtos[i].price * (rate / 100)) * 0,
            hectaresTratados: 0,
            comboArea: 0,
            programPacksDiscount: 0,
            dose: produtos[i].dose,
            volume: 0,
            negotiatedPrice: 0,
            flexibilidade: 0,
            ajuste: 0,
            valorTotal: 0,
            cenarioId: cenarioId,
            clienteId: rowClient.clientId,
            distritoId: rowClient.district,
            campanhaId: produtos[i].campaign,
            produtoId: produtos[i].productId,
            cur: produtos[i].cur,
            cogs: produtos[i].cogs,
            salesDeduction: produtos[i].salesDeduction,
            gmTarget: produtos[i].gmTarget,
            flexibilidadeTargetMkt: produtos[i].flexibilityTargetMkt,
            valorTotalPrecoFinalCorteva: 0,
            valorFinalNegociado: 0,
            nup: 0,
            saudeProduto: 0,
            nupNegociado: 0,
            saudeProdutoNegociado: 0,
            perSaudeNegociado: 0,
            analytics: true,
            motivo: produtos[i].motivo,
            moeda: moeda
          },
        });
      }
    }
  },
  addRow: (e, produto, produtos, cliente, clientes, campanha, campanhas, condicao, condicoes, packs, descontosPacks, produtosPacks, linhas,
    descontoCat, precoProduto, encargosRate, actions, isCreatedCenario, idCenario, clienteId, distritoId, campanhaId, moeda) =>
    async (dispatch, getState) => {
      if (produto && cliente) {
        const row = produtos.find((b) => produto === b.productId);
        const rowClient = clientes.find((c) => cliente === c.id);
        // const rowCampaign = campanhas.find((c) => campanha === c.campaignId);
        if (isCreatedCenario === false) {
          // actions.createSaveCenario(idCenario, clienteId, distritoId, rowClient.territory)
        }
        //console.log("Desconto Categoria: " + descontoCat);
        var rate = encargosRate.rate === undefined ? 0 : encargosRate.rate;
        dispatch({
          type: ADD_ROW,
          row: {
            // id: 0,
            title: row.product,
            price: row.price,
            embargoes: rate,
            category: row.productCategory,
            categoryDiscount: descontoCat / 100,
            packsDiscount: 0,
            cortevaFinalPrice:
              row.price +
              row.price * (rate / 100) -
              (row.price + row.price * (rate / 100)) * (descontoCat / 100) -
              (row.price + row.price * (rate / 100)) * 0,
            hectaresTratados: 0,
            gmTarget: row.gmTarget,
            flexibilidadeTargetMkt: row.flexibilityTargetMkt,
            comboArea: 0,
            programPacksDiscount: 0,
            dose: row.dose,
            volume: 0,
            negotiatedPrice: 0,
            flexibilidade: 0,
            ajuste: 0,
            valorTotal: 0,
            cenarioId: idCenario,
            clienteId: rowClient.clientId,
            distritoId: rowClient.district,
            campanhaId: campanhaId,
            produtoId: row.productId,
            cur: row.cur,
            cogs: row.cogs,
            salesDeduction: row.salesDeduction,
            valorTotalPrecoFinalCorteva: 0,
            valorFinalNegociado: 0,
            nup: 0,
            saudeProduto: 0,
            nupNegociado: 0,
            saudeProdutoNegociado: 0,
            perSaudeNegociado: 0,
            moeda: moeda
          },
        });
      }
    },
  updateRows: (produtos, currency, oldCurrency) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_ALL, produtos: produtos, currency: currency, oldCurrency: oldCurrency });
  },
};

export const CalcRow = (row, currency) => {
  //-----HECTARES TRATADOS-----
  row.hectaresTratados = (row.volume / row.dose).toFixed(0);
  //-----HECTARES TRATADOS-----

  //-----VALOR TOTAL-----
  row.valorTotal = row.volume * row.negotiatedPrice;
  //-----VALOR TOTAL-----

  //-----COMBO ÁREA-----
  if (row.volume === 0) {
    row.comboArea = 0
  }
  //-----COMBO ÁREA-----

  //-----AJUSTE-----
  var ajuste = row.negotiatedPrice -
    (row.price + row.price * (row.embargoes / 100) -
      (row.price + row.price * (row.embargoes / 100)) *
      (row.categoryDiscount + row.packsDiscount / 100))

  row.ajuste = ajuste < 0 ? 0 : ajuste;
  //-----AJUSTE-----

  //-----PRECO FINAL-----
  var finalPrice =
    row.price +
    row.price * (row.embargoes / 100) -
    (row.price + row.price * (row.embargoes / 100)) * row.categoryDiscount -
    (row.price + row.price * (row.embargoes / 100)) *
    (row.packsDiscount / 100);
  //-----PRECO FINAL-----

  //-----FLEXIBILIDADE-----
  var flexibilidade;
  if (ajuste > 0) {
    flexibilidade = 0;
  } else if (row.negotiatedPrice < 1) {
    flexibilidade = 0;
  } else {
    flexibilidade = Number(((row.cortevaFinalPrice - row.negotiatedPrice) / (row.price + row.price * (row.embargoes / 100))).toFixed(6));
  }
  flexibilidade = flexibilidade * 100;
  row.flexibilidade = flexibilidade;
  //-----FLEXIBILIDADE-----

  //-----FLEXIBILIDADE MARKETING-----
  var flexibilidadeMkt;
  if (row.negotiatedPrice > row.cortevaFinalPrice) {
    flexibilidadeMkt = 0;
  } else {
    flexibilidadeMkt = Number(((row.negotiatedPrice / row.cortevaFinalPrice) - 1) * 100);
  }

  row.flexibilidadeMkt = flexibilidadeMkt;

  let absoluteFlex = Math.abs(row.flexibilidade / 100);
  let absoluteFlexTarget = Math.abs(row.flexibilidadeTargetMkt / 100);
  let flexPerActual = 0;

  if (absoluteFlex < absoluteFlexTarget) {
    flexPerActual = (row.flexibilidade / 100) / (row.flexibilidadeTargetMkt / 100);
  } else {
    let roundedFlex = Math.round(row.flexibilidade / 100, 2);
    let roundedFlexTarget = Math.round(row.flexibilidadeTargetMkt / 100, 2);
    flexPerActual = roundedFlex / roundedFlexTarget;
  }

  flexPerActual = Number.isFinite(flexPerActual) || Number.isNaN(flexPerActual) ? 0 : flexPerActual;
  row.finalFlexibilidadeTargetMkt = flexPerActual * 100;
  //-----FLEXIBILIDADE MARKETING-----

  //-----SEMÁFORO-----
  row.cortevaFinalPrice = Number(finalPrice.toFixed(2));

  row.valorTotalPrecoFinalCorteva = (row.price - (row.price * (row.categoryDiscount + (row.packsDiscount / 100)))) * row.volume

  row.valorFinalNegociado = ((row.negotiatedPrice - (row.negotiatedPrice * (row.embargoes / 100))) * row.volume)

  row.nup = ((row.negotiatedPrice - (row.negotiatedPrice * (row.embargoes / 100))) - ((row.negotiatedPrice - (row.negotiatedPrice * (row.embargoes / 100))) * (row.salesDeduction / 100)))

  row.saudeProduto = ((row.moeda === "BRL" ? (row.nup - (row.cogs * row.cur)) : ((row.nup - row.cogs))) * row.volume)

  row.nupNegociado = (row.negotiatedPrice - (row.negotiatedPrice * (row.salesDeduction / 100)))

  row.saudeProdutoNegociado = ((row.moeda === "BRL" ? (row.nupNegociado - (row.cogs * row.cur)) : ((row.nupNegociado - row.cogs))) * row.volume)

  row.perSaudeNegociado = (row.saudeProdutoNegociado / (row.valorTotal))

  row.cogsValue = (row.moeda === "BRL" ? row.volume * row.cogs * row.cur : row.volume * row.cogs);
  row.salesValue = row.valorTotal * (row.salesDeduction / 100);
  row.gm = row.valorTotal - row.salesValue - row.cogsValue; //VALOR GM
  row.gmPer = row.gm / row.valorTotal * 100; //VALOR GM PERCENTUAL
  row.finalGmPer = (row.gmPer / row.gmTarget) * 100; //VALOR %ATINGIDO GM
  //-----SEMÁFORO-----

  return row;
}

export const reducer = (state = initialState, action) => {
  state = state || initialState;

  if (action.type === requestItensCenario) {
    return { isLoading: true };
  }
  if (action.type === receiveItensCenario) {
    return action.ItensCenario
  }
  if (action.type === REMOVE_ALL_ROW) {
    return [];
  }
  if (action.type === attDiscount) {
    const row = state.find((b) => action.row.title === b.title && !(b.excluido != undefined && b.excluido));
    // row.comboArea = action.min;
    if (row.hectaresTratados < action.min) {
      row.comboArea = Number(row.hectaresTratados);
    } else {
      row.comboArea = action.min;
    }

    if (row.hectaresTratados === 0 || row.comboArea === 0) {
      row.packsDiscount = 0;
    } else {
      row.packsDiscount = Number((
        ((action.discount * row.comboArea) / row.hectaresTratados) *
        100
      ).toFixed(3));
    }

    if (isNaN(row.packsDiscount)) {
      row.packsDiscount = 0;
    }

    var ajuste =
      row.negotiatedPrice -
        (row.price +
          row.price * (row.embargoes / 100) -
          (row.price + row.price * (row.embargoes / 100)) *
          (row.categoryDiscount + row.packsDiscount / 100)) <
        0
        ? 0
        : row.negotiatedPrice -
        (row.price +
          row.price * (row.embargoes / 100) -
          (row.price + row.price * (row.embargoes / 100)) *
          (row.categoryDiscount + row.packsDiscount / 100));

    row.ajuste = ajuste;

    var finalPrice =
      row.price +
      row.price * (row.embargoes / 100) -
      (row.price + row.price * (row.embargoes / 100)) * row.categoryDiscount -
      (row.price + row.price * (row.embargoes / 100)) *
      (row.packsDiscount / 100);

    row.cortevaFinalPrice = Number(finalPrice.toFixed(2));

    var flexibilidade;
    if (ajuste > 0 || row.negotiatedPrice < 1) {
      flexibilidade = 0;
    } else {
      flexibilidade = Number(((row.cortevaFinalPrice - row.negotiatedPrice) / (row.price + row.price * (row.embargoes / 100))).toFixed(6));
    }

    flexibilidade = flexibilidade * 100;
    row.flexibilidade = flexibilidade;

    var flexibilidadeMkt;
    if (row.negotiatedPrice > row.cortevaFinalPrice) {
      flexibilidadeMkt = 0;
    } else {
      flexibilidadeMkt = Number(((row.negotiatedPrice / row.cortevaFinalPrice) - 1) * 100);
    }

    row.flexibilidadeMkt = flexibilidadeMkt;

    let absoluteFlex = Math.abs(row.flexibilidade / 100);
    let absoluteFlexTarget = Math.abs(row.flexibilidadeTargetMkt / 100);
    let flexPerActual = 0;

    if (absoluteFlex < absoluteFlexTarget) {
      flexPerActual = (row.flexibilidade / 100) / (row.flexibilidadeTargetMkt / 100);
    } else {
      let roundedFlex = Math.round(row.flexibilidade / 100, 2);
      let roundedFlexTarget = Math.round(row.flexibilidadeTargetMkt / 100, 2);
      flexPerActual = roundedFlex / roundedFlexTarget;
    }

    flexPerActual = Number.isFinite(flexPerActual) || Number.isNaN(flexPerActual) ? 0 : flexPerActual;
    row.finalFlexibilidadeTargetMkt = flexPerActual * 100;
  }
  if (action.type === changeCondition) {
    var condi = [];

    for (var i = 0; i < action.conditions.length; i++) {
      if (action.conditions[i].paymentTermsId === action.value)
        condi.push(action.conditions[i]);
    }

    // const rows = state.filter((b) => action.rowId !== b.id);
    const row = state.find((b) => action.row.title === b.title);
    var emb = action.conditions;
    row.embargoes = emb;

    var finalPrice =
      row.price +
      row.price * (row.embargoes / 100) -
      (row.price + row.price * (row.embargoes / 100)) * row.categoryDiscount -
      (row.price + row.price * (row.embargoes / 100)) * row.packsDiscount;
    row.cortevaFinalPrice = Number(finalPrice.toFixed(2));

    var ajuste =
      row.negotiatedPrice -
        (row.price +
          row.price * (row.embargoes / 100) -
          (row.price + row.price * (row.embargoes / 100)) *
          (row.categoryDiscount + row.packsDiscount / 100)) <
        0
        ? 0
        : row.negotiatedPrice -
        (row.price +
          row.price * (row.embargoes / 100) -
          (row.price + row.price * (row.embargoes / 100)) *
          (row.categoryDiscount + row.packsDiscount / 100));
    row.ajuste = ajuste.toFixed(2);

    var flexibilidade;
    if (ajuste > 0 || row.negotiatedPrice < 1) {
      flexibilidade = 0;
    } else {
      flexibilidade = Number(((row.cortevaFinalPrice - row.negotiatedPrice) / (row.price + row.price * (row.embargoes / 100))).toFixed(6));
    }

    flexibilidade = flexibilidade * 100;
    row.flexibilidade = flexibilidade;

    var flexibilidadeMkt;
    if (row.negotiatedPrice > row.cortevaFinalPrice) {
      flexibilidadeMkt = 0;
    } else {
      flexibilidadeMkt = Number(((row.negotiatedPrice / row.cortevaFinalPrice) - 1) * 100);
    }

    row.flexibilidadeMkt = flexibilidadeMkt;

    let absoluteFlex = Math.abs(row.flexibilidade / 100);
    let absoluteFlexTarget = Math.abs(row.flexibilidadeTargetMkt / 100);
    let flexPerActual = 0;

    if (absoluteFlex < absoluteFlexTarget) {
      flexPerActual = (row.flexibilidade / 100) / (row.flexibilidadeTargetMkt / 100);
    } else {
      let roundedFlex = Math.round(row.flexibilidade / 100, 2);
      let roundedFlexTarget = Math.round(row.flexibilidadeTargetMkt / 100, 2);
      flexPerActual = roundedFlex / roundedFlexTarget;
    }

    flexPerActual = Number.isFinite(flexPerActual) || Number.isNaN(flexPerActual) ? 0 : flexPerActual;
    row.finalFlexibilidadeTargetMkt = flexPerActual * 100;
  }
  if (action.type === REMOVE_ROW) {
    const row = state.find((b) => action.title === b.title && !(b.excluido != undefined && b.excluido));
    const index = state.findIndex((b) => action.title === b.title && !(b.excluido != undefined && b.excluido));
    if (row.analytics) {
      row.motivo = action.motivo;
      row.excluido = true;

      return [
        ...state.slice(0, index),

        {
          ...row,
        },
        ...state.slice(index + 1),
      ];
    } else {
      const rows = state.filter((b) => action.title !== b.title && !(b.excluido != undefined && b.excluido));
      //console.log(rows)
      return [...rows];
    }

  }
  if (action.type === ADD_ROW) {
    //console.log(state);
    const row = state.find((b) => action.row.title === b.title && !(b.excluido != undefined && b.excluido));
    if (row) {
      return state;
    }

    return [...state, action.row];
  }
  if (action.type === changeValue) {
    const row = state.find((b) => action.title === b.title && !(b.excluido != undefined && b.excluido));
    const index = state.findIndex((obj) => action.title === obj.title && !(obj.excluido != undefined && obj.excluido));
    //console.log(index)
    //console.log(row)
    // const rows = state.filter((b) => action.title !== b.title);
    var flexibilidade = row.flexibilidade;
    var hectaresTratados = row.hectaresTratados;
    var valorTotal = row.valorTotal;
    var ajuste = row.ajuste;

    if (action.name === "negotiatedPrice") {
      row.negotiatedPrice = action.value
      //flexibilidade = (action.value - row.price).toFixed(3);
      valorTotal = action.value * row.volume;
      row.valorTotal = valorTotal
      //console.log("EMBARGOES: " + row.embargoes)
      ajuste =
        action.value -
          (row.price +
            row.price * (row.embargoes / 100) -
            (row.price + row.price * (row.embargoes / 100)) *
            (row.categoryDiscount + row.packsDiscount / 100)) <
          0
          ? 0
          : action.value -
          (row.price +
            row.price * (row.embargoes / 100) -
            (row.price + row.price * (row.embargoes / 100)) *
            (row.categoryDiscount + row.packsDiscount / 100));

      if (ajuste > 0 || row.negotiatedPrice < 1) {
        flexibilidade = 0;
      } else {
        flexibilidade = Number(((Number(row.cortevaFinalPrice.toFixed(2)) - row.negotiatedPrice) / (row.price + (row.price * (row.embargoes / 100)))).toFixed(6));
      }

      flexibilidade = flexibilidade * 100;
      row.flexibilidade = flexibilidade;
      row.ajuste = ajuste;

      var flexibilidadeMkt;
      if (row.negotiatedPrice > row.cortevaFinalPrice) {
        flexibilidadeMkt = 0;
      } else {
        flexibilidadeMkt = Number(((row.negotiatedPrice / row.cortevaFinalPrice) - 1) * 100);
      }

      row.flexibilidadeMkt = flexibilidadeMkt;

      let absoluteFlex = Math.abs(row.flexibilidade / 100);
      let absoluteFlexTarget = Math.abs(row.flexibilidadeTargetMkt / 100);
      let flexPerActual = 0;

      if (absoluteFlex < absoluteFlexTarget) {
        flexPerActual = (row.flexibilidade / 100) / (row.flexibilidadeTargetMkt / 100);
      } else {
        let roundedFlex = Math.round(row.flexibilidade / 100, 2);
        let roundedFlexTarget = Math.round(row.flexibilidadeTargetMkt / 100, 2);
        flexPerActual = roundedFlex / roundedFlexTarget;
      }

      flexPerActual = Number.isFinite(flexPerActual) || Number.isNaN(flexPerActual) ? 0 : flexPerActual;
      row.finalFlexibilidadeTargetMkt = flexPerActual * 100;
    }

    if (action.name === "volume") {
      row.volume = action.value
      hectaresTratados = (action.value / row.dose).toFixed(0);
      row.hectaresTratados = hectaresTratados
      valorTotal = action.value * row.negotiatedPrice;
      row.valorTotal = valorTotal
      if (action.value === 0) {
        row.comboArea = 0
      }
    }

    row.valorTotalPrecoFinalCorteva = (row.price - (row.price * (row.categoryDiscount + (row.packsDiscount / 100)))) * row.volume

    row.valorFinalNegociado = ((row.negotiatedPrice - (row.negotiatedPrice * (row.embargoes / 100))) * row.volume)

    row.nup = ((row.negotiatedPrice - (row.negotiatedPrice * (row.embargoes / 100))) - ((row.negotiatedPrice - (row.negotiatedPrice * (row.embargoes / 100))) * (row.salesDeduction / 100)))

    row.saudeProduto = ((row.moeda === "BRL" ? (row.nup - (row.cogs * row.cur)) : ((row.nup - row.cogs))) * row.volume)

    row.nupNegociado = (row.negotiatedPrice - (row.negotiatedPrice * (row.salesDeduction / 100)))

    row.saudeProdutoNegociado = ((row.moeda === "BRL" ? (row.nupNegociado - (row.cogs * row.cur)) : ((row.nupNegociado - row.cogs))) * row.volume)

    row.perSaudeNegociado = (row.saudeProdutoNegociado / (row.valorTotal))

    row.cogsValue = (row.moeda === "BRL" ? row.volume * row.cogs * row.cur : row.volume * row.cogs);
    row.salesValue = row.valorTotal * (row.salesDeduction / 100);
    row.gm = row.valorTotal - row.salesValue - row.cogsValue;
    row.gmPer = (row.gm / row.valorTotal) * 100;
    row.finalGmPer = (row.gmPer / row.gmTarget) * 100;

    return [
      ...state.slice(0, index),

      {
        ...row,
        [action.name]: action.value,
        flexibilidade: flexibilidade,
        hectaresTratados: hectaresTratados,
        valorTotal: valorTotal,
        ajuste: ajuste,
      },
      ...state.slice(index + 1),
    ];
  }
  if (action.type === UPDATE_ALL) {
    const rows = state;
    var produtos = action.produtos;
    var moeda = action.currency;
    var moedaA = action.oldCurrency;
    var isDiferent = moedaA !== moeda;
    if (produtos?.length > 0) {
      var updatedRows = rows.map((item) => {
        var product = produtos.find(x => item.title === x.product);
        if (product !== undefined) {
          item.price = product.price;
          item.category = product.productCategory;
          item.dose = product.dose;
          item.cur = product.cur;
          item.cogs = product.cogs;
          item.gmTarget = product.gmTarget;
          item.flexibilidadeTargetMkt = product.flexibilityTargetMkt;
          item.moeda = moeda;

          if (isDiferent) {
            item.negotiatedPrice = 0;
          }
          item.salesDeduction = product.salesDeduction;
          item = CalcRow(item, moeda);
          return item;
        }
        return undefined;
      }).filter(item => item !== undefined);

      return updatedRows;
    } else {
      return state;
    }

  }

  return state;
};
