import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ErrorIcon = (props) => {
    return (
        <SvgIcon {...props} width="127" height="127" viewBox="0 0 127 127">
           <path fillRule="evenodd" clipRule="evenodd" d="M63.5 0C98.5647 0 127 28.4353 127 63.5C127 98.5647 98.5647 127 63.5 127C28.4353 127 0 98.5647 0 63.5C0 28.4353 28.4353 0 63.5 0ZM63.5 12.7C35.4902 12.7 12.7 35.4902 12.7 63.5C12.7 91.5099 35.4902 114.3 63.5 114.3C91.5099 114.3 114.3 91.5099 114.3 63.5C114.3 35.4902 91.5099 12.7 63.5 12.7ZM78.0606 39.9606L87.0395 48.9395L72.4789 63.5L87.0395 78.0606L78.0606 87.0395L63.5 72.4789L48.9395 87.0395L39.9606 78.0606L54.5211 63.5L39.9606 48.9395L48.9395 39.9606L63.5 54.5211L78.0606 39.9606Z"/>
        </SvgIcon>
    );
};

export default ErrorIcon;
