import React, { Component } from "react";
import { connect } from "react-redux";

import userManager from "../../../util/userManager";
import PropTypes from "prop-types";

class LogoutPage extends Component {
  componentDidMount(event) {
    userManager.signoutRedirect();
  }

  render() {
    return (
      <div>
        <h3>Log out</h3>
      </div>
    );
  }
}

LogoutPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(LogoutPage);
