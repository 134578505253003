import { history } from "../helpers";


const requestClientsType = "REQUEST_CLIENTS";
const receiveClientsType = "RECEIVE_CLIENTS";
const requestClientsAccountsType = "REQUEST_CLIENTS_ACCOUNTS";
const receiveClientsAccountsType = "RECEIVE_CLIENTS_ACCOUNTS";
const requestMunicipiosType = "REQUEST_MUNICIPIOS";
const receiveMunicipiosType = "RECEIVE_MUNICIPIOS";
const initialState = { clients: [], isLoading: false, accounts: [], isLoadingAccount: false, municipios: [], isLoadingMunicipios: false };

export const clientActions = {
  requestClients: () => async (dispatch, getState) => {
    const { oidc, clientes } = getState();

    if (clientes?.clients.length <= 0) {
      dispatch({ type: requestClientsType });

      const url = `/api/analyticsclient`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const clientList = await response.json();
      dispatch({ type: receiveClientsType, clients: clientList });
    }
  },
  requestClientsAccounts: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestClientsAccountsType });

    const url = `/api/analyticsclient/account/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const accounts = await response.json();
    dispatch({ type: receiveClientsAccountsType, accounts });
  },
  requestMunicipios: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestMunicipiosType });

    const url = `/api/analyticsclient/municipios`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const municipios = await response.json();
    dispatch({ type: receiveMunicipiosType, municipios });
  },
  changeTerritorio: (territorio) => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/whitespace/changeTerritorio/${territorio}`; //Mais de uma &nomevarivel${props.nomevariavel}

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },

  redirect: (url) => async (dispatch, getState) => {

    history.push(url, { some: "state" })
    history.go(0)
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestClientsType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveClientsType) {
    return {
      ...state,
      clients: action.clients,
      isLoading: false,
    };
  }
  if (action.type === requestClientsAccountsType) {
    return {
      ...state,
      isLoadingAccount: true,
    };
  }
  if (action.type === receiveClientsAccountsType) {
    return {
      ...state,
      accounts: action.accounts,
      isLoadingAccount: false,
    };
  }
  if (action.type === requestMunicipiosType) {
    return {
      ...state,
      isLoadingMunicipios: true,
    };
  }
  if (action.type === receiveMunicipiosType) {
    return {
      ...state,
      municipios: action.municipios,
      isLoadingMunicipios: false,
    };
  }

  return state;
};
