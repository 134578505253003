const requestJobsType = "REQUEST_JOBS";
const receiveJobsType = "RECEIVE_JOBS";

const initialState = { jobs: [], isLoadingJobs: false };

export const adminJobsActions = {
  runFarmPlotJob: () => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/jobs/FarmPlotJobStart`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  stopFarmPlotJob: () => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/jobs/StopFarmPlotJob`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  runResumeJob: () => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/jobs/ResumeJobStart`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  },
  stopResumeJob: () => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/jobs/StopResumeJob`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    await fetch(url, options);
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestJobsType) {
    return {
      ...state,
      isLoadingJobs: true,
    };
  }
  if (action.type === receiveJobsType) {
    return {
      ...state,
      jobs: action.jobs,
      isLoadingJobs: false,
    };
  }

  return state;
};
