import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { enrichmentActions, Safras, CultureList, CulturasIndex } from "../../../actions/enrichment.actions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import NumericInput from "material-ui-numeric-input";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import TablePagination from "@mui/material/TablePagination";
import FormControl from "@mui/material/FormControl";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import InfoIcon from "@mui/icons-material/Info";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTheme, styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import ListSubheader from "@mui/material/ListSubheader";
import { VariableSizeList } from "react-window";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import EventIcon from "@mui/icons-material/Event";
import { userActions } from "../../../actions/user.actions";
import ButtonSelectTerrritory from "../../ButtonSelectTerrritory";
import AddIcon from "@mui/icons-material/Add";
import { Backdrop, CircularProgress, Popover, Stack } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import FilterListIcon from '@mui/icons-material/FilterList';
import { ConInfSelect } from "./ConsultorInfluenciador";

function renderRow(props) {
  const LISTBOX_PADDING = 0; // px

  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    fontSize: "12px",
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function TablePaginationActions(props) {
  const theme = { direction: "tl" };
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function EnhancedTableToolbar(props) {
  const {
    anchor,
    openanchor,
    toggleDrawer,
    list,
    msgSave,
    status,
    statusSelected,
    handleChangeSelect,
    handleSearchText,
    territorio,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 45%", color: "var(--cort-blue)" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Coleta de Dados de Cliente
      </Typography>

      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 850,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquise por Visão única ou Nome do Produtor"
          onChange={handleSearchText}
          inputProps={{ "aria-label": "Pesquise por visão única / produtor" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={handleSearchText}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      {/* <FormControl component="fieldset" sx={{ width: "80%" }}>
        <FormGroup aria-label="position" row>
          {status.map((st) => (
            <FormControlLabel
              key={st.id}
              value="top"
              control={
                <Switch
                  defaultChecked={statusSelected.indexOf(st.text) > -1}
                  size="small"
                  name={st.text}
                  onChange={handleChangeSelect}
                />
              }
              label={st.text}
              labelPlacement="top"
            />
          ))}
        </FormGroup>
      </FormControl> */}

      <Typography sx={{ flex: "1 1 40%", textAlign: "end" }} component="div">
        {msgSave}
      </Typography>

      <Tooltip title="Atualizar dados">
        <IconButton onClick={() => props.actions.requestEnriquecer(territorio)}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Ajuda">
        <IconButton
          aria-label="help"
          onClick={toggleDrawer(anchor, true)}
          sx={{ float: "right" }}
          color="secondary"
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>

      {openanchor &&
        <SwipeableDrawer
          anchor={anchor} 
          open={openanchor}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      }

    </Toolbar>
  );
}

class EnrichmentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produto: "",
      page: 0,
      rowsPerPage: 10,
      openanchor: false,
      searchText: "",
      statusSelected: ["Não Iniciado", "Em Andamento", "Finalizado"],
      status: [
        { id: 0, text: "Não Iniciado" },
        { id: 1, text: "Em Andamento" },
        { id: 2, text: "Finalizado" },
      ],
      territorio: undefined,
      area: undefined,
      distrito: undefined,
      isSetStorage: false,
    };

    this.setByStorage = this.setByStorage.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.onChangeDistrito = this.onChangeDistrito.bind(this);
    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);
  }
  componentDidMount() {
    this.props.actions.requestPurchasePools();
    this.props.actions.requestProductionUnits();
    this.props.actions.requestConsultorInfluenciador();
    this.props.actions.requestTerritorios();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();
  }

  componentWillUnmount(nextProps) { }

  handleChange = (event) => {
    this.setState({ produto: event.target.value });
  };

  handleChangeSelect = (event) => {
    const { name, checked } = event.target;
    let { statusSelected } = this.state;
    if (checked && statusSelected.indexOf(name) === -1) {
      statusSelected.push(name);
    }

    if (!checked && statusSelected.indexOf(name) > -1) {
      statusSelected = statusSelected.filter((b) => b !== name);
    }

    this.setState({
      statusSelected: statusSelected,
    });
  };

  handleSearchText = (event) => {
    const { value } = event.target;

    this.setState({
      searchText: value,
    });
  };

  onChangeArea(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ area: newInputValue });
      this.setState({ distrito: undefined });
      this.setState({ territorio: undefined });
      localStorage.setItem("Area", JSON.stringify(newInputValue));
      localStorage.removeItem("Distrito");
      localStorage.removeItem("Territorio");
    } else {
      this.setState({ area: options[0] });
      localStorage.setItem("Area", JSON.stringify(options[0]));
    }
  }

  onChangeDistrito(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ distrito: newInputValue });
      this.setState({ territorio: undefined });
      localStorage.setItem("Distrito", JSON.stringify(newInputValue));
      localStorage.removeItem("Territorio");
    } else {
      this.setState({ distrito: options[0] });
      localStorage.setItem("Distrito", JSON.stringify(options[0]));
    }
  }

  onChangeTerritorio(event, newInputValue, options) {
    if (newInputValue != null) {
      this.setState({ territorio: newInputValue });
      this.props.actions.requestEnriquecer(newInputValue.territoryId);
      this.props.actions.setTerritorio(newInputValue);
      localStorage.setItem("Territorio", JSON.stringify(newInputValue));
    }
    // else
    //   this.setState({ territorio: options[0] });
  }

  setByStorage(areas = [], distritos = [], territorios = []) {
    if (areas?.length === 0 && distritos?.length === 0 && territorios?.length === 1) {
      this.props.actions.requestEnriquecer("");
    } else {
      var area = JSON.parse(localStorage.getItem("Area"));
      var distrito = JSON.parse(localStorage.getItem("Distrito"));
      var territorio = JSON.parse(localStorage.getItem("Territorio"));
      if (area && areas.find((x) => x === area) != undefined) {
        this.setState({ area: area });
      } else {
        localStorage.removeItem("Area");
      }
      if (
        distrito &&
        distritos.find((x) => x.districtName === distrito.districtName) != undefined
      ) {
        this.setState({ distrito: distrito });
      } else {
        localStorage.removeItem("Distrito");
      }
      if (
        territorio &&
        territorios.find((x) => x.territoryName == territorio.territoryName) != undefined
      ) {
        this.setState({ territorio: territorio });
        this.props.actions.setTerritorio(territorio);
        this.props.actions.requestEnriquecer(territorio.territoryId);
      } else {
        localStorage.removeItem("Territorio");
      }
    }


    this.setState({ isSetStorage: true });
  }

  render() {
    let rows = this.props.enrichment.enrichment;
    var role = "";
    var roleLevels = undefined;
    var territorio = "";
    var territorios = [];
    var areas = ["TODOS"];
    var distritos = [{ districtName: "TODOS" }];

    if (
      !this.props.user.isLoadingRole &&
      this.props.user.role != null &&
      !this.props.user.isLoadingRoleLevels &&
      this.props.user.roleLevels != null
    ) {
      roleLevels = this.props.user.roleLevels;
      role = this.props.user.role.role;
      if (role.accessLevel === roleLevels.viewAll) territorio = "";
      else if (role.accessLevel >= roleLevels.viewArea)
        territorio = this.props.user.role.areaName;
      else if (role.accessLevel >= roleLevels.viewManyDistrict)
        territorio = this.props.user.role.districtName;
      else if (role.accessLevel >= roleLevels.viewDistrict)
        territorio = this.props.user.role.districtName;
      else territorio = this.props.user.role.territory.territoryName;
    }

    if (
      !this.props.user.isLoadingTerritorios &&
      this.props.user.territorios.length > 0 &&
      !this.props.user.isLoadingRole &&
      this.props.user.role != null &&
      !this.props.user.isLoadingRoleLevels &&
      this.props.user.roleLevels != null
    ) {
      var territoriosToFilter = this.props.user.territorios;
      areas = areas.concat(this.props.user.role.areas);
      areas = areas.filter((x) => x !== "");
      var distritosToFilter = this.props.user.role.distritos;

      if (!this.state.isSetStorage) {
        this.setByStorage(
          this.props.user.role.areas,
          this.props.user.role.distritos,
          this.props.user.territorios
        );
      }

      if (
        this.state.area !== "" &&
        this.state.area != null &&
        this.state.area != undefined
      ) {
        if (this.state.area !== "TODOS") {
          distritosToFilter = distritosToFilter.filter(
            (x) => x.areaName === this.state.area
          );
          territoriosToFilter = territoriosToFilter.filter(
            (x) => x.areaName === this.state.area
          );
        }
      }

      distritos = distritos.concat(distritosToFilter);
      distritos = distritos.filter((x) => x.districtName !== "");
      if (
        this.state.distrito !== "" &&
        this.state.distrito != null &&
        this.state.distrito != undefined
      ) {
        if (this.state.distrito.districtName !== "TODOS") {
          territoriosToFilter = territoriosToFilter.filter(
            (x) => x.districtName === this.state.distrito.districtName
          );
        }
      }

      territorios = territorios.concat(territoriosToFilter);
    }

    if (!this.props.enrichment.enrichment) {
      rows = [];
    }
    const handleChangePage = (event, newPage) => {
      this.setState({ page: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
      this.setState({ page: 0 });
    };
    const { statusSelected, status, openanchor, searchText } = this.state;
    const anchor = "right";

    const toggleDrawer = (anchor, open) => (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      this.setState({ openanchor: open });
    };

    const list = (anchor) => (
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 550 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {[
            "Obrigado por dedicar seu tempo a essa grande prioridade da Corteva! Fizemos o máximo possível para que seja a experiência mais fácil e rápida.",
            "Algumas dicas para ajudar:",
          ].map((text, index) => (
            <ListItem key={text}>
              <ListItemText
                primary={text}
                sx={{ textAlign: index === 0 ? "center" : "start" }}
              />
            </ListItem>
          ))}
        </List>

        <Divider />

        <List>
          {[
            "Queremos olhar para frente: Coloque a estimativa dos dados de área plantada nas safras verão e safrinha de 23/24 e 24/25.",
            "Faça o seu melhor, não precisa ser exato, mas a melhor informação que tiver.",
            "Pool de Compras: É uma associação de produtores que tem como objetivo realizar compras conjuntas através de um centralizador. Se a conta fizer parte de algum informe.",
            "Visão Única: Similar a ‘UP’ É um grupo de produtores onde decisões são tomadas em conjunto, como por exemplo, a gestão das propriedades, decisões técnicas, entre outros. Pode ser composta por produtores da mesma família ou não. Um produtor de uma visão única também pode fazer parte de um pool de compras.",
            "Os dados pré-preenchidos estão errados? Corrija.",
            "Se a fazenda não deve estar mais na sua carteira Remova desmarcando a opção ‘Carteira’. A conta será removida de sua carteira após revisão das áreas competentes.",
            "O campo Visão Única estará sempre preenchido. Se o cliente não se agrupa com ninguém ele terá uma visão única com ele mesmo (somente 1 CNPJ/CPF).",
            "Se o Cliente não faz parte de Pool de Compras. Use a opção ‘Não faz parte de Pool de Compras’.",
            "Consultores – Influenciadores é uma informação nova. Preencha da melhor forma possível. Por enquanto estamos buscando conhecer influenciadores externos à Fazenda – para uma melhor análise de investimentos e direcionamento de trabalhos com Agronomia e Pesquisa.",
          ].map((text, index) => (
            <ListItem key={text.split("-")[0]}>
              <ListItemIcon>
                {/* <InfoIcon color="primary" /> */}
                <Avatar
                  sx={{ bgcolor: "var(--cort-blue)", width: 30, height: 30 }}
                >
                  {index + 1}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={text.split("–")[0]}
                secondary={
                  text.split("–")[1] && (
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      ></Typography>
                      {text.split("–")[1]}
                    </React.Fragment>
                  )
                }
              />
            </ListItem>
          ))}
        </List>

        <Divider />

        {/* <List>
          {["Prazo para finalização do preenchimento: 18/11"].map(
            (text, index) => (
              <ListItem key={text}>
                <ListItemIcon>
                  <EventIcon color="danger" />
                </ListItemIcon>
                <ListItemText primary={text} sx={{ textAlign: "center" }} />
              </ListItem>
            )
          )}
        </List> */}
        
        <List>
          {["Dúvidas ou Problemas: "].map((text, index) => (
            <ListItem key={text}>
              <ListItemText
                primary={text}
                sx={{ textAlign: "center" }}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <MailIcon /> rogerio.s.ferreira@corteva.com | 62 99633 9409
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>

        {/* <List>
          {["rafael.b.mendes@corteva.com | 11 989999838"].map((text, index) => (
            <ListItem key={text} sx={{ textAlign: "center" }}>
              <ListItemIcon>
                <MailIcon color="danger" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Box>
    );

    const statusList = [];

    status
      .filter((s) => statusSelected.indexOf(s.text) > -1)
      .map((st) => statusList.push(st.id));

    var rowsFilter = rows.filter((k) => statusList.indexOf(k.status) > -1);

    if (searchText !== "") {
      rowsFilter = rowsFilter.filter(
        (k) =>
          k?.visaoUnica?.toLowerCase().includes(searchText.toLowerCase()) ||
          k?.cliente?.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    // .sort((a, b) => {
    //   return a.visaoUnica?.localeCompare(b.visaoUnica);
    // });

    return (
      <Grid container spacing={2}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.user.isLoadingTerritorios || this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {!(
              this.props.user.isLoadingTerritorios ||
              this.props.user.isLoadingRole ||
              this.props.user.isLoadingRoleLevels
            ) &&
              role.accessLevel > roleLevels?.viewTerritory && (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginTop: 0,
                    marginLeft: 0,
                    marginBottom: "16px",
                    width: "calc(100% - 16px)",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    className="rounded-3"
                    sx={{
                      marginLeft: "16px",
                      marginTop: "16px",
                      backgroundColor: "#e6e8ea",
                      fontWeight: "500",
                      padding: "8px 16px !important",
                    }}
                  >
                    <Stack
                      justifyContent="flex-start"
                      alignItems="center"
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                      <h6 className="m-0">
                        Selecione o território desejado para visualização dos
                        dados:
                      </h6>
                      <ButtonSelectTerrritory
                        variant="outlined"
                        color={"azul"}
                        selectedArea={
                          this.state.area ? this.state.area : areas[0]
                        }
                        areas={areas}
                        onChangeArea={this.onChangeArea}
                        selectedDistrito={
                          this.state.distrito
                            ? this.state.distrito
                            : distritos[0]
                        }
                        distritos={distritos}
                        onChangeDistrito={this.onChangeDistrito}
                        selectedTerritorio={this.state.territorio}
                        territorios={territorios}
                        onChangeTerritorio={this.onChangeTerritorio}
                        role={role}
                        roleLevels={roleLevels}
                      >
                        Selecionar Território
                      </ButtonSelectTerrritory>
                      {role?.accessLevel >= roleLevels?.viewDistrict && (
                        <p className="mb-0">
                          Território: {this.state.territorio?.territoryName}
                        </p>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              )}

            <EnhancedTableToolbar
              actions={this.props.actions}
              anchor={anchor}
              openanchor={openanchor}
              toggleDrawer={toggleDrawer}
              list={list}
              status={status}
              statusSelected={statusSelected}
              handleChangeSelect={this.handleChangeSelect}
              handleSearchText={this.handleSearchText}
              msgSave={this.props.enrichment.msgSave ?? "Sem alterações"}
              territorio={this.state.territorio?.territoryId}
            />
            {!this.props.enrichment.isLoading &&
              this.props.enrichment.enrichment &&
              this.props.enrichment.enrichment.length > 0 &&
              <RenderForecastsTable
                props={this.props}
                state={this.state}
                rows={rowsFilter}
                status={status}
                statusSelected={statusSelected}
                handleChangeSelect={this.handleChangeSelect}
                territorio={this.state.territorio?.territoryId}
              />}
            <TablePagination
              // sx={{
              //   ".MuiTablePagination-toolbar": {
              //     backgroundColor: "#a9aeb3",
              //     width: "950px",
              //     color: "rgb(41, 39, 39)",
              //     height: "35px",
              //   },
              // }}
              rowsPerPageOptions={[10, 20, 25, 100]}
              component="div"
              count={rowsFilter.length ?? 0}
              labelRowsPerPage="Linhas por página"
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={function defaultLabelDisplayedRows({
                from,
                to,
                count,
              }) {
                return `${from}-${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
              }}
              SelectProps={{
                inputProps: {
                  "aria-label": "linhas por página",
                  label: "linhas por página",
                },
                native: true,
              }}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          {this.props.enrichment.isLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    );
  }
}

const groups = [
  { label: "Soja", id: "Soja" },
  { label: "Milho Verão", id: "MilhoVerao" },
  { label: "Algodão Verão", id: "AlgodaoVerao" },
  { label: "Sorgo Verão", id: "SorgoVerao" },
  { label: "Girassol Verão", id: "GirassolVerao" },
  { label: "Gergelim Verão", id: "GergelimVerao" },
  { label: "Milho Safrinha", id: "MilhoSafrinha" },
  { label: "Algodão Safrinha", id: "AlgodaoSafrinha" },
  { label: "Sorgo Safrinha", id: "SorgoSafrinha" },
  { label: "Girassol Safrinha", id: "GirassolSafrinha" },
  { label: "Gergelim Safrinha", id: "GergelimSafrinha" },
];

const CulturasCol = () => {
  var cols = []
  const labels = {
    "sojaGrao": "Soja Grão", "sojaSemente": "Soja prod. semente",
    "milhoVeraoGrao": "Milho Grão", "milhoVeraoSilagem": "Milho Silagem", "milhoVeraoSemente": "Produção de Sementes",
    "milhoSafrinhaGrao": "Milho Grão", "milhoSafrinhaSilagem": "Milho Silagem", "milhoSafrinhaSemente": "Produção de Sementes",
    "sorgoVerao": "Sorgo",
    "sorgoSafrinha": "Sorgo",
    "algodaoVerao": "Algodão",
    "algodaoSafrinha": "Algodão",
    "girassolVerao": "Girassol",
    "girassolSafrinha": "Girassol",
    "gergelimVerao": "Gergelim",
    "gergelimSafrinha": "Gergelim",
  };
  for (var safra of Safras) {
    for (var cultura of CulturasIndex) {
      for (var c of CultureList[cultura]) {
        var cIndex = CultureList[cultura].indexOf(c);
        cols.push({
          id: c,
          label: `${labels[c]} (Ha)`,
          style: { maxWidth: 95, width: 95, minxWidth: 95, borderLeft: (cIndex === 0 ? "1px solid #FFF" : "") },
          precision: 0,
          align: "right",
          format: "inputNumber",
          group: cultura,
          safra: safra,
        })
      }
      // if (CultureList[cultura].length > 1) {
      //   // eslint-disable-next-line no-loop-func
      //   var group = groups.filter(x => x.id === cultura)[0].label.split(" ")[0]
      //   cols.push({
      //     id: `total${cultura}`,
      //     label: `Total ${group} (Ha)`,
      //     style: { maxWidth: 95, width: 95, minxWidth: 95 },
      //     precision: 0,
      //     align: "right",
      //     format: "number",
      //     group: cultura,
      //     safra: safra,
      //   })
      // }
    }
    cols.push({
      id: `totalHa`,
      label: `Área plantada na Fazenda (Ha)`,
      style: { maxWidth: 95, width: 95, minWidth: 95, borderLeft: "1px solid #FFF", textAlign: "end" },
      precision: 0,
      align: "right",
      format: "number",
      safra: safra,
    })
  }
  return cols;
}
function RenderForecastsTable({ props, state, rows, status, statusSelected, handleChangeSelect, territorio }) {
  const { page, rowsPerPage } = state;
  const confirm = useConfirm();
  const rowsSlice = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const columns = [
    {
      id: "",
      label: "",
      // label: (<PopupState variant="popover" popupId="demo-popup-popover">
      //   {(popupState) => (
      //     <>
      //       <Tooltip title="Filtro" >
      //         <IconButton {...bindTrigger(popupState)} color="fullWhite" size="small" >
      //           <FilterListIcon />
      //         </IconButton>
      //       </Tooltip>
      //       <Popover
      //         {...bindPopover(popupState)}
      //         anchorOrigin={{
      //           vertical: 'bottom',
      //           horizontal: 'center',
      //         }}
      //         transformOrigin={{
      //           vertical: 'top',
      //           horizontal: 'left',
      //         }}
      //       >
      //         <FormControl component="fieldset" className="p-1">
      //           <FormGroup aria-label="position" sx={{ paddingTop: "0.5rem" }}>
      //             {status.map((st) => (
      //               <FormControlLabel
      //                 key={st.id}
      //                 value="top"
      //                 control={
      //                   <Switch
      //                     checked={statusSelected.indexOf(st.text) > -1}
      //                     size="small"
      //                     name={st.text}
      //                     onChange={handleChangeSelect}
      //                     inputProps={{ 'aria-label': 'controlled' }}
      //                   />
      //                 }
      //                 sx={{ marginLeft: 0 }}
      //                 label={st.text}
      //               // labelPlacement="top"
      //               />
      //             ))}
      //           </FormGroup>
      //         </FormControl>
      //       </Popover>
      //     </>
      //   )}
      // </PopupState>),
      style: {
        position: "sticky",
        left: 0,
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        zIndex: 998,
      },
      align: "center",
      format: "action",
    },
    {
      id: "visaoUnica",
      label: "Visao Única",
      style: {
        position: "sticky",
        left: 50,
        width: 190,
        minWidth: 190,
        maxWidth: 190,
        zIndex: 999,
      },
      align: "center",
      format: "inputTextSelect",
      options: props.enrichment.productionUnits ?? [],
      confirmBeforeInsert: async (value) => {
        if ((props.enrichment.productionUnits ?? []).indexOf(value) === -1) {
          try {
            await confirm({
              title: "Deseja criar um novo nome?",
              description: "Não foi localizado nenhum registro com o nome informado e um novo será criado.",
              confirmationText: "Sim, salvar",
              cancellationText: "Não, descartar alterações",
            })
          } catch (e) {
         //   console.log(e)
            return false
          }

          return true
        }
        return true
      }
    },
    {
      id: "cliente",
      label: "Nome do Produtor",
      align: "center",
      style: {
        position: "sticky",
        left: 240,
        width: 250,
        minWidth: 250,
        maxWidth: 250,
        zIndex: 1000,
        whiteSpace: "break-spaces",
      },
    },
    {
      id: "documentoFormat",
      label: "CPF/CNPJ",
      style: {
        width: 100,
        minWidth: 100,
      },
      align: "center",
    },
    {
      id: "ie",
      label: "Inscrição Estadual",

      style: { minWidth: 120, width: 120 },
      align: "center",
    },
    {
      id: "municipio",
      label: "Municipio/UF da Fazenda",
      style: { minWidth: 130, width: 130 },
      align: "center",
    },
    {
      id: "endereco",
      label: "Endereço da Fazenda",
      style: { minWidth: 220, width: 220 },
      align: "center",
    },
    {
      id: "account",
      label: "Cod Único da Conta",
      style: { minWidth: 120, width: 120 },
      align: "center",
    },
    {
      id: "codSementes",
      label: "Cod Sementes",
      style: { minWidth: 90, width: 90 },
      align: "center",
    },
    // {
    //   id: "codDOW",
    //   label: "Cód DOW",
    //   style: { minWidth: 170 },
    //   align: "center",
    // },
    // {
    //   id: "codDuPont",
    //   label: "Cód DuPont",
    //   style: { minWidth: 170 },
    //   align: "center",
    // },
    {
      id: "carteira",
      label: "Carteira",
      style: { maxWidth: 60, minWidth: 60, width: 60 },
      align: "center",
      format: "inputCheckbox",
      tooltipTrue:
        "Desmarque essa opção se a fazenda não deve estar mais na sua carteira, ao desmarcar todos os dados de Ha serão zerados.",
      tooltipFalse:
        "Marque essa opção se a fazenda fizer parte da sua carteira",
    },
    {
      id: "fazenda",
      label: "Nome da Fazenda",
      style: { minWidth: 160, maxWidth: 160, width: 160 },
      align: "center",
      format: "inputText",
      defaultValue: "Faz. "
    },

    {
      id: "pool",
      label: "Pool",
      style: { minWidth: 130, maxWidth: 130, width: 130 },
      align: "center",
      format: "inputSelect",
      options: props.enrichment.purchasePools ?? [],
    },
    {
      id: "consultorInfluenciador",
      label: "Consultor Influenciador Externo",
      style: { minWidth: 240, maxWidth: 240, width: 240/*, borderLeft: "1px solid #FFF"*/ },
      align: "center",
      format: "ConsultorInfluencidorSelect",
      options: props.enrichment.consultorInfluenciador ?? [],
    },
    ...CulturasCol(),
    // {
    //   id: "totalHa",
    //   label: "Total da Fazenda (Ha)",
    //   style: { minWidth: 95, textAlign: "end", width: 95, borderLeft: "1px solid #FFF" },
    //   align: "right",
    //   format: "number",
    // },
    // {
    //   id: "pastagem",
    //   label: "Pastagem (Ha)",
    //   style: { minWidth: 170 },
    //   align: "center",
    //   format: "number",
    //   precision: 0,
    //   align: "right",
    //   format: "inputNumber",
    // },
    // {
    //   id: "cabecasGado",
    //   label: "Cabeças de Gado (Un)",
    //   style: { minWidth: 170 },
    //   align: "center",
    //   format: "number",
    //   precision: 0,
    //   align: "right",
    //   format: "inputNumber",
    // },
  ];

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: "border-box",
      "& ul": {
        padding: 0,
        margin: 0,
        fontSize: "8px",
      },
    },
  });

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });
  const LISTBOX_PADDING = 0; // px

  // Adapter for react-window
  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });


  const safras = [{ label: "SAFRA 23/24", id: "23_24" }, { label: "SAFRA 24/25", id: "24_25" }];
  const [culturaSelected, setCulturaSelected] = React.useState(groups.map(x => x.id));
  const [safraSelected, setSafraSelected] = React.useState(safras.map(x => x.id));

  const ChangeSelected = (ev) => {
    let aux = [...culturaSelected];
    const { name } = ev.target;
    if (aux.indexOf(name) === -1) {
      aux.push(name);
    } else {
      aux = aux.filter((b) => b !== name);
    }
    setCulturaSelected(aux);
  }

  const ChangeSafraSelected = (ev) => {
    let aux = [...safraSelected];
    const { name } = ev.target;
    if (aux.indexOf(name) === -1) {
      aux.push(name);
    } else {
      aux = aux.filter((b) => b !== name);
    }
    setSafraSelected(aux);
  }

  const colVeraoLen = (culturaSelected.indexOf("Soja") !== -1 ? 2 : 0) + (culturaSelected.indexOf("MilhoVerao") !== -1 ? 3 : 0) + (culturaSelected.indexOf("AlgodaoVerao") !== -1 ? 1 : 0) + (culturaSelected.indexOf("SorgoVerao") !== -1 ? 1 : 0) + (culturaSelected.indexOf("GirassolVerao") !== -1 ? 1 : 0) + (culturaSelected.indexOf("GergelimVerao") !== -1 ? 1 : 0)
  const colSafrinhaLen = (culturaSelected.indexOf("MilhoSafrinha") !== -1 ? 3 : 0) + (culturaSelected.indexOf("AlgodaoSafrinha") !== -1 ? 1 : 0) + (culturaSelected.indexOf("SorgoSafrinha") !== -1 ? 1 : 0) + (culturaSelected.indexOf("GirassolSafrinha") !== -1 ? 1 : 0) + (culturaSelected.indexOf("GergelimSafrinha") !== -1 ? 1 : 0)
  // console.log(props.enrichment);
  return (
    <React.Fragment>
      <Stack
        sx={{ margin: "8px" }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Paper sx={{ p: "2px 4px" }}>
          <FormControl component="fieldset" sx={{ justifyContent: "center" }}>
            <FormGroup aria-label="position" row sx={{ justifyContent: "center" }}>
              {safras.map((st) => (
                <FormControlLabel
                  key={st.id}
                  value="top"
                  control={
                    <Switch
                      checked={safraSelected.indexOf(st.id) > -1}
                      size="small"
                      name={st.id}
                      onChange={ChangeSafraSelected}
                    />
                  }
                  label={st.label}
                  labelPlacement="top"
                />
              ))}
            </FormGroup>
          </FormControl>
        </Paper>

        <Paper sx={{ p: "2px 4px" }}>
          <FormControl component="fieldset" sx={{ justifyContent: "center" }}>
            <FormGroup aria-label="position" row sx={{ justifyContent: "center" }}>
              {groups.map((st) => (
                <FormControlLabel
                  key={st.id}
                  value="top"
                  control={
                    <Switch
                      checked={culturaSelected.indexOf(st.id) > -1}
                      size="small"
                      name={st.id}
                      onChange={ChangeSelected}
                    />
                  }
                  label={st.label}
                  labelPlacement="top"
                />
              ))}
            </FormGroup>
          </FormControl>
        </Paper>

      </Stack>

      <TableContainer
        component={Paper}
        style={{
          maxWidth: "100%",
          maxHeight: "67vh",
        }}
      >
        <Table
          stickyHeader
          // sx={{ minWidth: "110vw" }}
          className="enrichTable"
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow sx={{ height: (safraSelected.length === 0 ? "87px" : "29px") }}>
              <TableCell align="center" colSpan={3} rowSpan={3} sx={{ left: 0 }}>
                <Paper sx={{ p: "2px 4px", backgroundColor: "#ffffffad" }}>
                  <FormControl component="fieldset" className="p-1" sx={{ width: "100%" }}>
                    <FormGroup aria-label="position" sx={{ paddingTop: "0.5rem", flexDirection: "row", justifyContent: "space-around" }}>
                      {status.map((st) => (
                        <FormControlLabel
                          key={st.id}
                          value="top"
                          control={
                            <Switch
                              checked={statusSelected.indexOf(st.text) > -1}
                              size="small"
                              name={st.text}
                              onChange={handleChangeSelect}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          sx={{ margin: 0 }}
                          label={
                            <div>
                              {st.text}
                              {st.id !== 2 ? <AssignmentTurnedInIcon color={st.id === 1 ? "amarelo" : "vermelho"} /> :
                                <CheckCircleIcon color="verde" />
                              }
                            </div>
                          }
                          labelPlacement="top"
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Paper>
              </TableCell>
              <TableCell align="center" colSpan={10} rowSpan={3}>
                Informações
              </TableCell>
              {safraSelected.map(s => {
                const safra = safras.filter(x => x.id === s)[0];
                return (
                  <TableCell key={s} align="right" colSpan={colVeraoLen + colSafrinhaLen + 1} sx={{ minWidth: "90px", borderLeft: "1px solid #FFF" }}>
                    {safra.label}
                  </TableCell>
                )
              })
              }
              {/* <TableCell align="right" sx={{ minWidth: "90px", borderLeft: "1px solid #FFF" }} rowSpan={3}>
                {props.enrichment.total ?? 0} Ha
              </TableCell> */}
            </TableRow>
            <TableRow sx={{ height: (safraSelected.length === 0 ? "0" : (colVeraoLen === 0 && colSafrinhaLen === 0 ? "58px" : "29px")) }}>

              {safraSelected.map(s => {
                return (
                  <React.Fragment key={s}>
                    {colVeraoLen > 0 &&
                      <TableCell align="right" colSpan={colVeraoLen} sx={{ minWidth: "90px", borderLeft: "1px solid #FFF", whiteSpace: "nowrap" }}>
                        Verão {s === "23_24" ? "2023" : "2024"}
                      </TableCell>
                    }
                    {colSafrinhaLen > 0 &&
                      <TableCell align="right" colSpan={colSafrinhaLen} sx={{ minWidth: "90px", borderLeft: "1px solid #FFF", whiteSpace: "nowrap" }}>
                        Safrinha {s === "23_24" ? "2024" : "2025"}
                      </TableCell>
                    }
                    <TableCell align="right" rowSpan={2} sx={{ minWidth: "95px", borderLeft: "1px solid #FFF" }}>
                      {props.enrichment[s]["total"] ?? 0} Ha
                    </TableCell>
                  </React.Fragment>
                )
              })
              }
            </TableRow>
            <TableRow>
              {Safras.filter(x => safraSelected.indexOf(x) !== -1).map(safra => {
                return (
                  <React.Fragment key={safra}>
                    {CulturasIndex.filter(x => culturaSelected.indexOf(x) !== -1).map(cultura => {
                      var culturas = CultureList[cultura];
                      return (
                        <React.Fragment key={`${safra}-${cultura}`}>
                          {culturas.map((c, cIndex) => {
                            return (
                              <TableCell key={`${safra}-${cultura}-${c}-${cIndex}`} align="right" sx={{ minWidth: "90px", borderLeft: (cIndex === 0 ? "1px solid #FFF" : "") }}>
                                {props.enrichment[safra]["total" + c] ?? 0} Ha
                              </TableCell>
                            );
                          })}
                          {/* {culturas.length > 1 &&
                            <TableCell align="right" sx={{ minWidth: "90px" }}>
                              {props.enrichment[safra]["total" + cultura] ?? 0} Ha
                            </TableCell>
                          } */}
                        </React.Fragment>
                      );
                    })
                    }
                  </React.Fragment>
                );
              })
              }
            </TableRow>
            <TableRow>
              {columns.filter(x => (x.group === undefined || culturaSelected.indexOf(x.group) !== -1) && (x.safra === undefined || safraSelected.indexOf(x.safra) !== -1)).map((column) => (
                <TableCell
                  key={column.id + (column.safra ?? "")}
                  align={column.align}
                  sx={column.style}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsSlice.map((row, i) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.filter(x => (x.group === undefined || culturaSelected.indexOf(x.group) !== -1) && (x.safra === undefined || safraSelected.indexOf(x.safra) !== -1)).map((column) => {
                    var value = row[column.id];
                    if (column.defaultValue != null && column.defaultValue !== undefined) {
                      value = value != null && value !== undefined && value.replaceAll(" ", "") !== "" ? value : column.defaultValue;
                    }

                    if (column.format === "inputNumber") {
                      value = row["culturaSafra"][column.safra]["culturasInfo"][column.id]["value"];
                    }
                    else if (column.format === "number" && column.safra && column.id !== "totalHa") {
                      value = row["culturaSafra"][column.safra]["totais"][column.id];
                    }
                    else if (column.format === "number" && column.safra && column.id === "totalHa") {
                      value = row["culturaSafra"][column.safra]["totalHa"];
                    }

                    return (
                      <TableCell
                        key={column.id + (column.safra ?? "")}
                        align={column.align}
                        style={column.style}
                      >
                        {column.format &&
                          column.format === "action" &&
                          row.status !== 2 && (
                            <Tooltip title="Finalizar preenchimento desse produtor">
                              <IconButton
                                aria-label="Finalizar preenchimento"
                                color={row.status === 1 ? "amarelo" : "vermelho"}
                                onClick={() => props.actions.finalizar(row, i)}
                              >
                                <AssignmentTurnedInIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                        {column.format &&
                          column.format === "action" &&
                          row.status === 2 && (
                            <Tooltip title="Finalizado, clique para voltar para a edição">
                              <IconButton
                                color="verde"
                                onClick={() =>
                                  props.actions.VoltarEmAndamento(row, i)
                                }
                              >
                                <CheckCircleIcon sx={{ mr: 1 }} />
                              </IconButton>
                            </Tooltip>
                          )}

                        {column.format && column.format === "inputNumber" ? (
                          <NumericInput
                            disabled={!row.carteira || row.status === 2}
                            name={column.id}
                            precision={column.precision}
                            aria-describedby="outlined-weight-helper-text"
                            decimalChar=","
                            thousandChar="."
                            value={value ?? 0}
                            onInput={(e) =>
                              props.actions.handleChangeNumeric(e, row, i, column.safra)
                            }
                            onBlur={(e) => {
                              props.actions.handleBlurNumeric(e, row, i)
                            }}
                            style={{ minWidth: "90px" }}
                            // onChange={(e) => props.actions.handleChange(e, row, i)}
                            className="numericInput"
                          />
                        ) : column.format && column.format === "inputCheckbox" ? (
                          <Tooltip
                            title={
                              value
                                ? column.tooltipTrue ?? ""
                                : column.tooltipFalse ?? ""
                            }
                          >
                            <input
                              disabled={row.status === 2}
                              type="checkbox"
                              name={column.id}
                              defaultChecked={value}
                              onChange={(e) =>
                                props.actions.handleChangeCheckbox(e, row, i)
                              }
                              style={{ textAlign: "end", height: "24px" }}
                            />
                          </Tooltip>
                        ) : column.format && column.format === "inputText" ? (
                          <TextField
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            defaultValue={value}
                            name={column.id}
                            onBlur={(e) => props.actions.handleChange(e, row, i)}
                            disabled={!row.carteira || row.status === 2}
                          />
                        ) : column.format && column.format === "inputTextSelect" ? (
                          <Autocomplete
                            freeSolo
                            value={value}
                            PopperComponent={StyledPopper}
                            renderOption={(props, option) => [props, option]}
                            ListboxComponent={ListboxComponent}
                            size="small"
                            sx={{
                              display: "inline-block",
                              width: "100%",
                            }}
                            id={`${column.id}-${row.id}`}
                            disabled={!row.carteira || row.status === 2}
                            disableClearable
                            options={column.options}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={column.id}
                                onBlur={async (e) => {
                                  if (column.confirmBeforeInsert == null || column.confirmBeforeInsert === undefined) {
                                    props.actions.handleChange(e, row, i)
                                  } else if (await column.confirmBeforeInsert(e.target.value)) {
                                    props.actions.handleChange(e, row, i)
                                  }
                                }
                                }
                                disabled={!row.carteira}
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                              />
                            )}
                          />
                        ) : column.format && column.format === "inputSelect" ? (
                          <Autocomplete
                            value={value}
                            PopperComponent={StyledPopper}
                            renderOption={(props, option) => [props, option]}
                            ListboxComponent={ListboxComponent}
                            size="small"
                            sx={{
                              display: "inline-block",
                              width: "100%",
                            }}
                            id={`${column.id}-${row.id}`}
                            disabled={!row.carteira || row.status === 2}
                            disableClearable
                            options={column.options}
                            onChange={(event, newValue) => {
                              const e = { target: { name: column.id, value: newValue } };
                              props.actions.handleChange(e, row, i)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={column.id}
                                // onBlur={(e) =>
                                //   props.actions.handleChange(e, row, i)
                                // }
                                disabled={!row.carteira}
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                              />
                            )}
                          />
                        ) : column.format && column.format === "ConsultorInfluencidorSelect" ? (
                          <ConInfSelect
                            value={column.options.find(x => x.id === value)}
                            PopperComponent={StyledPopper}
                            renderOption={(props, option) => [props, option.label]}
                            ListboxComponent={ListboxComponent}
                            size="small"
                            sx={{
                              display: "inline-block",
                              width: "100%",
                            }}
                            id={`${column.id}-${row.id}`}
                            disabled={!row.carteira || row.status === 2}
                            disableClearable
                            options={column.options}
                            onChange={(event, newValue) => {
                              const e = { target: { name: column.id, value: newValue } };
                              props.actions.handleChange(e, row, i)
                            }}
                            handleCreate={(newValue) => { props.actions.createConsultorInfluenciador(newValue, row, territorio, column.id, i) }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={column.id}
                                // onBlur={(e) =>
                                //   props.actions.handleChange(e, row, i)
                                // }
                                disabled={!row.carteira}
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                              />
                            )}
                          />
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...enrichmentActions,
          ...userActions,
        },
        dispatch
      ),
    };
  }
)(EnrichmentPage);
