import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchIcon from '@mui/icons-material/Search';
import { FormControlLabel, Grid, InputBase, Stack } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { accountWhitespaceActions } from "../../../../../actions/admin/accountWhitespace.actions";
import Switch from '@mui/material/Switch';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort,
    changeCondition, clients
  } = props;

  const stateLock = clients.length > 0 ? clients.every(x => x.stateLock === true) : false;
  const countyLock = clients.length > 0 ? clients.every(x => x.countyLock === true) : false;
  const onetoone = clients.length > 0 ? clients.every(x => x.onetoone === true) : false;

  const [globalRow, setGlobalRow] = React.useState({
    stateLock,
    countyLock,
    onetoone,
  });

  if (globalRow.countyLock !== countyLock || globalRow.stateLock !== stateLock || globalRow.onetoone !== onetoone) {
    setGlobalRow({
      stateLock,
      countyLock,
      onetoone,
    });
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleCountyChange = (event, row) => {
    row.countyLock = event.target.checked;
    setGlobalRow(row);

    changeCondition({ type: "countylock", value: event.target.checked, row: row, general: true });
  };

  const handleStateChange = (event, row) => {
    row.stateLock = event.target.checked;
    setGlobalRow(row);

    changeCondition({ type: "statelock", value: event.target.checked, row: row, general: true });
  };

  const handleOneToChange = (event, row) => {
    row.onetoone = event.target.checked;
    setGlobalRow(row);

    changeCondition({ type: "relationlock", value: event.target.checked, row: row, general: true });
  };

  return (
    <TableHead
      sx={{
        backgroundColor: "#0072CE",
        "th": {
          color: "#FFF",
          fontWeight: "bold"
        },
      }}>
      <TableRow>
        <TableCell
          key={'name'}
          align={'left'}
          sortDirection={orderBy === 'name' ? order : false}
          size="medium"
          sx={{
            width: "100%",
            '*': {
              color: '#fff !important',
              '&:hover': {
                color: '#FFF !important',
              },
            },
          }}
        >
          Nome
        </TableCell>
        <TableCell
          key={'configuration'}
          align={'center'}
          sortDirection={false}
          size="medium"
          sx={{
            '*': {
              color: '#fff !important',
              '&:hover': {
                color: '#FFF !important',
              },
            },
          }}
        >
          <div>
            <h5>Configurações</h5>

            <Grid
              container
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 1.5, md: 2 }}
              justifyContent="center"
              alignItems="center"
              style={{ fontWeight: 'bold', fontSize: '1.1em', width: "max-content" }}
            >
              <Grid item>
                <Tooltip title="Permitir atribuições em estados diferentes do estado da conta.">
                  <FormControlLabel
                    sx={{ color: "#0072CE", fontWeight: "bold" }}
                    key={"statelock-global"}
                    onChange={(x) => handleStateChange(x, globalRow)}
                    control={<Switch checked={globalRow.stateLock} />}
                    label="Habilitar para Todos"
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Habilitar atribuições em Municípios que não fazem fronteira com o município da conta.">
                  <FormControlLabel
                    sx={{ color: "#0072CE", fontWeight: "bold" }}
                    key={"countylock-global"}
                    onChange={(x) => handleCountyChange(x, globalRow)}
                    control={<Switch checked={globalRow.countyLock} />}
                    label="Habilitar para Todos"
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Permitir apenas uma atribuição por account.">
                  <FormControlLabel
                    sx={{ color: "#0072CE", fontWeight: "bold" }}
                    key={"onetoonelock-global"}
                    onChange={(x) => handleOneToChange(x, globalRow)}
                    control={<Switch checked={globalRow.onetoone} />}
                    label="Bloquear para Todos"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />

      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />

      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`; }

function EnhancedTableToolbar({ handleSearchText = (event) => { } }) {
  return (
    <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }}
      spacing={{ xs: 1, sm: 1.5, md: 2 }}
      justifyContent={{ xs: "center", sm: "center", md: "space-around" }}
      alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
      sx={{ mb: "16px" }}
    >
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquise por Nome"
          onChange={handleSearchText}
          inputProps={{ "aria-label": "Pesquise por Nome" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </Stack>
  );
}

function CustomPaginationActionsTable({ rows, oidc, handleSearchText, changeCondition, ...props }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('probBuy2MoreProducts');

  const handleCountyChange = (event, row) => {
    changeCondition({ type: "countylock", value: event.target.checked, row: row });
  };

  const handleStateChange = (event, row) => {
    changeCondition({ type: "statelock", value: event.target.checked, row: row });
  };

  const handleOneToChange = (event, row) => {
    changeCondition({ type: "relationlock", value: event.target.checked, row: row });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <EnhancedTableToolbar handleSearchText={handleSearchText} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            changeCondition={changeCondition}
            clients={rows}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              : stableSort(rows, getComparator(order, orderBy))
            ).map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ width: "40%" }} >
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    <Grid
                      container
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 1.5, md: 2 }}
                      justifyContent="center"
                      alignItems="center"
                      style={{ fontWeight: 'bold', fontSize: '1.1em', width: "max-content" }}
                    >
                      <Grid item>
                        <Tooltip title="Pemitir atribuições fora do estado da conta.">
                          <FormControlLabel key={"statelock-" + row.id} onChange={(x) => handleStateChange(x, row)} control={<Switch defaultChecked={row.stateLock} />} label="Permitir fora do Estado" />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Pemitir atribuições em Municípios que não fazem fronteira com o município da conta.">
                          <FormControlLabel key={"countylock-" + row.id} onChange={(x) => handleCountyChange(x, row)} control={<Switch defaultChecked={row.countyLock} />} label="Permitir Municípos não fronteiriços" />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Permitir apenas uma atribuição de fazenda por account.">
                          <FormControlLabel key={"onetoonelock-" + row.id} onChange={(x) => handleOneToChange(x, row)} control={<Switch defaultChecked={row.onetoone} />} label="1:1" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage="Linhas por página:"
          labelDisplayedRows={defaultLabelDisplayedRows}
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'Linhas por página',
            },
            native: false,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </>
  );
}

class AdminAccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      statusSelected: [],
    };
  }

  componentWillMount() {
    this.props.actions.getClientsList();
  }

  handleChangeSelect = (event) => {
    const { name, checked } = event.target;
    let { statusSelected } = this.state;
    if (checked && statusSelected.indexOf(name) === -1) {
      statusSelected.push(name);
    }

    if (!checked && statusSelected.indexOf(name) > -1) {
      statusSelected = statusSelected.filter((b) => b !== name);
    }

    this.setState({
      statusSelected: statusSelected,
    });
  };

  handleSearchText = (event) => {
    const { value } = event.target;

    this.setState({
      searchText: value,
    });
  };

  handleChangeConditions = (event) => {
    if (event.general == true) {
      var data = {
        StateLock: event.type == "statelock" ? event.value : event.row.stateLock,
        CountyAsideLock: event.type == "countylock" ? event.value : event.row.countyLock,
        OneToOneLock: event.type == "relationlock" ? event.value : event.row.onetoone
      };

      this.props.actions.setAllClientsConditions(data);
    }
    else {
      var data = {
        StateLock: event.type == "statelock" ? event.value : event.row.stateLock,
        CountyAsideLock: event.type == "countylock" ? event.value : event.row.countyLock,
        OneToOneLock: event.type == "relationlock" ? event.value : event.row.onetoone
      };

      this.props.actions.setClientConditions(event.row.id, data);
    }
  };

  render() {
    const { oidc } = this.props;

    var rows = [];
    if (!this.props.accountWhitespace.isLoadingClients && this.props.accountWhitespace.clients) {
      rows = this.props.accountWhitespace.clients.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    if (this.state.searchText !== "") {
      rows = rows.filter((k) =>
        k?.name?.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    }

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.accountWhitespace.isLoadingClients}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin-Whitespace"}>
                Whitespace
              </Link>
              <Typography color="text.primary">Configurações Vu's</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {!this.props.adminUser.isLoading &&
            <div>
              <CustomPaginationActionsTable
                rows={rows}
                oidc={oidc}
                handleSearchText={this.handleSearchText}
                changeCondition={this.handleChangeConditions}
              />
            </div>
          }
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...accountWhitespaceActions
      }, dispatch),
    };
  }
)(AdminAccountPage);
