import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "16px",
          my: 0,
          color: '#fff',
          display: 'block',
          fontWeight: "700",
          textTransform: "none"
        }
      }
    },
  }
});

function NavBarSimulador({ oidc, modulos = [] }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const userLoggedIn = oidc?.user;
  var salesforceLink = ""
  if (userLoggedIn) {
    salesforceLink = !oidc?.user?.profile?.upn?.toLowerCase().includes("@corteva.com") ?
      `https://corteva.my.site.com/Partner/s/report/00O3l000009DvWlEAK` :
      `https://corteva.lightning.force.com/lightning/r/Report/00O3l000009DvWlEAK/view`
  }

  return (
    <AppBar position="static" color='azul'>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Toolbar disableGutters sx={{ minHeight: "40px !important" }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <a target="_blank" href={salesforceLink} rel="noreferrer">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" color="#000">Salesforce</Typography>
                </MenuItem>
              </a>
              {modulos !== null && modulos.find(x => x.includes("Simulador2")) &&
                <Link to="/Simulador2">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" color="#000">Simulador</Typography>
                  </MenuItem>
                </Link>
              }
              {modulos !== null && modulos.find(x => x.includes("Simulador2")) &&
                <Link to="/WhitespaceView">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" color="#000">Whitespace Analytics</Typography>
                  </MenuItem>
                </Link>
              }
              <MenuItem onClick={handleCloseNavMenu} disabled={true}>
                <Typography textAlign="center" color="#000">Cross-Sell & Growth</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu} disabled={true}>
                <Typography textAlign="center" color="#000">Customer 1-Page</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <ThemeProvider theme={theme}>
              <a target="_blank" href={salesforceLink} rel="noreferrer">
                <Button onClick={handleCloseNavMenu}>
                  Salesforce
                </Button>
              </a>
              {modulos !== null && modulos.find(x => x.includes("Simulador2")) &&
                <Link to="/Simulador2">
                  <Button onClick={handleCloseNavMenu}>
                    Simulador
                  </Button>
                </Link>
              }
              {modulos !== null && modulos.find(x => x.includes("Simulador2")) &&
                <Link to="/WhitespaceView">
                  <Button onClick={handleCloseNavMenu}>
                    Whitespace Analytics
                  </Button>
                </Link>
              }
              <Button
                disabled
                onClick={handleCloseNavMenu}
                sx={{ color: '#ffffff80 !important' }}
              >
                Cross-Sell & Growth
              </Button>
              <Button
                disabled
                onClick={handleCloseNavMenu}
                sx={{ color: '#ffffff80 !important' }}
              >
                Customer 1-Page
              </Button>
              <a className="nav-link" target="_blank" href="https://app.powerbi.com.mcas.ms/groups/83b365d1-ccde-4480-a7d3-bda8b9ca0769/reports/ad772169-03f1-4ac4-888d-ab4c5e1c00d4/ReportSection40396ab4706c40901d26?experience=power-bi" rel="noreferrer">
                <Button onClick={handleCloseNavMenu}>
                  C360 Dashboard
                </Button>
              </a>
            </ThemeProvider>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBarSimulador;