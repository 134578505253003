import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CortevaIcon = (props) => {
    return (
        <SvgIcon {...props} width="884" height="882" viewBox="0 0 884 882">
            <path fillRule="evenodd" clipRule="evenodd" d="M-3.92678e-06 393.925C21.6795 186.558 186.426 21.76 393.768 -1.80928e-06L-3.92678e-06 393.925ZM6.27018 528.281C44.6518 719.259 205.857 865.806 403.962 882L100.557 578.48L172.104 506.958L537.787 872.781C574.22 864.62 608.958 851.971 641.389 835.445L242.593 436.496L315.221 363.898L728.392 777.226C753.18 756.014 775.599 732.112 795.195 705.972L631.725 541.549H871.935C879.505 509.099 883.507 475.279 883.507 440.524C883.507 436.822 883.462 433.131 883.371 429.45H529.138L440.543 340.857H872.25C864.205 305.871 852.017 272.467 836.23 241.19H440.543L529.138 152.597H777.177C714.017 78.8215 627.025 26.0528 528.13 6.21775L6.27018 528.281Z" />
        </SvgIcon>
    );
};

export default CortevaIcon;
