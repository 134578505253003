import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  DialogContent,
  FormControl,
  FormLabel,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Switch
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function formatToCNPJ(str) {
  if (str == null || str === undefined)
  return ""

  return str?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
}

function removeAccents(str) {
  if (str == null || str === undefined)
    return ""

  return str?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")?.replace(/\s+/g, "")?.toLowerCase();
}

function getMappedValues(array, property) {
  return Array.from(new Set(array.map(x => x[property])));
}

const FilterDialog = ({ applyFilter, label = "", rows, unfilteredRows }) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [values, setValues] = useState([]);

  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    if (showAll) {
      switch (removeAccents(label)) {
        case 'canal':
          setValues(getMappedValues(rows, 'canal'));
          break;
        case 'raizdecnpj':
          setValues(getMappedValues(rows, 'raizCNPJ'));
          break;
        case 'cnpj':
          setValues(getMappedValues(rows, 'cnpj'));
          break;
        case 'municipio':
          setValues(getMappedValues(rows, 'municipio'));
          break;
        case 'produto':
          setValues(getMappedValues(rows, 'produto'));
          break;
        case 'uf':
          setValues(getMappedValues(rows, "uf"));
          break;
        case 'segmento':
          setValues(getMappedValues(rows, "segmento"));
          break;
        default:
          setValues([]);
          break;
      }
    }
    else {
      switch (removeAccents(label)) {
        case 'canal':
          setValues(getMappedValues(unfilteredRows.flatMap(x => x.estoqueCanal), 'canal'));
          break;
        case 'raizdecnpj':
          setValues(getMappedValues(unfilteredRows.flatMap(x => x.estoqueCanal), 'raizCNPJ'));
          break;
        case 'cnpj':
          setValues(getMappedValues(unfilteredRows.flatMap(x => x.estoqueCanal), 'cnpj'));
          break;
        case 'municipio':
          setValues(getMappedValues(unfilteredRows.flatMap(x => x.estoqueCanal), 'municipio'));
          break;
        case 'produto':
          setValues(getMappedValues(unfilteredRows.flatMap(x => x.estoqueProduto), 'nome'));
          break;
        case 'uf':
          setValues(getMappedValues(unfilteredRows.flatMap(x => x.estoqueCanal), 'uf'));
          break;
        case 'segmento':
          setValues(getMappedValues(unfilteredRows.flatMap(x => x.estoqueProduto), 'segmento'));
          break;
        default:
          setValues([]);
          break;
      }
    }

  }, [showAll, label, rows, unfilteredRows]);

  const handleToggle = (e, value) => {
    const isSelected = selectedValue.includes(value);
    setSelectedValues((prevValues) =>
      isSelected
        ? prevValues.filter((prevValue) => prevValue !== value)
        : [...prevValues, value]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(removeAccents(e.target.value));
  };

  const toggleOpen = (e, value) => {
    e.stopPropagation();
    setOpen(value);
  }

  const filteredValues = values
    ? values.filter((value) => {
      return removeAccents(value)?.includes(searchTerm);
    })
    : [];

  const selected = filteredValues.filter(value => selectedValue.includes(value)).sort((a, b) => a.localeCompare(b));
  const unselected = filteredValues.filter(value => !selectedValue.includes(value)).sort((a, b) => a.localeCompare(b));
  const sortedValues = [...selected, ...unselected];

  return (
    <React.Fragment>
      <IconButton
        type="button"
        sx={{ color: "#FFF" }}
        aria-label="search"
        onClick={(e) => toggleOpen(e, true)}
      >
        <SearchIcon sx={{ fontSize: "1.2rem" }} />
      </IconButton>

      <Dialog
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={(e) => toggleOpen(e, false)}
        sx={{
          ".MuiDialog-paper": {
            width: "calc(100% - 64px)",
          },
        }}
      >
        <DialogTitle>Filtrar {label}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                placeholder={`Digite para buscar ${label}`}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel required onChange={() => setShowAll(!showAll)} control={<Switch defaultChecked={showAll} />} label={!showAll ? "Mostrar apenas dados filtrados" : "Mostrar todos os dados"} />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>{label}</FormLabel>
                <List sx={{ maxHeight: 200, overflowY: "auto" }}>
                  {filteredValues.length > 0 ? (
                    sortedValues.map((value, index) => (
                      <ListItem key={value + index} disablePadding>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedValue.includes(value)}
                              onChange={(e) => handleToggle(e, value)}
                            />
                          }
                          label={label.includes('CNPJ') ? formatToCNPJ(value) : value}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary={`Nenhum ${label} encontrado.`} />
                    </ListItem>
                  )}
                </List>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => toggleOpen(e, false)}>Cancelar</Button>
          <Button
            variant={"contained"}
            onClick={(e) => {
              applyFilter(selectedValue, label);
              toggleOpen(e, false)
            }}
            autoFocus
          >
            Aplicar Filtro
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FilterDialog;
