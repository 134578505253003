import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { ResumoColsCp } from "./ResumoColsCp";
import React, { useEffect, useRef, useState } from "react";
import { CellInput } from "./CellInput";

export function TableCpaCp({
  cpa,
  actuationAreaLabel = "Área",
  childrenActuationAreaLabel = "Business Area",
  responsavelLabel = "RSL",
  onChange = (id = 0, value = 0) => { },
  canEditParent = false,
  onChangeParent = (id = 0, value = 0) => { },
}) {
  const col1Ref = useRef(null);
  const col2Ref = useRef(null);
  const col3Ref = useRef(null);
  const [colWidths, setColWidths] = useState({ col1: 0, col2: 0, col3: 0 });

  const updateColWidths = () => {
    const col1Width = col1Ref.current ? col1Ref.current.offsetWidth : 0;
    const col2Width = col2Ref.current ? col2Ref.current.offsetWidth : 0;
    const col3Width = col3Ref.current ? col3Ref.current.offsetWidth : 0;
    setColWidths({ col1: col1Width, col2: col2Width, col3: col3Width });
  };

  useEffect(() => {
    updateColWidths();
    window.addEventListener('resize', updateColWidths);
    return () => {
      window.removeEventListener('resize', updateColWidths);
    };
  }, [cpa]);

  return <TableContainer component={Paper}
    sx={
      {
        whiteSpace: "nowrap",
        overflowX: "auto",
        ".fixed-row": {
          "td, th": {
            ":nth-of-type(1)": {
              left: "0",
              position: "sticky",
              background: "white",
              zIndex: 1,
            },
            ":nth-of-type(2)": {
              left: `${colWidths.col1}px`,
              position: "sticky",
              background: "white",
              zIndex: 1,
            },
            ":nth-of-type(3)": {
              left: `${colWidths.col1 + colWidths.col2}px`,
              boxShadow: "-1px 0px 0 rgba(224, 224, 224, 1) inset",
              position: "sticky",
              background: "white",
              zIndex: 1,
            },
            ":nth-of-type(4)": {
              left: `${colWidths.col1 + colWidths.col2 + colWidths.col3}px`,
              boxShadow: "-1px 0px 0 rgba(224, 224, 224, 1) inset",
              position: "sticky",
              background: "white",
              zIndex: 1,
            },
          },
        }
      }
    }
  >
    <Table>
      <TableHead>
        <TableRow className="fixed-row" sx={{ "th": { py: "8px" } }}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="center">Buget</TableCell>
          <TableCell align="center" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }} colSpan={5}>Cenários Aprovados</TableCell>
          <TableCell align="center" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }} colSpan={5}>Cenários Aguardando Aprovação</TableCell>
          <TableCell align="center" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }} colSpan={5}>Cenários Aprovados + Aguardando Aprovação</TableCell>
        </TableRow>
        <TableRow className="fixed-row"
          sx={{
            backgroundColor: "#0072CE ! important",
            "th": {
              backgroundColor: "#0072CE ! important",
              color: "#FFF",
              fontWeight: "bold",
              py: "8px"
            },
          }}
        >
          <TableCell ref={col1Ref}>{actuationAreaLabel}</TableCell>
          <TableCell ref={col2Ref}>{childrenActuationAreaLabel}</TableCell>
          <TableCell ref={col3Ref}>{responsavelLabel}</TableCell>
          <TableCell align="center">CPA Disponível</TableCell>
          {/* APROVADOS */}
          <TableCell align="right">Total Preço Base + Encargos</TableCell>
          <TableCell align="right">Total Geral Corteva</TableCell>
          <TableCell align="right">Total Geral Negociado</TableCell>
          <TableCell align="right">CPA Geral Usado</TableCell>
          <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>% CPA Geral Usado</TableCell>
          {/* AGUARDANDO APROVAÇÃO */}
          <TableCell align="right">Total Preço Base + Encargos</TableCell>
          <TableCell align="right">Total Geral Corteva</TableCell>
          <TableCell align="right">Total Geral Negociado</TableCell>
          <TableCell align="right">CPA Geral Usado</TableCell>
          <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>% CPA Geral Usado</TableCell>
          {/* APROVADOS + AGUARDANDO APROVAÇÃO*/}
          <TableCell align="right">Total Preço Base + Encargos</TableCell>
          <TableCell align="right">Total Geral Corteva</TableCell>
          <TableCell align="right">Total Geral Negociado</TableCell>
          <TableCell align="right">CPA Geral Usado</TableCell>
          <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>% CPA Geral Usado</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cpa && <TableRow className="fixed-row" sx={{ "td": { background: "#d9d9d9 !important", borderColor: "white !important", py: "8px", ":nth-of-type(4)": canEditParent && { px: "8px" } } }}>
          <TableCell>{cpa?.actuationArea}</TableCell>
          <TableCell>Total</TableCell>
          <TableCell sx={{ boxShadow: "-1px 0px 0 white inset !important" }}></TableCell>
          {canEditParent ?
            <CellInput
              name="perCpaDisponivel"
              placeholder=""
              value={cpa.perCpaDisponivel}
              onChange={(value) => onChangeParent(cpa.id, value)}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              customInput={TextField}
              size="small"
              suffix={"%"}
              sx={{ input: { textAlign: "right", fontSize: "0.875rem", p: "8.5px 8px" } }}
              allowNegative={false}
            /> :
            <TableCell sx={{ boxShadow: "-1px 0px 0 white inset !important" }} align="right">
              {Number((cpa?.perCpaDisponivel ?? 0).toFixed(2)).toLocaleString("pt-BR")}%
            </TableCell>
          }
          <ResumoColsCp cpa={cpa} />
        </TableRow>}
        {cpa?.children?.map((child) => {
          return <TableRow key={child.id} className="fixed-row" sx={{ "td": { py: "8px", ":nth-of-type(4)": { px: "8px" } } }}>
            <TableCell>{cpa?.actuationArea}</TableCell>
            <TableCell>{child.actuationArea}</TableCell>
            <TableCell>{child.responsavel?.name ?? "-"}</TableCell>
            <CellInput
              name="perCpaDisponivel"
              placeholder=""
              value={child.perCpaDisponivel}
              onChange={(value) => onChange(child.id, value)}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              customInput={TextField}
              size="small"
              suffix={"%"}
              sx={{ input: { textAlign: "right", fontSize: "0.875rem", p: "8.5px 8px" } }}
              maxValue={cpa?.perCpaDisponivel}
              messageError={"CPA acima do disponível!"}
              allowNegative={false}
            />
            <ResumoColsCp cpa={child} />
          </TableRow>
        })}
      </TableBody>
    </ Table>
  </TableContainer >
}