const requestSafrasType = "REQUEST_SAFRAS";
const receiveSafrasType = "RECEIVE_SAFRAS";
const requestSafraType = "REQUEST_SAFRA";
const receiveSafraType = "RECEIVE_SAFRA";

const initialState = { safras: [], safra: null, isLoading: false, isLoadingSafras: false };

export const adminWhitespaceActions = {
  requestSafras: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSafrasType });

    const url = `/api/safras/getAll`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const safras = await response.json();
    dispatch({ type: receiveSafrasType, safras });
  },
  requestSafra: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSafraType });

    const url = `/api/safras/get/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const safra = await response.json();
    dispatch({ type: receiveSafraType, safra });
  },
  updateSafra: (id, safraEdit, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSafraType });

    const url = `/api/safras/edit/${id}`;

    const options = {
      method: "POST",
      body: JSON.stringify(safraEdit),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-Whitespace');
      dispatch({ type: receiveSafraType, safra: null });
      dispatch(adminWhitespaceActions.requestSafras());
    }
  },
  saveSafra: (newSafra, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSafraType });

    const url = `/api/safras/create`;

    const options = {
      method: "POST",
      body: JSON.stringify(newSafra),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-Whitespace');
      dispatch({ type: receiveSafraType, safra: null });
      dispatch(adminWhitespaceActions.requestSafras());
    }
  },
  turnDefault: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/safras/default/${id}`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receiveSafraType, safra: null });
      dispatch(adminWhitespaceActions.requestSafras());
    }
  },
  disableSafra: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    const url = `/api/safras/disable/${id}`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receiveSafraType, safra: null });
      dispatch(adminWhitespaceActions.requestSafras());
    }
  },
  cleanSafra: () => async (dispatch, getState) => {
    dispatch({ type: receiveSafraType, safra: null });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestSafrasType) {
    return {
      ...state,
      isLoadingSafras: true,
    };
  }
  if (action.type === receiveSafrasType) {
    return {
      ...state,
      safras: action.safras,
      isLoadingSafras: false,
    };
  }
  if (action.type === requestSafraType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveSafraType) {
    return {
      ...state,
      safra: action.safra,
      isLoading: false,
    };
  }
  return state;
};
