/* eslint-disable eqeqeq */
const requestStocks = "REQUEST_STOCKS";
const receiveStocks = "RECEIVE_STOCKS";

const requestStockPeriods = "REQUEST_STOCK_PERIODS";
const receiveStockPeriods = "RECEIVE_STOCK_PERIODS";

const requestSaveStocks = "REQUEST_SAVE_STOCKS";
const receiveSaveStocks = "RECEIVE_SAVE_STOCKS";

const requestCreateStock = "REQUEST_CREATE_STOCK";
const receiveCreateStock = "RECEIVE_CREATE_STOCK";

const requestValidStocks = "REQUEST_VALIDATE_STOCKS";
const receiveValidStocks = "RECEIVE_VALIDATE_STOCKS";

const receiveValidAllStocks = "RECEIVE_VALIDATE_ALL_STOCKS";

const clickStockProperties = "CLICK_STOCK";
const changeStockProperties = "CHANGE_STOCK";

const requestChannels = "REQUEST_CHANNELS";
const receiveChannels = "RECEIVE_CHANNELS";

const requestCreateChannel = "REQUEST_CREATE_CHANNEL";
const receiveCreateChannel = "RECEIVE_CREATE_CHANNEL";

const requestProducts = "REQUEST_PRODUCTS";
const receiveProducts = "RECEIVE_PRODUCTS";

const requestTerritoryIbge = "REQUEST_TERRITORY_IBGE";
const receiveTerritoryIbge = "RECEIVE_TERRITORY_IBGE";

const requestSourceCNPJChannels = "REQUEST_SOURCE_CHANNELS";
const receiveSourceCNPJChannels = "RECEIVE_SOURCE_CHANNELS";

const requestPeriods = "REQUEST_PERIODS";
const receivePeriods = "RECEIVE_PERIODS";

const requestChangePeriods = "REQUEST_CHANGE_PERIODS";
const receiveChangePeriods = "RECEIVE_CHANGE_PERIODS";

const requestValidAllStocks = "REQUEST_VALID_ALL_STOCKS";

const initialState = {
  stocks: [],
  isLoading: false,

  channels: [],
  products: [],
  sourcesCNPJ: [],

  periods: [],
  territories: {},

  messages: "",
};

export const StocksActions = {
  requestStocks: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestStocks });

    const url = `api/Estoque/getAllEstoques`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const stocks = await response.json();
    dispatch({ type: receiveStocks, stocks });
  },
  requestProducts: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestProducts });

    const url = `api/Estoque/getAllProdutos`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const products = await response.json();
    dispatch({ type: receiveProducts, products });
  },
  requestChannels: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestChannels });

    const url = `api/Estoque/getAllCanais`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const channels = await response.json();
    dispatch({ type: receiveChannels, channels });
  },

  requestStockPeriods: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestStockPeriods });

    const url = `api/Estoque/getEstoquePeriodo`;

    const options = {
      method: "GET",

      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };
    const response = await fetch(url, options);
    const periods = await response.json();

    dispatch({ type: receiveStockPeriods, periods });
  },

  //Busca de dados
  requestCanaisCNPJ: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSourceCNPJChannels });

    const url = `api/Estoque/canaisCNPJ`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const sources = await response.json();
    dispatch({ type: receiveSourceCNPJChannels, sources });
  },
  setStockPeriod: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestChangePeriods });

    const url = `api/Estoque/setPeriod/${id}`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        dispatch({ type: receiveChangePeriods });

        dispatch(StocksActions.requestStockPeriods());
        dispatch(StocksActions.requestStocks());
      } else {
        dispatch({ type: receiveChangePeriods });
      }
    } catch (error) {
      dispatch({ type: receiveChangePeriods });
    }
  },

  //Ações do usuário
  requestAllData: () => async (dispatch, getState) => {
    dispatch(StocksActions.requestStockPeriods());
    dispatch(StocksActions.requestStocks());
    dispatch(StocksActions.requestProducts());
    dispatch(StocksActions.requestChannels());

  },
  handleClickNumeric: (e, id) => async (dispatch, getState) => {
    const { name, value } = e.target;
    dispatch({ type: clickStockProperties, id, name, value: Number(value) });
  },
  handleChangeNumeric: (value, name, id) => async (dispatch, getState) => {
    if (Number(value) < 0 && name == "estoqueFisico")
      return;

    dispatch({ type: changeStockProperties, id, name, value: Number(value) });
  },
  handleBlurNumeric: (e, id) => async (dispatch, getState) => {
    const { oidc, stocks } = getState();
    const { name } = e.target;

    const rowIndex = stocks.stocks.findIndex(x => x.id === id);
    const row = stocks.stocks[rowIndex];

    dispatch({ type: requestSaveStocks });

    if (row[name] > 0 && row["old" + name] === row[name])
      return;

    const url = `api/Estoque/saveEstoque/${id}`;

    const options = {
      method: "POST",
      body: JSON.stringify(row),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    dispatch({
      type: receiveSaveStocks,
      date: new Date().toLocaleString("pt-BR"),
      sucess: response.status === 200,
    });
  },
  handleConfirmStock: (id, status) => async (dispatch, getState) => {
    const { oidc, stocks, user } = getState();

    let newStock = stocks.stocks;
    const index = newStock.findIndex((stock) => stock.id === id);

    const usuario = user.user;
    const rowUser = newStock[index].usuario;
    if (newStock[index]["estoqueValido"] > 0 && newStock[index]["idUsuario"] !== usuario.userId) {
      if (usuario.role.includes("DSM")) {
        if (rowUser != null && rowUser.role.includes("DSM")) {
          dispatch({ type: "CALL_ALERT", title: "Aviso", message: "Você não foi o responsável por esta validação." });
          return;
        }
      }
      else if (usuario.role.includes("Sales Representative")) {
        if (rowUser != null && rowUser.role.includes("Sales Representative")) {
          dispatch({ type: "CALL_ALERT", title: "Aviso", message: "Você não foi o responsável por esta validação." });
          return;
        }
      }
    }

    dispatch({ type: requestValidStocks });

    const url = `api/Estoque/toggleValidationStock/${id}/${status}`;

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    dispatch({
      type: receiveValidStocks,
      id,
      status,
      date: new Date().toLocaleString("pt-BR"),
      sucess: response.status === 200,
    });
  },
  handleConfirmAllStock: (status, ids) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestValidAllStocks });

    const validacao = {
      status,
      ids
    }

    const url = `api/Estoque/toggleValidationAllStockInList`;

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(validacao)
    };

    const response = await fetch(url, options);
    dispatch({
      type: receiveValidAllStocks,
      date: new Date().toLocaleString("pt-BR"),
      sucess: response.status === 200,
    });
    dispatch(StocksActions.requestStocks());
  },

  handleCreateChannel: (form, setHttpError) => async (dispatch, getState) => {
    const { oidc } = getState();

    form.adicionadoManualmente = true;

    dispatch({ type: requestCreateChannel });

    const url = `api/Estoque/createCanal`;

    const options = {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);

      if (response.status === 409) {
        setHttpError("O CNPJ inserido já faz parte de um canal existente.");
      } else {
        dispatch({
          type: receiveCreateChannel,
          date: new Date().toLocaleString("pt-BR"),
          success: response.status === 200,
        });
        dispatch(StocksActions.requestChannels());
        dispatch(StocksActions.requestStocks());
      }
    } catch (error) {
      console.error("Erro ao criar o Canal.", error);
    }
  },

  handleCreateStock: (form) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCreateStock });

    const url = `api/Estoque/createEstoqueDado`;

    const options = {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    dispatch({
      type: receiveCreateStock,
      date: new Date().toLocaleString("pt-BR"),
      sucess: response.status === 200,
    });
    dispatch(StocksActions.requestStocks());
  },
  requestTerritoryIbge: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestTerritoryIbge });

    const url = `api/Estoque/territory`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      const territory = await response.json();
      dispatch({ type: receiveTerritoryIbge, territory });
    }
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestValidAllStocks:
      return {
        ...state,
        isLoading: true
      }
    case requestStockPeriods:
      return {
        ...state,
      };
    case receiveStockPeriods:
      return {
        ...state,
        periods: action.periods,
      };
    case requestPeriods:
      return {
        ...state,
      };
    case receivePeriods:
      return {
        ...state,
      };
    case requestStocks:
      return {
        ...state,
        isLoading: true,
      };
    case receiveStocks:
      return {
        ...state,
        stocks: action.stocks,
        isLoading: false,
      };
    case receiveSaveStocks:
      return {
        ...state,
        messages: action.sucess
          ? `Salvo em ${action.date}`
          : `Não foi possivel salvar...`,
      };
    case receiveCreateStock:
      return {
        ...state,
        messages: action.sucess
          ? `Atualizado em ${action.date}`
          : `Não foi possivel atualizar...`,
      };
    case clickStockProperties:
      let newStocks = state.stocks;
      let stockIndex = newStocks.findIndex(x => x.id === action.id);

      newStocks[stockIndex]["old" + action.name] = action.value;
      return {
        ...state,
        stocks: newStocks,
      };
    case changeStockProperties:
      let changedStock = state.stocks;
      let rowIndex = changedStock.findIndex(x => x.id === action.id);
      const sellInCorteva = changedStock[rowIndex]["sellInCorteva"];
      const sellOut = changedStock[rowIndex]["sellOutIntegra"];

      if (action.name === "estoqueDisponivel") {
        changedStock[rowIndex]["apontadoManualmenteDisponivel"] = true;
      }
      else if (action.name === "estoqueFisico") {
        changedStock[rowIndex]["apontadoManualmenteFisico"] = true;
      }

      changedStock[rowIndex][action.name] = action.value;

      changedStock[rowIndex]["saldoEstoque"] =
        changedStock[rowIndex]["estoqueDisponivelAnterior"] +
        sellInCorteva -
        sellOut;

      return {
        ...state,
        stocks: changedStock,
      };
    case receiveValidStocks:
      let newStock = state.stocks;
      const index = newStock.findIndex((stock) => stock.id === action.id);

      newStock[index]["estoqueDisponivel"] = action.status >= 1 ? (newStock[index]["apontadoManualmenteDisponivel"] ? newStock[index]["estoqueDisponivel"] : newStock[index]["estoqueDisponivelIntegra"] > 0 ? newStock[index]["estoqueDisponivelIntegra"] : newStock[index]["sellInIntegra"]) : (newStock[index]["apontadoManualmenteDisponivel"] ? newStock[index]["estoqueDisponivel"] : 0);
      newStock[index]["estoqueFisico"] = action.status >= 1 ? (newStock[index]["apontadoManualmenteFisico"] ? newStock[index]["estoqueFisico"] : newStock[index]["estoqueFisicoIntegra"] > 0 ? newStock[index]["estoqueFisicoIntegra"] : newStock[index]["sellInIntegra"]) : (newStock[index]["apontadoManualmenteFisico"] ? newStock[index]["estoqueFisico"] : 0);

      let estoqueValido = newStock[index]["estoqueValido"];
      let validacaoAnterior = newStock[index]["validacaoAnterior"];

      newStock[index]["validacaoAnterior"] = estoqueValido < 2 ? estoqueValido : 0;
      newStock[index]["estoqueValido"] = action.status <= validacaoAnterior ? validacaoAnterior : action.status;

      return {
        ...state,
        stocks: newStock,
        messages: action.sucess
          ? `Salvo em ${action.date}`
          : `Não foi possivel salvar...`,
      };
    case receiveValidAllStocks:
      return {
        ...state,
        messages: action.sucess
          ? `Salvo em ${action.date}`
          : `Não foi possivel salvar...`,
        isLoading: false
      };
    case receiveChannels:
      return {
        ...state,
        channels: action.channels,
      };
    case receiveCreateChannel:
      return {
        ...state,
        messages: action.sucess
          ? `Atualizado em ${action.date}`
          : `Não foi possivel atualizar...`,
      };
    case receiveProducts:
      return {
        ...state,
        products: action.products,
      };
    case receiveTerritoryIbge:
      return {
        ...state,
        territories: action.territory
      };
    case receiveSourceCNPJChannels:
      return {
        ...state,
        sourcesCNPJ: action.sources
      };
    default:
      return state;
  }
};
