export const enrichConstants = {
  changeValue: "CHANGE_VALUE_ENRICH",
  changeNumberValue: "CHANGE_NUMBER_VALUE_ENRICH",
  changeValues: "CHANGE_VALUES_ENRICH",
  resetValues: "RESET_VALUES_ENRICH",
  endEnrich: "END_ENRICH",
  backEnrich: "BACK_ENRICH",
  requestSaveType: "REQUEST_SAVE_ENRICH",
  receiveSaveType: "RECEIVE_SAVE_ENRICH",
  requestEnrichType: "REQUEST_ENRICH",
  receiveEnrichType: "RECEIVE_ENRICH",
  requestPurchasePoolsType: "REQUEST_PURCHASEPOOLS",
  receivePurchasePoolsType: "RECEIVE_PURCHASEPOOLS",
  requestProductionUnitsType: "REQUEST_PRODUCTIONUNITS",
  receiveProductionUnitsType: "RECEIVE_PRODUCTIONUNITS",
  requestConsultorInfluenciadorType: "REQUEST_CONSULTOR_INFLUENCIADOR",
  receiveConsultorInfluenciadorType: "RECEIVE_CONSULTOR_INFLUENCIADOR",
  requestCreateConsultorInfluenciadorType: "REQUEST_CREATE_CONSULTOR_INFLUENCIADOR",
  receiveCreateConsultorInfluenciadorType: "RECEIVE_CREATE_CONSULTOR_INFLUENCIADOR",
};
