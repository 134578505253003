/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useCallback, useRef } from 'react';
import {
  area as TurfArea,
  polygon as TurfPolygon,
  center as TurfCenter,
  featureCollection,
  union as TurfUnion,
  intersect as TurfIntersect,
  circle as TurfCircle,
  multiPolygon,
} from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import defaultDrawStyle from "@mapbox/mapbox-gl-draw/src/lib/theme.js";

import SplitPolygonMode, {
  drawStyles as splitPolygonDrawStyles,
  Constants as splitPolygonConstants,
} from "mapbox-gl-draw-split-polygon-mode";

import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import Map, { Source, Layer, Marker, NavigationControl } from 'react-map-gl';
import DrawControl, { drawRef, mapRef } from './Control-Panel';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import MapIcon from '@mui/icons-material/Map';
import Slider from '@mui/material/Slider';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import PanToolIcon from '@mui/icons-material/PanTool';
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import DeleteIcon from '@mui/icons-material/Delete';
import LayersIcon from '@mui/icons-material/Layers';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import PublicIcon from '@mui/icons-material/Public';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { ReactComponent as ChangeLayer } from "../../../../assets/svg/ChangeLayer.svg";
import { ReactComponent as BreakIcon } from "../../../../assets/svg/Break.svg";
import { ReactComponent as UnionIcon } from "../../../../assets/svg/Union.svg";

import {
  dataLayer, selectedLayer, assignedLayer,
  accountLayer, allAssignedLayer, regionLayer, lineLayer, municiLayer,
  accountLineLayer, assignedLineLayer, allAccountLineLayer
} from './Map-Style';
import './Control-Panel.css';
import '../mapbox-gl.css';
import FarmPanel from './FarmPanel.jsx';
import PlotPanel from './PlotPanel.jsx';
import CultureAttr from '../CultureAttr.jsx';
import CoordinatesPagination from '../CoordinatesPagination.jsx';
import CountdownTime from '../MapView/CountdownTime.jsx';
import { isNumber } from '../../../../helpers/utils.js';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ2FicmllbGRhc3NpZSIsImEiOiJjaW93dnp4N2IwMW1pdWNtOHZsOTdrZGtwIn0.q9DFHVBMw2qOZy7Cv4nayA'; // Set your mapbox token here

function distanceC(lat1, lat2, lon1, lon2) {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 = lon1 * Math.PI / 180;
  lon2 = lon2 * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a = Math.pow(Math.sin(dlat / 2), 2)
    + Math.cos(lat1) * Math.cos(lat2)
    * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  // calculate the result
  return (c * r);
}
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}
function isNullOrEmpty(str) {
  return str === null || str === undefined || str.length <= 0;
}

export default function App(props) {
  const {
    accountId, counties,
    data, plotData,
    assignedData, accountData,
    farmInfo, plotInfo, farmSelected,
    polygonRegion, allPlotData,
    disablePolygon, disableListPolygon, disablePlotPolygon,
    disableListPlotPolygon, selectPolygon, updatePolygon,
    isLoading, progressFarm, cleanList,
    changeLoadState, coordinates, setMapbox, setDraw,
    plotSelected, showPlots,
    allAssignedData, showAllData,
    selectPlotPolygon, updatePlotPolygon,
    createMultiplesPolygons,
    selectData, setSelectData, goTo, goToPolygon,
    updateListPlotPolygon, copySourceCodes,
    groupId, territoryId, accountsList,
    marker, markerOn, onClickMarker, addData,
    cultures, culturesGoal, plotLayer,
    plotLineLayer, irrigations,
    selectedPolygon, isLoadingPlots,
    hasPlots, progressPlot, setShowPlots, buttonAccountPlots
  } = props;

  const drawR = drawRef;
  const mapR = mapRef;

  const featuresInfo = useRef([]);
  const lassoTool = useRef(false);

  const [errorEdit, setErrorEdit] = useState(false);
  const [confirmation, setConfirmationOption] = useState(false);
  const [messageType, setMessageTitle] = useState("");
  const [message, setMessage] = useState("");
  const [messageAction, setMessageAction] = useState(null);

  const [hoverInfo, setHoverInfo] = useState(null);
  const [cutPolygon, setCutPolygon] = useState(null);

  const [munici, setMunici] = useState(null);
  const [zoomMap, setZoomMap] = useState(3);
  const [sizeSelection, setSizeSelection] = useState(1);
  const [apparence, setApparence] = useState("mapbox://styles/mapbox/satellite-streets-v12");

  const [drawTools, setDrawTools] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [edit, setEdit] = useState(false);
  const [shift, setShift] = useState(false);
  const [combine, setCombine] = useState(false);
  const [uncombine, setUncombine] = useState(false);
  const [rope, setRope] = useState(false);
  const [oldCoord, setOldCoord] = useState([0, 0]);

  const [polyHover, setPolyHover] = useState(null);
  const [styled, setStyled] = useState(false);
  const [openPlotOptions, setPlotOptions] = useState(false);
  const [farmOpen, setFarmOpen] = useState(false);
  const [plotOpen, setPlotOpen] = useState(false);
  const [resumeOpen, setResumeOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);

  //Buttons

  const setMessageBox = (title, message, value, haveOption) => {
    setMessageTitle(title);
    setMessage(message);
    setMessageAction(value);
    setConfirmationOption(haveOption);
    setErrorEdit(true);
  }

  const handleChange = (value) => {
    if (!errorEdit)
      return;

    changePropertiesPlot(plotInfo, value);

    clearList("plot");
  };
  const handleFarmClick = (Transition) => () => {
    setTransition(() => Transition);

    setPlotOpen(false);
    setResumeOpen(false);
    setFarmOpen(!farmOpen);
  };
  const handlePlotClick = (Transition) => () => {
    setTransition(() => Transition);

    setFarmOpen(false);
    setResumeOpen(false);
    setPlotOpen(!plotOpen);
  };
  const handleResumeClick = (Transition) => () => {
    setTransition(() => Transition);

    setPlotOpen(false);
    setFarmOpen(false);
    setResumeOpen(!resumeOpen);
  };
  const handleResumeClose = () => {
    setPlotOpen(false);
    setFarmOpen(false);
    setResumeOpen(!resumeOpen);
  };

  const changeMapStyle = (value) => {
    setStyled(!styled);
    setApparence('mapbox://styles/mapbox/' + value);
    mapR.setStyle(apparence);
  }
  const changeSizeSelection = (event) => {
    setSizeSelection(event.target.value);
  }

  const savePolygon = (counties, data, plotData, draw, showplot) => {
    var draws = draw?.getAll();

    var polygons = [];
    var remainPolygons = 0;

    draws.features.forEach(polygon => {
      var intersect = 0;
      if (showplot) {
        plotData.features.forEach(x => {
          var i = TurfIntersect(polygon, x);

          if (i !== null) {
            intersect += 1;
          }
        });
      }
      else {
        data.features.forEach(x => {
          var i = TurfIntersect(polygon, x);

          if (i !== null) {
            intersect += 1;
          }
        });
      }

      if (intersect <= 0) {
        const latlng = TurfCenter(TurfPolygon(polygon.geometry.coordinates));
        var area = TurfArea(TurfPolygon(polygon.geometry.coordinates));
        var rounded_area = Math.round(area / 10000);

        polygon.properties["Area"] = rounded_area;
        polygon.properties["NUM_AREA"] = rounded_area;
        polygon.properties["LAT"] = latlng.geometry.coordinates[1];
        polygon.properties["LNG"] = latlng.geometry.coordinates[0];

        if (MapboxDrawGeodesic.isCircle(polygon)) {
          const center = MapboxDrawGeodesic.getCircleCenter(polygon);
          const radius = MapboxDrawGeodesic.getCircleRadius(polygon);

          const options = { steps: 15, units: 'kilometers', properties: polygon.properties };
          var circle = TurfCircle(center, radius, options);

          area = TurfArea(TurfPolygon(circle.geometry.coordinates));
          rounded_area = Math.round(area / 10000);

          circle.id = polygon.id;
          circle.properties["Area"] = rounded_area;
          circle.properties["LAT"] = center[1];
          circle.properties["LNG"] = center[0];
          circle.properties["NUM_AREA"] = rounded_area;
          delete circle.properties["circleRadius"];

          polygon = circle;
        }

        var munici = [];
        counties.features.forEach(x => {
          var i = TurfIntersect(polygon, x);

          if (i !== null) {
            munici = { uf: x.properties.SIGLA, municipio: x.properties.Nome_Munic, codIbge: x.properties.GEOCODIG_M };
            return;
          }
        });

        if (showplot && (munici == null || munici.length <= 0)) {
          data.features.forEach(x => {
            var i = TurfIntersect(polygon, x);

            if (i !== null) {
              const codIbgeP = x.properties.COD_IMOVEL.split("-")[1];
              munici = { uf: x.properties.COD_ESTADO, municipio: x.properties.NOM_MUNICI, codIbge: codIbgeP };
              return;
            }
          });
        }

        if (munici.length <= 0) {
          setMessageTitle("Aviso - Polígono fora do munícipio");
          setMessage("O Polígono está fora dos munícipios carregados, carregue o munícipio próximo a este para conseguir realizar a criação.");
          setErrorEdit(true);
          return;
        }

        var source = "";
        var poly = polygon;
        poly.properties["Uf"] = munici.uf;
        poly.properties["CodIbge"] = munici.codIbge;
        poly.properties["NOM_MUNICI"] = munici.municipio;

        if (showplot) {
          poly.properties["GEOCODIG_M"] = munici.codIbge;
          poly.properties["Sigla"] = munici.uf;

          source = munici.uf + "-" + munici.codIbge + "-" + uuidv4();
          poly.properties["COD_TALHAO"] = source;
        }
        else {
          poly.properties["COD_ESTADO"] = munici.uf;

          source = poly.properties.COD_ESTADO + "-" + munici.codIbge + "-" + poly.id;
          poly.properties["COD_IMOVEL"] = source;
        }

        if (poly.properties.Uf === "" || poly.properties.Uf === null || poly.properties.CodIbge === "" || poly.properties.CodIbge === null)
          return;

        poly = TurfPolygon(poly.geometry.coordinates, poly.properties);

        polygons.push(poly);
        draw?.delete(polygon.id);
      }
      else {
        setMessageTitle("Aviso - Poligono");
        setMessage("O Polígono está sobrepondo ou possui uma intersecção com outro poligono, logo, não é possível realizar a criação.");
        setErrorEdit(true);

        remainPolygons += 1;
      }
    })

    createMultiplesPolygons(polygons);

    if (remainPolygons <= 0) {
      draw?.deleteAll();
      featuresInfo.current = [];
      setHoverInfo(null);
    }
  }

  function goRopeMode() {
    setRope(!rope);
  }
  function selectPolygonMode(selectMode, draw) {
    setEdit(false);
    if (draw?.getMode() == "simple_select") {
      setSelectMode(!selectMode);
    }
    else {
      try {
        draw?.changeMode("simple_select");
      } catch (err) {
        console.error(err);
      }
    }
  }

  function combineFeatures(draw) {
    draw.combineFeatures();
  }
  function uncombineFeatures(draw) {
    draw.uncombineFeatures();
  }
  function goSplitMode(selectedFeatureIDs, draw) {
    try {
      draw?.changeMode("split_polygon", {
        featureIds: selectedFeatureIDs,
        /** Default option vlaues: */
        highlightColor: "#05fffb",
        // lineWidth: 0,
        // lineWidthUnit: "kilometers",
      });
    } catch (err) {
      console.error(err);
    }
  }
  function splitPolygon(draw) {
    const selectedFeatureIDs = draw.getSelectedIds();

    if (selectedFeatureIDs.length > 0) {
      goSplitMode(selectedFeatureIDs, draw);
    }
  }
  function drawCircle(draw) {
    try {
      setEdit(true);
      draw?.changeMode("draw_circle");
    } catch (err) {
      console.error(err);
    }
  }
  function drawPolygon(draw) {
    try {
      setEdit(true);
      draw?.changeMode("draw_polygon");
    } catch (err) {
      console.error(err);
    }
  }

  //Methods

  const onCreate = useCallback(e => {
    if (lassoTool.current == false) {
      var feature = e.features[0];
      var area = TurfArea(TurfPolygon(feature.geometry.coordinates));
      var rounded_area = Math.round(area / 10000);

      feature.properties["Area"] = rounded_area;
      feature.properties["NUM_AREA"] = rounded_area;

      if (MapboxDrawGeodesic.isCircle(feature)) {
        const center = MapboxDrawGeodesic.getCircleCenter(feature);
        const radius = MapboxDrawGeodesic.getCircleRadius(feature);

        var options = { steps: 15, units: 'kilometers', properties: feature.properties };
        var circle = TurfCircle(center, radius, options);

        area = TurfArea(TurfPolygon(circle.geometry.coordinates));
        rounded_area = Math.round(area / 10000);

        circle.id = feature.id;
        circle.properties["Area"] = rounded_area;
        circle.properties["NUM_AREA"] = rounded_area;
        delete circle.properties["circleRadius"];

        feature = circle;
      }

      var info = featuresInfo.current;
      info.push(feature);

      featuresInfo.current = info;
      setHoverInfo(feature);
      setEdit(false);
    }
  }, []);

  const onUpdate = useCallback(e => {
    if (e.action === "split_polygon") {
      const allFeatures = drawR.getAll().features;

      allFeatures.forEach(({ id }) =>
        drawR.setFeatureProperty(
          id,
          splitPolygonConstants.highlightPropertyName,
          undefined
        )
      );
    }
  }, []);

  const onDelete = useCallback(e => {
    var deleteF = e.features;
    var info = featuresInfo.current;

    deleteF.forEach(y => {
      info.forEach((x, index) => {
        if (x.id == y.id) {
          info.splice(index, 1);
        }
      });
    });

    if (info.length > 0) {
      featuresInfo.current = info;
      setHoverInfo(info[0]);
    }
    else {
      featuresInfo.current = [];
      setHoverInfo(null);
    }
  }, []);

  const onCombine = useCallback(e => {
    var feature = e.createdFeatures[0];
    var polygon;
    try {
      var union;
      var lastItem;
      feature.geometry.coordinates.forEach(x => {
        var poly;
        if (lastItem != null) {
          poly = TurfPolygon(x);
          var item = TurfUnion(poly, lastItem);
          lastItem = item;
          union = item;
        }
        else {
          poly = TurfPolygon(x);
          lastItem = poly;
        }
      });
      polygon = TurfPolygon(union.geometry.coordinates);
    }
    catch (e) {
      setCombine(false);

      setMessageTitle("Não foi possivel Combinar");
      setMessage("Para realizar um combinação, verifique se os polígonos possuem uma sobreposição.");
      setErrorEdit(true);
      return;
    }

    if (polygon != null) {
      setCombine(true);

      setMessageTitle("Combinação Ativa");
      setMessage("Para finalizar a operação de combinação, salve os polígonos.");
      setErrorEdit(true);
    }
  }, []);

  const onUncombine = useCallback(e => {
    if (e.createdFeatures.length > 1) {
      setUncombine(true);
      setMessageTitle("Separação Ativa");
      setMessage("Para finalizar a operação de separação, salve os polígonos.");
      setErrorEdit(true);
    }
  }, []);

  const onHover = useCallback((e) => {
    const {
      features,
      point: { x, y }
    } = e;

    if (!isNullOrEmpty(features)) {
      if (!isNullOrEmpty(features[0].properties.ACCOUNT)) {
        const hoveredFeature = features && features[0];
        setPolyHover(hoveredFeature && { feature: hoveredFeature, x, y });
      }
      else {
        setPolyHover(null);
      }
    }
    else {
      setPolyHover(null);
    }
  }, []);

  const onClick = useCallback((event, data, plotData, farmSelected, plotSelected, selectData, edit, showPlots, map, size, selectMode) => {
    if (!edit && selectMode) {
      const bbox = [
        [event.point.x - size, event.point.y - size],
        [event.point.x + size, event.point.y + size]
      ];
      // Find features intersecting the bounding box.
      const selectedFeatures = map.queryRenderedFeatures(bbox, {
        layers: ['data', 'plot']
      });

      if (selectedFeatures?.length > 0) {
        var modifiedData = [];
        var obj;
        if (selectData != null) {
          for (var i = 0; i < selectData.features.length; i++) {
            obj = selectData.features[i];
            if (obj != null) {
              modifiedData.push(obj);
            }
          }
        }

        selectedFeatures.forEach(x => {
          const hoveredFeature = x;
          if (hoveredFeature != null) {
            if (!showPlots) {
              for (var j = 0; j < data.features.length; j++) {
                obj = data.features[j];

                if (obj.properties.COD_IMOVEL === hoveredFeature.properties.COD_IMOVEL) {
                  if ((farmSelected.includes(obj.properties.COD_IMOVEL) || event.originalEvent.ctrlKey == true) && event.originalEvent.shiftKey == false) {
                    var index = farmSelected.indexOf(obj.properties.COD_IMOVEL);
                    if (index > -1) {
                      modifiedData.splice(index, 1);
                    }
                  }
                  else if (!farmSelected.includes(obj.properties.COD_IMOVEL)) {
                    if (farmSelected.length >= 100) {
                      setMessageTitle("Aviso - Muitos Polígonos selecionados");
                      setMessage("O sistema pode apresentar travamentos ou mal funcionamento ao manipular tantos polígonos");
                      setErrorEdit(true);
                      return;
                    }

                    modifiedData.push(obj);
                  }

                  selectPolygon(hoveredFeature.properties.COD_IMOVEL, obj, event.originalEvent.ctrlKey, event.originalEvent.shiftKey);
                  break;
                }
              }
            }
            else {
              for (var f = 0; f < plotData.features.length; f++) {
                obj = plotData.features[f];
                var source = obj.properties.COD_TALHAO;
                var hoveredSource = hoveredFeature.properties.COD_TALHAO;

                if (source === hoveredSource) {
                  if ((plotSelected.includes(source) || event.originalEvent.ctrlKey == true) && event.originalEvent.shiftKey == false) {
                    var index = plotSelected.indexOf(source);
                    if (index > -1) {
                      modifiedData.splice(index, 1);
                    }
                  }
                  else if (!plotSelected.includes(source)) {
                    if (plotSelected.length >= 100) {
                      setMessageTitle("Aviso - Muitos Polígonos selecionados");
                      setMessage("O sistema pode apresentar travamentos ou mal funcionamento ao manipular tantos polígonos");
                      setErrorEdit(true);
                      return;
                    }

                    modifiedData.push(obj);
                  }

                  selectPlotPolygon(hoveredSource, obj, event.originalEvent.ctrlKey, event.originalEvent.shiftKey);
                  break;
                }
              }
            }
          };
        })

        modifiedData = featureCollection(modifiedData);
        setSelectData(modifiedData);
      }
    }
  }, []);

  const onMapLoad = (map, draw) => {
    if (map != null) {
      setMapbox(map);
    }

    if (draw != null) {
      setDraw(draw);
    }
  }

  const onDataLoad = (coordinates, oldCoord, zoomMap, changeLoadState, goTo) => {
    if (oldCoord === coordinates)
      return;

    setOldCoord(coordinates);

    setMunici(null);
    featuresInfo.current = [];
    setHoverInfo(null);
    setEdit(false);
    changeLoadState(false);

    goTo('data');
  }

  const setEditMultiArea = (draw, selectData) => {
    setCutPolygon(selectData);

    setEdit(true);
    selectData.features.forEach(x => {
      var feature = {
        type: x.type,
        properties: x.properties,
        geometry: x.geometry
      };
      draw?.add(feature);
    })
  }
  const setEditArea = (feature, draw) => {
    setCutPolygon(feature);

    setEdit(true);
    draw?.add(feature);
  }
  const cancelEditArea = (draw) => {
    draw?.deleteAll();
    featuresInfo.current = [];
    setHoverInfo(null);
    setEdit(false);
  }

  const saveFarmArea = (draw, map) => {
    var draws = draw?.getAll();
    var oldPolygon = cutPolygon;
    var modifiedData = selectData.features;

    var intersect = false;
    var cancel = false;

    if (draws.features.length > 0) {
      if (draws.features.length === 1) {
        data.features.forEach(x => {
          if (x.properties.COD_TALHAO != draws.features[0].properties.COD_TALHAO &&
            draws.features[0].geometry.coordinates.length == 1) {
            if (!isNullOrEmpty(x.properties.ACCOUNT_ID) && draws.features[0].properties.ACCOUNT_ID != x.properties.ACCOUNT_ID) {
              var intersectPoly = TurfIntersect(TurfPolygon(draws.features[0].geometry.coordinates), TurfPolygon(x.geometry.coordinates));

              if (intersectPoly !== null) {
                intersect = true;
              }
            }
          }
        });

        if (intersect) {
          setMessageTitle("Aviso - Poligono");
          setMessage("O Polígono está sobrepondo ou possui uma intersecção com outro poligono, logo, não é possível realizar a criação.");
          setErrorEdit(true);
          return;
        }

        const area = TurfArea(draws);
        if (area > 0) {
          var latlng;
          if (draws.features[0].geometry.type == "MultiPolygon") {
            latlng = TurfCenter(multiPolygon(draws.features[0].geometry.coordinates));
          }
          else {
            latlng = TurfCenter(TurfPolygon(draws.features[0].geometry.coordinates));
          }
          var rounded_area = Math.round(area / 10000);
          draws.features[0].properties.NUM_AREA = rounded_area;
          draws.features[0].properties["LAT"] = latlng.geometry.coordinates[1];
          draws.features[0].properties["LNG"] = latlng.geometry.coordinates[0];

          if (draws.features[0].geometry.coordinates.length > 1) {
            var oldFeature = null;
            oldPolygon.features.forEach(z => {
              if (oldFeature == null) {
                oldFeature = z;
              }
              else if (oldFeature.properties.ACCOUNT_ID != z.properties.ACCOUNT_ID &&
                oldFeature.properties.ACCOUNT_ID != "" && oldFeature.properties.ACCOUNT_ID != null &&
                z.properties.ACCOUNT_ID != "" && z.properties.ACCOUNT_ID != null) {
                setMessageTitle("Aviso");
                setMessage("Os polígonos possuem donos distintos, logo, não podem ser unificados.");
                setErrorEdit(true);
                cancel = true;
              }
            })

            if (draws.features[0].geometry.type == "MultiPolygon" && combine == false && uncombine == false) {
              setMessageTitle("Aviso - Tentativa de Corte");
              setMessage("O Polígono não foi cortado, após utilizar a linha de corte selecione o polígono e use a ferramenta de descombinar para que ocorra a divisão.");
              setErrorEdit(true);
              cancel = true;
            }
          }

          if (cancel)
            return;

          try {
            var polygon;
            var sources = [];

            if (draws.features[0].geometry.coordinates.length > 1) {
              try {
                var union;
                var lastItem;
                draws.features[0].geometry.coordinates.forEach(x => {
                  var poly;
                  if (lastItem != null) {
                    poly = TurfPolygon(x);
                    var item = TurfUnion(poly, lastItem);
                    lastItem = item;
                    union = item;
                  }
                  else {
                    poly = TurfPolygon(x);
                    lastItem = poly;
                  }
                });
                polygon = TurfPolygon(union.geometry.coordinates, draws.features[0].properties);
              }
              catch (e) {
                try {
                  polygon = TurfPolygon(draws.features[0].geometry.coordinates, draws.features[0].properties);
                }
                catch (e) {
                  polygon = multiPolygon(draws.features[0].geometry.coordinates, draws.features[0].properties);
                }
              }
            }
            else {
              polygon = TurfPolygon(draws.features[0].geometry.coordinates, draws.features[0].properties);
            }

            modifiedData.forEach(x => {
              oldPolygon.features.forEach(z => {
                if (x.properties.COD_IMOVEL === z.properties.COD_IMOVEL) {
                  if (polygon.properties.COD_IMOVEL !== x.properties.COD_IMOVEL) {
                    sources.push(x.properties.COD_IMOVEL);
                  }
                }
              });
            });

            if (sources.length > 0 && !sources.includes(polygon.properties.COD_IMOVEL))
              disableListPolygon(sources);

            updatePolygon(polygon.properties.COD_IMOVEL, polygon);
          }
          catch (e) {
            console.log(e);
            setMessageTitle("Aviso - Não foi possivel salvar");
            setMessage("O Polígono possui erros, impossibilitando que as alterações sejam aplicadas");
            setErrorEdit(true);
          }
        }
        else {
          var source = draws.features[0].properties.COD_IMOVEL;
          disablePolygon(source);
        }
      }
      else {
        var oldFeatures = oldPolygon.features[0];
        var features = [];
        var listPolygon = [];
        var sources = [];

        if (combine == false && uncombine == false) {
          setMessageTitle("Aviso");
          setMessage("Caso tenha ocorrido uma tentativa de combinação, utilize a ferramenta combinar e atente-se ao fato de que os polígonos a serem combinados devem possuir uma intersecção entre sí.");
          setErrorEdit(true);
        }

        try {
          draws.features.forEach(x => {
            data.features.forEach(z => {
              if (z.properties.COD_IMOVEL != oldFeatures.properties.COD_IMOVEL) {
                var i = TurfIntersect(TurfPolygon(x.geometry.coordinates), z);

                if (i !== null) {
                  intersect = true;
                }
              }
            });

            const area = TurfArea(x);
            const latlng = TurfCenter(TurfPolygon(x.geometry.coordinates));
            const rounded_area = Math.round(area / 10000);
            x.properties["LAT"] = latlng.geometry.coordinates[1];
            x.properties["LNG"] = latlng.geometry.coordinates[0];

            x.properties["Uf"] = oldFeatures.properties.COD_ESTADO;
            const codIbge = oldFeatures.properties.COD_IMOVEL.split("-")[1];

            x.properties["CodIbge"] = codIbge;
            x.properties["NOM_MUNICI"] = oldFeatures.properties.NOM_MUNICI;
            x.properties["COD_ESTADO"] = oldFeatures.properties.COD_ESTADO;

            const source = x.properties.COD_ESTADO + "-" + codIbge + "-" + x.id;
            x.properties["COD_IMOVEL"] = source;
            x.properties["NUM_AREA"] = rounded_area;

            const polygon = TurfPolygon(x.geometry.coordinates, x.properties);

            listPolygon.push({
              Source: polygon.properties.COD_IMOVEL,
              Body: JSON.stringify(featureCollection([polygon])),
            });

            features.push(polygon);
          });

          if (intersect) {
            setMessageTitle("Aviso - Poligono");
            setMessage("O Polígono está sobrepondo ou possui uma intersecção com outro poligono, logo, não é possível realizar a criação.");
            setErrorEdit(true);
            return;
          }

          modifiedData.forEach((x, mIndex) => {
            oldPolygon.features.forEach(z => {
              if (x.properties.COD_IMOVEL === z.properties.COD_IMOVEL) {
                sources.push(z.properties.COD_IMOVEL);
              }
            });
          });

          disableListPolygon(sources);
          createMultiplesPolygons(features);
        }
        catch (e) {
          setMessageTitle("Erro");
          setMessage("" + e);
          setErrorEdit(true);
        }
      }
    }

    clearList("farm");
  }
  const removeFarmPolygon = (feature, draw, map) => {
    removeFromList(feature, true, draw, map);

    disablePolygon(feature.properties.COD_IMOVEL);
  }
  const removeAllFarmPolygon = (features, draw, map) => {
    const list = features.map(x => x.properties.COD_IMOVEL);
    disableListPolygon(list);

    clearList("farm");
  }

  const changePropertiesPlot = (data, content) => {
    const sendData = data.map(x => {
      x.properties["CULT_APONT"] = content.culture;
      x.properties["CULT_PLANT_DATE"] = content.plantDate;
      x.properties["CULT_HARVEST_DATE"] = content.harvestDate;
      x.properties["CULT_DELTA_DAYS"] = content.deltaDay;

      x.properties["TIPO_IRRIGACAO"] = content.irrigationType;
      x.properties["FINALIDADE"] = content.goal;

      x.properties["SEMENTE"] = content.irrigated;
      x.properties["IRRIGADO"] = content.seed;

      const internalSource = x.properties.COD_TALHAO;
      return { Id: 0, Source: internalSource, Body: featureCollection([x]) };
    });

    updateListPlotPolygon(sendData);
  }
  const savePlotArea = (draw, map) => {
    var draws = draw?.getAll();
    var modifiedData = selectData.features;
    var oldPolygon = cutPolygon;

    var cancel = false;
    var intersect = false;

    if (draws.features.length > 0) {
      var sources = [];
      if (draws.features.length === 1) {
        plotData.features.forEach(x => {
          if (x.properties.COD_TALHAO != draws.features[0].properties.COD_TALHAO && draws.features[0].geometry.coordinates.length == 1) {
            if (!isNullOrEmpty(x.properties.ACCOUNT_ID) && draws.features[0].properties.ACCOUNT_ID != x.properties.ACCOUNT_ID) {
              var intersectPoly = TurfIntersect(TurfPolygon(draws.features[0].geometry.coordinates), TurfPolygon(x.geometry.coordinates));

              if (intersectPoly !== null) {
                intersect = true;
              }
            }
          }
        });

        if (intersect) {
          setMessageTitle("Aviso - Poligono");
          setMessage("O Polígono está sobrepondo ou possui uma intersecção com outro poligono, logo, não é possível realizar a criação.");
          setErrorEdit(true);
          return;
        }

        const area = TurfArea(draws);
        if (area > 0) {
          var latlng;
          if (draws.features[0].geometry.type == "MultiPolygon") {
            latlng = TurfCenter(multiPolygon(draws.features[0].geometry.coordinates));
          }
          else {
            latlng = TurfCenter(TurfPolygon(draws.features[0].geometry.coordinates));
          }
          var rounded_area = Math.round(area / 10000);
          draws.features[0].properties.NUM_AREA = rounded_area;
          draws.features[0].properties["LAT"] = latlng.geometry.coordinates[1];
          draws.features[0].properties["LNG"] = latlng.geometry.coordinates[0];

          if (draws.features[0].geometry.coordinates.length > 1) {
            var oldFeature = null;
            oldPolygon.features.forEach(z => {
              if (oldFeature == null) {
                oldFeature = z;
              }
              else if (oldFeature.properties.CULT_APONT != z.properties.CULT_APONT &&
                oldFeature.properties.CULT_APONT != "" && oldFeature.properties.CULT_APONT != null &&
                z.properties.CULT_APONT != "" && z.properties.CULT_APONT != null) {
                setMessageTitle("Aviso");
                setMessage("Os polígonos possuem culturas distintas, logo, não podem ser unificados.");
                setErrorEdit(true);
                cancel = true;
              }
              else if (oldFeature.properties.ACCOUNT_ID !== z.properties.ACCOUNT_ID &&
                oldFeature.properties.ACCOUNT_ID != "" && oldFeature.properties.ACCOUNT_ID != null &&
                z.properties.ACCOUNT_ID != "" && z.properties.ACCOUNT_ID != null) {
                setMessageTitle("Aviso");
                setMessage("Os polígonos possuem donos distintos, logo, não podem ser unificados.");
                setErrorEdit(true);
                cancel = true;
              }
            })

            if (draws.features[0].geometry.type == "MultiPolygon" && combine == false && uncombine == false) {
              setMessageTitle("Aviso - Tentativa de Corte");
              setMessage("O Polígono não foi cortado, após utilizar a linha de corte selecione o polígono e use a ferramenta de descombinar para que ocorra a divisão.");
              setErrorEdit(true);
              cancel = true;
            }
          }

          if (cancel)
            return;

          try {
            var polygon;
            if (draws.features[0].geometry.coordinates.length > 1) {
              try {
                var union;
                var lastItem;
                draws.features[0].geometry.coordinates.forEach(x => {
                  var poly;
                  if (lastItem != null) {
                    poly = TurfPolygon(x);
                    var item = TurfUnion(poly, lastItem);
                    lastItem = item;
                    union = item;
                  }
                  else {
                    poly = TurfPolygon(x);
                    lastItem = poly;
                  }
                });
                polygon = TurfPolygon(union.geometry.coordinates, draws.features[0].properties);
              }
              catch (e) {
                try {
                  polygon = TurfPolygon(draws.features[0].geometry.coordinates, draws.features[0].properties);
                }
                catch (e) {
                  polygon = multiPolygon(draws.features[0].geometry.coordinates, draws.features[0].properties);
                }
              }
            }
            else {
              polygon = TurfPolygon(draws.features[0].geometry.coordinates, draws.features[0].properties);
            }

            const source = polygon.properties.COD_TALHAO;
            modifiedData.forEach((modifiedItem) => {
              const internalSource = modifiedItem.properties.COD_TALHAO;
              oldPolygon.features.forEach((oldFeature) => {
                const dSource = oldFeature.properties.COD_TALHAO;
                if (internalSource === dSource && source !== internalSource) {
                  sources.push(dSource);
                }
              });
            });

            if (sources.length > 0 && !sources.includes(source))
              disableListPlotPolygon(sources);

            updatePlotPolygon(source, polygon);
          }
          catch (e) {
            console.log(e);
            setMessageTitle("Aviso - Não foi possivel salvar");
            setMessage("O Polígono possui erros, impossibilitando que as alterações sejam aplicadas");
            setErrorEdit(true);
          }
        }
        else {
          var source = draws.features[0].properties.COD_TALHAO;
          disablePlotPolygon(source);
        }
      }
      else {
        var oldFeatures = cutPolygon.features[0];
        var features = [];
        var listPolygon = [];

        if (combine == false && uncombine == false) {
          setMessageTitle("Aviso");
          setMessage("Caso tenha ocorrido uma tentativa de combinação, utilize a ferramenta combinar e atente-se ao fato de que os polígonos a serem combinados devem possuir uma intersecção entre sí.");
          setErrorEdit(true);
        }

        try {
          draws.features.forEach(x => {
            plotData.features.forEach(z => {
              if (z.properties.COD_TALHAO != oldFeatures.properties.COD_TALHAO) {
                var i = TurfIntersect(TurfPolygon(x.geometry.coordinates), z);

                if (i !== null) {
                  intersect = true;
                }
              }
            });

            var uf = oldFeatures.properties.Sigla;
            var ibge = oldFeatures.properties.GEOCODIG_M;

            const area = TurfArea(x);
            var latlng = TurfCenter(TurfPolygon(x.geometry.coordinates));
            var rounded_area = Math.round(area / 10000);
            x.properties["LAT"] = latlng.geometry.coordinates[1];
            x.properties["LNG"] = latlng.geometry.coordinates[0];
            x.properties["Area"] = rounded_area;
            x.properties["GEOCODIG_M"] = ibge;
            x.properties["Sigla"] = uf;

            source = uf + "-" + ibge + "-" + uuidv4();
            x.properties["COD_TALHAO"] = source;

            var polygon = TurfPolygon(x.geometry.coordinates, x.properties);

            listPolygon.push({
              Source: polygon.properties.COD_TALHAO,
              Body: JSON.stringify(featureCollection([polygon])),
            });

            features.push(x);
          })

          if (intersect) {
            setMessageTitle("Aviso - Poligono");
            setMessage("O Polígono está sobrepondo ou possui uma intersecção com outro poligono, logo, não é possível realizar a criação.");
            setErrorEdit(true);
            return;
          }

          modifiedData.forEach((modifiedItem, index) => {
            const internalSource = modifiedItem.properties.COD_TALHAO;
            oldPolygon.features.forEach(oldFeature => {
              const dSource = oldFeature.properties.COD_TALHAO;
              if (internalSource === dSource) {
                if (source !== internalSource) {
                  sources.push(oldFeature.properties.COD_TALHAO);
                }
              }
            });
          });

          if (sources.length > 0 && !sources.includes(source))
            disableListPlotPolygon(sources);

          createMultiplesPolygons(features);
        }
        catch (e) {
          setMessageTitle("Erro");
          setMessage("" + e);
          setErrorEdit(true);
        }
      }
    }

    clearList("plot");
  }
  const removePlotPolygon = (feature, draw, map) => {
    removeFromList(feature, false, draw, map);

    var source = feature.properties.COD_TALHAO;
    disablePlotPolygon(source);
  }
  const removeAllPlotPolygon = (features, draw, map) => {
    const list = features.map(x => x.properties.COD_TALHAO);
    disableListPlotPolygon(list);

    clearList("plot");
  }

  const clearList = (value) => {
    cleanList(value);

    drawR?.deleteAll();
    featuresInfo.current = [];
    setHoverInfo(null);
    setEdit(false);
    setCombine(false);
    setUncombine(false);
  }
  const removeFromList = (feature, farm, draw, map) => {
    var modifiedData = [];
    if (feature != null) {
      var featuresToIterate = selectData?.features || [];

      featuresToIterate.forEach(obj => {
        modifiedData.push(obj);
      });

      if (farm) {
        if (farmSelected.length <= 0) {
          modifiedData = [];
        }

        data.features.forEach(obj => {
          if (obj.properties.COD_IMOVEL === feature.properties.COD_IMOVEL) {
            if (farmSelected.includes(obj.properties.COD_IMOVEL)) {
              var index = farmSelected.indexOf(obj.properties.COD_IMOVEL);
              if (index > -1) {
                modifiedData.splice(index, 1);
              }
            }

            selectPolygon(feature.properties.COD_IMOVEL, obj, false, false);
            return;
          }
        });
      } else {
        plotData.features.forEach(obj => {
          var source = obj.properties.COD_TALHAO;
          var hoveredSource = feature.properties.COD_TALHAO;

          if (source === hoveredSource) {
            if (plotSelected.length <= 0) {
              modifiedData = [];
            }

            if (plotSelected.includes(source)) {
              var index = plotSelected.indexOf(source);
              if (index > -1) {
                modifiedData.splice(index, 1);
              }
            }

            selectPlotPolygon(hoveredSource, obj, false, false);
            return;
          }
        });
      }
    }

    modifiedData = featureCollection(modifiedData);
    setSelectData(modifiedData);

    var layer = map?.getSource('select');
    if (layer != null) {
      layer.setData(modifiedData);
    }

    draw?.deleteAll();
    setEdit(false);
  }

  let canEditAllFarm = !(farmInfo.filter(row => (!isNullOrEmpty(row.properties.CLIENT_ID) && row.properties.CLIENT_ID !== groupId && !accountsList.includes(row.properties.ACCOUNT_ID))).length > 0)
  let canEditAllPlot = !(plotInfo.filter(row => (!isNullOrEmpty(row.properties.CLIENT_ID) && row.properties.CLIENT_ID !== groupId && !accountsList.includes(row.properties.ACCOUNT_ID))).length > 0)
  let hoverProperties = !isNullOrEmpty(hoverInfo) ? hoverInfo.properties : null;
  let polyProperties = !isNullOrEmpty(polyHover) ? polyHover.feature.properties : null;

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ width: "100%", height: "100%" }}>
        <Grid item xs={12} sx={{ zIndex: 0, position: "relative", paddingLeft: "0px !important", paddingTop: "0 !important" }}>
          <Backdrop
            sx={{ color: '#ffffff', position: "absolute", zIndex: (theme) => theme.zIndex.drawer, backgroundColor: "#000000" }}
            open={isLoading}
          >
            <Stack spacing={2} alignItems="center" direction="column">
              <div
                style={{
                  color: "white",
                  fontFamily: "Gilroy",
                  fontWeight: "Bold"
                }}>
                {isNullOrEmpty(progressFarm) ?
                  "Carregando..." :
                  isNumber(progressFarm) ? <CountdownTime initialValue={progressFarm} /> : progressFarm
                }
              </div>
              <CircularProgress
                color="inherit"
              />
            </Stack>
          </Backdrop>

          <Map
            style={{ position: "absolute !important", borderRadius: "0 0 8px 8px" }}
            initialViewState={{
              latitude: coordinates[1],
              longitude: coordinates[0],
              zoom: zoomMap
            }}
            mapStyle={apparence}
            mapboxAccessToken={MAPBOX_TOKEN}
            interactiveLayerIds={['data', 'plot']}
            onLoad={onMapLoad(mapR, drawR)}
            onRender={onDataLoad(coordinates, oldCoord, zoomMap, changeLoadState, goTo)}
            onMouseMove={(event) =>
              onHover(event, mapR)}
            onMouseDown={(event) =>
              onClick(event, data, plotData, farmSelected, plotSelected,
                selectData, edit, showPlots, mapR,
                sizeSelection, selectMode)}
          >

            <DrawControl
              position="bottom-left"
              displayControlsDefault={false}
              controls={{
                combine_features: true,
                uncombine_features: true,
                trash: true,
              }}
              modes={{
                ...SplitPolygonMode(MapboxDraw.modes),
                ...MapboxDrawGeodesic.enable(MapboxDraw.modes),
              }}
              styles={[...splitPolygonDrawStyles(defaultDrawStyle)]}
              userProperties={true}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onCombine={onCombine}
              onUncombine={onUncombine}
            />

            <NavigationControl
              position="top-right"
              style={{
                backgroundColor: "var(--cort-blue)",
                color: "white",
              }} />

            {markerOn && (
              <Marker
                longitude={marker.longitude}
                latitude={marker.latitude}
                anchor="bottom"
                onClick={onClickMarker}
              >
                <svg height={20} viewBox="0 0 24 24" style={{ fill: '#d00', stroke: 'none' }}>
                  <path d={`M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`} />
                </svg>
              </Marker>
            )}

            {!showPlots && (
              <>
                <Source type="geojson" data={counties}>
                  <Layer {...municiLayer} />
                </Source>
                <Source id='data' type="geojson" data={data} generateId={true}>
                  <Layer {...dataLayer} />
                  <Layer {...lineLayer} />
                </Source>
                <Source id='plot' type="geojson" data={featureCollection([])}>
                  <Layer {...plotLayer} />
                </Source>
                {!edit && (
                  <>
                    {showAllData && (
                      <Source id='allAssigned' type="geojson" data={allAssignedData}>
                        <Layer {...allAssignedLayer} />
                      </Source>
                    )}
                    <Source id='assigned' type="geojson" data={assignedData}>
                      <Layer {...assignedLayer} />
                    </Source>
                    <Source id='account' type="geojson" data={accountData}>
                      <Layer {...accountLayer} />
                    </Source>
                    <Source id='select' type="geojson" data={selectData}>
                      <Layer {...selectedLayer} />
                    </Source>
                  </>
                )}
              </>
            )}
            {showPlots && (
              <>
                <Source id='data' type="geojson" data={featureCollection([])}>
                  <Layer {...dataLayer} />
                </Source>
                <Source type="geojson" data={polygonRegion}>
                  <Layer {...regionLayer} />
                </Source>
                <Source id='plot' type="geojson" data={plotData} generateId={true}>
                  <Layer {...plotLayer} />
                  <Layer {...plotLineLayer} />
                </Source>
                {!edit && (
                  <>
                    {showAllData && (
                      <Source id='allAssigned' type="geojson" data={allAssignedData}>
                        <Layer {...allAccountLineLayer} />
                      </Source>
                    )}

                    <Source id='assigned' type="geojson" data={assignedData}>
                      <Layer {...assignedLineLayer} />
                    </Source>
                    <Source id='account' type="geojson" data={accountData}>
                      <Layer {...accountLineLayer} />
                    </Source>
                    <Source id='select' type="geojson" data={selectData}>
                      <Layer {...selectedLayer} />
                    </Source>
                  </>
                )}
              </>
            )}
            {polyHover && !edit && (
              <Box component={Paper} sx={{ position: "absolute", left: polyHover.x + 15, top: polyHover.y, borderRadius: "8px" }}>
                <TableContainer sx={{
                  maxHeight: "40px !important",
                  overflowX: "unset",
                  'table': {
                    border: 'none',
                    overflowWrap: "anywhere",
                    padding: "6px ",
                    borderRadius: "8px 8px 0px 0px",
                  },
                  'td': {
                    border: 'none',
                    overflowWrap: "anywhere",
                    padding: "6px "
                  }
                }}>
                  <Table aria-label="collapsible table" sx={{ overflowX: "unset" }}>
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                      <TableRow>
                        <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "8px !important" }}>
                          {!isNullOrEmpty(polyProperties.ACCOUNT) ? polyProperties.ACCOUNT : "Não atribuido"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <TableContainer sx={{
                  borderRadius: "0px 0px 8px 8px",
                  'td': {
                    border: 'none',
                    overflowWrap: "anywhere",
                    padding: "6px ",
                  }
                }}>
                  <Table aria-label="collapsible table">
                    <TableBody>
                      {!isNullOrEmpty(polyProperties.CLIENT_NAME) && (
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                          <TableCell sx={{ maxWidth: "200px !important", overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            VU: {!isNullOrEmpty(polyProperties.CLIENT_NAME) ? polyProperties.CLIENT_NAME : ""}
                          </TableCell>
                        </TableRow>
                      )}
                      {showPlots && (
                        <>
                          {!isNullOrEmpty(polyProperties.CULT_APONT) && (
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                Cultura Apontada: {cultures.find(x => x.value == polyProperties.CULT_APONT).name}
                              </TableCell>
                            </TableRow>
                          )}
                          {!isNullOrEmpty(polyProperties.CULT_PLANT_DATE) && (
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                Data Plantio: {new Date(polyProperties.CULT_PLANT_DATE).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })}
                              </TableCell>
                            </TableRow>
                          )}
                          {!isNullOrEmpty(polyProperties.CULT_HARVEST_DATE) && (
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                              <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                Data Colheita: {new Date(polyProperties.CULT_HARVEST_DATE).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      {!isNullOrEmpty(polyProperties.USER_NAME) && (
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                          <TableCell sx={{ overflowWrap: "anywhere", padding: "6px 6px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            Atribuído Por: {!isNullOrEmpty(polyProperties.USER_NAME) ? polyProperties.USER_NAME : ""}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Map>

          <BottomNavigation
            showLabels
            sx={{
              backgroundColor: "var(--cort-blue)",
              borderRadius: "5px",
              position: 'absolute',
              right: "122px",
              top: "10px",
            }}
          >
            <BottomNavigationAction sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: "Bold",
              whiteSpace: "nowrap",
              padding: "0px 26px 0px 22px"
            }}
              label={
                <>
                  {isLoadingPlots ? isNullOrEmpty(progressPlot) ?
                    "Carregando..." :
                    isNumber(progressPlot) ? <CountdownTime initialValue={progressPlot} /> : progressPlot :
                    !showPlots ? "Mostrar Talhões" : "Mostrar Fazendas"}
                  {isLoadingPlots && (
                    <CircularProgress
                      color="inherit"
                      sx={{ width: "8px !important", height: "8px !important", marginLeft: "8px !important" }}
                      variant={progressPlot === 0 ? undefined : "determinate"}
                      value={progressPlot}
                    />
                  )}
                </>
              }
              icon={<ChangeLayer style={{ width: "18px", height: "24px" }} />}
              onClick={() => hasPlots ? showPlots ? (setPlotOptions(false), setShowPlots(false)) : setPlotOptions(!openPlotOptions) : {}}
            />
            {openPlotOptions && (
              <Box sx={{
                position: "absolute",
                top: "56px",
                left: "5px",
                width: "110px",
                background: "#ffffff",
                borderRadius: "0px 0px 8px 8px",
                padding: "8px",
                fontFamily: 'Gilroy',
                fontWeight: 'bold',
                fontSize: "0.8rem"
              }}>
                <Stack direction={"column"} spacing={2}>
                  <div
                    onClick={() => { setPlotOptions(false); buttonAccountPlots() }}
                    style={{

                      textAlign: 'center',
                      cursor: 'pointer',
                    }}>
                    {accountId ? "Account" : "Visão Única"}
                  </div>
                  {!isNullOrEmpty(selectedPolygon) && (
                    <div
                      onClick={() => { setPlotOptions(false); setShowPlots(!showPlots, selectedPolygon) }}
                      style={{
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}>
                      Selecionados
                    </div>
                  )}
                </Stack>
              </Box>
            )}

            <BottomNavigationAction sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: "Bold",
            }}
              label="Fazendas"
              icon={<MapIcon />}
              onClick={handleFarmClick(TransitionLeft)}
            />
            <BottomNavigationAction sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: "Bold",
            }}
              label="Talhões"
              icon={<LayersIcon />}
              onClick={handlePlotClick(TransitionLeft)}
            />
          </BottomNavigation>

          <BottomNavigation
            showLabels
            sx={{
              backgroundColor: "var(--cort-blue)",
              borderRadius: "5px",
              position: 'absolute',
              right: "10px",
              top: "10px",
            }}
          >
            <CoordinatesPagination addData={addData} changeLoadState={changeLoadState} />
          </BottomNavigation>

          <Box sx={{ position: "absolute", top: "115px", left: "10px" }}>
            <Stack direction={"row"} spacing={2}>
              <Tooltip title="Limpar Seleção">
                <IconButton
                  sx={{
                    color: "white", backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "3px",
                  }}
                  onClick={() => clearList("all")}>
                  <PlaylistRemoveIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Alterna entre o modo de seleção/arrasto">
                <IconButton
                  sx={{
                    color: "white",
                    backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                  onClick={() => selectPolygonMode(selectMode, drawR)}>
                  {selectMode && (
                    <PanToolIcon sx={{ width: "15px", height: "15px" }} />
                  )}
                  {!selectMode && (
                    <SwipeDownIcon sx={{ width: "20px", height: "20px" }} />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title="Aumenta o raio em km que será selecionado os polígonos">
                <Slider
                  label="Distancia da seleção"
                  orientation="horizontal"
                  valueLabelDisplay="auto"
                  min={1}
                  value={sizeSelection}
                  onChange={changeSizeSelection}
                  sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: "8px", width: "200px" }}
                />
              </Tooltip>
            </Stack>
            <Dialog
              open={errorEdit}
              onClose={() => setErrorEdit(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {messageType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {confirmation && (
                  <React.Fragment>
                    <Button onClick={() => {
                      setErrorEdit(false);
                      setConfirmationOption(false);
                    }}
                    >
                      Cancelar
                    </Button>
                    <Button onClick={() => {
                      setErrorEdit(false);
                      setConfirmationOption(false);
                      handleChange(messageAction);
                    }}
                      autoFocus>
                      Sim
                    </Button>
                  </React.Fragment>
                )}
                {!confirmation && (
                  <Button onClick={() => {
                    setErrorEdit(false);
                  }}
                    autoFocus>
                    Ok
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </Box>

          {/* Ferramentas de Edição */}
          <Box sx={{ position: "absolute", top: "10px", left: "310px" }}>
            {!drawTools && !edit && (
              <IconButton
                sx={{
                  color: "var(--cort-blue)",
                  backgroundColor: "white !important",
                  width: "56px",
                  height: "56px",
                  borderRadius: "8px",
                  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                }}
                onClick={() => setDrawTools(true)}
              >
                <DesignServicesIcon />
              </IconButton>
            )}
            {(drawTools || edit) && (
              <Stack direction={"row"} spacing={2}>
                <Tooltip title="Voltar">
                  <IconButton
                    sx={{
                      color: "var(--cort-blue)",
                      backgroundColor: "white !important",
                      width: "32px",
                      height: "56px",
                      borderRadius: "8px",
                      boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                    }}
                    onClick={() => { cancelEditArea(drawR); setDrawTools(false) }}>
                    <ArrowLeftIcon />
                  </IconButton>
                </Tooltip>
                {edit && (
                  <>
                    <Tooltip title="Descombinar">
                      <IconButton
                        sx={{
                          color: "var(--cort-blue)",
                          backgroundColor: "white !important",
                          width: "32px",
                          height: "56px",
                          borderRadius: "8px",
                          boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                        }}
                        onClick={() => uncombineFeatures(drawR)}>
                        <BreakIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Combinar">
                      <IconButton
                        sx={{
                          color: "var(--cort-blue)",
                          backgroundColor: "white !important",
                          width: "32px",
                          height: "56px",
                          borderRadius: "8px",
                          boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                        }}
                        onClick={() => combineFeatures(drawR)}>
                        <UnionIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Desenhar linha de corte">
                      <IconButton
                        sx={{
                          color: "var(--cort-blue)",
                          backgroundColor: "white !important",
                          width: "32px",
                          height: "56px",
                          borderRadius: "8px",
                          boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                        }}
                        onClick={() => splitPolygon(drawR)}>
                        <ContentCutIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {!edit && (
                  <>
                    <Tooltip title="Desenhar Circulo">
                      <IconButton
                        sx={{
                          color: "var(--cort-blue)",
                          backgroundColor: "white !important",
                          width: "32px",
                          height: "56px",
                          borderRadius: "8px",
                          boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                        }}
                        onClick={() => drawCircle(drawR)}>
                        <DataUsageIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Desenhar Polígono">
                      <IconButton
                        sx={{
                          color: "var(--cort-blue)",
                          backgroundColor: "white !important",
                          width: "32px",
                          height: "56px",
                          borderRadius: "8px",
                          boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important"
                        }}
                        onClick={() => drawPolygon(drawR)}>
                        <DriveFileRenameOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            )}
          </Box>

          {/* Utilitarios */}
          <Box sx={{ position: "absolute", top: "167px", right: "10px" }}>
            <Stack spacing={2}>

              <Tooltip title="Mudar estilo">
                <IconButton
                  sx={{
                    color: "white", backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "3px",
                  }}
                  onClick={() => setStyled(!styled)}
                >
                  <VisibilityIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              </Tooltip>

              {styled && (
                <Paper
                  sx={{
                    position: "absolute",
                    top: "-15px",
                    right: "40px",
                    borderRadius: '8px',
                    padding: '8px',
                    color: 'black',
                    backgroundColor: 'white',
                    maxHeight: '320px',
                    overflowY: 'auto',
                    zIndex: (theme) => theme.zIndex.drawer,
                  }}
                >
                  <div
                    onClick={() => changeMapStyle("streets-v12")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Básico">
                      <PublicIcon />
                    </Tooltip>
                  </div>
                  <div
                    onClick={() => changeMapStyle("satellite-streets-v12")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Satelite">
                      <SatelliteAltIcon />
                    </Tooltip>
                  </div>
                  <div
                    onClick={() => changeMapStyle("dark-v11")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Modo Escuro">
                      <DarkModeIcon />
                    </Tooltip>
                  </div>
                  <div
                    onClick={() => changeMapStyle("light-v11")}
                    style={{
                      fontFamily: 'Gilroy',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="Modo Claro">
                      <WbSunnyIcon />
                    </Tooltip>
                  </div>
                </Paper>
              )}

              <Tooltip title="Mostra o mapa por completo">
                <IconButton
                  sx={{
                    color: "white", backgroundColor: "var(--cort-blue)",
                    width: "30px",
                    marginTop: "5px !important",
                    borderRadius: "3px",
                  }}
                  onClick={() => goTo('data')}>
                  <ZoomInMapIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>

          {hoverInfo && (
            <Box component={Paper}
              sx={{
                position: "absolute", bottom: "10px", left: "10px",
                height: "110px",
                width: "290px",
                textAlign: "center",
                padding: "1% 1% 1% 1%",
                lineBreak: "anywhere",
                zIndex: (theme) => theme.zIndex.drawer + 2
              }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Novo Polígono
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <b>Área Poligono:</b>{" " + Number(Math.round(hoverProperties.NUM_AREA)).toLocaleString("pt-br") + " ha"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ fontWeight: "bold", width: "100%" }}
                    variant="contained"
                    onClick={() => savePolygon(counties, data, allPlotData, drawR, showPlots)}>
                    {featuresInfo.length > 1 ? "Criar Polígonos" : "Criar Polígono"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Snackbar
            open={farmOpen && !isLoading && !isLoading}
            TransitionComponent={transition}
            key={'farm'}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <Box component={Paper}>
              <Grid container spacing={2} sx={{ maxWidth: "350px", maxHeight: "525px", marginTop: "0 !important", marginLeft: "0 !important" }}>
                <Grid item xs={12} sx={{ padding: "0 !important" }}>
                  <FarmPanel
                    data={farmInfo}
                    copySourceCodes={copySourceCodes}
                    drawR={drawR}
                    mapR={mapR}
                    inEdit={edit}
                    goToPolygon={goToPolygon}
                    remove={removeFromList}
                    saveArea={saveFarmArea}
                    cancel={cancelEditArea}
                    setEdit={setEditArea}
                    cleanList={clearList}
                    removePolygon={removeFarmPolygon}
                    groupId={groupId}
                    territoryId={territoryId}
                    accountsList={accountsList}
                  />
                </Grid>
                {!edit && (
                  <Grid container spacing={2} sx={{ paddingTop: "16px", textAlign: "center", paddingRight: "8px", paddingLeft: "8px" }}>
                    <Grid className='align-self-center' item xs={6}>
                      <Tooltip title={!canEditAllFarm ? "Não é possivel editar todos polígonos selecionados" : "Editar todos polígonos selecionados"}>
                        <span>
                          <Button
                            fullWidth
                            disabled={farmInfo?.length <= 0 || showPlots || !canEditAllFarm}
                            sx={{ fontWeight: "Bold", fontSize: "clamp(1px, 1px, 100px)", height: "53px" }}
                            onClick={() => setEditMultiArea(drawR, selectData)}
                            variant="contained"
                          >
                            <EditIcon />
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid className='align-self-center' item xs={6}>
                      <Tooltip title="Centralizar mapa nos polígonos selecionados">
                        <span>
                          <Button
                            fullWidth
                            disabled={farmInfo?.length <= 0 || showPlots}
                            sx={{ fontWeight: "Bold", fontSize: "clamp(1px, 1px, 100px)", height: "53px" }}
                            onClick={() => goTo('select')}
                            variant="contained"
                          >
                            <ZoomInMapIcon />
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {edit && (
                  <Grid container spacing={2} sx={{ paddingTop: "16px", textAlign: "center", paddingRight: "8px" }}>
                    <Grid className='align-self-center' item xs={4}>
                      <IconButton onClick={() => saveFarmArea(drawR, mapR)}>
                        <SaveIcon />
                      </IconButton>
                    </Grid>
                    <Grid className='align-self-center' item xs={4}>
                      <Tooltip title={!canEditAllFarm ? "Não é possivel remover todos polígonos selecionados" : "Remover todos polígonos selecionados"}>
                        <span>
                          <IconButton onClick={() => removeAllFarmPolygon(farmInfo, drawR, mapR)}
                            disabled={!canEditAllFarm}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid className='align-self-center' item xs={4}>
                      <IconButton onClick={() => cancelEditArea(drawR)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                </Grid>
              </Grid>
            </Box>
          </Snackbar>
          <Snackbar
            open={plotOpen && !isLoading && !isLoading}
            TransitionComponent={transition}
            key={'plot'}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Box component={Paper}>
              <Grid container spacing={2} sx={{ maxWidth: "350px", maxHeight: "525px", marginTop: "0 !important", marginLeft: "0 !important" }}>
                <Grid item xs={12} sx={{ padding: "0 !important" }}>
                  <PlotPanel
                    data={plotInfo}
                    drawR={drawR}
                    mapR={mapR}
                    inEdit={edit}
                    goToPolygon={goToPolygon}
                    copySourceCodes={copySourceCodes}
                    remove={removeFromList}
                    saveArea={savePlotArea}
                    cancel={cancelEditArea}
                    setEdit={setEditArea}
                    removePolygon={removePlotPolygon}
                    cleanList={clearList}
                    changePropertiesPlot={changePropertiesPlot}
                    groupId={groupId}
                    accountsList={accountsList}
                    cultures={cultures}
                  />
                </Grid>
                {!edit && (
                  <Grid container spacing={2} className='align-self-center' sx={{ paddingTop: "16px", textAlign: "center", paddingRight: "8px", paddingLeft: "8px" }}>
                    <Grid item xs={6}>
                      <CultureAttr cultures={cultures} culturesGoal={culturesGoal} irrigations={irrigations} plotInfo={plotInfo} setMessageBox={setMessageBox} />
                    </Grid>
                    <Grid className='align-self-center' item xs={3}>
                      <Tooltip title={!canEditAllPlot ? "Não é possivel editar todos polígonos selecionados" : "Editar todos polígonos selecionados"}>
                        <span>
                          <Button
                            fullWidth
                            disabled={plotInfo?.length <= 0 || !showPlots || !canEditAllPlot}
                            sx={{ fontWeight: "Bold", height: "56px" }}
                            onClick={() => setEditMultiArea(drawR, selectData)}
                            variant="contained"
                          >
                            <EditIcon />
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid className='align-self-center' item xs={3}>
                      <Tooltip title="Centralizar mapa nos polígonos selecionados">
                        <Button
                          fullWidth
                          disabled={plotInfo?.length <= 0 || !showPlots}
                          sx={{ fontWeight: "Bold", fontSize: "clamp(1px, 1px, 100px)", height: "53px" }}
                          onClick={() => goTo('select')}
                          variant="contained"
                        >
                          <ZoomInMapIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {edit && (
                  <Grid container spacing={2} sx={{ paddingTop: "16px", textAlign: "center", paddingRight: "8px" }}>
                    <Grid className='align-self-center' item xs={4}>
                      <IconButton onClick={() => savePlotArea(drawR)}>
                        <SaveIcon />
                      </IconButton>
                    </Grid>
                    <Grid className='align-self-center' item xs={4}>
                      <Tooltip title={!canEditAllPlot ? "Não é possivel remover todos polígonos selecionados" : "Remover todos polígonos selecionados"}>
                        <span>
                          <IconButton onClick={() => removeAllPlotPolygon(plotInfo, drawR, mapR)}
                            disabled={!canEditAllPlot}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid className='align-self-center' item xs={4}>
                      <IconButton onClick={() => cancelEditArea(drawR)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                </Grid>
              </Grid>
            </Box>
          </Snackbar>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}