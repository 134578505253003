const requestMetodoPagamentoType = "REQUEST_METODOPAGAMENTO";
const receiveMetodoPagamentoType = "RECEIVE_METODOPAGAMENTO";

const setMaxBarter = "METODOPAGAMENTO_SET_MAX_BARTER";

const requestMetodoType = "METODOPAGAMENTO_REQUEST_METODO";
const receiveMetodoType = "METODOPAGAMENTO_RECEIVE_METODO";

const REQUEST_ALL_METODOS = "REQUEST_ALL_METODOS";
const RECEIVE_ALL_METODOS = "RECEIVE_ALL_METODOS";

const initialState = { allMetodos: [], metodos: [], metodo: {}, maxBarter: 0, isLoading: false };

export const metodoPagamentoActions = {
  requestAllMetodos: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: REQUEST_ALL_METODOS });

    const url = `/api/Simulador2/SimuladorMetodoPagamentoV2/all`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      }
    };

    try {
      const response = await fetch(url, options);
      const metodoPagamento = await response.json();
      dispatch({ type: RECEIVE_ALL_METODOS, metodoPagamento });
    } catch (error) {
      console.error('Erro ao buscar métodos de pagamento:', error);
    }
  },
  requestMetodoPagamento: (campanha, metodoId = undefined) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestMetodoPagamentoType });

    const url = `/api/Simulador2/SimuladorMetodoPagamentoV2/campanha?campanha=${campanha}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      }
    };

    try {
      const response = await fetch(url, options);
      const metodoPagamento = await response.json();

      dispatch({ type: receiveMetodoPagamentoType, metodoPagamento });

      try {
        if (metodoId !== undefined) {
          const metodo = metodoPagamento.find(metodo => metodo.metodoPagamentoId === metodoId);
          if (metodo) {
            dispatch({ type: setMaxBarter, maxBarter: metodo.maxBarter });
          }
        }
      } catch (_) {

      }
    } catch (error) {
      console.error('Erro ao buscar métodos de pagamento:', error);
    }
  },
  GetMetodoPagamento: (metodoId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestMetodoType });

    const url = `/api/Simulador2/SimuladorMetodoPagamentoV2/Get/${metodoId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      }
    };

    try {
      const response = await fetch(url, options);
      const metodoPagamento = await response.json();

      dispatch({ type: receiveMetodoType, metodoPagamento });

    } catch (error) {
      console.error('Erro ao buscar método de pagamento:', error);
    }
  },
  setMaxBarter: (maxBarter = 0) => async (dispatch) => {
    dispatch({ type: setMaxBarter, maxBarter });
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case requestMetodoPagamentoType:
      return {
        ...state,
        isLoading: true,
      };
    case receiveMetodoPagamentoType:
      return {
        ...state,
        metodos: action.metodoPagamento,
        isLoading: false,
      };
    case requestMetodoType:
      return {
        ...state,
        metodo: {},
        isLoading: true,
      };
    case receiveMetodoType:
      return {
        ...state,
        metodo: action.metodoPagamento,
        isLoading: false,
      };
    case REQUEST_ALL_METODOS:
      return {
        ...state,
        isLoading: true
      };
    case RECEIVE_ALL_METODOS:
      return {
        ...state,
        allMetodos: action.metodoPagamento,
        isLoading: false
      }
    case setMaxBarter:
      return {
        ...state,
        maxBarter: action.maxBarter,
      }
    default:
      return state;
  }
};

