const requestencargosType = "REQUEST_ENCARGOS";
const receiveencargosType = "RECEIVE_ENCARGOS";

const requestAllEncargos = "REQUEST_ALL_ENCARGOS";
const receiveAllEncargos = "RECEIVE_ALL_ENCARGOS";

const initialState = { allEncargos: [], encargos: [], isLoading: false };

export const encargosActions = {
  requestencargos: (campanha) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestencargosType });

    const url = `/api/simuladorencargoscampanha/${encodeURIComponent(campanha)}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const encargos = await response.json();
    dispatch({ type: receiveencargosType, encargos });
  },
  requestencargos2: (campanha) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestencargosType });

    const url = `/api/simulador2/simuladorencargos/campanha?campanha=${campanha}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const encargos = await response.json();
    dispatch({ type: receiveencargosType, encargos });
  },
  requestAllEncargos: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAllEncargos });

    const url = `/api/simuladorEncargosSeeds/getAll`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const encargos = await response.json();
    dispatch({ type: receiveAllEncargos, encargos });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestencargosType) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveencargosType) {
    return {
      ...state,
      encargos: action.encargos,
      isLoading: false,
    };
  }

  if (action.type === requestAllEncargos) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveAllEncargos) {
    return {
      ...state,
      allEncargos: action.encargos,
      isLoading: false,
    };
  }

  return state;
};
