import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SketchPicker } from 'react-color';
import { isNullOrUndefined } from "../../../helpers/utils";

export default function MapPanel({ cultures, actions, assignedData, allAssignedData, pointedCulture, predictCulture, showingStates, showPlots, refresh, cultureSwitch }) {
    const [open, setOpen] = useState(false);
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [colorPickerValue, setColorPickerValue] = useState("");

    const openColorPicker = (value) => {
        setColorPickerValue(value);
        setColorPickerOpen(true);
    }

    return (
        <React.Fragment>
            <Button sx={{
                display: open ? "none" : "block",
                position: "absolute",
                bottom: "10px",
                left: "10px",
                textAlign: "center",
                padding: "8px 16px",
                borderRadius: "8px",
                width: "fit-content",
                height: "40px",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
                backgroundColor: "white !important",
                zIndex: (theme) => theme.zIndex.drawer,
            }}
                onClick={() => setOpen(true)}
            >
                Legenda
            </Button>
            <TableContainer
                sx={{
                    display: open ? "block" : "none",
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    textAlign: "center",
                    padding: "8px",
                    borderRadius: "8px",
                    width: "fit-content",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "black",
                    backgroundColor: "white",
                    zIndex: (theme) => theme.zIndex.drawer,
                }}>
                <IconButton aria-label="Voltar" onClick={() => setOpen(false)} sx={{ float: "right" }}>
                    <CloseIcon sx={{ height: "20px", width: "20px" }} />
                </IconButton>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {!showPlots && !showingStates && (
                            <>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "20px", border: "2px solid black", borderRadius: "2px", alignSelf: "center", }}></div>
                                        <div>Fazendas</div>
                                    </Stack>
                                </TableRow>
                                {!isNullOrUndefined(assignedData) && !isNullOrUndefined(assignedData.features) && (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                        <Stack direction={'row'} spacing={2}>
                                            <div style={{ height: "20px", width: "20px", backgroundColor: "var(--cort-blue)", borderRadius: "2px", alignSelf: "center", }}></div>
                                            <div>Atribuíções</div>
                                        </Stack>
                                    </TableRow>
                                )}
                                {!isNullOrUndefined(allAssignedData) && !isNullOrUndefined(allAssignedData.features) && (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                        <Stack direction={'row'} spacing={2}>
                                            <div style={{ height: "20px", width: "20px", backgroundColor: "red", borderRadius: "2px", alignSelf: "center", }}></div>
                                            <div>Atribuíções de outras VU's</div>
                                        </Stack>
                                    </TableRow>
                                )}
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "20px", border: "2px solid var(--cort-blue)", borderRadius: "2px", alignSelf: "center", }}></div>
                                        <div>Município</div>
                                    </Stack>
                                </TableRow>
                            </>
                        )}
                        {showPlots && !showingStates && (
                            <>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "20px", border: "2px solid gray", borderRadius: "2px", alignSelf: "center", }}></div>
                                        <div>Fazendas</div>
                                    </Stack>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "20px", border: "2px solid lightgray", borderRadius: "2px", alignSelf: "center", }}></div>
                                        <div>Talhões</div>
                                    </Stack>
                                </TableRow>
                                {!isNullOrUndefined(assignedData) && !isNullOrUndefined(assignedData.features) && (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                        <Stack direction={'row'} spacing={2}>
                                            <div style={{ height: "20px", width: "20px", border: "2px solid var(--cort-blue)", borderRadius: "2px", alignSelf: "center", }}></div>
                                            <div>Atribuíções</div>
                                        </Stack>
                                    </TableRow>
                                )}
                                {!isNullOrUndefined(allAssignedData) && !isNullOrUndefined(allAssignedData.features) && (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                        <Stack direction={'row'} spacing={2}>
                                            <div style={{ height: "20px", width: "20px", backgroundColor: "red", borderRadius: "2px", alignSelf: "center", }}></div>
                                            <div>Atribuíções de outras VU's</div>
                                        </Stack>
                                    </TableRow>
                                )}
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    Culturas
                                </TableRow>
                                {cultureSwitch && (
                                    <div style={{ maxHeight: "95px", overflowY: "auto" }}>
                                        {predictCulture &&
                                            Object.keys(predictCulture).map((x, index) => {
                                                if (x !== "Vazio") {
                                                    const culture = cultures.find(y => y.name == x);

                                                    return (
                                                        <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }} size="small" onClick={() => openColorPicker(culture.value)}>
                                                            <Stack direction={'row'} spacing={2}>
                                                                <div style={{ height: "20px", width: "20px", backgroundColor: culture.color, borderRadius: "2px", alignSelf: "center", cursor: "pointer" }}></div>
                                                                <div>{x}</div>
                                                            </Stack>
                                                        </TableRow>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                )}
                                {!cultureSwitch && (
                                    <div style={{ maxHeight: "95px", overflowY: "auto" }}>
                                        {pointedCulture && Object.keys(pointedCulture).map((x, index) => {
                                            if (x !== "Vazio") {
                                                const culture = cultures.find(y => y.name == x);

                                                return (
                                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small" onClick={() => openColorPicker(culture.value)}>
                                                        <Stack direction={'row'} spacing={2}>
                                                            <div style={{ height: "20px", width: "20px", backgroundColor: culture.color, borderRadius: "2px", alignSelf: "center", cursor: "pointer" }}></div>
                                                            <div>{x}</div>
                                                        </Stack>
                                                    </TableRow>
                                                )
                                            }
                                        })}
                                    </div>
                                )}
                            </>
                        )}
                        {!showPlots && showingStates && (
                            <>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "20px", border: "2px solid black", borderRadius: "2px", alignSelf: "center", }}></div>
                                        <div>Múnicipios</div>
                                    </Stack>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "40px", border: "1px solid var(--cort-blue)", borderRadius: "2px", alignSelf: "center", background: "linear-gradient(to right, transparent, var(--cort-blue)" }}></div>
                                        <div>Percentual de hectare atribuido</div>
                                    </Stack>
                                </TableRow>
                            </>
                        )}
                        {showPlots && showingStates && (
                            <>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "20px", border: "2px solid lightgray", borderRadius: "2px", alignSelf: "center", }}></div>
                                        <div>Múnicipios</div>
                                    </Stack>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                                    <Stack direction={'row'} spacing={2}>
                                        <div style={{ height: "20px", width: "40px", border: "1px solid var(--cort-blue)", borderRadius: "2px", alignSelf: "center", background: "linear-gradient(to right, transparent, var(--cort-blue)" }}></div>
                                        <div>Percentual de hectare atribuido</div>
                                    </Stack>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <ColorPickerDialog isOpen={colorPickerOpen} onClose={setColorPickerOpen} value={colorPickerValue} actions={actions} refresh={refresh} />
        </React.Fragment >
    )
}

const ColorPickerDialog = ({ isOpen, onClose, value, actions, refresh }) => {
    const [selectedColor, setSelectedColor] = useState('#ffffff');

    const handleColorChange = (color) => {
        setSelectedColor(color.hex);
    };

    const handleDialogOk = () => {
        actions.changeColorCulture({ name: value, color: selectedColor });

        refresh();
        onClose(false);
    };
    const handleDialogClose = () => {
        onClose(false);
    };

    return (
        <Dialog open={isOpen} onClose={handleDialogClose} >
            <DialogTitle>Escolha uma cor para a Cultura</DialogTitle>
            <DialogContent sx={{ maxWidth: "400px !important" }}>
                <SketchPicker color={selectedColor}
                    onChange={handleColorChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancelar</Button>
                <Button variant="contained" onClick={handleDialogOk}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};