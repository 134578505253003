import * as React from 'react';
import Grid from '@mui/material/Grid';
// import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { FixedSizeList } from 'react-window';
import { useEffect } from 'react';
import { TextField } from '@mui/material';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList({ left = [], setLeft = (values = []) => { left = values }, right = [], setRight = (values = []) => { right = values }, getLabel = (value) => { return value }, rightTitle = "", leftTitle = "", color = "azul", buttonVariant = "outlined", property = "" }) {
  const [checked, setChecked] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ width: "calc(100%) !important" }}
      justifyContent="center"
      alignItems="center">
      <Grid
        item
        xs={4} sm={8} md={5}
        sx={{ paddingLeft: "0 !important" }}
      >
        <CustomList
          items={left}
          title={leftTitle}
          color={color}
          property={property}
          side="left"
          checked={checked}
          setChecked={setChecked}
          handleToggle={handleToggle}
          getLabel={getLabel}
        />
      </Grid>
      <Grid
        item
        xs={4} sm={8} md={2}
        sx={{ paddingLeft: "0 !important" }}
      >
        <Grid container direction="column" alignItems="center"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex' }
          }}>
          <Button
            color={color}
            sx={{ my: 0.5 }}
            variant={buttonVariant}
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="Mover selecionados para direita"
          >
            <KeyboardDoubleArrowRightIcon />
          </Button>
          <Button
            color={color}
            sx={{ my: 0.5 }}
            variant={buttonVariant}
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="Mover selecionados para esquerda"
          >
            <KeyboardDoubleArrowLeftIcon />
          </Button>
        </Grid>
        <Grid container gap={2} alignItems="center" justifyContent={'center'}
          sx={{
            display: { xs: 'flex', sm: 'flex', md: 'none' }
          }}>
          <Button
            color={color}
            sx={{ my: 0.5 }}
            variant={buttonVariant}
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="Mover selecionados para baixo"
          >
            <KeyboardDoubleArrowDownIcon />
          </Button>
          <Button
            color={color}
            sx={{ my: 0.5 }}
            variant={buttonVariant}
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="Mover selecionados para cima"
          >
            <KeyboardDoubleArrowUpIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4} sm={8} md={5}
        sx={{ paddingLeft: "0 !important" }}
      >
        <CustomList
          items={right}
          title={rightTitle}
          color={color}
          property={property}
          side="right"
          checked={checked}
          setChecked={setChecked}
          handleToggle={handleToggle}
          getLabel={getLabel}
        />
      </Grid>
    </Grid>
  );
}

const CustomList = ({ items, title, side = "", property, color = "azul", checked, setChecked = () => { }, handleToggle = () => { }, getLabel = () => { } }) => {
  const [filter, setFilter] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState(items);

  useEffect(() => {
    setFilter("");
    setFilteredItems(items);
  }, [items]);

  const normalizeString = (str) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  };

  const handleChangeFilter = (event) => {
    const value = normalizeString(event.target.value);
    const filtered = items.filter((x) => normalizeString(x[property]).includes(value));

    setFilter(event.target.value);
    setFilteredItems(filtered);
  }

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  return (
    <Card>
      <CardHeader
        sx={{
          px: 2,
          py: 1,
          backgroundColor: 'var(--bs-card-cap-bg)',
          borderBottom: 'var(--bs-card-border-width) solid var(--bs-card-border-color)',
          'span:first-of-type': {
            fontSize: "1.15rem",
          }
        }}
        title={title}
        subheader={`${numberOfChecked(filteredItems)}/${items.length} selecionados`}
      />
      <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ padding: "0 16px" }}>
        <Checkbox
          color={color}
          onClick={handleToggleAll(filteredItems)}
          checked={numberOfChecked(items) === items.length && items.length !== 0}
          indeterminate={
            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
          }
          disabled={items.length === 0}
          inputProps={{
            'aria-label': 'todos selecionados',
          }}
        />
        <TextField fullWidth
          value={filter}
          placeholder="Buscar..."
          variant="standard"
          margin="dense"
          onChange={handleChangeFilter}
          sx={{
            '& input': {
              padding: "2px 2px 2px 2px!important",
            },
            '*::after': {
              border: "none !important"
            },
            '*::before': {
              border: "none !important"
            }
          }}
        />
      </Stack>
      <Divider />
      <FixedSizeList
        height={230}
        width={"100%"}
        itemSize={46}
        itemCount={filteredItems.length}
        overscanCount={5}
      >
        {({ index, style }) => {
          const labelId = `transfer-list-all-${side}-item-${index}-label`;
          return (
            <ListItem
              style={style} key={index} component="div"
              role="listitem"
              button
              onClick={handleToggle(filteredItems[index])}
            >
              <ListItemIcon>
                <Checkbox
                  color={color}
                  checked={checked.indexOf(filteredItems[index]) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${getLabel(filteredItems[index])}`} />
            </ListItem>
          );
        }
        }
      </FixedSizeList>
    </Card>
  );
}