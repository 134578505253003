import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { BottomNavigationAction, Checkbox, /*FormGroup,*/ IconButton, InputBase, ListSubheader, Paper } from '@mui/material';
// import { FormControl } from '@material-ui/core';
// import { FixedSizeList } from 'react-window';
import ListItem from '@mui/material/ListItem';
import { Virtuoso, GroupedVirtuoso } from 'react-virtuoso'
import { groupBy as groupByLoash } from 'lodash'

const tryNumberParse = (value = "", valueIsNumber = true) => {
  if (valueIsNumber) {
    var number = Number(value);
    return isNaN(number) || !isFinite(number) ? value : number;
  } else {
    return value;
  }
}

const customSorter = (sortBy) => (a, b) => {
  if (a[sortBy] < b[sortBy]) {
    return -1;
  }
  if (a[sortBy] > b[sortBy]) {
    return 1;
  }
  return 0;
};

const groupData = (data = [], groupBy = (item) => { return item }, sortBy = "") => {
  if (groupBy !== undefined) {
    var orderData = data;
    if (sortBy !== "" && sortBy !== undefined && sortBy != null) {
      orderData = data.sort(customSorter(sortBy))
    }
    const groupedData = groupByLoash(orderData, groupBy)
    const groupCounts = Object.values(groupedData).map((item) => item.length)
    const groups = Object.keys(groupedData)

    return { data: orderData, groupCounts, groups }
  }
  return { data: undefined, groupCounts: undefined, groups: undefined }
}

function ConfirmationDialogRaw({
  onClose,
  value: valueProp,
  open,
  title,
  cancelText = "Cancelar",
  confirmText = "Ok",
  options = [{ label: '', value: '', codIbge: '', uf: '', municipio: '' }],
  getKey = (item, index = 0) => { return item?.codIbge },
  getLabel = (item) => { return (item?.uf + " - " + item?.municipio) },
  getValue = (item) => { return item?.codIbge },
  valueIsNumber = true,
  groupBy = undefined,
  sortBy = "",
  ...other }) {
  const [value, setValue] = React.useState(valueProp);
  const [text, setText] = React.useState('');
  const radioGroupRef = React.useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  React.useEffect(() => {
    if (!open) {
      setValue([...valueProp]);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
    setText('');
  };

  const handleOk = () => {
    onClose(value);
    setText('');
  };

  const handleChange = (event) => {
    var aux = value;
    var cod = tryNumberParse(event.target.value, valueIsNumber)
    if (event.target.checked) {
      aux.push(cod);
      setValue([...aux]);
    }
    else {
      var index = aux.indexOf(cod);
      if (index !== -1) {
        aux.splice(index, 1);
      }
      setValue([...aux]);
    }
  };

  const handleSearchText = (event) => {
    setText(event.target.value);
  }

  var filterOpts = options?.filter(x => { return getLabel(x).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) })

  const { data, groupCounts, groups } = groupData(filterOpts, groupBy, sortBy);


  const renderRow = (index) => {
    var option;

    if (groupBy === undefined) {
      option = filterOpts[index];
    } else {
      option = data[index];
    }

    var checked = value.indexOf(tryNumberParse(getValue(option), valueIsNumber)) > -1
    return (
      <ListItem
        key={`${getKey(option, index)}`}
        sx={{ alignItems: "baseline" }}
        component="div"
        role="listitem"
      >
        <FormControlLabel
          sx={{ ml: 0, mr: 0, mb: 0 }}
          control={<Checkbox checked={checked} onChange={handleChange} value={tryNumberParse(getValue(option), valueIsNumber)} />}
          label={getLabel(option)}
        />
      </ListItem>
    )
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      fullScreen={fullScreen}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers sx={{ height: 'fit-content', padding: '10px 12px', overflow: "visible" }}>
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            boxShadow: 'none',
            border: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: '1rem !important' }}
            onChange={handleSearchText}
            value={text}
          />
          <IconButton
            type="button"
            sx={{ p: "5px 10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </DialogContent>
      <DialogContent dividers sx={{ padding: 0 }}>
        {groupBy === undefined ?
          <Virtuoso
            style={{ height: "255px" }}
            data={filterOpts}
            itemContent={renderRow}
          />
          :
          <GroupedVirtuoso
            key={groupCounts} //Atualizar o elemento quando atualiza a quantidade de itens se não quebra o padding 
            groupCounts={groupCounts}
            style={{ height: "255px" }}
            groupContent={index => {
              return <ListSubheader>{groups[index]}</ListSubheader>
            }}
            itemContent={renderRow}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button onClick={handleOk}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ConfirmationDialog({
  title = "Municípios",
  cancelText = "Cancelar",
  confirmText = "Ok",
  options = [{ label: '', value: '' }],
  getLabel = (item) => { return (item?.uf + " - " + item?.municipio) },
  getValue = (item) => { return item?.codIbge },
  valueIsNumber = true,
  getKey = (item, index = 0) => { return item?.codIbge },
  Node = Button,
  onChange = (newValue = [], oldValue = []) => { },
  keepState = true,
  groupBy = undefined,
  sortBy = "",
  ...props }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState([]);

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      onChange(newValue, value);
      if (keepState)
        setValue(newValue);
    }
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClickListItem}
        sx={{
          color: "white",
          fontFamily: "Gilroy",
          fontWeight: "Bold",
        }}
        label="Adicionar"
        icon={props.children}
        {...props}
      />
      <ConfirmationDialogRaw
        open={open}
        onClose={handleClose}
        value={value}
        options={options}
        title={title}
        cancelText={cancelText}
        confirmText={confirmText}
        getKey={getKey}
        getLabel={getLabel}
        getValue={getValue}
        valueIsNumber={valueIsNumber}
        groupBy={groupBy}
        sortBy={sortBy}
      />
    </React.Fragment>
  );
}