import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CornIcon(props) {
  return <SvgIcon viewBox="0 0 32 50" {...props} >
    <path d="M15.7012 44.2718C14.8303 31.4983 10.258 25.8857 8.08069 24.6761C8.20165 16.6926 9.82253 0.725769 15.3384 0.725769C20.8542 0.725769 22.9589 16.4507 23.3218 24.3132C19.8381 24.3132 16.7899 37.6189 15.7012 44.2718Z" fill="#EFE8BA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.2648 2.98441C12.2343 1.29298 13.5594 0 15.3384 0C17.1047 0 18.4605 1.26162 19.4806 2.92644C20.515 4.61433 21.3281 6.90686 21.9655 9.39066C23.2432 14.3696 23.8641 20.3199 24.0469 24.2797L24.0819 25.039H23.3219C23.1293 25.039 22.8663 25.1298 22.5243 25.4375C22.1825 25.745 21.8175 26.2209 21.4406 26.8634C20.6881 28.1463 19.9656 29.9582 19.3046 32.0193C17.9857 36.1315 16.9585 41.0832 16.4176 44.389L14.9772 44.3212C14.5471 38.0123 13.2053 33.5087 11.7276 30.4535C10.239 27.3759 8.6373 25.8155 7.72831 25.3105L7.34851 25.0995L7.35509 24.6651C7.416 20.6453 7.85376 14.6044 8.97808 9.54956C9.53887 7.02832 10.2815 4.69984 11.2648 2.98441ZM8.81385 24.2778C10.0418 25.1162 11.6252 26.9082 13.0343 29.8215C14.1848 32.2 15.233 35.3528 15.8846 39.4105C16.4296 36.858 17.1219 34.0719 17.9224 31.576C18.5949 29.4791 19.3558 27.5487 20.1886 26.129C20.6044 25.4201 21.0575 24.8045 21.5535 24.3584C21.8553 24.0868 22.1937 23.8602 22.5654 23.7244C22.3515 19.8408 21.7394 14.3492 20.5595 9.75145C19.9359 7.3212 19.166 5.19109 18.243 3.68485C17.3059 2.15553 16.3301 1.45153 15.3384 1.45153C14.3596 1.45153 13.4136 2.15441 12.5241 3.70625C11.6484 5.2341 10.944 7.39631 10.395 9.86471C9.33676 14.6224 8.8966 20.3228 8.81385 24.2778Z" fill="#020202" />
    <path d="M21.647 25.8058C23.7312 23.4458 28.4704 23.2245 30.5795 23.2245C29.4629 32.0746 29.4629 30.9683 28.7185 34.6558C28.1317 37.563 26.5877 43.546 24.2523 46.0871C22.2331 48.2635 16.0641 50.6597 16.0641 48.2996C16.0641 45.3496 16.0641 42.3996 16.4363 38.7121C16.8085 35.0246 19.0416 28.7558 21.647 25.8058Z" fill="#1D9641" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31.4026 22.4988H30.5796C29.5055 22.4988 27.7373 22.5545 25.9583 22.9192C24.204 23.2788 22.3046 23.9648 21.1031 25.3254C19.718 26.8936 18.4711 29.2896 17.5277 31.7197C16.5817 34.1567 15.9089 36.7115 15.7143 38.6392C15.3385 42.3631 15.3385 45.3435 15.3385 48.2909V48.2996C15.3385 48.7679 15.4995 49.196 15.8465 49.5098C16.1711 49.8033 16.5849 49.9296 16.9732 49.9762C17.7397 50.0682 18.694 49.8838 19.6229 49.5856C21.4879 48.9869 23.6612 47.7914 24.7844 46.5807L24.7867 46.5782C26.0625 45.1901 27.0714 42.9356 27.8273 40.7455C28.592 38.5297 29.1323 36.2742 29.43 34.7994C29.5973 33.9709 29.7272 33.3832 29.8409 32.8692C30.2328 31.0961 30.4311 30.1993 31.2996 23.3154L31.4026 22.4988ZM29.7545 23.9624C28.9818 30.0344 28.7903 30.8998 28.4188 32.5789C28.3055 33.0909 28.1755 33.6784 28.0072 34.5122C27.7181 35.9446 27.193 38.1343 26.4552 40.272C25.7087 42.4347 24.7783 44.4416 23.7191 45.5948C22.8227 46.5602 20.9029 47.6502 19.1793 48.2035C18.3136 48.4814 17.5995 48.5895 17.1462 48.535C16.9246 48.5084 16.8406 48.4517 16.82 48.4331C16.8128 48.4266 16.8094 48.4219 16.8056 48.4126C16.801 48.4012 16.79 48.3678 16.79 48.2996C16.79 45.3499 16.7904 42.4325 17.1585 38.785C17.3361 37.0252 17.966 34.6018 18.8809 32.245C19.7984 29.8814 20.9707 27.668 22.191 26.2862C23.0737 25.2867 24.5861 24.6822 26.2497 24.3412C27.5032 24.0842 28.7666 23.9917 29.7545 23.9624Z" fill="#020202" />
    <path d="M9.75558 25.8058C7.67132 23.4458 2.93213 23.2245 0.823059 23.2245C1.93962 32.0746 1.93962 30.9683 2.684 34.6558C3.27085 37.563 4.81478 43.546 7.15026 46.0871C9.16938 48.2635 15.3384 50.6597 15.3384 48.2996C15.3384 45.3496 15.3384 42.3996 14.9662 38.7121C14.594 35.0246 12.3609 28.7558 9.75558 25.8058Z" fill="#1D9641" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 22.4988H0.823087C1.89715 22.4988 3.66535 22.5545 5.44439 22.9192C7.19866 23.2788 9.09804 23.9648 10.2996 25.3254C11.6846 26.8936 12.9315 29.2896 13.8749 31.7197C14.821 34.1567 15.4938 36.7115 15.6883 38.6392C16.0642 42.3631 16.0642 45.3435 16.0642 48.2909V48.2996C16.0642 48.7679 15.9032 49.196 15.5562 49.5098C15.2315 49.8033 14.8178 49.9296 14.4295 49.9762C13.663 50.0682 12.7087 49.8838 11.7797 49.5856C9.91478 48.9869 7.74144 47.7914 6.61823 46.5807L6.61593 46.5782C5.34012 45.1901 4.33123 42.9356 3.57536 40.7455C2.8106 38.5297 2.27031 36.2742 1.97261 34.7994C1.80535 33.9709 1.67543 33.3832 1.56179 32.8692C1.16981 31.0961 0.971543 30.1993 0.103028 23.3154L0 22.4988ZM1.64813 23.9624C2.42084 30.0344 2.61233 30.8998 2.98385 32.5789C3.09712 33.0909 3.22713 33.6784 3.39544 34.5122C3.6846 35.9446 4.20969 38.1343 4.94748 40.272C5.6939 42.4347 6.62432 44.4416 7.68353 45.5948C8.57996 46.5602 10.4997 47.6502 12.2234 48.2035C13.089 48.4814 13.8032 48.5895 14.2564 48.535C14.4781 48.5084 14.562 48.4517 14.5826 48.4331C14.5899 48.4266 14.5932 48.4219 14.597 48.4126C14.6017 48.4012 14.6127 48.3678 14.6127 48.2996C14.6127 45.3499 14.6123 42.4325 14.2441 38.785C14.0665 37.0252 13.4367 34.6018 12.5218 32.245C11.6042 29.8814 10.4319 27.668 9.21161 26.2862C8.32891 25.2867 6.81657 24.6822 5.15291 24.3412C3.89948 24.0842 2.6361 23.9917 1.64813 23.9624Z" fill="#020202" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1115 5.44323L13.1611 1.08038L14.6125 1.09688L14.5632 5.44323H16.7899V1.45151H18.2414V5.44323H20.0558V6.89477H18.2414V10.1607H21.5074V11.6123H18.2414V13.7896H22.2331V15.2411H18.2414V18.507H22.9589V19.9586H18.2414V22.4988H23.3218V23.9503H18.2414V26.4905H20.4187V27.942H18.2414V30.8451H18.6043V32.2966H18.2414V33.7481H16.7899V32.2966H14.258L14.2497 33.0306L12.7982 33.0141L12.8064 32.2966H12.7982V30.8451H12.8229L12.8559 27.942H10.6209V26.4905H12.8724L12.9012 23.9503H8.08069V22.4988H12.9177L12.9466 19.9586H8.44357V18.507H12.9631L13.0002 15.2411H8.80646V13.7896H13.0167L13.0414 11.6123H9.53222V10.1607H13.0579L13.095 6.89477H10.6209V5.44323H13.1115ZM14.5467 6.89477L14.5095 10.1607H16.7899V6.89477H14.5467ZM16.7899 11.6123H14.4931L14.4683 13.7896H16.7899V11.6123ZM16.7899 15.2411H14.4518L14.4147 18.507H16.7899V15.2411ZM16.7899 19.9586H14.3982L14.3693 22.4988H16.7899V19.9586ZM16.7899 23.9503H14.3528L14.324 26.4905H16.7899V23.9503ZM16.7899 27.942H14.3075L14.2745 30.8451H16.7899V27.942ZM14.2497 34.8368H17.1528V36.2883H14.2497V34.8368Z" fill="black" />
  </SvgIcon>
}