import { TableCell } from "@mui/material";

export function ResumoColsSeeds({ cpa }) {
  return <>
    {/* APROVADOS */}
    <TableCell align="right">
      {
        (cpa?.aprovadoTotalGeralNegociado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {Number((cpa?.aprovadoTotalGeralSacosNegociado ?? 0).toFixed(2)).toLocaleString("pt-BR")}
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovadoCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
      {
        (cpa?.aprovadoPerSacoCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    {/* AGUARDANDO APROVAÇÃO */}
    <TableCell align="right">
      {
        (cpa?.aprovacaoTotalGeralNegociado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {Number((cpa?.aprovacaoTotalGeralSacosNegociado ?? 0).toFixed(2)).toLocaleString("pt-BR")}
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.aprovacaoCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
      {
        (cpa?.aprovacaoPerSacoCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    {/* APROVADOS + AGUARDANDO APROVAÇÃO*/}
    <TableCell align="right">
      {
        (cpa?.workflowTotalGeralNegociado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right">
      {Number((cpa?.workflowTotalGeralSacosNegociado ?? 0).toFixed(2)).toLocaleString("pt-BR")}
    </TableCell>
    <TableCell align="right">
      {
        (cpa?.workflowCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
    <TableCell align="right" sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
      {
        (cpa?.workflowPerSacoCpaGeralUsado ?? 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      }
    </TableCell>
  </>
}