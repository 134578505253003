const requestProductsPriceType = "REQUEST_ProductsPrice";
const receiveProductsPriceType = "RECEIVE_ProductsPrice";
const initialState = { ProductsPrice: [], isLoading: false };

export const productPriceActions = {
  requestProductsPrice: (campanha, clienteProps, cliente, produto) => async (dispatch, getState) => {
    const { oidc } = getState();
    var distrito = ""

    for (var j = 0; j < clienteProps.length; j++) {
      // eslint-disable-next-line eqeqeq
      if (clienteProps[j].id == cliente) {
        distrito = clienteProps[j].district
      }
    }

    dispatch({ type: requestProductsPriceType });

    const url = `/api/simuladorprodutos/${encodeURIComponent(campanha)}/${encodeURIComponent(distrito)}/${encodeURIComponent(produto)}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ProductsPrice = await response.json();
    dispatch({ type: receiveProductsPriceType, ProductsPrice });
  },
  requestProductsPrice2: (campanha, cliente, produto) => async (dispatch, getState) => {
    const { oidc } = getState();
    var distrito = ""

    if (cliente) {
      distrito = cliente.distrito
    }

    dispatch({ type: requestProductsPriceType });

    const url = `/api/simulador2/simuladorprodutos/produto?campanha=${campanha}&distrito=${distrito}&produto=${produto}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ProductsPrice = await response.json();
    dispatch({ type: receiveProductsPriceType, ProductsPrice });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestProductsPriceType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveProductsPriceType) {
    return {
      ...state,
      ProductsPrice: action.ProductsPrice,
      isLoading: false,
    };
  }

  return state;
};
