import React, { useState } from 'react';
import { TableCell } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { NumericFormat } from "react-number-format";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    // maxWidth: 220,
    border: 'none',
  },
}));


export const ErrorTooltip = ({ messageError, maxValue, ...props }) => {
  // const { messageError, maxValue } = props;
  const [open, setOpen] = React.useState(false);

  const isAllowed = (value, max) => {
    var number = Number(value.value)

    if (number > max) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return number <= max;
  };

  const handleClose = () => {

    setOpen(false);
  };

  return (<HtmlTooltip placement="right" leaveDelay={600} open={open} onClose={handleClose}
    title={
      <React.Fragment>
        <Alert severity="error">
          {messageError}
        </Alert>
      </React.Fragment>
    }
  >
    <span>

      <NumericFormat
        {...props}
        isAllowed={(values, sourceInfo) => isAllowed(values, maxValue)}
      />
    </span>

  </HtmlTooltip>
  );
}
