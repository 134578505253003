import { Stack } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

export default function Semaforo({ cor = undefined }) {
  return <Stack
    direction="row"
    spacing={0}
    justifyContent="flex-start"
    className="border border-2 d-table p-2 rounded"
    sx={{
      borderColor: cor !== undefined && cor != null ? `#${cor === 0 ? "C00000" : cor === 1 ? "FFC000" : "00B050"} !important` : "var(--bs-border-color) !important"
    }}>
    <CircleIcon sx={{ color: `${cor === 2 ? "#00B050" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
    <CircleIcon sx={{ color: `${cor === 1 ? "#FFC000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
    <CircleIcon sx={{ color: `${cor === 0 ? "#C00000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
  </Stack>
}