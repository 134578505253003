import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { userActions } from "../../../actions/user.actions";
import AllowUseNavigate, { navigate } from "../../Navigate";

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waitNavigateLoad: true
    };
    this.finishLoading = this.finishLoading.bind(this);
  }

  componentWillMount() {
  }

  finishLoading() {
    if (this.state.waitNavigateLoad && (!this.props.user.isLoading || !this.props.user.isLoadingRedirect)) {
      this.setState({ waitNavigateLoad: false })
      this.props.actions.requestModulosAndRedirect(navigate);
    }
  }

  handleAccessModule(descricao, url) {
    this.props.actions.requestAccessTo(descricao, url, navigate);
  }

  render() {
   // console.log('Props HomePage:', this.props);
    return (
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ margin: "0 auto" }} direction="row" xl="auto" justifyContent="center" alignItems="center" className="mt-4 mb-5">
        <AllowUseNavigate afterLoadNavigate={this.finishLoading} />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.user.isLoading || this.state.waitNavigateLoad}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {!this.props.user.isLoading && !this.state.waitNavigateLoad &&
          this.props.user.modulos.sort((a, b) => a.descricao.localeCompare(b.descricao)).map((modulo) => {
            return (
              <Grid item xs={2} sm={8} md={6} justifyContent="center" alignItems="center" key={modulo.id}>
                <Card sx={{
                  maxWidth: "90%",
                  margin: "0 auto",
                  transition: "transform 0.2s",
                  '&:hover': {
                    transform: "scale(1.05)",
                  },
                }}>
                  <CardActionArea sx={{ display: "flex", flexDirection: "column" }} onClick={() => this.handleAccessModule(modulo.descricao, `/${modulo.redirectUrl}`)} style={{ color: "#000" }}>
                    <CardMedia
                      component="img"
                      image={`${modulo.imageUrl}`}
                      alt={`${modulo.descricao}`}
                      sx={{ height: { xs: "calc((100vw * 0.9) * 0.381944)", sm: "calc((100vw * 0.9) * 0.381944)", md: "calc(((100vw / 2) * 0.9) * 0.381944)" }, width: "auto", minWidth: "100%" }}
                    />
                    <CardContent sx={{ background: "linear-gradient(360deg, black, transparent)", backdropFilter: "blur(1px)", width: "100%", padding: "12px", position: "absolute", bottom: "0" }}>
                      <Typography variant="h5" component="div" sx={{ textAlign: "center", color: "white", fontWeight: "bold", textDecoration: "none" }}>
                        {modulo.descricao}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...userActions
      }, dispatch),
    };
  }
)(HomePage);
