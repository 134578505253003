const requestAllSeedsEmbalagens = "REQUEST_ALL_SEEDS_EMBALAGENS";
const receiveAllSeedsEmbalagens = "RECEIVE_ALL_SEEDS_EMBALAGENS";
const initialState = { embalagens: [], isLoading: false };

export const seedsEmbalagensActions = {
  requestAllEmbalagens: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAllSeedsEmbalagens });

    const url = `/api/SimuladorProdutosSeeds/embalagens`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const embalagens = await response.json();
    dispatch({ type: receiveAllSeedsEmbalagens, embalagens });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestAllSeedsEmbalagens) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveAllSeedsEmbalagens) {
    return {
      ...state,
      embalagens: action.embalagens,
      isLoading: false,
    };
  }

  return state;
};
