import React, { useState } from "react";
import {
    featureCollection
} from '@turf/turf';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { ListItem, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DehazeIcon from '@mui/icons-material/Dehaze';

function isNullOrEmpty(str) {
    return str === null || str === undefined || str.length === 0;
  }

function Row(props) {
    const { row, drawR, mapR, inEdit, saveArea, setEdit, removeList, removePolygon, cancel, goToPolygon, groupId, accountsList, cultures } = props;
    const [open, setOpen] = React.useState(false);
    const [exclude, setExclude] = React.useState(false);

    const handleClickOpen = () => {
        setExclude(true);
    };

    const handleClose = () => {
        setExclude(false);
    };

    var canEdit = !((row.properties.CLIENT_ID != null && row.properties.CLIENT_ID !== "" && row.properties.CLIENT_ID !== groupId) && (!accountsList.includes(row.properties.ACCOUNT_ID)))

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                <TableCell sx={{ padding: "0" }} align="center">
                    <IconButton sx={{ padding: "0" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ fontSize: "70%", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 6px" }}>
                    {row.properties.COD_TALHAO}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "70%", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 16px" }}>
                    {Number(Math.round(row.properties.Area)).toLocaleString("pt-br") + " ha"}
                </TableCell>
                <TableCell align="center" >
                    <IconButton sx={{ padding: "0" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => removeList(row, false, drawR, mapR)}
                    >
                        <CloseIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: "5%" }}>
                            <Grid container spacing={2} sx={{
                                height: "100%",
                            }}>
                                <Grid className='align-self-center' item xs={6}>
                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Cliente: " + (row.properties.ACCOUNT != null && row.properties.ACCOUNT !== "" ? row.properties.ACCOUNT : "Sem Cliente")} />
                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem", fontWeight: "bold" } }} primary={"Culturas"}/>
                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Classificada: " + (!isNullOrEmpty(row.properties.CULT_PREDIT) ? cultures.find(x => x.value == row.properties.CULT_PREDIT).name : "Nenhuma")} />
                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Apontada: " + (!isNullOrEmpty(row.properties.CULT_APONT) ? cultures.find(x => x.value == row.properties.CULT_APONT).name : "Nenhuma")} />
                                    {/* <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Data Plantio: " +
                                        (row.properties.CULT_PLANT_DATE != null && row.properties.CULT_PLANT_DATE !== "" ?
                                            new Date(row.properties.CULT_PLANT_DATE).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }) : "Nenhuma")} />
                                            
                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Data Colheita: " +
                                        (row.properties.CULT_HARVEST_DATE != null && row.properties.CULT_HARVEST_DATE !== "" ?
                                            new Date(row.properties.CULT_HARVEST_DATE).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }) : "Nenhuma")} />

                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"É Campo de produção de semente: " +
                                        (row.properties.SEMENTE != null && row.properties.SEMENTE !== "" ? row.properties.SEMENTE == "true" ? "Sim" : "Não" : "Não definido")} />

                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"É Irrigado: " +
                                        (row.properties.IRRIGADO != null && row.properties.IRRIGADO !== "" ? row.properties.IRRIGADO == "true" ? "Sim" : "Não" : "Não definido")} /> */}
                                </Grid>
                                {!inEdit && (
                                    <Grid className='align-self-center' item xs={6}>
                                        <Stack direction="row" spacing={2}>
                                            <ListItem>
                                                <Tooltip title="Editar Área">
                                                    <IconButton aria-label="Editar Área"
                                                        onClick={() => setEdit(featureCollection([row]), drawR)}
                                                        disabled={!canEdit}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItem>
                                            <ListItem>
                                                <Tooltip title="Centralizar no Polígono">
                                                    <IconButton aria-label="Centralizar no Polígono"
                                                        onClick={() => goToPolygon(row)}
                                                    >
                                                        <GpsFixedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItem>
                                        </Stack>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container spacing={2} sx={{
                                height: "100%",
                            }}>
                                {inEdit && (
                                    <>
                                        <Grid className='align-self-center' item xs={4}>
                                            <ListItem>
                                                <Tooltip title="Salvar Área">
                                                    <IconButton aria-label="Salvar Área"
                                                        onClick={() => saveArea(drawR, mapR)}
                                                    >
                                                        <SaveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItem>
                                        </Grid>
                                        <Grid className='align-self-center' item xs={4}>
                                            <ListItem>
                                                <Tooltip title="Deletar Área">
                                                    <IconButton aria-label="Deletar Área"
                                                        onClick={handleClickOpen}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Dialog
                                                    open={exclude}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"Remover propiedade"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            Deseja realmente remover o polígono {row.properties.CountyFID + row.properties.TileID}?
                                                            O processo pode levar alguns segundos e não pode ser revertido.
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleClose}>Não</Button>
                                                        <Button onClick={() => {
                                                            removePolygon(row, drawR)
                                                            handleClose()
                                                        }}
                                                            autoFocus>
                                                            Sim
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ListItem>
                                        </Grid>
                                        <Grid className='align-self-center' item xs={4}>
                                            <ListItem>
                                                <Tooltip title="Cancelar Edição">
                                                    <IconButton aria-label="Cancelar Edição"
                                                        onClick={() => cancel(drawR)}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItem>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

export default function MapPanel(props) {
    const { data, drawR, mapR, inEdit, saveArea, setEdit, remove, removePolygon, cancel, cleanList, goToPolygon, copySourceCodes, groupId, accountsList, cultures } = props;

    return (
        <React.Fragment>
            <TableContainer sx={{ borderRadius: "4px 4px 0px 0px" }}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                        <TableRow>
                            <TableCell align="center" sx={{ padding: "0px" }}>
                                <Tooltip title={"Copiar códigos dos polígonos"}>
                                    <IconButton sx={{ padding: "0", color: "white" }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => copySourceCodes(true)}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 6px" }}>
                                Nome
                            </TableCell>
                            <TableCell align="right" sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 16px" }}>
                                Área Total
                            </TableCell>
                            <TableCell align="center" sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 16px" }}>
                                <Tooltip title={"Remover Todas Seleções"}>
                                    <IconButton sx={{ padding: "0", color: "white" }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => cleanList("plot")}
                                    >
                                        <PlaylistRemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer sx={{ minHeight: "280px", maxHeight: "280px", overflowX: "hidden", borderRadius: "0px 0px 4px 4px" }}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {data?.map((data, index) => (
                            <Row key={index}
                                row={data}
                                drawR={drawR}
                                mapR={mapR}
                                inEdit={inEdit}
                                goToPolygon={goToPolygon}
                                removeList={remove}
                                cancel={cancel}
                                setEdit={setEdit}
                                saveArea={saveArea}
                                removePolygon={removePolygon}
                                groupId={groupId}
                                accountsList={accountsList}
                                cultures={cultures}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}
