import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../actions/user.actions";
import { AlertActions } from "../actions/alert.actions";

import NavBar from "./Navbar";
import NavBarSimulador from "./NavbarSimulador";
import configure from "../util/configureSignalR";
import SimpleBoxError from "./SimpleBoxError";
import { Outlet } from "react-router-dom";

let connection = null;

class LayoutSimulador extends React.Component {
  componentWillMount() {
    this.props.actions.requestRoutes();
    const { oidc } = this.props;
    if (oidc && oidc?.user && oidc.user.access_token) {
      connection = configure(oidc);
    }
  }
  render() {
    const { oidc } = this.props;
    const userLoggedIn = oidc.user;
    return (
      <div>
        {userLoggedIn && (
          <div>
            <SimpleBoxError
              open={this.props.alert.alertOpen}
              onClose={this.props.actions.closeAlert}
              type={this.props.alert.alertType}
              title={this.props.alert.alertTitle}
              message={this.props.alert.alertMessage}
            />

            <NavBar oidc={oidc} connection={connection} />
            <NavBarSimulador oidc={oidc} modulos={this.props.user.routes} />
            <div id="main-content" className="container-fluid">
              {this.props.outlet ? <Outlet /> : this.props.children}
            </div>
          </div>
        )}
        {!userLoggedIn && (this.props.outlet ? <Outlet /> : this.props.children)}
      </div>
    );
  }
}

const connectedDefaultPage = connect(
  (state) => {
    return {
      oidc: state.oidc,
      user: state.user,
      alert: state.alert
    }
  },
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...userActions,
        ...AlertActions
      }, dispatch),
    };
  }
)(LayoutSimulador);
export { connectedDefaultPage as LayoutSimulador };
