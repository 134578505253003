/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { userActions } from "../../../actions/user.actions";
import { clientTerritoryActions } from "../../../actions/clientTerritory.actions";
import { precoVeActions } from "../../../actions/precove.actions";
import { simuladorVeEncargosActions } from "../../../actions/simuladorVeEncargos.action";
import { produtoveActions } from "../../../actions/produtove.action";
import { Alert, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, createTheme, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { NumericFormat } from "react-number-format";
import { simuladorVeActions } from "../../../actions/simuladorVendaExterna.actions";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    // maxWidth: 220,
    border: 'none',
  },
}));

const CellInputAlert = ({ messageError, maxValue, ...props }) => {
  // const { messageError, maxValue } = props;
  const [open, setOpen] = React.useState(false);

  const isAllowed = (value, max) => {
    if (maxValue === undefined || maxValue === null || Number.isNaN(maxValue)) {
      return true;
    }

    var number = Number(value.value)

    if (number > max) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return number <= max;
  };

  const handleClose = () => {

    setOpen(false);
  };

  return (<HtmlTooltip placement="right" leaveDelay={600} open={open} onClose={handleClose}
    title={
      <React.Fragment>
        <Alert severity="error">
          {messageError}
        </Alert>
      </React.Fragment>
    }
  >
    <TableCell align="center">
      <NumericFormat
        {...props}
        isAllowed={(values, sourceInfo) => isAllowed(values, maxValue)}
      />
    </TableCell>
  </HtmlTooltip>
  );
}


class SimulatorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cliente: 25,
      cli: 0,
      clientId: "",
      produto: "",
      encargos: "",
      moeda: "",
      prazoPagamento: "",
      currency: "",
      volume: "",
      territorio: "",
      distrito: "",
      open: false,
      status: "",
      canSave: false,
      precoNegociado: "",
      mensagem: "",
      botao: "",
      moedaAuxiliar: "",
      index: "",
      prazoAuxiliar: ""
    };
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.setState({ cli: params.GroupId });

    this.props.actions.requestClientTerritories();
    this.props.actions.requestEncargos();
    this.props.actions.simulacaoCliente(params.GroupId);
    //  this.props.actions.requestProdutoPorTerritorio(params.GroupId, this.state.moeda);
    this.props.actions.requestCondicaoComercial(this.state.moeda);

    // this.props.actions.requestPrecoProduto(this.state.produto, this.state.territorio, this.state.moeda);

  }

  handleChange = (event) => {
    this.setState({ produto: event.target.value });
    this.props.actions.requestPrecoProduto(event.target.value, this.state.territorio, this.state.moeda);
  };

  handleMoeda = (event) => {
    this.setState({ produto: "" });
    // this.setState({ prazoPagamento: "" });
    if (this.state.moeda === "" || this.props.simuladorVendaExterna.rows.length === 0) {
      this.setState({ moeda: event.target.value });
      this.props.actions.requestCondicaoComercial(event.target.value);
    }

    if (this.props.simuladorVendaExterna.rows.length > 0) {
      this.setState({ moedaAuxiliar: event.target.value });
      this.handleClickOpen(2, "");
    }

  };

  handleCondicaoComercial = (event) => {
    this.setState({ produto: "" });
    // this.setState({ prazoPagamento: event.target.value });
    if (this.state.moeda === "" || this.props.simuladorVendaExterna.rows.length === 0) {
      this.setState({ prazoPagamento: event.target.value });
      this.props.actions.requestProdutoPorTerritorio(this.state.cli, this.state.moeda);
    }

    if (this.props.simuladorVendaExterna.rows.length > 0) {
      this.setState({ prazoAuxiliar: event.target.value });
      this.handleClickOpen(3, "");
    }
  };

  handleChangeVolume = (event, index) => {

    const volume = event.floatValue;
    const rows = [...this.props.simuladorVendaExterna.rows];

    rows[index].volume = volume;
    if (volume > 0) {
      rows[index].valorTotal = rows[index].precoFinal * volume;
      rows[index].valorFinalNegociado = rows[index].precoNegociado * volume;
    } else {
      rows[index].valorTotal = rows[index].precoFinal;
      rows[index].valorFinalNegociado = rows[index].precoNegociado;
    }


    this.props.actions.updateRows(rows);
    this.setState({ volume });
  };

  handleChangePrecoNegociado = (event, index) => {

    var precoNegociado = event.floatValue;
    const rows = [...this.props.simuladorVendaExterna.rows];
    if (precoNegociado === undefined) {
      precoNegociado = 0;
    }

    rows[index].precoNegociado = precoNegociado;
    if (rows[index].volume > 0) {
      rows[index].valorFinalNegociado = rows[index].precoNegociado * rows[index].volume;
    } else {
      rows[index].valorFinalNegociado = precoNegociado;
    }

    this.props.actions.updateRows(rows);
    this.setState({ precoNegociado });
  };

  handleClickOpen = (opcao, index) => {
    console.log("index:", index);
    this.setState({ index: index });
    if (opcao === 1) {
      this.setState({ botao: "Excluir" });
      this.setState({ mensagem: "Deseja realmente exluir este produto?" });
    }
    if (opcao === 2) {
      this.setState({ botao: "Alterar" });
      this.setState({ mensagem: "Ao realizar a troca de moeda, todos os dados simulados serão exluídos. Deseja realmente alterar Moeda?" });
    }
    if (opcao === 3) {
      this.setState({ botao: "Alterar" });
      this.setState({ mensagem: "Ao realizar a troca de Prazo de Pagamento, todos os dados simulados serão exluídos. Deseja realmente alterar Prazo de Pagamento?" });
    }
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ moedaAuxiliar: "" });
    this.setState({ prazoAuxiliar: "" });
  };

  handleDeleteRow = (index) => {
    if (this.state.botao === "Alterar") {
      this.props.actions.removeAllRows();
      if (this.state.moedaAuxiliar != "") {
        this.setState({ prazoPagamento: "" });
        this.setState({ moeda: this.state.moedaAuxiliar });
        this.props.actions.requestCondicaoComercial(this.state.moedaAuxiliar);
      }
      if (this.state.prazoAuxiliar != "") {
        this.setState({ prazoPagamento: this.state.prazoAuxiliar });
        this.props.actions.requestProdutoPorTerritorio(this.state.cli, this.state.moeda);
      }
    }
    if (this.state.botao === "Excluir") {
      this.props.actions.deleteRow(this.state.index);

    }
    this.handleClose();
  }

  addProduct = () => {
    const rows = [...this.props.simuladorVendaExterna.rows];
    if (!(rows.find((x) => this.state.produto.id === x.produto.id && this.state.moeda === x.moeda && this.state.prazoPagamento === x.prazoPagamento))) {
      this.setState({ canSave: true });
      this.props.actions.addRow(
        this.state.produto,
        this.props.precoVendaExterna.precos,
        this.props.encargoVendaExterna.encargos,
        this.state.moeda,
        this.state.prazoPagamento,
      );
    }
  }

  SaveCenario = (total, status) => {
    const rows = [...this.props.simuladorVendaExterna.rows];
    this.props.actions.createSaveGenerateCenario(
      this.state.cli,
      status,
      rows,
      total,
      this.state.moeda,
      this.state.prazoPagamento,
    ).then(() => {
      this.props.actions.removeAllRows();
      this.props.actions.simulacaoCliente(this.state.cli);
    })

  }


  render() {
    // const currency = 'BRL';
    var rows = this.props.simuladorVendaExterna.rows ? this.props.simuladorVendaExterna.rows : [];
    //var territorio = "";
    var distrito = "";
    var territorio = "";
    var cli = "";
    var canSave = false;
    var canFinalizar = false;

    // if (this.props.simuladorVendaExterna.rows.length === 0 || rows.find((x) => x.volume === "") || rows.find((x) => x.precoNegociado === "")) {
    //   canSave = false;
    // } else {
    //   canSave = true;
    // }
    const isProdutoSelecionado = this.state.produto !== "";

    for (var j = 0; j < this.props.clientesVendaExterna.clients.length; j++) {
      // eslint-disable-next-line eqeqeq
      if (this.props.clientesVendaExterna.clients[j].id == this.state.cli) {
        this.state.cliente = this.props.clientesVendaExterna.clients[j];
        cli = this.props.clientesVendaExterna.clients[j].account.nome;
        this.state.territorio = this.props.clientesVendaExterna.clients[j].territory.territoryId;
        territorio = this.props.clientesVendaExterna.clients[j].territory.territoryName;
        distrito = this.props.clientesVendaExterna.clients[j].territory.districtName;
        var endereco = this.state.cliente.account.endereco;
        var municipio = this.state.cliente.account.municipio;
        var uf = this.state.cliente.account.uf;
        var ie = this.state.cliente.account.ie;
        var cpfCnpj = this.state.cliente.account.cpfCnpj;
      }
    }
    var total = 0;
    for (var i = 0; i < rows.length; i++) {
      total = total + rows[i].valorFinalNegociado;
    }

    // var canSave = this.state.moeda !== "" && this.state.prazoPagamento !== "" && this.state.produto !== "" && rows.length > 0;
    // var rascunho = rows.find(x => x.volume !== 0) && rows.find(x => x.precoNegociado !== 0);

    if (this.props.simuladorVendaExterna.rows.length === 0) {
      canSave = false;
    } else {
      canSave = true;
      if (rows.find((x) => x.volume === 0) || rows.find((x) => x.volume === undefined) || rows.find((x) => x.precoNegociado === 0) || rows.find((x) => x.precoNegociado === undefined)) {
        canFinalizar = false;
      } else {
        canFinalizar = true;
      }
    }


    return (
      <div className="card">
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/simuladorVe"}
              >
                Simulador Venda Externa
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/simuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">Nova Simulação</Typography>
            </Breadcrumbs>
          </Stack>
        </div>

        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{cli}</h1>
              </div>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {distrito}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Endereço:</span> {endereco}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Cidade:</span> {municipio} - {uf}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Inscrição Estadual:</span> {ie}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>CPF/CNPJ:</span> {cpfCnpj}</h6>
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                {total > 0 && (
                  <>
                    <Grid item xs={2} sm={8} md={8} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        <b>Valor total Simulação: </b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={4} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        {
                          total.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={12} sx={{ alignSelf: "flex-end" }}>
              <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2} justifyContent={"center"}>
                {canSave ?
                  <Link to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
                    <Button
                      onClick={() => this.SaveCenario(total, "Rascunho")}
                      variant="contained"
                      color="laranja"
                      sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                    >
                      Salvar Rascunho
                    </Button>
                  </Link> :
                  <Tooltip title="Não é possivel salvar o cenário sem condição de pagamento e vencimento">
                    <span>
                      <Button
                        disabled={true}
                        variant="contained"
                        color="laranja"
                        sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                      >
                        Salvar Rascunho
                      </Button>
                    </span>
                  </Tooltip>
                }
                {canFinalizar ? <Link
                  to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
                >
                  <Button
                    onClick={() => this.SaveCenario(total, "Finalizado")}
                    variant="contained"
                    color="verde"
                    sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                  >
                    Finalizar Cenário
                  </Button>
                </Link> :
                  <Tooltip title="Preencha todos os campos para finalizar o cenário">
                    <span>
                      <Button
                        disabled={true}
                        variant="contained"
                        color="verde"
                        sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                      >
                        Finalizar Cenário
                      </Button>
                    </span>
                  </Tooltip>
                }
                <Link
                  to={`/SimuladorVe/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
                >
                  <Button
                    variant="contained"
                    color="vermelho"
                    onClick={() => this.deleteRows()}
                    sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                  >
                    Sair sem Salvar
                  </Button>
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                  <p>Selecione os parâmetros da Simulação:</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={8} md={2}>
              <label className="d-block">Moeda</label>
              <Select
                value={this.state.moeda}
                placeholder="Moeda"
                onChange={this.handleMoeda}
                sx={{ minWidth: 130, width: "100%" }}
                size="small"
              >
                <MenuItem value={"BRL"}>BRL</MenuItem>
                <MenuItem value={"USD"}>USD</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={2} sm={8} md={2.25}>
              <label>Prazo de Pagamento</label>
              <Select
                value={this.state.prazoPagamento}
                placeholder="Prazo de Pagamento"
                onChange={this.handleCondicaoComercial}
                sx={{ width: "100%" }}
                size="small"
              >
                {!this.props.encargoVendaExterna.isLoading &&
                  this.props.encargoVendaExterna.condicaoComercial.map((prazoPagamento) => (
                    <MenuItem
                      key={prazoPagamento.id}
                      value={prazoPagamento.condicaoComercial}
                    >
                      {prazoPagamento.condicaoComercial}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            <Grid item xs={2} sm={8} md={2.5}>
              <label style={{ marginBottom: "0" }}>Adicionar Produtos</label>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Select
                  value={this.state.produto}
                  placeholder="Produto"
                  onChange={this.handleChange}
                  sx={{ width: "100%" }}
                  size="small"
                >
                  {!this.props.produtosVendaExterna.isLoading &&
                    this.props.produtosVendaExterna.produtos.map((produto) => (
                      <MenuItem key={produto.id} value={produto}>
                        {produto.nome}
                      </MenuItem>
                    ))}
                </Select>
                <IconButton
                  color="primary"
                  size="large"
                  aria-label="Adicionar Produto"
                  disabled={!isProdutoSelecionado}
                  onClick={this.addProduct}
                >
                  <AddShoppingCartIcon />
                </IconButton>
              </Stack>
            </Grid>

            {this.props.simuladorVendaExterna.length > 0 && (
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "0 !important", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                  <Grid item xs={2} sm={1.5} md={2.5} sx={{ textAlign: { xs: "center", sm: "left" }, paddingTop: "1rem !important" }}>
                    <p>Cesta do Cliente</p>
                  </Grid>
                  <Grid item xs={2} sm={5.5} md={8} sx={{ textAlign: "center", paddingTop: "1rem !important" }}>
                    <p>(Informe os dados de Volume)</p>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={2} sm={8} md={12}>
              {this.props.simuladorVendaExterna.rows.length === 0 && (
                <p>Use a caixa de seleção acima para iniciar uma simulação</p>
              )}
              {this.props.simuladorVendaExterna.rows.length > 0 &&
                <TableContainer component={Paper}>
                  <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="custom pagination table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            th: {
                              fontWeight: "bold",
                              color: "white",
                            },
                            backgroundColor: "var(--cort-blue)"
                          }}
                        >
                          <TableCell align="center">Produto</TableCell>
                          <TableCell align="center">Preço à Vista</TableCell>
                          <TableCell align="center">Encargo</TableCell>
                          <TableCell align="center">Desconto</TableCell>
                          <TableCell align="center">Preço Corteva</TableCell>
                          <TableCell align="center">Volume</TableCell>
                          <TableCell align="center">Preço Negociado</TableCell>
                          {/* <TableCell align="center">Valor Final Corteva</TableCell> */}
                          <TableCell align="center">Valor Final</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" align="center">
                              {row.produto.nome}
                            </TableCell>
                            <TableCell align="center">
                              {
                                row.preco.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                                })
                              }
                            </TableCell>
                            <TableCell align="center">
                              {Number((row.encargo * 100)).toFixed(2).toLocaleString("pt-BR")}%
                            </TableCell>
                            <TableCell align="center">  {
                              row.desconto.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell>
                            <TableCell align="center">  {
                              row.precoFinal.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })}</TableCell>
                            <CellInputAlert
                              name="volume"
                              placeholder="Volume"
                              id="outlined-size-small"
                              value={row.volume}
                              onValueChange={(event) => this.handleChangeVolume(event, index)}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              customInput={TextField}
                              size="small"
                              sx={{ input: { textAlign: "right" } }}
                              maxValue={500000}
                              messageError={"Volume acima do permitido!"}
                              allowNegative={false}
                            />
                            <CellInputAlert
                              name="PrecoNegociado"
                              placeholder="Preço Negociado"
                              id="outlined-size-small"
                              value={row.precoNegociado}
                              onValueChange={(event) => this.handleChangePrecoNegociado(event, index)}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              customInput={TextField}
                              size="small"
                              sx={{ input: { textAlign: "right" } }}
                              maxValue={row.precoFinal}
                              messageError={"Preço negociado incorreto, favor revisar!"}
                              allowNegative={false}
                            />
                            {/* <TableCell align="center">{
                              row.valorTotal.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell> */}
                            <TableCell align="center">{
                              row.valorFinalNegociado.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: (this.state.moeda === "BRL" ? "BRL" : "USD"),
                              })
                            }</TableCell>
                            <TableCell align="center">
                              <IconButton aria-label="delete" size="small" onClick={() => this.handleClickOpen(1, index)}>
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                              <Dialog
                                open={this.state.open}
                                onClose={this.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {this.state.mensagem}
                                </DialogTitle>
                                <DialogActions>
                                  <Button onClick={this.handleClose}>Cancelar</Button>
                                  <Button variant="contained" onClick={this.handleDeleteRow} autoFocus>
                                    {this.state.botao}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ThemeProvider>
                </TableContainer >
              }
            </Grid>
          </Grid>


        </div>
      </div>
    );
  }

}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {

          ...clientTerritoryActions,
          ...precoVeActions,
          ...produtoveActions,
          ...simuladorVeEncargosActions,
          ...simuladorVeActions,
          ...userActions
        },
        dispatch
      ),
    };
  }
)(SimulatorPage);
