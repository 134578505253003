import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl, useMap } from 'react-map-gl';

export let drawRef = null;
export let mapRef = null;

export default function DrawControl(props) {
  const { current: map } = useMap();
  mapRef = map;

  drawRef = useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate, (e) => {});
      map.on('draw.delete', props.onDelete);
      map.on('draw.combine', props.onCombine);
      map.on('draw.uncombine', props.onUncombine);
    },
    ({ map }) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.delete', props.onDelete);
    },
    {
      position: props.position
    });

  return null;
}