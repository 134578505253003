import React from "react";

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { ListItem, Tooltip } from "@mui/material";
import Grid from '@mui/material/Grid';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function Row(props) {
    const { row, drawR, removeList, goToPolygon, showingStates } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} size="small">
                <TableCell sx={{ padding: "0" }} align="center">
                    <IconButton sx={{ padding: "0" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ fontSize: "0.7rem", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 6px" }}>
                    {!showingStates &&
                        row.properties.COD_IMOVEL
                    }
                    {showingStates &&
                        row.properties.Nome_Munic
                    }
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem", maxWidth: "75px", overflowWrap: "anywhere", padding: "6px 16px" }}>
                    {!showingStates &&
                        Number(Math.round(row.properties.NUM_AREA)).toLocaleString("pt-br") + " ha"
                    }
                    {showingStates &&
                        Number(Math.round(row.properties.Area)).toLocaleString("pt-br") + " ha"
                    }
                </TableCell>
                <TableCell align="center" sx={{ padding: "0" }}>
                    <IconButton
                        size="small"
                        onClick={() => removeList(row, true, drawR)}
                    >
                        <CloseIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" sx={{ 'td': { border: 'none' } }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            {!showingStates && (
                                                <>
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Múnicipio: " + row.properties.NOM_MUNICI} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Dono: " + ((row.properties.ACCOUNT != null && row.properties.ACCOUNT !== "") ? row.properties.ACCOUNT : "Sem Dono")} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Total de talhões: " + (row.properties.PLOT_COUNT != null ? Number(row.properties.PLOT_COUNT).toLocaleString("pt-br") : "Não Contabilizado")} />
                                                </>
                                            )}
                                            {showingStates && (
                                                <>
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Estado Sigla: " + (row.properties.SIGLA)} />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Total de Fazendas: " + Math.round(row.properties.ContagemFazendas).toLocaleString("pt-br")} />
                                                    <ListItemText
                                                        sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }}
                                                        primary={
                                                            "Fazendas Atribuídas: "
                                                            + (Math.round(row.properties.FazendasConhecidas)).toLocaleString("pt-br")
                                                            + " ("
                                                            + (Number((row.properties.FazendasConhecidas / row.properties.ContagemFazendas) * 100)).toFixed(1).toLocaleString("pt-br")
                                                            + "%)"}
                                                    />

                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Área Fazendas: " + (Math.round(row.properties.AreaTotalFazendas)).toLocaleString("pt-br") + " ha"} />
                                                    <ListItemText
                                                        sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }}
                                                        primary={
                                                            "Área Fazendas Atribuídas: "
                                                            + (Math.round(row.properties.AreaTotalFazendasConhecidas)).toLocaleString("pt-br")
                                                            + " ha ("
                                                            + (Number((row.properties.AreaTotalFazendasConhecidas / row.properties.AreaTotalFazendas) * 100)).toFixed(1).toLocaleString("pt-br")
                                                            + "%)"}
                                                    />
                                                    <ListItemText sx={{ ".MuiTypography-root": { fontSize: "0.7rem" } }} primary={"Área do Município: " + (Math.round(row.properties.Area).toLocaleString("pt-br") + " ha")} />
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={2}>
                                                <ListItem>
                                                    <Tooltip title="Centralizar no Polígono">
                                                        <IconButton aria-label="Centralizar no Polígono"
                                                            onClick={() => goToPolygon(row)}
                                                        >
                                                            <GpsFixedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItem>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

export default function MapPanel(props) {
    const { data, drawR, inEdit, saveArea, setEdit, remove, removePolygon, cancel, cleanList, goToPolygon, copySourceCodes, showingStates } = props;

    return (
        <React.Fragment>
            <TableContainer sx={{ borderRadius: "4px 4px 0px 0px" }}>
                <Table aria-label="collapsible table">
                    <TableHead sx={{ backgroundColor: "var(--cort-blue)" }}>
                        <TableRow>
                            <TableCell align="center" sx={{ padding: "0px" }}>
                                <Tooltip title={"Copiar códigos dos polígonos"}>
                                    <IconButton sx={{ padding: "0", color: "white" }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => copySourceCodes(false)}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 6px" }}>
                                Nome
                            </TableCell>
                            <TableCell align="right" sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 16px" }}>
                                Área Total
                            </TableCell>
                            <TableCell align="center" sx={{ fontFamily: "Gilroy", fontWeight: "Bold", color: "white", padding: "16px 16px" }}>
                                <Tooltip title={"Remover Todas Seleções"}>
                                    <IconButton sx={{ padding: "0", color: "white" }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => cleanList("data")}
                                    >
                                        <PlaylistRemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer sx={{ minHeight: "280px", maxHeight: "280px", borderRadius: "0px 0px 4px 4px" }}>
                <Table aria-label="collapsible table">
                    <TableBody>
                        {data && data.map((data, index) => (
                            <Row key={index}
                                row={data}
                                drawR={drawR}
                                inEdit={inEdit}
                                goToPolygon={goToPolygon}
                                removeList={remove}
                                cancel={cancel}
                                setEdit={setEdit}
                                saveArea={saveArea}
                                removePolygon={removePolygon}
                                showingStates={showingStates}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}