/* eslint-disable no-redeclare */
import { enrichConstants } from "../constants";

const initialState = [];

export const Safras = ["23_24", "24_25"];

export const CultureList = {
  "Soja": ["sojaGrao", "sojaSemente"],
  "MilhoVerao": ["milhoVeraoGrao", "milhoVeraoSilagem", "milhoVeraoSemente",],
  "MilhoSafrinha": ["milhoSafrinhaGrao", "milhoSafrinhaSilagem", "milhoSafrinhaSemente",],
  "SorgoVerao": ["sorgoVerao"],
  "SorgoSafrinha": ["sorgoSafrinha"],
  "AlgodaoVerao": ["algodaoVerao"],
  "AlgodaoSafrinha": ["algodaoSafrinha"],
  "GirassolVerao": ["girassolVerao"],
  "GirassolSafrinha": ["girassolSafrinha"],
  "GergelimVerao": ["gergelimVerao"],
  "GergelimSafrinha": ["gergelimSafrinha"],
};

export const CulturasIndex = [
  "Soja",
  "MilhoVerao",
  "AlgodaoVerao",
  "SorgoVerao",
  "GirassolVerao",
  "GergelimVerao",
  "MilhoSafrinha",
  "AlgodaoSafrinha",
  "SorgoSafrinha",
  "GirassolSafrinha",
  "GergelimSafrinha",
];
export function sumCultura(list) {
  var sums = {
    total: 0
  }

  list.forEach((entry) => {
    if (entry.carteira === true) {
      for (var s of Safras) {
        if (!sums[s]) {
          sums[s] = {}
        }
        if (!sums[s]["total"]) {
          sums[s]["total"] = 0;
        }

        for (var j in CultureList) {
          if (j) {
            if (!sums[s]["total" + j]) {
              sums[s]["total" + j] = 0
            }
            for (var i of CultureList[j]) {
              if (!sums[s]["total" + i]) {
                sums[s]["total" + i] = 0
              }
              try {
                var value = entry ? Number(
                  entry["culturaSafra"][s]["culturasInfo"][i]["value"].toLocaleString("pt-BR")
                    .replaceAll(".", "")
                    .replaceAll(",", ".") ?? 0
                ) : 0;
                sums[s]["total" + i] += value;
                sums[s]["total" + j] += value;
                sums[s]["total"] += value;
                sums.total += value;

              } catch (e) { }
            }
          }
        }
      }
    }
  });

  try {
    for (var s of Safras) {
      for (var j in CultureList) {
        if (j) {
          for (var i of CultureList[j]) {
            sums[s]["total" + i] = sums[s]["total" + i].toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
            });
          }
          sums[s]["total" + j] = sums[s]["total" + j].toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
          });
        }
      }
      sums[s]["total"] = sums[s]["total"].toLocaleString("pt-BR", {
        minimumFractionDigits: 0,
      });
    }
    sums.total = sums.total.toLocaleString("pt-BR", {
      minimumFractionDigits: 0,
    });
  } catch (e) { }

  return sums;
}


export async function save(dispatch, getState, row) {
  const Enriquecimentos = getState().enrichment.enrichment;
  const territorio = getState().user.territorio?.territoryId;

  const enrich = Enriquecimentos.find((b) => row.id === b.id);

  dispatch({ type: enrichConstants.requestSaveType });

  const { oidc } = getState();
  const url = `/api/Enriquecimentos?territorio=${territorio ?? ""}`;
  const options = {
    method: "Post",
    body: JSON.stringify(enrich),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${oidc.user.access_token}`,
    },
  };

  var respose = await fetch(url, options);

  dispatch({
    type: enrichConstants.receiveSaveType,
    date: new Date().toLocaleString("pt-BR"),
    sucess: respose.status === 200,
  });

  dispatch(enrichmentActions.requestProductionUnits());
  dispatch(enrichmentActions.requestPurchasePools());
}

export const enrichmentActions = {
  requestEnriquecer: (territoryId) => async (dispatch, getState) => {
    const { oidc } = getState();
    const territorio = getState().user.territorio?.territoryId;
    dispatch({ type: enrichConstants.requestEnrichType });

    const url = `/api/Enriquecimentos?territorio=${territoryId ? territoryId : territorio ? territorio : ""}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const enrichment = await response.json();

    dispatch({ type: enrichConstants.receiveEnrichType, enrichment });
  },
  requestPurchasePools: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enrichConstants.requestPurchasePoolsType });

    const url = `/api/Enriquecimentos/PurchasePools`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const PurchasePools = await response.json();
    dispatch({ type: enrichConstants.receivePurchasePoolsType, PurchasePools });
  },
  requestProductionUnits: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enrichConstants.requestProductionUnitsType });

    const url = `/api/Enriquecimentos/ProductionUnits`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ProductionUnits = await response.json();

    dispatch({
      type: enrichConstants.receiveProductionUnitsType,
      ProductionUnits,
    });
  },
  requestConsultorInfluenciador: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: enrichConstants.requestConsultorInfluenciadorType });

    const url = `/api/Enriquecimentos/ConsultorInfluenciador`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ConsultorInfluenciador = await response.json();

    dispatch({
      type: enrichConstants.receiveConsultorInfluenciadorType,
      ConsultorInfluenciador,
    });
  },
  createConsultorInfluenciador: (newData, row, territoryId, name, index) => async (dispatch, getState) => {
    const { oidc } = getState();
    const territorio = getState().user.territorio?.territoryId;

    dispatch({ type: enrichConstants.requestConsultorInfluenciadorType });

    const url = `/api/Enriquecimentos/ConsultorInfluenciador?territorio=${territoryId ? territoryId : territorio ? territorio : ""}`;
    newData["newAccountId"] = row.id;

    const options = {
      method: "POST",
      body: JSON.stringify(newData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ConsultorInfluenciadorId = await response.json();

    dispatch(enrichmentActions.requestConsultorInfluenciador());
    dispatch({ type: enrichConstants.changeValue, name, value: ConsultorInfluenciadorId, row, index });
  },
  handleChange: (e, row, index) => async (dispatch, getState) => {
    const { name, value } = e.target;
    dispatch({ type: enrichConstants.changeValue, name, value, row, index });
    save(dispatch, getState, row);
  },
  handleChangeNumeric: (e, row, index, safra) => async (dispatch, getState) => {
    var { name, value } = e.target;
    value = Number(value.replaceAll(".", ""));
    dispatch({ type: enrichConstants.changeNumberValue, name, value, row, index, safra });
    // save(dispatch, getState, row);
  },
  handleBlurNumeric: (e, row, index) => async (dispatch, getState) => {
    save(dispatch, getState, row);
  },

  handleChangeValues:
    (e, row, index, indexValues) => async (dispatch, getState) => {
      const { name, value } = e.target;
      dispatch({
        type: enrichConstants.changeValues,
        name,
        value,
        row,
        index,
        indexValues,
      });
    },

  handleChangeCheckbox: (e, row, index) => (dispatch, getState) => {
    const { name, checked } = e.target;
    const value = checked;
    dispatch({ type: enrichConstants.changeValue, name, value, row, index });
    dispatch({ type: enrichConstants.resetValues, name, value, row, index });
    save(dispatch, getState, row);
  },
  finalizar: (row, index) => (dispatch, getState) => {
    dispatch({ type: enrichConstants.endEnrich, row, index });
    save(dispatch, getState, row);
  },
  VoltarEmAndamento: (row, index) => (dispatch, getState) => {
    dispatch({ type: enrichConstants.backEnrich, row, index });
    save(dispatch, getState, row);
  },
};

export const reducer = (state = initialState, action) => {
  state = state || initialState;

  if (action.type === enrichConstants.endEnrich) {
    const row = action.row;

    const index = state.enrichment.findIndex((obj) => obj.id === row.id);
    row.status = 2;
    return {
      ...state,
      salvar: true,
      enrichment: [
        ...state.enrichment.slice(0, index),
        {
          ...row,
          status: 2,
        },
        ...state.enrichment.slice(index + 1),
      ],
    };
  }

  if (action.type === enrichConstants.backEnrich) {
    const row = action.row;

    const index = state.enrichment.findIndex((obj) => obj.id === row.id);
    row.status = 1;
    return {
      ...state,
      salvar: true,
      enrichment: [
        ...state.enrichment.slice(0, index),
        {
          ...row,
          status: 1,
        },
        ...state.enrichment.slice(index + 1),
      ],
    };
  }
  if (action.type === enrichConstants.changeValue) {
    // const index = action.index;
    const row = action.row;

    const index = state.enrichment.findIndex((obj) => obj.id === row.id);
    // const row = state.enrichment.find((b) => action.rowId === b.id);
    row[action.name] = action.value;
    row.status = 1;


    var totalHa = 0;

    if (action.name === "carteira" && !action.value) {
      for (var safra of Safras) {
        for (var j3 in CultureList) {
          if (j3) {
            for (var j of CultureList[j3]) {
              try {
                row["culturaSafra"][safra]["culturasInfo"][j]["value"] = "0";
              } catch (e) { }
            }
            row["culturaSafra"][safra]["totais"]["total" + j3] = "0";
          }
        }
        row["culturaSafra"][safra]["totalHa"] = "0";
      }
      row.totalHa = "0";
    }

    const totais = sumCultura(state.enrichment);

    return {
      ...state,
      salvar: true,
      ...totais,
      enrichment: [
        ...state.enrichment.slice(0, index),
        {
          ...row,
          status: 1,
          [action.name]: action.value,
        },
        ...state.enrichment.slice(index + 1),
      ],
    };
  }
  if (action.type === enrichConstants.changeNumberValue) {
    // const index = action.index;
    const row = action.row;

    const index = state.enrichment.findIndex((obj) => obj.id === row.id);
    // const row = state.enrichment.find((b) => action.rowId === b.id);
    if (!row.culturasInfo) {
      row.culturasInfo = {}
    }
    row["culturaSafra"][action.safra]["culturasInfo"][action.name]["value"] = action.value;
    row.status = 1;

    var totalHa = 0;
    for (var safra of Safras) {
      row["culturaSafra"][safra]["totalHa"] = 0
      for (var j2 in CultureList) {
        if (j2) {
          row["culturaSafra"][safra]["totais"]["total" + j2] = 0
          for (var i of CultureList[j2]) {
            try {
              var value = row ? Number(
                row["culturaSafra"][safra]["culturasInfo"][i]["value"].toLocaleString("pt-BR")
                  .replaceAll(".", "")
                  .replaceAll(",", ".") ?? 0
              ) : 0;
              row["culturaSafra"][safra]["totais"]["total" + j2] += value;
              row["culturaSafra"][safra]["totalHa"] += value;
              totalHa += value;

            } catch (e) { }
          }
        }
      }
    }


    const totais = sumCultura(state.enrichment);

    return {
      ...state,
      salvar: true,
      ...totais,
      enrichment: [
        ...state.enrichment.slice(0, index),
        {
          ...row,
          status: 1,
          totalHa: totalHa.toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
          }),
        },
        ...state.enrichment.slice(index + 1),
      ],
    };
  }

  if (action.type === enrichConstants.requestEnrichType) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === enrichConstants.receiveEnrichType) {
    const totais = sumCultura(action.enrichment);

    return {
      ...state,
      ...totais,
      enrichment: action.enrichment,
      isLoading: false,
    };
  }

  if (action.type === enrichConstants.receivePurchasePoolsType) {
    return {
      ...state,
      purchasePools: action.PurchasePools,
    };
  }

  if (action.type === enrichConstants.receiveProductionUnitsType) {
    return {
      ...state,
      productionUnits: action.ProductionUnits,
    };
  }
  if (action.type === enrichConstants.receiveConsultorInfluenciadorType) {
    return {
      ...state,
      consultorInfluenciador: action.ConsultorInfluenciador,
    };
  }
  if (action.type === enrichConstants.requestSaveType) {
    return {
      ...state,
      msgSave: "Salvando ...",
      salvar: false,
    };
  }

  if (action.type === enrichConstants.receiveSaveType) {
    return {
      ...state,
      msgSave: action.sucess
        ? `Salvo em ${action.date}`
        : `Oops tivemos para salvar ...`,
      salvar: false,
    };
  }

  return state;
};
