/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  DialogContent,
  FormControl,
  FormLabel,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import Virtualize from "../../AutocompleteVirtual";
import ProductTransferList from "./ProductTransferList";

function formatToCNPJ(srt) {
  return srt.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
}

function parseCNPJ(str) {
  return str.replace(/[^\d]/g, '');
}

const AddStockDialog = ({ confirmAction, channels, products, period }) => {
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [raiz, setRaiz] = React.useState("");
  const [cnpj, setCNPJ] = React.useState("");
  const [uf, setUF] = React.useState("");
  const [municipio, setMunicipio] = React.useState("");

  const [canal, setCanal] = React.useState("");
  const [produtos, setProdutos] = React.useState([]);

  const handleChangeName = (value) => {
    setName(value);
    setRaiz("");
    setCNPJ("");
    setUF("");
    setMunicipio("");
  };

  const handleChangeRaiz = (value) => {
    setRaiz(value);

    // if (period?.porRaiz) {
    //   const canal = channels.find(x => x.canal === name.canal && x.raizCNPJ === parseCNPJ(value));
    //   setCanal(canal);
    // }
  };

  const handleChangeUf = (value) => {
    setUF(value);
  };

  const handleChangeMunicipio = (value) => {
    setMunicipio(value);
    const canal = channels.find(x => x.canal === name.canal && x.cnpj === parseCNPJ(cnpj) && x.uf === uf && x.municipio === value);
    setCanal(canal);
  };

  const handleClose = () => {
    setName("");
    setRaiz("");
    setCNPJ("");
    setUF("");
    setMunicipio("");

    setCanal("");
    setProdutos([]);
    setOpen(false);
  };

  let raizes = channels.filter(x => x.canal === name.canal).map(x => x.raizCNPJ).sort((a, b) => a.localeCompare(b));
  raizes = [...new Set(raizes)];

  let cnpjs = raiz.length > 0 ? channels.filter(x => x.cnpj.includes(raiz)).map(x => x.cnpj).sort((a, b) => a.localeCompare(b)) : [];
  cnpjs = [...new Set(cnpjs)];

  let ufs = cnpjs.length > 0 ? channels.filter(x => x.cnpj === parseCNPJ(cnpj)).map(x => x.uf).sort((a, b) => a.localeCompare(b)) : [];
  ufs = [...new Set(ufs)];

  let municipios = ufs.length > 0 ? channels.filter(x => x.uf === uf && x.cnpj === parseCNPJ(cnpj)).map(x => x.municipio).sort((a, b) => a.localeCompare(b)) : [];
  municipios = [...new Set(municipios)];

  useEffect(() => {
    if (raizes.length === 1) {
      handleChangeRaiz(raizes[0]);
    }

    if (cnpjs.length === 1) {
      setCNPJ(cnpjs[0]);
    }

    if (ufs.length === 1) {
      setUF(ufs[0]);
    }

    if (municipios.length === 1) {
      handleChangeMunicipio(municipios[0]);
    }
  }, [channels, raizes, cnpjs, ufs, municipios]);

  const uniqueNames = [...new Set(channels.map(x => x.canal))];
  const uniqueChannels = uniqueNames.map(canal => {
    const channel = channels.find(x => x.canal === canal);
    return channel;
  });

  return (
    <React.Fragment>
      <Tooltip title='Adicionar Informações'>
        <IconButton
          sx={{
            color: "white",
            backgroundColor: "var(--cort-blue)",
            borderRadius: "8px",
            '&:hover': {
              color: "var(--cort-blue)",
              backgroundColor: "white"
            }
          }}
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          ".MuiDialog-paper": {
            maxWidth: "60%",
          },
        }}
      >
        <DialogTitle>Adicionar Informações de Canal/Produto</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={period?.porRaiz ? 7 : 9}>
              <FormControl fullWidth>
                <FormLabel>Canais</FormLabel>
                <Virtualize
                  sx={{
                    color: "black",
                    width: "100%",
                    height: "100%",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    div: {
                      margin: "0px !important",
                    },
                  }}
                  value={name?.canal}
                  options={uniqueChannels.sort((a, b) => a.canal.localeCompare(b.canal))}
                  noOptionsText="Canal..."
                  getOptionLabel={(option) => option.canal}
                  getValue={(option) => option.canal}
                  onChange={(event, value) => handleChangeName(value)}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Canal..."
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                ></Virtualize>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <FormLabel>Raiz CNPJ</FormLabel>
                <Virtualize
                  sx={{
                    color: "black",
                    width: "100%",
                    height: "100%",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    div: {
                      margin: "0px !important",
                    },
                  }}
                  value={raiz}
                  options={raizes}
                  noOptionsText="Raiz..."
                  getOptionLabel={(option) => option}
                  getValue={(option) => option}
                  onChange={(event, value) => handleChangeRaiz(value)}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Raiz..."
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                ></Virtualize>
              </FormControl>
            </Grid>
            {!period?.porRaiz && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FormLabel>CNPJ</FormLabel>
                  <Virtualize
                    sx={{
                      color: "black",
                      width: "100%",
                      height: "100%",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      div: {
                        margin: "0px !important",
                      },
                    }}
                    value={cnpj}
                    options={cnpjs.map(x => formatToCNPJ(x))}
                    noOptionsText="CNPJ..."
                    getOptionLabel={(option) => formatToCNPJ(option)}
                    getValue={(option) => option}
                    onChange={(event, value) => setCNPJ(value)}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="CNPJ..."
                        variant="outlined"
                        margin="dense"
                      />
                    )}
                  ></Virtualize>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={2}>
              <FormControl fullWidth>
                <FormLabel>UF</FormLabel>
                <Virtualize
                  sx={{
                    color: "black",
                    width: "100%",
                    height: "100%",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    div: {
                      margin: "0px !important",
                    },
                  }}
                  value={uf}
                  options={ufs}
                  noOptionsText="UF..."
                  getOptionLabel={(option) => option}
                  getValue={(option) => option}
                  onChange={(event, value) => handleChangeUf(value)}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="UF..."
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                ></Virtualize>
              </FormControl>
            </Grid>
            {!period?.porRaiz && (
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel>Município</FormLabel>
                  <Virtualize
                    sx={{
                      color: "black",
                      width: "100%",
                      height: "100%",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      div: {
                        margin: "0px !important",
                      },
                    }}
                    value={municipio}
                    options={municipios}
                    noOptionsText="Município..."
                    getOptionLabel={(option) => option}
                    getValue={(option) => option}
                    onChange={(event, value) => handleChangeMunicipio(value)}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Município..."
                        variant="outlined"
                        margin="dense"
                      />
                    )}
                  ></Virtualize>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Produtos</FormLabel>
                <ProductTransferList
                  products={products}
                  right={produtos}
                  setRight={setProdutos}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancelar</Button>
          <Button
            variant={"contained"}
            disabled={canal?.length <= 0}
            onClick={() => {
              confirmAction({ canal, produtos });
              handleClose();
            }}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddStockDialog;
