const requestFornecedorType = "REQUEST_FORNECEDOR";
const receiveFornecedorType = "RECEIVE_FORNECEDOR";
const requestGrupoMaterialType = "REQUEST_GRUPOMATERIAL";
const receiveGrupoMaterialType = "RECEIVE_GRUPOMATERIAL";
const requestFornecedoresType = "REQUEST_FORNECEDORES";
const receiveFornecedoresType = "RECEIVE_FORNECEDORES";
const requestProdutoType = "REQUEST_PRODUTO";
const receiveProdutoType = "RECEIVE_PRODUTO";
const requestDeleteFornecedor = "REQUEST_DELETE_FORNECEDOR";
const receiveDeleteFornecedor = "RECEIVE_DELETE_FORNECEDOR";
const requestDisponibilidadesType = "REQUEST_DISPONIBILIDADE";
const receiveDisponibilidadesType = "RECEIVE_DISPONIBILIDADE";
const requestDeleteGrupoMaterial = "REQUEST_DELETE_GRUPOMATERIAL";
const receiveDeleteGrupoMaterial = "RECEIVE_DELETE_GRUPOMATERIAL";
const requestEncargosType = "REQUEST_ENCARGOS";
const receiveEncargosType = "RECEIVE_ENCARGOS";
const requestDeleteEncargo = "REQUEST_DELETE_ENCARGO";
const receiveDeleteEncargo = "RECEIVE_DELETE_ENCARGO";
const requestUserType = "REQUEST_USER";
const receiveUserType = "RECEIVE_USER";
const requestProdutosType = "REQUEST_PRODUTOS";
const receiveProdutosType = "RECEIVE_PRODUTOS";
const requestCriarDisponibilidadeType = "REQUEST_CRIAR_DISPONIBILIDADE";
const receiveCriarDisponibilidadeType = "RECEIVE_CRIAR_DISPONIBILIDADE";
const requestCriarPrecoType = "REQUEST_CRIAR_PRECO";
const receiveCriarPrecoType = "RECEIVE_CRIAR_PRECO";
const addRow = "SIMULADOR_VE_ADD_ROW";
const deleteRow = "SIMULADOR_VE_DELETE_ROW";
const requestSaveDisponibilidade = "REQUEST_SAVE_DISPONIBILIDADE";
const receiveSaveDisponibilidade = "RECEIVE_SAVE_DISPONIBILIDADE";
const removeAllRows = "REMOVE_ALL_ROW";
const requestDeleteProduto = "REQUEST_DELETE_PRODUTO";
const receiveDeleteProduto = "RECEIVE_DELETE_PRODUTO";
const requestLoadDisponibilidade = "REQUEST_LOAD_DISPONIBILIDADE";
const receiveLoadDisponibilidade = "RECEIVE_LOAD_DISPONIBILIDADE";
const requestUpdateDisponibilidade = "REQUEST_UPDATE_DISPONIBILIDADE";
const receiveUpdateDisponibilidade = "RECEIVE_UPDATE_DISPONIBILIDADE";
const requestDeleteDisponibilidade = "REQUEST_DELETE_DISPONIBILIDADE";
const receiveDeleteDisponibilidade = "RECEIVE_DELETE_DISPONIBILIDADE";

const initialState = {
  fornecedor: [],
  isLoadingFornecedor: false,
  fornecedores: [],
  grupoMaterial: [],
  produto: [],
  isLoadingProduto: false,
  disponibilidades: [],
  disponibilidade: [],
  encargos: [],
  produtos: [],
  isLoadingCriarDisponibilidade: false,
  isLoadingCriarPreco: false,
  rows: [],
};

const CreateRow = (produtoId, produtos, areaComercial, distrito, territorio, preco, descontoMaximo, moeda) => {

  //"This.produtos:", produtos);
  return {
    ProdutoSimuladorVendaExternaId: produtoId,
    produto: produtos,
    disponibilidadeId: 0,
    areaComercial: areaComercial,
    distrito: distrito,
    territorio: territorio,
    preco: preco,
    descontoMaximo: descontoMaximo,
    moeda: moeda,
    acesso: " ",
    status: true,
    source: "source"

  }
};
export const adminSimuladorVeActions = {
  clearFornecedor: () => async (dispatch, getState) => {
    dispatch({ type: receiveFornecedorType, fornecedor: null });
  },
  clearProduto: () => async (dispatch, getState) => {
    dispatch({ type: receiveProdutoType, produto: null });
  },
  saveFornecedor: (fornecedor, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();
    // console.log("Fornecedor:", fornecedor);

    dispatch({ type: requestFornecedorType });

    const url = `/api/produtoVendaExterna/createFornecedor`;

    const options = {
      method: "POST",
      body: JSON.stringify(fornecedor),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin-Fornecedores');
      dispatch({ type: receiveFornecedorType, fornecedor: null });
      //dispatch(adminUserActions.requestUsers());
    }
  },
  // saveProduto: (produto, navigate) => async (dispatch, getState) => {
  //   const { oidc } = getState();
  //   console.log("Produto:", produto);

  //   dispatch({ type: requestProdutoType });

  //   const url = `/api/produtoVendaExterna/createProduto`;

  //   const options = {
  //     method: "POST",
  //     body: JSON.stringify(produto),
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${oidc.user.access_token}`,
  //     },
  //   };

  //   const response = await fetch(url, options);
  //   if (response.ok) {
  //     // const user = await response.json();
  //     navigate('/Admin-SimuladorVendaExterna');
  //     dispatch({ type: receiveProdutoType, produto: null });
  //     //dispatch(adminUserActions.requestUsers());
  //   }
  // },
  requestFornecedores: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestFornecedoresType });

    const url = `/api/admin/simuladorVe/fornecedores`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const fornecedores = await response.json();
    // console.log("fornecedores", fornecedores);
    dispatch({ type: receiveFornecedoresType, fornecedores });
  },
  requestProduct: (id) => async (dispatch, getState) => {
    const { oidc } = getState();
    //"produtoiD:", id);

    dispatch({ type: requestProdutoType });

    const url = `/api/admin/simuladorVe/produto/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const produto = await response.json();
    // console.log("produto", produto);
    dispatch({ type: receiveProdutoType, produto });
  },
  requestProdutos: () => async (dispatch, getState) => {
    const { oidc } = getState();


    dispatch({ type: requestProdutosType });

    const url = `/api/admin/simuladorVe/produtos`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const produtos = await response.json();
    //console.log("produtos simuladorve", produtos);
    dispatch({ type: receiveProdutosType, produtos });
  },
  updateProduto: (produtoId, productEdit, navigate) => async (dispatch, getState) => {

    // console.log("produto: ", produtoId, productEdit);
    const { oidc } = getState();

    dispatch({ type: requestProdutoType });

    const url = `/api/admin/simuladorVe/updateProduto/${produtoId}/${productEdit.nome}/${productEdit.fornecedor}/${productEdit.grupoMaterial}`;

    const options = {
      method: "PUT",
      //body: JSON.stringify(fornecedorEdit),
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin-Produtos')
      dispatch({ type: receiveProdutoType, produto: null });
    }
  },
  deleteProduto: (produtoId) => async (dispatch, getState) => {
    //console.log(produtoId);
    const { oidc } = getState();
    dispatch({ type: requestDeleteProduto });

    const url = `/api/admin/simuladorVe/deleteProduto/${produtoId}`;
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receiveDeleteProduto, produtoId });
    }
  },
  requestFornecedor: (id) => async (dispatch, getState) => {
    const { oidc } = getState();
    // console.log("fORNECEDORiD:", id);

    dispatch({ type: requestFornecedorType });

    const url = `/api/admin/simuladorVe/fornecedor/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const fornecedor = await response.json();
    // console.log("fornecedor", fornecedor);
    dispatch({ type: receiveFornecedorType, fornecedor });
  },
  requestGrupoMateriais: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGrupoMaterialType });

    const url = `/api/admin/simuladorVe/grupoMaterial`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const grupoMaterial = await response.json();
    // console.log("requestgrupoMaterial", grupoMaterial);
    dispatch({ type: receiveGrupoMaterialType, grupoMaterial });
  },
  requestGrupoMaterial: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGrupoMaterialType });

    const url = `/api/admin/simuladorVe/grupoMaterial/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const grupoMaterial = await response.json();
    // console.log("requestgrupoMaterial", grupoMaterial);
    dispatch({ type: receiveGrupoMaterialType, grupoMaterial });
  },

  deleteFornecedor: (fornecedorId) => async (dispatch, getState) => {
    // console.log(fornecedorId);
    const { oidc } = getState();
    dispatch({ type: requestDeleteFornecedor });

    const url = `/api/admin/simuladorVe/deleteFornecedor/${fornecedorId}`;
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receiveDeleteFornecedor, fornecedorId });
    }
  },
  updateFornecedor: (fornecedorId, fornecedorEdit, navigate) => async (dispatch, getState) => {

    // console.log("fornecedor: ", fornecedorId, fornecedorEdit);
    const { oidc } = getState();

    dispatch({ type: requestFornecedorType });

    const url = `/api/admin/simuladorVe/updateFornecedor/${fornecedorId}/${fornecedorEdit.nome}/${fornecedorEdit.cnpj}`;

    const options = {
      method: "PUT",
      //body: JSON.stringify(fornecedorEdit),
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin-Fornecedores')
      dispatch({ type: receiveFornecedorType, fornecedor: null });
    }
  },
  requestDisponilidades: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestDisponibilidadesType });

    const url = `/api/admin/simuladorVe/disponibilidades`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const disponibilidades = await response.json();
    // console.log("disponibilidades", disponibilidades);
    dispatch({ type: receiveDisponibilidadesType, disponibilidades });
  },
  updateGrupoMaterial: (grupoMaterialId, grupoMaterialEdit, navigate) => async (dispatch, getState) => {

    // console.log("fornecedor: ", grupoMaterialId, grupoMaterialEdit);
    const { oidc } = getState();

    dispatch({ type: requestGrupoMaterialType });

    const url = `/api/admin/simuladorVe/updateGrupoMaterial/${grupoMaterialId}/${grupoMaterialEdit.nome}`;

    const options = {
      method: "PUT",
      //body: JSON.stringify(fornecedorEdit),
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin-GrupoMaterial')
      dispatch({ type: receiveGrupoMaterialType, grupoMaterial: null });
    }
  },
  saveGrupoMaterial: (grupoMaterial, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();
    // console.log("grupoMaterial:", grupoMaterial);

    dispatch({ type: requestGrupoMaterialType });

    const url = `/api/produtoVendaExterna/createGrupoMaterial`;

    const options = {
      method: "POST",
      body: JSON.stringify(grupoMaterial),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin-GrupoMaterial');
      dispatch({ type: receiveGrupoMaterialType, grupoMaterial: null });
      //dispatch(adminUserActions.requestUsers());
    }
  },
  deleteGrupoMaterial: (grupoMaterialId) => async (dispatch, getState) => {
    //console.log(grupoMaterialId);
    const { oidc } = getState();
    dispatch({ type: requestDeleteGrupoMaterial });

    const url = `/api/admin/simuladorVe/deleteGrupoMaterial/${grupoMaterialId}`;
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receiveDeleteGrupoMaterial, grupoMaterialId });
    }
  },
  requestEncargos: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargosType });

    const url = `/api/admin/simuladorVe/encargos`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const encargos = await response.json();
    dispatch({ type: receiveEncargosType, encargos });
  },
  requestEncargo: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargosType });

    const url = `/api/admin/simuladorVe/encargo/${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const encargos = await response.json();
    dispatch({ type: receiveEncargosType, encargos });
  },
  updateEncargo: (encargoId, encargoEdit, navigate) => async (dispatch, getState) => {

    const { oidc } = getState();

    dispatch({ type: requestEncargosType });

    const url = `/api/admin/simuladorVe/updateEncargo/${encargoId}`;

    const options = {
      method: "PUT",
      body: JSON.stringify(encargoEdit),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      //const encargos = await response.json();
      navigate('/Admin-Encargos')
      dispatch({ type: receiveEncargosType, encargos: null });
    }
  },
  saveEncargo: (encargo, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestEncargosType });

    const url = `/api/admin/simuladorVe/createEncargo`;

    const options = {
      method: "POST",
      body: JSON.stringify(encargo),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin-Encargos');
      dispatch({ type: receiveEncargosType, encargos: null });
      //dispatch(adminUserActions.requestUsers());
    }
  },
  deleteEncargo: (encargoId) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestDeleteEncargo });

    const url = `/api/admin/simuladorVe/deleteEncargo/${encargoId}`;
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receiveDeleteEncargo, encargoId });
    }
  },
  saveProduto: (produto, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestUserType });

    const {
      nome,
      fornecedor,
      grupoMaterial,
    } = produto;

    const produtoSend = {
      id: 0,
      nome,
      fornecedorId: fornecedor,
      grupoMaterialId: grupoMaterial,
      source: "source",
      status: true,
      dataCriacao: new Date(),
      dataAtualizacao: new Date(),
    }

    const url = `/api/admin/simuladorVe/createProduto`;

    const options = {
      method: "POST",
      body: JSON.stringify(produtoSend),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin-Produtos');
      dispatch({ type: receiveUserType, produto: null });
      //dispatch(adminUserActions.requestUsers());
    }
  },
  requestProdutosByFornecedorGrupo: (fornecedor, grupoMaterial) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestProdutosType });

    const url = `/api/admin/simuladorVe/produtosByFornecedorGrupo/${fornecedor}/${grupoMaterial}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const produtos = await response.json();
    dispatch({ type: receiveProdutosType, produtos });
  },
  saveDisponibilidade: (disponibilidade, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCriarDisponibilidadeType });

    const url = `/api/admin/simuladorVe/createDisponibilidade`;

    const options = {
      method: "POST",
      body: JSON.stringify(disponibilidade),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // navigate('/Admin-Precos');
      dispatch({ type: receiveCriarDisponibilidadeType, disponibilidade: null });
    }
  },

  savePreco: (preco, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestCriarPrecoType });

    const url = `/api/admin/simuladorVe/createPreco`;

    const options = {
      method: "POST",
      body: JSON.stringify(preco),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      navigate('/Admin-Precos');
      dispatch({ type: receiveCriarPrecoType, preco: null });
    }
  },

  addRow: (produto, listaProdutos, areaComercial, distrito, territorio, preco, descontoMaximo, moeda) =>
    async (dispatch, getState) => {
      if (produto) {

        const produtos = listaProdutos.find((x) => x.id === produto);
        const row = CreateRow(produto, produtos, areaComercial, distrito, territorio, preco, descontoMaximo, moeda);

        dispatch({ type: addRow, row: row, });
      }
    },
  deleteRow: (index) => async (dispatch, getState) => {
    dispatch({
      type: deleteRow,
      index: index,
    });
  },
  loadDisponibilidade: (disponibilidadeId) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestLoadDisponibilidade });

    const url = `/api/admin/simuladorVe/disponibilidade/${disponibilidadeId}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro desconhecido');
      }
      const disponibilidade = await response.json();


      const rows = disponibilidade.precosSimuladorVendaExterna.map(item => {
        const produto = item.produtoSimuladorVendaExterna;
        return CreateRow(produto.id, produto, item.areaComercial, item.distrito, item.territorio, item.preco, item.descontoMaximo, item.moeda);
      });

      dispatch({ type: receiveLoadDisponibilidade, rows, disponibilidade });
    } catch (error) {
      console.error('Erro ao carregar disponibilidade:', error);
    }
  },
  updateDisponibilidade: (disponibilidadeId, fornecedor, grupoMaterial, rows, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestUpdateDisponibilidade });

    const url = `/api/admin/simuladorVe/atualizarDisponibilidade/${disponibilidadeId}/${fornecedor}/${grupoMaterial}`;
    const options = {
      method: "PUT",
      body: JSON.stringify(rows),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro desconhecido');
      }
      const updatedData = await response.json();
      navigate('/Admin-Precos');
      dispatch({ type: receiveUpdateDisponibilidade, updatedData });
    } catch (error) {
      console.error('Erro ao atualizar cenário:', error);
      // dispatch({ type: updateCenarioFailure, error });
    }
  },

  createSaveDisponibilidade: (fornecedor, grupoMaterial, rows, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();
    var rowss = rows.map((x, index) => { x.ordem = index; return x });

    dispatch({ type: requestSaveDisponibilidade });

    const url = `/api/admin/simuladorVe/savePrecos/${fornecedor}/${grupoMaterial}`;

    const options = {
      method: "POST",
      body: JSON.stringify(rowss),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro desconhecido');
      }
      const SaveDisponibilidadeItems = await response.json();
      navigate('/Admin-Precos');
      dispatch({ type: receiveSaveDisponibilidade, SaveDisponibilidadeItems });
    } catch (error) {
      console.error('Erro ao salvar preços:', error);
    }

  },
  removeAllRows: () => async (dispatch, getState) => {
    dispatch({ type: removeAllRows });
  },

  deleteDisponibilidade: (disponibilidadeId) => async (dispatch, getState) => {
    const { oidc } = getState();
    dispatch({ type: requestDeleteDisponibilidade });

    const url = `/api/admin/simuladorVe/deleteDisponibilidade/${disponibilidadeId}`;
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      dispatch({ type: receiveDeleteDisponibilidade, disponibilidadeId });
    }
  },


};

export const reducer = (state, action) => {
  state = state || initialState;
  if (action.type === requestFornecedorType) {
    return {
      ...state,
      isLoadingFornecedor: true,
    };
  }
  if (action.type === receiveFornecedorType) {
    return {
      ...state,
      fornecedor: action.fornecedor,
      isLoadingFornecedor: false,
    };
  }
  if (action.type === requestFornecedoresType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveFornecedoresType) {
    return {
      ...state,
      fornecedores: action.fornecedores,
      isLoading: false,
    };
  }
  if (action.type === requestGrupoMaterialType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveGrupoMaterialType) {
    return {
      ...state,
      grupoMaterial: action.grupoMaterial,
      isLoading: false,
    };
  }
  if (action.type === requestProdutoType) {
    return {
      ...state,
      isLoadingProduto: true,
    };
  }
  if (action.type === receiveProdutoType) {
    return {
      ...state,
      produto: action.produto,
      isLoadingProduto: false,
    };
  }
  if (action.type === requestDeleteFornecedor) {
    return {
      ...state,
      isLoadingFornecedor: true,
    };
  }

  if (action.type === receiveDeleteFornecedor) {
    return {
      ...state,
      fornecedores: state.fornecedores.filter((x) => x.id !== action.fornecedorId),
      isLoadingFornecedor: false,
    };
  }
  if (action.type === requestDisponibilidadesType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveDisponibilidadesType) {
    return {
      ...state,
      disponibilidades: action.disponibilidades,
      isLoading: false,
    };
  }
  if (action.type === requestDeleteGrupoMaterial) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveDeleteGrupoMaterial) {
    return {
      ...state,
      grupoMaterial: state.grupoMaterial.filter((x) => x.id !== action.grupoMaterialId),
      isLoading: false,
    };
  }
  if (action.type === requestEncargosType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveEncargosType) {
    return {
      ...state,
      encargos: action.encargos,
      isLoading: false,
    };
  }
  if (action.type === requestDeleteEncargo) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveDeleteEncargo) {
    return {
      ...state,
      encargos: state.encargos.filter((x) => x.id !== action.encargoId),
      isLoading: false,
    };
  }
  if (action.type === requestUserType) {
    return {
      ...state,
      isLoadingUser: true,
    };
  }
  if (action.type === receiveUserType) {
    return {
      ...state,
      user: action.user,
      isLoadingUser: false,
    };
  }
  if (action.type === requestProdutosType) {
    return {
      ...state,
      isLoadingProdutos: true,
    };
  }
  if (action.type === receiveProdutosType) {
    return {
      ...state,
      produtos: action.produtos,
      isLoadingProdutos: false,
    };
  } if (action.type === requestFornecedorType) {
    return {
      ...state,
      isLoadingFornecedor: true,
    };
  }
  if (action.type === receiveFornecedorType) {
    return {
      ...state,
      fornecedor: action.fornecedor,
      isLoadingFornecedor: false,
    };
  }
  if (action.type === addRow) {
    return {
      ...state,
      rows: [...state.rows, action.row],
      isLoading: false,
    };
  }
  if (action.type === requestCriarDisponibilidadeType) {
    return {
      ...state,
      isLoadingCriarDisponibilidade: true,
    };
  }
  if (action.type === receiveCriarDisponibilidadeType) {
    return {
      ...state,
      isLoadingCriarDisponibilidade: false,
    };
  }
  if (action.type === requestCriarPrecoType) {
    return {
      ...state,
      isLoadingCriarPreco: true,
    };
  }
  if (action.type === receiveCriarPrecoType) {
    return {
      ...state,
      isLoadingCriarPreco: false,
    };
  }
  if (action.type === deleteRow) {
    return {
      ...state,
      rows: state.rows.filter((item, index) => index !== action.index),
      isLoading: false,
    };
  }
  if (action.type === receiveSaveDisponibilidade) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === removeAllRows) {
    return {
      ...state,
      rows: state.rows.splice(),
      isLoading: false,
    };
  }
  if (action.type === requestDeleteProduto) {
    return {
      ...state,
      isLoadingProdutos: true,
    };
  }

  if (action.type === receiveDeleteProduto) {
    return {
      ...state,
      produtos: state.produtos.filter((x) => x.id !== action.produtoId),
      isLoadingProdutos: false,
    };
  }
  if (action.type === requestLoadDisponibilidade) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveLoadDisponibilidade) {
    return {
      ...state,
      rows: action.rows,
      disponibilidade: action.disponibilidade,
      isLoading: false,
    };
  }
  if (action.type === requestUpdateDisponibilidade) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveUpdateDisponibilidade) {
    return {
      ...state,
      rows: state.rows.map((item) =>
        item.id === action.updatedData.id ? action.updatedData : item
      ),
      isLoading: false,
    };
  }
  if (action.type === requestDeleteDisponibilidade) {
    return {
      ...state,
      isLoadingDisponibilidade: true,
    };
  }

  if (action.type === receiveDeleteDisponibilidade) {
    return {
      ...state,
      disponibilidades: state.disponibilidades.filter((x) => x.id !== action.disponibilidadeId),
      isLoading: false,
    };
  }


  return state;
};
