import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { NumericFormat } from "react-number-format";
// import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    // maxWidth: 220,
    border: 'none',
  },
}));

export const CellInputAlert = ({ messageError, maxValue, ...props }) => {
  // const { messageError, maxValue } = props;
  const [open, setOpen] = React.useState(false);

  const isAllowed = (value, max) => {
    var number = Number(value.value)

    if (number > max) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return number <= max;
  };

  const handleClose = () => {

    setOpen(false);
  };

  return (<HtmlTooltip placement="right" leaveDelay={600} open={open} onClose={handleClose}
    title={
      <React.Fragment>
        <Alert severity="error">
          {messageError}
        </Alert>
      </React.Fragment>
    }
  >
    <TableCell align="center">
      <NumericFormat
        {...props}
        isAllowed={(values, sourceInfo) => isAllowed(values, maxValue)}
      />
    </TableCell>
  </HtmlTooltip>
  );
}

const invertSignal = (value = 0) => {
  return value * -1;
}

export default function SimulatorTableEditable(props) {
  const { state, actions } = props
  const [openDialog, setOpenDialog] = React.useState(false);
  const [event, setEvent] = React.useState("");
  const [line, setLine] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [motivo, setMotivo] = React.useState("");
  const [produto, setProduto] = React.useState("");

  const handleChangeMotivo = (e) => {
    setMotivo(e.target.value);
  }

  const handleClickOpenDialog = (e, i, title, motivo) => {
    setProduto(title)
    setMotivo(motivo);
    setEvent(e);
    setLine(i);
    setTitle(title);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMotivo("");
    setProduto("");
  };

  const handleRemove = () => {
    if (motivo.replaceAll(" ", "").length >= 20) {
      actions.actions.removeRow(event, line, title, motivo);
      setOpenDialog(false);
      setMotivo("");
      setProduto("");
    }
  };

  const rows = actions.simulators.map(x => {
    x.max = x.price * 2
    return x
  });

  actions.actions.buscaCombo(
    rows.filter((row) => !(row.excluido !== undefined && row.excluido)),
    actions.packdiscounts.descontopacks,
    actions.produtoPacks.produtopacks,
    actions.packs.packs,
    state.currency
  );

  return (
    <TableContainer component={Paper}>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Deseja Realmente remover?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escreva o motivo da remoção do produto <b>{produto}</b> recomendado para o Cliente:
          </DialogContentText>
          <TextField
            autoFocus
            value={motivo}
            onChange={handleChangeMotivo}
            margin="dense"
            id="name"
            label="Justificativa*"
            fullWidth
            variant="standard"
            error={motivo.replaceAll(" ", "").length < 20}
            helperText={motivo.replaceAll(" ", "").length < 20 ? `Quantidade de Caracteres: ${motivo.replaceAll(" ", "").length} de 20` : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleRemove}>Remover</Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={theme}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow
              sx={{
                th: {
                  fontWeight: "bold",
                },
              }}
            >
              <TableCell align="center">Produto</TableCell>
              <TableCell align="center">Preço</TableCell>
              <TableCell align="center">Encargos</TableCell>
              <TableCell align="center">Desconto Categoria</TableCell>
              <TableCell align="center">Hectares tratados</TableCell>
              <TableCell align="center">Combo Area</TableCell>
              <TableCell align="center">Desconto Packs</TableCell>
              <TableCell align="center">Valor Final Corteva</TableCell>
              <TableCell align="center">Volume</TableCell>
              <TableCell align="center">Preço Negociado</TableCell>
              <TableCell align="center">Flexibilidade</TableCell>
              {/*<TableCell align="center">Flexibilidade Marketing</TableCell>*/}
              <TableCell align="center">Ajuste</TableCell>
              <TableCell align="center">Valor Final</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.filter((row) => !(row.excluido !== undefined && row.excluido)).map((row, i) => {
              //var descontopack = (row.price + (row.price * (row.embargoes / 100))) * (row.packsDiscount / 100);
              var imagem = actions.produtos.products.find(x => x.productId === row.produtoId);
              return (
                <TableRow key={row.title}>
                  <TableCell>
                    {imagem?.imagemProduto ? <img alt={row.title} src={imagem.imagemProduto} height="50" style={{ maxWidth: "150px" }} /> : row.title}
                  </TableCell>

                  <TableCell align="right">
                    {
                      row.price.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (state.currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {Number(row.embargoes === undefined ? 0 : row.embargoes).toLocaleString("pt-BR")}%
                  </TableCell>
                  <TableCell align="right">
                    {invertSignal(row.categoryDiscount * 100)}%
                  </TableCell>
                  <TableCell align="right">
                    {(Number(row.hectaresTratados)).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {(Number.isInteger(row.comboArea) ? row.comboArea : 0).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {
                      /*descontopack.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (state.currency === "BRL" ? "BRL" : "USD"),
                      })*/`${invertSignal(row.packsDiscount)}%`
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.cortevaFinalPrice.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (state.currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <CellInputAlert
                    name="volume"
                    placeholder="Volume"
                    id="outlined-size-small"
                    value={row.volume}
                    onValueChange={(values, sourceInfo) => actions.actions.handleChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }, i, row.title, state.currency)}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    customInput={TextField}
                    size="small"
                    sx={{ input: { textAlign: "right" } }}
                    maxValue={500000}
                    messageError={"Volume acima do permitido!"}
                    allowNegative={false}
                  />
                  <CellInputAlert
                    name="negotiatedPrice"
                    placeholder="Preço Negociado"
                    value={row.negotiatedPrice}
                    onValueChange={(values, sourceInfo) => actions.actions.handleChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }, i, row.title, state.currency)}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    size="small"
                    sx={{ input: { textAlign: "right" } }}
                    maxValue={row.max}
                    messageError={"Preço negociado incorreto, favor revisar!"}
                    allowNegative={false}
                  />

                  <TableCell align="right">
                    {invertSignal(row.flexibilidade).toFixed(2).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                    %
                  </TableCell>

                  {/*<TableCell align="right">
                    {row.flexibilidadeMkt.toFixed(3).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                    %
                  </TableCell>*/}

                  <TableCell align="right">
                    {
                      row.ajuste.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (state.currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>

                  <TableCell align="right">
                    {
                      row.valorTotal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (state.currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => { if (row.analytics) { handleClickOpenDialog(e, i, row.title, row.motivo) } else { actions.actions.removeRow(e, i, row.title); } }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}