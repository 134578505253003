import React from "react";
// import { Col } from "react-bootstrap";
// import { NavMenu } from "./NavMenu";
import { connect } from "react-redux";
import AdminNavBar from "./AdminNavBar";
import configure from "../util/configureSignalR";

let connection = null;

class AdminLayout extends React.Component {
  componentWillMount() {
    const { oidc } = this.props;
    if (oidc && oidc?.user && oidc.user.access_token) {
      connection = configure(oidc);
    }
  }
  render() {
    const { oidc } = this.props;
    const userLoggedIn = oidc.user;
    return (
      <>
        {userLoggedIn && (
          <>
            <AdminNavBar oidc={oidc} connection={connection}>
              {this.props.children}
            </AdminNavBar>
          </>
        )}
        {!userLoggedIn && this.props.children}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    oidc: state.oidc,
  };
}

const connectedDefaultPage = connect(mapStateToProps)(AdminLayout);
export { connectedDefaultPage as AdminLayout };
