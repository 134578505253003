import * as React from "react";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { FormControl, Paper, Typography } from "@mui/material";


export default function ButtonSelectSafra({
  safras = [],
  changeSafra = (value) => {},
  selectedSafra = undefined,
  children,
  ...props
}) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => {
    setOpen(open);
  };
  return (
    <React.Fragment>
      <FormControl variant="standard"
            sx={{
              position: "absolute",
              top: "115px",
              left: "10px",
              textAlign: "center",
              borderRadius: "8px",
              padding: "8px",
              width: "290px",
              height: "35px",
              justifyContent: 'center',
              color: "white",
              backgroundColor: "var(--cort-blue)",
              zIndex: (theme) => theme.zIndex.drawer,
              ':hover': {
                cursor: "pointer"
              }
            }}
            onClick={() => toggleDrawer(!open)}
          >
            <Typography
              style={{
                fontFamily: "Gilroy",
                fontWeight: "Bold",
                textAlign: "center",
                fontSize: "1.25rem",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div>
                {selectedSafra && selectedSafra.nome}
                <SwapHorizIcon
                  sx={{
                    color: 'white',
                    textAlign: 'center'
                  }}
                />
              </div>
            </Typography>

            {open && (
              <Paper
                sx={{
                  position: 'absolute',
                  top: 'calc(100% + 8px)',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  borderRadius: '8px',
                  padding: '8px',
                  width: '290px',
                  color: 'black',
                  backgroundColor: 'white',
                  maxHeight: '100px',
                  overflowY: 'auto',
                  zIndex: (theme) => theme.zIndex.drawer,
                }}
              >
                {safras && safras?.sort((a, b) => new Date(b.dataFim) - new Date(a.dataFim))
                  .map((x, index) => (
                    <div
                      key={index}
                      onClick={() => changeSafra(x)}
                      style={{
                        fontFamily: 'Gilroy',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        padding: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      {x.nome}
                    </div>
                  ))}
              </Paper>
            )}
          </FormControl>
    </React.Fragment>
  );
}
