import { HandleResponse } from "../helpers";

const requestModulosType = "REQUEST_USER_MODULO";
const receiveModulosType = "RECEIVE_USER_MODULO";
const receiveModulosRedirectType = "RECEIVE_USER_MODULO_REDIRECT";
const requestRoutesType = "REQUEST_USER_ROUTES";
const receiveRoutesType = "RECEIVE_USER_ROUTES";
const requestModulosPrivateRouteType = "REQUEST_USER_MODULO_PRIVATE_ROUTE";
const receiveModulosPrivateRouteType = "RECEIVE_USER_MODULO_PRIVATE_ROUTE";
const completeModulosType = "COMPLETE_USER_MODULO";
const requestTerritorioType = "REQUEST_USER_TERRITORIO";
const receiveTerritorioType = "RECEIVE_USER_TERRITORIO";
const setTerritorioType = "SET_USER_TERRITORIO";
const requestRoleType = "REQUEST_USER_ROLE";
const receiveRoleType = "RECEIVE_USER_ROLE";
const requestRoleLevelsType = "REQUEST_ROLE_LEVELS";
const receiveRoleLevelsType = "RECEIVE_ROLE_LEVELS";
const requestUserInfosType = "REQUEST_USER_INFOS";
const receiveUserInfosType = "RECEIVE_USER_INFOS";
const requestAccessToModule = "REQUEST_MODULO_ACCESS";
const requestRolesCpaType = "REQUEST_ROLE_CPA";
const receiveRolesCpaType = "RECEIVE_ROLE_CPA";
const failureRolesCpaType = "FAILURE_ROLE_CPA";

const initialState = {
  user: null,
  modulos: [],
  routesPrivateRoute: [],
  routes: [],
  territorios: [],
  territorio: null,
  roleLevels: null,
  role: null,
  hasCompleteRequestModulo: false,
  isLoadingRedirect: false,
  isLoadingPrivateRoute: false,
  isLoadingRoute: false,
  isLoading: false,
  isLoadingTerritorios: false,
  isLoadingRole: false,
  isLoadingRolesCpa: false,
  isLoadingRoleLevels: false,
  rolesCpa: []

};

export const userActions = {
  requestRoutesPrivateRoute: () => async (dispatch, getState) => {
    try {
      dispatch({ type: requestModulosPrivateRouteType });

      const { oidc } = getState();

      const url = `/api/userModulo/AssignedRoutes`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const routes = await response.json();
      dispatch({ type: receiveModulosPrivateRouteType, routes });
    } catch {
      dispatch({ type: completeModulosType });
    } finally {
      dispatch({ type: completeModulosType });
    }
  },
  requestUser: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestUserInfosType });

    const url = `api/userModulo/GetUser`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const user = await response.json();
    dispatch({ type: receiveUserInfosType, user });
  },
  requestRoutes: () => async (dispatch, getState) => {
    dispatch({ type: requestRoutesType });

    const { oidc } = getState();

    const url = `/api/userModulo/AssignedRoutes`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const routes = await response.json();
    dispatch({ type: receiveRoutesType, routes });
  },
  requestModulos: () => async (dispatch, getState) => {
    dispatch({ type: requestModulosType });

    const { oidc } = getState();

    const url = `/api/userModulo`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const modulos = await response.json();
    dispatch({ type: receiveModulosType, modulos });
  },
  requestModulosAndRedirect: (navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestModulosType });

    const url = `/api/userModulo`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const modulos = await response.json();
    if (modulos?.length === 1) {
      const url = `api/userModulo/requestAccess/${modulos[0].descricao}`;

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const accessAllowed = await response.json();

      if (accessAllowed) {
        if (modulos[0].redirectUrl.includes('/')) {
          navigate(modulos[0].redirectUrl);
        } else {
          navigate(`/${modulos[0].redirectUrl}`);
        }
      }
      else {
        const message = modulos[0].descricao === "Estoque" ?
          "O período de preenchimento não está ativo ou está indisponível para você neste momento." :
          "O Módulo que você está tentando acessar está desativado no momento.";

        dispatch({
          type: "CALL_ALERT",
          responseType: "info",
          title: "Módulo indisponível",
          message: message
        });
      }
    }

    dispatch({ type: receiveModulosType, modulos });
  },
  requestTerritorios: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestTerritorioType });

    const url = `/api/userModulo/Territorios`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const territorios = await response.json();
    dispatch({ type: receiveTerritorioType, territorios });
  },
  requestRole: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestRoleType });

    const url = `/api/userModulo/UserRole`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const role = await response.json();
    dispatch({ type: receiveRoleType, role });
  },
  requestRoleLevels: () => async (dispatch, getState) => {
    const { oidc, user } = getState();
    if (!user.roleLevels) {
      dispatch({ type: requestRoleLevelsType });

      const url = `/api/userModulo/RoleLevels`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      const roleLevels = await response.json();
      dispatch({ type: receiveRoleLevelsType, roleLevels });
    }
  },
  setTerritorio: (territorio) => async (dispatch, getState) => {
    dispatch({ type: setTerritorioType, territorio });
  },
  requestAccessTo: (descricao, redirectTo, navigate) => async (dispatch, getState) => {
    dispatch({ type: requestAccessToModule });

    const { oidc } = getState();

    const url = `api/userModulo/requestAccess/${descricao}`;

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const accessAllowed = await response.json();

    if (accessAllowed) {
      navigate(redirectTo);
    }
    else {
      const message = descricao === "Estoque" ? "O período de preenchimento não está ativo ou está indisponível para você neste momento." : "O Módulo que você está tentando acessar está desativado no momento.";

      dispatch({
        type: "CALL_ALERT",
        responseType: "info",
        title: "Acesso Negado",
        message: message
      });
    }
  },
  requestRolesCpa: () => async (dispatch, getState) => {
    const { oidc, adminRole } = getState();
    if (!adminRole.rolesCpa || (adminRole.rolesCpa && adminRole.rolesCpa.length === 0)) {

      dispatch({ type: requestRolesCpaType });

      const url = `/api/userModulo/CpaRoles`;

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
        },
      };

      const response = await fetch(url, options);
      HandleResponse(response).then(
        roles => {
          dispatch({ type: receiveRolesCpaType, roles });
        },
        error => {
          dispatch({ type: failureRolesCpaType });
          dispatch({ type: "CALL_ALERT", title: "Não foi possível receber os níveis de acesso.", message: error, responseType: "error" });
        }
      );
    }
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestModulosType) {
    return {
      ...state,
      isLoading: true,
      isLoadingRedirect: true,
    };
  }
  if (action.type === receiveModulosRedirectType) {
    return {
      ...state,
      isLoadingRedirect: false,
    };
  }
  if (action.type === receiveModulosType) {
    return {
      ...state,
      modulos: action.modulos,
      isLoading: false,
      isLoadingRedirect: false
    };
  }
  if (action.type === requestRoutesType) {
    return {
      ...state,
      isLoadingRoute: true,
    };
  }
  if (action.type === receiveRoutesType) {
    return {
      ...state,
      routes: action.routes,
      isLoadingRoute: false,
    };
  }
  if (action.type === requestModulosPrivateRouteType) {
    return {
      ...state,
      isLoadingPrivateRoute: true,
      hasCompleteRequestModulo: false,
    };
  }
  if (action.type === receiveModulosPrivateRouteType) {
    return {
      ...state,
      routesPrivateRoute: action.routes,
      isLoadingPrivateRoute: false,
      hasCompleteRequestModulo: true,
    };
  }
  if (action.type === completeModulosType) {
    return {
      ...state,
      hasCompleteRequestModulo: true,
    };
  }
  if (action.type === requestTerritorioType) {
    return {
      ...state,
      isLoadingTerritorios: true,
    };
  }
  if (action.type === receiveTerritorioType) {
    return {
      ...state,
      territorios: action.territorios,
      isLoadingTerritorios: false,
    };
  }
  if (action.type === requestRoleType) {
    return {
      ...state,
      isLoadingRole: true,
    };
  }
  if (action.type === receiveRoleType) {
    return {
      ...state,
      role: action.role,
      isLoadingRole: false,
    };
  }
  if (action.type === requestRoleLevelsType) {
    return {
      ...state,
      isLoadingRoleLevels: true,
    };
  }
  if (action.type === receiveRoleLevelsType) {
    return {
      ...state,
      roleLevels: action.roleLevels,
      isLoadingRoleLevels: false,
    };
  }
  if (action.type === setTerritorioType) {
    return {
      ...state,
      territorio: action.territorio,
    };
  }
  if (action.type === receiveUserInfosType) {
    return {
      ...state,
      user: action.user
    }
  }
  if (action.type === requestRolesCpaType) {
    return {
      ...state,
      isLoadingRolesCpa: true,
    };
  }
  if (action.type === receiveRolesCpaType) {
    return {
      ...state,
      rolesCpa: action.roles,
      isLoadingRolesCpa: false,
    };
  }
  if (action.type === failureRolesCpaType) {
    return {
      ...state,
      rolesCpa: [],
      isLoadingRolesCpa: false,
    };
  }

  return state;
};
