const requestUsersType = "REQUEST_USERS";
const receiveUsersType = "RECEIVE_USERS";
const requestUserType = "REQUEST_USER";
const receiveUserType = "RECEIVE_USER";
const requestTerritorioType = "REQUEST_USER_TERRITORIO";
const receiveTerritorioType = "RECEIVE_USER_TERRITORIO";
const setTerritorioType = "SET_USER_TERRITORIO";
const requestRoleType = "REQUEST_USER_ROLE";
const receiveRoleType = "RECEIVE_USER_ROLE";
const requestRoleLevelsType = "REQUEST_ROLE_LEVELS";
const receiveRoleLevelsType = "RECEIVE_ROLE_LEVELS";

const initialState = { users: [], user: null, territorios: [], territorio: null, roleLevels: null, role: null, isLoading: false, isLoadingUser: false, isLoadingTerritorios: false, isLoadingRole: false, isLoadingRoleLevels: false };

export const adminUserActions = {
  clearUser: () => async (dispatch, getState) => {
    dispatch({ type: receiveUserType, user: null });
  },
  requestUsers: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestUsersType });

    const url = `/api/admin/user`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const users = await response.json();
    dispatch({ type: receiveUsersType, users });
  },
  requestUser: (userId) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestUserType });

    const url = `/api/admin/user/${userId}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      const user = await response.json();
      dispatch({ type: receiveUserType, user });
    }
  },
  updateUser: (userId, userEdit, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestUserType });

    const url = `/api/admin/user/${userId}`;

    const options = {
      method: "PUT",
      body: JSON.stringify(userEdit),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin')
      dispatch({ type: receiveUserType, user: null });
      dispatch(adminUserActions.requestUsers());
    }
  },
  saveUser: (userEdit, navigate) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestUserType });

    const url = `/api/admin/user`;

    const options = {
      method: "POST",
      body: JSON.stringify(userEdit),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    if (response.ok) {
      // const user = await response.json();
      navigate('/Admin');
      dispatch({ type: receiveUserType, user: null });
      dispatch(adminUserActions.requestUsers());
    }
  },
  requestTerritorios: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestTerritorioType });

    const url = `/api/userModulo/Territorios`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const territorios = await response.json();
    dispatch({ type: receiveTerritorioType, territorios });
  },
  requestRole: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestRoleType });

    const url = `/api/userModulo/UserRole`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const role = await response.json();
    dispatch({ type: receiveRoleType, role });
  },
  requestRoleLevels: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestRoleLevelsType });

    const url = `/api/userModulo/RoleLevels`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const roleLevels = await response.json();
    dispatch({ type: receiveRoleLevelsType, roleLevels });
  },
  setTerritorio: (territorio) => async (dispatch, getState) => {
    dispatch({ type: setTerritorioType, territorio });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestUsersType) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveUsersType) {
    return {
      ...state,
      users: action.users,
      isLoading: false,
    };
  }
  if (action.type === requestUserType) {
    return {
      ...state,
      isLoadingUser: true,
    };
  }
  if (action.type === receiveUserType) {
    return {
      ...state,
      user: action.user,
      isLoadingUser: false,
    };
  }
  if (action.type === requestTerritorioType) {
    return {
      ...state,
      isLoadingTerritorios: true,
    };
  }
  if (action.type === receiveTerritorioType) {
    return {
      ...state,
      territorios: action.territorios,
      isLoadingTerritorios: false,
    };
  }
  if (action.type === requestRoleType) {
    return {
      ...state,
      isLoadingRole: true,
    };
  }
  if (action.type === receiveRoleType) {
    return {
      ...state,
      role: action.role,
      isLoadingRole: false,
    };
  }
  if (action.type === requestRoleLevelsType) {
    return {
      ...state,
      isLoadingRoleLevels: true,
    };
  }
  if (action.type === receiveRoleLevelsType) {
    return {
      ...state,
      roleLevels: action.roleLevels,
      isLoadingRoleLevels: false,
    };
  }
  if (action.type === setTerritorioType) {
    return {
      ...state,
      territorio: action.territorio,
    };
  }


  return state;
};
