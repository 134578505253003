import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { SaveCenarioActions } from "../../../actions/savecenario.actions";
import { clientActions } from "../../../actions/client.actions";
import { cenariosActions } from "../../../actions/cenario.actions";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from "@mui/material/Grid";
import { Checkbox } from "@mui/material";
import { nivelSemaforoActions } from "../../../actions/nivelSemaforo.actions";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const zeroPad = (num, places) => String(num).padStart(places, '0')
const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "77px",
          padding: "6px 6px",
          "&:first-child": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const qtdCard = 3;

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />

      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />

      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`; }

function CustomPaginationActionsTable(props) {
  const { rows, addCenario, removeCenario, selected } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeCheck = (e, cenarioId) => {
    if (e.target.checked) {
      addCenario(cenarioId)
    } else {
      removeCenario(cenarioId);
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
        <TableHead sx={{
          backgroundColor: "var(--cort-blue)",
          "th": {
            color: "#FFF",
            fontWeight: "bold"
          },
        }}>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell size="medium" align="center">Simulação</TableCell>
            <TableCell size="medium">Data Simulação</TableCell>
            <TableCell size="medium">Campanha</TableCell>
            <TableCell size="medium">Produtos</TableCell>
            <TableCell size="medium" align="right">Valor Final</TableCell>
            <TableCell size="medium" align="center">
              Visualizar Cenário
            </TableCell>
            <TableCell size="medium" align="center">Status</TableCell>
            <TableCell size="medium" align="center">Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <TableRow
              key={row.cenarioId}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                'th': {
                  maxWidth: '400px'
                }
              }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  disabled={selected.length === 3 && selected.indexOf(row.cenarioId) === -1}
                  checked={selected.indexOf(row.cenarioId) !== -1}
                  onChange={(e) => { handleChangeCheck(e, row.cenarioId) }}
                />
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {zeroPad(row.cenarioId, 4)}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.dataCriada}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.campanha}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.produtos}
              </TableCell>
              <TableCell align="right">
                {
                  Number(parseFloat(row.valorFinal).toFixed(2)).toLocaleString("pt-br", {
                    style: "currency",
                    currency: row.moeda,
                  })
                }
              </TableCell>
              <TableCell align="center">
                <a target="_blank" href={`/Cenario?CenarioId=${row.cenarioId}&GroupId=${row.id}`} rel="noreferrer">
                  <Button variant="contained">Visualizar</Button>
                </a>
              </TableCell>
              <TableCell align="center">
                {row.situacaoCenario}
              </TableCell>
              <TableCell align="center">
                <CircleIcon sx={{ color: `${row.score?.cor === 0 ? "#C00000" : row.score?.cor === 1 ? "#FFC000" : row.score?.cor === 2 ? "#00B050" : "var(--bs-border-color)"}` }} />
              </TableCell>

            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={defaultLabelDisplayedRows}
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'Linhas por página',
          },
          native: false,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>

  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: "100vw",
    border: 'none',
  },
}));

const invertSignal = (value = 0) => {
  return value * -1;
}

function ProdutosTable({ rows, currency }) {
  const [rowHeight, setRowHeight] = React.useState(0);

  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={theme}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{
              "th": {
                fontWeight: "bold"
              },
            }}>

              <TableCell>Produto</TableCell>
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Flexibilidade</TableCell>
              <TableCell align="right">Ajuste</TableCell>
              <TableCell align="right">Preço Negociado</TableCell>
              <TableCell align="right">Valor Final</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow key={row.produto} ref={rowRef}>
                  <TableCell>
                    {row.produto}
                  </TableCell>
                  <TableCell align="right">
                    {(row.volume).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {invertSignal(row.flexibilidade).toFixed(3).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                    %
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.ajuste.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.precoNegociado.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.valorFinal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}

function CustomCardCenario({ row, handleRemoveCenario, actions, ...props }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [motivo, setMotivo] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {

    setOpen(false);
  };

  const handleChangeMotivo = (e) => {
    setMotivo(e.target.value);
  }

  const handleClickOpenDialog = (e) => {
    setMotivo("");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMotivo("");
  };

  const enviarAprovacaoCenario = () => {
    if (motivo.replaceAll(" ", "").length > 20) {
      actions.enviarCenario(row.id, row.cenarioId, motivo);
      setOpenDialog(false);
    }
  }

  var color = `${row.score ? `${row.score.cor === 0 ? "vermelho" : row.score.cor === 1 ? "amarelo" : "verde"}` : "neutro"}`;

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Deseja realmente enviar o cenário para aprovação?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Insira uma justificativa sobre o envio:
          </DialogContentText>
          <TextField
            autoFocus
            value={motivo}
            onChange={handleChangeMotivo}
            margin="dense"
            id="name"
            label="Justificativa*"
            fullWidth
            variant="standard"
            error={motivo.replaceAll(" ", "").length < 20}
            helperText={motivo.replaceAll(" ", "").length < 20 ? `Quantidade de Caracteres: ${motivo.replaceAll(" ", "").length} de 20` : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={enviarAprovacaoCenario}>Enviar</Button>
        </DialogActions>
      </Dialog>
      <Card sx={{ backgroundColor: 'var(--bs-card-cap-bg)', width: `max(calc(((100% - 222px) / ${qtdCard}) - 16px), 306px)`, boxShadow: "none" }}>
        <CardHeader
          action={
            <IconButton aria-label="remover" onClick={(e) => { handleRemoveCenario(row.cenarioId) }}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent sx={{ marginTop: "-64px" }}>
          <Typography sx={{ fontSize: "15px", height: 24, textAlign: "center" }} color="text.primary">
            {`#${zeroPad(row.cenarioId, 4)}`}
          </Typography>
          <Typography sx={{ fontSize: "15px", height: 24, textAlign: "center" }} component="div">
            <CircleIcon sx={{ verticalAlign: "top" }} color={color} />
          </Typography>
          <Typography color="text.primary"
            sx={{
              fontSize: "15px",
              textAlign: "center",
              height: 24,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {row.campanha}
          </Typography>
          <Typography sx={{ fontSize: "15px", textAlign: "center", height: 24 }} color="text.primary">
            {row.vencimento}
          </Typography>
          <Typography sx={{ fontSize: "15px", textAlign: "right", height: 24 }} color="text.primary">
            {Number(parseFloat(row.valorTotalBase).toFixed(2)).toLocaleString("pt-br", {
              style: "currency",
              currency: row.moeda,
            })}
          </Typography>
          <Typography sx={{ fontSize: "15px", textAlign: "right", height: 24 }} color="text.primary">
            <Stack
              direction="row"
              spacing={0}
              justifyContent="flex-end"
              sx={{
                "*": {
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }
              }}
            >
              <div>{Number(parseFloat((row.descontoCategoriaPer * 100)).toFixed(2)).toLocaleString("pt-br")}%</div>
              <div>
                {Number(parseFloat(row.descontoCategoria).toFixed(2)).toLocaleString("pt-br", {
                  style: "currency",
                  currency: row.moeda,
                })}
              </div>
            </Stack>
          </Typography>
          <Typography sx={{ fontSize: "15px", textAlign: "right", height: 24 }} color="text.primary">
            <Stack
              direction="row"
              spacing={0}
              justifyContent="flex-end"
              sx={{
                "*": {
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }
              }}
            >
              <div>{Number(parseFloat((row.descontoPackPer * 100)).toFixed(2)).toLocaleString("pt-br")}%</div>
              <div>
                {Number(parseFloat(row.descontoPack).toFixed(2)).toLocaleString("pt-br", {
                  style: "currency",
                  currency: row.moeda,
                })}
              </div>
            </Stack>
          </Typography>
          <Typography sx={{ fontSize: "15px", textAlign: "right", height: 24 }} color="text.primary">
            <Stack
              direction="row"
              spacing={0}
              justifyContent="flex-end"
              sx={{
                "*": {
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }
              }}
            >
              <div>{Number(parseFloat((row.flexibilidadePer * 100)).toFixed(2)).toLocaleString("pt-br")}%</div>
              <div>
                {Number(parseFloat(row.flexibilidade).toFixed(2)).toLocaleString("pt-br", {
                  style: "currency",
                  currency: row.moeda,
                })}
              </div>
            </Stack>
          </Typography>
          <Typography sx={{ fontSize: "15px", textAlign: "right", height: 24 }} color="text.primary">
            <Stack
              direction="row"
              spacing={0}
              justifyContent="flex-end"
              sx={{
                "*": {
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }
              }}
            >
              <div>{Number(parseFloat((row.ajustePer * 100)).toFixed(2)).toLocaleString("pt-br")}%</div>
              <div>
                {Number(parseFloat(row.ajuste).toFixed(2)).toLocaleString("pt-br", {
                  style: "currency",
                  currency: row.moeda,
                })}
              </div>
            </Stack>

          </Typography>
          <Typography sx={{ fontSize: "15px", textAlign: "right", height: 24 }} color="text.primary">
            {Number(parseFloat(row.valorFinal).toFixed(2)).toLocaleString("pt-br", {
              style: "currency",
              currency: row.moeda,
            })}
          </Typography>
          <Grid container spacing={2} className="mt-2">
            <Grid item xs={12}>
              <HtmlTooltip placement="top" open={open} onClose={handleClose}
                title={
                  <React.Fragment>
                    <ProdutosTable rows={row.itens} currency={row.moeda} />
                  </React.Fragment>
                }
              >
                <Button variant="contained" color="azul" fullWidth onClick={(e) => { setOpen(true); }}>Visualizar</Button>
              </HtmlTooltip>
            </Grid>
            <Grid item xs={12}>
              <Button disabled={row.situacao !== 1} onClick={handleClickOpenDialog} variant="contained" color="azul" fullWidth>Enviar para aprovação</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

class CompararCenario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      cenarios: [],
      openAlert: false,
      messageAlert: "",
      severityType: "success",
      situacao: [
        { texto: "Rascunho", codigo: 0 },
        { texto: "Salvo", codigo: 1 },
        { texto: "Excluído", codigo: 2 },
        { texto: "Aguardando Aprovação", codigo: 3 },
        { texto: "Aprovado", codigo: 4 },
        { texto: "Reprovado", codigo: 5 },
      ]
    };

    this.handleAddCenario = this.handleAddCenario.bind(this)
    this.handleRemoveCenario = this.handleRemoveCenario.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ clientId: params.GroupId });

    this.props.actions.requestClients();
    this.props.actions.requestAllNivelSemaforo();
    this.props.actions.requestCenarios(params.GroupId);
  }

  handleAddCenario(cenarioId) {
    var { cenarios } = this.state;

    if (cenarios.length === qtdCard) {
      this.setState({ messageAlert: "Não é possivel adicionar mais cenários à comparação!" });
      this.setState({ severityType: "warning" });
      this.setState({ openAlert: true });
    } else {
      const index = cenarios.indexOf(cenarioId);
      if (index === -1) {
        cenarios.push(cenarioId);
      } else {
        this.setState({ messageAlert: "O cenário já está presente na comparação!" });
        this.setState({ severityType: "error" });
        this.setState({ openAlert: true });
      }

      this.setState({ cenarios: cenarios });
    }
  }

  handleRemoveCenario(cenarioId) {
    var { cenarios } = this.state;

    const index = cenarios.indexOf(cenarioId);
    if (index > -1) {
      cenarios.splice(index, 1);
    }

    this.setState({ cenarios: cenarios });

  }

  handleClose() {
    this.setState({ openAlert: false });
  }

  handleOpenDialog(cenarioId) {
    this.setState({ cenarioId: cenarioId })
    this.setState({ openDialog: true })
  }

  handleCloseDialog() {
    this.setState({ openDialog: false })
  }

  enviarAprovacaoCenario() {
    this.props.actions.enviarCenario(this.state.cenarioId, this.state.comentario);
    this.setState({ cenarioId: 0 })
    this.setState({ comentario: "" })
    this.setState({ openDialog: false })
  }

  handleChangeMotivo(e) {
    this.setState({ comentario: e.target.value })
  }

  render() {
    const { clientId, cenarios, openAlert, messageAlert, severityType } = this.state;

    var rows = [];
    var client = {}
    var cli = ""
    var territorio = ""
    var distrito = ""

    if (!this.props.listCenarios.isLoading && this.props.listCenarios && !this.props.clientes.isLoading && this.props.clientes) {

      rows = this.props.listCenarios.listCenarios.filter(x => x.situacaoCenario !== 0).map(c => {
        var cliente = this.props.clientes.clients.find(x => x.clientId === c.clienteId)
        var situacao = this.state.situacao.find(x => x.codigo === c.situacaoCenario)?.texto;
        return {
          id: clientId,
          cenarioId: c.cenarioId,
          situacao: c.situacaoCenario,
          situacaoCenario: situacao,
          statusCenario: c.statusCenario,
          campanha: c.campanha == null ? "" : c.campanha,
          vencimento: (new Date(c.vencimento)).toLocaleDateString(),
          condicao: c.condicao,
          distrito: c.distrito,
          clienteId: c.clienteId,
          territorio: c.territorio,
          dataCriada: (new Date(c.dataCriada)).toLocaleDateString(),
          itens: c.itensCenarios,
          produtos: c.itensCenarios.filter(x => { return x.volume !== 0 }).map(x => { return x.produto }).join(", "),
          volumeTotal: c.itensCenarios.reduce((sum, x) => { return sum + x.volume }, 0),
          valorFinal: c.soma,
          cliente: cliente?.clientName,
          score: c.nivelSemaforoAtt,
          moeda: c.moeda == null || c.moeda === undefined ? "USD" : c.moeda,
          valorTotalBase: c.valorTotalBase,
          descontoCategoria: c.descontoCategoria,
          descontoCategoriaPer: c.descontoCategoriaPer,
          descontoPack: c.descontoPack,
          descontoPackPer: c.descontoPackPer,
          flexibilidade: c.flexibilidade,
          flexibilidadePer: c.flexibilidadePer,
          ajuste: c.ajuste,
          ajustePer: c.ajustePer,
        }
      })
    }

    if (!this.props.clientes.isLoading && this.props.clientes) {
      // eslint-disable-next-line eqeqeq
      client = this.props.clientes.clients.find(x => x.id == clientId)
      // console.log(clientId, client)
      if (client) {
        cli = client.clientName
        territorio = client.territory;
        distrito = client.district
      }
    }
    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.clientes.isLoading || this.props.listCenarios.isLoading || this.props.nivelSemaforo.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity={severityType} sx={{ width: '100%' }}>
            {messageAlert}
          </Alert>
        </Snackbar>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/MinhasSimulacoesCliente?GroupId=${clientId}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/Simulador"}
              >
                Simulador
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/MinhasSimulacoesCliente?GroupId=${clientId}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">Comparar Cenários</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={12}>
              <h1 style={{ color: "var(--cort-blue)" }}>{cli}</h1>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio}</h6>
              <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {distrito}</h6>
            </Grid>
            <Grid item xs={2} sm={8} md={12}>
              {!this.props.listCenarios.isLoading && this.props.listCenarios && cenarios.length > 0 &&
                <Paper className="mb-4" sx={{ overflowX: "auto", boxShadow: "none" }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    sx={{ minWidth: `${222 + cenarios.length * (306/*width*/ + 16/*padding*/)}px` }}>
                    <Card sx={{ boxShadow: "none", minWidth: "222px" }}>
                      <CardContent sx={{ textAlign: "right" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Simulação
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Score
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Campanha
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Vencimento
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Vl. Total (Preço+Encargos)
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Desconto Categoria
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Desconto Packs
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Flexibilidade
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Ajuste Comercial
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold", height: 24 }} color="text.primary">
                          Valor Total Simulação
                        </Typography>
                      </CardContent>
                    </Card>
                    {cenarios.map((cenarioId) => {
                      var data = rows.find(x => x.cenarioId === cenarioId)
                      return (
                        <CustomCardCenario key={`Card-Comparar-${data.cenarioId}`}
                          row={data}
                          handleRemoveCenario={this.handleRemoveCenario}
                          actions={this.props.actions} />
                      )
                    })}
                  </Stack>
                </Paper>
              }
            </Grid>
            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                  <p>Selecione até 3 cenários para comparação:</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={8} md={12} >
              <CustomPaginationActionsTable rows={rows} addCenario={this.handleAddCenario} removeCenario={this.handleRemoveCenario} selected={cenarios} />
            </Grid>
          </Grid>
        </div>
      </div>

    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...clientActions,
        ...cenariosActions,
        ...SaveCenarioActions,
        ...nivelSemaforoActions,
      }, dispatch),
    };
  }
)(CompararCenario);
