import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Autocomplete, Button, Checkbox, FormHelperText, MenuItem, Select, Stack, TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { adminUserActions } from '../../../../actions/admin/user.actions';
import { StocksActions as stockActions } from '../../../../actions/stock.actions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useFormik } from 'formik';
import { adminModuloActions } from '../../../../actions/admin/modulo.actions';
import { adminRoleActions } from '../../../../actions/admin/role.actions';
import TransferList from '../../../EnchantedTransferList';
import { adminTerritoryActions } from '../../../../actions/admin/territory.actions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AllowUseNavigate, { navigate } from '../../../Navigate';
import Virtualize from '../../../AutocompleteVirtual';
import { isNullOrEmpty } from '../../../../helpers';

const camposTexto = [
  { texto: "Email", prop: "email" },
  { texto: "Nome", prop: "name" },
  { texto: "Telefone", prop: "phone" },
  { texto: "Celular", prop: "mobilePhone" },
];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function FormEdit({
  user = {},
  modulos = [],
  roles = [],
  roleLevels = undefined,
  areas = [],
  distritos = [],
  territorios = [],
  businessAreas = [],
  sourcesCNPJ = [],
  send = (user) => { },
  ...props }
) {
  const [userConst, setUser] = React.useState(null);
  const [modulosConst, setModulos] = React.useState(null);
  const [modulosUser, setModulosUser] = React.useState([]);
  const [modulosNotUser, setModulosNotUser] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState(null);
  const [selectedTerritorio, setTerritorio] = React.useState(undefined);
  const [selectedArea, setArea] = React.useState(undefined);
  const [selectedDistrito, setDistrito] = React.useState(undefined);
  const [selectedDistritos, setDistritos] = React.useState(undefined);
  const [selectedBusinessArea, setBusinessArea] = React.useState(undefined);
  const [selectedSourcesCnpjs, setSourcesCNPJ] = React.useState(undefined);
  const [selectedCnpjs, setSelectedCNPJ] = React.useState(undefined);

  const formik = useFormik({
    initialValues: user,
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Insira um email';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = 'Email invalido';
      }
      if (!values.role) {
        errors.role = 'Insira um nivel de acesso';
      }
      return errors;
    },
    onSubmit: (values) => {
      if (checkSubmit()) {
        values.territorio = selectedTerritorio !== undefined ? selectedTerritorio?.territoryId : "";
        values.distrito = selectedDistrito;
        values.distritos = selectedDistritos;
        values.businessArea = selectedBusinessArea;
        values.areas = selectedArea;
        values.raizesCNPJ = selectedSourcesCnpjs;
        values.cnpjs = selectedCnpjs;
        values.modulos = modulosUser.map(x => { return x.id });
        send(values);
      }
    },
  });

  const setRole = (role) => {
    setSelectedRole(roles.find(x => x.name === role))
  }

  const checkSubmit = () => {
    if (selectedRole && selectedRole?.accessLevel === -10)
      return true;
    else if (selectedRole && selectedRole?.accessLevel === roleLevels?.viewTerritory && selectedTerritorio !== undefined)
      return true;
    else if (selectedRole && selectedRole?.accessLevel >= roleLevels?.viewDistrict && selectedRole?.accessLevel < roleLevels?.viewManyDistrict && selectedDistrito !== undefined)
      return true;
    else if (selectedRole && selectedRole?.accessLevel >= roleLevels?.viewManyDistrict && selectedRole?.accessLevel < roleLevels?.viewBusinessArea && selectedDistritos !== undefined && selectedDistritos.length > 0)
      return true;
    else if (selectedRole && selectedRole?.accessLevel >= roleLevels?.viewBusinessArea && selectedRole?.accessLevel < roleLevels?.viewArea && !isNullOrEmpty(selectedArea) && !isNullOrEmpty(selectedBusinessArea))
      return true;
    else if (selectedRole && selectedRole?.accessLevel >= roleLevels?.viewArea && selectedRole?.accessLevel < roleLevels?.viewAll && selectedArea !== undefined)
      return true;
    else if (selectedRole && selectedRole?.accessLevel === roleLevels?.viewAll)
      return true;
    return false;
  }

  if (userConst !== user || modulosConst !== modulos) {
    setUser(user);
    setModulos(modulos);
    setModulosUser(modulos.filter(x => formik.values?.userModulo?.find(y => y.moduloId === x.id) !== undefined));
    setModulosNotUser(modulos.filter(x => formik.values?.userModulo?.find(y => y.moduloId === x.id) === undefined));
    setRole(formik.values?.role);
    setSourcesCNPJ(user?.userSourceCNPJ?.map(x => x.raizCNPJ));
    setSelectedCNPJ(user?.userCNPJ?.map(x => x.cnpj));
    if (user?.userTerritory?.length > 0) {
      var teritory = user.userTerritory[0];
      if (teritory?.territoryId != null)
        setTerritorio(territorios.find(x => x.territoryId === teritory.territoryId))
      if (teritory?.areaName != null)
        setArea(teritory.areaName)
      if (teritory?.businessArea != null)
        setBusinessArea(teritory.businessArea)
      if (teritory?.districtName != null)
        setDistrito(teritory.districtName)
      setDistritos(user.userTerritory.filter(x => x.districtName != null).map(x => { return x.districtName }))
    }
  }

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: "8px 0" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      {camposTexto.map((x, index) => (
        <TextField
          key={index}
          color={'azul'}
          fullWidth
          id={x.prop}
          name={x.prop}
          label={x.texto}
          variant="standard"
          value={formik.values[x.prop]}
          onChange={formik.handleChange}
          error={formik.touched[x.prop] && Boolean(formik.errors[x.prop])}
          helperText={formik.touched[x.prop] && formik.errors[x.prop]}
        />
      ))
      }
      <FormControl error={formik.touched.role && Boolean(formik.errors.role)} variant="standard" fullWidth>
        <InputLabel id="role-label">Nivel de Acesso</InputLabel>
        <Select
          color={'azul'}
          labelId="role-label"
          id="role"
          name="role"
          aria-describedby="role-text"
          value={formik.values?.role ?? ""}
          error={formik.touched.role && Boolean(formik.errors.role)}
          label="Acesso"
          required
          onChange={(e) => { setRole(e.target.value); formik.handleChange(e); }}
        >
          {
            roles.map((x, index) => (
              <MenuItem key={index} value={x.name}>{x.name}</MenuItem>
            ))
          }
        </Select>
        <FormHelperText id="role-text">
          {formik.touched.role && formik.errors.role}
        </FormHelperText>
      </FormControl>
      {(selectedRole && selectedRole?.accessLevel === roleLevels?.viewTerritory) &&
        <FormControl variant="standard" fullWidth>
          <Autocomplete
            fullWidth
            color={'azul'}
            disablePortal
            id="territorio"
            name="territorio"
            value={selectedTerritorio}
            options={territorios}
            getOptionLabel={(option) => option.territoryName}
            disableClearable
            onChange={(event, newInputValue) => { setTerritorio(newInputValue) }}
            noOptionsText="Território não encontrado"
            renderInput={(params) =>
              <TextField
                {...params}
                required
                error={selectedTerritorio === undefined}
                helperText={selectedTerritorio === undefined ? "Selecione um território" : ""}
                variant="standard"
                label="Território" />
            }
          />
        </FormControl>
      }
      {(selectedRole && selectedRole?.accessLevel >= roleLevels?.viewDistrict && selectedRole?.accessLevel < roleLevels?.viewManyDistrict) &&
        <FormControl variant="standard" fullWidth>
          <Autocomplete
            fullWidth
            color={'azul'}
            disablePortal
            id="distrito"
            name="distrito"
            options={distritos.filter(x => !isNullOrEmpty(x))}
            disableClearable
            value={selectedDistrito}
            onChange={(event, newInputValue) => { setDistrito(newInputValue) }}
            noOptionsText="Distrito não encontrado"
            renderInput={(params) =>
              <TextField
                {...params}
                required
                error={selectedDistrito === undefined}
                helperText={selectedDistrito === undefined ? "Selecione um distrito" : ""}
                variant="standard"
                label="Distrito" />
            }
          />
        </FormControl>
      }
      {(selectedRole && selectedRole?.accessLevel >= roleLevels?.viewManyDistrict && selectedRole?.accessLevel < roleLevels?.viewBusinessArea) &&
        <FormControl variant="standard" fullWidth>
          <Autocomplete
            fullWidth
            multiple
            color={'azul'}
            disablePortal
            id="distritos"
            name="distritos"
            options={distritos.filter(x => !isNullOrEmpty(x))}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            value={selectedDistritos}
            onChange={(event, newInputValue) => { setDistritos(newInputValue) }}
            noOptionsText="Distrito não encontrado"
            renderInput={(params) =>
              <TextField
                {...params}
                error={selectedDistritos === undefined || selectedDistritos.length === 0}
                helperText={(selectedDistritos === undefined || selectedDistritos.length === 0) ? "Selecione um distrito" : ""}
                variant="standard"
                label="Distritos" />
            }
          />
        </FormControl>
      }
      {(selectedRole && selectedRole?.accessLevel >= roleLevels?.viewBusinessArea && selectedRole?.accessLevel < roleLevels?.viewArea) &&
        <>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              fullWidth
              color={'azul'}
              disablePortal
              id="areas"
              name="areas"
              options={areas.filter(x => !isNullOrEmpty(x))}
              disableClearable
              value={selectedArea}
              onChange={(event, newInputValue) => { setArea(newInputValue); setBusinessArea(null) }}
              noOptionsText="Área não encontrada"
              renderInput={(params) =>
                <TextField
                  {...params}
                  error={selectedArea === undefined}
                  helperText={selectedArea === undefined ? "Selecione uma área" : ""}
                  variant="standard"
                  label="Áreas" />
              }
            />
          </FormControl>

          {selectedArea && <FormControl variant="standard" fullWidth>
            <Autocomplete
              fullWidth
              color={'azul'}
              disablePortal
              id="businessArea"
              name="businessArea"
              options={businessAreas.filter(x => !isNullOrEmpty(x) && x?.areaName === selectedArea).map(x => x.businessArea)}
              value={selectedBusinessArea}
              onChange={(event, newInputValue) => { setBusinessArea(newInputValue) }}
              noOptionsText="Business Area não encontrada"
              renderInput={(params) =>
                <TextField
                  {...params}
                  error={isNullOrEmpty(selectedBusinessArea)}
                  helperText={isNullOrEmpty(selectedBusinessArea) ? "Selecione um distrito" : ""}
                  variant="standard"
                  label="Business Area" />
              }
            />
          </FormControl>}
        </>
      }
      {(selectedRole && selectedRole?.accessLevel >= roleLevels?.viewArea && selectedRole?.accessLevel < roleLevels?.viewAll) &&
        <FormControl variant="standard" fullWidth>
          <Autocomplete
            fullWidth
            color={'azul'}
            disablePortal
            id="areas"
            name="areas"
            options={areas.filter(x => !isNullOrEmpty(x))}
            disableClearable
            value={selectedArea}
            onChange={(event, newInputValue) => { setArea(newInputValue) }}
            noOptionsText="Área não encontrada"
            renderInput={(params) =>
              <TextField
                {...params}
                error={selectedArea === undefined}
                helperText={selectedArea === undefined ? "Selecione uma área" : ""}
                variant="standard"
                label="Áreas" />
            }
          />
        </FormControl>
      }
      {(selectedRole) &&
        <>
          <Virtualize
            fullWidth
            multiple
            disableCloseOnSelect
            checkbox={true}
            value={selectedSourcesCnpjs}
            options={sourcesCNPJ.flatMap(x => x.raizCNPJ)}
            noOptionsText="Raiz CNPJ..."
            getOptionLabel={(option) => option}
            getValue={(option) => option}
            onChange={(event, value) => setSourcesCNPJ(value)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label="Raiz CNPJ"
                variant="standard"
                margin="dense"
              />
            )}
          ></Virtualize>

          <Virtualize
            fullWidth
            multiple
            disableCloseOnSelect
            checkbox={true}
            value={selectedCnpjs}
            options={sourcesCNPJ.flatMap(x => x.cnpj)}
            noOptionsText="CNPJs..."
            getOptionLabel={(option) => option}
            getValue={(option) => option}
            onChange={(event, value) => setSelectedCNPJ(value)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label="CNPJs"
                variant="standard"
                margin="dense"
              />
            )}
          ></Virtualize>
        </>
      }
      <TransferList left={modulosNotUser}
        setLeft={setModulosNotUser}
        property={"descricao"}
        leftTitle={"Módulos Disponíveis"}
        getLabel={(value) => { return value.descricao }}
        right={modulosUser}
        setRight={setModulosUser}
        rightTitle={"Módulos do Usuário"}
        buttonVariant={'contained'}
      />
      <Button color="azul" variant="contained" fullWidth type="submit">
        Salvar
      </Button>
    </Box>
  );
}

class AdminUsuarioEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.sendUser = this.sendUser.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.clearUser();
  }

  componentWillMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.UserId !== undefined && params.UserId !== '')
      this.props.actions.requestUser(params.UserId)

    this.props.actions.requestModulos();
    this.props.actions.requestRoles();
    this.props.actions.requestRoleLevels();
    this.props.actions.requestTerritorios();
    this.props.actions.requestAreas();
    this.props.actions.requestDistritos();
    this.props.actions.requestBusinessAreas();
    this.props.actions.requestCanaisCNPJ();
  }

  sendUser(user) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.UserId !== undefined && params.UserId !== '')
      this.props.actions.updateUser(params.UserId, user, navigate);
    else
      this.props.actions.saveUser(user, navigate);
  }

  render() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var isLoading = this.props.adminUser.isLoadingUser ||
      this.props.adminUser.isLoadingRoleLevels ||
      this.props.adminModulo.isLoading ||
      this.props.adminRole.isLoading ||
      this.props.adminTerritory.isLoading ||
      this.props.adminTerritory.isLoadingAreas ||
      this.props.adminTerritory.isLoadingbusinessAreas ||
      this.props.adminTerritory.isLoadingDistritos;

    var isDataLoad = this.props.adminUser;

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <AllowUseNavigate />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={"/Admin"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/Admin"}>
                Usuários
              </Link>
              <Typography color="text.primary">{(params.UserId !== undefined && params.UserId !== '') ? "Editar" : "Novo"}</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          {!isLoading && isDataLoad &&
            <FormEdit
              user={this.props.adminUser.user ?? {}}
              modulos={this.props.adminModulo.modulos}
              roles={this.props.adminRole.roles}
              roleLevels={this.props.adminUser.roleLevels}
              areas={this.props.adminTerritory.areas}
              distritos={this.props.adminTerritory.distritos}
              territorios={this.props.adminTerritory.territorios}
              businessAreas={this.props.adminTerritory.businessAreas}
              sourcesCNPJ={this.props.stocks.sourcesCNPJ}
              send={this.sendUser}
            />
          }
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminUserActions,
        ...adminModuloActions,
        ...adminRoleActions,
        ...adminTerritoryActions,
        ...stockActions
      }, dispatch),
    };
  }
)(AdminUsuarioEditPage);
