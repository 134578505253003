import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Stack,
} from '@mui/material';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import RoomIcon from '@mui/icons-material/Room';

export default function CoordinatesPagination(props) {
  const { button, addData, changeLoadState } = props;

  const [open, setOpen] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [userCoordinates, setUserCoordinates] = useState(null);

  const handleOpen = () => {
    if (userCoordinates != null && userCoordinates.length > 0) {
      setLatitude(userCoordinates[0]);
      setLongitude(userCoordinates[1]);
    }
    else {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const { latitude, longitude } = position.coords;
            setUserCoordinates({ latitude, longitude });
            setLatitude(latitude.toString());
            setLongitude(longitude.toString());
          },
          function (error) {
            console.error('Não foi possivel obter a localização atual:', error);
          }
        );
      }
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value.replace(/,/g, '.'));
  };

  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value.replace(/,/g, '.'));
  };

  const handleSearch = () => {
    let value = [latitude, longitude];

    changeLoadState(true);
    addData(value, 'coord');

    setLatitude('');
    setLongitude('');
    setOpen(false);
  };

  const handleGetCoordinates = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const { latitude, longitude } = position.coords;
          setUserCoordinates({ latitude, longitude });
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
        },
        function (error) {
          console.error('Não foi possivel obter a localização atual:', error);
        }
      );
    }
  };

  return (
    <>
      {button && (
        <Tooltip title="Buscar coordenada">
          <IconButton
            sx={{
              color: "white", backgroundColor: "var(--cort-blue)",
              width: "30px",
              height: "30px",
              borderRadius: "3px",
            }}
            onClick={handleOpen}>
            <GpsFixedIcon sx={{ width: "15px", height: "15px" }} />
          </IconButton>
        </Tooltip>
      )}
      {!button && (
        <BottomNavigationAction
          sx={{
            color: 'white',
            fontFamily: 'Gilroy',
            fontWeight: 'Bold',
          }}
          label="Coordenada"
          showLabel={true}
          icon={<GpsFixedIcon />}
          onClick={handleOpen}
        />
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Coordenadas
          <Tooltip
            title="O modelo utilizado para as coordenadas é o de números decimais (dois números antes da vírgula e na maioria dos casos para o Brasil com números negativos para Latitude e Longitude). Exemplo de coordenadas para o escritório Corteva: Latitude: -23.504110300268756 / Longitude: -46.84497159047309"
          >
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={2} sx={{ marginTop: "8px" }}>
            <TextField
              label="Latitude"
              value={latitude}
              onChange={handleLatitudeChange}
              fullWidth
            />
            <TextField
              label="Longitude"
              value={longitude}
              onChange={handleLongitudeChange}
              fullWidth
            />
            <Tooltip title="Encontrar minha localização. Atenção: O sistema pode sofrer interferências de VPN e outros softwares capazes de alterar sua localização.">
              <Button variant="contained" onClick={handleGetCoordinates} color="primary">
                <RoomIcon sx={{ color: "white" }} />
              </Button>
            </Tooltip>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSearch} color="primary" sx={{ fontWeight: 'bold' }}>
            Pesquisar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}